import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
// import { HttpLink } from 'apollo-link-http'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import jwtDecode from 'jwt-decode'
import { getAccessToken, setAccessToken } from './AccessToken'
//import { split, HttpLink } from '@apollo/client'
import { split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { FinalLogout } from '@hr-root/hr-employee-react/src/containers/AccountModule/Logout'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'

const cache = new InMemoryCache({})

export const hrNodeNavUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrx-dev-api.ifca.io/protected'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrx-uat-api.ifca.io/protected'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxapi.hrx.asia/protected'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcaapi.hrx.asia/protected'
    : process.env.REACT_APP_API_URL === 'dev-v2-build'
    ? 'https://hrx-dev-api.ifca.io/protected'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcaapi-uat.hrx.asia/protected'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-api.hrx.asia/protected'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-uat-api.hrx.asia/protected'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://api.eon.com.my/protected'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://api-uat.eon.com.my/protected'
    : process.env.REACT_APP_API_URL === 'demo-build'
    ? 'https://hrx-demo.hrx.asia/protected'
    : 'http://localhost:7000/protected'

export const hrNodeUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrx-dev-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrx-uat-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcaapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'dev-v2-build'
    ? 'https://hrx-dev-api.ifca.io/hrx-v2'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcaapi-uat.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-uat-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://api.eon.com.my/hrx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://api-uat.eon.com.my/hrx'
    : process.env.REACT_APP_API_URL === 'demo-build'
    ? 'https://hrx-demo.hrx.asia/hrx'
    : 'http://localhost:7000/hrx'

export const wsNodeUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'wss://hrx-dev-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'wss://hrx-uat-api.ifca.io/hrx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'wss://hrxapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'wss://ifcaapi.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'wss://ifcaapi-uat.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'wss://hrms-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'wss://hrms-uat-api.hrx.asia/hrx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'wss://api.eon.com.my/hrx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'wss://api-uat.eon.com.my/hrx'
    : process.env.REACT_APP_API_URL === 'demo-build'
    ? 'https://hrx-demo.hrx.asia/hrx'
    : 'ws://localhost:7000/hrx'

export const hrNodeRefreshUrl =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrx-dev-api.ifca.io/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrx-uat-api.ifca.io/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxapi.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcaapi.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'dev-v2-build'
    ? 'https://hrx-dev-api.ifca.io/refresh_token_hrx-v2'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcaapi-uat.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-api.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-uat-api.hrx.asia/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://api.eon.com.my/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://api-uat.eon.com.my/refresh_token_hrx'
    : process.env.REACT_APP_API_URL === 'demo-build'
    ? 'https://hrx-demo.hrx.asia/refresh_token_hrx'
    : 'http://localhost:7000/refresh_token_hrx'

export const ReportURL =
  process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hrxdevreporting.ifca.asia/'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hrxreporting.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hrxreporting.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'ifca-build'
    ? 'https://ifcareporting.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'ifca-uat-build'
    ? 'https://ifcareporting-uat.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'prod-v5-build'
    ? 'https://hrms-report.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'uat-v5-build'
    ? 'https://hrms-reporting-uat.hrx.asia/'
    : process.env.REACT_APP_API_URL === 'prod-eon-build'
    ? 'https://reporting.eon.com.my/'
    : process.env.REACT_APP_API_URL === 'uat-eon-build'
    ? 'https://reporting-uat.eon.com.my/'
    : 'https://hrxdevreporting.ifca.asia/'

//Getting access Token and passing it in request headers
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
                ['Reference1']: localStorage.getItem('userAccess')
                  ? encryptMessage(localStorage.getItem('userAccess'))
                  : null,
              },
            })
          } //accessToken is defined
        }) //then operation ends here
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }) //handle ends here
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const uploadLink = createUploadLink({
  uri: hrNodeUrl,
  credentials: 'include',
})

const httplink = new HttpLink({
  uri: hrNodeUrl,
  credentials: 'include',
}) //new HttpLink ends here
const wsLink = new WebSocketLink({
  uri: wsNodeUrl,
  options: {
    reconnect: true,
  },
})

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httplink
)

export const WSclient = new ApolloClient({
  cache: cache,
  link: splitLink,
})
export const HRClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()

        if (!token) {
          return true
        }

        try {
          const { exp } = jwtDecode(token) as any
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch (err) {
          console.log(err)
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(hrNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        })
      },
      handleFetch: async accessToken => {
        setAccessToken(accessToken)
      },
      handleError: err => {
        window.alert(
          'Your login token is invalid/expired. Try to relogin again!!!'
        )
        console.error(err)

        FinalLogout()
      },
    }),
    onError(() => {}),
    requestLink,
    uploadLink,
    // splitLink as any,
    //httplink,
    // new HttpLink({
    // uri: hrNodeUrl,
    // credentials: 'include',
    // }), //new HttpLink ends here
  ]),
  cache,
})

// const uploadLink = createUploadLink({
// uri: hrNodeUrl,
// credentials: 'include',
// });

// export const Uploadclient = new ApolloClient({
// cache,
// link: ApolloLink.from([requestLink, uploadLink]),
// });
