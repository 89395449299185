import { format, parseISO } from 'date-fns'
import moment from 'moment'

export const formatDate = (date: Date) =>
  new Date(date)
    .toDateString()
    .split(' ')
    .slice(1)
    .join(' ')

export const convertMinToHours = (minute: number) => {
  if (minute) {
    let hours = Math.floor(minute / 60)
    let minutes = minute % 60
    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}m`
    }
    if (hours == 0 && minutes > 0) {
      return `${minutes}m`
    }
    if (hours > 0 && minutes == 0) {
      return `${hours}h`
    }
  } else {
    return null
  }
}

export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD-MM-YYYY HH:MM')
  return buildDate
}

export const convertDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy hh:mm a') : 'NA'
}

export const dateConvert = (date: string | Date) => {
  const result = typeof date === 'string' ? parseISO(date) : date
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}

export const convertDateTime = (date: Date | string) => {
  const result = typeof date === 'string' ? parseISO(date) : date
  return date && format(result, 'dd MMM yyyy hh:mm a')
}

export const getMonthYearFromDate = (date: Date | string) => {
  const result = typeof date === 'string' ? parseISO(date) : date
  return date && format(result, 'MMM yyyy')
}

export const calculateDuration = (startDate: Date | string, endDate: Date | string) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  let years = end.getFullYear() - start.getFullYear()
  let months = end.getMonth() - start.getMonth()

  if (months < 0) {
    years--
    months += 12
  }

  if (end.getDate() < start.getDate()) {
    months--

    if (months < 0) {
      years--
      months += 12
    }
  }

  let duration = ''
  if (years > 0) {
    duration += `${years} ${years > 1 ? 'years' : 'year'}`
  }

  if (months > 0) {
    if (years > 0) {
      duration += ' and '
    }

    duration += `${months} ${months > 1 ? 'months' : 'month'}`
  }

  return duration
}

export function timeElapsed(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60
  )

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}m`
  }
  if (minutes > 0 && hours == 0) {
    return `${minutes}m`
  }
  if (hours > 0 && minutes == 0) {
    return `${hours}h `
  }
  return `0h`
}
export function timeElapsedByHour(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var minutes = Math.floor(milisec_diff / 1000)
  return minutes
}

export function timeElapsedByMin(_datetime: Date, _now: Date) {
  var datetime = new Date(_datetime).getTime()
  var now = new Date(_now).getTime()

  //   if (isNaN(datetime)) {
  //     return ' on ' + _datetime;
  //   }

  if (datetime < now) {
    var milisec_diff = now - datetime
  } else {
    var milisec_diff = datetime - now
  }

  var minutes = Math.floor(milisec_diff / (1000 * 60))
  return minutes
}

export const dateValidity = (Date: string) => {
  console.log(Date, 'Date')
  // regular expression to match required date format
  let re = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/
  if (Date) {
    if (Date.match(re)) {
      return true
    }
    return false
  }

  return false
}
export const timeValidity = (Time: string) => {
  // regular expression to match required date format
  let re1 = /^\d{1,2}([:.]?\d{1,2})?([ ]?[a|p]m)?$/
  let re2 = /^\d{1,2}([:.]?\d{1,2})?([ ]?[A|P]M)?$/
  let re3 = /^\d{1,2}([:.]?\d{1,2})([:.]?\d{1,2})?([ ]?[A|P]M)?$/
  if (Time) {
    if (Time.match(re1) || Time.match(re2) || Time.match(re3)) {
      return true
    }
    return false
  }

  return false
}

export const convertTimestamp = (Time: string) => {
  const timestamp = +Time // Example timestamp
  const date = new Date(timestamp)

  const hours = date.getHours()
  const minutes = date.getMinutes()
  const amPm = hours >= 12 ? 'PM' : 'AM'
  let Hours = hours % 12 || 12 //tu  Adjust hours for 12-hour format

  const formattedTime = `${Hours.toString().padStart(
    2,
    '0'
  )}:${minutes.toString().padStart(2, '0')} ${amPm}`
  return formattedTime
}

export const getTime = (Time: string) => {
  const givenTime = new Date(Time)

  // Get the hour in 12-hour format (0-11)
  const hours = givenTime.getHours() % 12

  // Use ternary operator to add AM/PM
  const period = hours >= 12 ? 'PM' : 'AM'

  // Format the minutes with leading zero if needed
  const minutes = givenTime
    .getMinutes()
    .toString()
    .padStart(2, '0')

  // Combine the hour, minutes, and period into the 12-hour format string
  const formattedTime = `${hours}:${minutes} ${period}`
  return formattedTime
}
