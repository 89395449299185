import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { FullHeader } from 'components/ExternalComponent/FullHeader'
import {
  useJobPortalInfoQuery,
  useJobPostingInfoByJobPortalQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

export const AvailableVacancyDetail = (props: any) => {
  // Define
  let history = useHistory()
  const { state }: any = useLocation()

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [JobPortal, setJobPortal] = useState(null)
  const [JobPostingInfo, setJobPostingInfo] = useState(null)

  // Query
  const {
    data: JobPortalInfo = { JobPortalInfo: null },
    loading: JobPortalInfoLoading,
  } = useJobPortalInfoQuery({
    fetchPolicy: 'no-cache',
    variables: {
      PortalToken: state?.portalToken,
    },
    onCompleted: data => {
      if (data?.JobPortalInfo) {
        setJobPortal(data?.JobPortalInfo)
      }
    },
  })

  const {
    data: JobPostingInfoByJobPortal = { JobPostingInfoByJobPortal: null },
    loading: JobPostingInfoByJobPortalLoading,
  } = useJobPostingInfoByJobPortalQuery({
    fetchPolicy: 'no-cache',
    variables: {
      JobPostingID: state?.JobPostingID,
    },
    onCompleted: data => {
      if (data?.JobPostingInfoByJobPortal) {
        setJobPostingInfo(data?.JobPostingInfoByJobPortal)
      }
    },
  })

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  // Function
  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/JobPortal/JobPortalForm`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      {JobPortalInfoLoading || JobPostingInfoByJobPortalLoading ? (
        <Loading backdropColor={JobPortal?.theme_color} />
      ) : (
        <>
          <FullHeader
            themeColor={JobPortal?.theme_color}
            desktopBanner={JobPortal?.desktop_banner}
            mobileBanner={JobPortal?.mobile_banner}
          />

          <ContentWrapper externalBasicHeader footer>
            <div className="content-wrap full">
              <span
                className="smTitle"
                style={{ color: JobPortal?.theme_color }}
              >
                {JobPostingInfo?.job_position}
              </span>
            </div>

            <div className="content-wrap full">
              <span className="smTitle">Description :</span>
              <div
                className="quill-content"
                style={{
                  fontSize: 12,
                }}
                dangerouslySetInnerHTML={{
                  __html: JobPostingInfo?.job_description,
                }}
              ></div>
            </div>

            <div className="content-wrap full">
              <span className="smTitle">Requirements :</span>
              <div
                className="quill-content"
                style={{
                  fontSize: 12,
                }}
                dangerouslySetInnerHTML={{
                  __html: JobPostingInfo?.job_specification,
                }}
              ></div>
            </div>
          </ContentWrapper>

          <Footer
            externalDisplay
            themeColor={JobPortal?.theme_color}
            options={[
              {
                onClick: () =>
                  history.push(`/JobPortal/AvailableVacancyListing`, {
                    ...state,
                  }),
                name: 'Back',
                color: 'primary',
              },
              {
                onClick: () =>
                  history.push(`/JobPortal/JobPortalForm`, {
                    ...state,
                  }),
                name: 'Apply Now',
                color: 'primary',
              },
            ]}
          />

          <SnackBarMsg
            open={openSnackBar}
            message={snackBarMessage}
            externalDisplay
          />
        </>
      )}
    </>
  )
}
