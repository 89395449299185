import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Grid, ListItem, ListItemText, TextField } from '@material-ui/core'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { Status } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import NumberFormat from 'react-number-format'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import uuid from 'uuid'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { lightenColor } from 'containers/helper/ColorConverter'
import useInputStyles from '../../CustomInputStyles'

interface ReferenceForm {
  Name: string
  MobileNo: string
  Email: string
  Relationship: string
  YearsKnown: number
  JobDesignation: string
  CompanyName: string
}

export const ReferenceForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelReferenceID = state?.ReferenceID
  const ReferenceInfo = state?.ListingData?.find(
    x => x?.section_name === 'References'
  )?.DataList
  const CurrentInfo = ReferenceInfo?.find(
    x => x?.PersonnelReferenceID === PersonnelReferenceID
  )
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<ReferenceForm>({
    defaultValues: {
      Name: CurrentInfo?.Name || '',
      MobileNo: CurrentInfo?.MobileNo || '',
      Email: CurrentInfo?.Email || '',
      Relationship: CurrentInfo?.Relationship || '',
      YearsKnown: CurrentInfo?.YearsKnown || null,
      JobDesignation: CurrentInfo?.JobDesignation || '',
      CompanyName: CurrentInfo?.CompanyName || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  // Function
  const onSubmit = (data: ReferenceForm, addNew: boolean) => {
    setIsDisable(true)
    let input = {
      PersonnelReferenceID: CurrentInfo?.PersonnelReferenceID || uuid(),
      Name: data?.Name,
      MobileNo: data?.MobileNo,
      Email: data?.Email,
      Relationship: data?.Relationship || null,
      YearsKnown: Number(data?.YearsKnown) || null,
      JobDesignation: data?.JobDesignation || null,
      CompanyName: data?.CompanyName || null,
      Status: Status.Active,
    }

    if (mode === 'New') {
      ReferenceInfo?.unshift(input)
    } else if (mode === 'Edit') {
      const indexToEdit = ReferenceInfo?.findIndex(
        x => x?.PersonnelReferenceID === PersonnelReferenceID
      )

      if (indexToEdit !== -1) {
        ReferenceInfo[indexToEdit] = input
      }
    }

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'References') {
          return {
            ...x,
            DataList: ReferenceInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={JobPortal?.theme_color || '#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'References'}
            themeColor={JobPortal?.theme_color || '#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  name={'Name'}
                  label="Name"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Name"
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.Name?.message}
                      error={!!errors.Name}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'MobileNo'}
                  label="Contact No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Contact No."
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.MobileNo?.message}
                      error={!!errors.MobileNo}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                    pattern: {
                      value: /^[+-]?[0-9]*\.?[0-9]+$/,
                      message: 'Invalid Contact No.',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'Email'}
                  label="Email"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Email"
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.Email?.message}
                      error={!!errors.Email}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="Relationship"
                  label="Relationship"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      label="Relationship"
                      value={value}
                      name={name}
                      fullWidth
                      className={`${classes.textField} left`}
                      onChange={e => onChange(e.target.value)}
                    />
                  )}
                />

                <Controller
                  name="YearsKnown"
                  label="Years Known"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <NumberFormat
                      allowNegative={false}
                      customInput={TextField}
                      label={`Years Known`}
                      name={name}
                      min={0}
                      value={value}
                      className={`${classes.textField} right`}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.YearsKnown?.message}
                      error={!!errors.YearsKnown}
                    />
                  )}
                  rules={{
                    validate: value =>
                      value >= 0 || 'Value cannot be less than 0',
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'JobDesignation'}
                  label="Job Position"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Job Position"
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'CompanyName'}
                  label="Company Name"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Company Name"
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                    />
                  )}
                />
              </Grid>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            themeColor={JobPortal?.theme_color}
            options={
              mode === 'Edit'
                ? [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save',
                      color: 'primary',
                      disable: isDisable,
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, true))()
                      },
                      name: 'Save & New',
                      color: 'primary',
                      disable: isDisable,
                    },
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save & Exit',
                      color: 'primary',
                      disable: isDisable,
                    },
                  ]
            }
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
            sections={{
              header: {
                children: (
                  <ListItem
                    className="remove-padding"
                    style={{
                      backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: JobPortal?.theme_color || '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
