import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import {
  BloodType,
  DietaryPreference,
  DocumentType,
  PersonnelParameterType,
  Status,
  useRecruitmentParameterListingQuery,
  useDataUpdateHealthInfoLazyQuery,
  useDataUpdateHealthInfoQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import NumberFormat from 'react-number-format'
import {
  AddBox,
  AttachFile,
  IndeterminateCheckBox,
  MoreVert,
} from '@material-ui/icons'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { useMenuOption } from 'containers/helper/hooks/useMenuOption'
import { dateConvert } from 'containers/helper/formatDate'
import { isSameDay } from 'date-fns'
import uuid from 'uuid'

interface HealthInfoForm {
  Height: string
  Weight: string
  BMI: string
  Diet: string
  BloodType: string
  IsSmoker: boolean
}

export const HealthInfoForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const requiredField = 'This field is required'
  const mode = state?.mode
  const SubscriptionInfo = state?.SubscriptionInfo
  const HealthInfo = state?.ListingData?.find(
    x => x?.section_name === 'Health Info'
  )?.DataList

  const BloodTypes = Object.values(BloodType).map(i => {
    return i.replace(/_/g, ' ')
  })

  const DietaryPreferences = Object.values(DietaryPreference).map(i => {
    return i.replace(/_/g, ' ')
  })

  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
    register,
    reset,
  } = useForm<any>({
    defaultValues: {
      Height: HealthInfo?.Health?.Height || null,
      Weight: HealthInfo?.Health?.Weight || null,
      BMI: HealthInfo?.Health?.BMI || null,
      Diet: HealthInfo?.Health?.DietaryPreference || '',
      BloodType: HealthInfo?.Health?.BloodType || '',
      IsSmoker: HealthInfo?.Health?.IsSmoker || false,
    },
  })

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState('')
  const [vaccinationDialog, setVaccinationDialog] = useState('')
  const [medicalRecordsDialog, setMedicalRecordsDialog] = useState(false)
  const [attachmentToRemove, setAttachmentToRemove] = useState(
    HealthInfo?.AttachmentToRemove || []
  )
  const [isDisable, setIsDisable] = useState(false)
  const [VaccinationListing, setVaccinationListing] = useState([])
  const [vaccination, setVaccination] = useState(
    HealthInfo?.VaccinationRecordInput || []
  )
  const [currentVaccination, setCurrentVaccination] = useState(null)
  const [medicalRecords, setMedicalRecords] = useState(
    HealthInfo?.MedicalRecordInput || {
      DataList: [],
      DeleteList: [],
    }
  )
  const [currentMedicalRecords, setCurrentMedicalRecords] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })

  // Query
  const {
    data: { RecruitmentParameterListing } = { RecruitmentParameterListing: [] },
    loading: RecruitmentParameterListingLoading,
  } = useRecruitmentParameterListingQuery({
    fetchPolicy: 'no-cache',
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
      type: [PersonnelParameterType.VaccinationType],
    },
    onCompleted: data => {
      if (data?.RecruitmentParameterListing) {
        setVaccinationListing(data?.RecruitmentParameterListing)
      }
    },
  })

  const [
    getDataUpdateHealthInfo,
    {
      data: { DataUpdateHealthInfo } = { DataUpdateHealthInfo: null },
      loading: DataUpdateHealthInfoLoading,
    },
  ] = useDataUpdateHealthInfoLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ContactID: state?.ListingData?.find(
        x => x?.section_name === 'Personal Info'
      )?.DataList?.Contact?.ContactID,
    },
    onCompleted: data => {
      const result = data?.DataUpdateHealthInfo

      if (result) {
        if (
          result?.VaccinationListSummary?.length > 0 &&
          vaccination?.length === 0 &&
          attachmentToRemove?.length === 0
        ) {
          const list = result?.VaccinationListSummary?.map(x => {
            if (!x?.vaccinationAttachedment?.DocumentID) {
              return {
                ...x,
                DocumentID: x?.vaccinationAttachedment?.DocumentID,
                vaccinationAttachedment: null,
              }
            } else {
              return {
                ...x,
                DocumentID: x?.vaccinationAttachedment?.DocumentID,
              }
            }
          })

          setVaccination(list)
        }

        if (
          result?.MedicalRecords?.length > 0 &&
          medicalRecords?.DataList?.length === 0 &&
          medicalRecords?.DeleteList?.length === 0
        ) {
          setMedicalRecords({
            DataList: result?.MedicalRecords,
            DeleteList: [],
          })
        }
      }
    },
  })

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false, null)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (!state?.isHealthInfo) {
      getDataUpdateHealthInfo({
        variables: {
          ContactID: state?.ListingData?.find(
            x => x?.section_name === 'Personal Info'
          )?.DataList?.Contact?.ContactID,
        },
      })
    }
  }, [state?.isHealthInfo])

  // Function
  const onSubmit = (data: HealthInfoForm) => {
    setIsDisable(true)

    let healthInfoInput = {
      Height: Number(data?.Height),
      Weight: Number(data?.Weight),
      BMI: Number(data?.BMI),
      BloodType: Object.values(BloodType).find(
        i => i === data?.BloodType?.replace(/ /g, '_')
      ),
      DietaryPreference: Object.values(DietaryPreference).find(
        i => i === data?.Diet?.replace(/ /g, '_')
      ),
      IsSmoker: data?.IsSmoker,
    }

    let MedicalRecordInput = medicalRecords

    let VaccinationRecordInput = vaccination

    let AttachmentToRemove = attachmentToRemove

    snackBar('Saved Successfully!', true, {
      Health: healthInfoInput,
      MedicalRecordInput,
      VaccinationRecordInput,
      AttachmentToRemove,
    })
  }

  const handleSubmitMedicalRecords = () => {
    if (selectedFile?.files?.length > 0) {
      let updated = [
        ...medicalRecords?.DataList,
        {
          MedicalRecordID: uuid(),
          DocumentInput: {
            DocumentType: DocumentType.Employee,
            Attachment: selectedFile?.files[0],
            Description: selectedFile?.files[0]
              ? selectedFile?.files[0]?.name
              : '',
            FileSize: selectedFile?.files[0]?.size,
            Status: Status.Active,
          },
        },
      ]

      setMedicalRecords(prev => ({
        ...prev,
        DataList: updated,
      }))
      setMedicalRecordsDialog(false)
    }
  }

  const handleDeleteMedicalRecords = () => {
    let updated = null

    const indexToDelete = medicalRecords?.DataList?.findIndex(
      x => x?.MedicalRecordID === currentMedicalRecords?.MedicalRecordID
    )

    if (indexToDelete !== -1) {
      const existingData = DataUpdateHealthInfo?.MedicalRecords?.find(
        x => x?.MedicalRecordID === currentMedicalRecords?.MedicalRecordID
      )

      if (existingData) {
        medicalRecords?.DeleteList?.push({
          ...medicalRecords?.DataList[indexToDelete],
          Status: Status.Inactive,
        })
      }

      updated = medicalRecords?.DataList?.filter(
        (_, index) => index !== indexToDelete
      )

      setMedicalRecords(prev => ({
        ...prev,
        DataList: updated,
      }))

      setDeleteDialog('')
    }
  }

  const handleSubmitVaccination = () => {
    let DocumentInput = null
    clearErrors('VaccinationDate')
    clearErrors('VaccinationType')

    if (!getValues('VaccinationType')) {
      setError('VaccinationType', {
        type: 'required',
        message: requiredField,
      })
    }

    if (!getValues('VaccinationDate')) {
      setError('VaccinationDate', {
        type: 'required',
        message: requiredField,
      })
    }

    if (selectedFile?.files?.length > 0) {
      DocumentInput = {
        DocumentType: DocumentType.Employee,
        Attachment: selectedFile?.files[0],
        Description: selectedFile?.files[0] ? selectedFile?.files[0]?.name : '',
        FileSize: selectedFile?.files[0]?.size,
        Status: Status.Active,
      }
    }

    if (vaccinationDialog === 'New') {
      if (currentVaccination?.vaccinationID) {
        const exist = vaccination?.filter(x =>
          isSameDay(
            new Date(currentVaccination?.vaccinationDate),
            new Date(x?.vaccinationDate)
          )
        )

        if (exist?.length > 0) {
          setError('VaccinationDate', {
            type: 'validate',
            message: 'Date must be different with other vaccination type!',
          })

          return
        }

        const updated = [
          ...vaccination,
          {
            vaccinationID: currentVaccination?.vaccinationID,
            vaccinationDate: currentVaccination?.vaccinationDate,
            vaccinationAttachedment: DocumentInput,
          },
        ]

        setVaccination(updated)
        setCurrentVaccination(null)
        setVaccinationDialog('')
      }
    } else if (vaccinationDialog === 'Edit') {
      const editVaccinationList = vaccination?.filter(
        (_, index) => vaccination[menu?.index] !== vaccination[index]
      )

      let exist = null
      if (editVaccinationList?.length > 0) {
        exist = editVaccinationList?.filter(x =>
          isSameDay(
            new Date(currentVaccination?.vaccinationDate),
            new Date(x?.vaccinationDate)
          )
        )
      }

      if (exist?.length > 0) {
        setError('VaccinationDate', {
          type: 'validate',
          message: 'Date must be different with other vaccination type!',
        })

        return
      }

      vaccination[menu?.index].vaccinationID = currentVaccination?.vaccinationID
      vaccination[menu?.index].vaccinationDate =
        currentVaccination?.vaccinationDate

      vaccination[menu?.index].vaccinationAttachedment = selectedFile?.files[0]
        ?.BucketFileName
        ? selectedFile?.files[0]
        : DocumentInput

      setVaccinationDialog('')
      setCurrentVaccination(null)
    }
  }

  const handleDeleteVaccination = () => {
    if (menu?.obj?.vaccinationAttachedment?.DocumentID) {
      setAttachmentToRemove(prev => [
        ...prev,
        { ...menu?.obj?.vaccinationAttachedment },
      ])
    }

    const updated = vaccination?.filter((_, index) => index !== menu?.index)

    setVaccination(updated)
    setDeleteDialog('')
  }

  const currentAttachmentFile = (file, indexNum) => {
    if (
      vaccination?.filter(
        (x, index) => x?.vaccinationAttachedment !== null && index === indexNum
      )
    ) {
      if (file === null) {
        setSelectedFile({ files: [] })
      } else {
        setSelectedFile({ files: [file] })
      }
    }
  }

  const calculateBMI = () => {
    let bmiHeight = watch('Height')
    let bmiWeight = watch('Weight')

    if (!(bmiHeight && bmiWeight)) {
      setValue('BMI', '0.0')
      return
    }

    let bmi =
      (Number(bmiWeight) / Number(bmiHeight) / Number(bmiHeight)) * 10000 || 0
    setValue('BMI', bmi?.toFixed(1))
  }

  const onChangeFile = event => {
    const file = event.target.files[0]

    if (!file) return

    let nextState = { ...selectedFile }
    nextState.files.splice(0, nextState.files.length)
    nextState.files.push(file)
    setSelectedFileName(file?.name)
    setSelectedFile(nextState)
  }

  const snackBar = (message: string, redirect: boolean, updatedData: any) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
          ListingData: state?.ListingData?.map(x => {
            if (x?.section_name === 'Health Info') {
              return {
                ...x,
                DataList: updatedData,
              }
            }

            return x
          }),
        })
      }
    }, 2000)
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={'#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'Health Info'}
            themeColor={'#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  name="Height"
                  label="Height (cm)"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <NumberFormat
                      allowNegative={false}
                      customInput={TextField}
                      label={`Height (cm)`}
                      name={name}
                      required
                      min={1}
                      autoComplete="off"
                      value={value}
                      className={`left`}
                      onValueChange={e => {
                        onChange(e.value)
                        calculateBMI()
                      }}
                      helperText={
                        errors?.Height?.message ||
                        (value &&
                          value <= 0 &&
                          'Value cannot be less than or equal to 0')
                      }
                      error={!!errors?.Height || (value && value <= 0)}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                    validate: value =>
                      value > 0 || 'Value cannot be less than or equal to 0',
                  }}
                />

                <Controller
                  name="Weight"
                  label="Weight (cm)"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <NumberFormat
                      allowNegative={false}
                      customInput={TextField}
                      label={`Weight (kg)`}
                      name={name}
                      required
                      min={1}
                      autoComplete="off"
                      value={value}
                      className={`right`}
                      onValueChange={e => {
                        onChange(e.value)
                        calculateBMI()
                      }}
                      helperText={
                        errors?.Weight?.message ||
                        (value &&
                          value <= 0 &&
                          'Value cannot be less than or equal to 0')
                      }
                      error={!!errors?.Weight || (value && value <= 0)}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                    validate: value =>
                      value > 0 || 'Value cannot be less than or equal to 0',
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'BMI'}
                  label="BMI"
                  control={control}
                  render={({ name, value }) => (
                    <TextField
                      name={name}
                      label="BMI"
                      fullWidth
                      value={value ?? '0.0'}
                      disabled
                      className={`left`}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        style: {
                          color: 'black',
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="BloodType"
                  label="BloodType"
                  required
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="BloodType"
                      value={value}
                      name={name}
                      fullWidth
                      className={`right`}
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.BloodType?.message}
                      error={!!errors.BloodType}
                    >
                      {BloodTypes.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="Diet"
                  label="Dietary Preference"
                  required
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Dietary Preference"
                      value={value}
                      name={name}
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.Diet?.message}
                      error={!!errors.Diet}
                    >
                      {DietaryPreferences.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  control={control}
                  name="IsSmoker"
                  render={({ name, value, onChange }) => (
                    <FormControl
                      component="fieldset"
                      error={!!errors?.IsSmoker}
                      fullWidth
                    >
                      <FormLabel style={{ fontSize: '12px' }}>
                        Smoker Status
                      </FormLabel>
                      <RadioGroup
                        name={name}
                        value={
                          value === null ? '' : value ? 'Smoker' : 'Non-smoker'
                        }
                        onChange={e => {
                          onChange(e.target.value === 'Smoker')
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6} className="left">
                            <FormControlLabel
                              value={'Non-smoker'}
                              control={<Radio color="primary" />}
                              label={'Non-smoker'}
                            />
                          </Grid>

                          <Grid item xs={6} className="right">
                            <FormControlLabel
                              value={'Smoker'}
                              control={<Radio color="primary" />}
                              label={'Smoker'}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      {errors?.IsSmoker && (
                        <FormHelperText error>
                          {errors?.IsSmoker?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </CardContents>

            <CardContents
              section={{
                header: {
                  title: 'Vaccination',
                },
              }}
            >
              <>
                <List className="core-list" style={{ width: '100%' }}>
                  {vaccination?.length > 0 &&
                    vaccination?.map((el, index) => (
                      <ListItem key={index}>
                        {/* {`${index + 1}. `} */}
                        <ListItemText
                          primary={
                            <>
                              <span className="xsTitle">
                                {
                                  VaccinationListing?.find(
                                    x =>
                                      x?.PersonnelParameterID ===
                                      el?.vaccinationID
                                  )?.Name
                                }
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              <div className="extra-row">
                                {el?.vaccinationAttachedment?.Attachment ||
                                el?.DocumentID ? (
                                  <AttachFile
                                    style={{ fontSize: 'small' }}
                                    color="primary"
                                    onClick={() => {
                                      if (
                                        el?.vaccinationAttachedment
                                          ?.DocumentFile
                                      ) {
                                        window.open(
                                          el?.vaccinationAttachedment
                                            ?.DocumentFile,
                                          '_blank'
                                        )
                                      } else {
                                        window.open(
                                          URL.createObjectURL(
                                            el?.vaccinationAttachedment
                                              ?.Attachment
                                          )
                                        )
                                      }
                                    }}
                                  />
                                ) : (
                                  <AttachFile
                                    style={{
                                      fontSize: 'small',
                                      fill: 'grey',
                                    }}
                                  />
                                )}
                                &nbsp;
                                <span className="desc">
                                  {dateConvert(el?.vaccinationDate)}
                                </span>
                              </div>
                            </>
                          }
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={e => {
                              handleClick(e, el?.vaccinationID, index, el)
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>

                <Grid item xs={12} style={{ marginBottom: '16px' }}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        color: '#FF9800',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setVaccinationDialog('New')
                        setCurrentVaccination({
                          vaccinationDate: new Date(),
                          vaccinationID: null,
                          vaccinationAttachedment: null,
                        })
                        setSelectedFile({ files: [] })
                        setSelectedFileName('')
                      }}
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </>
            </CardContents>

            <CardContents
              section={{
                header: {
                  title: 'Medical Records',
                },
              }}
            >
              <>
                <List className="core-list" style={{ width: '100%' }}>
                  {medicalRecords?.DataList?.length > 0 &&
                    medicalRecords?.DataList?.map((el, index) => (
                      <ListItem key={index}>
                        {/* {`${index + 1}. `} */}
                        <ListItemText
                          primary={
                            <>
                              <div className="extra-row">
                                {(el?.DocumentInput?.Attachment ||
                                  el?.Document) && (
                                  <AttachFile
                                    style={{ fontSize: 'small' }}
                                    color="primary"
                                    onClick={() => {
                                      if (el?.Document?.DocumentFile) {
                                        window.open(
                                          el?.Document?.DocumentFile,
                                          '_blank'
                                        )
                                      } else {
                                        window.open(
                                          URL.createObjectURL(
                                            el?.DocumentInput?.Attachment
                                          )
                                        )
                                      }
                                    }}
                                  />
                                )}
                                &nbsp;
                                <span className="desc">
                                  {el?.DocumentInput?.Description ||
                                    el?.Description}
                                </span>
                              </div>
                            </>
                          }
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setDeleteDialog('Medical Records')
                              setCurrentMedicalRecords(el)
                            }}
                          >
                            <IndeterminateCheckBox
                              style={{
                                color: 'red',
                                width: '26px',
                                height: '26px',
                                marginTop: '-2px',
                              }}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>

                <Grid item xs={12} style={{ marginBottom: '16px' }}>
                  <div
                    style={{
                      paddingTop: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      className="mdDesc"
                      style={{
                        color: '#FF9800',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setMedicalRecordsDialog(true)
                        setSelectedFile({ files: [] })
                        setSelectedFileName('')
                      }}
                    >
                      Click to Add
                    </span>
                  </div>
                </Grid>
              </>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            options={[
              {
                onClick: () => {
                  handleSubmit(onSubmit)()
                },
                name: 'Save',
                color: 'primary',
                disabled: isDisable,
              },
            ]}
          />

          <Menu
            disableScrollLock={true}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClick={handleClose}
          >
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setVaccinationDialog('Edit')
                setCurrentVaccination({
                  vaccinationDate: menu?.obj?.vaccinationDate,
                  vaccinationID: menu?.obj?.vaccinationID,
                })
                setSelectedFileName(
                  menu?.obj?.vaccinationAttachedment?.Description
                )
                setSelectedFile({ files: [] })
                currentAttachmentFile(
                  menu?.obj?.vaccinationAttachedment,
                  menu?.index
                )
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setDeleteDialog('Vaccination')
              }}
            >
              Delete
            </MenuItem>
          </Menu>

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={vaccinationDialog === 'New' || vaccinationDialog === 'Edit'}
            onClose={() => {
              setVaccinationDialog('')
              setCurrentVaccination(null)
            }}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            Vaccination
                          </span>

                          <span
                            className="smTitle"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            {vaccinationDialog}
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        name="VaccinationDate"
                        control={control}
                        defaultValue={
                          currentVaccination?.vaccinationDate || new Date()
                        }
                        render={({ onChange, value, name }) => (
                          <KeyboardDatePicker
                            required
                            helperText={errors.VaccinationDate?.message}
                            error={!!errors.VaccinationDate}
                            label="Vaccination Date"
                            format="dd MMM yyyy"
                            onChange={date => {
                              onChange(date)

                              const updated = {
                                ...currentVaccination,
                                vaccinationDate: date,
                              }
                              setCurrentVaccination(updated)
                            }}
                            value={value}
                            name={name}
                            fullWidth
                            margin="normal"
                            maxDate={new Date()}
                            autoComplete="off"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        )}
                        rules={{
                          required: { value: true, message: requiredField },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="VaccinationType"
                      label="Vaccination Type"
                      required
                      control={control}
                      ref={register}
                      defaultValue={currentVaccination?.vaccinationID || null}
                      render={({ onChange, value, name }) => (
                        <TextField
                          select
                          label="Vaccination Type"
                          value={value}
                          name={name}
                          fullWidth
                          required
                          margin="normal"
                          onChange={e => {
                            onChange(e.target.value)

                            const updated = {
                              ...currentVaccination,
                              vaccinationID: e.target.value,
                            }
                            setCurrentVaccination(updated)
                          }}
                          helperText={errors.VaccinationType?.message}
                          error={!!errors.VaccinationType}
                        >
                          {VaccinationListing.map((el, index) => (
                            <MenuItem
                              key={index}
                              value={el?.PersonnelParameterID}
                            >
                              {el?.Name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      rules={{
                        required: { value: true, message: requiredField },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="VaccinationAttachment"
                      label="Vaccination Attachment"
                      control={control}
                      ref={register}
                      render={({ name, onChange }) => (
                        <InputUploadAllType
                          value={selectedFileName}
                          label="Vaccination Attachment"
                          handleUpload={e => {
                            onChangeFile(e)

                            if (menu?.obj?.DocumentID) {
                              const updated = [
                                ...attachmentToRemove,
                                { ...menu?.obj?.vaccinationAttachedment },
                              ]

                              setAttachmentToRemove(updated)
                            }

                            const file = e.target.files[0]
                            if (file) {
                              onChange(file)
                            }
                          }}
                          fullWidth
                          margin="normal"
                          autoComplete="off"
                          name={name}
                        />
                      )}
                    />
                  </Grid>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setVaccinationDialog('')
                        setCurrentVaccination(null)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => handleSubmitVaccination(),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={medicalRecordsDialog}
            onClose={() => {
              setMedicalRecordsDialog(false)
            }}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            Medical Records
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <Grid item xs={12}>
                    <Controller
                      name="Attachment"
                      label="Attachment"
                      control={control}
                      ref={register}
                      render={({ name, onChange }) => (
                        <InputUploadAllType
                          value={selectedFileName}
                          label="Attachment"
                          handleUpload={e => {
                            onChangeFile(e)

                            const file = e.target.files[0]
                            if (file) {
                              onChange(file)
                            }
                          }}
                          fullWidth
                          margin="normal"
                          autoComplete="off"
                          name={name}
                        />
                      )}
                    />
                  </Grid>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setMedicalRecordsDialog(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => handleSubmitMedicalRecords(),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <CommonDialog
            fullWidth={true}
            open={
              deleteDialog === 'Vaccination' ||
              deleteDialog === 'Medical Records'
            }
            onClose={() => setDeleteDialog('')}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div className="session">
                        <div className="flex session">
                          <div className="title flex-space">{deleteDialog}</div>

                          <span className="title">Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  {deleteDialog === 'Vaccination' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div className="content-wrap full">
                        <span className="desc" style={{ color: 'grey' }}>
                          Vaccination Type
                        </span>
                        <div className="mdDesc" style={{ lineHeight: 1.2 }}>
                          {
                            VaccinationListing?.find(
                              x =>
                                x?.PersonnelParameterID ===
                                menu?.obj?.vaccinationID
                            )?.Name
                          }
                        </div>
                      </div>

                      <div className="content-wrap full">
                        <span className="desc" style={{ color: 'grey' }}>
                          Vaccination Date
                        </span>
                        <div className="mdDesc" style={{ lineHeight: 1.2 }}>
                          {dateConvert(menu?.obj?.vaccinationDate)}
                        </div>
                      </div>

                      <div className="content-wrap full">
                        <span className="desc" style={{ color: 'grey' }}>
                          Vaccination Attachment
                        </span>
                        <div className="mdDesc" style={{ lineHeight: 1.2 }}>
                          {menu?.obj?.vaccinationAttachedment?.Description ??
                            '-'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div className="content-wrap full">
                        <span className="desc" style={{ color: 'grey' }}>
                          Medical Records
                        </span>
                        <div className="mdDesc" style={{ lineHeight: 1.2 }}>
                          {currentMedicalRecords?.DocumentInput?.Description ||
                            currentMedicalRecords?.Description ||
                            '-'}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mdDesc">Are you sure you want to delete?</div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setDeleteDialog('')
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },

                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => {
                        if (deleteDialog === 'Vaccination') {
                          handleDeleteVaccination()
                        } else if (deleteDialog === 'Medical Records') {
                          handleDeleteMedicalRecords()
                        }
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <SnackBarMsg
            open={openSnackBar}
            message={snackBarMessage}
            externalDisplay
          />
        </>
      )}
    </>
  )
}
