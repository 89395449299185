import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccCurrentMonthPreviousPayrollCycleOutput = {
  __typename?: 'AccCurrentMonthPreviousPayrollCycleOutput';
  EmployeeID: Scalars['String'];
  TotalExemptNormalRemunerationAmount: Scalars['Float'];
  TotalExemptAdditionalRemunerationAmount: Scalars['Float'];
  TotalChargableTax1: Scalars['Float'];
  TotalChargableTax2: Scalars['Float'];
  TotalChargableEPF: Scalars['Float'];
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  EPFPaidByNormalRemunerationPaid: Scalars['Float'];
  EPFPaidByAdditionalRemuneration: Scalars['Float'];
  TotalChargableEPFVE: Scalars['Float'];
  TotalChargableSocso: Scalars['Float'];
  TotalChargableEIS: Scalars['Float'];
  TotalChargableHRDF: Scalars['Float'];
  EmployeeEPF: Scalars['Float'];
  EmployerEPF: Scalars['Float'];
  EmployeeEPFVE: Scalars['Float'];
  EmployerEPFVE: Scalars['Float'];
  TotalChargableTax: Scalars['Float'];
  TotalChargableAddRemuneration: Scalars['Float'];
  Tax: Scalars['Float'];
  Zakat: Scalars['Float'];
  EmployeeEIS: Scalars['Float'];
  EmployerEIS: Scalars['Float'];
  EmployeeSocso: Scalars['Float'];
  EmployerSocso: Scalars['Float'];
};

export type AcceptedPreHiringInput = {
  org_unit_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id: Scalars['String'];
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status: PrehiringStatus;
  data_update_status?: Maybe<DataUpdateStatus>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  remark?: Maybe<Scalars['String']>;
};

export type AccessControl = {
  __typename?: 'AccessControl';
  ID: Scalars['Float'];
};

export type AccessControlInput = {
  ID: Scalars['Float'];
};

export type AccumulatedPreviousMonthOutput = {
  __typename?: 'AccumulatedPreviousMonthOutput';
  AccEPFPaid: Scalars['Float'];
  AccEPFVEPaid: Scalars['Float'];
  AccNormalRemunerationEPFPaid: Scalars['Float'];
  AccAdditionalRemunerationEPFPaid: Scalars['Float'];
  AccPCBPaid: Scalars['Float'];
  AccZakatPaid: Scalars['Float'];
  AccGrossTaxablePaid: Scalars['Float'];
  AccSocsoPaid: Scalars['Float'];
  AccEISPaid: Scalars['Float'];
  AccTaxPaid: Scalars['Float'];
  AccTaxRelief: Scalars['Float'];
};

export type ActivityListJson = {
  __typename?: 'ActivityListJson';
  Description?: Maybe<Scalars['String']>;
};

export type ActivityListJsonInput = {
  Description?: Maybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type Address2 = {
  __typename?: 'Address2';
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput2 = {
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdhocSetupDetails = {
  EmployeeIDs: Array<Scalars['String']>;
  SuperiorReviewFormTemplateID: Scalars['String'];
  SuperiorSelfReviewFormTemplateID: Scalars['String'];
  PeerReviewFormTemplateID: Scalars['String'];
  SubordinateReviewFormTemplateID: Scalars['String'];
};

export type AdminExpenseFilterInput = {
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  TrainingCourse?: Maybe<Array<Scalars['JSON']>>;
};

export type AdminRecruitmentSummaryOutput = {
  __typename?: 'AdminRecruitmentSummaryOutput';
  ApprovalPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  ExpenseTotal?: Maybe<Scalars['Float']>;
  JobPortalTotal?: Maybe<Scalars['Float']>;
  EApplicationFormSetupLastUpdated?: Maybe<Scalars['DateTime']>;
  PrehiringFormSetupLastUpdated?: Maybe<Scalars['DateTime']>;
  OfferChecklistTotal?: Maybe<Scalars['Float']>;
  ELetterLastUpdated?: Maybe<Scalars['DateTime']>;
  AdditionalFieldsTotal?: Maybe<Scalars['Float']>;
  DeclineReasonTotal?: Maybe<Scalars['Float']>;
};

export type AdvancePolicyAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  AdvancePolicy: AdvancePolicyEntity;
};

export type AdvancePolicyConditionEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyConditionEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyConditionID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  ConditionList: Scalars['JSON'];
  AdvancePolicy: AdvancePolicyEntity;
  Sequence: Scalars['Float'];
};

export type AdvancePolicyEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  AdvancePolicyCondition?: Maybe<Array<AdvancePolicyConditionEntity>>;
  InUse: Scalars['Boolean'];
  PaymentItem: PayItemEntity;
  PaymentCycle: PayrollCycleEntity;
  DeductionItem: PayItemEntity;
  DeductionCycle: PayrollCycleEntity;
};

export type AdvancePolicyInput = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  ConditionList: Array<PayrollConditionListInput>;
  UserLogs: Array<UserLogInput>;
};

export type AdvanceProcessingDetailEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingDetailEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingDetailID: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  PaymentType: PayItemType;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemID: Scalars['String'];
  Amount: Scalars['Float'];
  OneTimePayID?: Maybe<Scalars['String']>;
  AdvanceProcessing: AdvanceProcessingEntity;
  PeriodMonth: Scalars['String'];
  PayrollCycle: PayrollCycleEntity;
  PayItem: PayItemEntity;
  IsModified?: Maybe<Scalars['JSON']>;
};

export type AdvanceProcessingEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  IsPosted?: Maybe<Scalars['Boolean']>;
  AdvanceProcessingDetail?: Maybe<Array<AdvanceProcessingDetailEntity>>;
  Employee: EmployeeEntity;
  AdvancePolicyName: Scalars['String'];
  IsProcessed: Scalars['Boolean'];
};

export type AllowanceBenefitInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AllowanceBenfitEntity = InterfaceBase & {
  __typename?: 'AllowanceBenfitEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceBenefitID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Company: CompanyEntity;
};

export type AllowanceDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Name?: Maybe<Scalars['String']>;
};

export type AllowanceDeductionRoundingEntity = InterfaceBase & {
  __typename?: 'AllowanceDeductionRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  DailyAllowance?: Maybe<Array<DailyAllowancePolicyEntity>>;
  MonthlyAllowance?: Maybe<Array<MonthlyAllowancePolicyEntity>>;
  PeriodicDeduction?: Maybe<Array<PeriodicDeductionPolicyEntity>>;
};

export enum AllowanceFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Lifetime = 'Lifetime'
}

export type AllowanceItemEntity = InterfaceBase & {
  __typename?: 'AllowanceItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceItemID: Scalars['String'];
  AllowanceBenefitID: Scalars['String'];
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  AllowanceFrequency: AllowanceFrequency;
  TotalAmount: Scalars['Float'];
  AllowanceBenefit: AllowanceBenfitEntity;
};

export type AllowanceItemInput = {
  AllowanceBenefitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  AllowanceFrequency?: Maybe<AllowanceFrequency>;
  TotalAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AnnouncementDashboardCardOutput = {
  __typename?: 'AnnouncementDashboardCardOutput';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ApplicantStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentApplicationStage>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type AppraisalCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategoryItems?: Maybe<Array<AppraisalCategoryItemEntity>>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalCategoryInput = {
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID: Scalars['String'];
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<Scalars['String']>;
  QuantitativeType?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalCategoryItemInput = {
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<KpiType>;
  QuantitativeType?: Maybe<QuantitativeType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCompanySettingEntity = InterfaceBase & {
  __typename?: 'AppraisalCompanySettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalPolicy?: Maybe<AppraisalPolicyEntity>;
  AppraisalTempleteSetting?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalCompanySettingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalEmployeeLisitngOutput = InterfaceBase & {
  __typename?: 'AppraisalEmployeeLisitngOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  ContractDueDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  AppraisalStatus?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubordinateAppraisalHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export type AppraisalPolicyEntity = InterfaceBase & {
  __typename?: 'AppraisalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalPolicyID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalPolicyInput = {
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleDetailEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID: Scalars['String'];
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeader?: Maybe<AppraisalScheduleHeaderEntity>;
};

export type AppraisalScheduleDetailInput = {
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  AppraisalScheduleDetails?: Maybe<Array<AppraisalScheduleDetailEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalScheduleHeaderInput = {
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<AppraisalScheduleDetailInput>>;
};

export type AppraisalTemplateHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalTemplateHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalTempleteByJobPositionEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteByJobPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID: Scalars['String'];
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalSchedule?: Maybe<AppraisalScheduleHeaderEntity>;
  KPITemplete?: Maybe<AppraisalTempleteEntity>;
  CompetencyTemplete?: Maybe<AppraisalTempleteEntity>;
  OthersTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalTemplateHeader?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalTempleteByJobPositionHeaderInput = {
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateByJobPositionList?: Maybe<Array<AppraisalTempleteByJobPositionInput>>;
};

export type AppraisalTempleteByJobPositionInput = {
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID: Scalars['String'];
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalTempleteCategoryInput = {
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Items?: Maybe<Array<AppraisalTempleteCategoryItemInput>>;
};

export type AppraisalTempleteCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryItemID: Scalars['String'];
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<AppraisalTempleteCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
};

export type AppraisalTempleteCategoryItemInput = {
  AppraisalTempleteCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalTempleteInput = {
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<AppraisalTempleteCategoryInput>>;
};

export enum AppraisalType {
  Kpi = 'KPI',
  Competency = 'Competency',
  Others = 'Others'
}

export enum AppraiseeType {
  Self = 'Self',
  Superior = 'Superior',
  Subordinate = 'Subordinate',
  Peer = 'Peer',
  SelfAssesment = 'Self_Assesment',
  Employee = 'Employee'
}

export type ApprovalClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ApprovalList = {
  __typename?: 'ApprovalList';
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalList1 = {
  PersonnelManagement?: Maybe<Array<Scalars['JSON']>>;
  LeaveManagement?: Maybe<Array<Scalars['JSON']>>;
  ClaimManagement?: Maybe<Array<Scalars['JSON']>>;
  ProjectExpenseManagement?: Maybe<Array<Scalars['JSON']>>;
  RequisitionManagement?: Maybe<Array<Scalars['JSON']>>;
  TimeAttendance?: Maybe<Array<Scalars['JSON']>>;
  PerformanceManagement?: Maybe<Array<Scalars['JSON']>>;
};

export type ApprovalListInput = {
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Status?: Maybe<WorkflowLogStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
  Description?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  SubscriptionAccountID: Scalars['String'];
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  ApprovalList: Array<ApprovalListJson>;
  ApprovalPolicyAssignment: Array<ApprovalPolicyAssignmentEntity>;
  InUse: Scalars['Boolean'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyMatrixEntity = NewInterfaceBase & {
  __typename?: 'ApprovalPolicyMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  description: Scalars['String'];
  subscription_account_id: Scalars['String'];
  approval_policy_type: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  category?: Maybe<Scalars['String']>;
  approval_policy_id: Scalars['String'];
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
};

export type ApprovalPolicyMatrixInput = {
  approval_policy_matrix_id?: Maybe<Scalars['String']>;
  approval_policy_id?: Maybe<Scalars['String']>;
  approval_policy_type?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
  description?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalProjectExpenseClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export enum ApprovalStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Withdraw = 'Withdraw',
  Withdrawn = 'Withdrawn',
  Submitted = 'Submitted'
}

export type ApproveClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApproveClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ApproveClaimVerificationOutput = {
  __typename?: 'ApproveClaimVerificationOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
};

export type ApproveProjectExpenseClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApprovedClaimDetail = {
  __typename?: 'ApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type ApprovedClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
};

export type ApprovedProjectExpenseClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedProjectExpenseClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type AttachmentInput = {
  Index?: Maybe<Scalars['Float']>;
  Filename?: Maybe<Scalars['String']>;
  Format?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  Index?: Maybe<Scalars['Float']>;
  Info?: Maybe<Scalars['String']>;
};

export type AttendanceAnalysisTemplateEntity = InterfaceBase & {
  __typename?: 'AttendanceAnalysisTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AttendanceAnalysisTemplate?: Maybe<Array<Scalars['JSON']>>;
};

export type AttendanceAnalysisTemplateInput = {
  AttendanceAnalysisID?: Maybe<Scalars['String']>;
  AttendanceAnalysisTemplate?: Maybe<Array<Scalars['JSON']>>;
};

export type AttendanceCalculationEntity = InterfaceBase & {
  __typename?: 'AttendanceCalculationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
};

export type AttendanceCalculationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceCalculationID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  PostStatus?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionDetail = {
  __typename?: 'AttendanceCorrectionDetail';
  date?: Maybe<Scalars['String']>;
  approval_status?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionDetailInput = {
  attendance_correction_id?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
  integration_clock_type?: Maybe<Scalars['String']>;
  check_in_method?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionEntity = NewInterfaceBase & {
  __typename?: 'AttendanceCorrectionEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  attendance_correction_id?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  integration_clock_type?: Maybe<IntegrationClockType>;
  check_in_method?: Maybe<CheckInMethod>;
};

export type AttendanceCorrectionHeaderEntity = NewInterfaceBase & {
  __typename?: 'AttendanceCorrectionHeaderEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  voiceDocument?: Maybe<DocumentsEntity>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  approval_status?: Maybe<ApprovalStatus>;
  remarks?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['JSON']>;
  approver_id?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['String']>;
  approval_dt?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  AttendanceCorrection?: Maybe<Array<AttendanceCorrectionEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type AttendanceCorrectionInput = {
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['JSON']>;
  voice_document_input?: Maybe<DocumentsInput>;
  document_input?: Maybe<Array<DocumentsInput>>;
  attendance_correction_detail?: Maybe<Array<AttendanceCorrectionDetailInput>>;
};

export type AttendanceCorrectionRequestUpdateInput = {
  AttendanceCorrectionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type AttendanceCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'AttendanceCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type AttendanceCutOffPeriodInput = {
  AttendanceCutOffPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AttendanceDashboardCardOutput = {
  __typename?: 'AttendanceDashboardCardOutput';
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  sortType: Scalars['String'];
  description: Scalars['String'];
  ClockingRecord?: Maybe<Scalars['JSON']>;
};

export type AttendanceDetailOutput = {
  __typename?: 'AttendanceDetailOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['String']>;
  Long?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendancePostInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  EmployeePayItemIDPairs?: Maybe<Array<AttendanceCalculationInput>>;
};

export type AttendancePostingOutput = InterfaceBase & {
  __typename?: 'AttendancePostingOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
};

export type AttendanceProcessingBatchEntity = {
  __typename?: 'AttendanceProcessingBatchEntity';
  AttendanceProcessingBatchID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  Errorlog?: Maybe<Array<Scalars['JSON']>>;
  CompletedTime?: Maybe<Scalars['DateTime']>;
};

export type AttendanceProcessingOutput = {
  __typename?: 'AttendanceProcessingOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  ShiftPatternName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AbsentOccurence?: Maybe<Scalars['Float']>;
  LateInOccurence?: Maybe<Scalars['Float']>;
  EarlyOutOccurence?: Maybe<Scalars['Float']>;
  ImcompleteClockingOccurence?: Maybe<Scalars['Float']>;
  LeaveCount?: Maybe<Scalars['Float']>;
};

export type AttendanceRecordEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WearMask?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  DateTime?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordHikVisionEntity = {
  __typename?: 'AttendanceRecordHikVisionEntity';
  ID?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['String']>;
  AccessDate?: Maybe<Scalars['String']>;
  AcessTime?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Direction?: Maybe<Scalars['String']>;
  AcessDT?: Maybe<Scalars['String']>;
  Temperature?: Maybe<Scalars['Float']>;
  TemperatureStatus?: Maybe<Scalars['String']>;
  WearMask?: Maybe<Scalars['String']>;
  DeviceSerial?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  DateTime?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordOutput = {
  __typename?: 'AttendanceRecordOutput';
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
};

export type AttendanceRecordPairedEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordPairedEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Array<Scalars['JSON']>>;
  ClockingRecord?: Maybe<Array<Scalars['JSON']>>;
  TimeOffRecord?: Maybe<Array<Scalars['JSON']>>;
  ApprovedOTRecord?: Maybe<Array<Scalars['JSON']>>;
  LeaveTakenRecord?: Maybe<Array<Scalars['JSON']>>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Array<Scalars['JSON']>>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordPairedInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Scalars['JSON']>;
  ClockingRecord?: Maybe<Scalars['JSON']>;
  TimeOffRecord?: Maybe<Scalars['JSON']>;
  ApprovedOTRecord?: Maybe<Scalars['JSON']>;
  LeaveTakenRecord?: Maybe<Scalars['JSON']>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Scalars['JSON']>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
};

export type AttendanceRecordPairedObj = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  OrgunitID?: Maybe<Scalars['String']>;
  PairedData?: Maybe<Array<Scalars['JSON']>>;
};

export enum AttendanceTransactionType {
  Daily = 'Daily',
  Periodic = 'Periodic'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AuthorisedTrainingProviderEntity = InterfaceBase & {
  __typename?: 'AuthorisedTrainingProviderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type AuthorisedTrainingProviderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactInput>;
};

export type AutomatedClockDataImportEntity = {
  __typename?: 'AutomatedClockDataImportEntity';
  AutomatedClockDataImportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AutomatedClockDataImportInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
};

export enum AvailableBasis {
  AnnualEntitlement = 'Annual_Entitlement',
  EarnBasis = 'Earn_Basis',
  NoProration = 'No_Proration'
}

export enum BackdatedOtMethod {
  CalendarDay = 'CALENDAR_DAY',
  WorkingDay = 'WORKING_DAY'
}

export enum Bank {
  AffinBankBerhad = 'Affin_Bank_Berhad',
  AffinIslamicBankBerhad = 'Affin_Islamic_Bank_Berhad',
  AlRajhiBankingInvestmentCorporationMalaysiaBerhad = 'Al_Rajhi_Banking_Investment_Corporation_Malaysia_Berhad',
  AllianceBankMalaysiaBerhad = 'Alliance_Bank_Malaysia_Berhad',
  AllianceIslamicBankBerhad = 'Alliance_Islamic_Bank_Berhad',
  AmBankMBerhad = 'Am_Bank_M_Berhad',
  AmBankIslamicBerhad = 'Am_Bank_Islamic_Berhad',
  BangkokBankBerhad = 'Bangkok_Bank_Berhad',
  BankIslamMalaysiaBerhad = 'Bank_Islam_Malaysia_Berhad',
  BankMuamalatMalaysiaBerhad = 'Bank_Muamalat_Malaysia_Berhad',
  BankOfAmericaMalaysiaBerhad = 'Bank_of_America_Malaysia_Berhad',
  BankOfChinaMalaysiaBerhad = 'Bank_of_China_Malaysia_Berhad',
  BankRakyat = 'Bank_Rakyat',
  BankSimpananNasional = 'Bank_Simpanan_Nasional',
  BankPertanianMalaysiaBerhadAgroBank = 'Bank_Pertanian_Malaysia_Berhad_Agro_Bank',
  BnpParibasMalaysiaBerhad = 'BNP_Paribas_Malaysia_Berhad',
  ChinaConstructionBankMalaysiaBerhad = 'China_Construction_Bank_Malaysia_Berhad',
  CimbBankBerhad = 'CIMB_Bank_Berhad',
  CimbIslamicBankBerhad = 'CIMB_Islamic_Bank_Berhad',
  CitibankBerhad = 'Citibank_Berhad',
  DeutscheBankMalaysiaBerhad = 'Deutsche_Bank_Malaysia_Berhad',
  HongLeongBankBerhad = 'Hong_Leong_Bank_Berhad',
  HongLeongIslamicBankBerhad = 'Hong_Leong_Islamic_Bank_Berhad',
  HsbcAmanahMalaysiaBerhad = 'HSBC_Amanah_Malaysia_Berhad',
  HsbcBankMalaysiaBerhad = 'HSBC_Bank_Malaysia_Berhad',
  IndiaInternationalBankMalaysiaBerhad = 'India_International_Bank_Malaysia_Berhad',
  IndustrialAndCommercialBankOfChinaMalaysiaBerhad = 'Industrial_and_Commercial_Bank_of_China_Malaysia_Berhad',
  JpMorganChaseBank = 'JP_Morgan_Chase_Bank',
  KuwaitFinanceHouseMalaysiaBerhad = 'Kuwait_Finance_House_Malaysia_Berhad',
  MalayanBankingBerhad = 'Malayan_Banking_Berhad',
  MaybankIslamicBerhad = 'Maybank_Islamic_Berhad',
  MbsbBankBerhad = 'MBSB_Bank_Berhad',
  MizuhoBankMalaysiaBerhad = 'Mizuho_Bank_Malaysia_Berhad',
  MufgBankMalaysiaBerhad = 'MUFG_Bank_Malaysia_Berhad',
  OcbcAlAminBankBerhad = 'OCBC_Al_Amin_Bank_Berhad',
  OcbcBankMalaysiaBerhad = 'OCBC_Bank_Malaysia_Berhad',
  PtBankMuamalatIndonesiaTbk = 'PT_Bank_Muamalat_Indonesia_Tbk',
  PublicBankBerhad = 'Public_Bank_Berhad',
  PublicIslamicBankBerhad = 'Public_Islamic_Bank_Berhad',
  RhbBankBerhad = 'RHB_Bank_Berhad',
  RhbIslamicBankBerhad = 'RHB_Islamic_Bank_Berhad',
  StandardCharteredBankMalaysiaBerhad = 'Standard_Chartered_Bank_Malaysia_Berhad',
  StandardCharteredSaadiqBerhad = 'Standard_Chartered_Saadiq_Berhad',
  SumitomoMitsuiBankingCorporationMalaysiaBerhad = 'Sumitomo_Mitsui_Banking_Corporation_Malaysia_Berhad',
  TheBankOfNovaScotiaBerhad = 'The_Bank_of_Nova_Scotia_Berhad',
  UnitedOverseasBankMalaysiaBerhad = 'United_Overseas_Bank_Malaysia_Berhad',
  DbsBankLimited = 'DBS_Bank_Limited'
}

export type BankInfoEntity = InterfaceBase & {
  __typename?: 'BankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BankInfoID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  AccountName: Scalars['String'];
  AccountNo: Scalars['String'];
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  CompanyBank: CompanyEntity;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoInputs = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoOutput = {
  __typename?: 'BankInfoOutput';
  BankInfoID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BasicDashboardCardOutput = {
  __typename?: 'BasicDashboardCardOutput';
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  CompanyName?: Maybe<Scalars['String']>;
  type?: Maybe<DashboardCardType>;
  ObjectDate?: Maybe<Scalars['DateTime']>;
  Due: Scalars['Float'];
};

export type BasicPayGroupListingOutput = {
  __typename?: 'BasicPayGroupListingOutput';
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<Array<UserLogs>>;
  InUse: Scalars['Boolean'];
};

export type BatchEntryByShiftEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Shift?: Maybe<ShiftEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BatchEntryByShiftPatternEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftPatternID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftPatternID: Scalars['String'];
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export enum BatchOvertimeDelegation {
  BatchOvertimeApproval = 'Batch_Overtime_Approval',
  BatchOvertimeCancellationApproval = 'Batch_Overtime_Cancellation_Approval'
}

export type BatchOvertimeDetails = {
  __typename?: 'BatchOvertimeDetails';
  ApprovalStatus?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type BatchOvertimeEntity = InterfaceBase & {
  __typename?: 'BatchOvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  BatchOvertimeStatus: OvertimeStatus;
  OvertimeRecord?: Maybe<Array<Scalars['JSON']>>;
  Overtime?: Maybe<Array<OvertimeEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
};

export type BatchOvertimeInput = {
  OvertimeRecord?: Maybe<Array<Scalars['JSON']>>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BatchOvertimeStatus?: Maybe<OvertimeStatus>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
};

export type BiYearError = {
  __typename?: 'BiYearError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BiYearlyError = {
  __typename?: 'BiYearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BlockLeaveEntity = InterfaceBase & {
  __typename?: 'BlockLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockLeaveID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type BlockLeaveInput = {
  BlockLeaveID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum BloodType {
  OMinus = 'O_Minus',
  OPlus = 'O_Plus',
  AMinus = 'A_Minus',
  APlus = 'A_Plus',
  BMinus = 'B_Minus',
  BPlus = 'B_Plus',
  AbMinus = 'AB_Minus',
  AbPlus = 'AB_Plus'
}

export type BluetoothBeaconDevicesEntity = {
  __typename?: 'BluetoothBeaconDevicesEntity';
  BluetoothBeaconDevicesID: Scalars['String'];
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type BluetoothBeaconDevicesInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
};

export type BonusSimulationEntity = InterfaceBase & {
  __typename?: 'BonusSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BonusSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type BonusSimulationInput = {
  BonusSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BreakTimeEntity = InterfaceBase & {
  __typename?: 'BreakTimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BreakTimeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type: BreakTimeType;
  Start: Scalars['String'];
  End: Scalars['String'];
  ShiftID: Scalars['String'];
  shift?: Maybe<ShiftEntity>;
  ShiftDayID: Scalars['String'];
  shiftDay?: Maybe<ShiftDayEntity>;
};

export type BreakTimeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Type: BreakTimeType;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Start: Scalars['String'];
  End: Scalars['String'];
  BreakTimeID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  dayIndex?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type BreakTimeListInput = {
  Type?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  ShiftDuration?: Maybe<Scalars['Float']>;
  ShiftMinDuration?: Maybe<Scalars['Float']>;
  dayIndex?: Maybe<Scalars['Float']>;
};

export enum BreakTimeType {
  OtBreak = 'OT_BREAK',
  ShiftBreak = 'SHIFT_BREAK',
  OtBreakPrePost = 'OT_BREAK_Pre_Post'
}

export type BudgetGroupEntity = NewInterfaceBase & {
  __typename?: 'BudgetGroupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  TrainingBudgetItems?: Maybe<Array<TrainingBudgetItemsEntity>>;
};

export type BudgetGroupInput = {
  name?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
};

export type BudgetGroupMatrixEntity = NewInterfaceBase & {
  __typename?: 'BudgetGroupMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  budget_group_id: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  description: Scalars['String'];
  BudgetGroup?: Maybe<BudgetGroupEntity>;
};

export type BudgetGroupMatrixInput = {
  budget_group_matrix_id?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
};

export type BusinessInsightClaimTakenOuput = {
  __typename?: 'BusinessInsightClaimTakenOuput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  ClaimTakenByLeaveType?: Maybe<Array<ClaimTakenByLeaveType>>;
};

export type BusinessInsightCompanyOutput = {
  __typename?: 'BusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDepartmentOutput>>;
};

export type BusinessInsightDepartmentOutput = {
  __typename?: 'BusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDivisionOutput>>;
};

export type BusinessInsightDivisionOutput = {
  __typename?: 'BusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessInsightGroupingOutput = {
  __typename?: 'BusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTakenOutput = {
  __typename?: 'BusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTypeOutput = {
  __typename?: 'BusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type BusinessInsightListingOutput = {
  __typename?: 'BusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type BusinessInsightOutput = {
  __typename?: 'BusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollPayItemOutput = {
  __typename?: 'BusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'BusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollStatutoryOutput = {
  __typename?: 'BusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type BusinessInsightSummaryOutput = {
  __typename?: 'BusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<BusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<BusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<BusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<BusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<BusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<BusinessInsightGroupingOutput>>;
};

export type BusinessInsightSummaryPayrollOutput = {
  __typename?: 'BusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<BusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<BusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<BusinessInsightPayrollPayItemOutput>>;
};

export type CcMappingListEntity = InterfaceBase & {
  __typename?: 'CCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export enum CfMethod {
  No = 'No',
  PercentageEntitlement = 'Percentage_Entitlement',
  PercentageBalance = 'Percentage_Balance',
  Day = 'Day'
}

export type Cp159Entity = InterfaceBase & {
  __typename?: 'CP159Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CP159ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
};

export type Cp159Input = {
  CP159ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum CalculationMethod {
  ServiceDay = 'Service_Day',
  ServiceMonth = 'Service_Month'
}

export type CalendarOffDayEmployeeOutput = {
  __typename?: 'CalendarOffDayEmployeeOutput';
  StateID: Scalars['String'];
  EmployeeID: Scalars['String'];
  DesignationName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
};

export type CalendarOffDayEntity = InterfaceBase & {
  __typename?: 'CalendarOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  States?: Maybe<StatesEntity>;
  Employee?: Maybe<EmployeeEntity>;
  TotalEmployee?: Maybe<Scalars['Float']>;
};

export type CalendarOffDayInput = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  StateID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarOffDayOutput = {
  __typename?: 'CalendarOffDayOutput';
  StateID: Scalars['String'];
  Description: Scalars['String'];
  TotalEmployee?: Maybe<Scalars['Float']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type CalendarPolicyEntity = InterfaceBase & {
  __typename?: 'CalendarPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  StateID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  States?: Maybe<StatesEntity>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyDelaredHolidays?: Maybe<Array<CompanyDelaredHolidayEntity>>;
};

export type CalendarPolicyInput = {
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarPolicyOutput = {
  __typename?: 'CalendarPolicyOutput';
  CalendarPolicyID?: Maybe<Scalars['String']>;
  StateID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Array<Scalars['Float']>>;
  Default?: Maybe<Scalars['Boolean']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  CompanyOffDayID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  RestOffDay?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  YearlyHolidaysGroup?: Maybe<Array<Scalars['JSON']>>;
};

export type CareerLogEntity = InterfaceBaseV3 & {
  __typename?: 'CareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ContractDueDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  ToOrgUnitTable?: Maybe<CompanyOrgUnitEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<PayGroupEntity>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
  IsAllowToCancel: Scalars['Boolean'];
  ShiftGroupName: Scalars['String'];
  ReportToSuperiorName: Scalars['String'];
  PrevCompReportToSuperiorName: Scalars['String'];
};

export type CareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
};

export type ChangeShiftEntity = InterfaceBase & {
  __typename?: 'ChangeShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ChangeShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  FromShift?: Maybe<ShiftEntity>;
  FromShiftID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShift?: Maybe<ShiftEntity>;
  ToShiftID?: Maybe<Scalars['String']>;
  ChangeShiftStatus: ChangeShiftStatus;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  RejectReason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ReplacedEmployee?: Maybe<EmployeeEntity>;
  ReplacedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ChangeShiftHistory?: Maybe<Array<ChangeShiftEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ChangeShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ChangeShiftID?: Maybe<Scalars['String']>;
  FromShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShiftID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ChangeShiftStatus?: Maybe<ChangeShiftStatus>;
  ReplacedBy?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
};

export enum ChangeShiftStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type ChangesData = {
  __typename?: 'ChangesData';
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Address2>;
  Address?: Maybe<Address2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<Address2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export type ChangesDataInput = {
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<AddressInput2>;
  Address?: Maybe<AddressInput2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<AddressInput2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export enum CheckInMethod {
  Qr = 'QR',
  Location = 'Location',
  Bluetooth = 'Bluetooth',
  ManualEntry = 'ManualEntry'
}

export type ClaimAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ClaimAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ClaimAccountDetailEntity = InterfaceBase & {
  __typename?: 'ClaimAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  ClaimAccountDetail?: Maybe<Array<ClaimAccountDetailEntity>>;
};

export type ClaimAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderOutput = {
  __typename?: 'ClaimAccountHeaderOutput';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  DeleteDisable?: Maybe<Scalars['Boolean']>;
};

export type ClaimAppDetailsOutput = {
  __typename?: 'ClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ClaimAppOutput = {
  __typename?: 'ClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimAppDetailsOutput>>;
};

export type ClaimBenefitEntity = InterfaceBase & {
  __typename?: 'ClaimBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  InUse: Scalars['Boolean'];
};

export type ClaimBenefitInput = {
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
};

export enum ClaimCategory {
  Normal = 'Normal',
  Mileage = 'Mileage',
  Daily = 'Daily',
  Meal = 'Meal'
}

export type ClaimCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'ClaimCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export enum ClaimDailyFrequency {
  Day = 'Day',
  Night = 'Night'
}

export type ClaimDailyRateEntity = InterfaceBase & {
  __typename?: 'ClaimDailyRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDailyRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Array<ClaimParameterEntity>>;
  dailyRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
  VenueName: Scalars['String'];
};

export type ClaimDailyRateInput = {
  ClaimDailyRateID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDetailListingReportInput = {
  CompanyID: Scalars['String'];
  SelectAllEmp: Scalars['Boolean'];
  ClaimTypeIDs: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  GroupBy: Scalars['String'];
  ApproveStartDate?: Maybe<Scalars['DateTime']>;
  ApproveEndDate?: Maybe<Scalars['DateTime']>;
  CreateStartDate?: Maybe<Scalars['DateTime']>;
  VerifyStartDate?: Maybe<Scalars['DateTime']>;
  VerifyEndDate?: Maybe<Scalars['DateTime']>;
  CreateEndDate?: Maybe<Scalars['DateTime']>;
  ReceiptStartDate?: Maybe<Scalars['DateTime']>;
  ReceiptEndDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimDetails = {
  __typename?: 'ClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ClaimDetailsListing?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimDetailsEntity = InterfaceBase & {
  __typename?: 'ClaimDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDetailsID: Scalars['String'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Array<Scalars['JSON']>>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  MileageLogIDs?: Maybe<Array<Scalars['String']>>;
  Rate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  Distance?: Maybe<Scalars['Float']>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimHeader?: Maybe<ClaimHeaderEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  MultiCurrencySetup?: Maybe<MultiCurrencySetupEntity>;
  ClaimMileage?: Maybe<ClaimMileageTableEntity>;
};

export type ClaimDetailsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks: Scalars['String'];
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus: ApprovalStatus;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDuplicateList = {
  __typename?: 'ClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export enum ClaimFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  BiYearly = 'Bi_Yearly',
  Lifetime = 'Lifetime'
}

export type ClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimHeaderEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CancelRemark?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ClaimNo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimHistory?: Maybe<Array<ClaimHeaderEntity>>;
  ClaimDetails: Array<ClaimDetailsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  JobAccount: JobAccountEntity;
  Approver?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
};

export type ClaimHistoryListingOutput = {
  __typename?: 'ClaimHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimInsertOutput = {
  __typename?: 'ClaimInsertOutput';
  RespStatus?: Maybe<Scalars['String']>;
  RespMsg?: Maybe<Array<ArrMesg>>;
};

export type ClaimItemEntity = InterfaceBase & {
  __typename?: 'ClaimItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimItemID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  MileageTable?: Maybe<ClaimParameterEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  MileageTableName?: Maybe<Scalars['String']>;
};

export type ClaimItemInput = {
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
};

export type ClaimItemsWithAmountInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailInput>>;
};

export type ClaimItemsWithAmountList = {
  __typename?: 'ClaimItemsWithAmountList';
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailList>>;
};

export type ClaimManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum ClaimManagementDelegation {
  ClaimSubmissionApproval = 'Claim_Submission_Approval',
  ClaimCancellationApproval = 'Claim_Cancellation_Approval'
}

export type ClaimManagementListing = {
  __typename?: 'ClaimManagementListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimMealRateEntity = InterfaceBase & {
  __typename?: 'ClaimMealRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMealRateID: Scalars['String'];
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  MealRate?: Maybe<Array<ClaimParameterEntity>>;
  MealRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  MealType: Scalars['String'];
  VenueName: Scalars['String'];
};

export type ClaimMealRateInput = {
  ClaimMealRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableEntity = InterfaceBase & {
  __typename?: 'ClaimMileageTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMileageTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  mileageTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableInput = {
  ClaimMileageTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimParameterEntity = InterfaceBase & {
  __typename?: 'ClaimParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimMileageTableEntity>>;
  UserFieldsDetails?: Maybe<Array<ClaimUserDefineFieldsEntity>>;
  VehicleDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealDetails?: Maybe<Array<ClaimMealRateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type ClaimParameterInput = {
  ClaimParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  Status?: Maybe<Status>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClaimParameterType {
  VehicleType = 'Vehicle_Type',
  MileageLocation = 'Mileage_Location',
  MileageTable = 'Mileage_Table',
  MealType = 'Meal_Type',
  Venue = 'Venue',
  ExpenseAccounts = 'Expense_Accounts',
  ClaimUserDefineFields = 'Claim_User_Define_Fields'
}

export type ClaimPoolConditionEntity = InterfaceBase & {
  __typename?: 'ClaimPoolConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimPool?: Maybe<ClaimPoolEntity>;
};

export type ClaimPoolConditionInput = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type ClaimPoolEntity = InterfaceBase & {
  __typename?: 'ClaimPoolEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ClaimTypes?: Maybe<Array<ClaimTypeEntity>>;
  ClaimPoolCondition: Array<ClaimPoolConditionEntity>;
};

export type ClaimPoolInput = {
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  ClaimPoolConditionList?: Maybe<Array<ClaimPoolConditionInput>>;
};

export type ClaimPostedListingOutput = {
  __typename?: 'ClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type ClaimPostingInput = {
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimPostingListingOutput = {
  __typename?: 'ClaimPostingListingOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  DocumentIDList?: Maybe<Array<Scalars['String']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<ClaimPostedListingOutput>;
};

export type ClaimPostingManagementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['String']>;
  DateTo?: Maybe<Scalars['String']>;
};

export type ClaimPostingManagementOutput = {
  __typename?: 'ClaimPostingManagementOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingOutput = {
  __typename?: 'ClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<ClaimPostingPeriodStatus>>;
};

export type ClaimPostingPeriodStatus = {
  __typename?: 'ClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type ClaimRecordHistoryInput = {
  EmployeeID?: Maybe<Scalars['String']>;
};

export type ClaimRecordHistoryOutput = {
  __typename?: 'ClaimRecordHistoryOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimRecordOutput = {
  __typename?: 'ClaimRecordOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  VerifyBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  History?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetail = {
  __typename?: 'ClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  UserDefineField1?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  MileageLogIDs?: Maybe<Array<Scalars['String']>>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimTypePaymentMethod?: Maybe<Scalars['String']>;
  ClaimCategory?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailHeaderOutput = {
  __typename?: 'ClaimRecordsDetailHeaderOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeBankName?: Maybe<Scalars['String']>;
  EmployeeBankAccountNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<ClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailInput = {
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailList = {
  __typename?: 'ClaimRecordsDetailList';
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimRecordsItemInput = {
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  Fields?: Maybe<Scalars['JSON']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  MileageLogIDs?: Maybe<Array<Scalars['String']>>;
  DataTable?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
};

export type ClaimRecordsListing = {
  __typename?: 'ClaimRecordsListing';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  DocumentIDList?: Maybe<Array<Scalars['String']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimSettingEntity = InterfaceBase & {
  __typename?: 'ClaimSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimSettingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
};

export type ClaimSettingInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimSettingsListing = {
  __typename?: 'ClaimSettingsListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CountRecords?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimTakenByLeaveType = {
  __typename?: 'ClaimTakenByLeaveType';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type ClaimTemplateEntity = InterfaceBase & {
  __typename?: 'ClaimTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type ClaimTemplateInput = {
  ClaimTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimToApproveListingOutput = {
  __typename?: 'ClaimToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
  ClaimHistory?: Maybe<Array<ClaimHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ClaimTrxError = {
  __typename?: 'ClaimTrxError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ClaimTypeEntity = InterfaceBase & {
  __typename?: 'ClaimTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  PayItem?: Maybe<PayItemEntity>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  ClaimPolicies?: Maybe<Array<ClaimItemEntity>>;
};

export type ClaimTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
};

export type ClaimUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'ClaimUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimUserDefineFieldsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
  userTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type ClaimUserDefineFieldsInput = {
  ClaimUserDefineFieldsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type ClaimVehicleRateEntity = InterfaceBase & {
  __typename?: 'ClaimVehicleRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimVehicleRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  vehicleRate?: Maybe<Array<ClaimParameterEntity>>;
  vehicleRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  IsRequiredMileageLog?: Maybe<Scalars['Boolean']>;
  MileageRateCalculationMethod?: Maybe<MileageRateCalculationMethod>;
  VehicleName: Scalars['String'];
};

export type ClaimVehicleRateInput = {
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MileageRateCalculationMethod?: Maybe<MileageRateCalculationMethod>;
  IsRequiredMileageLog?: Maybe<Scalars['Boolean']>;
};

export enum ClockPairingType {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW'
}

export type CommonSettingsInfoLinesOutput = {
  __typename?: 'CommonSettingsInfoLinesOutput';
  JobGradeTotal?: Maybe<Scalars['Float']>;
  SkillSetTotal?: Maybe<Scalars['Float']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate2?: Maybe<Scalars['DateTime']>;
  JobPositionTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  RecSrcTotal?: Maybe<Scalars['Float']>;
  UsrDefTotal?: Maybe<Scalars['Float']>;
  DataChangeApprovalPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
  PersonnelEFileSecurityPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyAccessPermEntity = InterfaceBase & {
  __typename?: 'CompanyAccessPermEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAccessPermID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  PayGroupAccess: Array<Scalars['String']>;
};

export type CompanyAccessPermInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressEntity = InterfaceBase & {
  __typename?: 'CompanyAltAddressEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyCyclePeriodListOutput = {
  __typename?: 'CompanyCyclePeriodListOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  MMMYYYY?: Maybe<Scalars['String']>;
  PayCycleName?: Maybe<Scalars['String']>;
};

export type CompanyDelaredHolidayEntity = InterfaceBase & {
  __typename?: 'CompanyDelaredHolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyDelaredHolidayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyDelaredHolidayInput = {
  CompanyDelaredHolidayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type CompanyEntity = InterfaceBase & {
  __typename?: 'CompanyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  SupportEmails?: Maybe<Scalars['String']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  MileageCutOffDay?: Maybe<Scalars['Float']>;
  MileageLogCutOffDay?: Maybe<Scalars['Float']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  AttendanceCorrectionCutOffDay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Departments?: Maybe<Array<DepartmentEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  BankInfos?: Maybe<Array<BankInfoEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  Contact?: Maybe<ContactEntity>;
  CompanyStatutory?: Maybe<CompanyStatutoryEntity>;
  LeaveCompulsory?: Maybe<Array<LeaveCompulsoryPolicyEntity>>;
  JobGrade?: Maybe<Array<JobGradePolicyEntity>>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  State?: Maybe<StatesEntity>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
  ModerationTableModifiedDate?: Maybe<Scalars['DateTime']>;
};

export type CompanyHierarchyEntity = InterfaceBase & {
  __typename?: 'CompanyHierarchyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyHierarchyID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type CompanyHierarchyInput = {
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  SupportEmails?: Maybe<Scalars['String']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  AttendanceCorrectionCutOffDay?: Maybe<Scalars['Float']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  MileageCutOffDay?: Maybe<Scalars['Float']>;
  MileageLogCutOffDay?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
};

export type CompanyOffDayEntity = InterfaceBase & {
  __typename?: 'CompanyOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Holiday?: Maybe<HolidayEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyOffDayInput = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyDayOffIDs?: Maybe<Array<Scalars['String']>>;
};

export type CompanyOffDayOutput = {
  __typename?: 'CompanyOffDayOutput';
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
};

export type CompanyOffDayWithFilterOutput = {
  __typename?: 'CompanyOffDayWithFilterOutput';
  CalendarOffDayID: Scalars['String'];
};

export type CompanyOrgUnitEntity = InterfaceBase & {
  __typename?: 'CompanyOrgUnitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOrgUnitID: Scalars['String'];
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyHierarchy?: Maybe<CompanyHierarchyEntity>;
};

export type CompanyOrgUnitInput = {
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CompanyPayGroupListingOutput = {
  __typename?: 'CompanyPayGroupListingOutput';
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  Status: Status;
  UserLogs: Array<UserLogs>;
};

export type CompanyRunningNoEntity = InterfaceBase & {
  __typename?: 'CompanyRunningNoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyRunningNoID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyRunningNoInput = {
  CompanyRunningNoID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyStatutoryAccountEntity = {
  __typename?: 'CompanyStatutoryAccountEntity';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
  StatutoryAccount?: Maybe<CompanyStatutoryEntity>;
};

export type CompanyStatutoryAccountInput = {
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryAccountOutput = {
  __typename?: 'CompanyStatutoryAccountOutput';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryEntity = InterfaceBase & {
  __typename?: 'CompanyStatutoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyStatutoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  SignByCompany?: Maybe<CompanyEntity>;
  EPFAccount?: Maybe<Array<StatutoryAccount>>;
  SocsoAccount?: Maybe<Array<StatutoryAccount>>;
  PCBAccount?: Maybe<Array<StatutoryAccount>>;
  EISAccount?: Maybe<Array<StatutoryAccount>>;
  StatutoryAccount?: Maybe<Array<CompanyStatutoryAccountEntity>>;
};

export type CompanyStatutoryInput = {
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryOutput = {
  __typename?: 'CompanyStatutoryOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  AccNoDesc?: Maybe<Scalars['String']>;
};

export type CompanyViewLastUpdatedOutput = {
  __typename?: 'CompanyViewLastUpdatedOutput';
  CompanyInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyInfoIsNewLastUpdated?: Maybe<Scalars['Boolean']>;
  StatutoryLastPostedDate?: Maybe<Scalars['DateTime']>;
  DepartmentLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessAuthLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyHierarchyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyOrgUnitLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyReportingStructureLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyJobPositionLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAutoNumberingLastPostedDate?: Maybe<Scalars['DateTime']>;
  ExpensesAccountLastPostedDate?: Maybe<Scalars['DateTime']>;
  BankInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarOffDayPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EmployeeLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  CalendarPolicyListLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfGeneralDocument?: Maybe<Scalars['Float']>;
  TotalSizeOfGeneralDocument?: Maybe<Scalars['Float']>;
  RoleLastUpdated?: Maybe<Scalars['DateTime']>;
  RoleLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfDepartment?: Maybe<Scalars['Float']>;
  NoOfRoleAssignment?: Maybe<Scalars['Float']>;
  DefaultState?: Maybe<Scalars['String']>;
  NoOfStates?: Maybe<Scalars['Float']>;
};

export type CompositionRulesInput = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Min: Scalars['Float'];
  Max: Scalars['Float'];
};

export type CompulsaryCourseByJobGradeEntity = InterfaceBase & {
  __typename?: 'CompulsaryCourseByJobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
};

export type CompulsaryCourseByJobGradeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompulsaryCourseItemInput = {
  training_course_library_id?: Maybe<Scalars['String']>;
};

export type CompulsoryLeaveOutput = {
  __typename?: 'CompulsoryLeaveOutput';
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  CountEmployees?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedByName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Employees?: Maybe<Array<EmployeeIndentity>>;
};

export type ConditionList = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
};

export type ConditionListInput = {
  Criteria: Array<CriteriaListInput>;
  Description: Scalars['String'];
};

export type ConditionObject = {
  __typename?: 'ConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ConditionObjectSpecialCase = {
  __typename?: 'ConditionObjectSpecialCase';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  TargetEmployeesContact?: Maybe<Array<DelegationEntity>>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  IsTalentPool?: Maybe<Scalars['Boolean']>;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  IsTalentPool?: Maybe<Scalars['Boolean']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ContractDocType {
  WorkPermit = 'Work_Permit',
  EmploymentLetter = 'Employment_Letter',
  EmploymentContract = 'Employment_Contract',
  EmploymentPass = 'Employment_Pass'
}

export type ContractEmployeeOutput = {
  __typename?: 'ContractEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ContractInfo?: Maybe<ContractInfoEntity>;
  Document?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<Scalars['String']>;
  IsContractOverdue?: Maybe<Scalars['Boolean']>;
  IsContractEnded?: Maybe<Scalars['Boolean']>;
};

export type ContractInfoEntity = InterfaceBase & {
  __typename?: 'ContractInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  ContractDocumentType?: Maybe<PersonnelParameterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ContractInfoDocument?: Maybe<DocumentsEntity>;
};

export type ContractInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
};

export type CoobinetItemInput = {
  upc?: Maybe<Scalars['String']>;
  picUrl?: Maybe<Scalars['String']>;
  goodsName?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  goodsPrice?: Maybe<Scalars['String']>;
  realPayAmt?: Maybe<Scalars['String']>;
};

export type CoolbinetTransactionEntity = InterfaceBase & {
  __typename?: 'CoolbinetTransactionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CoolbinetTransactionID: Scalars['String'];
  storeId?: Maybe<Scalars['String']>;
  coolbinetId?: Maybe<Scalars['String']>;
  checkout?: Maybe<Scalars['String']>;
  entryTime?: Maybe<Scalars['String']>;
  outTime?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Scalars['JSON']>>;
  cartId?: Maybe<Scalars['String']>;
  lbCustomerId?: Maybe<Scalars['String']>;
};

export type CoolbinetTransactionInput = {
  CoolbinetTransactionID?: Maybe<Scalars['String']>;
  coolbinetId?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  checkout?: Maybe<Scalars['String']>;
  entryTime?: Maybe<Scalars['String']>;
  outTime?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Scalars['JSON']>>;
  cartId?: Maybe<Scalars['String']>;
  lbCustomerId?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CostAllocationEntity = InterfaceBase & {
  __typename?: 'CostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CostAllocationID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CostAllocationInput = {
  CostAllocationID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CostCenterMappingEntity = InterfaceBase & {
  __typename?: 'CostCenterMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type CostCenterMappingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryEntity = InterfaceBase & {
  __typename?: 'CountryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CountryID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  PhoneCode?: Maybe<Scalars['String']>;
};

export type CourseCategoryEntity = NewInterfaceBase & {
  __typename?: 'CourseCategoryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  TrainingCourseLibrary?: Maybe<Array<TrainingCourseLibraryEntity>>;
};

export type CourseCategoryInput = {
  name?: Maybe<Scalars['String']>;
  course_category_id?: Maybe<Scalars['String']>;
};

export type CourseClassificationEntity = InterfaceBase & {
  __typename?: 'CourseClassificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  TrainingCourses?: Maybe<Array<TrainingCourseEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type CourseClassificationInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseEvalEntity = InterfaceBase & {
  __typename?: 'CourseEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CourseEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type CourseEvalInput = {
  CourseEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseFulfilmentOutput = {
  __typename?: 'CourseFulfilmentOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Array<EmployeeFulfilmentOutput>>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type CourseProviderOutput = {
  __typename?: 'CourseProviderOutput';
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<AuthorisedTrainingProviderEntity>>;
};

export enum CourseType {
  Hour = 'Hour',
  Day = 'Day'
}

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['String']>;
};

export enum CrossDayType {
  No = 'NO',
  Yes = 'YES',
  ShiftStartTime = 'SHIFT_START_TIME',
  ShiftFirstHalfEndTime = 'SHIFT_FIRST_HALF_END_TIME',
  ShiftSecondHalfStartTime = 'SHIFT_SECOND_HALF_START_TIME',
  ShiftEndTime = 'SHIFT_END_TIME',
  ClockOutTime = 'CLOCK_OUT_TIME'
}

export enum CurrencyEffectiveMethod {
  ByReceiptDate = 'By_Receipt_Date',
  BySubmissionDate = 'By_Submission_Date'
}

export type DmImported = {
  __typename?: 'DMImported';
  MigratedData_Bank?: Maybe<Scalars['Float']>;
  MigratedData_Company?: Maybe<Scalars['Float']>;
  MigratedData_DepartmentDivision?: Maybe<Scalars['Float']>;
  MigratedData_Employee?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeDependent?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeJobInfo?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeSalary?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeStatutoryInfo?: Maybe<Scalars['Float']>;
  MigratedData_FixedAllwDed?: Maybe<Scalars['Float']>;
  MigratedData_JobDesignation?: Maybe<Scalars['Float']>;
  MigratedData_JobGrade?: Maybe<Scalars['Float']>;
  MigratedData_PayItem?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCutOff?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCycle?: Maybe<Scalars['Float']>;
  MigratedData_PayrollHistory?: Maybe<Scalars['Float']>;
  MigratedData_RecruitmentSource?: Maybe<Scalars['Float']>;
};

export type DailyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DailyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'DailyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type DailyTemplateEntity = InterfaceBase & {
  __typename?: 'DailyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DailyTemplateInput = {
  DailyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DashboardCardSetting = {
  isExpand: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  DashboardCardType: DashboardCardType;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum DashboardCardType {
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager'
}

export type DataChangesDetails = {
  __typename?: 'DataChangesDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  DataChangesID: Scalars['String'];
  Action: Scalars['String'];
  Type: Scalars['String'];
};

export type DataChangesEntity = {
  __typename?: 'DataChangesEntity';
  DataChangesID: Scalars['String'];
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  DataChangeHistory?: Maybe<Array<DataChangesEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type DataChangesInput = {
  DataChangesID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  OldData?: Maybe<ChangesDataInput>;
  Status?: Maybe<Status>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
};

export enum DataUpdateStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved'
}

export type DataUpdateStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<DataUpdateStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};


export enum DayType {
  NormalDay = 'NORMAL_DAY',
  OffDay = 'OFF_DAY',
  RestDay = 'REST_DAY',
  PublicHoliday = 'PUBLIC_HOLIDAY'
}

export type DeductionPolicyEntity = InterfaceBase & {
  __typename?: 'DeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type DeductionPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DeductionTemplateEntity = InterfaceBase & {
  __typename?: 'DeductionTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DeductionTemplateInput = {
  DeductionTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DelegationEntity = InterfaceBase & {
  __typename?: 'DelegationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DelegationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetContactID?: Maybe<Scalars['String']>;
  SubmitterEmployee?: Maybe<EmployeeEntity>;
  TargetEmployee?: Maybe<EmployeeEntity>;
  TargetEmployeeContact?: Maybe<ContactEntity>;
};

export type DelegationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  EmployeeID?: Maybe<Scalars['String']>;
  DelegationID?: Maybe<Scalars['String']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Company: CompanyEntity;
};

export type DepartmentInput = {
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DepartmentType {
  Department = 'Department',
  Division = 'Division'
}

export type DepartmentUpdateInput = {
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DietaryPreference {
  CellacDisease = 'Cellac_Disease',
  Diabetic = 'Diabetic',
  Halal = 'Halal',
  Kosher = 'Kosher',
  LactoseIntolerance = 'Lactose_Intolerance',
  PeanutAllergies = 'Peanut_Allergies',
  VegetarianAndVegans = 'Vegetarian_And_Vegans'
}

export type DisplayReportTypeSequenceOutput = {
  __typename?: 'DisplayReportTypeSequenceOutput';
  CP22aReportSeq?: Maybe<Scalars['Float']>;
  CP21ReportSeq?: Maybe<Scalars['Float']>;
  EAFormReportSeq?: Maybe<Scalars['Float']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  EAFormSetupID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type DocumentGrouping = {
  __typename?: 'DocumentGrouping';
  DocumentType: DocumentType;
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberAssignmentEntity = InterfaceBase & {
  __typename?: 'DocumentNumberAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberAssignmentInput = {
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberDetailID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocNumHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  HasNextNumber?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
  IsStandard?: Maybe<Scalars['Boolean']>;
  DocNumDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  DocumentNumberingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  StartFrom?: Maybe<Scalars['Float']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
};

export type DocumentProfilePictureOutput = {
  __typename?: 'DocumentProfilePictureOutput';
  Employee?: Maybe<EmployeeEntity>;
};

export enum DocumentType {
  Payslip = 'Payslip',
  EaForm = 'EA_FORM',
  TaxRelief = 'Tax_Relief',
  GeneralDoc = 'General_Doc',
  PersonalDoc = 'Personal_Doc',
  ContractDoc = 'Contract_Doc',
  Claim = 'Claim',
  Leave = 'Leave',
  Employee = 'Employee',
  News = 'News',
  Requisition = 'Requisition',
  Company = 'Company',
  ReportTemplate = 'ReportTemplate',
  Appraisal = 'Appraisal',
  Tms = 'TMS',
  ProjectExpense = 'ProjectExpense',
  DataChanges = 'DataChanges',
  AttendanceRecord = 'AttendanceRecord',
  EmployeeSalary = 'EmployeeSalary',
  MonthlyTransaction = 'MonthlyTransaction',
  FixedAllowanceDeduction = 'FixedAllowanceDeduction',
  Helpdesk = 'Helpdesk',
  Training = 'Training',
  PayrollProcess = 'Payroll_Process',
  Overtime = 'Overtime',
  Recruitment = 'Recruitment'
}

export type DocumentsEntity = InterfaceBase & {
  __typename?: 'DocumentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentID: Scalars['String'];
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  DocumentName?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Folder?: Maybe<FolderEntity>;
};

export type DocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
};

export type DutyRosterEntity = InterfaceBase & {
  __typename?: 'DutyRosterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DutyRosterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Color?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type DutyRosterInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  DutyRosterID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EaFormSetupEntity = InterfaceBase & {
  __typename?: 'EAFormSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAFormSetupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
};

export type EaFormSetupInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EaPartFCategoryEntity = {
  __typename?: 'EAPartFCategoryEntity';
  EAPartFCategoryID: Scalars['String'];
  Description: Scalars['String'];
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
};

export type EaPartFEntity = InterfaceBase & {
  __typename?: 'EAPartFEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAPartFID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
};

export type EaPartFInput = {
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EApplicationSummaryOutput = {
  __typename?: 'EApplicationSummaryOutput';
  EApplicationList: Array<Rec_EApplicationFormSetupEntity>;
  SummaryData?: Maybe<Scalars['JSON']>;
};

export type EisCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  EISChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type EisCalculationOutput = {
  __typename?: 'EISCalculationOutput';
  EmployeeID: Scalars['String'];
  EISAmount: Scalars['Float'];
  EISRequirement?: Maybe<Scalars['String']>;
  EmployeeEISAmount?: Maybe<Scalars['Float']>;
  EmployerEISAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
};

export type EisPolicyEntity = InterfaceBase & {
  __typename?: 'EISPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Formula: Scalars['String'];
  Remarks: Scalars['String'];
};

export type EisPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisRefEntity = InterfaceBase & {
  __typename?: 'EISRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISRefID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount: Scalars['Float'];
  EmpAmount: Scalars['Float'];
};

export type EisRefInput = {
  EISRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount?: Maybe<Scalars['Float']>;
  EmpAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfCalculationInput = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  CurrentMonthEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
  EPFNormalRemunerationChargableAmount?: Maybe<Scalars['Float']>;
  AccumulatedEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
};

export type EpfCalculationInputV2 = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  BasicSalary: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
  NormalRemuneration: Scalars['Float'];
  AdditionalRemuneration?: Maybe<Scalars['Float']>;
  EPFEmployeeToMinus?: Maybe<Scalars['Float']>;
  ForcastedRemuneration?: Maybe<Scalars['Float']>;
  MonthlyWageRemuneration?: Maybe<Scalars['Float']>;
  EPFChargableAmount: Scalars['Float'];
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
};

export type EpfCalculationOutput = {
  __typename?: 'EPFCalculationOutput';
  EmployeeID: Scalars['String'];
  EPFAmount: Scalars['Float'];
  EPFRoundingAmount: Scalars['Float'];
  EmployeeEPFAmount?: Maybe<Scalars['Float']>;
  EmployerEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EmployerVERate: Scalars['Float'];
  EmployeeVERate: Scalars['Float'];
  TotalEmployerRate: Scalars['Float'];
  TotalEmployeeRate: Scalars['Float'];
  EmpUserDefineEPF?: Maybe<Scalars['JSON']>;
};

export type EpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'EPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyDetailID: Scalars['String'];
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  EPFPolicy?: Maybe<EpfPolicyEntity>;
};

export type EpfPolicyDetailInput = {
  EPFPolicyDetailID?: Maybe<Scalars['String']>;
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfPolicyEntity = InterfaceBase & {
  __typename?: 'EPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  Formula: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EPFPolicyDetails?: Maybe<Array<EpfPolicyDetailEntity>>;
};

export type EpfPolicyInput = {
  EPFPolicyID?: Maybe<Scalars['String']>;
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  Age?: Maybe<Scalars['Float']>;
  EPFChargableAmount?: Maybe<Scalars['Float']>;
  EmployerRate?: Maybe<Scalars['Float']>;
  EmployeeRate?: Maybe<Scalars['Float']>;
  Formula?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfRateCalculationOutput = {
  __typename?: 'EPFRateCalculationOutput';
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
};

export type EarnLeaveEntity = InterfaceBase & {
  __typename?: 'EarnLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EarnLeaveID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
};

export enum Education {
  No = 'No',
  Under_18YearsOld = 'Under_18_years_old',
  CertificateMatriculation = 'CertificateMatriculation',
  DiplomaDegree = 'DiplomaDegree'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  Subject?: Maybe<Scalars['String']>;
  Body?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  Subject?: Maybe<Scalars['String']>;
  Body?: Maybe<Scalars['String']>;
};

export type EmmployeeListingInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type EmpCalendarLeaveListing = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimBalance = {
  __typename?: 'EmpClaimBalance';
  ClaimItems?: Maybe<ClaimItemEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurenceBalance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimBalanceOutput = {
  __typename?: 'EmpClaimBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimBalance?: Maybe<Array<EmpClaimBalance>>;
};

export type EmpClaimEntitlementByClaimTypeOutput = {
  __typename?: 'EmpClaimEntitlementByClaimTypeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summary?: Maybe<EntitlementSummary>;
};

export type EmpClaimEntitlementByEmployeeOutput = {
  __typename?: 'EmpClaimEntitlementByEmployeeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summarries?: Maybe<Array<EntitlementSummary>>;
};

export type EmpClaimEntitlementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimEntitlementOutput = {
  __typename?: 'EmpClaimEntitlementOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
};

export type EmpClaimPoolBalance = {
  __typename?: 'EmpClaimPoolBalance';
  ClaimPool?: Maybe<ClaimPoolEntity>;
  ClaimPoolCondition?: Maybe<ClaimPoolConditionEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimPoolBalanceOutput = {
  __typename?: 'EmpClaimPoolBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimPoolBalance?: Maybe<Array<EmpClaimPoolBalance>>;
};

export type EmpClaimTypeValidationOutput = {
  __typename?: 'EmpClaimTypeValidationOutput';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveApplicationInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  SaveAsType: ApprovalStatus;
};

export type EmpLeaveBalanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpLeaveBalanceOuput = {
  __typename?: 'EmpLeaveBalanceOuput';
  Employee?: Maybe<EmployeeEntity>;
  LeaveTypeBalance?: Maybe<Array<LeaveTypeBalance>>;
};

export type EmpLeaveDetailInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
};

export type EmpLeaveDetailOuput = {
  __typename?: 'EmpLeaveDetailOuput';
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type EmpLeaveEntitlementOuput = {
  __typename?: 'EmpLeaveEntitlementOuput';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmpLeaveItems?: Maybe<Array<EmpLeaveItemEntitlement>>;
};

export type EmpLeaveItemEntitlement = {
  __typename?: 'EmpLeaveItemEntitlement';
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBenefitRules?: Maybe<Array<LeaveBenefitRuleEntity>>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
};

export type EmpLeaveListingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatusList?: Maybe<Array<ApprovalStatus>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveListingOutput = {
  __typename?: 'EmpLeaveListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveItemName?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type EmpLeaveTypeValidationOutput = {
  __typename?: 'EmpLeaveTypeValidationOutput';
  LeaveTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpProjectExpenseTypeValidationOutput = {
  __typename?: 'EmpProjectExpenseTypeValidationOutput';
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpRequisitionInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
};

export type EmpSalary = {
  __typename?: 'EmpSalary';
  EmployeeID?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  PreStandardWages?: Maybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  SalaryType?: Maybe<Scalars['String']>;
};

export type EmpWorkTime = {
  SysID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  StartDateTime: Scalars['DateTime'];
  EndDateTime: Scalars['DateTime'];
  PunchInDateTime: Scalars['String'];
  PunchOutDateTime: Scalars['String'];
  CalStartDateTime: Scalars['String'];
  CalEndDateTime: Scalars['String'];
  ProductivityMins: Scalars['Float'];
  OvertimeMins: Scalars['Float'];
  ErrorDay: Scalars['String'];
  ErrorDate: Scalars['String'];
  TranStatus: Scalars['String'];
};

export type EmployeeAppLoginResponse = {
  __typename?: 'EmployeeAppLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  employee?: Maybe<EmployeeResponse>;
};

export type EmployeeAppSecurityAssignmentEntity = {
  __typename?: 'EmployeeAppSecurityAssignmentEntity';
  EmployeeAppSecurityAssignmentID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityAssignmentInput = {
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityEntity = {
  __typename?: 'EmployeeAppSecurityEntity';
  EmployeeAppSecurityID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurityPermission?: Maybe<Array<EmployeeAppSecurityPermissionEntity>>;
};

export type EmployeeAppSecurityInput = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityOutput = {
  __typename?: 'EmployeeAppSecurityOutput';
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PermissionList?: Maybe<Array<EmployeeAppSecurityPermissionOutput>>;
  InUse: Scalars['Boolean'];
};

export type EmployeeAppSecurityPermissionEntity = {
  __typename?: 'EmployeeAppSecurityPermissionEntity';
  EmployeeAppSecurityPermissionID: Scalars['String'];
  EmployeeAppSecurityID: Scalars['String'];
  PermissionID: Scalars['String'];
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityPermissionOutput = {
  __typename?: 'EmployeeAppSecurityPermissionOutput';
  EmployeeAppSecurityPermissionID?: Maybe<Scalars['String']>;
  PermissionID?: Maybe<Scalars['Float']>;
  PermissionName?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalDetailID: Scalars['String'];
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeader?: Maybe<EmployeeAppraisalHeaderEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalDetailInput = {
  EmployeeAppraisalDetailID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Array<Scalars['JSON']>>;
  Signature?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeAppraisalDetails?: Maybe<Array<EmployeeAppraisalDetailEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  SignatureDocument?: Maybe<DocumentsEntity>;
  FinalRating?: Maybe<Scalars['Float']>;
  AttachmentList?: Maybe<Array<DocumentsEntity>>;
  GetSignatureDocument?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderInput = {
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<AppraiseeType>;
  EmployeeID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  SignatureInput?: Maybe<DocumentsInput>;
  SignatureToRemove?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeAppraisalDetailInput>>;
};

export type EmployeeAppraisalInput = {
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAssetEntity = InterfaceBase & {
  __typename?: 'EmployeeAssetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAssetID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeAssetInput = {
  EmployeeAssetID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAttendanceOutput = {
  __typename?: 'EmployeeAttendanceOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseRecord?: Maybe<Array<EmployeeCourseRecordAttendanceOutput>>;
  FinalTotalAttendanceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'EmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeBankInfoInput = {
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
};

export type EmployeeBenefitInKindEntity = InterfaceBase & {
  __typename?: 'EmployeeBenefitInKindEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBenefitInKindID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBenefitInKindInput = {
  EmployeeBenefitInKindID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBirthdaysOutput = {
  __typename?: 'EmployeeBirthdaysOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type EmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Entity = InterfaceBase & {
  __typename?: 'EmployeeCP22Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeCP22ID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LHDNReportID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LHDNReport?: Maybe<LhdnReportEntity>;
};

export type EmployeeCp22Input = {
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Output = {
  __typename?: 'EmployeeCP22Output';
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReportSequence?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type EmployeeCalendarOutput = {
  __typename?: 'EmployeeCalendarOutput';
  companyOffDays?: Maybe<Array<CompanyOffDayOutput>>;
  employeeBirthdays?: Maybe<Array<EmployeeBirthdaysOutput>>;
  employeeLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
  employeeStaffLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
};

export type EmployeeCheckIn = {
  EmployeeNo: Scalars['String'];
  ClockDateTime: Scalars['DateTime'];
  ClockType: Scalars['String'];
  ClockRemark: Scalars['String'];
};

export type EmployeeConditionsDetails = {
  __typename?: 'EmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<ConditionObject>;
  JobPosition?: Maybe<ConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<ConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<ConditionObject>;
  ShiftGroup?: Maybe<ConditionObjectSpecialCase>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  ConfirmedDueDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<PolicyOutput>>;
  EmployeeCareerLogs?: Maybe<Array<Scalars['JSON']>>;
  Age?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeCourseRecordAttendanceOutput = {
  __typename?: 'EmployeeCourseRecordAttendanceOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  TotalAttendaceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'EmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PersonnelID?: Maybe<Scalars['String']>;
};

export type EmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactInput>;
};

export type EmployeeDetailsInput = {
  Date: Scalars['DateTime'];
  Company: Scalars['String'];
  JoinedDate: Scalars['DateTime'];
  ConfirmedDate: Scalars['DateTime'];
  JobPosition: Scalars['String'];
  JobGrade: Scalars['String'];
  EmploymentType: Scalars['String'];
  OrgUnit: Scalars['String'];
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EmployeeDiaryEntity = InterfaceBase & {
  __typename?: 'EmployeeDiaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDiaryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeDiaryInput = {
  EmployeeID: Scalars['String'];
  EmployeeDiaryID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeEntity = InterfaceBase & {
  __typename?: 'EmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  ContractDueDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
};

export type EmployeeEvaluationAnswerEntity = NewInterfaceBase & {
  __typename?: 'EmployeeEvaluationAnswerEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_employee_evaluation_id: Scalars['String'];
  survey_item_id: Scalars['String'];
  answer: Scalars['String'];
  answer_point?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<ScheduledTrainingEmployeeEvaluationEntity>;
};

export type EmployeeEvaluationAnswerInput = {
  employee_evaluation_answer_id?: Maybe<Scalars['String']>;
  survey_item_id?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_point?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type EmployeeFilterInput = {
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeFilterListingBySubscriptionAccountOutput = {
  __typename?: 'EmployeeFilterListingBySubscriptionAccountOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EmployeeStatutoryCreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeStatutoryModifiedDT?: Maybe<Scalars['DateTime']>;
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  CareerLogID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastWorkingDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastEmploymentDate?: Maybe<Scalars['DateTime']>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  FromEmploymentType?: Maybe<Scalars['String']>;
  ToEmploymentType?: Maybe<Scalars['String']>;
  ExitReasonID?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<Scalars['String']>;
  PersonnelParameterType?: Maybe<Scalars['String']>;
};

export type EmployeeFulfilmentOutput = {
  __typename?: 'EmployeeFulfilmentOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
};

export type EmployeeIndentity = {
  __typename?: 'EmployeeIndentity';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  IsSpecial?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  ContractDueDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeInsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'EmployeeInsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Employee?: Maybe<EmployeeEntity>;
  InsuranceScheme?: Maybe<InsuranceSchemeEntity>;
};

export type EmployeeInsuranceSchemeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiCategoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKPICategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID: Scalars['String'];
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  EmployeeKPIHeader?: Maybe<EmployeeKpiHeaderEntity>;
  EmployeeKPIDetail?: Maybe<Array<EmployeeKpiDetailEntity>>;
};

export type EmployeeKpiCategoryInput = {
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeKpiDetailInput>>;
};

export type EmployeeKpiDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID: Scalars['String'];
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<EmployeeKpiCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  EmployeeKPIDetailRating?: Maybe<Array<EmployeeKpiDetailRatingEntity>>;
};

export type EmployeeKpiDetailInput = {
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Scale?: Maybe<Array<EmployeeKpiDetailRatingInput>>;
};

export type EmployeeKpiDetailRatingEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailRatingID: Scalars['String'];
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPIDetail?: Maybe<EmployeeKpiDetailEntity>;
};

export type EmployeeKpiDetailRatingInput = {
  EmployeeKPIDetailRatingID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<Array<EmployeeKpiCategoryEntity>>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiHeaderInput = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<EmployeeKpiCategoryInput>>;
};

export type EmployeeKpiEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseDetailsID: Scalars['String'];
  EmployeeKpiDetails: Array<Scalars['JSON']>;
  Comment?: Maybe<Scalars['String']>;
  KpiExerciseDetail: KpiExerciseDetailsEntity;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  EmployeeKpiDetails: Array<KpiMinMaxWeightage>;
  Status: Status;
};

export type EmployeeKpiRepositoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiRepositoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: Scalars['JSON'];
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiRepositoryInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: KpiCategoryItemInput;
};

export type EmployeeLeaveEntitlementEntity = {
  __typename?: 'EmployeeLeaveEntitlementEntity';
  EmployeeLeaveEntitlementID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveYear: Scalars['Float'];
  Date: Scalars['DateTime'];
  Entitlement: Scalars['Float'];
  Days?: Maybe<Scalars['Float']>;
  LeavePolicyID: Scalars['String'];
  IsLocked: Scalars['Boolean'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Employee: EmployeeEntity;
  LeaveType: LeaveTypeEntity;
  LeaveItem: LeaveItemEntity;
};

export type EmployeeLeavePolicy = {
  __typename?: 'EmployeeLeavePolicy';
  LeaveYear?: Maybe<Scalars['Float']>;
  LeavePolicy?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type EmployeeLeavesOutput = {
  __typename?: 'EmployeeLeavesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<Scalars['String']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  LeaveApprovalStatus?: Maybe<Scalars['String']>;
  OwnSubordinates?: Maybe<Scalars['Boolean']>;
  TotalDay?: Maybe<Scalars['Float']>;
};

export type EmployeeLeavesSummary = {
  __typename?: 'EmployeeLeavesSummary';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmployeeLeavePolicies?: Maybe<Array<EmployeeLeavePolicy>>;
};

export type EmployeeListOutput = {
  __typename?: 'EmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type EmployeeListingOutput = {
  __typename?: 'EmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};

export type EmployeeListingOutputv2 = {
  __typename?: 'EmployeeListingOutputv2';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
};

export type EmployeeNotificationOutput = {
  __typename?: 'EmployeeNotificationOutput';
  NotificationID?: Maybe<Scalars['String']>;
  Topic?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<LeaveDetails>;
  ClaimDetails?: Maybe<ClaimDetails>;
  RequisitionDetails?: Maybe<RequisitionDetails>;
  OvertimeDetails?: Maybe<OvertimeEntity>;
  TimeOffDetails?: Maybe<TimeOffEntity>;
  ChangeShiftDetails?: Maybe<ChangeShiftEntity>;
  DataChangesDetails?: Maybe<DataChangesDetails>;
  ProjectExpenseClaimDetails?: Maybe<ProjectExpenseClaimDetails>;
  PerformanceKpiDetail?: Maybe<KpiExerciseDetailsEntity>;
  PerformanceReviewDetail?: Maybe<EmployeeReviewEntity>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  ProjectTimesheetDetail?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
  TrainingNominationDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  TrainingRequestDetail?: Maybe<EmployeeTrainingRequestEntity>;
  TrainingSelfRegistrationDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  TrainingWithdrawalDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  Rec_RequisitionDetail?: Maybe<Rec_RequisitionEntity>;
  Rec_OfferDetail?: Maybe<Rec_OfferOutput>;
  Rec_HiringDetail?: Maybe<Rec_HiringOutput>;
  Status?: Maybe<Status>;
  BatchOvertimeDetail?: Maybe<BatchOvertimeDetails>;
  AttendanceCorrectionDetail?: Maybe<AttendanceCorrectionDetail>;
};

export type EmployeePlanningOutput = {
  __typename?: 'EmployeePlanningOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  QuaterType?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PlanningList?: Maybe<Array<EmployeeKpiHeaderEntity>>;
};

export type EmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'EmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  TaxExemptionList?: Maybe<Scalars['String']>;
};

export type EmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxExemptionList?: Maybe<Scalars['String']>;
};

export type EmployeeReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type EmployeeReportingOutput = {
  __typename?: 'EmployeeReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  SuperiorID: Scalars['String'];
  Superior?: Maybe<EmployeeEntity>;
  SubordinateID: Scalars['String'];
  Subordinate?: Maybe<EmployeeEntity>;
};

export type EmployeeResponse = {
  __typename?: 'EmployeeResponse';
  subscriptionAccountID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  SuperUserID?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
};

export type EmployeeReviewEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  IsDelegation?: Maybe<Scalars['Boolean']>;
  ToDate: Scalars['DateTime'];
  ReviewDate?: Maybe<Scalars['DateTime']>;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog?: Maybe<Scalars['JSON']>;
  ReviewExercise?: Maybe<ReviewExerciseEntity>;
  EmployeeReviewSection: Array<EmployeeReviewSectionEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Reviewer?: Maybe<EmployeeEntity>;
  ReviewFormTemplate?: Maybe<ReviewFormTemplateEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
  ReviewFormTemplateName: Scalars['String'];
  ApprovalHistory: Array<EmployeeReviewEntity>;
  ReviewGradingTable: Scalars['JSON'];
  HasNextLevelApproval?: Maybe<Scalars['Boolean']>;
  ReviewType: Scalars['String'];
  WeightageType: QuantitativeType;
  IsSendBackToInitaite?: Maybe<Scalars['Boolean']>;
};

export type EmployeeReviewInput = {
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  EmployeeReviewSection: Array<EmployeeReviewSectionInput>;
};

export type EmployeeReviewSectionEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable?: Maybe<Scalars['Boolean']>;
  Recommendation?: Maybe<Scalars['JSON']>;
  Weightage: Scalars['Float'];
  EmployeeReview?: Maybe<EmployeeReviewEntity>;
  ReviewSection?: Maybe<ReviewSectionEntity>;
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemEntity>;
};

export type EmployeeReviewSectionInput = {
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable: Scalars['Boolean'];
  Weightage: Scalars['Float'];
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemInput>;
};

export type EmployeeReviewSectionItemEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionID: Scalars['String'];
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
  ReviewItemDetail: Scalars['JSON'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReviewSection?: Maybe<EmployeeReviewSectionEntity>;
  ReviewConversationLog?: Maybe<Array<ReviewConversationLogEntity>>;
  ItemDetails: Scalars['JSON'];
};

export type EmployeeReviewSectionItemInput = {
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type EmployeeReviewSetupInput = {
  ID: Scalars['String'];
  ReviewerID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export enum EmployeeSalaryReason {
  AnnualReview = 'Annual_Review',
  ChangePosition = 'Change_Position',
  ConfirmationOfProbation = 'Confirmation_of_Probation',
  ContractRenewal = 'Contract_Renewal',
  Increment = 'Increment',
  InternalTransfer = 'Internal_Transfer',
  MarketAdjustment = 'Market_Adjustment',
  Others = 'Others',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  NewHire = 'New_Hire'
}

export type EmployeeStatutoryEntity = {
  __typename?: 'EmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeStatutoryInput = {
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
};

export type EmployeeSummary = {
  __typename?: 'EmployeeSummary';
  TimeOff?: Maybe<Scalars['JSON']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  Roster?: Maybe<Scalars['Float']>;
  OT?: Maybe<Scalars['JSON']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ScanDate?: Maybe<Scalars['DateTime']>;
  Shift?: Maybe<Scalars['JSON']>;
  ClockType?: Maybe<IntegrationClockType>;
  TimeOffDate?: Maybe<Scalars['DateTime']>;
  ChangeShiftDate?: Maybe<Scalars['DateTime']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  Batch?: Maybe<Scalars['DateTime']>;
  totalBatch?: Maybe<Scalars['Float']>;
  subordinatesTotal?: Maybe<Scalars['Float']>;
  LatestAttendanceCorrectionRecord?: Maybe<Scalars['DateTime']>;
};

export type EmployeeTaxReliefEntity = InterfaceBase & {
  __typename?: 'EmployeeTaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTaxReliefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  TaxRelief?: Maybe<TaxReliefEntity>;
};

export type EmployeeTaxReliefInput = {
  EmployeeTaxReliefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingEntity = InterfaceBase & {
  __typename?: 'EmployeeTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTrainingID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeTrainingInput = {
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingRequestEntity = NewInterfaceBase & {
  __typename?: 'EmployeeTrainingRequestEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  subscription_account_id: Scalars['String'];
  training_name: Scalars['String'];
  description: Scalars['String'];
  training_provider_name?: Maybe<Scalars['String']>;
  contact_info?: Maybe<Scalars['String']>;
  training_dt?: Maybe<Scalars['DateTime']>;
  estimated_course_fee?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
  justification: Scalars['String'];
  web_url?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  approval_status: Request_Approval_Status;
  approval_log: Array<Scalars['JSON']>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  voiceDocument?: Maybe<DocumentsEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  TrainingHistory?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type EmployeeTransactionOutput = {
  __typename?: 'EmployeeTransactionOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeFullName?: Maybe<Scalars['String']>;
  PayItems?: Maybe<Array<PayItemOutput>>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  InUse?: Maybe<Scalars['Boolean']>;
  IsProcessed: Scalars['Boolean'];
};

export type EmployeeUserDefineFieldsDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsDetailID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type EmployeeUserDefineFieldsDetailInput = {
  EmployeeUserDefineFieldsDetailID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  EmployeeUserDefineFieldsDetail?: Maybe<EmployeeUserDefineFieldsDetailEntity>;
};

export type EmployeeUserDefineFieldsInput = {
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeesBalanceLeaveSummary = {
  __typename?: 'EmployeesBalanceLeaveSummary';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export enum EmploymentType {
  Contract = 'Contract',
  Internship = 'Internship',
  Permanent = 'Permanent',
  PartTime = 'Part_Time',
  CasualWorker = 'Casual_Worker',
  FixedTermContract = 'Fixed_Term_Contract'
}

export type EncashCarryForwardLeaveEntity = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
};

export type EncashCarryForwardLeaveInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashCarryForwardLeaveOutput = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  PayrollProcessed?: Maybe<Scalars['Boolean']>;
};

export type EncashOffboadingLeaveEntity = InterfaceBase & {
  __typename?: 'EncashOffboadingLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashOffboadingLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export type EncashOffboadingLeaveInput = {
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashedLeaveHistory = {
  __typename?: 'EncashedLeaveHistory';
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export enum EntDistributionMethod {
  LosPeriod = 'LOS_Period',
  EqualMonthly = 'Equal_Monthly'
}

export type EntRoleUsrArgCountOutput = {
  __typename?: 'EntRoleUsrArgCountOutput';
  roleId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type EntRoleUsrArgEntity = {
  __typename?: 'EntRoleUsrArgEntity';
  id: Scalars['String'];
  entId: Scalars['String'];
  roleId: Scalars['String'];
  accId: Scalars['String'];
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Role?: Maybe<RoleEntity>;
};

export type EntRoleUsrArgInput = {
  id?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  accId?: Maybe<Scalars['String']>;
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum EntitlementAvailability {
  LengthOfService = 'Length_of_Service',
  BeginningOfYear = 'Beginning_of_Year'
}

export enum EntitlementBasedOn {
  DateJoin = 'Date_Join',
  JoinReferenceDate = 'Join_Reference_Date'
}

export enum EntitlementComputation {
  Max = 'Max',
  ByService = 'By_Service'
}

export type EntitlementSummary = {
  __typename?: 'EntitlementSummary';
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<Scalars['String']>;
  Entitled?: Maybe<Scalars['Float']>;
  Draft?: Maybe<Scalars['Float']>;
  Pending?: Maybe<Scalars['Float']>;
  Claimed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID?: Maybe<Array<Scalars['String']>>;
  roleID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};

export type EonEaDocumentEntity = {
  __typename?: 'EonEaDocumentEntity';
  EonEaDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchDateTime?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type EonPayslipDocumentEntity = {
  __typename?: 'EonPayslipDocumentEntity';
  EonPayslipDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchDateTime?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type EventStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Event_Status>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type ExcelPayItemTransactionOutput = {
  __typename?: 'ExcelPayItemTransactionOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  TotalNonPayable?: Maybe<Scalars['Float']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum ExitReason {
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Illness = 'Illness',
  Absconded = 'Absconded',
  DepartureFromMalaysia = 'DepartureFromMalaysia',
  InterCompanyTransfer = 'InterCompanyTransfer',
  Null = 'Null'
}

export type ExportAttendanceProcessing = InterfaceBase & {
  __typename?: 'ExportAttendanceProcessing';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
  Contact?: Maybe<ContactEntity>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinalClaimApprovalUpdateInput = {
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingDetailEntity = InterfaceBase & {
  __typename?: 'FinalRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingDetailID: Scalars['String'];
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
};

export type FinalRatingDetailInput = {
  FinalRatingDetailID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingHeaderEntity = InterfaceBase & {
  __typename?: 'FinalRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  FinalRatingDetails?: Maybe<Array<FinalRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type FinalRatingHeaderInput = {
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<FinalRatingDetailInput>>;
};

export type FolderEntity = InterfaceBase & {
  __typename?: 'FolderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID: Scalars['String'];
  FolderName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Documents?: Maybe<Array<DocumentsEntity>>;
};

export type FormItemRatingEntity = InterfaceBase & {
  __typename?: 'FormItemRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrderType: SequenceOrderType;
  From: Scalars['Float'];
  To: Scalars['Float'];
  RatingScaleTable: Array<Scalars['JSON']>;
  InputMethod: RatingInputMethod;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  InUse: Scalars['Boolean'];
};

export type FormItemRatingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  OrderType?: Maybe<SequenceOrderType>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RatingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type FormItemRatingSetup = {
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
};

export enum FormStatus {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type FreezePayrollEntity = InterfaceBase & {
  __typename?: 'FreezePayrollEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Employees?: Maybe<EmployeeEntity>;
};

export type FreezePayrollInput = {
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GlAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'GLAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};

export type GlAccountDetailEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type GlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type GlAccountHeaderEntity = InterfaceBase & {
  __typename?: 'GLAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  GLAccountDetail?: Maybe<Array<GlAccountDetailEntity>>;
};

export type GlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type GlClaimRecordsDetail = {
  __typename?: 'GLClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralSettingsLastUpdatedOutput = {
  __typename?: 'GeneralSettingsLastUpdatedOutput';
  RoleSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  RoleSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayrollSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  LeaveSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeaveSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  ClaimSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobGradeIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeNoOfActiveRecords?: Maybe<Scalars['Float']>;
  JobDesignationLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobDesignationIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobDesignationNoOfActiveRecords?: Maybe<Scalars['Float']>;
  RecruitmentSourceCount?: Maybe<Scalars['Float']>;
  RecruitmentSourceIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollItemIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemNoOfActiveRecords?: Maybe<Scalars['Float']>;
  LHDNReportsNoOfActiveRecords?: Maybe<Scalars['Float']>;
  CompanylastUpdate?: Maybe<Scalars['Boolean']>;
  CompanylastPosted?: Maybe<Scalars['DateTime']>;
  NewslastUpdate?: Maybe<Scalars['Boolean']>;
  NewslastPosted?: Maybe<Scalars['DateTime']>;
  AutoNumberSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
};

export type GenerateClaimCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GenerateLeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailOutput = {
  __typename?: 'GetApprovedDetailOutput';
  Date?: Maybe<Scalars['String']>;
  ApprovedBySuperior?: Maybe<Scalars['String']>;
  ApprovedAmt?: Maybe<Scalars['Float']>;
};

export type GetCompletedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetCompletedDetailOutput = {
  __typename?: 'GetCompletedDetailOutput';
  ClaimType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['String']>;
  ClaimApprovedAmount?: Maybe<Scalars['Float']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  JobCode?: Maybe<Scalars['String']>;
  ClaimLimit?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type GetEmpClaimListingInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
};

export type GetEmpClaimListingOutput = {
  __typename?: 'GetEmpClaimListingOutput';
  Subject?: Maybe<Scalars['String']>;
  SummaryTotalAmount?: Maybe<Scalars['Float']>;
  TotalClaimItem?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['String']>;
};

export type GetMainClaimListingOutput = {
  __typename?: 'GetMainClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  TotalClaimApproved?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type GetOsFulfilmentByJobGradeOutput = {
  __typename?: 'GetOSFulfilmentByJobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type GradingTable = {
  __typename?: 'GradingTable';
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GradingTableInput = {
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GroupCountEmployeeRoleListingOutput = {
  __typename?: 'GroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type GroupedMileageLogs = {
  __typename?: 'GroupedMileageLogs';
  period: Scalars['String'];
  vehicleTypeID: Scalars['String'];
  totalDistance: Scalars['Float'];
  logs: Array<MileageLogEntity>;
};

export type HrdcClaimInput = {
  hrdc_claim_id?: Maybe<Scalars['String']>;
  scheduled_training_id?: Maybe<Scalars['String']>;
  application_no?: Maybe<Scalars['String']>;
  amount_claimed?: Maybe<Scalars['Float']>;
  total_trainee?: Maybe<Scalars['Float']>;
  approved_claimed?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  attachment?: Maybe<DocumentsInput>;
  voice_input?: Maybe<DocumentsInput>;
  approver_id?: Maybe<Scalars['String']>;
};

export type HrdfCalculationInput = {
  EmployeeID: Scalars['String'];
  HRDFChargableAmount: Scalars['Float'];
  HRDFRate: Scalars['Float'];
};

export type HrdfCalculationOutput = {
  __typename?: 'HRDFCalculationOutput';
  EmployeeID: Scalars['String'];
  EmployerHRDFAmount?: Maybe<Scalars['Float']>;
  HRDFRate: Scalars['Float'];
};

export type HrdfClaimEntity = InterfaceBase & {
  __typename?: 'HRDFClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HRDFClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type HrdfClaimInput = {
  HRDFClaimID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HrdfClaimOutput = {
  __typename?: 'HRDFClaimOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  LatestClaimRefNo?: Maybe<Scalars['String']>;
  LatestClaimAmount?: Maybe<Scalars['Float']>;
};

export type HelpdeskListingReportInput = {
  CompanyID: Scalars['String'];
  SubmissionStartDate?: Maybe<Scalars['DateTime']>;
  SubmissionEndDate?: Maybe<Scalars['DateTime']>;
  ClosedStartDate?: Maybe<Scalars['DateTime']>;
  ClosedEndDate?: Maybe<Scalars['DateTime']>;
  AssigneeIDs: Array<Scalars['JSON']>;
  StatusLists: Array<Status>;
};

export type HelpdeskSubjectEntity = InterfaceBase & {
  __typename?: 'HelpdeskSubjectEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment?: Maybe<Scalars['Boolean']>;
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Assignees: Scalars['JSON'];
  InUse: Scalars['Boolean'];
};

export type HelpdeskSubjectInput = {
  ID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment: Scalars['Boolean'];
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
};

export type HelpdeskTicketEntity = InterfaceBase & {
  __typename?: 'HelpdeskTicketEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  HelpdeskSubjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubjectDetails: Scalars['JSON'];
  TicketNo: Scalars['String'];
  ContactID: Scalars['String'];
  RequestDescription: Scalars['String'];
  RequestAttachment?: Maybe<Array<Scalars['JSON']>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<Scalars['JSON']>>;
  ReassignedDT?: Maybe<Scalars['DateTime']>;
  OpenDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
  Employee?: Maybe<EmployeeEntity>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  RequestDocuments?: Maybe<Array<DocumentsEntity>>;
  ResolutionDocuments?: Maybe<Array<DocumentsEntity>>;
  CurrentAssignees: Scalars['JSON'];
  AssigneeClosedTicket?: Maybe<Scalars['String']>;
};

export type HelpdeskTicketInput = {
  HelpdeskTicketID?: Maybe<Scalars['String']>;
  HelpdeskSubjectID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TicketNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  RequestDescription?: Maybe<Scalars['String']>;
  RequestAttachment?: Maybe<Array<DocumentsInput>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<DocumentsInput>>;
  ReassignedDT?: Maybe<Scalars['String']>;
  OpenDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
};

export type HolidayEntity = InterfaceBase & {
  __typename?: 'HolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HolidayID: Scalars['String'];
  RestOffType: RestOffType;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
};

export type HolidayInput = {
  HolidayID?: Maybe<Scalars['String']>;
  RestOffType?: Maybe<RestOffType>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  currentEmployeeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveTypeHeader?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  UntillDate?: Maybe<Scalars['DateTime']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  myBirthDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  leaveType?: Maybe<Scalars['String']>;
  HolidayDescription?: Maybe<Scalars['String']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export enum HrPermission {
  HomeRead = 'HOME__READ',
  HomeCalendarRead = 'HOME_CALENDAR_READ',
  HomeCalendarBirthday = 'HOME_CALENDAR_BIRTHDAY',
  HomeDashboardRead = 'HOME_DASHBOARD____READ',
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager',
  HomeMymenuMyPersonalInfoRead = 'HOME_MYMENU_MY_PERSONAL_INFO_____READ',
  HomeMymenuRead = 'HOME_MYMENU_____READ',
  HomeMymenuMyAppovalDelegationRead = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_READ',
  HomeMymenuMyAppovalDelegationCreate = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_CREATE',
  HomeMymenuMyAppovalDelegationUpdate = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_UPDATE',
  HomeMymenuMyAppovalDelegationDelete = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_DELETE',
  HomeMymenuMyPersonalInfoPersonalProfileRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_PROFILE_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEducationMymenuView = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____VIEW',
  HomeMymenuMyPersonalInfoEducationMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEducationMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoCertificateMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____READ',
  HomeMymenuMyPersonalInfoCertificateMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoCertificateMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoCertificateMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____READ',
  HomeMymenuMyPersonalInfoReferencesMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoReferencesMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoDependentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_DEPENDENT_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____READ',
  HomeMymenuMyPersonalInfoPersonalDocumentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoJobInfoMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_JOB_INFO_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_____READ',
  HomeMymenuMyEmploymentInfoAssetAssignedMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoPerformanceJournalMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoInsuranceSchemeMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoCareerMovementMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoGeneralDocumentMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_GENERAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoEmployeeUserDefineFieldsRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomeMymenuMyPayrollInfoRead = 'HOME_MYMENU_MY_PAYROLL_INFO_____READ',
  HomeMymenuMyPayrollInfoViewPayrollHistoryMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_VIEW_PAYROLL_HISTORY_MYMENU_____READ',
  HomeMymenuMyPayrollInfoStatutoryInfoMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_STATUTORY_INFO_MYMENU_____READ',
  HomeMymenuMyPayrollInfoTp3MymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_TP3_MYMENU_____READ',
  HomeMymenuMyPayrollInfoPayslipMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_PAYSLIP_MYMENU_____READ',
  HomeMymenuMyPayrollInfoEaFormMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_EA_FORM_MYMENU_____READ',
  HomeMymenuMyLeaveRead = 'HOME_MYMENU_MY_LEAVE_____READ',
  HomeMymenuMyLeaveCreate = 'HOME_MYMENU_MY_LEAVE_____CREATE',
  HomeMymenuMyLeaveUpdate = 'HOME_MYMENU_MY_LEAVE_____UPDATE',
  HomeMymenuMyLeaveSubmit = 'HOME_MYMENU_MY_LEAVE_____SUBMIT',
  HomeMymenuMyLeaveDelete = 'HOME_MYMENU_MY_LEAVE_____DELETE',
  HomeMymenuMyLeaveCancel = 'HOME_MYMENU_MY_LEAVE_____CANCEL',
  HomeMymenuMyLeaveWithdraw = 'HOME_MYMENU_MY_LEAVE_____WITHDRAW',
  HomeMymenuMyLeaveEntitlementRead = 'HOME_MYMENU_MY_LEAVE_ENTITLEMENT_____READ',
  HomeMymenuMyClaimEntitlementRead = 'HOME_MYMENU_MY_CLAIM_ENTITLEMENT_____READ',
  HomeMymenuMyClaimRead = 'HOME_MYMENU_MY_CLAIM_____READ',
  HomeMymenuMyClaimCreate = 'HOME_MYMENU_MY_CLAIM_____CREATE',
  HomeMymenuMyClaimUpdate = 'HOME_MYMENU_MY_CLAIM_____UPDATE',
  HomeMymenuMyClaimDelete = 'HOME_MYMENU_MY_CLAIM_____DELETE',
  HomeMymenuMyClaimCancel = 'HOME_MYMENU_MY_CLAIM_____CANCEL',
  HomeMymenuMyClaimSubmit = 'HOME_MYMENU_MY_CLAIM_____SUBMIT',
  HomeMymenuMyClaimWithdraw = 'HOME_MYMENU_MY_CLAIM_____WITHDRAW',
  HomeMymenuMyProjectExpenseClaimWithdraw = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMymenuMyProjectExpenseClaimSubmit = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMymenuMyProjectExpenseClaimCreate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMymenuMyProjectExpenseClaimRead = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMymenuMyProjectExpenseClaimUpdate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____UPDATE',
  HomeMymenuMyProjectExpenseClaimDelete = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____DELETE',
  HomeMymenuMyProjectExpenseClaimCancel = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMymenuMyRequisitionDelete = 'HOME_MYMENU_MY_REQUISITION_____DELETE',
  HomeMymenuMyRequisitionSubmit = 'HOME_MYMENU_MY_REQUISITION_____SUBMIT',
  HomeMymenuMyRequisitionRead = 'HOME_MYMENU_MY_REQUISITION_____READ',
  HomeMymenuMyRequisitionCreate = 'HOME_MYMENU_MY_REQUISITION_____CREATE',
  HomeMymenuMyRequisitionUpdate = 'HOME_MYMENU_MY_REQUISITION_____UPDATE',
  HomeMymenuMyRequisitionWithdraw = 'HOME_MYMENU_MY_REQUISITION_____WITHDRAW',
  HomeMymenuMyRequisitionCancel = 'HOME_MYMENU_MY_REQUISITION_____CANCEL',
  HomeMymenuMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_____READ',
  HomeMymenuMyTimesheetMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____READ',
  HomeMymenuMyTimesheetMyTimesheetCreate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____CREATE',
  HomeMymenuMyTimesheetMyTimesheetDelete = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____DELETE',
  HomeMymenuMyTimesheetMyTimesheetUpdate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryCreate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeMymenuMyTimesheetProjectTimesheetEntryDelete = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeMymenuMyTimesheetProjectTimesheetEntryUpdate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionSubmit = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeMymenuMyTimeAttendanceRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_____READ',
  HomeMymenuMyTimeAttendanceDutyRosterMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_DUTY_ROSTER_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceAttendanceMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_MYMENU_____READ',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateRead = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____READ',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateCreate = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____CREATE',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateCancel = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateWithdraw = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____WITHDRAW',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____CANCEL',
  HomeMymenuMyPerformanceRead = 'HOME_MYMENU_MY_PERFORMANCE_____READ',
  HomeMymenuMyPerformanceKpiMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuDelete = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____DELETE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiRepositoryMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____UPDATE',
  HomeMymenuMyDataChangeRequestRead = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____READ',
  HomeMymenuMyDataChangeRequestCreate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CREATE',
  HomeMymenuMyDataChangeRequestSubmit = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____SUBMIT',
  HomeMymenuMyDataChangeRequestCancel = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CANCEL',
  HomeMymenuMyDataChangeRequestUpdate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____UPDATE',
  HomeMymenuMyDataChangeRequestDelete = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____DELETE',
  HomeMysubordinateRead = 'HOME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_____READ',
  HomeMysubordinateSubordinatePersonalInfoPersonalProfileMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_PROFILE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoDependentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_DEPENDENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoPersonalDocumentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_DOCUMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_____READ',
  HomeMysubordinateSubordinateEmploymentInfoJobInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_JOB_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoStatutoryInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_STATUTORY_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoTp3MysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_TP3_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoAssetAssignedMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoPerformanceJournalMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoInsuranceSchemeMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoCareerMovementMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoEmployeeUserDefineFieldsMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateTimesheetRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TIMESHEET_____READ',
  HomeMysubordinateSubordinateLeaveRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____READ',
  HomeMysubordinateSubordinateLeaveSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____SUBMIT',
  HomeMysubordinateSubordinateLeaveCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CREATE',
  HomeMysubordinateSubordinateLeaveWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____WITHDRAW',
  HomeMysubordinateSubordinateLeaveCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CANCEL',
  HomeMysubordinateSubordinateClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CREATE',
  HomeMysubordinateSubordinateClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____READ',
  HomeMysubordinateSubordinateClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateLeaveEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____SUBMIT',
  HomeMysubordinateSubordinateRequisitionCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CREATE',
  HomeMysubordinateSubordinateRequisitionRead = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____READ',
  HomeMysubordinateSubordinateRequisitionCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateProjectExpenseClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMysubordinateSubordinateProjectExpenseClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateProjectExpenseClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSave = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SAVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceDutyRosterMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_DUTY_ROSTER_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSubordinateOrganizationChartRead = 'HOME_MYSUBORDINATE_SUBORDINATE_ORGANIZATION_CHART_____READ',
  HomeMysubordinateTrainingManagementRead = 'HOME_MYSUBORDINATE_TRAINING_MANAGEMENT_____READ',
  HomeMysubordinateSubordinateListingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LISTING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingScheduledTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_SCHEDULED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequiredTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUIRED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequiredTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUIRED_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____CREATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____WITHDRAW',
  HomeMysubordinateSubordinateTrainingScheduledTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingScheduledTrainingCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____CREATE',
  HomeMysubordinateSubordinateTrainingScheduledTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingTrainingCalendarRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____READ',
  HomeMysubordinateSubordinateTrainingTrainingCalendarCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____CREATE',
  HomeMysubordinateSubordinateTrainingTrainingCalendarUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____UPDATE',
  HomeMysubordinateSubordinateTrainingTrainingRecordsRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_RECORDS_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentByCompulsoryCoursesRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_BY_COMPULSORY_COURSES_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentByTargetHoursRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_BY_TARGET_HOURS_____READ',
  HomeMysubordinateScheduledTrainingCreate = 'HOME_MYSUBORDINATE_SCHEDULED_TRAINING_____CREATE',
  HomeMysubordinateSubordinateTrainingScheduledTrainingView = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____VIEW',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateApprove = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____APPROVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateReInitiate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____RE_INITIATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSendBack = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SEND_BACK',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateProceed = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____PROCEED',
  HomeOnBehalfRead = 'HOME_ON_BEHALF_____READ',
  HomeOnBehalfLeaveRead = 'HOME_ON_BEHALF_LEAVE_____READ',
  HomeOnBehalfLeaveEntitlement = 'HOME_ON_BEHALF_LEAVE_____ENTITLEMENT',
  HomeOnBehalfLeaveCreate = 'HOME_ON_BEHALF_LEAVE_____CREATE',
  HomeOnBehalfLeaveCancel = 'HOME_ON_BEHALF_LEAVE_____CANCEL',
  HomeOnBehalfLeaveWithdraw = 'HOME_ON_BEHALF_LEAVE_____WITHDRAW',
  HomeOnBehalfClaimRead = 'HOME_ON_BEHALF_CLAIM_____READ',
  HomeOnBehalfClaimEntitlement = 'HOME_ON_BEHALF_CLAIM_____ENTITLEMENT',
  HomeOnBehalfClaimCreate = 'HOME_ON_BEHALF_CLAIM_____CREATE',
  HomeOnBehalfClaimWithdraw = 'HOME_ON_BEHALF_CLAIM_____WITHDRAW',
  HomeOnBehalfClaimCancel = 'HOME_ON_BEHALF_CLAIM_____CANCEL',
  HomeOnBehalfTimeAttendanceRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_____READ',
  HomeOnBehalfTimeAttendanceOvertimeRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____READ',
  HomeOnBehalfTimeAttendanceOvertimeCreate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____CREATE',
  HomeOnBehalfTimeAttendanceOvertimeCancel = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____CANCEL',
  HomeOnBehalfTimeAttendanceOvertimeWithdraw = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____WITHDRAW',
  HomeOnBehalfTimeAttendanceOvertimeDelete = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____DELETE',
  HomeOnBehalfTimeAttendanceOvertimeUpdate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____UPDATE',
  HomeOnBehalfTimeAttendanceTimeOffRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____READ',
  HomeOnBehalfTimeAttendanceTimeOffCancel = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____CANCEL',
  HomeOnBehalfTimeAttendanceTimeOffWithdraw = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____WITHDRAW',
  HomeOnBehalfTimeAttendanceTimeOffUpdate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____UPDATE',
  HomeOnBehalfTimeAttendanceTimeOffCreate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____CREATE',
  HomeOnBehalfTimeAttendanceTimeOffDelete = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____DELETE',
  HomeOnBehalfTimeAttendanceChangeShiftRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____READ',
  HomeOnBehalfTimeAttendanceChangeShiftCancel = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____CANCEL',
  HomeOnBehalfTimeAttendanceChangeShiftWithdraw = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____WITHDRAW',
  HomeOnBehalfTimeAttendanceChangeShiftCreate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____CREATE',
  HomeOnBehalfTimeAttendanceChangeShiftUpdate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____UPDATE',
  HomeOnBehalfTimeAttendanceChangeShiftDelete = 'HOME_ON_BEHALF_TIME_ATTENDANCE_CHANGE_SHIFT_____DELETE',
  HomeTrainingRead = 'HOME_TRAINING_______READ',
  HomeTimesheetRead = 'HOME_TIMESHEET_______READ',
  CompanySettingTimesheetRead = 'COMPANY_SETTING_TIMESHEET_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______CREATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______UPDATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityDelete = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______DELETE',
  CompanySettingTimesheetTimesheetAccountCodeRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______READ',
  CompanySettingTimesheetTimesheetAccountCodeCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______CREATE',
  CompanySettingTimesheetTimesheetAccountCodeUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______UPDATE',
  HomeTimesheetEmployeeTimesheetRead = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______READ',
  HomeTimesheetEmployeeTimesheetExport = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______EXPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetEntrySubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY_SUBMISSION_______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryCreate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryDelete = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryUpdate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionSubmit = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverGenerateReport = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______GENERATE_REPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverSendEmail = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______SEND_EMAIL',
  HomeTimesheetMandayAnalysisByAccountCodeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______READ',
  HomeTimesheetMandayAnalysisByAccountCodeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______EXPORT',
  HomeTimesheetMandayAnalysisByAccountTypeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______READ',
  HomeTimesheetMandayAnalysisByAccountTypeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______EXPORT',
  HomeAdminStandardAutoNumberingRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______UPDATE',
  HomeAdminStandardAutoNumberingClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingTravelRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingTravelRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingAssetRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingAssetRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingOtherRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingOtherRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______READ',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______UPDATE',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______READ',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  HomeAdminStandardAutoNumberingRecruitmentRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Recruitment_Requisition_ID_______READ',
  HomeAdminStandardAutoNumberingRecruitmentRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Recruitment_Requisition_ID_______UPDATE',
  HomeAdminStandardAutoNumberingJobVacancyIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Job_Vacancy_ID_______READ',
  HomeAdminStandardAutoNumberingJobVacancyIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Job_Vacancy_ID_______UPDATE',
  CompanySettingAdminRead = 'COMPANY_SETTING_ADMIN_______READ',
  CompanySettingAdminCreate = 'COMPANY_SETTING_ADMIN_______CREATE',
  CompanySettingAdminCompanyInfoRead = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______READ',
  CompanySettingAdminCompanyInfoUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______UPDATE',
  CompanySettingAdminStatutoryInfoRead = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______READ',
  CompanySettingAdminStatutoryInfoUpdate = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______UPDATE',
  CompanySettingAdminCompanyHierarchyStructureRead = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______READ',
  CompanySettingAdminCompanyHierarchyStructureUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______UPDATE',
  CompanySettingAdminOrgUnitStructureRead = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______READ',
  CompanySettingAdminOrgUnitStructureUpdate = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______UPDATE',
  CompanySettingAdminJobPositionByOrgUnitRead = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______READ',
  CompanySettingAdminJobPositionByOrgUnitUpdate = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______UPDATE',
  CompanySettingAdminReportingStructureRead = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______READ',
  CompanySettingAdminReportingStructureCreate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______CREATE',
  CompanySettingAdminReportingStructureUpdate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______UPDATE',
  CompanySettingAdminReportingStructureDelete = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______DELETE',
  CompanySettingAdminCalendarPolicyRead = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______READ',
  CompanySettingAdminCalendarPolicyCreate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______CREATE',
  CompanySettingAdminCalendarPolicyUpdate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______UPDATE',
  CompanySettingAdminCalendarPolicyDelete = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______DELETE',
  CompanySettingAdminCalendarPolicyInactive = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______INACTIVE',
  CompanySettingAdminCalendarPolicyReactivate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______REACTIVATE',
  CompanySettingAdminCompanyAccessLevelAuthorizationRead = 'COMPANY_SETTING_ADMIN_COMPANY_ACCESS_LEVEL_AUTHORIZATION_______READ',
  CompanySettingAdminAccessPermissionRead = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______READ',
  CompanySettingAdminAccessPermissionUpdate = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  CompanySettingAdminGeneralDocumentRead = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______READ',
  CompanySettingAdminGeneralDocumentView = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______VIEW',
  CompanySettingAdminGeneralDocumentCreate = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentDelete = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______DELETE',
  CompanySettingAdminEmployeeAppSecurityAssignmentRead = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______READ',
  CompanySettingAdminEmployeeAppSecurityAssignmentView = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______VIEW',
  CompanySettingAdminEmployeeAppSecurityAssignmentCreate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentUpdate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______UPDATE',
  CompanySettingAdminGeneralDocumentDelete = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______DELETE',
  CommonSettingAdminRead = 'COMMON_SETTING_ADMIN_______READ',
  CommonSettingTrainingRead = 'COMMON_SETTING_TRAINING_______READ',
  CommonSettingTrainingCourseCategoryRead = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______READ',
  CommonSettingTrainingCourseCategoryCreate = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______CREATE',
  CommonSettingTrainingCourseCategoryUpdate = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______UPDATE',
  CommonSettingTrainingCourseCategoryDelete = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______DELETE',
  CommonSettingTrainingTrainingCoursesLibraryRead = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______READ',
  CommonSettingTrainingTrainingCoursesLibraryCreate = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______CREATE',
  CommonSettingTrainingTrainingCoursesLibraryUpdate = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______UPDATE',
  CommonSettingTrainingTrainingCoursesLibraryDelete = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______DELETE',
  CommonSettingTrainingTrainingProvidersRead = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______READ',
  CommonSettingTrainingTrainingProvidersCreate = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______CREATE',
  CommonSettingTrainingTrainingProvidersUpdate = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______UPDATE',
  CommonSettingTrainingTrainingProvidersDelete = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______DELETE',
  CommonSettingTrainingTrainingExpensesRead = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______READ',
  CommonSettingTrainingTrainingExpensesCreate = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______CREATE',
  CommonSettingTrainingTrainingExpensesUpdate = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______UPDATE',
  CommonSettingTrainingTrainingExpensesDelete = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______DELETE',
  CommonSettingTrainingTrainingApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingRequestApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingRequestApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingRequestApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingRequestApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingRequestApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingRequestApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingNominationApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingNominationApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingNominationApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingNominationApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingNominationApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingNominationApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainerProfileRead = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______READ',
  CommonSettingTrainingTrainerProfileCreate = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______CREATE',
  CommonSettingTrainingTrainerProfileUpdate = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______UPDATE',
  CommonSettingTrainingTrainerProfileDelete = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______DELETE',
  CommonSettingTrainingTrainingSurveyRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______READ',
  CommonSettingTrainingTrainingSurveyCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______CREATE',
  CommonSettingTrainingTrainingSurveyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______UPDATE',
  CommonSettingTrainingTrainingSurveyDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyTypeRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______READ',
  CommonSettingTrainingTrainingSurveySurveyTypeCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyTypeUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyTypeDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyItemsRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______READ',
  CommonSettingTrainingTrainingSurveySurveyItemsCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyItemsUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyItemsDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyFormRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______READ',
  CommonSettingTrainingTrainingSurveySurveyFormCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyFormUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyFormDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______DELETE',
  CommonSettingTrainingBudgetGroupRead = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______READ',
  CommonSettingTrainingBudgetGroupCreate = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______CREATE',
  CommonSettingTrainingBudgetGroupUpdate = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______UPDATE',
  CommonSettingTrainingBudgetGroupDelete = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______DELETE',
  CommonSettingTrainingTargetGroupRead = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______READ',
  CommonSettingTrainingTargetGroupCreate = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______CREATE',
  CommonSettingTrainingTargetGroupUpdate = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______UPDATE',
  CommonSettingTrainingTargetGroupDelete = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______DELETE',
  HomeTrainingTrainingSettingsRead = 'HOME_TRAINING_TRAINING_SETTINGS____READ',
  HomeTrainingTrainingSettingBudgetRead = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____READ',
  HomeTrainingTrainingSettingBudgetUpdate = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____UPDATE',
  HomeTrainingTrainingSettingBudgetCreate = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____CREATE',
  HomeTrainingTrainingSettingBudgetDelete = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____DELETE',
  HomeTrainingTrainingSettingTargetRead = 'HOME_TRAINING_TRAINING_SETTING_TARGET____READ',
  HomeTrainingTrainingSettingTargetUpdate = 'HOME_TRAINING_TRAINING_SETTING_TARGET____UPDATE',
  HomeTrainingTrainingSettingTargetCreate = 'HOME_TRAINING_TRAINING_SETTING_TARGET____CREATE',
  HomeTrainingTrainingSettingTargetDelete = 'HOME_TRAINING_TRAINING_SETTING_TARGET____DELETE',
  HomeTrainingTrainingSettingApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_APPROVAL____READ',
  HomeTrainingTrainingSettingRequestApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____READ',
  HomeTrainingTrainingSettingRequestApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____CREATE',
  HomeTrainingTrainingSettingRequestApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingRequestApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____DELETE',
  HomeTrainingTrainingSettingNominationApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____READ',
  HomeTrainingTrainingSettingNominationApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____CREATE',
  HomeTrainingTrainingSettingNominationApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingNominationApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____DELETE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____READ',
  HomeTrainingTrainingSettingSelfRegistrationApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____CREATE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____DELETE',
  HomeTrainingTrainingSettingWithdrawalApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____READ',
  HomeTrainingTrainingSettingWithdrawalApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____CREATE',
  HomeTrainingTrainingSettingWithdrawalApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingWithdrawalApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____DELETE',
  HomeTrainingTrainingExpensesView = 'HOME_TRAINING_TRAINING_EXPENSES____VIEW',
  HomeTrainingTrainingExpensesUpdate = 'HOME_TRAINING_TRAINING_EXPENSES____UPDATE',
  HomeTrainingTrainingMaintenanceRead = 'HOME_TRAINING_TRAINING_MAINTENANCE____READ',
  HomeTrainingTrainingMaintenanceBudgetRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____READ',
  HomeTrainingTrainingMaintenanceBudgetUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____UPDATE',
  HomeTrainingTrainingMaintenanceBudgetCreate = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____CREATE',
  HomeTrainingTrainingMaintenanceBudgetDelete = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____DELETE',
  HomeTrainingTrainingMaintenanceCompulsoryCoursesRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_COMPULSORY_COURSES____READ',
  HomeTrainingTrainingMaintenanceCompulsoryCoursesUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_COMPULSORY_COURSES____UPDATE',
  HomeTrainingTrainingMaintenanceTargetHoursRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____READ',
  HomeTrainingTrainingMaintenanceTargetHoursUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____UPDATE',
  HomeTrainingTrainingMaintenanceTargetHoursCreate = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____CREATE',
  HomeTrainingTrainingMaintenanceTargetHoursDelete = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____DELETE',
  HomeTrainingTrainingRequestRead = 'HOME_TRAINING_TRAINING_REQUEST____READ',
  HomeTrainingTrainingRequestUpdate = 'HOME_TRAINING_TRAINING_REQUEST____UPDATE',
  HomeTrainingTrainingRequestCreate = 'HOME_TRAINING_TRAINING_REQUEST____CREATE',
  HomeTrainingTrainingRequestDelete = 'HOME_TRAINING_TRAINING_REQUEST____DELETE',
  HomeTrainingTrainingRequestWithdraw = 'HOME_TRAINING_TRAINING_REQUEST____WITHDRAW',
  HomeTrainingTrainingEventScheduleRead = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____READ',
  HomeTrainingTrainingEventScheduleUpdate = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____UPDATE',
  HomeTrainingTrainingEventScheduleCreate = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____CREATE',
  HomeTrainingTrainingEventAttendanceRead = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____READ',
  HomeTrainingTrainingEventAttendanceSave = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____SAVE',
  HomeTrainingTrainingEventAttendanceUpdate = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____UPDATE',
  HomeTrainingTrainingEvaluationRead = 'HOME_TRAINING_TRAINING_EVALUATION____READ',
  HomeTrainingTrainingEvaluationEventEvaluationRead = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION____READ',
  HomeTrainingTrainingEvaluationEventEvaluationCreate = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION____CREATE',
  HomeTrainingTrainingEvaluationEventEvaluationRevert = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______REVERT',
  HomeTrainingTrainingEvaluationEventEvaluationUpload = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______UPLOAD',
  HomeTrainingTrainingEvaluationEventEvaluationUpdate = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______UPDATE',
  HomeTrainingTrainingEvaluationEventEvaluationDelete = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______DELETE',
  HomeTrainingTrainingEvaluationEvaluationResultsRead = 'HOME_TRAINING_TRAINING_EVALUATION_EVALUATION_RESULTS____READ',
  HomeTrainingTrainingEvaluationEvaluationResultsUpdate = 'HOME_TRAINING_TRAINING_EVALUATION_EVALUATION_RESULTS_______UPDATE',
  HomeTrainingTrainingExpensesRead = 'HOME_TRAINING_TRAINING_EXPENSES____READ',
  HomeTrainingHrdcClaimRead = 'HOME_TRAINING_HRDC_CLAIM____READ',
  HomeTrainingHrdcClaimCreate = 'HOME_TRAINING_HRDC_CLAIM____CREATE',
  HomeTrainingHrdcClaimUpdate = 'HOME_TRAINING_HRDC_CLAIM____UPDATE',
  HomeTrainingHrdcClaimDelete = 'HOME_TRAINING_HRDC_CLAIM____DELETE',
  HomeTrainingHrdcClaimUpdateStatus = 'HOME_TRAINING_HRDC_CLAIM____UPDATE_STATUS',
  HomeTrainingHrdcClaimStatusLog = 'HOME_TRAINING_HRDC_CLAIM____STATUS_LOG',
  HomeTrainingTrainingFulfillmentRead = 'HOME_TRAINING_TRAINING_FULFILLMENT____READ',
  HomeTrainingTrainingRecordsRead = 'HOME_TRAINING_TRAINING_RECORDS____READ',
  HomeAdminExecSummaryRead = 'HOME_ADMIN_EXEC_SUMMARY____READ',
  HomeAdminExternalUserRead = 'HOME_ADMIN_EXTERNAL_USER____READ',
  HomeAdminExternalUserReadUser = 'HOME_ADMIN_EXTERNAL_USER_READ_USER',
  HomeAdminExternalUserReadEmployee = 'HOME_ADMIN_EXTERNAL_USER_READ_EMPLOYEE',
  HomeAdminExternalUserView = 'HOME_ADMIN_EXTERNAL_USER_______VIEW',
  HomeAdminExternalUserCreate = 'HOME_ADMIN_EXTERNAL_USER_______CREATE',
  HomeAdminExternalUserUpdate = 'HOME_ADMIN_EXTERNAL_USER_______UPDATE',
  HomeAdminExternalUserUnblock = 'HOME_ADMIN_EXTERNAL_USER_______UNBLOCK',
  HomeAdminExternalUserResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_______RESEND_INVITATION',
  HomeAdminExternalUserChangePassword = 'HOME_ADMIN_EXTERNAL_USER_______CHANGE_PASSWORD',
  HomeAdminExternalUserCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_READ',
  HomeAdminExternalUserCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_UPDATE',
  HomeAdminExternalUserChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeView = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_VIEW',
  HomeAdminExternalUserEmployeeChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeInactive = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_INACTIVE',
  HomeAdminExternalUserEmployeeReactivate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_REACTIVATE',
  HomeAdminExternalUserEmployeeChangePassword = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_PASSWORD',
  HomeAdminExternalUserEmployeeResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_RESEND_INVITATION',
  HomeAdminExternalUserEmployeeHelpdeskAssignee = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_HELPDESK_ASSIGNEE',
  HomeAdminExternalUserEmployeeCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_READ',
  HomeAdminExternalUserEmployeeCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_UPDATE',
  HomeAdminAccessLevelRead = 'HOME_ADMIN_ACCESS_LEVEL____READ',
  HomeAdminAccessLevelView = 'HOME_ADMIN_ACCESS_LEVEL_______VIEW',
  HomeAdminAccessLevelCreate = 'HOME_ADMIN_ACCESS_LEVEL_______CREATE',
  HomeAdminAccessLevelUpdate = 'HOME_ADMIN_ACCESS_LEVEL_______UPDATE',
  HomeAdminAccessLevelDelete = 'HOME_ADMIN_ACCESS_LEVEL_______DELETE',
  HomeAdminEmployeeAppSecurityRead = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY____READ',
  HomeAdminEmployeeAppSecurityView = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______VIEW',
  HomeAdminEmployeeAppSecurityCreate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______CREATE',
  HomeAdminEmployeeAppSecurityUpdate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______UPDATE',
  HomeAdminEmployeeAppSecurityDelete = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______DELETE',
  HomePersonnelExecSummaryRead = 'HOME_PERSONNEL_EXEC_SUMMARY____READ',
  HomePersonnelRead = 'HOME_PERSONNEL_______READ',
  HomeAdminBulletinRead = 'HOME_ADMIN_BULLETIN______READ',
  HomeAdminBulletinCreate = 'HOME_ADMIN_BULLETIN______CREATE',
  HomeAdminBulletinUpdate = 'HOME_ADMIN_BULLETIN______UPDATE',
  HomeAdminBulletinDelete = 'HOME_ADMIN_BULLETIN______DELETE',
  HomeAdminBulletinPublish = 'HOME_ADMIN_BULLETIN______PUBLISH',
  HomeAdminBulletinUnpublish = 'HOME_ADMIN_BULLETIN______UNPUBLISH',
  HomeAdminBulletinDuplicate = 'HOME_ADMIN_BULLETIN______DUPLICATE',
  HomeAdminBulletinAcknowledgedList = 'HOME_ADMIN_BULLETIN______ACKNOWLEDGED_LIST',
  HomeAdminReportingStructureGroupRead = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______READ',
  HomeAdminReportingStructureGroupCreate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______CREATE',
  HomeAdminReportingStructureGroupUpdate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______UPDATE',
  HomeAdminReportingStructureGroupDelete = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______DELETE',
  CommonSettingPersonnelRead = 'COMMON_SETTING_PERSONNEL_______READ',
  CommonSettingPersonnelPersonnelParameterRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___READ',
  CommonSettingPersonnelPersonnelParameterCreate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___CREATE',
  CommonSettingPersonnelPersonnelParameterUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___UPDATE',
  CommonSettingPersonnelPersonnelParameterDelete = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___DELETE',
  CommonSettingPersonnelPersonnelParameterInactive = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___INACTIVE',
  CommonSettingPersonnelPersonnelParameterReactivate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___REACTIVATE',
  CommonSettingPersonnelJobGradeRead = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___READ',
  CommonSettingPersonnelJobGradeCreate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___CREATE',
  CommonSettingPersonnelJobGradeUpdate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___UPDATE',
  CommonSettingPersonnelJobGradeDelete = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___DELETE',
  CommonSettingPersonnelSkillSetupRead = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___READ',
  CommonSettingPersonnelSkillSetupCreate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___CREATE',
  CommonSettingPersonnelSkillSetupUpdate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___UPDATE',
  CommonSettingPersonnelSkillSetupDelete = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___DELETE',
  CommonSettingPersonnelJobPositionRead = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___READ',
  CommonSettingPersonnelJobPositionCreate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___CREATE',
  CommonSettingPersonnelJobPositionUpdate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___UPDATE',
  CommonSettingPersonnelJobPositionDelete = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___DELETE',
  CommonSettingPersonnelInsuranceSchemeRead = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CommonSettingPersonnelInsuranceSchemeCreate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___CREATE',
  CommonSettingPersonnelInsuranceSchemeUpdate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CommonSettingPersonnelInsuranceSchemeDelete = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___DELETE',
  CommonSettingPersonnelRecruitmentSourceRead = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CommonSettingPersonnelRecruitmentSourceCreate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___CREATE',
  CommonSettingPersonnelRecruitmentSourceUpdate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CommonSettingPersonnelRecruitmentSourceDelete = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___DELETE',
  CommonSettingPersonnelUserDefinedRead = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___READ',
  CommonSettingPersonnelUserDefinedCreate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___CREATE',
  CommonSettingPersonnelUserDefinedUpdate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CommonSettingPersonnelUserDefinedDelete = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___DELETE',
  CommonSettingPersonnelELetterTemplateRead = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CommonSettingPersonnelELetterTemplateUpdate = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___UPDATE',
  CompanySettingPersonnelRead = 'COMPANY_SETTING_PERSONNEL_______READ',
  CompanySettingPersonnelInsuranceSchemeRead = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CompanySettingPersonnelInsuranceSchemeUpdate = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CompanySettingPersonnelRecruitmentSourceRead = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CompanySettingPersonnelRecruitmentSourceUpdate = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CompanySettingPersonnelUserDefinedRead = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___READ',
  CompanySettingPersonnelUserDefinedUpdate = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CompanySettingPersonnelCostCenterMappingRead = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___READ',
  CompanySettingPersonnelCostCenterMappingUpdate = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___UPDATE',
  CompanySettingPersonnelELetterTemplateRead = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CompanySettingPersonnelELetterTemplatedUpdate = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATED___UPDATE',
  CompanySettingPersonnelEmployeeUserDefineFieldsRead = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  CompanySettingPersonnelEmployeeUserDefineFieldsUpdate = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelPersonnelEFileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE____READ',
  HomePersonnelPersonnelEFilePersonnelProfileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___READ',
  HomePersonnelPersonnelEFilePersonnelProfileCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___CREATE',
  HomePersonnelPersonnelEFilePersonnelProfileUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___UPDATE',
  HomePersonnelPersonnelEFileEducationRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___READ',
  HomePersonnelPersonnelEFileEducationCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___CREATE',
  HomePersonnelPersonnelEFileEducationUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___UPDATE',
  HomePersonnelPersonnelEFileEducationDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___DELETE',
  HomePersonnelPersonnelEFileCertificateRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___READ',
  HomePersonnelPersonnelEFileCertificateCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___CREATE',
  HomePersonnelPersonnelEFileCertificateUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___UPDATE',
  HomePersonnelPersonnelEFileCertificateDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___DELETE',
  HomePersonnelPersonnelEFileReferencesRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___READ',
  HomePersonnelPersonnelEFileReferencesCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___CREATE',
  HomePersonnelPersonnelEFileReferencesUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___UPDATE',
  HomePersonnelPersonnelEFileReferencesDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___DELETE',
  HomePersonnelPersonnelEFileHealthInfoRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___READ',
  HomePersonnelPersonnelEFileHealthInfoUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___UPDATE',
  HomePersonnelPersonnelEFileDependentRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___READ',
  HomePersonnelPersonnelEFileDependentCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___CREATE',
  HomePersonnelPersonnelEFileDependentUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___UPDATE',
  HomePersonnelPersonnelEFileDependentDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___DELETE',
  HomePersonnelPersonnelEFileJobHistoryRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__READ',
  HomePersonnelPersonnelEFileJobHistoryCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__CREATE',
  HomePersonnelPersonnelEFileJobHistoryUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__UPDATE',
  HomePersonnelPersonnelEFileJobHistoryDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__DELETE',
  HomePersonnelPersonnelEFileUserDefinedRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___READ',
  HomePersonnelPersonnelEFileUserDefinedUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___UPDATE',
  HomePersonnelPersonnelEFileDocumentsRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___READ',
  HomePersonnelPersonnelEFileDocumentsCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___CREATE',
  HomePersonnelPersonnelEFileDocumentsDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___DELETE',
  HomePersonnelEmployeeInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO____CREATE',
  HomePersonnelEmployeeInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO____READ',
  HomePersonnelEmployeeInfoJobInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_READ',
  HomePersonnelEmployeeInfoJobInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_UPDATE',
  HomePersonnelEmployeeInfoPersonalInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_READ',
  HomePersonnelEmployeeInfoApprovalDelegationRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_READ',
  HomePersonnelEmployeeInfoApprovalDelegationCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_CREATE',
  HomePersonnelEmployeeInfoApprovalDelegationUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_UPDATE',
  HomePersonnelEmployeeInfoApprovalDelegationDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_DELETE',
  HomePersonnelEmployeeInfoPersonalInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_CREATE',
  HomePersonnelEmployeeInfoStatutoryInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_READ',
  HomePersonnelEmployeeInfoStatutoryInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_READ',
  HomePersonnelEmployeeInfoZakatContributionCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_CREATE',
  HomePersonnelEmployeeInfoZakatContributionUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_DELETE',
  HomePersonnelEmployeeInfoTp3Read = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_READ',
  HomePersonnelEmployeeInfoTp3Update = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____READ',
  HomePersonnelEmployeeInfoAssetAssignedCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____CREATE',
  HomePersonnelEmployeeInfoAssetAssignedUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____DELETE',
  HomePersonnelEmployeeInfoPerformanceJournalRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____READ',
  HomePersonnelEmployeeInfoPerformanceJournalCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____CREATE',
  HomePersonnelEmployeeInfoPerformanceJournalUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____UPDATE',
  HomePersonnelEmployeeInfoPerformanceJournalDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____DELETE',
  HomePersonnelEmployeeInfoInsuranceSchemeRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_INSURANCE_SCHEME____READ',
  HomePersonnelEmployeeInfoCareerMovementRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CAREER_MOVEMENT____READ',
  HomePersonnelEmployeeInfoSubordinateRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_SUBORDINATE____READ',
  HomePersonnelEmployeeInfoDigitalDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_DIGITAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoGeneralDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_GENERAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____CREATE',
  HomePersonnelEmployeeInfoContractDocumentUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____UPDATE',
  HomePersonnelEmployeeInfoContractDocumentDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____DELETE',
  HomePersonnelEmployeeInfoPaySlipRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PAY_SLIP____READ',
  HomePersonnelEmployeeInfoEaFormRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EA_FORM____READ',
  HomePersonnelEmployeeInfoELetterRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____READ',
  HomePersonnelEmployeeInfoELetterDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____DELETE',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelStaffConfirmationRead = 'HOME_PERSONNEL_STAFF_CONFIRMATION____READ',
  HomePersonnelStaffConfirmationUpdate = 'HOME_PERSONNEL_STAFF_CONFIRMATION____UPDATE',
  HomePersonnelProbationExtensionRead = 'HOME_PERSONNEL_PROBATION_EXTENSION____READ',
  HomePersonnelStaffPromotionRead = 'HOME_PERSONNEL_STAFF_PROMOTION____READ',
  HomePersonnelStaffPromotionCreate = 'HOME_PERSONNEL_STAFF_PROMOTION____CREATE',
  HomePersonnelStaffPromotionUpdate = 'HOME_PERSONNEL_STAFF_PROMOTION____UPDATE',
  HomePersonnelStaffPromotionDelete = 'HOME_PERSONNEL_STAFF_PROMOTION____DELETE',
  HomePersonnelStaffDemotionRead = 'HOME_PERSONNEL_STAFF_DEMOTION____READ',
  HomePersonnelStaffDemotionCreate = 'HOME_PERSONNEL_STAFF_DEMOTION____CREATE',
  HomePersonnelStaffDemotionUpdate = 'HOME_PERSONNEL_STAFF_DEMOTION____UPDATE',
  HomePersonnelStaffDemotionDelete = 'HOME_PERSONNEL_STAFF_DEMOTION____DELETE',
  HomePersonnelStaffRedesignationRead = 'HOME_PERSONNEL_STAFF_REDESIGNATION____READ',
  HomePersonnelStaffRedesignationCreate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____CREATE',
  HomePersonnelStaffRedesignationUpdate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____UPDATE',
  HomePersonnelStaffRedesignationDelete = 'HOME_PERSONNEL_STAFF_REDESIGNATION____DELETE',
  HomePersonnelStaffInternalTransferRead = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____READ',
  HomePersonnelStaffInternalTransferCreate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____CREATE',
  HomePersonnelStaffInternalTransferUpdate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____UPDATE',
  HomePersonnelStaffInternalTransferDelete = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____DELETE',
  HomePersonnelStaffInterCompanyTransferRead = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____READ',
  HomePersonnelStaffInterCompanyTransferCreate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____CREATE',
  HomePersonnelStaffInterCompanyTransferUpdate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____UPDATE',
  HomePersonnelStaffInterCompanyTransferDelete = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____DELETE',
  HomePersonnelStaffTerminationRead = 'HOME_PERSONNEL_STAFF_TERMINATION____READ',
  HomePersonnelStaffTerminationCreate = 'HOME_PERSONNEL_STAFF_TERMINATION____CREATE',
  HomePersonnelStaffTerminationUpdate = 'HOME_PERSONNEL_STAFF_TERMINATION____UPDATE',
  HomePersonnelStaffTerminationDelete = 'HOME_PERSONNEL_STAFF_TERMINATION____DELETE',
  HomePersonnelStaffOffboardingRead = 'HOME_PERSONNEL_STAFF_OFFBOARDING____READ',
  HomePersonnelStaffOffboardingCreate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____CREATE',
  HomePersonnelStaffOffboardingUpdate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____UPDATE',
  HomePersonnelStaffOffboardingDelete = 'HOME_PERSONNEL_STAFF_OFFBOARDING____DELETE',
  HomePersonnelStaffRetirementRead = 'HOME_PERSONNEL_STAFF_RETIREMENT____READ',
  HomePersonnelStaffRetirementUpdate = 'HOME_PERSONNEL_STAFF_RETIREMENT____UPDATE',
  HomePersonnelStaffRetirementDelete = 'HOME_PERSONNEL_STAFF_RETIREMENT____DELETE',
  HomePersonnelStaffInsuranceSchemeRead = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____READ',
  HomePersonnelStaffInsuranceSchemeCreate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____CREATE',
  HomePersonnelStaffInsuranceSchemeUpdate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____UPDATE',
  HomePayrollExecSummaryRead = 'HOME_PAYROLL_EXEC_SUMMARY____READ',
  HomePayrollRead = 'HOME_PAYROLL_______READ',
  CommonSettingPayrollRead = 'COMMON_SETTING_PAYROLL_______READ',
  CommonSettingPayrollPayItemRead = 'COMMON_SETTING_PAYROLL_PAY_ITEM___READ',
  CommonSettingPayrollPayItemCreate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___CREATE',
  CommonSettingPayrollPayItemUpdate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___UPDATE',
  CommonSettingPayrollPayItemDelete = 'COMMON_SETTING_PAYROLL_PAY_ITEM___DELETE',
  CommonSettingPayrollLhdnReportsRead = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___READ',
  CommonSettingPayrollLhdnReportsUpdate = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___UPDATE',
  CommonSettingPayrollLhdnReportsCopy = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___COPY',
  CommonSettingPayrollPayrollCycleRead = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___READ',
  CommonSettingPayrollPayrollCycleCreate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___CREATE',
  CommonSettingPayrollPayrollCycleUpdate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___UPDATE',
  CommonSettingPayrollPayrollCycleDelete = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___DELETE',
  CommonSettingPayrollAdvancePolicyRead = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___READ',
  CommonSettingPayrollAdvancePolicyCreate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___CREATE',
  CommonSettingPayrollAdvancePolicyUpdate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___UPDATE',
  CommonSettingPayrollAdvancePolicyDelete = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___DELETE',
  CommonSettingPayrollGlAccountMappingRead = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___READ',
  CommonSettingPayrollGlAccountMappingCreate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingPayrollGlAccountMappingUpdate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingPayrollGlAccountMappingDelete = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___DELETE',
  CommonSettingPayrollUserDefineEpfPolicyRead = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_READ',
  CommonSettingPayrollUserDefineEpfPolicyCreate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_CREATE',
  CommonSettingPayrollUserDefineEpfPolicyUpdate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_UPDATE',
  CommonSettingPayrollUserDefineEpfPolicyDelete = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_DELETE',
  CommonSettingPayrollPayGroupRead = 'COMMON_SETTING_PAYROLL_PAY_GROUP_READ',
  CommonSettingPayrollPayGroupCreate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_CREATE',
  CommonSettingPayrollPayGroupUpdate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_UPDATE',
  CommonSettingPayrollPayGroupDelete = 'COMMON_SETTING_PAYROLL_PAY_GROUP_DELETE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixRead = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_READ',
  CompanySettingPayrollUserDefineEpfPolicyMatrixCreate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_CREATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixUpdate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_UPDATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixDelete = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_DELETE',
  CompanySettingPayrollRead = 'COMPANY_SETTING_PAYROLL_______READ',
  CompanySettingPayrollCutoffDateUpdate = 'COMPANY_SETTING_PAYROLL_CUTOFF_DATE____UPDATE',
  CompanySettingPayrollPayrollCalendarRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____READ',
  CompanySettingPayrollPayrollCalendarUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____UPDATE',
  CompanySettingPayrollPayrollSettingRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_SETTING____READ',
  CompanySettingPayrollPayItemRead = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____READ',
  CompanySettingPayrollPayItemUpdate = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____UPDATE',
  CompanySettingPayrollPayItemDelete = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____DELETE',
  CompanySettingPayrollPayrollCycleRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____READ',
  CompanySettingPayrollPayrollCycleUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____UPDATE',
  CompanySettingPayrollAdvancePolicyRead = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____READ',
  CompanySettingPayrollAdvancePolicyUpdate = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____UPDATE',
  CompanySettingPayrollGlAccountAssignmentRead = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____READ',
  CompanySettingPayrollGlAccountAssignmentUpdate = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____UPDATE',
  CompanySettingPayrollBankCreate = 'COMPANY_SETTING_PAYROLL_BANK____CREATE',
  CompanySettingPayrollBankRead = 'COMPANY_SETTING_PAYROLL_BANK____READ',
  CompanySettingPayrollBankUpdate = 'COMPANY_SETTING_PAYROLL_BANK____UPDATE',
  CompanySettingPayrollBankDelete = 'COMPANY_SETTING_PAYROLL_BANK____DELETE',
  CompanySettingPayrollBankInterbankCreate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_CREATE',
  CompanySettingPayrollBankInterbankRead = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_READ',
  CompanySettingPayrollBankInterbankUpdate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_UPDATE',
  CompanySettingPayrollBankInterbankDelete = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_DELETE',
  CompanySettingPayrollPayGroupRead = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___READ',
  CompanySettingPayrollPayGroupUpdate = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___UPDATE',
  HomePayrollEmployeeSalaryRead = 'HOME_PAYROLL_EMPLOYEE_SALARY____READ',
  HomePayrollEmployeeSalarySalaryRecordsRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____READ',
  HomePayrollEmployeeSalarySalaryRecordsCreate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____CREATE',
  HomePayrollEmployeeSalarySalaryRecordsUpdate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____UPDATE',
  HomePayrollEmployeeSalarySalaryRecordsDelete = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____DELETE',
  HomePayrollEmployeeSalaryExcelUploadRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_READ',
  HomePayrollEmployeeSalaryExcelUploadUpload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_UPLOAD',
  HomePayrollEmployeeSalaryExcelUploadDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollEmployeeSalarySalaryAdjustmentRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_READ',
  HomePayrollEmployeeSalarySalaryAdjustmentDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_DOWNLOAD',
  HomePayrollGlExportRead = 'HOME_PAYROLL_GL_EXPORT____READ',
  HomePayrollGlExportDownload = 'HOME_PAYROLL_GL_EXPORT____DOWNLOAD',
  HomePayrollMonthlyRemunerationRead = 'HOME_PAYROLL_MONTHLY_REMUNERATION____READ',
  HomePayrollMonthlyRemunerationUpdate = 'HOME_PAYROLL_MONTHLY_REMUNERATION____UPDATE',
  HomePayrollBikRead = 'HOME_PAYROLL_BIK____READ',
  HomePayrollBikUpdate = 'HOME_PAYROLL_BIK____UPDATE',
  HomePayrollTaxReliefCreate = 'HOME_PAYROLL_TAX_RELIEF____CREATE',
  HomePayrollTaxReliefRead = 'HOME_PAYROLL_TAX_RELIEF____READ',
  HomePayrollTaxReliefUpdate = 'HOME_PAYROLL_TAX_RELIEF____UPDATE',
  HomePayrollOtherPayrollRelatedRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingPost = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____POST',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRevert = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____REVERT',
  HomePayrollFixedAllowanceDeductionRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION____READ',
  HomePayrollFixedAllowanceDeductionManualEntryCreate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_CREATE',
  HomePayrollFixedAllowanceDeductionManualEntryRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_READ',
  HomePayrollFixedAllowanceDeductionManualEntryUpdate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_UPDATE',
  HomePayrollFixedAllowanceDeductionManualEntryDelete = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_DELETE',
  HomePayrollFixedAllowanceDeductionExcelUploadRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_READ',
  HomePayrollFixedAllowanceDeductionExcelUploadUpload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_UPLOAD',
  HomePayrollFixedAllowanceDeductionExcelUploadDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollFixedAllowanceDeductionExportDataDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXPORT_DATA_DOWNLOAD',
  HomePayrollMonthlyTrxRead = 'HOME_PAYROLL_MONTHLY_TRX____READ',
  HomePayrollMonthlyTrxByEmployeeCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_CREATE',
  HomePayrollMonthlyTrxByEmployeeRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_READ',
  HomePayrollMonthlyTrxByEmployeeUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_UPDATE',
  HomePayrollMonthlyTrxByEmployeeDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_DELETE',
  HomePayrollMonthlyTrxByPayitemCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_CREATE',
  HomePayrollMonthlyTrxByPayitemRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_READ',
  HomePayrollMonthlyTrxByPayitemUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_UPDATE',
  HomePayrollMonthlyTrxByPayitemDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_DELETE',
  HomePayrollMonthlyTrxExcelUploadRead = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_READ',
  HomePayrollMonthlyTrxExcelUploadUpload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_UPLOAD',
  HomePayrollMonthlyTrxExcelUploadDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollMonthlyTrxExportDataDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXPORT_DATA_DOWNLOAD',
  HomePayrollFreezePayrollRead = 'HOME_PAYROLL_FREEZE_PAYROLL____READ',
  HomePayrollFreezePayrollCreate = 'HOME_PAYROLL_FREEZE_PAYROLL____CREATE',
  HomePayrollFreezePayrollDelete = 'HOME_PAYROLL_FREEZE_PAYROLL____DELETE',
  HomePayrollPayrollProcessRead = 'HOME_PAYROLL_PAYROLL_PROCESS____READ',
  HomePayrollShopeeImportPayrollProcessRead = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____READ',
  HomePayrollShopeeImportPayrollProcessImport = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____IMPORT',
  HomePayrollShopeeImportPayrollProcessDelete = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____DELETE',
  HomePayrollPayrollProcessProcess = 'HOME_PAYROLL_PAYROLL_PROCESS____PROCESS',
  HomePayrollPayrollProcessDelete = 'HOME_PAYROLL_PAYROLL_PROCESS____DELETE',
  HomePayrollPayrollClosingRead = 'HOME_PAYROLL_PAYROLL_CLOSING____READ',
  HomePayrollPayrollClosingLock = 'HOME_PAYROLL_PAYROLL_CLOSING____LOCK',
  HomePayrollPayrollClosingUnlock = 'HOME_PAYROLL_PAYROLL_CLOSING____UNLOCK',
  HomePayrollReadPayrollHistoryRead = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____READ',
  HomePayrollReadPayrollHistoryDelete = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____DELETE',
  HomePayrollPublishPayslipRead = 'HOME_PAYROLL_PUBLISH_PAYSLIP____READ',
  HomePayrollPublishPayslipPublish = 'HOME_PAYROLL_PUBLISH_PAYSLIP____PUBLISH',
  HomePayrollStatutoryAdjustmentRead = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_READ',
  HomePayrollStatutoryAdjustmentUpdate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_UPDATE',
  HomePayrollStatutoryAdjustmentCreate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_CREATE',
  HomePayrollStatutoryAdjustmentDelete = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_DELETE',
  HomePayrollPublishEaformRead = 'HOME_PAYROLL_PUBLISH_EAFORM____READ',
  HomePayrollPublishEaformPublish = 'HOME_PAYROLL_PUBLISH_EAFORM____PUBLISH',
  HomePayrollPublishEaformUnpublish = 'HOME_PAYROLL_PUBLISH_EAFORM____UNPUBLISH',
  HomePayrollPcbcp39PaymentRead = 'HOME_PAYROLL_PCBCP39_PAYMENT____READ',
  HomePayrollPayrollInitiationRead = 'HOME_PAYROLL_PAYROLL_INITIATION____READ',
  HomePayrollPcbcp39PaymentUpdate = 'HOME_PAYROLL_PCBCP39_PAYMENT____UPDATE',
  HomePayrollPayrollInitiationInitiate = 'HOME_PAYROLL_PAYROLL_INITIATION____INITIATE',
  HomeLeaveExecSummaryRead = 'HOME_LEAVE_EXEC_SUMMARY____READ',
  HomeLeaveRead = 'HOME_LEAVE____READ',
  CommonSettingLeaveRead = 'COMMON_SETTING_LEAVE_______READ',
  CommonSettingLeaveLeaveReasonRead = 'COMMON_SETTING_LEAVE_LEAVE_REASON___READ',
  CommonSettingLeaveLeaveReasonCreate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___CREATE',
  CommonSettingLeaveLeaveReasonUpdate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___UPDATE',
  CommonSettingLeaveLeaveReasonInactive = 'COMMON_SETTING_LEAVE_LEAVE_REASON___INACTIVE',
  CommonSettingLeaveLeaveReasonReactivate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___REACTIVATE',
  CommonSettingLeaveDiseaseCodeRead = 'COMMON_SETTING_LEAVE_DISEASE_CODE___READ',
  CommonSettingLeaveDiseaseCodeCreate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___CREATE',
  CommonSettingLeaveDiseaseCodeUpdate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___UPDATE',
  CommonSettingLeaveDiseaseCodeInactive = 'COMMON_SETTING_LEAVE_DISEASE_CODE___INACTIVE',
  CommonSettingLeaveDiseaseCodeReactivate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___REACTIVATE',
  CommonSettingLeaveLeaveTypeRead = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___READ',
  CommonSettingLeaveLeaveTypeCreate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___CREATE',
  CommonSettingLeaveLeaveTypeUpdate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___UPDATE',
  CommonSettingLeaveLeaveTypeDelete = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___DELETE',
  CommonSettingLeaveLeavePolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___READ',
  CommonSettingLeaveLeavePolicyView = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___VIEW',
  CommonSettingLeaveLeavePolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___CREATE',
  CommonSettingLeaveLeavePolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___UPDATE',
  CommonSettingLeaveLeavePolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___DELETE',
  CommonSettingLeaveLeaveRoundingRead = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___READ',
  CommonSettingLeaveLeaveRoundingView = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___VIEW',
  CommonSettingLeaveLeaveRoundingCreate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___CREATE',
  CommonSettingLeaveLeaveRoundingUpdate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___UPDATE',
  CommonSettingLeaveLeaveRoundingDelete = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___READ',
  CommonSettingLeaveLeaveApprovalPolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___CREATE',
  CommonSettingLeaveLeaveApprovalPolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___UPDATE',
  CommonSettingLeaveLeaveApprovalPolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyInactive = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___INACTIVE',
  CommonSettingLeaveLeaveApprovalPolicyReactivate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___REACTIVATE',
  CompanySettingLeaveRead = 'COMPANY_SETTING_LEAVE_______READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixRead = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixCreate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___CREATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixDelete = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___DELETE',
  CompanySettingLeaveLeaveSettingRead = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___READ',
  CompanySettingLeaveLeaveSettingUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___UPDATE',
  CompanySettingLeaveCompulsoryLeaveDeductionRead = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___READ',
  CompanySettingLeaveCompulsoryLeaveDeductionUpdate = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___UPDATE',
  CompanySettingLeaveOnBehalfPolicyRead = 'COMPANY_SETTING_LEAVE_ON_BEHALF_POLICY___READ',
  CompanySettingLeaveOnBehalfPolicyUpdate = 'COMPANY_SETTING_LEAVE_ON_BEHALF_POLICY___UPDATE',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentRead = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeLeaveOutOfStateCreate = 'HOME_LEAVE_OUT_OF_STATE____CREATE',
  HomeLeaveOutOfStateRead = 'HOME_LEAVE_OUT_OF_STATE____READ',
  HomeLeaveOutOfStateUpdate = 'HOME_LEAVE_OUT_OF_STATE____UPDATE',
  HomeLeaveOutOfStateDelete = 'HOME_LEAVE_OUT_OF_STATE____DELETE',
  HomeLeaveApplicationCreate = 'HOME_LEAVE_APPLICATION____CREATE',
  HomeLeaveApplicationRead = 'HOME_LEAVE_APPLICATION____READ',
  HomeLeaveApplicationUpdate = 'HOME_LEAVE_APPLICATION____UPDATE',
  HomeLeaveApplicationDelete = 'HOME_LEAVE_APPLICATION____DELETE',
  HomeLeaveApplicationApprove = 'HOME_LEAVE_APPLICATION____APPROVE',
  HomeLeaveApplicationReject = 'HOME_LEAVE_APPLICATION____REJECT',
  HomeLeaveApplicationWithdraw = 'HOME_LEAVE_APPLICATION____WITHDRAW',
  HomeLeaveApplicationView = 'HOME_LEAVE_APPLICATION____VIEW',
  HomeLeaveApplicationCalendarRead = 'HOME_LEAVE_APPLICATION_CALENDAR____READ',
  HomeLeaveLeaveTakenImportFromExcelRead = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____READ',
  HomeLeaveLeaveTakenImportFromExcelDownload = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____DOWNLOAD',
  HomeLeaveLeaveTakenImportFromExcelImport = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____IMPORT',
  HomeLeaveAdjustmentCreate = 'HOME_LEAVE_ADJUSTMENT____CREATE',
  HomeLeaveAdjustmentRead = 'HOME_LEAVE_ADJUSTMENT____READ',
  HomeLeaveAdjustmentUpdate = 'HOME_LEAVE_ADJUSTMENT____UPDATE',
  HomeLeaveAdjustmentDelete = 'HOME_LEAVE_ADJUSTMENT____DELETE',
  HomeLeaveLeaveEntitlementByEmployeeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeLeaveLeaveEntitlementByLeaveTypeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE____READ',
  HomeLeaveBlockLeaveCreate = 'HOME_LEAVE_BLOCK_LEAVE____CREATE',
  HomeLeaveBlockLeaveRead = 'HOME_LEAVE_BLOCK_LEAVE____READ',
  HomeLeaveBlockLeaveUpdate = 'HOME_LEAVE_BLOCK_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveCreate = 'HOME_LEAVE_COMPULSORY_LEAVE____CREATE',
  HomeLeaveCompulsoryLeaveUpdate = 'HOME_LEAVE_COMPULSORY_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveDelete = 'HOME_LEAVE_COMPULSORY_LEAVE____DELETE',
  HomeLeaveCompulsoryLeaveRead = 'HOME_LEAVE_COMPULSORY_LEAVE____READ',
  HomeLeavePostingRead = 'HOME_LEAVE_POSTING____READ',
  HomeLeavePostingPost = 'HOME_LEAVE_POSTING____POST',
  HomeLeavePostingRevert = 'HOME_LEAVE_POSTING____REVERT',
  HomeLeaveYearendCarryforwardRead = 'HOME_LEAVE_YEAREND_CARRYFORWARD____READ',
  HomeLeaveYearendCarryforwardPost = 'HOME_LEAVE_YEAREND_CARRYFORWARD____POST',
  HomeLeaveYearendCarryforwardReverse = 'HOME_LEAVE_YEAREND_CARRYFORWARD____REVERSE',
  HomeLeaveOffboardingEncashmentRead = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____READ',
  HomeLeaveOffboardingEncashmentPost = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____POST',
  HomeLeaveOffboardingEncashmentRevert = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____REVERT',
  HomeLeaveLeaveCarryForwardEncashmentRead = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____READ',
  HomeLeaveLeaveCarryForwardEncashmentPost = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____POST',
  HomeLeaveCareerMovementProcessRead = 'HOME_LEAVE_CAREER_MOVEMENT_PROCESS____READ',
  GeneralSettingLeaveTemplateCreate = 'GENERAL_SETTING_LEAVE_TEMPLATE____CREATE',
  GeneralSettingLeaveTemplateRead = 'GENERAL_SETTING_LEAVE_TEMPLATE____READ',
  GeneralSettingLeaveTemplateUpdate = 'GENERAL_SETTING_LEAVE_TEMPLATE____UPDATE',
  HomeClaimExecSummaryCreate = 'HOME_CLAIM_EXEC_SUMMARY____CREATE',
  HomeClaimExecSummaryRead = 'HOME_CLAIM_EXEC_SUMMARY____READ',
  HomeClaimRead = 'HOME_CLAIM_______READ',
  CommonSettingClaimRead = 'COMMON_SETTING_CLAIM_______READ',
  CommonSettingClaimClaimParameterRead = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___READ',
  CommonSettingClaimClaimParameterCreate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___CREATE',
  CommonSettingClaimClaimParameterUpdate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___UPDATE',
  CommonSettingClaimClaimParameterDelete = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___DELETE',
  CommonSettingClaimClaimParameterInactive = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___INACTIVE',
  CommonSettingClaimClaimParameterReactivate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___REACTIVATE',
  CommonSettingClaimClaimTypeRead = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___READ',
  CommonSettingClaimClaimTypeCreate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___CREATE',
  CommonSettingClaimClaimTypeUpdate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___UPDATE',
  CommonSettingClaimClaimTypeDelete = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___DELETE',
  CommonSettingClaimClaimTypeInactive = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___INACTIVE',
  CommonSettingClaimClaimTypeReactivate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___REACTIVATE',
  CommonSettingClaimClaimPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___READ',
  CommonSettingClaimClaimPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___CREATE',
  CommonSettingClaimClaimPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___UPDATE',
  CommonSettingClaimClaimPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___DELETE',
  CommonSettingClaimClaimPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___INACTIVE',
  CommonSettingClaimClaimPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___REACTIVATE',
  CommonSettingClaimClaimApprovalPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingClaimClaimApprovalPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingClaimClaimApprovalPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingClaimClaimApprovalPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingClaimClaimApprovalPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingClaimClaimApprovalPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingClaimGlAccountMappingRead = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CommonSettingClaimGlAccountMappingCreate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingClaimGlAccountMappingUpdate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingClaimGlAccountMappingDelete = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___DELETE',
  CompanySettingClaimRead = 'COMPANY_SETTING_CLAIM_______READ',
  CompanySettingClaimJobGradePolicyRead = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___READ',
  CompanySettingClaimJobGradePolicyUpdate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___UPDATE',
  CompanySettingClaimJobGradePolicyCreate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___CREATE',
  CompanySettingClaimJobGradePolicyDelete = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___DELETE',
  CompanySettingClaimGlAccountMappingRead = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CompanySettingClaimGlAccountMappingUpdate = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CompanySettingClaimClaimSettingRead = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___READ',
  CompanySettingClaimClaimSettingUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___UPDATE',
  CompanySettingClaimOnBehalfPolicyRead = 'COMPANY_SETTING_CLAIM_ON_BEHALF_POLICY___READ',
  CompanySettingClaimOnBehalfPolicyUpdate = 'COMPANY_SETTING_CLAIM_ON_BEHALF_POLICY___UPDATE',
  CompanySettingClaimClaimApprovalPolicyAssignmentRead = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingClaimClaimApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeClaimOutOfStateCreate = 'HOME_CLAIM_OUT_OF_STATE____CREATE',
  HomeClaimOutOfStateRead = 'HOME_CLAIM_OUT_OF_STATE____READ',
  HomeClaimOutOfStateUpdate = 'HOME_CLAIM_OUT_OF_STATE____UPDATE',
  HomeClaimOutOfStateDelete = 'HOME_CLAIM_OUT_OF_STATE____DELETE',
  HomeClaimApplicationCreate = 'HOME_CLAIM_APPLICATION____CREATE',
  HomeClaimApplicationRead = 'HOME_CLAIM_APPLICATION____READ',
  HomeClaimApplicationUpdate = 'HOME_CLAIM_APPLICATION____UPDATE',
  HomeClaimApplicationDelete = 'HOME_CLAIM_APPLICATION____DELETE',
  HomeClaimApplicationApprove = 'HOME_CLAIM_APPLICATION____APPROVE',
  HomeClaimApplicationReject = 'HOME_CLAIM_APPLICATION____REJECT',
  HomeClaimApplicationWithdraw = 'HOME_CLAIM_APPLICATION____WITHDRAW',
  HomeClaimApprovedClaimVerifyRead = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____READ',
  HomeClaimApprovedClaimVerifyReject = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REJECT',
  HomeClaimApprovedClaimVerifyVerify = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____VERIFY',
  HomeClaimApprovedClaimVerifyReverse = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REVERSE',
  HomeClaimPostingRead = 'HOME_CLAIM_POSTING____READ',
  HomeClaimPostingPost = 'HOME_CLAIM_POSTING____POST',
  HomeClaimPostingReverse = 'HOME_CLAIM_POSTING____REVERSE',
  HomeClaimPaymentRead = 'HOME_CLAIM_PAYMENT____READ',
  HomeClaimPaymentPost = 'HOME_CLAIM_PAYMENT____POST',
  HomeClaimPaymentReverse = 'HOME_CLAIM_PAYMENT____REVERSE',
  HomeClaimClaimEntitlementByEmployeeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeClaimClaimEntitlementByClaimTypeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_CLAIM_TYPE____READ',
  HomeClaimGlProcessingRead = 'HOME_CLAIM_GL_PROCESSING____READ',
  HomeClaimGlProcessingProcess = 'HOME_CLAIM_GL_PROCESSING____PROCESS',
  HomeClaimGlProcessingExport = 'HOME_CLAIM_GL_PROCESSING____EXPORT',
  HomeClaimGlExportRead = 'HOME_CLAIM_GL_EXPORT____READ',
  HomeClaimGlExportExport = 'HOME_CLAIM_GL_EXPORT____EXPORT',
  HomeClaimMileageLogRead = 'HOME_CLAIM_MILEAGE_LOG____READ',
  HomeClaimMileageLogCreate = 'HOME_CLAIM_MILEAGE_LOG____CREATE',
  HomeClaimMileageLogUpdate = 'HOME_CLAIM_MILEAGE_LOG____UPDATE',
  HomeClaimMileageLogDelete = 'HOME_CLAIM_MILEAGE_LOG____DELETE',
  GeneralSettingClaimTemplateCreate = 'GENERAL_SETTING_CLAIM_TEMPLATE____CREATE',
  GeneralSettingClaimTemplateRead = 'GENERAL_SETTING_CLAIM_TEMPLATE____READ',
  GeneralSettingClaimTemplateUpdate = 'GENERAL_SETTING_CLAIM_TEMPLATE____UPDATE',
  HomeTmsExecSummaryRead = 'HOME_TMS_EXEC_SUMMARY____READ',
  HomeTmsRead = 'HOME_TMS_______READ',
  CommonSettingTmsRead = 'COMMON_SETTING_TMS_______READ',
  CommonSettingTmsShiftSetupRead = 'COMMON_SETTING_TMS_SHIFT_SETUP___READ',
  CommonSettingTmsShiftSetupCreate = 'COMMON_SETTING_TMS_SHIFT_SETUP___CREATE',
  CommonSettingTmsShiftSetupUpdate = 'COMMON_SETTING_TMS_SHIFT_SETUP___UPDATE',
  CommonSettingTmsShiftSetupDelete = 'COMMON_SETTING_TMS_SHIFT_SETUP___DELETE',
  CommonSettingTmsShiftSetupDuplicate = 'COMMON_SETTING_TMS_SHIFT_SETUP___DUPLICATE',
  CommonSettingTmsShiftPatternRead = 'COMMON_SETTING_TMS_SHIFT_PATTERN___READ',
  CommonSettingTmsShiftPatternCreate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___CREATE',
  CommonSettingTmsShiftPatternUpdate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___UPDATE',
  CommonSettingTmsShiftPatternDelete = 'COMMON_SETTING_TMS_SHIFT_PATTERN___DELETE',
  CommonSettingTmsShiftGroupRead = 'COMMON_SETTING_TMS_SHIFT_GROUP___READ',
  CommonSettingTmsShiftGroupCreate = 'COMMON_SETTING_TMS_SHIFT_GROUP___CREATE',
  CommonSettingTmsShiftGroupUpdate = 'COMMON_SETTING_TMS_SHIFT_GROUP___UPDATE',
  CommonSettingTmsAttendanceAnalysisTemplateRead = 'COMMON_SETTING_TMS_ATTENDANCE_ANALYSIS_TEMPLATE___READ',
  CommonSettingTmsAttendanceAnalysisTemplateUpdate = 'COMMON_SETTING_TMS_ATTENDANCE_ANALYSIS_TEMPLATE___UPDATE',
  CommonSettingTmsShiftGroupDelete = 'COMMON_SETTING_TMS_SHIFT_GROUP___DELETE',
  CommonSettingTmsOvertimePolicyRead = 'COMMON_SETTING_TMS_OVERTIME_POLICY___READ',
  CommonSettingTmsOvertimePolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___CREATE',
  CommonSettingTmsOvertimePolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___UPDATE',
  CommonSettingTmsOvertimePolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_POLICY___DELETE',
  CommonSettingTmsOvertimeClaimTableRead = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___READ',
  CommonSettingTmsOvertimeClaimTableCreate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___CREATE',
  CommonSettingTmsOvertimeClaimTableUpdate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___UPDATE',
  CommonSettingTmsOvertimeClaimTableDelete = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___DELETE',
  CommonSettingTmsOvertimeClaimTableView = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___VIEW',
  CommonSettingTmsOvertimeReasonCodesRead = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___READ',
  CommonSettingTmsOvertimeReasonCodesCreate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___CREATE',
  CommonSettingTmsOvertimeReasonCodesUpdate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___UPDATE',
  CommonSettingTmsOvertimeReasonCodesDelete = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___DELETE',
  CommonSettingTmsOvertimeRoundingPolicyRead = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___READ',
  CommonSettingTmsOvertimeRoundingPolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___CREATE',
  CommonSettingTmsOvertimeRoundingPolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsOvertimeRoundingPolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___DELETE',
  CommonSettingTmsDailyAllowancePolicyRead = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsDailyAllowancePolicyCreate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsDailyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsDailyAllowancePolicyDelete = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsMonthlyAllowancePolicyRead = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsMonthlyAllowancePolicyCreate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsMonthlyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsMonthlyAllowancePolicyDelete = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsPeriodicDeductionPolicyRead = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___READ',
  CommonSettingTmsPeriodicDeductionPolicyCreate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsPeriodicDeductionPolicyUpdate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsPeriodicDeductionPolicyDelete = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsDeductionPolicyRead = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___READ',
  CommonSettingTmsDeductionPolicyCreate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsDeductionPolicyUpdate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsDeductionPolicyDelete = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyRead = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___READ',
  CommonSettingTmsAllowanceDeductionRoundingPolicyCreate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___CREATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyUpdate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyDelete = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___DELETE',
  CommonSettingTmsTimeOffPolicyRead = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___READ',
  CommonSettingTmsTimeOffPolicyCreate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___CREATE',
  CommonSettingTmsTimeOffPolicyUpdate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___UPDATE',
  CommonSettingTmsTimeOffPolicyDelete = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___DELETE',
  CommonSettingTmsTimeOffReasonCodesRead = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___READ',
  CommonSettingTmsTimeOffReasonCodesCreate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___CREATE',
  CommonSettingTmsTimeOffReasonCodesUpdate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___UPDATE',
  CommonSettingTmsTimeOffReasonCodesDelete = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___DELETE',
  CommonSettingTmsPeriodicAllowanceTypeRead = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___READ',
  CommonSettingTmsMobileCheckLocationRead = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___READ',
  CommonSettingTmsMobileCheckLocationCreate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___CREATE',
  CommonSettingTmsMobileCheckLocationUpdate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___UPDATE',
  CommonSettingTmsMobileCheckLocationDelete = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___DELETE',
  CommonSettingTmsMobileCheckLocationView = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___VIEW',
  CommonSettingTmsTmsApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY___READ',
  CommonSettingTmsPeriodicAllowanceTypeCreate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___CREATE',
  CommonSettingTmsPeriodicAllowanceTypeUpdate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___UPDATE',
  CommonSettingTmsPeriodicAllowanceTypeDelete = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___REACTIVATE',
  CompanySettingTmsRead = 'COMPANY_SETTING_TMS_______READ',
  CompanySettingTmsShiftAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___READ',
  CompanySettingTmsShiftAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftPatternAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___READ',
  CompanySettingTmsShiftPatternAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftGroupAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___READ',
  CompanySettingTmsShiftGroupAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___UPDATE',
  CompanySettingTmsTimeOffPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTimeOffPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingTmsCsOvertimeReasonCodesRead = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___READ',
  CompanySettingTmsCsOvertimeReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___UPDATE',
  CompanySettingTmsCsTimeOffReasonCodesRead = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___READ',
  CompanySettingTmsCsTimeOffReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___UPDATE',
  CompanySettingTmsJobGradePolicyMatrixRead = 'COMPANY_SETTING_TMS_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___CREATE',
  CompanySettingTmsDailyPolicyMatrixRead = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDailyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___DELETE',
  CompanySettingTmsMonthlyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___CREATE',
  CompanySettingTmsMonthlyPolicyMatrixRead = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___READ',
  CompanySettingTmsMonthlyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMonthlyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___DELETE',
  CompanySettingTmsDeductionPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___CREATE',
  CompanySettingTmsDeductionPolicyMatrixRead = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___READ',
  CompanySettingTmsDeductionPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDeductionPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___DELETE',
  CompanySettingTmsOvertimePolicyMatrixCreate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___CREATE',
  CompanySettingTmsOvertimePolicyMatrixRead = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___READ',
  CompanySettingTmsOvertimePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___UPDATE',
  CompanySettingTmsOvertimePolicyMatrixDelete = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___DELETE',
  CompanySettingTmsCsJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_CS_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMobileCheckLocationAssignmentRead = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___READ',
  CompanySettingTmsMobileCheckLocationAssignmentUpdate = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___UPDATE',
  CompanySettingTmsOvertimeSettingRead = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___READ',
  CompanySettingTmsOvertimeSettingUpdate = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___UPDATE',
  CompanySettingTmsOnBehalfPolicyRead = 'COMPANY_SETTING_TMS_ON_BEHALF_POLICY___READ',
  CompanySettingTmsOnBehalfPolicyUpdate = 'COMPANY_SETTING_TMS_ON_BEHALF_POLICY___UPDATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTmsApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentCreate = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___CREATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentDelete = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___DELETE',
  HomeTmsAttendanceRecordRead = 'HOME_TMS_ATTENDANCE_RECORD____READ',
  HomeTmsAttendanceRecordManualEntryRead = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____READ',
  HomeTmsAttendanceRecordManualEntryCreate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____CREATE',
  HomeTmsAttendanceRecordManualEntryUpdate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____UPDATE',
  HomeTmsAttendanceRecordManualEntryDelete = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____DELETE',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____READ',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelDownload = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____DOWNLOAD',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelImport = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____IMPORT',
  HomeTmsAttendanceRecordExportAttendanceDataToExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_EXPORT_ATTENDANCE_DATA_TO_EXCEL____READ',
  HomeTmsShiftGroupEmployeeAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____DELETE',
  HomeTmsShiftGroupShiftPatternAssignmentRead = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____READ',
  HomeTmsShiftGroupShiftPatternAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupShiftPatternAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____DELETE',
  HomeTmsDutyRosterRead = 'HOME_TMS_DUTY_ROSTER____READ',
  HomeTmsDutyRosterDutyRosterByIndividualRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____READ',
  HomeTmsDutyRosterDutyRosterByIndividualUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____UPDATE',
  HomeTmsDutyRosterDutyRosterByEmployeeListRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____READ',
  HomeTmsDutyRosterDutyRosterByEmployeeListUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____UPDATE',
  HomeTmsDutyRosterShiftAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterExcelUploadMaintenanceRead = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsDutyRosterExcelUploadMaintenanceDownload = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsDutyRosterExcelUploadMaintenanceImport = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsDutyRosterDutyRosterSummaryRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_SUMMARY____READ',
  HomeTmsOvertimeApplicationRead = 'HOME_TMS_OVERTIME_APPLICATION_______READ',
  HomeTmsOvertimeApplicationManualEntryRead = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____READ',
  HomeTmsOvertimeApplicationManualEntryCreate = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____CREATE',
  HomeTmsOvertimeApplicationManualEntryWithdraw = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____WITHDRAW',
  HomeTmsOvertimeApplicationManualEntryApprove = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____APPROVE',
  HomeTmsOvertimeApplicationManualEntryReject = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____REJECT',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceRead = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceDownload = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceImport = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsOvertimeApplicationExportOvertimeDataRead = 'HOME_TMS_OVERTIME_APPLICATION_EXPORT_OVERTIME_DATA____READ',
  HomeTmsChangeShiftRequestCreate = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______CREATE',
  HomeTmsChangeShiftRequestRead = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______READ',
  HomeTmsChangeShiftRequestDelete = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______DELETE',
  HomeTmsChangeShiftRequestApprove = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______APPROVE',
  HomeTmsChangeShiftRequestReject = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______REJECT',
  HomeTmsTimeOffApplicationCreate = 'HOME_TMS_TIME_OFF_APPLICATION_______CREATE',
  HomeTmsTimeOffApplicationRead = 'HOME_TMS_TIME_OFF_APPLICATION_______READ',
  HomeTmsTimeOffApplicationApprove = 'HOME_TMS_TIME_OFF_APPLICATION_______APPROVE',
  HomeTmsTimeOffApplicationReject = 'HOME_TMS_TIME_OFF_APPLICATION_______REJECT',
  HomeTmsTimeOffApplicationWithdraw = 'HOME_TMS_TIME_OFF_APPLICATION_______WITHDRAW',
  HomeTmsNewAttendanceRecordRead = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______READ',
  HomeTmsNewAttendanceRecordUpdate = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______UPDATE',
  HomeTmsNewAttendanceRecordRepair = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______REPAIR',
  HomeTmsAttendanceCorrectionRequestRead = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST____READ',
  HomeTmsAttendanceCorrectionRequestApprove = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST_______APPROVE',
  HomeTmsAttendanceCorrectionRequestReject = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST_______REJECT',
  HomeTmsAttendanceAnalysisRead = 'HOME_TMS_ATTENDANCE_ANALYSIS_______READ',
  HomeTmsAttendanceAnalysisExport = 'HOME_TMS_ATTENDANCE_ANALYSIS_______EXPORT',
  HomeTmsAttendanceProcessingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______CALCULATE',
  HomeTmsAttendanceProcessingDailyTransactionListingExport = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______EXPORT',
  HomeTmsAttendanceProcessingMonthlySummaryRead = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______READ',
  HomeTmsAttendanceProcessingMonthlySummaryCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______CALCULATE',
  HomeTmsAttendanceProcessingMonthlySummaryExport = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______EXPORT',
  HomeTmsAttendancePostingRead = 'HOME_TMS_ATTENDANCE_POSTING_______READ',
  HomeTmsAttendancePostingPost = 'HOME_TMS_ATTENDANCE_POSTING_______POST',
  HomeTmsAttendancePostingRevert = 'HOME_TMS_ATTENDANCE_POSTING_______REVERT',
  HomeTmsAttendancePostingPopulate = 'HOME_TMS_ATTENDANCE_POSTING_______POPULATE',
  HomeTmsEarnLeavePostingRead = 'HOME_TMS_EARN_LEAVE_POSTING_______READ',
  HomeTmsEarnLeavePostingPost = 'HOME_TMS_EARN_LEAVE_POSTING_______POST',
  HomeTmsEarnLeavePostingRevert = 'HOME_TMS_EARN_LEAVE_POSTING_______REVERT',
  HomeTmsAttendanceExclusionListRead = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______READ',
  HomeTmsAttendanceExclusionListCreate = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______CREATE',
  HomeTmsAttendanceExclusionListDelete = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______DELETE',
  HomeTmsContactTracingRead = 'HOME_TMS_CONTACT_TRACING_______READ',
  HomeTmsContactTracingExport = 'HOME_TMS_CONTACT_TRACING_______EXPORT',
  CommonSettingRequisitionRead = 'COMMON_SETTING_REQUISITION_______READ',
  CommonSettingRequisitionTypeRead = 'COMMON_SETTING_REQUISITION_TYPE______READ',
  CommonSettingRequisitionTypeCreate = 'COMMON_SETTING_REQUISITION_TYPE______CREATE',
  CommonSettingRequisitionTypeUpdate = 'COMMON_SETTING_REQUISITION_TYPE______UPDATE',
  CommonSettingRequisitionRequisitionApprovalPolicyRead = 'COMMON_SETTING_REQUISITION_REQUISITION_APPROVAL_POLICY___READ',
  CommonSettingRequisitionAssetRequisitionRead = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___READ',
  CommonSettingRequisitionAssetRequisitionCreate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___CREATE',
  CommonSettingRequisitionAssetRequisitionUpdate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___UPDATE',
  CommonSettingRequisitionAssetRequisitionDelete = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___DELETE',
  CommonSettingRequisitionAssetRequisitionInactive = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___INACTIVE',
  CommonSettingRequisitionAssetRequisitionReactivate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___REACTIVATE',
  CommonSettingRequisitionCashAdvanceRead = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___READ',
  CommonSettingRequisitionCashAdvanceCreate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___CREATE',
  CommonSettingRequisitionCashAdvanceUpdate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___UPDATE',
  CommonSettingRequisitionCashAdvanceDelete = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___DELETE',
  CommonSettingRequisitionCashAdvanceInactive = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___INACTIVE',
  CommonSettingRequisitionCashAdvanceReactivate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___REACTIVATE',
  CommonSettingRequisitionOtherRequisitionRead = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___READ',
  CommonSettingRequisitionOtherRequisitionCreate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___CREATE',
  CommonSettingRequisitionOtherRequisitionUpdate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___UPDATE',
  CommonSettingRequisitionOtherRequisitionDelete = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___DELETE',
  CommonSettingRequisitionOtherRequisitionInactive = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___INACTIVE',
  CommonSettingRequisitionOtherRequisitionReactivate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___REACTIVATE',
  CommonSettingRequisitionTravelRequestRead = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___READ',
  CommonSettingRequisitionTravelRequestCreate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___CREATE',
  CommonSettingRequisitionTravelRequestUpdate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___UPDATE',
  CommonSettingRequisitionTravelRequestDelete = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___DELETE',
  CommonSettingRequisitionTravelRequestInactive = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___INACTIVE',
  CommonSettingRequisitionTravelRequestReactivate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___REACTIVATE',
  CommonSettingRequisitionStaffMovementRequestRead = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__READ',
  CommonSettingRequisitionStaffMovementRequestCreate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__CREATE',
  CommonSettingRequisitionStaffMovementRequestUpdate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__UPDATE',
  CommonSettingRequisitionStaffMovementRequestDelete = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__DELETE',
  CommonSettingRequisitionStaffMovementRequestInactive = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__INACTIVE',
  CommonSettingRequisitionStaffMovementRequestReactivate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__REACTIVATE',
  CompanySettingRequisitionRead = 'COMPANY_SETTING_REQUISITION_______READ',
  CompanySettingRequisitionStaffMovementRequestRead = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______READ',
  CompanySettingRequisitionStaffMovementRequestUpdate = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______UPDATE',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentRead = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeRequisitionExecSummaryRead = 'HOME_REQUISITION_EXEC_SUMMARY____READ',
  HomeRequisitionRead = 'HOME_REQUISITION_______READ',
  HomeRequisitionUpdate = 'HOME_REQUISITION_______UPDATE',
  HomeRequisitionRequisitionApplicationRead = 'HOME_REQUISITION_REQUISITION_APPLICATION_______READ',
  HomeRequisitionRequisitionApplicationCreate = 'HOME_REQUISITION_REQUISITION_APPLICATION_______CREATE',
  HomeRequisitionRequisitionApplicationApprove = 'HOME_REQUISITION_REQUISITION_APPLICATION_______APPROVE',
  HomeRequisitionRequisitionApplicationReject = 'HOME_REQUISITION_REQUISITION_APPLICATION_______REJECT',
  HomeRequisitionRequisitionApplicationWithdraw = 'HOME_REQUISITION_REQUISITION_APPLICATION_______WITHDRAW',
  GeneralSettingRequisitionNotificationRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION____READ',
  DigitalReportingPersonnelRptBasicinfoView = 'DIGITAL_REPORTING_PERSONNEL_RPT_BASICINFO____VIEW',
  DigitalReportingPersonnelRptConfirmationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_CONFIRMATION____VIEW',
  DigitalReportingPersonnelRptDependentView = 'DIGITAL_REPORTING_PERSONNEL_RPT_DEPENDENT____VIEW',
  DigitalReportingPersonnelRptEduBackgroundView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EDU_BACKGROUND_VIEW',
  DigitalReportingPersonnelRptNewView = 'DIGITAL_REPORTING_PERSONNEL_RPT_NEW____VIEW',
  DigitalReportingPersonnelRptRegisnationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_REGISNATION____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceSalaryMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_SALARY_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeMasterExcelView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_MASTER_EXCEL_VIEW',
  DigitalReportingPayrollRptBankBankPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankCashcheckPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_CASHCHECK_PAYOUT_VIEW',
  DigitalReportingPayrollRptPayrollEmployeePayView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_PAY_VIEW',
  DigitalReportingPayrollRptPayrollEmployeeSalaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_SALARY_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollPayrollDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailByJobGradeOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_BY_JOB_GRADE_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollSalaryvarianceView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYVARIANCE_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbystaffcategoryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYSTAFFCATEGORY_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbyjobpositionView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYJOBPOSITION_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_EXPORT',
  DigitalReportingPayrollRptPayrollSalarysummaryByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptJoinPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_JOIN_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptResignPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_RESIGN_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalaryVarianceByEmployeeView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARY_VARIANCE_BY_EMPLOYEE_VIEW',
  DigitalReportingPayrollRptPayrollSalaryVarianceByPayitemView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARY_VARIANCE_BY_PAYITEM_VIEW',
  DigitalReportingPayrollRptPayrollTrxDetailExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_TRX_DETAIL_EXPORT',
  DigitalReportingPayrollRptPayrollStaffcurrentcompensationView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_STAFFCURRENTCOMPENSATION_VIEW',
  DigitalReportingPayrollRptPayrollYtdtransactionsummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_YTDTRANSACTIONSUMMARY_VIEW',
  DigitalReportingPayrollRptPayslipView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP____VIEW',
  DigitalReportingPayrollRptPayslipMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_MULTI_VIEW',
  DigitalReportingPayrollRptPayslipBmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptAsbView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB____VIEW',
  DigitalReportingPayrollRptAsbRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_RPT_VIEW',
  DigitalReportingPayrollRptAsbCsvTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_CSV_TXT_VIEW',
  DigitalReportingPayrollRptHrdfView = 'DIGITAL_REPORTING_PAYROLL_RPT_HRDF_VIEW',
  DigitalReportingPayrollRptHrDevelopmentFundListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_HR_DEVELOPMENT_FUND_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_VIEW',
  DigitalReportingPayrollRptZakatListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatListingWpklMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_WPKL_MULTI_VIEW',
  DigitalReportingPayrollRptTabungHajiView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_VIEW',
  DigitalReportingPayrollRptTabungHajiRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_RPT_VIEW',
  DigitalReportingPayrollRptTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptKwspBorangaView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_VIEW',
  DigitalReportingPayrollRptKwspBorangaMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_MULTI_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_CSV_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_VIEW',
  DigitalReportingPayrollRptSocsoBorang2MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG2_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang3MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG3_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1View = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_VIEW',
  DigitalReportingPayrollRptEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangeView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGE_VIEW',
  DigitalReportingPayrollRptLhdnBorangeaView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2017MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2017_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2021MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2021_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2023BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2023_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnCp159View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP159_VIEW',
  DigitalReportingPayrollRptLhdnCp21View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP21_VIEW',
  DigitalReportingPayrollRptLhdnCp22View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22_VIEW',
  DigitalReportingPayrollRptLhdnCp22AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_VIEW',
  DigitalReportingPayrollRptLhdnCp22ATxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_TXT_VIEW',
  DigitalReportingPayrollRptLhdnCp39View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39_VIEW',
  DigitalReportingPayrollRptLhdnCp39AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39A_VIEW',
  DigitalReportingPayrollRptLhdnCp8DView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8D_VIEW',
  DigitalReportingPayrollRptLhdnCp8DtxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8DTXT_VIEW',
  DigitalReportingPayrollRptLhdnTaxView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_TAX_VIEW',
  DigitalReportingPayrollRptLhdnLampiranBView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_LAMPIRAN_B_VIEW',
  DigitalReportingPayrollRptIntegrationAffbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_VIEW',
  DigitalReportingPayrollRptIntegrationAffbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationAffbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbEpfTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_EPF_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_VIEW',
  DigitalReportingPayrollRptIntegrationBimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbOnlinePayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_ONLINE_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_VIEW',
  DigitalReportingPayrollRptIntegrationCimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbZakatTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_ZAKAT_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationHlbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingPayrollRptIntegrationHlbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbConnectfirstPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_CONNECTFIRST_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbLhdnExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_LHDN_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbZakatExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_ZAKAT_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2UCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2U_CSV_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollMasapsTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_MASAPS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankKwspM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_KWSP_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankSocsoM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_SOCSO_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankEisM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_EIS_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankLhdnM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_LHDN_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankZakatM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_ZAKAT_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollIbgExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_IBG_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_VIEW',
  DigitalReportingPayrollRptIntegrationUobPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbEpfTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_EPF_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_LHDN_TXT_VIEW',
  DigitalReportingLeaveView = 'DIGITAL_REPORTING_LEAVE_______VIEW',
  DigitalReportingLeaveRptLeaveAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ANALYSIS____VIEW',
  DigitalReportingLeaveRptLeaveEntitlementBalanceAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ENTITLEMENT_BALANCE_ANALYSIS____VIEW',
  DigitalReportingLeaveRptLeaveTakenSummaryView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_TAKEN_SUMMARY____VIEW',
  DigitalReportingClaimView = 'DIGITAL_REPORTING_CLAIM_______VIEW',
  DigitalReportingClaimRptClaimBalanceAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_BALANCE_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimStatusAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_STATUS_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimDetailListingView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_DETAIL_LISTING____VIEW',
  DigitalReportingClaimRptStaffClaimView = 'DIGITAL_REPORTING_CLAIM_RPT_STAFF_CLAIM_VIEW',
  DigitalReportingClaimRptIntegrationView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION____VIEW',
  DigitalReportingClaimRptIntegrationHlbView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingHelpdeskView = 'DIGITAL_REPORTING_HELPDESK_______VIEW',
  DigitalReportingHelpdeskRptHelpdeskListingView = 'DIGITAL_REPORTING_HELPDESK_RPT_HELPDESK_LISTING____VIEW',
  HomeDigitalReportingView = 'HOME_DIGITAL_REPORTING_______VIEW',
  DigitalReportingPersonnelView = 'DIGITAL_REPORTING_PERSONNEL_______VIEW',
  DigitalReportingPayrollBankPayoutView = 'DIGITAL_REPORTING_PAYROLL____BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK____VIEW',
  DigitalReportingPayrollRptPayrollView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL____VIEW',
  DigitalReportingPayrollRptLhdnView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN____VIEW',
  DigitalReportingPayrollRptIntegrationView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION____VIEW',
  DigitalReportingPayrollView = 'DIGITAL_REPORTING_PAYROLL_______VIEW',
  DigitalReportingTmsView = 'DIGITAL_REPORTING_TMS_VIEW',
  DigitalReportingTmsAttendanceSummaryExcelListingRptView = 'DIGITAL_REPORTING_TMS_ATTENDANCE_SUMMARY_EXCEL_LISTING_RPT_VIEW',
  DigitalReportingTmsAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsEmployeeClockingListingRptView = 'DIGITAL_REPORTING_TMS_EMPLOYEE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsPeriodicOvertimeForecastListingRptView = 'DIGITAL_REPORTING_TMS_PERIODIC_OVERTIME_FORECAST_LISTING_RPT_VIEW',
  DigitalReportingTmsMonthlyAttendanceReportListingRptView = 'DIGITAL_REPORTING_TMS_MONTHLY_ATTENDANCE_REPORT_LISTING_RPT_VIEW',
  DigitalReportingTmsAbsenteeismListingRptView = 'DIGITAL_REPORTING_TMS_ABSENTEEISM_LISTING_RPT_VIEW',
  DigitalReportingTmsIncompleteClockingListingRptView = 'DIGITAL_REPORTING_TMS_INCOMPLETE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsIrregularAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_IRREGULAR_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsLateInEarlyOutListingRptView = 'DIGITAL_REPORTING_TMS_LATE_IN_EARLY_OUT_LISTING_RPT_VIEW',
  DigitalReportingTmsOtMealSummaryRptView = 'DIGITAL_REPORTING_TMS_OT_MEAL_SUMMARY_RPT_VIEW',
  DigitalReportingTmsOtMealDetailRptView = 'DIGITAL_REPORTING_TMS_OT_MEAL_DETAIL_RPT_VIEW',
  DigitalReportingProjectTimesheetView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_VIEW',
  DigitalReportingProjectTimesheetRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_RPT_VIEW',
  DigitalReportingProjectTimesheetSummaryRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_SUMMARY_RPT_VIEW',
  MulticurrencySetupView = 'MULTICURRENCY_SETUP_VIEW',
  MulticurrencySetupManualEntryView = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_VIEW',
  MulticurrencySetupManualEntryCreate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_CREATE',
  MulticurrencySetupManualEntryUpdate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_UPDATE',
  MulticurrencySetupManualEntryDelete = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_DELETE',
  MulticurrencySetupManualEntryReactivate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_REACTIVATE',
  MulticurrencySetupManualEntryInactive = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_INACTIVE',
  MulticurrencySetupManualExcelUploadView = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_VIEW',
  MulticurrencySetupManualExcelUploadImport = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_IMPORT',
  MulticurrencySetupManualExcelUploadDownload = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_DOWNLOAD',
  CommonSettingProjectExpenseRead = 'COMMON_SETTING_PROJECT_EXPENSE_READ',
  CommonSettingProjectExpenseProjectMasterRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_READ',
  CommonSettingProjectExpenseProjectMasterCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_CREATE',
  CommonSettingProjectExpenseProjectMasterUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_UPDATE',
  CommonSettingProjectExpenseProjectMasterDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_DELETE',
  CommonSettingProjectExpenseProjectExpenseTypeRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_READ',
  CommonSettingProjectExpenseProjectExpenseTypeCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_CREATE',
  CommonSettingProjectExpenseProjectExpenseTypeUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_UPDATE',
  CommonSettingProjectExpenseProjectExpenseTypeDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyRead = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyCreate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyUpdate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyDelete = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyInactive = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyReactivate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingProjectExpenseGlAccountMappingRead = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_READ',
  CommonSettingProjectExpenseGlAccountMappingCreate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_CREATE',
  CommonSettingProjectExpenseGlAccountMappingUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_UPDATE',
  CommonSettingProjectExpenseGlAccountMappingDelete = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_DELETE',
  HomeProjectExecSummaryRead = 'HOME_PROJECT_EXEC_SUMMARY____READ',
  CompanySettingProjectRead = 'COMPANY_SETTING_PROJECT_______READ',
  CompanySettingProjectProjectExpenseClaimSettingRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___READ',
  CompanySettingProjectProjectExpenseClaimSettingUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___UPDATE',
  CompanySettingProjectGlAccountMappingAssignmentRead = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___READ',
  CompanySettingProjectGlAccountMappingAssignmentUpdate = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___UPDATE',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___READ',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___UPDATE',
  HomeProjectRead = 'HOME_PROJECT_______READ',
  HomeProjectStaffProjectCostAllocationRead = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____READ',
  HomeProjectStaffProjectCostAllocationCreate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____CREATE',
  HomeProjectStaffProjectCostAllocationUpdate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____UPDATE',
  HomeProjectStaffProjectCostAllocationDelete = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____DELETE',
  HomeProjectProjectExpenseClaimApplicationRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____READ',
  HomeProjectProjectExpenseClaimApplicationCreate = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____CREATE',
  HomeProjectProjectExpenseClaimApplicationApprove = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____APPROVE',
  HomeProjectProjectExpenseClaimApplicationReject = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____REJECT',
  HomeProjectProjectExpenseClaimApplicationWithdraw = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____WITHDRAW',
  HomeProjectApprovedProjectExpenseClaimVerificationRead = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____READ',
  HomeProjectApprovedProjectExpenseClaimVerificationVerify = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____VERIFY',
  HomeProjectApprovedProjectExpenseClaimVerificationReject = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REJECT',
  HomeProjectApprovedProjectExpenseClaimVerificationReverse = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REVERSE',
  HomeProjectProjectExpenseClaimPostingToPayrollRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____READ',
  HomeProjectProjectExpenseClaimPostingToPayrollPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____POST',
  HomeProjectProjectExpenseClaimPostingToPayrollReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____REVERSE',
  HomeProjectProjectExpenseClaimPaymentRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____READ',
  HomeProjectProjectExpenseClaimPaymentPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____POST',
  HomeProjectProjectExpenseClaimPaymentReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____REVERSE',
  HomeProjectGlProcessingRead = 'HOME_PROJECT_GL_PROCESSING____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportRead = 'HOME_PROJECT_GL_EXPORT____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportClaimGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportClaimGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  CommonSettingPersonnelDataChangesRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CommonSettingPersonnelDataChangesCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___CREATE',
  CommonSettingPersonnelDataChangesUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  CommonSettingPersonnelDataChangesDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___DELETE',
  CommonSettingPersonnelDataChangesInactive = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___INACTIVE',
  CommonSettingPersonnelDataChangesReactivate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___REACTIVATE',
  CommonSettingPersonnelDataChangesPolicyView = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___VIEW',
  CommonSettingPersonnelDataChangesPolicyCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___CREATE',
  CommonSettingPersonnelDataChangesPolicyRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___READ',
  CommonSettingPersonnelDataChangesPolicyUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___UPDATE',
  CommonSettingPersonnelDataChangesPolicyDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___DELETE',
  CompanySettingPersonnelDataChangesRead = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CompanySettingPersonnelDataChangesUpdate = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  HomePersonnelDataChangesRead = 'HOME_PERSONNEL_DATA_CHANGES____READ',
  HomePersonnelDataChangesApprove = 'HOME_PERSONNEL_DATA_CHANGES____APPROVE',
  HomePersonnelDataChangesReject = 'HOME_PERSONNEL_DATA_CHANGES____REJECT',
  CommonSettingTmsBluetoothBeaconDevicesRead = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___READ',
  CommonSettingTmsBluetoothBeaconDevicesCreate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___CREATE',
  CommonSettingTmsBluetoothBeaconDevicesUpdate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___UPDATE',
  CommonSettingTmsBluetoothBeaconDevicesDelete = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___DELETE',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentRead = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___READ',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentUpdate = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___UPDATE',
  CommonSettingPerformanceRead = 'COMMON_SETTING_PERFORMANCE_______READ',
  CommonSettingPerformanceFormItemRatingSetupRead = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______READ',
  CommonSettingPerformanceFormItemRatingSetupCreate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______CREATE',
  CommonSettingPerformanceFormItemRatingSetupUpdate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______UPDATE',
  CommonSettingPerformanceFormItemRatingSetupDelete = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______DELETE',
  CommonSettingPerformanceReviewGradingTableRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______READ',
  CommonSettingPerformanceReviewGradingTableCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______CREATE',
  CommonSettingPerformanceReviewGradingTableUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______UPDATE',
  CommonSettingPerformanceReviewGradingTableDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______DELETE',
  CommonSettingPerformanceReviewPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______READ',
  CommonSettingPerformanceReviewPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______CREATE',
  CommonSettingPerformanceReviewPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______UPDATE',
  CommonSettingPerformanceReviewPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______DELETE',
  CommonSettingPerformanceKpiCategoryRead = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______READ',
  CommonSettingPerformanceKpiCategoryCreate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______CREATE',
  CommonSettingPerformanceKpiCategoryUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______UPDATE',
  CommonSettingPerformanceKpiCategoryDelete = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______READ',
  CommonSettingPerformanceKpiPredefinedItemCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______CREATE',
  CommonSettingPerformanceKpiPredefinedItemUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______UPDATE',
  CommonSettingPerformanceKpiPredefinedItemDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemReactivate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______REACTIVATE',
  CommonSettingPerformanceKpiPredefinedItemInactive = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______INACTIVE',
  CommonSettingPerformanceKpiPredefinedTemplateRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______READ',
  CommonSettingPerformanceKpiPredefinedTemplateCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______CREATE',
  CommonSettingPerformanceKpiPredefinedTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______UPDATE',
  CommonSettingPerformanceKpiPredefinedTemplateDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewItemRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______READ',
  CommonSettingPerformanceReviewItemCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______CREATE',
  CommonSettingPerformanceReviewItemUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______UPDATE',
  CommonSettingPerformanceReviewItemDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______DELETE',
  CommonSettingPerformanceReviewTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______READ',
  CommonSettingPerformanceReviewTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______READ',
  CommonSettingPerformanceReviewProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceReviewProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceReviewProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______DELETE',
  CommonSettingPerformanceReviewFormTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______READ',
  CommonSettingPerformanceReviewFormTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewFormTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewFormTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewGroupRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______READ',
  CommonSettingPerformanceReviewGroupCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______CREATE',
  CommonSettingPerformanceReviewGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______UPDATE',
  CommonSettingPerformanceReviewGroupDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______DELETE',
  CommonSettingPerformanceRewardGroupRead = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______READ',
  CommonSettingPerformanceRewardGroupCreate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______CREATE',
  CommonSettingPerformanceRewardGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______UPDATE',
  CommonSettingPerformanceRewardGroupDelete = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______DELETE',
  HomePerformanceRead = 'HOME_PERFORMANCE_______READ',
  CompanySettingPerformanceRead = 'COMPANY_SETTING_PERFORMANCE_______READ',
  CompanySettingPerformanceReviewGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___READ',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___READ',
  CompanySettingPerformanceReviewGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceReviewGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceReviewGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___DELETE',
  CompanySettingPerformanceRewardGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___READ',
  CompanySettingPerformanceRewardGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceRewardGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceRewardGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___DELETE',
  HomePerformanceOrganizationalGoalRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____READ',
  HomePerformanceOrganizationalGoalCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____CREATE',
  HomePerformanceOrganizationalGoalUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____UPDATE',
  HomePerformanceOrganizationalGoalDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____DELETE',
  HomePerformanceOrganizationalKpiPlanningRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____READ',
  HomePerformanceOrganizationalKpiPlanningCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CREATE',
  HomePerformanceOrganizationalKpiPlanningUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____UPDATE',
  HomePerformanceOrganizationalKpiPlanningDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____DELETE',
  HomePerformanceOrganizationalKpiPlanningChangeOwner = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CHANGE_OWNER',
  HomePerformanceKpiExerciseRead = 'HOME_PERFORMANCE_KPI_EXERCISE____READ',
  HomePerformanceKpiExerciseSetupRead = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____READ',
  HomePerformanceKpiExerciseSetupCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____CREATE',
  HomePerformanceKpiExerciseSetupUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____UPDATE',
  HomePerformanceKpiExerciseSetupDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DELETE',
  HomePerformanceKpiExerciseSetupGenerateKpi = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____GENERATE_KPI',
  HomePerformanceKpiExerciseSetupDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceKpiExerciseListingRead = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____READ',
  HomePerformanceKpiExerciseListingCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CREATE',
  HomePerformanceKpiExerciseListingUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____UPDATE',
  HomePerformanceKpiExerciseListingDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DELETE',
  HomePerformanceKpiExerciseListingInitiate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____INITIATE',
  HomePerformanceKpiExerciseListingRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____REVERT',
  HomePerformanceKpiExerciseListingClearRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceKpiExerciseListingDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DUPLICATE',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___UPDATE',
  CommonSettingPerformanceKpiProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______READ',
  CommonSettingPerformanceKpiProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceKpiProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceKpiProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______DELETE',
  HomePerformanceEmployeeKpiRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____READ',
  HomePerformanceEmployeeKpiRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____VIEW',
  HomePerformanceEmployeeReviewRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____READ',
  HomePerformanceEmployeeReviewRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____VIEW',
  HomePerformanceReviewExerciseRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE____READ',
  HomePerformanceReviewExerciseSetupRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____READ',
  HomePerformanceReviewExerciseSetupCreate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____CREATE',
  HomePerformanceReviewExerciseSetupView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____VIEW',
  HomePerformanceReviewExerciseSetupUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____UPDATE',
  HomePerformanceReviewExerciseSetupDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DELETE',
  HomePerformanceReviewExerciseSetupGenerate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____GENERATE',
  HomePerformanceReviewExerciseSetupDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceReviewExerciseSetupPeerRegistration = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____PEER_REGISTRATION',
  HomePerformanceReviewExerciseListingRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____READ',
  HomePerformanceReviewExerciseListingProceed = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____PROCEED',
  HomePerformanceReviewExerciseListingView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____VIEW',
  HomePerformanceReviewExerciseListingUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____UPDATE',
  HomePerformanceReviewExerciseListingDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DELETE',
  HomePerformanceReviewExerciseListingInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____INITIATE',
  HomePerformanceReviewExerciseListingReInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____RE_INITIATE',
  HomePerformanceReviewExerciseListingRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____REVERT',
  HomePerformanceReviewExerciseListingClearRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceReviewExerciseListingDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DUPLICATE',
  HomeMymenuMyPerformancePerformanceReviewMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____READ',
  HomeMymenuMyPerformancePerformanceReviewMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____VIEW',
  HomeMymenuMyPerformancePerformanceReviewMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____SUBMIT',
  HomeMymenuMyPerformanceReviewActionMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____READ',
  HomeMymenuMyPerformanceReviewActionMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____VIEW',
  HomeMymenuMyPerformanceReviewActionMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceReviewActionMymenuApprove = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____APPROVE',
  HomeMymenuMyPerformanceReviewActionMymenuSendBack = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SEND_BACK',
  HomeMymenuMyPerformanceReviewActionMymenuProceed = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____PROCEED',
  HomeMymenuMyPerformanceReviewActionMymenuSave = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SAVE',
  HomeMymenuMyPerformanceReviewActionMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SUBMIT',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateView = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____VIEW',
  CommonSettingTmsAutomatedDataImportRead = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___READ',
  CommonSettingTmsAutomatedDataImportCreate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___CREATE',
  CommonSettingTmsAutomatedDataImportUpdate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___UPDATE',
  CommonSettingTmsAutomatedDataImportDelete = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___DELETE',
  CommonSettingHelpdeskRead = 'COMMON_SETTING_HELPDESK_______READ',
  CommonSettingHelpdeskHelpdeskSubjectCreate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___CREATE',
  CommonSettingHelpdeskHelpdeskSubjectRead = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___READ',
  CommonSettingHelpdeskHelpdeskSubjectUpdate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___UPDATE',
  CommonSettingHelpdeskHelpdeskSubjectDelete = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___DELETE',
  CommonSettingHelpdeskHelpdeskSubjectInactive = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___INACTIVE',
  CommonSettingHelpdeskHelpdeskSubjectReactivate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___REACTIVATE',
  HomeHelpdeskRead = 'HOME_HELPDESK_______READ',
  HomeHelpdeskHelpdeskMasterListingRead = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___READ',
  HomeHelpdeskHelpdeskMasterListingView = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___VIEW',
  HomeHelpdeskHelpdeskMasterListingUpdate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___UPDATE',
  HomeHelpdeskHelpdeskMasterListingCreate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___CREATE',
  HomeHelpdeskHelpdeskMasterListingDelete = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___DELETE',
  HomeHelpdeskHelpdeskMasterListingReassign = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___REASSIGN',
  HomeHelpdeskHelpdeskMasterListingWithdraw = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___WITHDRAW',
  HomeHelpdeskAssigneeListingRead = 'HOME_HELPDESK_ASSIGNEE_LISTING___READ',
  HomeHelpdeskAssigneeListingView = 'HOME_HELPDESK_ASSIGNEE_LISTING___VIEW',
  HomeHelpdeskAssigneeListingUpdate = 'HOME_HELPDESK_ASSIGNEE_LISTING___UPDATE',
  HomeHelpdeskMyTicketsListingRead = 'HOME_HELPDESK_MY_TICKETS_LISTING___READ',
  HomeHelpdeskMyTicketsListingView = 'HOME_HELPDESK_MY_TICKETS_LISTING___VIEW',
  HomeHelpdeskMyTicketsListingCreate = 'HOME_HELPDESK_MY_TICKETS_LISTING___CREATE',
  HomeHelpdeskMyTicketsListingWithdraw = 'HOME_HELPDESK_MY_TICKETS_LISTING___WITHDRAW',
  HomeBusinessInsightsRead = 'HOME_BUSINESS_INSIGHTS_______READ',
  HomeBusinessInsightsHeadcountRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_______READ',
  HomeBusinessInsightsHeadcountHeadcountSalaryRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_HEADCOUNT_SALARY_______READ',
  HomeBusinessInsightsHeadcountYearsOfServiceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_YEARS_OF_SERVICE_______READ',
  HomeBusinessInsightsHeadcountSalaryRangeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_SALARY_RANGE_______READ',
  HomeBusinessInsightsHeadcountAgeGroupRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_AGE_GROUP_______READ',
  HomeBusinessInsightsHeadcountEmploymentTypeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYMENT_TYPE_______READ',
  HomeBusinessInsightsHeadcountEmployeeRaceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYEE_RACE_______READ',
  HomeBusinessInsightsHeadcountGenderRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_GENDER_______READ',
  HomeBusinessInsightsOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadStatutoryContributionRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_STATUTORY_CONTRIBUTION_______READ',
  HomeBusinessInsightsOvertimeRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_______READ',
  HomeBusinessInsightsOvertimeOtHoursCostRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_OT_HOURS_COST_______READ',
  HomeBusinessInsightsLeaveRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_______READ',
  HomeBusinessInsightsLeaveLeaveAnalysisRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_LEAVE_ANALYSIS_______READ',
  HomeBusinessInsightsStaffClaimRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_______READ',
  HomeBusinessInsightsStaffClaimClaimAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_CLAIM_ANALYSIS_______READ',
  HomeBusinessInsightsStaffTurnoverRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_______READ',
  HomeBusinessInsightsStaffTurnoverOffboardingAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_OFFBOARDING_ANALYSIS_______READ',
  CommonSettingTimesheetRead = 'COMMON_SETTING_TIMESHEET_______READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___DELETE',
  CommonSettingTimesheetProjectTimesheetApproverMasterResendEmail = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___RESEND_EMAIL',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___DELETE',
  HomeMymenuMyTrainingRead = 'HOME_MYMENU_MY_TRAINING_____READ',
  HomeMymenuMyTrainingTrainingCheckInRead = 'HOME_MYMENU_MY_TRAINING_TRAINING_CHECK_IN_____READ',
  HomeMymenuMyTrainingTrainingCheckInCreate = 'HOME_MYMENU_MY_TRAINING_TRAINING_CHECK_IN_____CREATE',
  HomeMymenuMyTrainingScheduledTrainingRead = 'HOME_MYMENU_MY_TRAINING_SCHEDULED_TRAINING_____READ',
  HomeMymenuMyTrainingScheduledTrainingUpdate = 'HOME_MYMENU_MY_TRAINING_SCHEDULED_TRAINING_____UPDATE',
  HomeMymenuMyTrainingTrainingCalendarRead = 'HOME_MYMENU_MY_TRAINING_TRAINING_CALENDAR_____READ',
  HomeMymenuMyTrainingTrainingCalendarCreate = 'HOME_MYMENU_MY_TRAINING_TRAINING_CALENDAR_____CREATE',
  HomeMymenuMyTrainingRequiredTrainingRead = 'HOME_MYMENU_MY_TRAINING_REQUIRED_TRAINING_____READ',
  HomeMymenuMyTrainingRequestForTrainingRead = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____READ',
  HomeMymenuMyTrainingRequestForTrainingCreate = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____CREATE',
  HomeMymenuMyTrainingRequestForTrainingCancel = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____CANCEL',
  HomeMymenuMyTrainingRequestForTrainingView = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____VIEW',
  HomeMymenuMyMileageLogRead = 'HOME_MYMENU_MY_MILEAGE_LOG___READ',
  HomeMymenuMyMileageLogCreate = 'HOME_MYMENU_MY_MILEAGE_LOG___CREATE',
  HomeMymenuMyMileageLogUpdate = 'HOME_MYMENU_MY_MILEAGE_LOG___UPDATE',
  HomeMymenuMyMileageLogDelete = 'HOME_MYMENU_MY_MILEAGE_LOG___DELETE',
  CommonSettingRecruitmentRead = 'COMMON_SETTING_RECRUITMENT_READ',
  CommonSettingRecruitmentApprovalPoliciesRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____DELETE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____DELETE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____DELETE',
  CommonSettingRecruitmentRecruitmentSourceRead = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_READ',
  CommonSettingRecruitmentRecruitmentSourceCreate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_CREATE',
  CommonSettingRecruitmentRecruitmentSourceUpdate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_UPDATE',
  CommonSettingRecruitmentRecruitmentSourceDelete = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_DELETE',
  CommonSettingRecruitmentRecruitmentExpensesRead = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_READ',
  CommonSettingRecruitmentRecruitmentExpensesCreate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_CREATE',
  CommonSettingRecruitmentRecruitmentExpensesUpdate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_UPDATE',
  CommonSettingRecruitmentRecruitmentExpensesDelete = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_DELETE',
  CommonSettingRecruitmentEApplicationFormSetupCreate = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_CREATE',
  CommonSettingRecruitmentEApplicationFormSetupUpdate = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_UPDATE',
  CommonSettingRecruitmentEApplicationFormSetupRead = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_READ',
  CommonSettingRecruitmentEApplicationFormSetupDelete = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_DELETE',
  CommonSettingRecruitmentELetterTemplateRead = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_READ',
  CommonSettingRecruitmentELetterTemplateCreate = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_CREATE',
  CommonSettingRecruitmentELetterTemplateUpdate = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_UPDATE',
  CommonSettingRecruitmentELetterTemplateDelete = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_DELETE',
  CommonSettingRecruitmentPreofferChecklistRead = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_READ',
  CommonSettingRecruitmentPreofferChecklistCreate = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_CREATE',
  CommonSettingRecruitmentPreofferChecklistUpdate = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_UPDATE',
  CommonSettingRecruitmentPreofferChecklistDelete = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_DELETE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsRead = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_READ',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsCreate = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_CREATE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsUpdate = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_UPDATE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsDelete = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_DELETE',
  CommonSettingRecruitmentDeclineReasonRead = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_READ',
  CommonSettingRecruitmentDeclineReasonCreate = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_CREATE',
  CommonSettingRecruitmentDeclineReasonUpdate = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_UPDATE',
  CommonSettingRecruitmentDeclineReasonDelete = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_DELETE',
  CommonSettingRecruitmentPrehiringFormSetupCreate = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_CREATE',
  CommonSettingRecruitmentPrehiringFormSetupUpdate = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_UPDATE',
  CommonSettingRecruitmentPrehiringFormSetupRead = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_READ',
  CommonSettingRecruitmentPrehiringFormSetupDelete = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_DELETE',
  CommonSettingRecruitmentJobPortalSetupRead = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_READ',
  CommonSettingRecruitmentJobPortalSetupCreate = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_CREATE',
  CommonSettingRecruitmentJobPortalSetupUpdate = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_UPDATE',
  CommonSettingRecruitmentJobPortalSetupDelete = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_DELETE',
  HomeRecruitmentRead = 'HOME_RECRUITMENT____READ',
  HomeRecruitmentRecruitmentSettingRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentSettingApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_SETTING_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____DELETE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____DELETE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____DELETE',
  HomeRecruitmentTalentPoolRead = 'HOME_RECRUITMENT_TALENT_POOL____READ',
  HomeRecruitmentTalentPoolTalentPoolListingRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____READ',
  HomeRecruitmentTalentPoolTalentPoolListingView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____VIEW',
  HomeRecruitmentTalentPoolTalentPoolListingCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____CREATE',
  HomeRecruitmentTalentPoolTalentPoolListingUpdate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____UPDATE',
  HomeRecruitmentTalentPoolTalentPoolListingDelete = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____DELETE',
  HomeRecruitmentTalentPoolTalentInvitationRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____READ',
  HomeRecruitmentTalentPoolTalentInvitationCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____CREATE',
  HomeRecruitmentTalentPoolTalentInvitationResendEmail = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____RESEND_EMAIL',
  HomeRecruitmentTalentPoolTalentInvitationCancel = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____CANCEL',
  HomeRecruitmentTalentPoolTalentInvitationUpdate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____UPDATE',
  HomeRecruitmentTalentPoolTalentInvitationView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____VIEW',
  HomeRecruitmentTalentPoolTalentSearchRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____READ',
  HomeRecruitmentTalentPoolTalentSearchView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____VIEW',
  HomeRecruitmentTalentPoolTalentSearchCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____CREATE',
  HomeRecruitmentTalentPoolTalentSearchDelete = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____DELETE',
  HomeRecruitmentRecruitmentRequisitionRead = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____READ',
  HomeRecruitmentRecruitmentRequisitionView = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____VIEW',
  HomeRecruitmentRecruitmentRequisitionUpdate = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____UPDATE',
  HomeRecruitmentRecruitmentRequisitionCreate = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____CREATE',
  HomeRecruitmentRecruitmentRequisitionDelete = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____DELETE',
  HomeRecruitmentRecruitmentRequisitionWithdraw = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____WITHDRAW',
  HomeRecruitmentJobVacancyManagementRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT____READ',
  HomeRecruitmentJobVacancyManagementJobVacancyRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____READ',
  HomeRecruitmentJobVacancyManagementJobVacancyCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____CREATE',
  HomeRecruitmentJobVacancyManagementJobVacancyUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____UPDATE',
  HomeRecruitmentJobVacancyManagementJobVacancyDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____DELETE',
  HomeRecruitmentJobVacancyManagementJobVacancyView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____VIEW',
  HomeRecruitmentJobVacancyManagementJobPostingRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____READ',
  HomeRecruitmentJobVacancyManagementJobPostingCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____CREATE',
  HomeRecruitmentJobVacancyManagementJobPostingUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____UPDATE',
  HomeRecruitmentJobVacancyManagementJobPostingDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____DELETE',
  HomeRecruitmentJobVacancyManagementJobPostingView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____VIEW',
  HomeRecruitmentJobVacancyManagementApplicantManagementRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____READ',
  HomeRecruitmentJobVacancyManagementApplicantManagementCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____CREATE',
  HomeRecruitmentJobVacancyManagementApplicantManagementUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____UPDATE',
  HomeRecruitmentJobVacancyManagementApplicantManagementDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____DELETE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____VIEW',
  HomeRecruitmentJobOfferManagementRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT____READ',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____READ',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____CREATE',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____UPDATE',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____DELETE',
  HomeRecruitmentJobOfferManagementOfferProposalRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____READ',
  HomeRecruitmentJobOfferManagementOfferProposalCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____CREATE',
  HomeRecruitmentJobOfferManagementOfferProposalUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____UPDATE',
  HomeRecruitmentJobOfferManagementOfferProposalDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____DELETE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____READ',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____CREATE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____UPDATE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____DELETE',
  HomeRecruitmentJobOfferManagementOfferProposalView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____VIEW',
  HomeRecruitmentJobOfferManagementOfferApprovalRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____READ',
  HomeRecruitmentJobOfferManagementOfferApprovalCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____CREATE',
  HomeRecruitmentJobOfferManagementOfferApprovalUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____UPDATE',
  HomeRecruitmentJobOfferManagementOfferApprovalDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____DELETE',
  HomeRecruitmentJobOfferManagementOfferApprovalView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____VIEW',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____READ',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____CREATE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____UPDATE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____DELETE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____VIEW',
  HomeRecruitmentHiringProcessRead = 'HOME_RECRUITMENT_HIRING_PROCESS____READ',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringRead = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____READ',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringSendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____SEND_EMAIL',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringResendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____RESEND_EMAIL',
  HomeRecruitmentHiringProcessQuickHiringRead = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____READ',
  HomeRecruitmentHiringProcessQuickHiringCreate = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____CREATE',
  HomeRecruitmentHiringProcessQuickHiringUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____UPDATE',
  HomeRecruitmentHiringProcessQuickHiringDelete = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____DELETE',
  HomeRecruitmentHiringProcessQuickHiringSendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____SEND_EMAIL',
  HomeRecruitmentHiringProcessPrehiringDataUpdateRead = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____READ',
  HomeRecruitmentHiringProcessPrehiringDataUpdateCancel = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____CANCEL',
  HomeRecruitmentHiringProcessPrehiringDataUpdateView = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____VIEW',
  HomeRecruitmentHiringProcessPrehiringDataUpdateUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____UPDATE',
  HomeRecruitmentHiringProcessPrehiringDataUpdateResendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____RESEND_EMAIL',
  HomeRecruitmentHiringProcessNewHireProcessRead = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____READ',
  HomeRecruitmentHiringProcessNewHireProcessUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____UPDATE',
  HomeRecruitmentHiringProcessNewHireProcessView = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____VIEW',
  HomeMymenuMyRecruitmentRead = 'HOME_MYMENU_MY_RECRUITMENT_____READ',
  HomeMymenuMyRecruitmentTalentSearchRead = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____READ',
  HomeMymenuMyRecruitmentTalentSearchView = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____VIEW',
  HomeMymenuMyRecruitmentTalentSearchCreate = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____CREATE',
  HomeMymenuMyRecruitmentTalentSearchDelete = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____DELETE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuRead = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____READ',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuCreate = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____CREATE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuView = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____VIEW',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuUpdate = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____UPDATE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuSubmit = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____SUBMIT',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuDelete = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____DELETE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuCancel = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____CANCEL',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuWithdraw = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____WITHDRAW',
  HomeMymenuMyRecruitmentApplicantManagementRead = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____READ',
  HomeMymenuMyRecruitmentApplicantManagementCreate = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____CREATE',
  HomeMymenuMyRecruitmentApplicantManagementUpdate = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____UPDATE',
  HomeMymenuMyRecruitmentApplicantManagementDelete = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____DELETE',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuRead = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____READ',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuUpdate = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____UPDATE',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuView = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____VIEW',
  HomeMymenuMyRecruitmentOfferListingRead = 'HOME_MYMENU_MY_RECRUITMENT_OFFER_LISTING____READ',
  HomeMymenuMyRecruitmentOfferListingView = 'HOME_MYMENU_MY_RECRUITMENT_OFFER_LISTING____VIEW',
  HomeLeaveMaxLeaveBalanceForfeitureRead = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____READ',
  HomeLeaveMaxLeaveBalanceForfeiturePost = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____POST',
  HomeLeaveMaxLeaveBalanceForfeitureReverse = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____REVERSE',
  HomeLeaveLeaveEntitlementByEmployeeReprocess = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_EMPLOYEE____REPROCESS',
  DigitalReportingTmsTimeOffApplicationRptView = 'DIGITAL_REPORTING_TMS_TIME_OFF_APPLICATION_RPT_VIEW'
}

export type HrdcClaimEntity = NewInterfaceBase & {
  __typename?: 'HrdcClaimEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  application_no: Scalars['String'];
  amount_claimed: Scalars['Float'];
  total_trainee?: Maybe<Scalars['Float']>;
  approved_claimed?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  document_id: Scalars['String'];
  voice_document_id?: Maybe<Scalars['String']>;
  approval_status: Approval_Status;
  HRDCClaimLog?: Maybe<Array<HrdcClaimLogEntity>>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  Document?: Maybe<DocumentsEntity>;
  voiceDocument?: Maybe<DocumentsEntity>;
};

export type HrdcClaimLogEntity = NewInterfaceBase & {
  __typename?: 'HrdcClaimLogEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  hrdc_claim_id: Scalars['String'];
  approver_id: Scalars['String'];
  approval_status: Approval_Status;
  remarks?: Maybe<Scalars['String']>;
  HRDCClaim?: Maybe<HrdcClaimEntity>;
  approverName: Scalars['String'];
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncrementSimulationEntity = InterfaceBase & {
  __typename?: 'IncrementSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IncrementSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type IncrementSimulationInput = {
  IncrementSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'InsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  Document?: Maybe<DocumentsEntity>;
};

export type InsuranceSchemeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name: Scalars['String'];
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum IntegrationClockType {
  In = 'IN',
  Out = 'OUT',
  Empty = 'Empty'
}

export type InterbankGiroEntity = InterfaceBase & {
  __typename?: 'InterbankGiroEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InterbankGiroID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
};

export type InterbankGiroInput = {
  InterbankGiroID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBase = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBaseInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
};

export type InterfaceBaseV2 = {
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
};

export type InterfaceBaseV3 = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum InterviewerRating {
  VeryPoor = 'Very_Poor',
  Poor = 'Poor',
  Average = 'Average',
  Good = 'Good',
  VeryGood = 'Very_Good'
}

export enum InviteStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved'
}

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  InvoiceDate?: Maybe<Scalars['String']>;
  InvoiceId?: Maybe<Scalars['String']>;
  InvoiceTotal?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
};

export enum ItemActionType {
  New = 'New',
  View = 'View',
  Edit = 'Edit',
  Delete = 'Delete'
}

export enum ItemRatingMethod {
  Comment = 'Comment',
  FormItemRating = 'Form_Item_Rating'
}



export type JobAccountEntity = InterfaceBase & {
  __typename?: 'JobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  MyJobAccount?: Maybe<Array<MyJobAccountEntity>>;
  ClaimHeader?: Maybe<Array<ClaimHeaderEntity>>;
};

export type JobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobCompetencyRclEntity = InterfaceBase & {
  __typename?: 'JobCompetencyRCLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobCompetencyRCLID: Scalars['String'];
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
};

export type JobCompetencyRclInput = {
  JobCompetencyRCLID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobDesignationEntity = InterfaceBase & {
  __typename?: 'JobDesignationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
  IsInUse: Scalars['Boolean'];
};

export type JobDesignationGradeEntity = InterfaceBase & {
  __typename?: 'JobDesignationGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationGradeID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobGrade: JobGradeEntity;
  JobDesignation: JobDesignationEntity;
};

export type JobDesignationGradeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
};

export type JobDesignationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type JobDesignationSkillSetEntity = InterfaceBase & {
  __typename?: 'JobDesignationSkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationSkillSetID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetDetail?: Maybe<SkillSetDetailEntity>;
  JobDesignation?: Maybe<JobDesignationEntity>;
};

export type JobDesignationSkillSetInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};

export type JobGradeBulkInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradeByJobDesignationOutput = {
  __typename?: 'JobGradeByJobDesignationOutput';
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<JobGradeOutput>;
};

export type JobGradeEntity = InterfaceBase & {
  __typename?: 'JobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobGradePolicies?: Maybe<Array<JobGradePolicyEntity>>;
};

export type JobGradeInput = {
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradeOutput = {
  __typename?: 'JobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradePolicyEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
};

export type JobGradePolicyInput = {
  JobGradePolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradePolicyMatrixEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyMatrixID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGradeID: Scalars['String'];
  OvertimeClaimTableID: Scalars['String'];
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
  OvertimeClaimTable?: Maybe<OvertimeClaimTableEntity>;
};

export type JobGradePolicyMatrixInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};

export type JobGradesEntity = InterfaceBase & {
  __typename?: 'JobGradesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type JobGradesInput = {
  name?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentEntity = InterfaceBase & {
  __typename?: 'JobPositionAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobPositionAssignmentID: Scalars['String'];
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentInput = {
  JobPositionAssignmentID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobPositionListingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobPositionReportingID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToCompanyID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingEntity = NewInterfaceBase & {
  __typename?: 'JobPositionReportingEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  job_position_reporting_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_structure_id?: Maybe<Scalars['String']>;
  reporting_structure?: Maybe<ReportingStructureEntity>;
  from_org_unit_id?: Maybe<Scalars['String']>;
  from_org_unit?: Maybe<CompanyOrgUnitEntity>;
  to_org_unit_id?: Maybe<Scalars['String']>;
  to_org_unit?: Maybe<CompanyOrgUnitEntity>;
  from_job_position_id?: Maybe<Scalars['String']>;
  from_job_position?: Maybe<JobDesignationEntity>;
  to_job_position_id?: Maybe<Scalars['String']>;
  to_job_position?: Maybe<JobDesignationEntity>;
  company?: Maybe<CompanyEntity>;
  to_company_id?: Maybe<Scalars['String']>;
  to_company?: Maybe<CompanyEntity>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JobPositionList: Array<JobPositionListingInput>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type JobPositionReportingOutput = {
  __typename?: 'JobPositionReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  JobPositionList?: Maybe<Array<JobPositionReportingEntity>>;
};

export type JobVacancyStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentJobVacancyStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type JoinedDateError = {
  __typename?: 'JoinedDateError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum KpiType {
  Quantitative = 'Quantitative',
  Qualitative = 'Qualitative'
}

export type KpiActiveInactiveInput = {
  ID: Scalars['String'];
  Status: Status;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryEntity = InterfaceBase & {
  __typename?: 'KpiCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrgGoals?: Maybe<Array<OrganizationalGoalEntity>>;
  OrgKpiPlannings?: Maybe<Array<OrgKpiPlanningDetailsEntity>>;
  InUse: Scalars['Boolean'];
  PredefinedItemCount: Scalars['Float'];
};

export type KpiCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryItemInput = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  RatingScaleTable: Array<RatingScaleTableInput>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiExerciseDetailsEditInput = {
  ID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExercise?: Maybe<KpiExerciseEntity>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  EmployeeKpiExerciseID?: Maybe<Scalars['String']>;
  EmployeeSubmitDT?: Maybe<Scalars['DateTime']>;
};

export type KpiExerciseEntity = InterfaceBase & {
  __typename?: 'KpiExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExerciseSetup?: Maybe<KpiExerciseSetupEntity>;
};

export type KpiExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseSetupEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupDetails?: Maybe<Array<KpiExerciseSetupDetailsEntity>>;
  InUse?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  KpiExerciseSetupDetails: Array<KpiExerciseSetupDetailsInput>;
};

export type KpiMinMaxWeightage = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Weightage?: Maybe<Scalars['Float']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  KpiItem?: Maybe<Array<KpiCategoryItemInput>>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiPredefinedItemEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  FormItemRatingID: Scalars['String'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  KpiCategory: KpiCategoryEntity;
  FormItemRating: FormItemRatingEntity;
  InUse: Scalars['Boolean'];
};

export type KpiPredefinedItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiPredefinedItemWeightage = {
  __typename?: 'KpiPredefinedItemWeightage';
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedItemWeightageInput = {
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedTemplateEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  WeightageType: QuantitativeType;
  KpiWeightage: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  CategoryCount: Scalars['Float'];
  ItemCount: Scalars['Float'];
};

export type KpiPredefinedTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  KpiWeightage?: Maybe<Array<KpiWeightageInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum KpiSource {
  KpiPredefinedTemplate = 'Kpi_Predefined_Template',
  EmployeeKpi = 'Employee_Kpi'
}

export type KpiWeightage = {
  __typename?: 'KpiWeightage';
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightage>>;
};

export type KpiWeightageInput = {
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightageInput>>;
};

export type LhdnReportEntity = InterfaceBase & {
  __typename?: 'LHDNReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LHDNReportID: Scalars['String'];
  ReportType: ReportType;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
};

export type LhdnReportInput = {
  ReportType?: Maybe<ReportType>;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LosInput = {
  From?: Maybe<Scalars['String']>;
  Until?: Maybe<Scalars['String']>;
  Day?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
};

export enum LanguageLevel {
  None = 'None',
  Slight = 'Slight',
  Fair = 'Fair',
  Fluent = 'Fluent'
}

export type LastUpdatedDatesOutput = {
  __typename?: 'LastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type Leave = {
  Name?: Maybe<Scalars['String']>;
  NoOfDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
};

export type LeaveAdjustmentListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type LeaveAdjustmentOutput = {
  __typename?: 'LeaveAdjustmentOutput';
  LeaveHeaderID?: Maybe<Scalars['String']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
};

export type LeaveAppOutput = {
  __typename?: 'LeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveApprovalUpdateInput = {
  LeaveTypeID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type LeaveBalanceSummary = {
  __typename?: 'LeaveBalanceSummary';
  BroughtForward?: Maybe<Scalars['Float']>;
  Adjustment?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  Earned?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  TakenYTD?: Maybe<Scalars['Float']>;
  Forfeited?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceYTD?: Maybe<Scalars['Float']>;
  CarryForward?: Maybe<Scalars['Float']>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  Encashment?: Maybe<Scalars['Float']>;
  Emergency?: Maybe<Scalars['Float']>;
  ApplicationFrequency?: Maybe<Scalars['Float']>;
  Advance?: Maybe<Scalars['Float']>;
  Occurred?: Maybe<Scalars['Float']>;
  CurrentYearAdd?: Maybe<Scalars['Float']>;
  CurrentYearUtilized?: Maybe<Scalars['Float']>;
  CurrentYearBalance?: Maybe<Scalars['Float']>;
  CarryForwardList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveBenefitEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveBenefitInput = {
  LeaveBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveBenefitRuleEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitRuleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID: Scalars['String'];
  LeaveItemID: Scalars['String'];
  FormulaText: Scalars['String'];
  FormulaValue: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveRule: LeaveRuleType;
  LOSRange: Scalars['String'];
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export type LeaveBenefitRuleInput = {
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  FormulaText?: Maybe<Scalars['String']>;
  FormulaValue?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveRule?: Maybe<LeaveRuleType>;
  LOSRange?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEmployeeEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeID: Scalars['String'];
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveCompulsory?: Maybe<LeaveCompulsoryEntity>;
};

export type LeaveCompulsoryEmployeeInput = {
  LeaveCompulsoryEmployeeID?: Maybe<Scalars['String']>;
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveCompulsoryEmployees?: Maybe<Array<LeaveCompulsoryEmployeeEntity>>;
};

export type LeaveCompulsoryInput = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryPolicyEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Sequence?: Maybe<Sequence>;
};

export type LeaveCompulsoryPolicyInput = {
  LeaveCompulsoryPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'LeaveCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type LeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveDetails = {
  __typename?: 'LeaveDetails';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type LeaveDetailsEntity = InterfaceBase & {
  __typename?: 'LeaveDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
  EmployeeID: Scalars['String'];
};

export type LeaveDetailsInput = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseEntity = InterfaceBase & {
  __typename?: 'LeaveDiseaseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDiseaseID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveEntitlementByLeaveTypeListing = {
  __typename?: 'LeaveEntitlementByLeaveTypeListing';
  LeaveYear?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeavePolicy?: Maybe<LeaveItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  EncashedLeaveHistory?: Maybe<EncashedLeaveHistory>;
};

export type LeaveEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveEntitlementID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Scalars['String']>;
  EmployeeLeavePolicies?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Level?: Maybe<Scalars['Float']>;
  Sort?: Maybe<Scalars['Float']>;
};

export type LeaveEntitlementTemplate = {
  __typename?: 'LeaveEntitlementTemplate';
  LeaveEntitlementName?: Maybe<Scalars['String']>;
  LeaveEntitlementDesc?: Maybe<Scalars['String']>;
  LeaveEntitlementTypes?: Maybe<Array<LeaveEntitlementTypes>>;
};

export type LeaveEntitlementTypes = {
  __typename?: 'LeaveEntitlementTypes';
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export enum LeaveFrequency {
  AnnualIncrement = 'Annual_Increment',
  Occurrence = 'Occurrence',
  ManualEntry = 'Manual_Entry'
}

export type LeaveHeaderEntity = InterfaceBase & {
  __typename?: 'LeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  TimeOffID: Scalars['String'];
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type LeaveHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Remarks?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  VoiceDocument?: Maybe<DocumentsInput>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  LeaveDetailsInput?: Maybe<Array<LeaveDetailsInput>>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  TimeOffID?: Maybe<Scalars['String']>;
};

export enum LeaveHeaderType {
  OpeningBalance = 'Opening_Balance',
  ClosingBalance = 'Closing_Balance',
  LeaveApplication = 'Leave_Application',
  LeaveAdjustment = 'Leave_Adjustment',
  SystemAdjustment = 'System_Adjustment',
  CompulsoryLeave = 'Compulsory_Leave',
  CarryForward = 'Carry_Forward',
  BroughtForward = 'Brought_Forward',
  InterCoBroughtForward = 'InterCo_BroughtForward',
  InterCoTaken = 'InterCo_Taken',
  InterCoAdjustment = 'InterCo_Adjustment',
  InterCoEarned = 'InterCo_Earned',
  MaxBalanceForfeit = 'Max_Balance_Forfeit',
  TimeOffDeduction = 'TimeOff_Deduction'
}

export type LeaveHomeSummaryOutput = {
  __typename?: 'LeaveHomeSummaryOutput';
  OutOfStateActiveRecords?: Maybe<Scalars['Float']>;
  OutOfStateIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PendingApproval?: Maybe<Scalars['Float']>;
  PendingApprovalIsLastUpdated?: Maybe<Scalars['Boolean']>;
  AdjustmentNoOfRecords?: Maybe<Scalars['Float']>;
  AdjustmentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  EmployeeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfLeaveType?: Maybe<Scalars['Float']>;
  LeaveTypeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  CompulsoryNoOfRecords?: Maybe<Scalars['Float']>;
  CompulsoryIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayDeductionLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayDeductionIsLastUpdated?: Maybe<Scalars['Boolean']>;
  YearEndCarryForwardLastPostedDate?: Maybe<Scalars['DateTime']>;
  YearEndCarryForwardIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type LeaveIndentity = {
  __typename?: 'LeaveIndentity';
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveEntitledDay?: Maybe<Scalars['Float']>;
  PendingApprovalTotalDay?: Maybe<Scalars['Float']>;
};

export type LeaveItemEntity = InterfaceBase & {
  __typename?: 'LeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID: Scalars['String'];
  LeaveBenefitID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  IsAllowApplyEmployee?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  PlatformLeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  LOS?: Maybe<Array<LeaveBenefitRuleEntity>>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  CFList?: Maybe<Array<Scalars['JSON']>>;
  IsCrossCheckLeaveBalance?: Maybe<Scalars['Boolean']>;
  CrossCheckLeaveList?: Maybe<Array<Scalars['String']>>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
  AutoWithdrawal?: Maybe<Scalars['Boolean']>;
  IsMonthlyMaxBalance?: Maybe<Scalars['Boolean']>;
  MonthlyMaxBalance?: Maybe<Scalars['Float']>;
  InUse: Scalars['Boolean'];
};

export type LeaveItemInput = {
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum LeaveManagementDelegation {
  LeaveSubmissionApproval = 'Leave_Submission_Approval',
  LeaveCancellationApproval = 'Leave_Cancellation_Approval'
}

export type LeaveManagementListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
};

export type LeaveOutOfStateEntity = InterfaceBase & {
  __typename?: 'LeaveOutOfStateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveOutOfStateID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  FromState?: Maybe<StatesEntity>;
  ToState?: Maybe<StatesEntity>;
  FromCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
  ToCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
};

export type LeaveOutOfStateInput = {
  LeaveOutOfStateID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePolicyInput = {
  Title: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveFrequency: LeaveFrequency;
  AvailableBasis?: Maybe<AvailableBasis>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  IsAllowApplyEmployee?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  CFList?: Maybe<Array<Scalars['JSON']>>;
  IsCrossCheckLeaveBalance?: Maybe<Scalars['Boolean']>;
  CrossCheckLeaveList?: Maybe<Array<Scalars['String']>>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  AutoWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
  IsMonthlyMaxBalance?: Maybe<Scalars['Boolean']>;
  MonthlyMaxBalance?: Maybe<Scalars['Float']>;
};

export type LeavePostingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeaderIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsIDs?: Maybe<Array<Scalars['String']>>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
};

export type LeavePostingListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePostingOutput = {
  __typename?: 'LeavePostingOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
};

export type LeavePostingPeriodOutput = {
  __typename?: 'LeavePostingPeriodOutput';
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  CountUnpostedLeave?: Maybe<Scalars['Float']>;
  CountPostedLeave?: Maybe<Scalars['Float']>;
  CountLeaveRecords?: Maybe<Scalars['Float']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  isPostedInTheListing?: Maybe<Scalars['Boolean']>;
};

export type LeaveReasonEntity = InterfaceBase & {
  __typename?: 'LeaveReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveReasonInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveRecordsListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};

export type LeaveRecordsOutput = {
  __typename?: 'LeaveRecordsOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type LeaveRoundingDetailEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingDetailID: Scalars['String'];
  LeaveRoundingID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
};

export type LeaveRoundingDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingDetailID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
};

export type LeaveRoundingEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveRoundingDetails?: Maybe<Array<LeaveRoundingDetailEntity>>;
  Companies?: Maybe<Array<CompanyEntity>>;
};

export type LeaveRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export enum LeaveRuleType {
  Los = 'LOS',
  Gender = 'Gender',
  IsConfirmed = 'Is_Confirmed'
}

export type LeaveTakenOutput = {
  __typename?: 'LeaveTakenOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  TotalRecords?: Maybe<Scalars['Float']>;
};

export type LeaveTempEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveTempEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTempEntitlementID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
};

export type LeaveTempEntitlementInput = {
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTemplateEntity = InterfaceBase & {
  __typename?: 'LeaveTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveTemplateInput = {
  LeaveTemplateID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTypeBalance = {
  __typename?: 'LeaveTypeBalance';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Entitlement?: Maybe<Scalars['Float']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurrenceBalance?: Maybe<Scalars['Float']>;
};

export type LeaveTypeEntity = InterfaceBase & {
  __typename?: 'LeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  LeavePolicies?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LifeTimeError = {
  __typename?: 'LifeTimeError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
  isEmployeeSuperUser?: Maybe<Scalars['Boolean']>;
  onPremServer?: Maybe<Scalars['String']>;
};

export type Md_BankEntity = {
  __typename?: 'MD_BankEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNumber?: Maybe<Scalars['String']>;
  BankSwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Scalars['String']>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_CompanyEntity = {
  __typename?: 'MD_CompanyEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  CompanyWebsiteURL?: Maybe<Scalars['String']>;
  TelNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDNNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['String']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['String']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['String']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['String']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  StatutorySignatoryEmployeeNo?: Maybe<Scalars['String']>;
  StatutorySignatoryEmployeeName?: Maybe<Scalars['String']>;
  StatutorySignatoryCompany?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
};

export type Md_DepartmentDivisionEntity = {
  __typename?: 'MD_DepartmentDivisionEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DepartmentType?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeDependentEntity = {
  __typename?: 'MD_EmployeeDependentEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  DependentName?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  EmergencyContactPerson?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  ChildEducation?: Maybe<Scalars['String']>;
  ChildEligibleRate?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeEntity = {
  __typename?: 'MD_EmployeeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  MaritalStatus?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Scalars['String']>;
  PermanentPostcode?: Maybe<Scalars['String']>;
  PermanentCity?: Maybe<Scalars['String']>;
  PermanentState?: Maybe<Scalars['String']>;
  PermanentCountry?: Maybe<Scalars['String']>;
  IsSameAddress?: Maybe<Scalars['String']>;
  PresentAddress?: Maybe<Scalars['String']>;
  PresentPostcode?: Maybe<Scalars['String']>;
  PresentCity?: Maybe<Scalars['String']>;
  PresentState?: Maybe<Scalars['String']>;
  PresentCountry?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeJobInfoEntity = {
  __typename?: 'MD_EmployeeJobInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  HeadofDepartment?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  SupervisorEmployeeNo?: Maybe<Scalars['String']>;
  SupervisorName?: Maybe<Scalars['String']>;
  SupervisorCompany?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Scalars['String']>;
  JoinDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['String']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarState?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeSalaryEntity = {
  __typename?: 'MD_EmployeeSalaryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeStatutoryInfoEntity = {
  __typename?: 'MD_EmployeeStatutoryInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  PayMethod?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFEEVERate?: Maybe<Scalars['String']>;
  EPFERVERate?: Maybe<Scalars['String']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  LHDNNo?: Maybe<Scalars['String']>;
  TaxMaritalStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['Boolean']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatPercentage?: Maybe<Scalars['String']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_FixedAllwDedEntity = {
  __typename?: 'MD_FixedAllwDedEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayCategory?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobDesignationEntity = {
  __typename?: 'MD_JobDesignationEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobDesignationName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobGradeEntity = {
  __typename?: 'MD_JobGradeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayItemEntity = {
  __typename?: 'MD_PayItemEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  EAPartF?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  AddRemuneration?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  Bonus?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22A?: Maybe<Scalars['String']>;
  CP22B?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCutOffEntity = {
  __typename?: 'MD_PayrollCutOffEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCycleEntity = {
  __typename?: 'MD_PayrollCycleEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollHistoryEntity = {
  __typename?: 'MD_PayrollHistoryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_RecruitmentSourceEntity = {
  __typename?: 'MD_RecruitmentSourceEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type MtdCalcOutput = {
  __typename?: 'MTDCalcOutput';
  MTD?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  CurrentMonthMTD?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  CalcInput?: Maybe<MtdCalculationInput>;
  TotalOneTimeChargableTax?: Maybe<Scalars['Float']>;
  TotalOneTimeChargableEPF?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  PrevTax?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  EPF?: Maybe<Scalars['Float']>;
  MTD_A?: Maybe<Scalars['Float']>;
  MTD_B?: Maybe<Scalars['Float']>;
  MTD_C?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  NonResidentRate?: Maybe<Scalars['Float']>;
};

export type MtdCalculationInput = {
  __typename?: 'MTDCalculationInput';
  Y?: Maybe<Scalars['Float']>;
  K?: Maybe<Scalars['Float']>;
  Y1?: Maybe<Scalars['Float']>;
  K1?: Maybe<Scalars['Float']>;
  Yt?: Maybe<Scalars['Float']>;
  Kt?: Maybe<Scalars['Float']>;
  Y2?: Maybe<Scalars['Float']>;
  K2?: Maybe<Scalars['Float']>;
  KA?: Maybe<Scalars['Float']>;
  YtA?: Maybe<Scalars['Float']>;
  KtA?: Maybe<Scalars['Float']>;
  K2A?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  B2?: Maybe<Scalars['Float']>;
  T2?: Maybe<Scalars['Float']>;
  P2?: Maybe<Scalars['Float']>;
  M2?: Maybe<Scalars['Float']>;
  R2?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Float']>;
  n1?: Maybe<Scalars['Float']>;
  D?: Maybe<Scalars['Float']>;
  Du?: Maybe<Scalars['Float']>;
  S?: Maybe<Scalars['Float']>;
  Su?: Maybe<Scalars['Float']>;
  Qc?: Maybe<Scalars['Float']>;
  ELP?: Maybe<Scalars['Float']>;
  LP1?: Maybe<Scalars['Float']>;
  P?: Maybe<Scalars['Float']>;
  P1?: Maybe<Scalars['Float']>;
  K22?: Maybe<Scalars['Float']>;
  Y22?: Maybe<Scalars['Float']>;
  M?: Maybe<Scalars['Float']>;
  R?: Maybe<Scalars['Float']>;
  B?: Maybe<Scalars['Float']>;
  T?: Maybe<Scalars['Float']>;
  Z?: Maybe<Scalars['Float']>;
  X?: Maybe<Scalars['Float']>;
  TaxResult?: Maybe<Scalars['Float']>;
};

export type MtdOutput = {
  __typename?: 'MTDOutput';
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  IsBasicSalary?: Maybe<Scalars['Boolean']>;
  NetMTDBeforeZakat?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  RoundedNetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxPartA1MTDOutput?: Maybe<MtdCalcOutput>;
  TaxPartA2MTDOutput?: Maybe<MtdCalcOutput>;
};

export type MaintenanceBudgetInput = {
  training_budget_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  budget_items?: Maybe<Array<MaintenanceBudgetItemsInput>>;
};

export type MaintenanceBudgetItemsInput = {
  training_budget_item_id?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type MaintenanceCourseInput = {
  target_group_courses_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  compulsary_course_items?: Maybe<Array<Scalars['JSON']>>;
};

export type MaintenanceTargetHoursInput = {
  training_target_hours_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  target_hours_items?: Maybe<Array<MaintenanceTargetHoursItemInput>>;
};

export type MaintenanceTargetHoursItemInput = {
  training_target_hours_item_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Separated = 'Separated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum MaxApplyLeave {
  ApplyDate = 'Apply_Date',
  LeaveDate = 'Leave_Date'
}

export type MaxOtPayItemInput = {
  PayItemID?: Maybe<Scalars['String']>;
};

export type MaxOtPayItemObject = {
  __typename?: 'MaxOTPayItemObject';
  PayItemID?: Maybe<Scalars['String']>;
};

export type MedicalRecordEntity = InterfaceBase & {
  __typename?: 'MedicalRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
};

export type MedicalRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type MileageLogEntity = InterfaceBaseV2 & {
  __typename?: 'MileageLogEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  MileageLogID: Scalars['String'];
  VehicleTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  TravelDate: Scalars['DateTime'];
  LocationFrom: Scalars['String'];
  LocationTo: Scalars['String'];
  Distance: Scalars['Float'];
  DocumentIDs?: Maybe<Array<Scalars['String']>>;
  ClaimDetailID?: Maybe<Scalars['String']>;
};

export type MileageLogInput = {
  MileageLogID?: Maybe<Scalars['String']>;
  VehicleTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
  TravelDate: Scalars['DateTime'];
  LocationFrom: Scalars['String'];
  LocationTo: Scalars['String'];
  Distance: Scalars['Float'];
  DocumentIDs?: Maybe<Array<Scalars['String']>>;
  Document?: Maybe<Array<DocumentsInput>>;
  Status: Status;
  UserLogs: Array<UserLogInput>;
  EmployeeID: Scalars['String'];
  ClaimDetailID?: Maybe<Scalars['String']>;
};

export enum MileageRateCalculationMethod {
  Stepped = 'Stepped',
  Staggered = 'Staggered'
}

export type MobileCheckInEntity = InterfaceBase & {
  __typename?: 'MobileCheckInEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileCheckInID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius: Scalars['Float'];
  IsByLocation?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type MobileCheckInInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MobileCheckInID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
};

export type MobileVersionEntity = {
  __typename?: 'MobileVersionEntity';
  MobileVersionID: Scalars['String'];
  Version?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Android?: Maybe<Scalars['Boolean']>;
  iOS?: Maybe<Scalars['Boolean']>;
};

export type ModerationRatingEntity = InterfaceBase & {
  __typename?: 'ModerationRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModerationRatingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ModerationRatingInput = {
  ModerationRatingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Module {
  Tms = 'TMS',
  TmsJobGradePolicy = 'TMS__JOB_GRADE_POLICY',
  TmsShiftGroupAssignment = 'TMS__SHIFT_GROUP_ASSIGNMENT',
  TmsAttendanceExclusionList = 'TMS__ATTENDANCE_EXCLUSION_LIST',
  Personnel = 'PERSONNEL',
  Payroll = 'PAYROLL',
  TmsOvertimePolicyMatrix = 'TMS_OVERTIME_POLICY_MATRIX'
}

export enum MonthPeriod {
  CurrentMonth = 'Current_Month',
  NextMonth = 'Next_Month'
}

export type MonthlyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type MonthlyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'MonthlyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicAllowancePolicyCondition?: Maybe<Array<PeriodicAllowancePolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export enum MonthlyCutOff {
  EarnOnStartingOfTheMonth = 'Earn_On_Starting_Of_The_Month',
  EarnOnEndOfTheMonth = 'Earn_On_End_Of_The_Month',
  ServiceDate = 'Service_Date'
}

export type MonthlyError = {
  __typename?: 'MonthlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type MonthlyTemplateEntity = InterfaceBase & {
  __typename?: 'MonthlyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTemplateInput = {
  MonthlyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTransactionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export enum MovementType {
  ProbationExtension = 'Probation_Extension',
  NewHire = 'NewHire',
  Confirmation = 'Confirmation',
  Promotion = 'Promotion',
  Transfer = 'Transfer',
  Demotion = 'Demotion',
  ExitCompany = 'Exit_Company',
  Secondment = 'Secondment',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer'
}

export type MultiCurrencyDetailsEntity = InterfaceBase & {
  __typename?: 'MultiCurrencyDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type MultiCurrencyDetailsInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MultiCurrencySetupEntity = InterfaceBase & {
  __typename?: 'MultiCurrencySetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  IsSystemDefault?: Maybe<Scalars['Boolean']>;
  SoftDelete?: Maybe<Status>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<MultiCurrencyDetailsEntity>>;
};

export type MultiCurrencySetupInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  InUse?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  DocumentInsert: DocumentsEntity;
  DocumentUpdate: DocumentsEntity;
  DocumentUpdateParentID: Scalars['Boolean'];
  DocumentDelete: Scalars['Boolean'];
  EmployeeProfileInsertUpdate: Scalars['Boolean'];
  createDutyRoster: Scalars['Boolean'];
  DutyRosterBatch: Scalars['Boolean'];
  ShiftPatternAssignmentToEmployee: Scalars['Boolean'];
  createBatchEntryByShiftPattern: Scalars['Boolean'];
  DeleteDutyRosterByEmployee: Scalars['Boolean'];
  DeleteDutyRosterForAll: Scalars['Boolean'];
  DeleteDutyRosterBySelectedEmployee: Scalars['Boolean'];
  NewsReadInsert: Scalars['Boolean'];
  NotificationUpdate: Scalars['Boolean'];
  deleteAttendanceRecord: Scalars['Boolean'];
  createAttendanceRecord: AttendanceRecordEntity;
  submitPairedAttandanceRecord: Scalars['Boolean'];
  updateAttendanceRecord: Scalars['Float'];
  DeleteTimeAttendanceRecord: Scalars['Boolean'];
  AttendanceExcelUpload?: Maybe<Array<ProcessAttendanceEntity>>;
  AttendanceExcelDelete: Scalars['Boolean'];
  AttendanceExcelInsertActual: Scalars['Boolean'];
  AttendanceRecordBatchDelete: Scalars['Boolean'];
  AttendanceRecordBatch: Scalars['Boolean'];
  AutomatedClockPairing: Scalars['Boolean'];
  AppraisalCategoryInsert: AppraisalCategoryEntity;
  AppraisalCategoryUpdate: Scalars['Boolean'];
  AppraisalCategoryDelete: Scalars['Boolean'];
  AppraisalCategoryItemInsert: AppraisalCategoryItemEntity;
  AppraisalCategoryItemUpdate: Scalars['Boolean'];
  AppraisalCategoryItemDelete: Scalars['Boolean'];
  AppraisalPolicyInsert: AppraisalPolicyEntity;
  AppraisalPolicyDelete: Scalars['Boolean'];
  AppraisalTemplateByJobPositionSubmit: Scalars['Boolean'];
  AppraisalScheduleSubmit: AppraisalScheduleHeaderEntity;
  AppraisalCompanySettingSubmit: AppraisalCompanySettingEntity;
  FinalRatingSubmit: FinalRatingHeaderEntity;
  FinalRatingDelete: Scalars['Boolean'];
  ScaleRatingSubmit: ScaleRatingHeaderEntity;
  ScaleRatingDelete: Scalars['Boolean'];
  BonusSimulationSubmit: Scalars['Boolean'];
  IncrementSimulationSubmit: Scalars['Boolean'];
  SubmitAppraisalTemplete: Scalars['Boolean'];
  SubmitAppraisal?: Maybe<Scalars['Boolean']>;
  ModerationSubmit: Scalars['Boolean'];
  SubmitStaffKPIPlanning?: Maybe<Scalars['Boolean']>;
  StaffKPIPlannigDelete: Scalars['Boolean'];
  StaffPerformanceAnalysisSummarySubmit: Scalars['Boolean'];
  ApprovalPolicyInsert: Scalars['Boolean'];
  ApprovalPolicyUpdate: Scalars['Boolean'];
  ApprovalPolicySubmit: Scalars['Boolean'];
  ApprovalPolicyDelete: Scalars['Boolean'];
  ApprovalPolicyAssignmentUpdate: Scalars['Boolean'];
  NewApprovalPolicyAssignmentDelete: Scalars['Boolean'];
  AttendanceAnalysisTemplateInsert: Scalars['Boolean'];
  SubmitAttendanceCorrectionRequest: Scalars['Boolean'];
  CancelAttendanceCorrectionRequest: Scalars['Boolean'];
  AttendanceCorrectionRequestApproval: Scalars['Boolean'];
  PostAttendanceRecord: Scalars['Boolean'];
  ReverseAttendanceRecord?: Maybe<Scalars['Boolean']>;
  AttendancePeriodEdit?: Maybe<Scalars['Boolean']>;
  AttendanceProccessBulkDelete: Scalars['Boolean'];
  OvertimeInsert: OvertimeEntity;
  OvertimeDraft: Scalars['Boolean'];
  OvertimeBatch: Scalars['Boolean'];
  OvertimeUpdate: Scalars['Boolean'];
  OvertimeDelete: Scalars['Boolean'];
  OvertimeExcelUpload?: Maybe<Array<Process_Overtime_ImportEntity>>;
  OvertimeExcelDelete: Scalars['Boolean'];
  OvertimeExcelInsertActual: Scalars['Boolean'];
  BatchOvertimeEntryInsert: Scalars['Boolean'];
  BatchOvertimeCancel: Scalars['Boolean'];
  BatchOvertimeWithdraw: Scalars['Boolean'];
  ToDoBatchOvertimeDraft: Scalars['Boolean'];
  ToDoBatchOvertimeSubmit: Scalars['Boolean'];
  ToDoBatchOvertimeWithdrawalApproval: Scalars['Boolean'];
  SubmitManagementClaimRecords?: Maybe<Scalars['Boolean']>;
  ApproveClaimRecords?: Maybe<Scalars['Boolean']>;
  CancelClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedClaimDetail?: Maybe<Scalars['Boolean']>;
  PostClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseClaimRecords?: Maybe<Scalars['Boolean']>;
  SubmitMyClaim: ClaimHeaderEntity;
  DeleteClaimRecords: Scalars['Boolean'];
  SubmitClaimForApproval: Scalars['Boolean'];
  DoUpdateClaim: Scalars['Boolean'];
  MyClaimInsert: ClaimInsertOutput;
  ClaimValidityUpdate: Scalars['Boolean'];
  ClaimValidityInsert: Scalars['Boolean'];
  ClaimTypeInsert: ClaimTypeEntity;
  ClaimTypeUpdate: Scalars['Boolean'];
  ClaimTypeDelete: Scalars['Boolean'];
  ClaimTypeTemplateInsert: Scalars['Boolean'];
  ClaimTypeTemplateUpdate: Scalars['Boolean'];
  ClaimTypeTemplateDelete: Scalars['Boolean'];
  ClaimTypeTemplateUpdateStatus: Scalars['Boolean'];
  ClaimTypeConditionUpdate: Scalars['Boolean'];
  ClaimTypeConditionDelete: Scalars['Boolean'];
  ClaimJobGradePolicyInsert: Scalars['Boolean'];
  ClaimJobGradePolicyUpdate: Scalars['Boolean'];
  ClaimPoolDelete: Scalars['Boolean'];
  ClaimPoolSubmit: Scalars['Boolean'];
  InsertClaimCutOffPeriod: Scalars['Boolean'];
  BankInsert: BankInfoEntity;
  BankUpdate: Scalars['Boolean'];
  BankDelete: Scalars['Boolean'];
  CompanyOffDaysDelete: Scalars['Boolean'];
  updateCompanyOffDaysStatus: Scalars['Boolean'];
  updateRestOffDayStatus: Scalars['Boolean'];
  CompanyOffDaysBulkInsert: Scalars['Boolean'];
  CalendarPolicyStatusUpdate: Scalars['Boolean'];
  CalendarPolicyDelete: Scalars['Boolean'];
  OffRestDayDelete: Scalars['Boolean'];
  OffRestDayBulkInsert: Scalars['Boolean'];
  CalendarOffDayUpdate: Scalars['Boolean'];
  CalendarOffDayInsert: CalendarOffDayEntity;
  CalanderPolicySubmit: Scalars['Boolean'];
  CompanyHolidaysBulkInsert: Scalars['Boolean'];
  CompanyDocumentInsert: DocumentsEntity;
  CompanyDocumentDelete: Scalars['Boolean'];
  CompanyFolderCreate: Scalars['Boolean'];
  CompanyFolderDelete: Scalars['Boolean'];
  CompanyInsert: Scalars['Boolean'];
  CompanyUpdate: Scalars['Boolean'];
  CompanyLeaveSettingUpdate: Scalars['Boolean'];
  CompanyTaxReliefUpdate: Scalars['Boolean'];
  CompanyClaimSettingUpdate: Scalars['Boolean'];
  CompanyProjectExpenseClaimSettingUpdate: Scalars['Boolean'];
  CompanyOnlyUpdate: Scalars['Boolean'];
  JobDesignationInsert: Scalars['Boolean'];
  JobPositionInsert: Scalars['Boolean'];
  JobDesignationUpdate: Scalars['Boolean'];
  JobDesignationSequenceUpdate: Scalars['Boolean'];
  JobPositionUpdate: Scalars['Boolean'];
  JobDesignationDelete: Scalars['Boolean'];
  JobPositionDelete: Scalars['Boolean'];
  RecruitmentSourceBulkInsert: Scalars['Boolean'];
  RecruitmentSourceInsert: RecruitmentSourceEntity;
  RecruitmentSourceUpdate: Scalars['Boolean'];
  RecruitmentSourceDelete: Scalars['Boolean'];
  CompanyAltAddressInsert: Scalars['Boolean'];
  CompanyAltAddressUpdate: Scalars['Boolean'];
  CompanyAltAddressDelete: Scalars['Boolean'];
  CompanyStatutoryAccountUpdate: Scalars['Boolean'];
  CompanyStatutoryInsert: CompanyStatutoryEntity;
  CompanyStatutoryUpdate: Scalars['Boolean'];
  CompanyStructureInsert: Scalars['Boolean'];
  CompanyOrgUnitInsert: Scalars['Boolean'];
  CompanyOrgUnitInsertV2: Scalars['Boolean'];
  CompanyOrgUnitUpdate: Scalars['Boolean'];
  AssignJobPosition: Scalars['Boolean'];
  CreateCompanyAccessPerm: Scalars['Boolean'];
  DepartmentInsert: DepartmentEntity;
  DepartmentUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  DivisionInsert: DepartmentEntity;
  DivisionUpdate: Scalars['Boolean'];
  DivisionDelete: Scalars['Boolean'];
  CreateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeInsert: Scalars['Boolean'];
  UpdateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeUpdate: Scalars['Boolean'];
  DeleteInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeDelete: Scalars['Boolean'];
  InterbankInsert: InterbankGiroEntity;
  InterbankBulkInsert: Scalars['Boolean'];
  InterbankDelete: Scalars['Boolean'];
  InterbankUpdate: Scalars['Boolean'];
  InterbankSingleUpdate: Scalars['Boolean'];
  JobAccountInsert: Scalars['Boolean'];
  JobAccountUpdate: Scalars['Boolean'];
  JobAccountDelete: Scalars['Boolean'];
  JobGradeBulkInsert: Scalars['Boolean'];
  JobGradeInsert: JobGradeEntity;
  JobGradeUpdate: Scalars['Boolean'];
  JobGradeDelete: Scalars['Boolean'];
  LeaveRoundingBulkInsert: Scalars['Boolean'];
  LeaveRoundingInsert: Scalars['Boolean'];
  UpdateLeaveRounding: Scalars['Boolean'];
  LeaveRoundingUpdate: Scalars['Boolean'];
  LeaveRoundingDelete: Scalars['Boolean'];
  LeaveCompulsoryPolicyBulkInsert: Scalars['Boolean'];
  LeaveCompulsoryPolicyUpdate: Scalars['Boolean'];
  LHDNReportInsert: Scalars['Boolean'];
  LHDNReportUpdate: Scalars['Boolean'];
  LHDNReportCopyInsert: Scalars['Boolean'];
  NotificationPolicyInsert: NotificationPolicyEntity;
  NotificationPolicyUpdate: Scalars['Boolean'];
  NotificationPolicyDelete: Scalars['Boolean'];
  NotificationPolicyConditionInsert: NotificationPolicyConditionEntity;
  NotificationPolicyConditionUpdate: Scalars['Boolean'];
  PayrollCycleInsert: PayrollCycleEntity;
  PayrollCycleUpdate: Scalars['Boolean'];
  PayrollCycleDelete: Scalars['Boolean'];
  ReportingGroupSubmit: Scalars['Boolean'];
  ReportingGroupDelete: Scalars['Boolean'];
  ReportingCompanyGroupDelete: Scalars['Boolean'];
  ReportingStructureGroupSubmit: Scalars['Boolean'];
  CreateSkillSet: Scalars['Boolean'];
  UpdateSkillSet: Scalars['Boolean'];
  DeleteSkillSet: Scalars['Boolean'];
  UserDefineBulkInsert: Scalars['Boolean'];
  CoolbinetTransaction: Scalars['JSON'];
  PostOffboardingLeaveEncashment: Scalars['Boolean'];
  RevertOffboardingLeaveEncashment: Scalars['Boolean'];
  PostFromPlatformLeaveTypes: Scalars['String'];
  SubmitMyLeaveApplication: Scalars['Boolean'];
  PostLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplicationV2: LeaveHeaderEntity;
  DeleteLeaveApplication: Scalars['Boolean'];
  DeletePendingLeaveApplication: Scalars['Boolean'];
  DeleteApprovedLeaveApplication: Scalars['Boolean'];
  PostLeaveOpeningYear: Scalars['Boolean'];
  LeaveTypeInsert: LeaveTypeEntity;
  LeaveTypeUpdate: Scalars['Boolean'];
  LeaveTypeDelete: Scalars['Boolean'];
  LeaveReasonInsert: LeaveReasonEntity;
  LeaveReasonUpdate: Scalars['Boolean'];
  LeaveReasonDelete: Scalars['Boolean'];
  LeaveDiseaseInsert: LeaveDiseaseEntity;
  LeaveDiseaseUpdate: Scalars['Boolean'];
  LeaveDiseaseDelete: Scalars['Boolean'];
  LeaveJobGradePolicyInsert: Scalars['Boolean'];
  LeaveJobGradePolicyUpdate: Scalars['Boolean'];
  LeaveTypeConditionUpdate: Scalars['Boolean'];
  LeaveTypeConditionDelete: Scalars['Boolean'];
  LeavePolicyUpdate: Scalars['Boolean'];
  LeavePolicyDelete: Scalars['Boolean'];
  LeavePolicyInsert: Scalars['Boolean'];
  LeaveTypeTemplateInsert: Scalars['Boolean'];
  LeaveTypeTemplateUpdate: Scalars['Boolean'];
  LeaveTypeTemplateDelete: Scalars['Boolean'];
  UserDashboardControlSubmit: Scalars['Boolean'];
  DataChangesInsert: DataChangesEntity;
  SubmitDataChanges: Scalars['Boolean'];
  DeleteDataChanges: Scalars['Boolean'];
  CancelDataChanges: Scalars['Boolean'];
  ApproveDataChanges: Scalars['Boolean'];
  RejectDataChanges: Scalars['Boolean'];
  DataChangesApproval: Scalars['Boolean'];
  DataChangesReject: Scalars['Boolean'];
  ShopeeDataImportVerify: Scalars['Boolean'];
  ShopeeDataImportVerifyRedirect: Scalars['Boolean'];
  DataImportVerify: Scalars['Boolean'];
  DataImportVerifyRedirect: Scalars['Boolean'];
  RemoveTempData: Scalars['Boolean'];
  ProceedForImport: Scalars['Boolean'];
  ActivateEmployees: Scalars['Boolean'];
  ActivateEmployeesInBulk: Scalars['Boolean'];
  DelegationInsert: Scalars['Boolean'];
  DelegationUpdate: Scalars['Boolean'];
  DelegationDelete: Scalars['Boolean'];
  NewsDelete: Scalars['Boolean'];
  NewsInsert: Scalars['Boolean'];
  NewsUpdate: Scalars['Boolean'];
  NewsPublish: Scalars['Boolean'];
  NewsDuplicate: Scalars['Boolean'];
  UpdatePasswordInBulk: Scalars['Boolean'];
  NewDocumentNumberingInsert: Scalars['Boolean'];
  CompanyDocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingDelete: Scalars['Boolean'];
  DocumentNumberingUpdateStatus: Scalars['Boolean'];
  DocumentNumberAssignment: Scalars['Boolean'];
  BatchEntryByShiftInsert: Scalars['Boolean'];
  updateBatchEntryByShiftPattern: Scalars['Boolean'];
  CreateEmployeeAppraisal: Scalars['Boolean'];
  UpdateEmployeeAppraisal: Scalars['Boolean'];
  DeleteEmployeeAppraisal: Scalars['Boolean'];
  CareerLogInsert: Scalars['Boolean'];
  CareerLogUpdate: Scalars['Boolean'];
  CreateEmployeeDiary: Scalars['Boolean'];
  UpdateEmployeeDiary: Scalars['Boolean'];
  DeleteEmployeeDiary: Scalars['Boolean'];
  EmployeeInfoInsert: EmployeeEntity;
  EmployeeProfilePicUpdate: Scalars['Boolean'];
  EmployeeInfoUpdate: Scalars['Boolean'];
  EmployeeCareerLogUpdate: Scalars['Boolean'];
  EmployeeStatutoryInsert: EmployeeStatutoryEntity;
  EmployeeStatutoryUpdate: Scalars['Boolean'];
  EmployeePrevContributionInsert: EmployeePrevContributionEntity;
  EmployeePrevContributionUpdate: Scalars['Boolean'];
  EmployeeDependentInsert: EmployeeDependentsEntity;
  EmployeeDependentUpdate: Scalars['Boolean'];
  EmployeeDependentInsertByEmployee: EmployeeDependentsEntity;
  EmployeeDependentUpdateByEmployee: Scalars['Boolean'];
  EmployeeTaxReliefBulkInsert: Scalars['Boolean'];
  EmployeeTaxReliefBulkUpdate: Scalars['Boolean'];
  EmployeeAssetInsert: EmployeeAssetEntity;
  EmployeeAssetUpdate: Scalars['Boolean'];
  EmployeeAssetDelete: Scalars['Boolean'];
  EmployeeBenefitInKindInsert: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindUpdate: Scalars['Boolean'];
  EmployeeBenefitInKindDelete: Scalars['Boolean'];
  EmployeeDependentDelete: Scalars['Boolean'];
  EmployeeSalaryInsert: Scalars['Boolean'];
  EmployeeSalaryUpdate: Scalars['Boolean'];
  EmployeeSalaryDelete: Scalars['Boolean'];
  EmployeeProfileUpload: EmployeeEntity;
  UpdateEmployeeLastLoginCompany: Scalars['Boolean'];
  EmployeeCP22Update: Scalars['Boolean'];
  CreateEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateEmployeeInsuranceScheme: Scalars['Boolean'];
  DeleteEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateNotificationReadStatus: Scalars['Boolean'];
  UpdateAllNotificationReadStatus: Scalars['Boolean'];
  UpdateBulkNotificationReadStatus: Scalars['Boolean'];
  NotificationReadStatusDelete: Scalars['Boolean'];
  CreateEmployeeTraining: Scalars['Boolean'];
  UpdateEmployeeTraining: Scalars['Boolean'];
  DeleteEmployeeTraining: Scalars['Boolean'];
  EmployeeUserDefineFieldsInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsUpdate: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailUpdate: Scalars['Boolean'];
  ZakatContributionInsert: Scalars['Boolean'];
  ZakatContributionUpdate: Scalars['Boolean'];
  ZakatContributionDelete: Scalars['Boolean'];
  CreateUpdateAppSecurity: Scalars['Boolean'];
  CreateUpdateAppSecurityAssignment: Scalars['Boolean'];
  DownloadDocuments: Scalars['JSON'];
  UploadEaDocuments: Scalars['Boolean'];
  UploadPayslipDocuments: Scalars['Boolean'];
  RemoveEonDocumentsByBatch: Scalars['Boolean'];
  HelpdeskSubjectSubmit: Scalars['Boolean'];
  HelpdeskSubjectAction: Scalars['Boolean'];
  SubmitHelpdeskTicket: Scalars['Boolean'];
  ReassignAssignee: Scalars['Boolean'];
  UpdateOpenDT?: Maybe<Scalars['Boolean']>;
  WithdrawOpenTicket: Scalars['Boolean'];
  AttendanceClocking: Scalars['JSON'];
  GetUserInfo: Scalars['JSON'];
  GetStaffAttendance: Scalars['JSON'];
  GetEmpWorkTime: Scalars['JSON'];
  BlockLeaveInsert: Scalars['Boolean'];
  BlockLeaveUpdate: Scalars['Boolean'];
  BlockLeaveStatusUpdate: Scalars['Boolean'];
  ReprocessLeaveEntitlement: Scalars['Boolean'];
  ReprocessLeaveEntitlementRedirect: Scalars['Boolean'];
  ReprocessLeaveEntitlementBySubscription: Scalars['Boolean'];
  PostCarryForwardEncashment: Scalars['Boolean'];
  LeaveRecordsApproval: Scalars['Boolean'];
  LeaveRecordsInsert: LeaveHeaderEntity;
  LeaveRecordsUpdate: Scalars['Boolean'];
  LeaveAdjustmentInsert: Scalars['Boolean'];
  LeaveStatusUpdate: Scalars['Boolean'];
  LeaveAdjustmentUpdate: Scalars['Boolean'];
  LeaveAdjustmentDelete: Scalars['Boolean'];
  PostLeaveRecords: Scalars['Boolean'];
  RevertLeaveRecords: Scalars['Boolean'];
  SaveCompulsoryLeave: Scalars['Boolean'];
  SaveUpdateCompulsoryLeave: Scalars['Boolean'];
  DeleteCompulsoryLeave: Scalars['Boolean'];
  InsertLeaveCutOffPeriods: Scalars['Boolean'];
  SubmitYearEndCarryForward: Scalars['Boolean'];
  ReversePostedYECFRecords?: Maybe<Scalars['Boolean']>;
  ReversePostedLCFE: Scalars['Boolean'];
  LeaveTakenUpload?: Maybe<Array<Process_LeaveDetails_ImportEntity>>;
  LeaveTakenDelete: Scalars['Boolean'];
  LeaveTakenInsertActual: Scalars['Boolean'];
  LeaveTakenBulkInsert: Scalars['Boolean'];
  PostMaxLeaveBalanceForfeiture: Scalars['Boolean'];
  ReverseMaxLeaveBalanceForfeiture: Scalars['Boolean'];
  LeaveOutOfStateInsert: LeaveOutOfStateEntity;
  LeaveOufOfStateUpdate: Scalars['Boolean'];
  LeaveOutOfStateDelete: Scalars['Boolean'];
  AccountMappingInsert: Scalars['Boolean'];
  AccountMappingUpdate: Scalars['Boolean'];
  AccountMappingDelete: Scalars['Boolean'];
  AccountMappingAssignmentInsert: Scalars['Boolean'];
  AccountMappingAssignmentUpdate: Scalars['Boolean'];
  ClaimAccountMappingInsert: Scalars['Boolean'];
  ClaimAccountMappingUpdate: Scalars['Boolean'];
  ClaimAccountMappingDelete: Scalars['Boolean'];
  ClaimAccountAssignmentInsert: Scalars['Boolean'];
  ClaimAccountAssignmentUpdate: Scalars['Boolean'];
  CostMappingUpdate: Scalars['Boolean'];
  DeleteProcessedGL: Scalars['Boolean'];
  FlagGLData: Scalars['JSON'];
  UnflagGLData: Scalars['JSON'];
  CreateMobileVersion: Scalars['Boolean'];
  MobileVersionDeleting: Scalars['Boolean'];
  MultiCurrencyUpdateStatus: Scalars['Boolean'];
  MultiCurrencyUpdateDelete: Scalars['Boolean'];
  MultiCurrencyInsert: Scalars['Boolean'];
  MultiCurrencyUpdate: Scalars['Boolean'];
  MultiCurrencySetupExcelUpload?: Maybe<Array<Process_Multicurrencysetup_ImportEntity>>;
  MultiCurrencyExcelDelete: Scalars['Boolean'];
  MultiCurrencyInsertActual: Scalars['Boolean'];
  SubmitOnBehalfPolicyByType: Scalars['Boolean'];
  PopulateAttendanceRecord: Scalars['Boolean'];
  OvertimeRoundingInsert: OvertimeRoundingSettingEntity;
  OvertimeRoundingSettingUpdate: Scalars['Boolean'];
  OvertimeRoundingSettingDelete: Scalars['Boolean'];
  OvertimeTemplateInsert: OvertimeTemplateEntity;
  OvertimeTemplateUpdate: Scalars['Boolean'];
  OvertimeTemplateDelete: Scalars['Boolean'];
  SubmitAdvancePolicy: Scalars['Boolean'];
  DeleteAdvancePolicy: Scalars['Boolean'];
  AssignAdvancePolicy: Scalars['Boolean'];
  GenerateAdvanceProcessing: Array<AdvanceProcessingEntity>;
  PostUnpostAdvanceProcessing: Scalars['Boolean'];
  SubmitPayGroup: Scalars['Boolean'];
  PayGroupDelete: Scalars['Boolean'];
  AssignCompanyPayGroup: Scalars['Boolean'];
  AssignPayrollCycleToCompany: Scalars['Boolean'];
  UpdateStatutoryAdjustment: Scalars['Boolean'];
  DeleteStatutoryAdjustment: Scalars['Boolean'];
  DeletePayrollHistory: Scalars['Boolean'];
  DeletePayrollProcess: Scalars['Boolean'];
  UpdatePayrollCalendar: Scalars['Boolean'];
  GenerateDefaultOrderforAllPayPeriods: Scalars['Boolean'];
  UpdatePayrollProcessStatus: Scalars['Boolean'];
  UpdateMonthlyTransactionByFixedAllowance: Scalars['Boolean'];
  InsertPayPeriodListing: Scalars['Boolean'];
  PayItemInsert: Scalars['Boolean'];
  PayItemUpdate: Scalars['Boolean'];
  SalaryBatch: Scalars['Boolean'];
  PayItemDelete: Scalars['Boolean'];
  RecurringPayInsert: Scalars['Boolean'];
  RecurringPayUpdate: Scalars['Boolean'];
  RecurringPayDelete: Scalars['Boolean'];
  CP159Update: Scalars['Boolean'];
  OneTimePayDelete: Scalars['Boolean'];
  OneTimePayInsert: Scalars['Boolean'];
  OneTimePayUpdate: Scalars['Boolean'];
  PublishPaySlip: Scalars['Boolean'];
  PublishEA: Scalars['Boolean'];
  UnPublishEA: Scalars['Boolean'];
  DeleteMonthlyTrx: Scalars['Boolean'];
  FreezePayrollInsert: Scalars['Boolean'];
  EmployeeFreezePayrollDelete: Scalars['Boolean'];
  EmployeeSalaryExcelUpload?: Maybe<Array<ProcessSalaryEntity>>;
  SalaryExcelDelete: Scalars['Boolean'];
  SalaryExcelInsertActual: Scalars['Boolean'];
  MonthlyTransactionExcelUpload?: Maybe<Array<ProcessMonthlyTransactionEntity>>;
  MonthlyTransactionExcelDelete: Scalars['Boolean'];
  MonthlyTransactionExcelInsertActual: Scalars['Boolean'];
  FixedAllowanceExcelUpload?: Maybe<Array<Scalars['JSON']>>;
  FixedAllowanceExcelDelete: Scalars['Boolean'];
  FixedAllowanceExcelInsertActual: Scalars['Boolean'];
  SubmitEmployeeKpi: Scalars['Boolean'];
  SaveEmployeeKpiInRepo: Scalars['Boolean'];
  EmployeeKpiRepoDelete: Scalars['Boolean'];
  KpiExerciseChangeSuperior: Scalars['Boolean'];
  KpiExerciseChangeStatus: Scalars['Boolean'];
  KpiExerciseSubmit: Scalars['Boolean'];
  KpiExerciseDelete: Scalars['Boolean'];
  KpiExerciseInitiateDraft: Scalars['Boolean'];
  KpiExerciseCopy: Scalars['Boolean'];
  KpiExerciseSetupSubmit: Scalars['Boolean'];
  KpiExerciseSetupCopy: Scalars['Boolean'];
  KpiExerciseSetupDelete: Scalars['Boolean'];
  KpiExerciseSetupGenerate: Scalars['Boolean'];
  OrganizationalGoalSubmit: Scalars['Boolean'];
  OrganizationalGoalDelete: Scalars['Boolean'];
  OrgKpiPlanningSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailsDelete: Scalars['Boolean'];
  PerformanceGroupMatrixSubmit: Scalars['Boolean'];
  PerformanceGroupMatrixDelete: Scalars['Boolean'];
  EmployeeReviewUpdateSetup: Scalars['Boolean'];
  CopyEmployeeReviewExercise: Scalars['Boolean'];
  EmployeeReviewFormStatusUpdate: Scalars['Boolean'];
  SubmitEmployeeReviewForm: Scalars['Boolean'];
  AddConversationLog: Scalars['Boolean'];
  PeerRegistrationSubmit: Scalars['Boolean'];
  ReviewExerciseSetupSubmit: Scalars['Boolean'];
  ReviewExerciseSetupCopy: Scalars['Boolean'];
  ReviewExerciseSetupDelete: Scalars['Boolean'];
  ReviewExerciseSetupGenerate: Scalars['Boolean'];
  PeriodicAllowanceTypeInput: PeriodicAllowanceTypeEntity;
  PeriodicAllowanceTypeUpdate: Scalars['Boolean'];
  PeriodicAllowanceTypeDelete: Scalars['Boolean'];
  PersonnelCertificateInsert: Scalars['Boolean'];
  PersonnelCertificateUpdate: Scalars['Boolean'];
  PersonnelCertificateDelete: Scalars['Boolean'];
  ContractInfoInsert: Scalars['Boolean'];
  ContractInfoUpdate: Scalars['Boolean'];
  ContractInfoEnd: Scalars['Boolean'];
  ContractInfoRenewal: Scalars['Boolean'];
  ReportDocDelete: Scalars['Boolean'];
  ContractInfoDelete: Scalars['Boolean'];
  PersonnelEducationInsert: Scalars['Boolean'];
  PersonnelEducationUpdate: Scalars['Boolean'];
  PersonnelEducationDelete: Scalars['Boolean'];
  PersonnelHealthInfoInsert: Scalars['Boolean'];
  PersonnelHealthInfoUpdate: Scalars['Boolean'];
  MedicalRecordDelete: Scalars['Boolean'];
  PersonnelJobHistoryInsert: Scalars['Boolean'];
  PersonnelJobHistoryUpdate: Scalars['Boolean'];
  PersonnelJobHistoryInsertByEmployee: Scalars['String'];
  PersonnelJobHistoryUpdateByEmployee: Scalars['Boolean'];
  PersonnelJobHistoryDelete: Scalars['Boolean'];
  PersonnelReferenceInsert: Scalars['Boolean'];
  PersonnelReferenceUpdate: Scalars['Boolean'];
  PersonnelReferenceDelete: Scalars['Boolean'];
  UserDefineDetailBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeUpdate: Scalars['Boolean'];
  StaffConfirmationInsert: Scalars['Boolean'];
  StaffConfirmationUpdate: Scalars['Boolean'];
  StaffConfirmationCancelConfirmed: Scalars['Boolean'];
  PersonnelStaffExitCompanyInsert: Scalars['Boolean'];
  PersonnelStaffExitCompanyUpdate: Scalars['Boolean'];
  StaffExitCompanyCancel: Scalars['Boolean'];
  ProcessStaffMovement: Scalars['Boolean'];
  StaffPromotionDemotionInsert: Scalars['Boolean'];
  StaffPromotionDemotionUpdate: Scalars['Boolean'];
  StaffPromotionDemotionCancel: Scalars['Boolean'];
  StaffTransferInsert: Scalars['Boolean'];
  StaffTransferUpdate: Scalars['Boolean'];
  StaffTransferCancel: Scalars['Boolean'];
  DeleteProjectExpenseClaimRecords: Scalars['Boolean'];
  SubmitProjectExpenseClaimForApproval: Scalars['Boolean'];
  SubmitMyProjectClaim: ProjectExpenseClaimHeaderEntity;
  ApproveProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  PostPEClaimRecords?: Maybe<Scalars['Boolean']>;
  ReversePEClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedProjectExpenseClaimDetail?: Maybe<Scalars['Boolean']>;
  ReverseApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  ProjectExpenseTypeSubmit: ProjectExpenseTypeEntity;
  ProjectExpenseTypeDelete: Scalars['Boolean'];
  ProjectGLAccountMappingSubmit: Scalars['Boolean'];
  ProjectGLAccountMappingDelete: Scalars['Boolean'];
  ProjectAccountAssignmentInsert: Scalars['Boolean'];
  ProjectAccountAssignmentUpdate: Scalars['Boolean'];
  DeleteClaimProcessedGL: Scalars['Boolean'];
  ProjectMasterSubmit: ProjectMasterEntity;
  ProjectMasterDelete: Scalars['Boolean'];
  ProjectCostAllocationSubmit: Scalars['Boolean'];
  ProjectCostAllocationDelete: Scalars['Boolean'];
  SubmitApproverMaster: Scalars['Boolean'];
  ResendConfirmationEmail: Scalars['Boolean'];
  ApproverConfirmedEmailAction: Scalars['Boolean'];
  DeleteApproverMaster: Scalars['Boolean'];
  DeleteApproverEmployeeListing: Scalars['Boolean'];
  SubmitApproverAssignment: Scalars['Boolean'];
  SubmitDailyEntry: Scalars['Boolean'];
  DeleteDailyEntry: Scalars['Boolean'];
  SubmitProjectTimesheet: Scalars['Boolean'];
  SubmitProjectTimesheetApproval: Scalars['Boolean'];
  ProjectTimesheetReportForApproverSendEmail: Scalars['Boolean'];
  RecruitmentExpensesInsert?: Maybe<Scalars['Boolean']>;
  RecruitmentExpensesDelete?: Maybe<Scalars['Boolean']>;
  ELetterTemplateInsert?: Maybe<Scalars['Boolean']>;
  ELetterTemplateUpdate?: Maybe<Scalars['Boolean']>;
  ELetterTemplateUpdateDefault?: Maybe<Scalars['Boolean']>;
  ELetterTemplateDelete?: Maybe<Scalars['Boolean']>;
  Rec_EApplicationFormSetupInsert: Scalars['Boolean'];
  PreOfferChecklistInsert?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistUpdate?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistSequenceUpdate?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistDelete?: Maybe<Scalars['Boolean']>;
  TalentPoolAdditionalUserInsert: Scalars['Boolean'];
  DeclineReasonInsert: Scalars['Boolean'];
  Rec_PrehiringFormSetupInsert: Scalars['Boolean'];
  JobPortalSetupInsert: Scalars['Boolean'];
  JobPortalSetupUpdate: Scalars['Boolean'];
  JobPortalSetupDelete: Scalars['Boolean'];
  JobPortalTalentInsert?: Maybe<Scalars['JSON']>;
  EApplicationInsert: Scalars['JSON'];
  DataUpdateEApplicationInsert: Scalars['JSON'];
  SubmitMyRecruitmentRequisition?: Maybe<Scalars['Boolean']>;
  UpdateMyRecRequisitionApprovalStatus?: Maybe<Scalars['Boolean']>;
  MyApprovedJobVacancyApplicantStatusUpdate: Scalars['Boolean'];
  SubmitRecruitmentRequisition?: Maybe<Scalars['Boolean']>;
  DeleteRecRequisition?: Maybe<Scalars['Boolean']>;
  UpdateRecRequisitionApprovalStatus?: Maybe<Scalars['Boolean']>;
  JobVacancyInsert: Scalars['Boolean'];
  JobVacancyStatusUpdate: Scalars['Boolean'];
  SubmitInterviewSchedule?: Maybe<Scalars['Boolean']>;
  UpdateInterviewResult?: Maybe<Scalars['Boolean']>;
  CompleteInterview?: Maybe<Scalars['Boolean']>;
  ReopenInterview?: Maybe<Scalars['Boolean']>;
  DeleteInterviewSchedule: Scalars['Boolean'];
  JobVacancyDelete: Scalars['Boolean'];
  JobPostingInsert: Scalars['Boolean'];
  JobPostingStatusUpdate: Scalars['Boolean'];
  JobPostingUpdate: Scalars['Boolean'];
  JobPostingDelete: Scalars['Boolean'];
  ApplicantStatusUpdate: Scalars['Boolean'];
  FinalSelectionStatusUpdate: Scalars['Boolean'];
  OfferProposalUpdate: Scalars['Boolean'];
  OfferProposalInsert: Scalars['Boolean'];
  OfferApprovalUpdate: Scalars['Boolean'];
  OfferContentInsert: Scalars['Boolean'];
  FinalizeOffer: Scalars['Boolean'];
  UpdateOfferResponse: Scalars['Boolean'];
  SendLetter: Scalars['Boolean'];
  InsertAcceptedPreHiring: Scalars['Boolean'];
  SendHiringEmail: Scalars['Boolean'];
  InsertQuickHiring: Scalars['Boolean'];
  UpdateQuickHiring: Scalars['Boolean'];
  DeleteQuickHiring: Scalars['Boolean'];
  CancelPreHiring: Scalars['Boolean'];
  ApprovePreHiring: Scalars['Boolean'];
  DataUpdateStatusLog: Scalars['Boolean'];
  DeclineNewHiringProcess: Scalars['Boolean'];
  OnboardNewHireProcess: Scalars['Boolean'];
  TalentPoolDelete: Scalars['Boolean'];
  TalentPersonalInsert: Scalars['Boolean'];
  TalentEducationInsert: Scalars['Boolean'];
  TalentHealthInfoInsert: Scalars['Boolean'];
  TalentStatutoryInsert: Scalars['Boolean'];
  TalentCertificateInsert: Scalars['Boolean'];
  TalentMembershipInsert: Scalars['Boolean'];
  TalentEmpHistoryInsert: Scalars['Boolean'];
  TalentReferenceInsert: Scalars['Boolean'];
  TalentDependentInsert: Scalars['Boolean'];
  TalentLanguageInsert: Scalars['Boolean'];
  TalentDocInsert: Scalars['Boolean'];
  TalentInfoDelete: Scalars['Boolean'];
  AddTalentToPrescreen: Scalars['Boolean'];
  SaveTalentSearch: Scalars['Boolean'];
  DeleteTalentSearch: Scalars['Boolean'];
  UpdateTalentStatus: Scalars['Boolean'];
  InsertTalentInvitation: Scalars['Boolean'];
  SendTalentInvitationEmail: Scalars['Boolean'];
  CancelTalentInvitation: Scalars['Boolean'];
  ApproveTalentInvitation: Scalars['Boolean'];
  ApprovalPolicyMatrixInsert?: Maybe<Scalars['Boolean']>;
  ApprovalPolicyMatrixDelete?: Maybe<Scalars['Boolean']>;
  DeleteApprovedRequisition: Scalars['Boolean'];
  RequisitionManagementApprovalUpdate: Scalars['Boolean'];
  RequisitionInsert: RequisitionEntity;
  RequisitionUpdate: Scalars['Boolean'];
  RequisitionDelete: Scalars['Boolean'];
  RequisitionSubmit: Scalars['Boolean'];
  submitRequisitionType: Scalars['Boolean'];
  InvoiceRPA: Array<InvoiceRpaResult>;
  ClaimParameterInsert: ClaimParameterEntity;
  ClaimUserDefineFieldsInsert: Scalars['Boolean'];
  MileageTableInsert: Scalars['Boolean'];
  VehicleRateInsert: Scalars['Boolean'];
  ClaimUserDefineFieldsUpdate: Scalars['Boolean'];
  ClaimUserDefineFieldsDuplicate: Scalars['Boolean'];
  MileageTableUpdate: Scalars['Boolean'];
  MileageTableDelete: Scalars['Boolean'];
  ClaimParameterUpdate: Scalars['Boolean'];
  ClaimParameterDelete: Scalars['Boolean'];
  PersonnelParameterInsert: PersonnelParameterEntity;
  PersonnelParameterUpdate: Scalars['Boolean'];
  PersonnelParameterDelete: Scalars['Boolean'];
  ReportTemplateInsert: Scalars['Boolean'];
  ReportTemplateAssignment: Scalars['Boolean'];
  ReportTemplateInactive: Scalars['Boolean'];
  ReportTemplateUpdate: Scalars['Boolean'];
  submitStaffMovementRequest: Scalars['Boolean'];
  EAPartFInsert: Scalars['Boolean'];
  EAPartFUpdate: Scalars['Boolean'];
  EAPartFDelete: Scalars['Boolean'];
  EAPartFCopyInsert: Scalars['Boolean'];
  EISPolicyUpdate: Scalars['Boolean'];
  EPFPolicyUpdate: Scalars['Boolean'];
  FormItemRatingSubmit: Scalars['Boolean'];
  FormItemRatingDelete: Scalars['Boolean'];
  KpiCategorySubmit: Scalars['Boolean'];
  KpiCategoryDelete: Scalars['Boolean'];
  KpiPredefinedItemSubmit: Scalars['Boolean'];
  KpiPredefinedItemUpdateStatus: Scalars['Boolean'];
  KpiPredefinedItemDelete: Scalars['Boolean'];
  KpiPredefinedTemplateSubmit: Scalars['Boolean'];
  KpiPredefinedTemplateDelete: Scalars['Boolean'];
  ReviewFormTemplateSubmit: Scalars['Boolean'];
  ReviewFormTemplateDelete: Scalars['Boolean'];
  ReviewGradingTableSubmit: Scalars['Boolean'];
  ReviewGradingTableDelete: Scalars['Boolean'];
  ReviewGroupSubmit: Scalars['Boolean'];
  ReviewGroupDelete: Scalars['Boolean'];
  ReviewItemSubmit: Scalars['Boolean'];
  ReviewItemDelete: Scalars['Boolean'];
  ReviewPolicySubmit: Scalars['Boolean'];
  ReviewPolicyDelete: Scalars['Boolean'];
  ReviewProcessPolicySubmit: Scalars['Boolean'];
  ReviewProcessPolicyDelete: Scalars['Boolean'];
  ReviewTemplateSubmit: Scalars['Boolean'];
  ReviewTemplateDelete: Scalars['Boolean'];
  RewardGroupSubmit: Scalars['Boolean'];
  RewardGroupDelete: Scalars['Boolean'];
  PersonnelSecurityPolicySubmit: Scalars['Boolean'];
  HolidayBulkInsert: Scalars['Boolean'];
  HolidayBulkDelete: Scalars['Boolean'];
  HolidayBulkUpdate: Scalars['Boolean'];
  SocsoPolicyUpdate: Scalars['Boolean'];
  TaxReliefInsert: Scalars['Boolean'];
  TaxReliefUpdate: Scalars['Boolean'];
  TaxReliefDelete: Scalars['Boolean'];
  TaxReliefCopyInsert: Scalars['Boolean'];
  MonthlyAttendanceProcessing: Scalars['Boolean'];
  AttendanceProcessingRedirect: Scalars['Boolean'];
  AttendanceProcessing: Scalars['Boolean'];
  UpdateAdjustedValue: Scalars['Boolean'];
  DeleteAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateBeaconDevice: Scalars['Boolean'];
  createChangeShift: ChangeShiftEntity;
  updateChangeShift: Scalars['Boolean'];
  deleteChangeShift: Scalars['Boolean'];
  updateChangeShiftSubmit: Scalars['Boolean'];
  createDailyAllowance: Scalars['Boolean'];
  updateDailyAllowance: Scalars['Boolean'];
  deleteDailyAllowance: Scalars['Boolean'];
  createDeductionPolicy: DeductionPolicyEntity;
  updateDeductionPolicyStatus: Scalars['Boolean'];
  updateDeductionPolicy: Scalars['Boolean'];
  PostEarnedLeave: Scalars['Boolean'];
  ReverseEarnedLeave: Scalars['Boolean'];
  createJobGradePolicyMatrix: JobGradePolicyMatrixEntity;
  createMobileCheckIn: Scalars['Boolean'];
  updateMobileCheckIn: Scalars['Boolean'];
  deleteMobileCheckIn: Scalars['Boolean'];
  InsertUpdateLocationRadiusSetting: Scalars['Boolean'];
  createMonthlyAllowance: Scalars['Boolean'];
  updateMonthlyAllowance: Scalars['Boolean'];
  deleteMonthlyAllowance: Scalars['Boolean'];
  createOvertimeClaimTable: OvertimeClaimTableEntity;
  updateOvertimeClaimTableStatus: Scalars['Boolean'];
  OTReasonUpdate: Scalars['Boolean'];
  OTReasonDelete: Scalars['Boolean'];
  OTReasonStatus: Scalars['Boolean'];
  OTReasonInsert: OvertimeReasonEntity;
  OvertimePolicyInsert: Scalars['Boolean'];
  OvertimePolicyUpdate: Scalars['Boolean'];
  OvertimePolicyDelete: Scalars['Boolean'];
  createPeriodicDeduction: Scalars['Boolean'];
  updatePeriodicDeduction: Scalars['Boolean'];
  deletePeriodicDeduction: Scalars['Boolean'];
  createAllowanceDeduction: Scalars['Boolean'];
  updateAllowanceDeduction: Scalars['Boolean'];
  deleteAllowanceDeduction: Scalars['Boolean'];
  createOTRounding: Scalars['Boolean'];
  updateOTRounding: Scalars['Boolean'];
  deleteOTRounding: Scalars['Boolean'];
  ShiftSettingInsert: Scalars['Boolean'];
  ShiftSetupUpdate: Scalars['Boolean'];
  ShiftSetupDelete: Scalars['Boolean'];
  createShiftGroup: Scalars['Boolean'];
  updateShiftGroup: Scalars['Boolean'];
  updateShiftGroupStatus: Scalars['Boolean'];
  SubmitShiftGroupPattern: Scalars['Boolean'];
  DeleteShiftGroupPattern: Scalars['Boolean'];
  logShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  updateShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  deleteShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  createShiftPattern: Scalars['Boolean'];
  updateShiftPattern: Scalars['Boolean'];
  deleteShiftPattern: Scalars['Boolean'];
  UserDefineEPFPolicyUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyDelete: Scalars['Boolean'];
  UserDefineEPFPolicyConditionInsertOrUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyConditionDelete: Scalars['Boolean'];
  updateTemplateLibraryAssignment: TemplateLibraryAssignmentEntity;
  createTemplateLibraryAssignment: Scalars['Float'];
  OvertimeSettingUpdate: Scalars['Boolean'];
  replaceTemplateLibraryAssignment: Scalars['Float'];
  replaceTemplateLibraryAssignmentForTMSShiftGroup: Scalars['Float'];
  replaceTemplateLibraryAssignmentv2: Scalars['Float'];
  deleteTemplateLibraryAssignment: Scalars['Float'];
  ShiftGroupAssignment: Scalars['Float'];
  DailyTypeConditionUpdate: Scalars['Boolean'];
  DailyTypeConditionDelete: Scalars['Boolean'];
  DeductionTypeConditionUpdate: Scalars['Boolean'];
  DeductionTypeConditionDelete: Scalars['Boolean'];
  TMSTypeConditionUpdate: Scalars['Boolean'];
  TMSTypeConditionDelete: Scalars['Boolean'];
  TmsotConditionTemplateLibraryUpdate: Scalars['Boolean'];
  TMSTimeOffConditionUpdate: Scalars['Boolean'];
  MonthlyTypeConditionUpdate: Scalars['Boolean'];
  MonthlyTypeConditionDelete: Scalars['Boolean'];
  TimeOffInsert: TimeOffEntity;
  TimeOffUpdate: Scalars['Boolean'];
  TimeOffDelete: Scalars['Boolean'];
  createTimeOff: Scalars['Boolean'];
  TimeOffReasonInput: TimeOffReasonEntity;
  TimeOffReasonUpdate: Scalars['Boolean'];
  TimeOffReasonDelete: Scalars['Boolean'];
  TimeOffSettingInsert: TimeOffSettingEntity;
  TimeOffSettingUpdate: Scalars['Boolean'];
  TimeOffSettingDelete: Scalars['Boolean'];
  TsAccountTypeInsert: Scalars['Boolean'];
  TsAccountTypeUpdate: Scalars['Boolean'];
  TsAccountTypeDelete: Scalars['Boolean'];
  TsAccountCodeInsert: Scalars['Boolean'];
  TsAccountCodeUpdate: Scalars['Boolean'];
  TsAccountCodeDelete: Scalars['Boolean'];
  StaffTimeSheetInsert: Scalars['Boolean'];
  StaffTimesheetDelete: Scalars['Boolean'];
  ToDoStatusUpdate: Scalars['Boolean'];
  createTrainingAttendanceRecord: TrainingAttendanceEntity;
  updateTrainingAttendanceRecord: Scalars['Float'];
  CompulsaryCourseByJobGradeSubmit: Scalars['Boolean'];
  CourseClassificationBulkInsert: Scalars['Boolean'];
  CourseClassificationDelete: Scalars['Boolean'];
  TrainingCourseSubmit: Scalars['Boolean'];
  TrainingCourseDelete: Scalars['Boolean'];
  AuthorisedTrainingProviderSubmit: Scalars['Boolean'];
  TrainingCostItemBulkInsert: Scalars['Boolean'];
  TrainingCostItemDelete: Scalars['Boolean'];
  TraineeEvalSubmit: Scalars['Boolean'];
  SupervisorEvalSubmit: Scalars['Boolean'];
  CourseEvalSubmit: Scalars['Boolean'];
  TrainerEvalSubmit: Scalars['Boolean'];
  ScheduleTrainingSubmit: Scalars['Boolean'];
  ScheduleTableSubmit: Scalars['Boolean'];
  ScheduleTrainingDelete: Scalars['Boolean'];
  ScheduleTrainingCancel: Scalars['Boolean'];
  RegisteredTraineeInsert: Scalars['Boolean'];
  RegisteredTraineeDelete: Scalars['Boolean'];
  TrainingBudgetInputBulkInsert: Scalars['Boolean'];
  TrainingCostSubmit: Scalars['Boolean'];
  CostAllocationSubmit: Scalars['Boolean'];
  HRDFClaimSubmit: Scalars['Boolean'];
  SubmitBudgetGroupMatrix?: Maybe<Scalars['Boolean']>;
  DeleteBudgetGroupMatrix?: Maybe<Scalars['Boolean']>;
  SubmitTargetGroupMatrix?: Maybe<Scalars['Boolean']>;
  DeleteTargetGroupMatrix?: Maybe<Scalars['Boolean']>;
  SubmitApprovalPolicyMatrix?: Maybe<Scalars['Boolean']>;
  DeleteApprovalPolicyMatrix?: Maybe<Scalars['Boolean']>;
  IsMaintenanceBudgetPeriodOverlap?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceBudget?: Maybe<Scalars['Boolean']>;
  DeleteMaintenanceBudget?: Maybe<Scalars['Boolean']>;
  IsMaintenanceTargetHoursPeriodOverlap?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceTargetHours?: Maybe<Scalars['Boolean']>;
  DeleteMaintenanceTargetHours?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceCourse?: Maybe<Scalars['Boolean']>;
  SubmitTrainingRequest?: Maybe<Scalars['Boolean']>;
  DeleteTrainingRequest?: Maybe<Scalars['Boolean']>;
  WithdrawTrainingRequest?: Maybe<Scalars['Boolean']>;
  SubmitScheduledTraining?: Maybe<Scalars['Boolean']>;
  DeleteScheduledTraining?: Maybe<Scalars['Boolean']>;
  UpdateScheduledTrainingEventStatus?: Maybe<Scalars['Boolean']>;
  SubmitAdminTrainingRegistration?: Maybe<Scalars['Boolean']>;
  UpdateEmployeeStatusAndNominator?: Maybe<Scalars['Boolean']>;
  UpdateEmployeeScheduledTrainingStatus?: Maybe<Scalars['Boolean']>;
  DeleteEmployeeTrainingRegistration?: Maybe<Scalars['Boolean']>;
  SubmitVenueInfo: Scalars['Boolean'];
  NotifyTrainee?: Maybe<Scalars['Boolean']>;
  SubmitTrainingAttendance?: Maybe<Scalars['Boolean']>;
  FinalizedAttendance?: Maybe<Scalars['Boolean']>;
  GenerateTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  InitiateTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  SubmitSurveyFormAnswer?: Maybe<Scalars['Boolean']>;
  UploadTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  RevertDraftTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  ClearRevertDraftTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  DeleteTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  SubmitScheduledTrainingExpense?: Maybe<Scalars['Boolean']>;
  SubmitHRDCClaim?: Maybe<Scalars['Boolean']>;
  UpdateHRDCClaimStatus?: Maybe<Scalars['Boolean']>;
  DeleteHRDCClaim?: Maybe<Scalars['Boolean']>;
  EmployeeSubmitTrainingRequest?: Maybe<Scalars['Boolean']>;
  EmployeeDeleteTrainingRequest?: Maybe<Scalars['Boolean']>;
  EmployeeCancelTrainingRequest?: Maybe<Scalars['Boolean']>;
  TrainingCheckIn: Scalars['JSON'];
  WithdrawSubordinateTrainingScheduled?: Maybe<Scalars['Boolean']>;
  ApplyTrainingEvent?: Maybe<Scalars['Boolean']>;
  NominateSubordinate?: Maybe<Scalars['Boolean']>;
  WithdrawSubordinate?: Maybe<Scalars['Boolean']>;
  SubmitCourseCategory?: Maybe<Scalars['Boolean']>;
  DeleteCourseCategory?: Maybe<Scalars['Boolean']>;
  SubmitTrainingCourseLibrary: Scalars['Boolean'];
  DeleteTrainingCourseLibrary?: Maybe<Scalars['Boolean']>;
  SubmitTrainingProvider?: Maybe<Scalars['Boolean']>;
  DeleteTrainingProvider?: Maybe<Scalars['Boolean']>;
  SubmitSurveyType?: Maybe<Scalars['Boolean']>;
  DeleteSurveyType?: Maybe<Scalars['Boolean']>;
  SubmitSurveyItem?: Maybe<Scalars['Boolean']>;
  DeleteSurveyItem?: Maybe<Scalars['Boolean']>;
  SubmitSurveyForm?: Maybe<Scalars['Boolean']>;
  DeleteSurveyForm?: Maybe<Scalars['Boolean']>;
  SubmitTrainingExpense?: Maybe<Scalars['Boolean']>;
  DeleteTrainingExpense?: Maybe<Scalars['Boolean']>;
  SubmitBudgetGroup?: Maybe<Scalars['Boolean']>;
  DeleteBudgetGroup?: Maybe<Scalars['Boolean']>;
  SubmitTargetGroup?: Maybe<Scalars['Boolean']>;
  DeleteTargetGroup?: Maybe<Scalars['Boolean']>;
  SubmitTrainerProfile?: Maybe<Scalars['Boolean']>;
  DeleteTrainerProfile?: Maybe<Scalars['Boolean']>;
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  CreateMileageLog: MileageLogEntity;
  UpdateMileageLog: Scalars['Boolean'];
  DeleteMileageLogItem: Scalars['Boolean'];
  AllowancePolicyInsert: AllowanceBenfitEntity;
  AllowancePolicyUpdate: Scalars['Boolean'];
  AllowanceEntitlementInsert: AllowanceItemEntity;
  AllowanceEntitlementUpdate: Scalars['Boolean'];
  UpdateLastProcess: Scalars['Boolean'];
  PayProcessInsert: Scalars['Boolean'];
  employeeAppLogin: EmployeeAppLoginResponse;
  createUser: UserEntity;
  genHashPassword: Scalars['JSON'];
  createSuperUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  RedirectDomainToken: Scalars['String'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  changeUserPassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  employeeLogout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  forgotEmployeePassword: Scalars['Boolean'];
  resetEmployeePassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  userActionStatus: Scalars['Boolean'];
  updateHelpdeskAssignee: Scalars['Boolean'];
  changeEmployeePassword: Scalars['Boolean'];
  UpdateLastLoginCompany: Scalars['Boolean'];
  UserProfilePicUpdate: Scalars['Boolean'];
  ResendInvitationById: Scalars['Boolean'];
};


export type MutationDocumentInsertArgs = {
  IsStatic?: Maybe<Scalars['Boolean']>;
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateArgs = {
  DocumentsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateParentIdArgs = {
  DocumentID: Scalars['String'];
  ParentID: Scalars['String'];
};


export type MutationDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationEmployeeProfileInsertUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
};


export type MutationCreateDutyRosterArgs = {
  DutyRosterInput: DutyRosterInput;
};


export type MutationDutyRosterBatchArgs = {
  removeList?: Maybe<Array<DutyRosterInput>>;
  Input?: Maybe<Array<DutyRosterInput>>;
};


export type MutationShiftPatternAssignmentToEmployeeArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  ShiftPatternID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type MutationCreateBatchEntryByShiftPatternArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  Input: DutyRosterInput;
};


export type MutationDeleteDutyRosterByEmployeeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationDeleteDutyRosterForAllArgs = {
  CompanyID: Scalars['String'];
};


export type MutationDeleteDutyRosterBySelectedEmployeeArgs = {
  Date: Array<Scalars['String']>;
  EmployeeID: Array<Scalars['String']>;
};


export type MutationNewsReadInsertArgs = {
  NewsReadInput: NewsReadInput;
};


export type MutationNotificationUpdateArgs = {
  input: NotificationInput;
};


export type MutationDeleteAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationCreateAttendanceRecordArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  input: AttendanceRecordInput;
};


export type MutationSubmitPairedAttandanceRecordArgs = {
  DataToDelete?: Maybe<Array<Scalars['String']>>;
  input: Array<AttendanceRecordPairedObj>;
};


export type MutationUpdateAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationDeleteTimeAttendanceRecordArgs = {
  AttendanceRecordID: Array<Scalars['String']>;
};


export type MutationAttendanceExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationAttendanceExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationAttendanceExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationAttendanceRecordBatchDeleteArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAttendanceRecordBatchArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAutomatedClockPairingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeList: Array<Scalars['String']>;
};


export type MutationAppraisalCategoryInsertArgs = {
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryUpdateArgs = {
  AppraisalCategoryID: Scalars['String'];
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryDeleteArgs = {
  AppraisalCategoryID: Scalars['String'];
};


export type MutationAppraisalCategoryItemInsertArgs = {
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemUpdateArgs = {
  AppraisalCategoryItemID: Scalars['String'];
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemDeleteArgs = {
  AppraisalCategoryItemID: Scalars['String'];
};


export type MutationAppraisalPolicyInsertArgs = {
  input: AppraisalPolicyInput;
};


export type MutationAppraisalPolicyDeleteArgs = {
  AppraisalPolicyID: Scalars['String'];
};


export type MutationAppraisalTemplateByJobPositionSubmitArgs = {
  input: AppraisalTempleteByJobPositionHeaderInput;
};


export type MutationAppraisalScheduleSubmitArgs = {
  input: AppraisalScheduleHeaderInput;
};


export type MutationAppraisalCompanySettingSubmitArgs = {
  input: AppraisalCompanySettingInput;
};


export type MutationFinalRatingSubmitArgs = {
  input: FinalRatingHeaderInput;
};


export type MutationFinalRatingDeleteArgs = {
  FinalRatingHeaderID: Scalars['String'];
};


export type MutationScaleRatingSubmitArgs = {
  input: ScaleRatingHeaderInput;
};


export type MutationScaleRatingDeleteArgs = {
  ScaleRatingHeaderID: Scalars['String'];
};


export type MutationBonusSimulationSubmitArgs = {
  BonusSimulationInput: Array<BonusSimulationInput>;
};


export type MutationIncrementSimulationSubmitArgs = {
  IncrementSimulationInput: Array<IncrementSimulationInput>;
};


export type MutationSubmitAppraisalTempleteArgs = {
  input: AppraisalTempleteInput;
};


export type MutationSubmitAppraisalArgs = {
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DocList?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<Array<DocumentsInput>>;
  Input: EmployeeAppraisalHeaderInput;
};


export type MutationModerationSubmitArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  input: Array<ModerationRatingInput>;
};


export type MutationSubmitStaffKpiPlanningArgs = {
  LatestVersion?: Maybe<Scalars['String']>;
  Submmit_Type: Scalars['String'];
  Input: EmployeeKpiHeaderInput;
};


export type MutationStaffKpiPlannigDeleteArgs = {
  EmployeeKPIHeaderID: Scalars['String'];
};


export type MutationStaffPerformanceAnalysisSummarySubmitArgs = {
  input: Array<StaffPerformanceAnalysisSummaryInput>;
};


export type MutationApprovalPolicyInsertArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicyUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicySubmitArgs = {
  input: NewApprovalPolicyInput;
};


export type MutationApprovalPolicyDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationApprovalPolicyAssignmentUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyAssignmentInput;
};


export type MutationNewApprovalPolicyAssignmentDeleteArgs = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};


export type MutationAttendanceAnalysisTemplateInsertArgs = {
  AttendanceAnalysisTemplateInput?: Maybe<Array<AttendanceAnalysisTemplateInput>>;
};


export type MutationSubmitAttendanceCorrectionRequestArgs = {
  input: AttendanceCorrectionInput;
};


export type MutationCancelAttendanceCorrectionRequestArgs = {
  AttendanceCorrectionRequestHeaderID: Scalars['String'];
};


export type MutationAttendanceCorrectionRequestApprovalArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: AttendanceCorrectionRequestUpdateInput;
};


export type MutationPostAttendanceRecordArgs = {
  input: AttendancePostInput;
};


export type MutationReverseAttendanceRecordArgs = {
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeePayItemIDPairs: Array<AttendanceCalculationInput>;
};


export type MutationAttendancePeriodEditArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type MutationAttendanceProccessBulkDeleteArgs = {
  AttendanceCalculationID: Array<Scalars['String']>;
};


export type MutationOvertimeInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: OvertimeInput;
};


export type MutationOvertimeDraftArgs = {
  input: OvertimeInput;
};


export type MutationOvertimeBatchArgs = {
  Input: Array<OvertimeInput>;
};


export type MutationOvertimeUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<OvertimeInput>;
};


export type MutationOvertimeDeleteArgs = {
  OvertimeID: Scalars['String'];
};


export type MutationOvertimeExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationOvertimeExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationOvertimeExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationBatchOvertimeEntryInsertArgs = {
  Remark?: Maybe<Scalars['String']>;
  BatchOvertimeStatus?: Maybe<OvertimeStatus>;
  EmployeeIDs: Array<Scalars['String']>;
  SubmitterID: Scalars['String'];
  Duration: Scalars['Float'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  WorkDate: Scalars['DateTime'];
  ShiftID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationBatchOvertimeCancelArgs = {
  ActionStatus?: Maybe<Scalars['String']>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationBatchOvertimeWithdrawArgs = {
  ActionStatus?: Maybe<Scalars['String']>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeDraftArgs = {
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeSubmitArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  StatusApproval?: Maybe<ApprovalStatus>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeWithdrawalApprovalArgs = {
  StatusApproval?: Maybe<ApprovalStatus>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationSubmitManagementClaimRecordsArgs = {
  input: SubmitClaimRecordsInput;
};


export type MutationApproveClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveClaimRecordsInput;
};


export type MutationCancelClaimRecordsArgs = {
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type MutationDeleteApprovedClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  CancelRemark?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  input: ApproveClaimRecordsInput;
};


export type MutationReverseApprovedClaimRecordsArgs = {
  input: ApproveClaimRecordsInput;
};


export type MutationVerifyApprovedClaimDetailArgs = {
  input: VerifyApprovedClaimDetailInput;
};


export type MutationPostClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostClaimRecordsInput;
};


export type MutationReverseClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyClaimArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  workFlowStepInput?: Maybe<Array<WorkFlowStepInput>>;
  input: SubmitClaimRecordsInput;
};


export type MutationDeleteClaimRecordsArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitClaimForApprovalArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationDoUpdateClaimArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  input: ClaimHeaderInput;
  ClaimHeaderID: Scalars['String'];
};


export type MutationMyClaimInsertArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  ClaimHeaderInput: ClaimHeaderInput;
};


export type MutationClaimValidityUpdateArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  ClaimTrxDayLimit: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationClaimValidityInsertArgs = {
  ClaimSettingInput: ClaimSettingInput;
};


export type MutationClaimTypeInsertArgs = {
  input: ClaimTypeInput;
};


export type MutationClaimTypeUpdateArgs = {
  ClaimTypeID: Scalars['String'];
  input: ClaimTypeInput;
};


export type MutationClaimTypeDeleteArgs = {
  ClaimTypeID: Scalars['String'];
};


export type MutationClaimTypeTemplateInsertArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateUpdateArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateDeleteArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type MutationClaimTypeTemplateUpdateStatusArgs = {
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeConditionUpdateArgs = {
  ClaimTemplateInput: ClaimTemplateInput;
};


export type MutationClaimTypeConditionDeleteArgs = {
  ClaimTemplateID: Scalars['String'];
};


export type MutationClaimJobGradePolicyInsertArgs = {
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimPoolDeleteArgs = {
  ClaimPoolID: Scalars['String'];
};


export type MutationClaimPoolSubmitArgs = {
  ClaimPoolInput: ClaimPoolInput;
};


export type MutationInsertClaimCutOffPeriodArgs = {
  input: GenerateClaimCutOffPeriodInput;
};


export type MutationBankInsertArgs = {
  BankInfoInputs: BankInfoInputs;
  CompanyID: Scalars['String'];
};


export type MutationBankUpdateArgs = {
  CompanyID: Scalars['String'];
  BankInfoID: Scalars['String'];
  input: BankInfoInputs;
};


export type MutationBankDeleteArgs = {
  BankInfoID: Scalars['String'];
};


export type MutationCompanyOffDaysDeleteArgs = {
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateCompanyOffDaysStatusArgs = {
  Status: Status;
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateRestOffDayStatusArgs = {
  Status: Status;
  RestOffDayID: Array<Scalars['String']>;
};


export type MutationCompanyOffDaysBulkInsertArgs = {
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCalendarPolicyStatusUpdateArgs = {
  Status: Status;
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationCalendarPolicyDeleteArgs = {
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationOffRestDayDeleteArgs = {
  RecurringRestOffDayID: Array<Scalars['String']>;
};


export type MutationOffRestDayBulkInsertArgs = {
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
};


export type MutationCalendarOffDayUpdateArgs = {
  input: CalendarOffDayInput;
  CalendarOffDayID: Scalars['String'];
};


export type MutationCalendarOffDayInsertArgs = {
  input: CalendarOffDayInput;
};


export type MutationCalanderPolicySubmitArgs = {
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  CalendarPolicyInput?: Maybe<CalendarPolicyInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyHolidaysBulkInsertArgs = {
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyDocumentInsertArgs = {
  DocumentsInput: DocumentsInput;
};


export type MutationCompanyDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationCompanyFolderCreateArgs = {
  userID: Scalars['String'];
  CompanyID: Scalars['String'];
  FolderName: Scalars['String'];
};


export type MutationCompanyFolderDeleteArgs = {
  FolderID: Scalars['String'];
};


export type MutationCompanyInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyLeaveSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyTaxReliefUpdateArgs = {
  ExcludeTaxRelief: Scalars['Boolean'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyProjectExpenseClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyOnlyUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationJobDesignationInsertArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobPositionInsertArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationUpdateArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationSequenceUpdateArgs = {
  JobDesignationIDs: Array<Scalars['String']>;
};


export type MutationJobPositionUpdateArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationJobPositionDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationRecruitmentSourceBulkInsertArgs = {
  Input: Array<RecruitmentSourceBulkInput>;
};


export type MutationRecruitmentSourceInsertArgs = {
  input: RecruitmentSourceInput;
};


export type MutationRecruitmentSourceUpdateArgs = {
  input: RecruitmentSourceInput;
  RecruitmentSourceID: Scalars['String'];
};


export type MutationRecruitmentSourceDeleteArgs = {
  RecruitmentSourceID: Scalars['String'];
};


export type MutationCompanyAltAddressInsertArgs = {
  input: CompanyAltAddressInput;
};


export type MutationCompanyAltAddressUpdateArgs = {
  input: CompanyAltAddressInput;
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyAltAddressDeleteArgs = {
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyStatutoryAccountUpdateArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  CompanyStatutoryID: Scalars['String'];
  input: Array<CompanyStatutoryAccountInput>;
};


export type MutationCompanyStatutoryInsertArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
};


export type MutationCompanyStatutoryUpdateArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
  CompanyStatutoryID: Scalars['String'];
};


export type MutationCompanyStructureInsertArgs = {
  input: Array<CompanyHierarchyInput>;
};


export type MutationCompanyOrgUnitInsertArgs = {
  input: Array<CompanyOrgUnitInput>;
};


export type MutationCompanyOrgUnitInsertV2Args = {
  input: Array<CompanyOrgUnitInput>;
};


export type MutationCompanyOrgUnitUpdateArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  Inputs: Array<CompanyOrgUnitInput>;
};


export type MutationAssignJobPositionArgs = {
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  input: Array<JobPositionAssignmentInput>;
};


export type MutationCreateCompanyAccessPermArgs = {
  PayGroupAccess: Array<Scalars['String']>;
  RoleID: Scalars['String'];
  CompanyID: Scalars['String'];
  input: Array<CompanyAccessPermInput>;
};


export type MutationDepartmentInsertArgs = {
  input: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDepartmentDeleteArgs = {
  DepartmentUpdateInput: DepartmentUpdateInput;
};


export type MutationDivisionInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDivisionUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDivisionDeleteArgs = {
  DepartmentID: Scalars['String'];
};


export type MutationCreateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
};


export type MutationInsuranceSchemeInsertArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
};


export type MutationUpdateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeUpdateArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationDeleteInsuranceSchemeArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeDeleteArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInterbankInsertArgs = {
  InterbankGiroInput: InterbankGiroInput;
};


export type MutationInterbankBulkInsertArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankDeleteArgs = {
  InterbankGiroID: Scalars['String'];
};


export type MutationInterbankUpdateArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankSingleUpdateArgs = {
  InterbankGiroInput: InterbankGiroInput;
  InterbankGiroID: Scalars['String'];
};


export type MutationJobAccountInsertArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountUpdateArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountDeleteArgs = {
  JobAccountID: Scalars['String'];
};


export type MutationJobGradeBulkInsertArgs = {
  input: Array<JobGradeBulkInsertInput>;
};


export type MutationJobGradeInsertArgs = {
  input: JobGradeInput;
};


export type MutationJobGradeUpdateArgs = {
  JobGradeID: Scalars['String'];
  input: JobGradeInput;
};


export type MutationJobGradeDeleteArgs = {
  JobGradeID: Scalars['String'];
};


export type MutationLeaveRoundingBulkInsertArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingInsertArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
};


export type MutationUpdateLeaveRoundingArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveRoundingUpdateArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingDeleteArgs = {
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveCompulsoryPolicyBulkInsertArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLeaveCompulsoryPolicyUpdateArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLhdnReportInsertArgs = {
  input: EaFormSetupInput;
};


export type MutationLhdnReportUpdateArgs = {
  input: EaFormSetupInput;
  EAFormSetupID: Scalars['String'];
};


export type MutationLhdnReportCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationNotificationPolicyInsertArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyUpdateArgs = {
  NotificationPolicyID: Scalars['String'];
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyDeleteArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyConditionInsertArgs = {
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationNotificationPolicyConditionUpdateArgs = {
  NotificationPolicyConditionID: Scalars['String'];
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationPayrollCycleInsertArgs = {
  input: PayrollCycleInput;
};


export type MutationPayrollCycleUpdateArgs = {
  PayrollCycleID: Scalars['String'];
  input: PayrollCycleInput;
};


export type MutationPayrollCycleDeleteArgs = {
  PayrollCycleID: Scalars['String'];
};


export type MutationReportingGroupSubmitArgs = {
  input: ReportingGroupInput;
};


export type MutationReportingGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingCompanyGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingStructureGroupSubmitArgs = {
  input: ReportingStructureGroupInput;
};


export type MutationCreateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
};


export type MutationUpdateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
  SkillSetID: Scalars['String'];
};


export type MutationDeleteSkillSetArgs = {
  SkillSetID: Scalars['String'];
};


export type MutationUserDefineBulkInsertArgs = {
  input: Array<UserDefineBulkInput>;
};


export type MutationCoolbinetTransactionArgs = {
  items?: Maybe<Array<CoobinetItemInput>>;
  outTime?: Maybe<Scalars['String']>;
  entryTime?: Maybe<Scalars['String']>;
  lbCustomerId: Scalars['String'];
  cartId: Scalars['String'];
  checkout: Scalars['String'];
  coolbinetId: Scalars['String'];
  storeId: Scalars['String'];
  requestKey: Scalars['String'];
};


export type MutationPostOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationRevertOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationPostFromPlatformLeaveTypesArgs = {
  SubscriptionUserID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationPostLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationSubmitLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationSubmitLeaveApplicationV2Args = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeletePendingLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteApprovedLeaveApplicationArgs = {
  IsAutoWithdrawal?: Maybe<Scalars['Boolean']>;
  IsSupersede?: Maybe<Scalars['Boolean']>;
  Remark: Scalars['String'];
  input: EmpLeaveDetailInput;
};


export type MutationPostLeaveOpeningYearArgs = {
  Year: Scalars['Float'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationLeaveTypeInsertArgs = {
  input: LeaveTypeInput;
};


export type MutationLeaveTypeUpdateArgs = {
  input: LeaveTypeInput;
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveTypeDeleteArgs = {
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveReasonInsertArgs = {
  input: LeaveReasonInput;
};


export type MutationLeaveReasonUpdateArgs = {
  input: LeaveReasonInput;
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveReasonDeleteArgs = {
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveDiseaseInsertArgs = {
  input: LeaveDiseaseInput;
};


export type MutationLeaveDiseaseUpdateArgs = {
  input: LeaveDiseaseInput;
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveDiseaseDeleteArgs = {
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveJobGradePolicyInsertArgs = {
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveTypeConditionUpdateArgs = {
  LeaveTemplateInput: LeaveTemplateInput;
};


export type MutationLeaveTypeConditionDeleteArgs = {
  LeaveTemplateID: Scalars['String'];
};


export type MutationLeavePolicyUpdateArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeavePolicyDeleteArgs = {
  LeavePolicyID: Scalars['String'];
};


export type MutationLeavePolicyInsertArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeaveTypeTemplateInsertArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateUpdateArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateDeleteArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type MutationUserDashboardControlSubmitArgs = {
  input: UserDashboardControlInput;
};


export type MutationDataChangesInsertArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelEducationInsertInput?: Maybe<PersonnelEducationInsertInput>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelCertificateInput?: Maybe<PersonnelCertificateInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  MedicalRecordInput?: Maybe<Array<MedicalRecordInput>>;
  ProfilePicInput?: Maybe<DocumentsInput>;
  DocumentsInput?: Maybe<DocumentsInput>;
  input: DataChangesInput;
};


export type MutationSubmitDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDeleteDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationCancelDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationApproveDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationRejectDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDataChangesApprovalArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationDataChangesRejectArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationShopeeDataImportVerifyArgs = {
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationShopeeDataImportVerifyRedirectArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationDataImportVerifyArgs = {
  PeriodYear?: Maybe<Scalars['String']>;
  TaxExemptionMapping?: Maybe<Array<TaxExemptionMappingInput>>;
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationDataImportVerifyRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['String']>;
  TaxExemptionMapping?: Maybe<Array<TaxExemptionMappingInput>>;
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationRemoveTempDataArgs = {
  Type: Scalars['String'];
};


export type MutationProceedForImportArgs = {
  Type: Scalars['String'];
};


export type MutationActivateEmployeesArgs = {
  Emails: Array<Scalars['String']>;
  IsSelectAll: Scalars['Boolean'];
};


export type MutationActivateEmployeesInBulkArgs = {
  accountID: Scalars['String'];
};


export type MutationDelegationInsertArgs = {
  DelegationInput: Array<DelegationInput>;
};


export type MutationDelegationUpdateArgs = {
  DelegationInput: Array<DelegationInput>;
  BatchOvertimeDelegation?: Maybe<Array<BatchOvertimeDelegation>>;
  PerformanceManagement?: Maybe<Array<PerformanceManagementDelegation>>;
  TimeAttendance?: Maybe<Array<TimeAttendanceDelegation>>;
  LeaveManagement?: Maybe<Array<LeaveManagementDelegation>>;
  RequisitionManagement?: Maybe<Array<RequisitionManagementDelegation>>;
  ProjectExpenseManagement?: Maybe<Array<ProjectExpenseManagementDelegation>>;
  ClaimManagement?: Maybe<Array<ClaimManagementDelegation>>;
  PersonnelManagement?: Maybe<Array<PersonnelManagementDelegation>>;
};


export type MutationDelegationDeleteArgs = {
  DelegationID: Scalars['String'];
};


export type MutationNewsDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
};


export type MutationNewsInsertArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsUpdateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentID: Scalars['String'];
  NewsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsPublishArgs = {
  PostStatus: PostStatus;
  NewsID: Scalars['String'];
};


export type MutationNewsDuplicateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  NewsID: Scalars['String'];
};


export type MutationNewDocumentNumberingInsertArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationCompanyDocumentNumberingUpdateArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationDocumentNumberingInsertArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingDeleteArgs = {
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberingUpdateStatusArgs = {
  DocStatus: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberAssignmentArgs = {
  input: Array<DocumentNumberAssignmentInput>;
};


export type MutationBatchEntryByShiftInsertArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  input: DutyRosterInput;
};


export type MutationUpdateBatchEntryByShiftPatternArgs = {
  BatchEntryByShiftPatternID: Scalars['String'];
  BatchEntryByShiftPatternInput: BatchEntryByShiftPatternInput;
};


export type MutationCreateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationUpdateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationDeleteEmployeeAppraisalArgs = {
  EmployeeAppraisalID: Scalars['String'];
};


export type MutationCareerLogInsertArgs = {
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCareerLogUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCreateEmployeeDiaryArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationUpdateEmployeeDiaryArgs = {
  DocumentID: Scalars['String'];
  EmployeeDiaryID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationDeleteEmployeeDiaryArgs = {
  EmployeeDiaryID: Scalars['String'];
};


export type MutationEmployeeInfoInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeInfoUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeCareerLogUpdateArgs = {
  EmployeeInput: EmployeeInput;
};


export type MutationEmployeeStatutoryInsertArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeeStatutoryUpdateArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeePrevContributionInsertArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeePrevContributionUpdateArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeeDependentInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentInsertByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeDependentUpdateByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeTaxReliefBulkInsertArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeTaxReliefBulkUpdateArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeAssetInsertArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
};


export type MutationEmployeeAssetUpdateArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeAssetDeleteArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindInsertArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
};


export type MutationEmployeeBenefitInKindUpdateArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindDeleteArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeDependentDeleteArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type MutationEmployeeSalaryInsertArgs = {
  Input: CareerLogInput;
};


export type MutationEmployeeSalaryUpdateArgs = {
  Input: CareerLogInput;
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeSalaryDeleteArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeProfileUploadArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
  DocumentID: Scalars['String'];
  EmployeeInput: EmployeeInput;
};


export type MutationUpdateEmployeeLastLoginCompanyArgs = {
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  LastestAccessedEntity: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeCp22UpdateArgs = {
  Input: Array<EmployeeCp22Input>;
};


export type MutationCreateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationUpdateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationDeleteEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationUpdateNotificationReadStatusArgs = {
  NotificationID: Scalars['String'];
};


export type MutationUpdateAllNotificationReadStatusArgs = {
  EmployeeID: Scalars['String'];
};


export type MutationUpdateBulkNotificationReadStatusArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationNotificationReadStatusDeleteArgs = {
  ID: Array<Scalars['String']>;
};


export type MutationCreateEmployeeTrainingArgs = {
  EmployeeTrainingInput: EmployeeTrainingInput;
};


export type MutationUpdateEmployeeTrainingArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeTrainingInput: EmployeeTrainingInput;
  EmployeeTrainingID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingArgs = {
  EmployeeTrainingID: Scalars['String'];
};


export type MutationEmployeeUserDefineFieldsInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsDetailInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationEmployeeUserDefineFieldsDetailUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationZakatContributionInsertArgs = {
  ZakatContributionInput: ZakatContributionInput;
};


export type MutationZakatContributionUpdateArgs = {
  input: ZakatContributionInput;
  ID: Scalars['String'];
};


export type MutationZakatContributionDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUpdateAppSecurityArgs = {
  permissionArr?: Maybe<Array<HrPermission>>;
  input: EmployeeAppSecurityInput;
};


export type MutationCreateUpdateAppSecurityAssignmentArgs = {
  input: EmployeeAppSecurityAssignmentInput;
};


export type MutationDownloadDocumentsArgs = {
  File: Scalars['String'];
};


export type MutationRemoveEonDocumentsByBatchArgs = {
  BatchDateTime: Scalars['String'];
  Type: Scalars['String'];
};


export type MutationHelpdeskSubjectSubmitArgs = {
  input: HelpdeskSubjectInput;
};


export type MutationHelpdeskSubjectActionArgs = {
  Action: Scalars['String'];
  SubjectID: Scalars['String'];
};


export type MutationSubmitHelpdeskTicketArgs = {
  mode: Scalars['String'];
  input: HelpdeskTicketInput;
  CompanyID: Scalars['String'];
};


export type MutationReassignAssigneeArgs = {
  Assignee: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  HelpdeskTicketID: Scalars['String'];
};


export type MutationUpdateOpenDtArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationWithdrawOpenTicketArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationAttendanceClockingArgs = {
  EmployeeCheckIn: Array<EmployeeCheckIn>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetUserInfoArgs = {
  FromDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetStaffAttendanceArgs = {
  ShiftDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetEmpWorkTimeArgs = {
  Refrence: Array<Scalars['JSON']>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationBlockLeaveInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  IsUpdateAttachment: Scalars['Boolean'];
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveStatusUpdateArgs = {
  Input: BlockLeaveInput;
};


export type MutationReprocessLeaveEntitlementArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  LeaveYear: Scalars['Float'];
};


export type MutationReprocessLeaveEntitlementRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  LeaveYear: Scalars['Float'];
};


export type MutationReprocessLeaveEntitlementBySubscriptionArgs = {
  LeaveYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationPostCarryForwardEncashmentArgs = {
  input: Array<EncashCarryForwardLeaveInput>;
};


export type MutationLeaveRecordsApprovalArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<LeaveApprovalUpdateInput>;
};


export type MutationLeaveRecordsInsertArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
};


export type MutationLeaveRecordsUpdateArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
  leaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentInsertArgs = {
  input: Array<LeaveHeaderInput>;
};


export type MutationLeaveStatusUpdateArgs = {
  ApprovalStatus: ApprovalStatus;
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationLeaveAdjustmentUpdateArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentDeleteArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationPostLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationRevertLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationSaveCompulsoryLeaveArgs = {
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationSaveUpdateCompulsoryLeaveArgs = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationDeleteCompulsoryLeaveArgs = {
  LeaveCompulsoryID: Scalars['String'];
};


export type MutationInsertLeaveCutOffPeriodsArgs = {
  input: GenerateLeaveCutOffPeriodInput;
};


export type MutationSubmitYearEndCarryForwardArgs = {
  leaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationReversePostedYecfRecordsArgs = {
  Year: Scalars['Float'];
  Input: Array<ReversePostedYecfInput>;
};


export type MutationReversePostedLcfeArgs = {
  Year: Scalars['Float'];
  Inputs: Array<ReversePostedLcfeInput>;
};


export type MutationLeaveTakenUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationLeaveTakenDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationLeaveTakenInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationLeaveTakenBulkInsertArgs = {
  LeaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationPostMaxLeaveBalanceForfeitureArgs = {
  LeaveHeaderInputs: Array<LeaveHeaderInput>;
};


export type MutationReverseMaxLeaveBalanceForfeitureArgs = {
  LeaveHeaderIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationLeaveOutOfStateInsertArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOufOfStateUpdateArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOutOfStateDeleteArgs = {
  LeaveOutOfStateID: Scalars['String'];
};


export type MutationAccountMappingInsertArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingUpdateArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingDeleteArgs = {
  GLAccountHeaderID: Scalars['String'];
};


export type MutationAccountMappingAssignmentInsertArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationAccountMappingAssignmentUpdateArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationClaimAccountMappingInsertArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingUpdateArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingDeleteArgs = {
  ClaimAccountHeaderID: Scalars['String'];
};


export type MutationClaimAccountAssignmentInsertArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationClaimAccountAssignmentUpdateArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationCostMappingUpdateArgs = {
  input: Array<CostCenterMappingInput>;
};


export type MutationDeleteProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationFlagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationUnflagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationCreateMobileVersionArgs = {
  Android: Scalars['Boolean'];
  iOS: Scalars['Boolean'];
  Version: Scalars['String'];
};


export type MutationMobileVersionDeletingArgs = {
  MobileVersionID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateStatusArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateDeleteArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyInsertArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencySetupExcelUploadArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationMultiCurrencyExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationMultiCurrencyInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationSubmitOnBehalfPolicyByTypeArgs = {
  input: Array<OnBehalfPolicyInput>;
  UserLogs: Array<UserLogInput>;
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type MutationPopulateAttendanceRecordArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type MutationOvertimeRoundingInsertArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingUpdateArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingDeleteArgs = {
  OvertimeRoundingSettingID: Scalars['String'];
};


export type MutationOvertimeTemplateInsertArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateUpdateArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateDeleteArgs = {
  OvertimeTemplateID: Scalars['String'];
};


export type MutationSubmitAdvancePolicyArgs = {
  input: AdvancePolicyInput;
};


export type MutationDeleteAdvancePolicyArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type MutationAssignAdvancePolicyArgs = {
  AdvancePolicyIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationGenerateAdvanceProcessingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationPostUnpostAdvanceProcessingArgs = {
  IsPosting: Scalars['Boolean'];
  AdvanceProcessingIDs: Array<Scalars['String']>;
};


export type MutationSubmitPayGroupArgs = {
  input: PayGroupInput;
};


export type MutationPayGroupDeleteArgs = {
  PayGroupID: Scalars['String'];
};


export type MutationAssignCompanyPayGroupArgs = {
  input: PayGroupAssignmentInput;
};


export type MutationAssignPayrollCycleToCompanyArgs = {
  input: PayrollCycleAssignmentInput;
};


export type MutationUpdateStatutoryAdjustmentArgs = {
  PayrollDetailInputs?: Maybe<Array<Scalars['JSON']>>;
  PayrollHeaderInput: Scalars['JSON'];
  StatutoryInput: StatutoryAdjustmentInput;
};


export type MutationDeleteStatutoryAdjustmentArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollHistoryArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollProcessArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdatePayrollCalendarArgs = {
  DeferredPayCycles?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  companyID: Scalars['String'];
};


export type MutationGenerateDefaultOrderforAllPayPeriodsArgs = {
  PayPeriodIDs: Array<Scalars['String']>;
  PayGroupID: Scalars['String'];
  companyID: Scalars['String'];
};


export type MutationUpdatePayrollProcessStatusArgs = {
  PayGroupID: Scalars['String'];
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  PayrollCycleStatus: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateMonthlyTransactionByFixedAllowanceArgs = {
  PayrollCycleID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationInsertPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationPayItemInsertArgs = {
  condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
};


export type MutationPayItemUpdateArgs = {
  Condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
  PayItemID: Scalars['String'];
};


export type MutationSalaryBatchArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<CareerLogInput>;
};


export type MutationPayItemDeleteArgs = {
  PayItemID: Scalars['String'];
};


export type MutationRecurringPayInsertArgs = {
  ExcelUpload: Scalars['Boolean'];
  DocumentsInput?: Maybe<DocumentsInput>;
  RecurringPayInput: Array<RecurringPayInput>;
};


export type MutationRecurringPayUpdateArgs = {
  input: RecurringPayInput;
  RecurringPayID: Scalars['String'];
};


export type MutationRecurringPayDeleteArgs = {
  RecurringPayID: Scalars['String'];
};


export type MutationCp159UpdateArgs = {
  CP159Input: Cp159Input;
};


export type MutationOneTimePayDeleteArgs = {
  OneTimePayID: Scalars['String'];
};


export type MutationOneTimePayInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationOneTimePayUpdateArgs = {
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationPublishPaySlipArgs = {
  PublishPaySlipID: Scalars['String'];
};


export type MutationPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationUnPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationDeleteMonthlyTrxArgs = {
  OneTimePayIDs: Array<Scalars['String']>;
};


export type MutationFreezePayrollInsertArgs = {
  input: FreezePayrollInput;
};


export type MutationEmployeeFreezePayrollDeleteArgs = {
  FreezePayrollID: Scalars['String'];
};


export type MutationEmployeeSalaryExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationSalaryExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationSalaryExcelInsertActualArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationMonthlyTransactionExcelUploadArgs = {
  PayGroupID: Scalars['String'];
  PayrollPeriod: Scalars['String'];
  PayrollCycleName: Scalars['String'];
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationMonthlyTransactionExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationMonthlyTransactionExcelInsertActualArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationFixedAllowanceExcelUploadArgs = {
  PayGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationFixedAllowanceExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationFixedAllowanceExcelInsertActualArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  RecurringPayIDsToInactive?: Maybe<Array<Scalars['String']>>;
  FileName: Scalars['String'];
};


export type MutationSubmitEmployeeKpiArgs = {
  Comment?: Maybe<Scalars['String']>;
  IsSubmitByApprover?: Maybe<Scalars['Boolean']>;
  input: EmployeeKpiInput;
};


export type MutationSaveEmployeeKpiInRepoArgs = {
  input: EmployeeKpiRepositoryInput;
};


export type MutationEmployeeKpiRepoDeleteArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type MutationKpiExerciseChangeSuperiorArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseChangeStatusArgs = {
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSubmitArgs = {
  input: KpiExerciseDetailsEditInput;
};


export type MutationKpiExerciseDeleteArgs = {
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseInitiateDraftArgs = {
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type MutationKpiExerciseCopyArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSetupSubmitArgs = {
  input: KpiExerciseSetupInput;
};


export type MutationKpiExerciseSetupCopyArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  KpiExerciseID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupDeleteArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationOrganizationalGoalSubmitArgs = {
  input: OrganizationalGoalInput;
};


export type MutationOrganizationalGoalDeleteArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type MutationOrgKpiPlanningSubmitArgs = {
  input: OrgKpiPlanningInput;
};


export type MutationOrgKpiPlanningDetailSubmitArgs = {
  input: OrgKpiPlanningDetailsInput;
};


export type MutationOrgKpiPlanningDetailsDeleteArgs = {
  OrgKpiPlanningDetailsID: Scalars['String'];
};


export type MutationPerformanceGroupMatrixSubmitArgs = {
  input: PerformanceGroupMatrixInput;
};


export type MutationPerformanceGroupMatrixDeleteArgs = {
  PerformanceGroupMatrixID: Scalars['String'];
};


export type MutationEmployeeReviewUpdateSetupArgs = {
  input: EmployeeReviewSetupInput;
};


export type MutationCopyEmployeeReviewExerciseArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewerID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
};


export type MutationEmployeeReviewFormStatusUpdateArgs = {
  IsSendBack?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  EmployeeReviewIDs: Array<Scalars['String']>;
};


export type MutationSubmitEmployeeReviewFormArgs = {
  Comment?: Maybe<Scalars['String']>;
  input: ReviewFormSubmitInput;
};


export type MutationAddConversationLogArgs = {
  input: ReviewConversationLogInput;
};


export type MutationPeerRegistrationSubmitArgs = {
  input?: Maybe<Array<ReviewPeerRegistrationInput>>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupSubmitArgs = {
  input: ReviewExerciseSetupInput;
};


export type MutationReviewExerciseSetupCopyArgs = {
  KpiAsAtDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewExerciseID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupDeleteArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationPeriodicAllowanceTypeInputArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeUpdateArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeDeleteArgs = {
  PeriodicAllowanceTypeID: Scalars['String'];
};


export type MutationPersonnelCertificateInsertArgs = {
  Input: PersonnelCertificateInput;
};


export type MutationPersonnelCertificateUpdateArgs = {
  Input: PersonnelCertificateInput;
  PersonnelCertificateID: Scalars['String'];
};


export type MutationPersonnelCertificateDeleteArgs = {
  PersonnelCertificateID: Scalars['String'];
};


export type MutationContractInfoInsertArgs = {
  ContractInfoInput: ContractInfoInput;
};


export type MutationContractInfoUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  ContractInfoInput: ContractInfoInput;
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoEndArgs = {
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoRenewalArgs = {
  ContractInfoInput: ContractInfoInput;
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationReportDocDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationContractInfoDeleteArgs = {
  ContractInfoID: Scalars['String'];
};


export type MutationPersonnelEducationInsertArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
};


export type MutationPersonnelEducationUpdateArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelEducationDeleteArgs = {
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelHealthInfoInsertArgs = {
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
};


export type MutationPersonnelHealthInfoUpdateArgs = {
  AttachmentToRemove?: Maybe<Array<DocumentsInput>>;
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
  PersonnelHealthInfoID: Scalars['String'];
};


export type MutationMedicalRecordDeleteArgs = {
  MedicalRecordID: Scalars['String'];
};


export type MutationPersonnelJobHistoryInsertArgs = {
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryInsertByEmployeeArgs = {
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateByEmployeeArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryDeleteArgs = {
  PersonnelJobHistoryID: Scalars['String'];
};


export type MutationPersonnelReferenceInsertArgs = {
  Input: PersonnelReferenceInput;
};


export type MutationPersonnelReferenceUpdateArgs = {
  Input: PersonnelReferenceInput;
  PersonnelReferenceID: Scalars['String'];
};


export type MutationPersonnelReferenceDeleteArgs = {
  PersonnelReferenceID: Scalars['String'];
};


export type MutationUserDefineDetailBulkInsertArgs = {
  UserDefineDetailInput: Array<UserDefineDetailInput>;
};


export type MutationPersonnelInsuranceSchemeBulkInsertArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  PersonnelIDs: Array<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationPersonnelInsuranceSchemeUpdateArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationStaffConfirmationInsertArgs = {
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationUpdateArgs = {
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationCancelConfirmedArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationPersonnelStaffExitCompanyInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationPersonnelStaffExitCompanyUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffExitCompanyCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationProcessStaffMovementArgs = {
  DateToProcess: Scalars['DateTime'];
};


export type MutationStaffPromotionDemotionInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationStaffTransferInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationDeleteProjectExpenseClaimRecordsArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitProjectExpenseClaimForApprovalArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyProjectClaimArgs = {
  input: SubmitProjectClaimRecordsInput;
};


export type MutationApproveProjectExpenseClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationDeleteApprovedProjectExpenseClaimRecordsArgs = {
  Remark: Scalars['String'];
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationPostPeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostPeClaimRecordsInput;
};


export type MutationReversePeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationVerifyApprovedProjectExpenseClaimDetailArgs = {
  input: VerifyApprovedProjectExpenseClaimDetailInput;
};


export type MutationReverseApprovedProjectExpenseClaimRecordsArgs = {
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationProjectExpenseTypeSubmitArgs = {
  input: ProjectExpenseTypeInput;
};


export type MutationProjectExpenseTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectGlAccountMappingSubmitArgs = {
  DetailInput: Array<ProjectGlAccountMappingDetailInput>;
  HeaderInput: ProjectGlAccountMappingInput;
};


export type MutationProjectGlAccountMappingDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectAccountAssignmentInsertArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationProjectAccountAssignmentUpdateArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationDeleteClaimProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PeriodID: Scalars['String'];
};


export type MutationProjectMasterSubmitArgs = {
  input: ProjectMasterInput;
};


export type MutationProjectMasterDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectCostAllocationSubmitArgs = {
  ProjectCostAllocationDetailInput: Array<ProjectCostAllocationDetailInput>;
  ProjectCostAllocationInput: ProjectCostAllocationInput;
};


export type MutationProjectCostAllocationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitApproverMasterArgs = {
  accountName: Scalars['String'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverMasterInput;
};


export type MutationResendConfirmationEmailArgs = {
  accountName: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationApproverConfirmedEmailActionArgs = {
  token: Scalars['String'];
};


export type MutationDeleteApproverMasterArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationDeleteApproverEmployeeListingArgs = {
  ProjectTimesheetPeriodID: Scalars['String'];
};


export type MutationSubmitApproverAssignmentArgs = {
  selectAllEmployee: Scalars['Boolean'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverAssignmentInput;
};


export type MutationSubmitDailyEntryArgs = {
  input: ProjectTimesheetDailyEntryInput;
  ContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  mode: Scalars['String'];
};


export type MutationDeleteDailyEntryArgs = {
  ProjectTimesheetDailyEntryID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetArgs = {
  input: Array<ProjectTimesheetDailyEntryInput>;
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetApprovalArgs = {
  Remarks?: Maybe<Scalars['String']>;
  StatusToChange: WorkflowLogStatus;
  token: Scalars['String'];
};


export type MutationProjectTimesheetReportForApproverSendEmailArgs = {
  input: ProjectTimesheetReportForApproverInput;
  ReportURL?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};


export type MutationRecruitmentExpensesInsertArgs = {
  input: Array<RecruitmentExpensesInput>;
};


export type MutationRecruitmentExpensesDeleteArgs = {
  recruitment_expense_ids: Array<Scalars['String']>;
};


export type MutationELetterTemplateInsertArgs = {
  input: RecruitmentE_LetterInput;
};


export type MutationELetterTemplateUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  ELetterTemplateID: Scalars['String'];
  input: RecruitmentE_LetterInput;
};


export type MutationELetterTemplateUpdateDefaultArgs = {
  PreofferChecklistID: Scalars['String'];
  ELetterTemplateID: Scalars['String'];
};


export type MutationELetterTemplateDeleteArgs = {
  ELetterTemplateID: Scalars['String'];
};


export type MutationRec_EApplicationFormSetupInsertArgs = {
  input: Array<Rec_EApplicationFormSetupInput>;
};


export type MutationPreOfferChecklistInsertArgs = {
  input: RecruitmentPre_OfferChecklistInput;
};


export type MutationPreOfferChecklistUpdateArgs = {
  input: Array<RecruitmentPre_OfferChecklistInput>;
};


export type MutationPreOfferChecklistSequenceUpdateArgs = {
  PreOfferChecklistIDs: Array<Scalars['String']>;
};


export type MutationPreOfferChecklistDeleteArgs = {
  PreOfferChecklistID: Scalars['String'];
};


export type MutationTalentPoolAdditionalUserInsertArgs = {
  input: Array<Rec_TalentPoolUserFieldsInput>;
};


export type MutationDeclineReasonInsertArgs = {
  input: Array<Rec_DeclineReasonInput>;
};


export type MutationRec_PrehiringFormSetupInsertArgs = {
  input: Array<Rec_PrehiringFormSetupInput>;
};


export type MutationJobPortalSetupInsertArgs = {
  input: Rec_JobPortalSetupInput;
};


export type MutationJobPortalSetupUpdateArgs = {
  input: Rec_JobPortalSetupInput;
  JobPortalID: Scalars['String'];
};


export type MutationJobPortalSetupDeleteArgs = {
  JobPortalID: Scalars['String'];
};


export type MutationJobPortalTalentInsertArgs = {
  JobPostingID: Scalars['String'];
  portalToken: Scalars['String'];
  input: ContactInput;
};


export type MutationEApplicationInsertArgs = {
  JobPostingID?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  documentsInput: Array<DocumentsInput>;
  referenceInput: Array<PersonnelReferenceInput>;
  languageInput: Array<PersonnelLanguageInput>;
  empHistoryInput: Array<PersonnelJobHistoryInput>;
  membershipInput: Array<PersonnelMembershipInput>;
  educationInput: Array<PersonnelEducationInsertInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
  userID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationDataUpdateEApplicationInsertArgs = {
  vaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  medicalRecordInput: Array<MedicalRecordInput>;
  attachmentToRemove?: Maybe<Array<DocumentsInput>>;
  healthInput: PersonnelHealthInfoInput;
  statutoryInput?: Maybe<Rec_TalentStatutoryInput>;
  dependentInput: Array<EmployeeDependentsInput>;
  documentsInput: Array<DocumentsInput>;
  referenceInput: Array<PersonnelReferenceInput>;
  empHistoryInput: Array<PersonnelJobHistoryInput>;
  certificateInput: Array<PersonnelCertificateInput>;
  educationInput: Array<PersonnelEducationInsertInput>;
  employeeInput?: Maybe<EmployeeInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
  userID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyRecruitmentRequisitionArgs = {
  input: Rec_RequisitionInput;
};


export type MutationUpdateMyRecRequisitionApprovalStatusArgs = {
  input: Rec_RequisitionInput;
};


export type MutationMyApprovedJobVacancyApplicantStatusUpdateArgs = {
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationSubmitRecruitmentRequisitionArgs = {
  input: Rec_RequisitionInput;
};


export type MutationDeleteRecRequisitionArgs = {
  RecRequisitionID: Scalars['String'];
};


export type MutationUpdateRecRequisitionApprovalStatusArgs = {
  input: Rec_RequisitionInput;
};


export type MutationJobVacancyInsertArgs = {
  recExpenseInput?: Maybe<Array<Rec_JobVacancyExpenseInput>>;
  recSourceInput?: Maybe<Array<Rec_JobVacancySourceInput>>;
  jobVacancyInput: Rec_JobVacancyInput;
};


export type MutationJobVacancyStatusUpdateArgs = {
  input: JobVacancyStatusLog;
  JobVacancyID: Scalars['String'];
};


export type MutationSubmitInterviewScheduleArgs = {
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_InterviewScheduleInput;
};


export type MutationUpdateInterviewResultArgs = {
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_InterviewerInput;
};


export type MutationCompleteInterviewArgs = {
  input: Rec_ApplicantInput;
};


export type MutationReopenInterviewArgs = {
  rec_applicant_id: Scalars['String'];
};


export type MutationDeleteInterviewScheduleArgs = {
  rec_interview_schedule_id: Scalars['String'];
};


export type MutationJobVacancyDeleteArgs = {
  JobVacancyID: Scalars['String'];
};


export type MutationJobPostingInsertArgs = {
  jobPostingInput: Rec_JobPostingInput;
};


export type MutationJobPostingStatusUpdateArgs = {
  input: Rec_JobPostingUpdateStatus;
  jobPostingId: Scalars['String'];
};


export type MutationJobPostingUpdateArgs = {
  jobPostingInput: Rec_JobPostingUpdate;
  jobPostingId: Scalars['String'];
};


export type MutationJobPostingDeleteArgs = {
  JobPostingID: Scalars['String'];
};


export type MutationApplicantStatusUpdateArgs = {
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationFinalSelectionStatusUpdateArgs = {
  FinalSelectionStatus?: Maybe<RecruitmentFinalSelectionStatus>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationOfferProposalUpdateArgs = {
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_OfferInput;
};


export type MutationOfferProposalInsertArgs = {
  input: Rec_OfferInput;
};


export type MutationOfferApprovalUpdateArgs = {
  input: Rec_OfferInput;
};


export type MutationOfferContentInsertArgs = {
  offerChecklistSnapshot?: Maybe<Array<OfferChecklistSnapshot>>;
  offerContentInput: Rec_OfferContentInput;
};


export type MutationFinalizeOfferArgs = {
  offerContentInput: Array<Rec_OfferContentInput>;
};


export type MutationUpdateOfferResponseArgs = {
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  input: Rec_OfferInput;
};


export type MutationSendLetterArgs = {
  Email: Scalars['String'];
  FullName: Scalars['String'];
  OfferProcessID: Scalars['String'];
  OfferChecklistSnapshot: Array<OfferChecklistSnapshot>;
  OfferContent: Array<Rec_OfferContentInput>;
  sendLog: SendLog;
};


export type MutationInsertAcceptedPreHiringArgs = {
  hiringInput: AcceptedPreHiringInput;
};


export type MutationSendHiringEmailArgs = {
  emailRemark?: Maybe<Scalars['String']>;
  dataUpdateStatusLog: DataUpdateStatusLog;
  HiringIDs: Array<Scalars['String']>;
};


export type MutationInsertQuickHiringArgs = {
  quickHiringInput: QuickHiringInput;
};


export type MutationUpdateQuickHiringArgs = {
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  quickHiringInput: QuickHiringInput;
  quickHiringId: Scalars['String'];
};


export type MutationDeleteQuickHiringArgs = {
  talentStatusLog: TalentStatusLog;
  quickHiringId: Scalars['String'];
};


export type MutationCancelPreHiringArgs = {
  cancelRemark?: Maybe<Scalars['String']>;
  hiringId: Scalars['String'];
};


export type MutationApprovePreHiringArgs = {
  talentStatusLog: TalentStatusLog;
  dataUpdateStatusLog: DataUpdateStatusLog;
  hiringIds: Array<Scalars['String']>;
};


export type MutationDataUpdateStatusLogArgs = {
  input: DataUpdateStatusLog;
  hiringId: Scalars['String'];
};


export type MutationDeclineNewHiringProcessArgs = {
  declineRemarkVoice?: Maybe<DocumentsInput>;
  declineRemark?: Maybe<Scalars['String']>;
  declineReason: Scalars['String'];
  declineDate: Scalars['DateTime'];
  talentStatusLog: TalentStatusLog;
  hiringId: Scalars['String'];
};


export type MutationOnboardNewHireProcessArgs = {
  input: OnboardNewHireInput;
  hiringId: Scalars['String'];
};


export type MutationTalentPoolDeleteArgs = {
  TalentPoolID: Scalars['String'];
};


export type MutationTalentPersonalInsertArgs = {
  employeeInput?: Maybe<EmployeeInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
};


export type MutationTalentEducationInsertArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelEducationInsertInput;
};


export type MutationTalentHealthInfoInsertArgs = {
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  vaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  medicalRecordInput: Array<MedicalRecordInput>;
  attachmentToRemove?: Maybe<Array<DocumentsInput>>;
  healthInput: PersonnelHealthInfoInput;
};


export type MutationTalentStatutoryInsertArgs = {
  TalentStatutoryID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: Rec_TalentStatutoryInput;
};


export type MutationTalentCertificateInsertArgs = {
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelCertificateInput;
};


export type MutationTalentMembershipInsertArgs = {
  PersonnelMembershipID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelMembershipInput;
};


export type MutationTalentEmpHistoryInsertArgs = {
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelJobHistoryInput;
};


export type MutationTalentReferenceInsertArgs = {
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelReferenceInput;
};


export type MutationTalentDependentInsertArgs = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  contactInput: ContactInput;
  dependentInput: EmployeeDependentsInput;
};


export type MutationTalentLanguageInsertArgs = {
  PersonnelLanguageID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: Array<PersonnelLanguageInput>;
};


export type MutationTalentDocInsertArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: DocumentsInput;
};


export type MutationTalentInfoDeleteArgs = {
  type: Scalars['String'];
  typeID: Scalars['String'];
};


export type MutationAddTalentToPrescreenArgs = {
  talentStatusLog: TalentStatusLog;
  applicantLog: ApplicantStatusLog;
  JobVacancyIDs: Array<Scalars['String']>;
  TalentPoolID: Scalars['String'];
};


export type MutationSaveTalentSearchArgs = {
  input: Rec_TalentSearchInput;
};


export type MutationDeleteTalentSearchArgs = {
  rec_talent_search_id: Scalars['String'];
};


export type MutationUpdateTalentStatusArgs = {
  talentStatusLog: TalentStatusLog;
  talentStatus: RecruitmentTalentStatus;
  talentPoolID: Scalars['String'];
};


export type MutationInsertTalentInvitationArgs = {
  sourceID?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  talentInvitationLog: TalentInvitationStatusLog;
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSendTalentInvitationEmailArgs = {
  emailRemark?: Maybe<Scalars['String']>;
  talentInvitationLog: TalentInvitationStatusLog;
  companyName: Scalars['String'];
  talentInvitationIDs: Array<Scalars['String']>;
};


export type MutationCancelTalentInvitationArgs = {
  cancelRemark?: Maybe<Scalars['String']>;
  talentInvitationID: Scalars['String'];
};


export type MutationApproveTalentInvitationArgs = {
  talentStatusLog: TalentStatusLog;
  talentInvitationLog: TalentInvitationStatusLog;
  talentInvitationIDs: Array<Scalars['String']>;
};


export type MutationApprovalPolicyMatrixInsertArgs = {
  input: ApprovalPolicyMatrixInput;
};


export type MutationApprovalPolicyMatrixDeleteArgs = {
  ApprovalPolicyMatrixID: Scalars['String'];
};


export type MutationDeleteApprovedRequisitionArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: EmpRequisitionInput;
};


export type MutationRequisitionManagementApprovalUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<RequisitionUpdateInput>;
};


export type MutationRequisitionInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: RequisitionInput;
};


export type MutationRequisitionUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  RequisitionInput: RequisitionInput;
};


export type MutationRequisitionDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
};


export type MutationRequisitionSubmitArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type MutationSubmitRequisitionTypeArgs = {
  input: RequisitionTypeInput;
};


export type MutationInvoiceRpaArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationClaimParameterInsertArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsInsertArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
};


export type MutationMileageTableInsertArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
};


export type MutationVehicleRateInsertArgs = {
  ClaimVehicleRateInput: Array<ClaimVehicleRateInput>;
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsUpdateArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimUserDefineFieldsDuplicateArgs = {
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableUpdateArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimParameterUpdateArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimParameterDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationPersonnelParameterInsertArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterUpdateArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterDeleteArgs = {
  PersonnelParameterID: Scalars['String'];
};


export type MutationReportTemplateInsertArgs = {
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationReportTemplateAssignmentArgs = {
  DocumentsInput: DocumentsInput;
  CompanyID: Scalars['String'];
  input: ReportTemplateInput;
};


export type MutationReportTemplateInactiveArgs = {
  ReportTemplateID: Scalars['String'];
};


export type MutationReportTemplateUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationSubmitStaffMovementRequestArgs = {
  input: StaffMovementRequestInput;
};


export type MutationEaPartFInsertArgs = {
  input: EaPartFInput;
};


export type MutationEaPartFUpdateArgs = {
  EAPartFID: Scalars['String'];
  input: EaPartFInput;
};


export type MutationEaPartFDeleteArgs = {
  EAPartFID: Scalars['String'];
};


export type MutationEaPartFCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationEisPolicyUpdateArgs = {
  EISPolicyID: Scalars['String'];
  input: EisPolicyInput;
};


export type MutationEpfPolicyUpdateArgs = {
  EPFPolicyDetailInput: Array<EpfPolicyDetailInput>;
  EPFPolicyInput: EpfPolicyInput;
  EPFPolicyID: Scalars['String'];
};


export type MutationFormItemRatingSubmitArgs = {
  input: FormItemRatingInput;
};


export type MutationFormItemRatingDeleteArgs = {
  FormItemRatingID: Scalars['String'];
};


export type MutationKpiCategorySubmitArgs = {
  input: KpiCategoryInput;
};


export type MutationKpiCategoryDeleteArgs = {
  KpiCategoryID: Scalars['String'];
};


export type MutationKpiPredefinedItemSubmitArgs = {
  input: KpiPredefinedItemInput;
};


export type MutationKpiPredefinedItemUpdateStatusArgs = {
  input: KpiActiveInactiveInput;
};


export type MutationKpiPredefinedItemDeleteArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type MutationKpiPredefinedTemplateSubmitArgs = {
  input: KpiPredefinedTemplateInput;
};


export type MutationKpiPredefinedTemplateDeleteArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type MutationReviewFormTemplateSubmitArgs = {
  input: ReviewFormTemplateInput;
};


export type MutationReviewFormTemplateDeleteArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type MutationReviewGradingTableSubmitArgs = {
  input: ReviewGradingTableInput;
};


export type MutationReviewGradingTableDeleteArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type MutationReviewGroupSubmitArgs = {
  input: ReviewGroupInput;
};


export type MutationReviewGroupDeleteArgs = {
  ReviewGroupID: Scalars['String'];
};


export type MutationReviewItemSubmitArgs = {
  input: ReviewItemInput;
};


export type MutationReviewItemDeleteArgs = {
  ReviewItemID: Scalars['String'];
};


export type MutationReviewPolicySubmitArgs = {
  input: ReviewPolicyInput;
};


export type MutationReviewPolicyDeleteArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type MutationReviewProcessPolicySubmitArgs = {
  input: ReviewProcessPolicyInput;
};


export type MutationReviewProcessPolicyDeleteArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type MutationReviewTemplateSubmitArgs = {
  input: ReviewTemplateInput;
};


export type MutationReviewTemplateDeleteArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type MutationRewardGroupSubmitArgs = {
  input: RewardGroupInput;
};


export type MutationRewardGroupDeleteArgs = {
  RewardGroupID: Scalars['String'];
};


export type MutationPersonnelSecurityPolicySubmitArgs = {
  input: PersonnelSecurityPolicyInput;
};


export type MutationHolidayBulkInsertArgs = {
  HolidayInput: Array<HolidayInput>;
};


export type MutationHolidayBulkDeleteArgs = {
  HolidayID: Array<Scalars['String']>;
};


export type MutationHolidayBulkUpdateArgs = {
  HolidayID: Array<Scalars['String']>;
  HolidayInput: Array<HolidayInput>;
};


export type MutationSocsoPolicyUpdateArgs = {
  SocsoPolicyID: Scalars['String'];
  input: SocsoPolicyInput;
};


export type MutationTaxReliefInsertArgs = {
  input: TaxReliefInput;
};


export type MutationTaxReliefUpdateArgs = {
  TaxReliefID: Scalars['String'];
  input: TaxReliefInput;
};


export type MutationTaxReliefDeleteArgs = {
  TaxReliefID: Scalars['String'];
};


export type MutationTaxReliefCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationMonthlyAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationAttendanceProcessingRedirectArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationUpdateAdjustedValueArgs = {
  input: AttendanceCalculationInput;
};


export type MutationDeleteAutomatedClockDataImportArgs = {
  AutomatedClockDataImportID: Scalars['String'];
};


export type MutationCreateUpdateAutomatedClockDataImportArgs = {
  input: AutomatedClockDataImportInput;
};


export type MutationCreateUpdateBeaconDeviceArgs = {
  input: BluetoothBeaconDevicesInput;
};


export type MutationCreateChangeShiftArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ChangeShiftInput: ChangeShiftInput;
};


export type MutationUpdateChangeShiftArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  RejectReason?: Maybe<Scalars['String']>;
  CSStatus: Scalars['String'];
  Input: Array<ChangeShiftInput>;
};


export type MutationDeleteChangeShiftArgs = {
  ChangeShiftID: Scalars['String'];
};


export type MutationUpdateChangeShiftSubmitArgs = {
  input: ChangeShiftInput;
};


export type MutationCreateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
};


export type MutationUpdateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
  DailyAllowanceID: Scalars['String'];
};


export type MutationDeleteDailyAllowanceArgs = {
  DailyAllowanceID: Scalars['String'];
};


export type MutationCreateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
};


export type MutationUpdateDeductionPolicyStatusArgs = {
  Status: Status;
  DeductionPolicyID: Scalars['String'];
};


export type MutationUpdateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
  DeductionPolicyID: Scalars['String'];
};


export type MutationPostEarnedLeaveArgs = {
  Remarks?: Maybe<Scalars['String']>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationReverseEarnedLeaveArgs = {
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationCreateJobGradePolicyMatrixArgs = {
  input: JobGradePolicyMatrixInput;
};


export type MutationCreateMobileCheckInArgs = {
  input: MobileCheckInInput;
};


export type MutationUpdateMobileCheckInArgs = {
  input: MobileCheckInInput;
  MobileCheckInID: Scalars['String'];
};


export type MutationDeleteMobileCheckInArgs = {
  MobileCheckInID: Scalars['String'];
};


export type MutationInsertUpdateLocationRadiusSettingArgs = {
  MobileCheckInID?: Maybe<Scalars['String']>;
  Radius: Scalars['Float'];
};


export type MutationCreateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
};


export type MutationUpdateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationDeleteMonthlyAllowanceArgs = {
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationCreateOvertimeClaimTableArgs = {
  input: OvertimeClaimTableInput;
};


export type MutationUpdateOvertimeClaimTableStatusArgs = {
  Status: Status;
  OvertimeClaimTableID: Scalars['String'];
};


export type MutationOtReasonUpdateArgs = {
  OvertimeReasonID: Scalars['String'];
  Input: OvertimeReasonInput;
};


export type MutationOtReasonDeleteArgs = {
  OvertimeReasonID: Scalars['String'];
};


export type MutationOtReasonStatusArgs = {
  OvertimeReasonID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationOtReasonInsertArgs = {
  Input: OvertimeReasonInput;
};


export type MutationOvertimePolicyInsertArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyUpdateArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyDeleteArgs = {
  OvertimePolicyID: Scalars['String'];
};


export type MutationCreatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
};


export type MutationUpdatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
  PeriodicDeductionID: Scalars['String'];
};


export type MutationDeletePeriodicDeductionArgs = {
  PeriodicDeductionID: Scalars['String'];
};


export type MutationCreateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
};


export type MutationUpdateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
  AllowanceDeductionID: Scalars['String'];
};


export type MutationDeleteAllowanceDeductionArgs = {
  AllowanceDeductionID: Scalars['String'];
};


export type MutationCreateOtRoundingArgs = {
  input: OtRoundingInput;
};


export type MutationUpdateOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
  input: OtRoundingInput;
};


export type MutationDeleteOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
};


export type MutationShiftSettingInsertArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
};


export type MutationShiftSetupUpdateArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
  ShiftID: Scalars['String'];
};


export type MutationShiftSetupDeleteArgs = {
  ShiftID: Scalars['String'];
};


export type MutationCreateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
};


export type MutationUpdateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupID: Scalars['String'];
};


export type MutationUpdateShiftGroupStatusArgs = {
  Status: Status;
  ShiftGroupID: Scalars['String'];
};


export type MutationSubmitShiftGroupPatternArgs = {
  input: ShiftGroupPatternInput;
};


export type MutationDeleteShiftGroupPatternArgs = {
  ShiftGroupPatternID: Scalars['String'];
};


export type MutationLogShiftGroupEmployeeAssignmentHistoryArgs = {
  inputs: Array<ShiftGroupEmployeeAssignmentLogInput>;
};


export type MutationUpdateShiftGroupEmployeeAssignmentHistoryArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  AssignmentLogID: Scalars['String'];
};


export type MutationDeleteShiftGroupEmployeeAssignmentHistoryArgs = {
  AssignmentLogID: Scalars['String'];
};


export type MutationCreateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
};


export type MutationUpdateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternID: Scalars['String'];
};


export type MutationDeleteShiftPatternArgs = {
  ShiftPatternID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyUpdateArgs = {
  UserDefineEPFPolicyDetailInput: Array<UserDefineEpfPolicyDetailInput>;
  UserDefineEPFPolicyInput: UserDefineEpfPolicyInput;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
};


export type MutationUserDefineEpfPolicyDeleteArgs = {
  UserDefineEPFPolicyID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyConditionInsertOrUpdateArgs = {
  UserDefineEPFPolicyMatrixInput: UserDefineEpfPolicyMatrixInput;
};


export type MutationUserDefineEpfPolicyConditionDeleteArgs = {
  UserDefineEPFPolicyMatrixID: Scalars['String'];
};


export type MutationUpdateTemplateLibraryAssignmentArgs = {
  input: TemplateLibraryAssignmentInput;
};


export type MutationCreateTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationOvertimeSettingUpdateArgs = {
  CompanyInput?: Maybe<CompanyInput>;
  CompanyID?: Maybe<Scalars['String']>;
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentForTmsShiftGroupArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentv2Args = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationDeleteTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationShiftGroupAssignmentArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
};


export type MutationDailyTypeConditionUpdateArgs = {
  DailyTemplateInput: DailyTemplateInput;
};


export type MutationDailyTypeConditionDeleteArgs = {
  DailyTemplateID: Scalars['String'];
};


export type MutationDeductionTypeConditionUpdateArgs = {
  DeductionTemplateInput: DeductionTemplateInput;
};


export type MutationDeductionTypeConditionDeleteArgs = {
  DeductionTemplateID: Scalars['String'];
};


export type MutationTmsTypeConditionUpdateArgs = {
  TimeAttendanceTemplateInput: TimeAttendanceTemplateInput;
};


export type MutationTmsTypeConditionDeleteArgs = {
  TMSTemplateID: Scalars['String'];
};


export type MutationTmsotConditionTemplateLibraryUpdateArgs = {
  CompanyID: Scalars['String'];
  TMSOTConditionInput: Array<TmsotConditionInput>;
};


export type MutationTmsTimeOffConditionUpdateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  TMSTimeOffConditionInput: Array<TmsTimeOffConditionInput>;
};


export type MutationMonthlyTypeConditionUpdateArgs = {
  MonthlyTemplateInput: MonthlyTemplateInput;
};


export type MutationMonthlyTypeConditionDeleteArgs = {
  MonthlyTemplateID: Scalars['String'];
};


export type MutationTimeOffInsertArgs = {
  input: TimeOffInput;
};


export type MutationTimeOffUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<TimeOffInput>;
};


export type MutationTimeOffDeleteArgs = {
  TimeOffID: Scalars['String'];
};


export type MutationCreateTimeOffArgs = {
  input: TimeOffPolicyInput;
};


export type MutationTimeOffReasonInputArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonUpdateArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonDeleteArgs = {
  TimeOffReasonID: Scalars['String'];
};


export type MutationTimeOffSettingInsertArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingUpdateArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingDeleteArgs = {
  TimeOffSettingID: Scalars['String'];
};


export type MutationTsAccountTypeInsertArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeUpdateArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTsAccountCodeInsertArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeUpdateArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationStaffTimeSheetInsertArgs = {
  StaffTimesheetInput: StaffTimesheetInput;
};


export type MutationStaffTimesheetDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationToDoStatusUpdateArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  RecruitmentHiringIDs?: Maybe<Array<TrxIDs>>;
  RecruitmentOfferIDs?: Maybe<Array<TrxIDs>>;
  RecruitmentRequisitionIDs?: Maybe<Array<TrxIDs>>;
  AttendanceCorrectionHeaderIDs?: Maybe<Array<TrxIDs>>;
  TrainingWithdrawalIDs?: Maybe<Array<TrxIDs>>;
  TrainingSelfRegistrationIDs?: Maybe<Array<TrxIDs>>;
  TrainingRequestIDs?: Maybe<Array<TrxIDs>>;
  TrainingNominationIDs?: Maybe<Array<TrxIDs>>;
  DataChangesIDs?: Maybe<Array<TrxIDs>>;
  ProjectExpenseClaimHeaderIDs: Array<TrxIDs>;
  ChangeShiftIDs: Array<TrxIDs>;
  TimeOffIDs: Array<TrxIDs>;
  OvertimeIDs: Array<TrxIDs>;
  ClaimHeaderIDs: Array<TrxIDs>;
  RequisitionIDs: Array<TrxIDs>;
  LeaveHeaderIDs: Array<TrxIDs>;
  EmployeeID: Scalars['String'];
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationCreateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationUpdateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationCompulsaryCourseByJobGradeSubmitArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type MutationCourseClassificationBulkInsertArgs = {
  input: Array<CourseClassificationInput>;
};


export type MutationCourseClassificationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTrainingCourseSubmitArgs = {
  input: TrainingCourseInput;
};


export type MutationTrainingCourseDeleteArgs = {
  TrainingCourseID: Scalars['String'];
};


export type MutationAuthorisedTrainingProviderSubmitArgs = {
  input: AuthorisedTrainingProviderInput;
};


export type MutationTrainingCostItemBulkInsertArgs = {
  input: Array<TrainingCostItemInput>;
};


export type MutationTrainingCostItemDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTraineeEvalSubmitArgs = {
  input: TraineeEvalInput;
};


export type MutationSupervisorEvalSubmitArgs = {
  input: SupervisorEvalInput;
};


export type MutationCourseEvalSubmitArgs = {
  input: CourseEvalInput;
};


export type MutationTrainerEvalSubmitArgs = {
  input: TrainerEvalInput;
};


export type MutationScheduleTrainingSubmitArgs = {
  input: ScheduleTrainingInput;
};


export type MutationScheduleTableSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingScheduleTableInput>;
};


export type MutationScheduleTrainingDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationScheduleTrainingCancelArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationRegisteredTraineeInsertArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationRegisteredTraineeDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationTrainingBudgetInputBulkInsertArgs = {
  input: Array<TrainingBudgetInput>;
};


export type MutationTrainingCostSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingCostInput>;
};


export type MutationCostAllocationSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<CostAllocationInput>;
};


export type MutationHrdfClaimSubmitArgs = {
  input: HrdfClaimInput;
};


export type MutationSubmitBudgetGroupMatrixArgs = {
  input: BudgetGroupMatrixInput;
};


export type MutationDeleteBudgetGroupMatrixArgs = {
  BudgetGroupMatrixID: Scalars['String'];
};


export type MutationSubmitTargetGroupMatrixArgs = {
  input: TargetGroupMatrixInput;
};


export type MutationDeleteTargetGroupMatrixArgs = {
  TargetGroupMatrixID: Scalars['String'];
};


export type MutationSubmitApprovalPolicyMatrixArgs = {
  input: ApprovalPolicyMatrixInput;
};


export type MutationDeleteApprovalPolicyMatrixArgs = {
  ApprovalPolicyMatrixID: Scalars['String'];
};


export type MutationIsMaintenanceBudgetPeriodOverlapArgs = {
  TrainingBudgetID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['String'];
  PeriodFrom: Scalars['String'];
};


export type MutationSubmitMaintenanceBudgetArgs = {
  input: MaintenanceBudgetInput;
};


export type MutationDeleteMaintenanceBudgetArgs = {
  TrainingBudgetID: Scalars['String'];
};


export type MutationIsMaintenanceTargetHoursPeriodOverlapArgs = {
  TrainingTargetHoursID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['String'];
  PeriodFrom: Scalars['String'];
};


export type MutationSubmitMaintenanceTargetHoursArgs = {
  input: MaintenanceTargetHoursInput;
};


export type MutationDeleteMaintenanceTargetHoursArgs = {
  TrainingTargetHoursID: Scalars['String'];
};


export type MutationSubmitMaintenanceCourseArgs = {
  input: MaintenanceCourseInput;
};


export type MutationSubmitTrainingRequestArgs = {
  input: TrainingRequestInput;
};


export type MutationDeleteTrainingRequestArgs = {
  TrainingRequestID: Scalars['String'];
};


export type MutationWithdrawTrainingRequestArgs = {
  Remarks?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  EmployeeTrainingRequestID: Scalars['String'];
};


export type MutationSubmitScheduledTrainingArgs = {
  input: ScheduledTrainingInput;
};


export type MutationDeleteScheduledTrainingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateScheduledTrainingEventStatusArgs = {
  Status: Event_Status;
  comment?: Maybe<Scalars['String']>;
  AdminEmployeeNo?: Maybe<Scalars['String']>;
  AdminEmployeeName?: Maybe<Scalars['String']>;
  AdminEmployeeID?: Maybe<Scalars['String']>;
  AdminContactID?: Maybe<Scalars['String']>;
  AccountName: Scalars['String'];
  AdminUserID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationSubmitAdminTrainingRegistrationArgs = {
  NominatorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateEmployeeStatusAndNominatorArgs = {
  ParticipationStatus?: Maybe<Participation_Status>;
  NominatorID?: Maybe<Scalars['String']>;
  EmployeeScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateEmployeeScheduledTrainingStatusArgs = {
  Status: Participation_Status;
  ScheduledTrainingEmployeeID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingRegistrationArgs = {
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
  ScheduledTrainingEmployeeID: Scalars['String'];
};


export type MutationSubmitVenueInfoArgs = {
  ScheduledTrainingData: Scalars['JSON'];
  input: ScheduledTrainingVenueInput;
};


export type MutationNotifyTraineeArgs = {
  SelectedEmployeeIDs: Array<Scalars['String']>;
  Attachment?: Maybe<DocumentsInput>;
  EmailInput: EmailTemplateInput;
  ScheduledTrainingID: Scalars['String'];
  AccountName: Scalars['String'];
};


export type MutationSubmitTrainingAttendanceArgs = {
  Remarks?: Maybe<Scalars['String']>;
  AdjustedCheckOut?: Maybe<Scalars['String']>;
  AdjustedCheckIn?: Maybe<Scalars['String']>;
  ScheduledTrainingTimetableID?: Maybe<Scalars['String']>;
  ScheduledTrainingEmployeeID?: Maybe<Scalars['String']>;
  TrainingAttendanceID?: Maybe<Scalars['String']>;
};


export type MutationFinalizedAttendanceArgs = {
  input?: Maybe<Array<NewTrainingAttendanceInput>>;
};


export type MutationGenerateTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ParticipaionStatus: Array<Participation_Status>;
  ScheduledTrainingSurveyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationInitiateTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationIDs: Array<Scalars['String']>;
};


export type MutationSubmitSurveyFormAnswerArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  SurveyAnswerInput: Array<EmployeeEvaluationAnswerInput>;
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationUploadTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  Attachments: Array<DocumentsInput>;
  Score: Scalars['Float'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationRevertDraftTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationClearRevertDraftTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationDeleteTrainingEvaluationArgs = {
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationSubmitScheduledTrainingExpenseArgs = {
  EmployeeInput?: Maybe<Array<Scalars['JSON']>>;
  input: Array<ScheduledTrainingExpenseInput>;
  ScheduledTrainingID: Scalars['String'];
};


export type MutationSubmitHrdcClaimArgs = {
  input: HrdcClaimInput;
};


export type MutationUpdateHrdcClaimStatusArgs = {
  ApprovalStatus: Approval_Status;
  Remarks?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
  HRDCClaimID: Scalars['String'];
};


export type MutationDeleteHrdcClaimArgs = {
  HRDCClaimID: Scalars['String'];
};


export type MutationEmployeeSubmitTrainingRequestArgs = {
  input: TrainingRequestInput;
};


export type MutationEmployeeDeleteTrainingRequestArgs = {
  TrainingRequestID: Scalars['String'];
};


export type MutationEmployeeCancelTrainingRequestArgs = {
  Remarks?: Maybe<Scalars['String']>;
  EmployeeTrainingRequestID: Scalars['String'];
};


export type MutationTrainingCheckInArgs = {
  input: TrainingCheckInInput;
};


export type MutationWithdrawSubordinateTrainingScheduledArgs = {
  Voice_Input?: Maybe<DocumentsInput>;
  Withdraw_Remarks?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationApplyTrainingEventArgs = {
  IsFull?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationNominateSubordinateArgs = {
  Remarks?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationWithdrawSubordinateArgs = {
  EmployeeID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationSubmitCourseCategoryArgs = {
  input: Array<CourseCategoryInput>;
};


export type MutationDeleteCourseCategoryArgs = {
  course_category_ids: Array<Scalars['String']>;
};


export type MutationSubmitTrainingCourseLibraryArgs = {
  input: TrainingCourseLibraryInput;
};


export type MutationDeleteTrainingCourseLibraryArgs = {
  training_course_library_id: Scalars['String'];
};


export type MutationSubmitTrainingProviderArgs = {
  input: TrainingProviderInput;
};


export type MutationDeleteTrainingProviderArgs = {
  training_provider_id: Scalars['String'];
};


export type MutationSubmitSurveyTypeArgs = {
  input: SurveyTypeInput;
};


export type MutationDeleteSurveyTypeArgs = {
  survey_type_id: Scalars['String'];
};


export type MutationSubmitSurveyItemArgs = {
  input: SurveyItemInput;
};


export type MutationDeleteSurveyItemArgs = {
  survey_item_id: Scalars['String'];
};


export type MutationSubmitSurveyFormArgs = {
  Training_Evaluation?: Maybe<Array<Training_Evaluation>>;
  input: SurveyFormInput;
};


export type MutationDeleteSurveyFormArgs = {
  survey_form_id: Scalars['String'];
};


export type MutationSubmitTrainingExpenseArgs = {
  input: Array<TrainingExpenseInput>;
};


export type MutationDeleteTrainingExpenseArgs = {
  training_expense_ids: Array<Scalars['String']>;
};


export type MutationSubmitBudgetGroupArgs = {
  input: Array<BudgetGroupInput>;
};


export type MutationDeleteBudgetGroupArgs = {
  budget_group_ids: Array<Scalars['String']>;
};


export type MutationSubmitTargetGroupArgs = {
  input: Array<TargetGroupInput>;
};


export type MutationDeleteTargetGroupArgs = {
  target_group_ids: Array<Scalars['String']>;
};


export type MutationSubmitTrainerProfileArgs = {
  input: TrainerProfileInput;
};


export type MutationDeleteTrainerProfileArgs = {
  TrainerProfileID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationCreateMileageLogArgs = {
  MileageLogInput: MileageLogInput;
};


export type MutationUpdateMileageLogArgs = {
  MileageLogID: Scalars['String'];
  input: MileageLogInput;
};


export type MutationDeleteMileageLogItemArgs = {
  MileageLogID: Scalars['String'];
};


export type MutationAllowancePolicyInsertArgs = {
  input: AllowanceBenefitInput;
};


export type MutationAllowancePolicyUpdateArgs = {
  AllowanceBenefitID: Scalars['String'];
  input: AllowanceBenefitInput;
};


export type MutationAllowanceEntitlementInsertArgs = {
  input: AllowanceItemInput;
};


export type MutationAllowanceEntitlementUpdateArgs = {
  AllowanceItemID: Scalars['String'];
  input: AllowanceItemInput;
};


export type MutationUpdateLastProcessArgs = {
  IsLastProcess: Scalars['Boolean'];
  PayrollCyclePeriodID: Scalars['String'];
};


export type MutationPayProcessInsertArgs = {
  input: PayrollDetailInput;
};


export type MutationEmployeeAppLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  accountName: Scalars['String'];
  input: UserInput;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  accountID: Scalars['String'];
  accountName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  companyID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationRedirectDomainTokenArgs = {
  UserID: Scalars['String'];
};


export type MutationLoginArgs = {
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationChangeUserPasswordArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationForgotEmployeePasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetEmployeePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationUnblockUserArgs = {
  accountID: Scalars['String'];
  input: PasswordInfo;
};


export type MutationUserActionStatusArgs = {
  accountID: Scalars['String'];
  input: ActionStatus;
};


export type MutationUpdateHelpdeskAssigneeArgs = {
  input: UpdateHelpdeskAssigneeInput;
};


export type MutationChangeEmployeePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationUpdateLastLoginCompanyArgs = {
  lastestAccessedEntity: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUserProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  UserID: Scalars['String'];
};


export type MutationResendInvitationByIdArgs = {
  data: Array<Scalars['JSON']>;
};

export type MyApplicationOutput = {
  __typename?: 'MyApplicationOutput';
  leave?: Maybe<LeaveAppOutput>;
  claim?: Maybe<ClaimAppOutput>;
  requisition?: Maybe<RequisitionAppOutput>;
};

export type MyClaimDetailOutput = {
  __typename?: 'MyClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<VerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
  MileageLogIDs?: Maybe<Array<Scalars['String']>>;
  Distance?: Maybe<Scalars['Float']>;
};

export type MyClaimHeaderDetails = {
  __typename?: 'MyClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
};

export type MyClaimListingOutput = {
  __typename?: 'MyClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  CountApprovedClaims?: Maybe<Scalars['Float']>;
  TotalApprovedClaimsAmount?: Maybe<Scalars['Float']>;
  ClaimRecords?: Maybe<Array<ClaimRecordsListing>>;
};

export type MyJobAccountEntity = InterfaceBase & {
  __typename?: 'MyJobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MyJobAccountID: Scalars['String'];
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  JobAccount: JobAccountEntity;
  Department: DepartmentEntity;
};

export type MyJobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveDetailOutput = InterfaceBase & {
  __typename?: 'MyLeaveDetailOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  TimeOffID: Scalars['String'];
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  DocumentFile?: Maybe<Scalars['String']>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovedByEmployeeID?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementList = {
  __typename?: 'MyLeaveEntitlementList';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementOuput = {
  __typename?: 'MyLeaveEntitlementOuput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Entitlement?: Maybe<Scalars['Float']>;
  AsToDateEnt?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type MyLeaveInsertInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveListingInput = {
  EmployeeID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypes?: Maybe<Array<Scalars['String']>>;
};

export type MyLeaveSummaryInput = {
  EmployeeID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type MyLeaveSummaryOutput = {
  __typename?: 'MyLeaveSummaryOutput';
  LeaveType: Scalars['String'];
  EntitledDay: Scalars['Float'];
  TakenDay: Scalars['Float'];
  BalanceDay: Scalars['Float'];
};

export type MyLeaveUpdateInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyPeClaimHeaderDetails = {
  __typename?: 'MyPEClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
};

export type MyProjectExpenseClaimDetailOutput = {
  __typename?: 'MyProjectExpenseClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<PeVerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PePostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type MyReviewActionOutput = {
  __typename?: 'MyReviewActionOutput';
  ID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseName: Scalars['String'];
};

export type MySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bruneian = 'Bruneian',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum NationalityType {
  Malaysian = 'Malaysian',
  NonMalaysian = 'Non_Malaysian'
}

export type NewApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type NewDocumentNumberingInsertInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type NewHireProcessOnboardInfoOutput = {
  __typename?: 'NewHireProcessOnboardInfoOutput';
  contact_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  probation_month?: Maybe<Scalars['Float']>;
  full_name?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  superior_full_name?: Maybe<Scalars['String']>;
  superior_role?: Maybe<Scalars['String']>;
  recruitment_source_name?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type NewInterfaceBase = {
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
};

export type NewInterfaceBase1 = {
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
};

export type NewInterfaceBaseInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
};

export type NewInterfaceBaseInput1 = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
};

export type NewReportingStructureEntity = NewInterfaceBase & {
  __typename?: 'NewReportingStructureEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_structure_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group?: Maybe<ReportingGroupEntity>;
  effective_date?: Maybe<Scalars['DateTime']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  subordinate_id?: Maybe<Scalars['String']>;
  subordinate?: Maybe<EmployeeEntity>;
  superior_id?: Maybe<Scalars['String']>;
  superior?: Maybe<EmployeeEntity>;
  reporting_type: ReportingType;
  is_data_import?: Maybe<Scalars['Boolean']>;
};

export type NewTrainingAttendanceEntity = NewInterfaceBase1 & {
  __typename?: 'NewTrainingAttendanceEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_employee_id: Scalars['String'];
  scheduled_training_timetable_id: Scalars['String'];
  check_in?: Maybe<Scalars['DateTime']>;
  check_out?: Maybe<Scalars['DateTime']>;
  adjusted_check_in?: Maybe<Scalars['DateTime']>;
  adjusted_check_out?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  EmployeeScheduledTraining?: Maybe<ScheduledTrainingEmployeeEntity>;
  ScheduledTrainingTimetable?: Maybe<ScheduledTrainingTimetableEntity>;
};

export type NewTrainingAttendanceInput = {
  scheduled_training_employee_id?: Maybe<Scalars['String']>;
  scheduled_training_id?: Maybe<Scalars['String']>;
  finalized_attendance?: Maybe<Scalars['Boolean']>;
};

export type NewTrainingBudgetEntity = NewInterfaceBase & {
  __typename?: 'NewTrainingBudgetEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  TrainingBudgetItems?: Maybe<Array<TrainingBudgetItemsEntity>>;
};

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom?: Maybe<Scalars['DateTime']>;
  ValidTo?: Maybe<Scalars['DateTime']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<PostStatus>;
  NewsRead: Array<NewsReadEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Contact?: Maybe<ContactEntity>;
  ContentUrl?: Maybe<Scalars['String']>;
  IsTraining?: Maybe<Scalars['Boolean']>;
  PublishedBy: Scalars['String'];
};

export type NewsInput = {
  NewsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom: Scalars['DateTime'];
  ValidTo?: Maybe<Scalars['DateTime']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  PostStatus?: Maybe<PostStatus>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentUrl?: Maybe<Scalars['String']>;
  IsTraining?: Maybe<Scalars['Boolean']>;
};

export type NewsReadEntity = InterfaceBase & {
  __typename?: 'NewsReadEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsReadID: Scalars['String'];
  NewsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  News: NewsEntity;
};

export type NewsReadInput = {
  NewsID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationEntity = InterfaceBase & {
  __typename?: 'NotificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  ShowNotification?: Maybe<Scalars['Boolean']>;
  Company: CompanyEntity;
  FromEmployee?: Maybe<EmployeeEntity>;
  ToEmployee?: Maybe<EmployeeEntity>;
};

export type NotificationInput = {
  NotificationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShowNotification?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyClaimEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
};

export type NotificationPolicyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyConditionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyConditionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyConditionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyID: Scalars['String'];
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType: NotificationPolicyType;
  PolicyType: PolicyType;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimEntity>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveEntity>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionEntity>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionEntity>>;
};

export type NotificationPolicyInput = {
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType?: Maybe<NotificationPolicyType>;
  PolicyType?: Maybe<PolicyType>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimInput>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveInput>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionInput>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionInput>>;
};

export type NotificationPolicyLastUpdatedOutput = {
  __typename?: 'NotificationPolicyLastUpdatedOutput';
  NotificationEmploymentLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLeaveLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationClaimLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationRequisitionLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationEmploymentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationLeaveIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationClaimIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationRequisitionIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyLeaveEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
};

export type NotificationPolicyLeaveInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyRequisitionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyRequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyRequisitionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType: RequisitionType;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyRequisitionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum NotificationPolicyType {
  EmploymentStatus = 'EmploymentStatus',
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition'
}

export enum NotificationType {
  News = 'News',
  Approval = 'Approval',
  SelfNotification = 'Self_Notification'
}

export type OtClaimEntitlement = {
  __typename?: 'OTClaimEntitlement';
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementList>>;
};

export type OtClaimEntitlementInput = {
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementListInput>>;
};

export type OtClaimEntitlementList = {
  __typename?: 'OTClaimEntitlementList';
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export type OtClaimEntitlementListInput = {
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export enum OtClaimTableType {
  OtClaims = 'OT_CLAIMS',
  ReplacementLeave = 'REPLACEMENT_LEAVE'
}

export enum OtEntitlementType {
  ActualHour = 'ACTUAL_HOUR',
  MaxHours = 'MAX_HOURS'
}

export type OtProcessingOutput = {
  __typename?: 'OTProcessingOutput';
  WorkDate?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type OtRoundingEntity = InterfaceBase & {
  __typename?: 'OTRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OTRoundingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type OtRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OTRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
};

export type OTconditionList = {
  Condition?: Maybe<Scalars['String']>;
  totalOTMinutes?: Maybe<Scalars['String']>;
  DeductOTMinutes?: Maybe<Scalars['String']>;
  MinimumPay?: Maybe<Scalars['String']>;
};

export enum Occurrence {
  CalendarYear = 'Calendar_Year',
  EmploymentPeriod = 'Employment_Period'
}

export type OfferChecklistSnapshot = {
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type OnBehalfCompanyOutput = {
  __typename?: 'OnBehalfCompanyOutput';
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  ReportingGroupIDs: Array<Scalars['String']>;
};

export type OnBehalfEntity = InterfaceBaseV2 & {
  __typename?: 'OnBehalfEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  OnBehalfPolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
  ModuleType: RequestType;
  ReportingGroupID: Scalars['String'];
  RequestTypeIDs: Array<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ReportingGroupName: Scalars['String'];
};

export type OnBehalfPolicyInput = {
  ReportingGroupID: Scalars['String'];
  RequestTypeIDs: Array<Scalars['String']>;
};

export type OnBehalfSubordinateOutput = {
  __typename?: 'OnBehalfSubordinateOutput';
  ReportingGroupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeName: Scalars['String'];
  Email: Scalars['String'];
  MobileNo: Scalars['String'];
  Avatar?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  Designation: Scalars['String'];
};

export type OnboardNewHireInput = {
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  IsHOD: Scalars['Boolean'];
  DesignationID: Scalars['String'];
  JobGradeID: Scalars['String'];
  StaffCategoryID: Scalars['String'];
  EmployeeStatus: Status;
  EmploymentType: EmploymentType;
  JoinedDate: Scalars['DateTime'];
  JoinedReferenceDate: Scalars['DateTime'];
  Probation?: Maybe<Scalars['Float']>;
  ConfirmedDate: Scalars['DateTime'];
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyID: Scalars['String'];
  RecuritmentSourceID: Scalars['String'];
  RetirementAge: Scalars['Float'];
  submitter_id?: Maybe<Scalars['String']>;
  onboard_status?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<Scalars['String']>;
};

export enum OnboardStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type OneTimePayEntity = InterfaceBase & {
  __typename?: 'OneTimePayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OneTimePayID: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  RecordSource?: Maybe<RecordSource>;
  ExcelFileName?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Employee?: Maybe<EmployeeEntity>;
  User?: Maybe<UserEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type OneTimePayInput = {
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type OneTimePayItemCalculatioOutput = {
  __typename?: 'OneTimePayItemCalculatioOutput';
  TotalOneTimeTax: Scalars['Float'];
  TotalOneTimeAddRemuneration: Scalars['Float'];
  TotalOneTimeEPF: Scalars['Float'];
  TotalOneTimeEPFVE: Scalars['Float'];
  EPFEmployeeToBeMinus: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPF: Scalars['Float'];
  TotalForcastedNormalRemunerationEPF: Scalars['Float'];
  TotalOneTimeMonthlyWageNumeration: Scalars['Float'];
  TotalOneTimeAddRemunerationEPF: Scalars['Float'];
  TotalOneTimeEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFVEEmployee: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployee: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFEmployer: Scalars['Float'];
  TotalOneTimeEPFVEEmployer: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployer: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeSocso: Scalars['Float'];
  TotalOneTimeZakat: Scalars['Float'];
  TotalOneTimeEIS: Scalars['Float'];
  TotalOneTimeBonus: Scalars['Float'];
  TotalOneTimeHRDF: Scalars['Float'];
  TotalOneTimeBonusEPF: Scalars['Float'];
  TotalOneTimeBonusEPFEmployee: Scalars['Float'];
  TotalOneTimeBonusEPFEmployer: Scalars['Float'];
  TotalCountOneTimeAddRemuneration: Scalars['Float'];
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
};

export type OneTimePayItemOutput = {
  __typename?: 'OneTimePayItemOutput';
  PayItemID: Scalars['String'];
  PayItemType?: Maybe<PayItemType>;
  PayName: Scalars['String'];
  PayCode: Scalars['String'];
  Calculation: Scalars['String'];
  Formula: Scalars['String'];
  Quantity: Scalars['Float'];
  Amount: Scalars['Float'];
  ActualAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TotalAddRemuneration: Scalars['Float'];
  ExemptNormalRemunerationAmount: Scalars['Float'];
  ExemptAdditionalRemunerationAmount: Scalars['Float'];
  AddRemunerationAmount: Scalars['Float'];
  EPFAmount: Scalars['Float'];
  EPFVEAmount: Scalars['Float'];
  SocsoAmount: Scalars['Float'];
  EISAmount: Scalars['Float'];
  BonusAmount: Scalars['Float'];
  HRDFAmount: Scalars['Float'];
  ZakatAmount: Scalars['Float'];
  EPFEmployee: Scalars['Boolean'];
  EPFEmployer: Scalars['Boolean'];
  EPFAmountEmployee: Scalars['Float'];
  EPFAmountEmployer: Scalars['Float'];
  EPFVEAmountEmployee: Scalars['Float'];
  EPFVEAmountEmployer: Scalars['Float'];
};

export type OrgKpiPlanningDetailsEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  OrgGoal?: Maybe<OrganizationalGoalEntity>;
};

export type OrgKpiPlanningDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
  Owner?: Maybe<EmployeeEntity>;
  TotalGoals: Scalars['Float'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
};

export type OrganizationalGoalEntity = InterfaceBase & {
  __typename?: 'OrganizationalGoalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  InUse: Scalars['Boolean'];
};

export type OrganizationalGoalInput = {
  ID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OvertimeCalculationItemOutput = {
  __typename?: 'OvertimeCalculationItemOutput';
  OTData?: Maybe<Scalars['JSON']>;
};

export type OvertimeClaimTableEntity = InterfaceBase & {
  __typename?: 'OvertimeClaimTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeClaimTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  OTClaimTableType?: Maybe<OtClaimTableType>;
  OTClaimData?: Maybe<Scalars['JSON']>;
  ReplacementLeaveData?: Maybe<Scalars['JSON']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeClaimTableInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTClaimData?: Maybe<Array<OtClaimEntitlementInput>>;
  ReplacementLeaveData?: Maybe<Array<ReplacementLeaveClaimTypeInput>>;
  OTClaimTableType: OtClaimTableType;
};

export type OvertimeDetails = {
  __typename?: 'OvertimeDetails';
  ApprovalStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type OvertimeEntity = InterfaceBase & {
  __typename?: 'OvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus: OvertimeStatus;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Approver?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  Duration?: Maybe<Scalars['Float']>;
  otReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  OvertimeHistory?: Maybe<Array<OvertimeEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  ShiftID?: Maybe<Scalars['String']>;
  Shift?: Maybe<ShiftEntity>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  BatchOvertime?: Maybe<BatchOvertimeEntity>;
};

export type OvertimeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
};

export type OvertimeOutput = {
  __typename?: 'OvertimeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Record?: Maybe<Scalars['String']>;
};

export type OvertimePolicyEntity = InterfaceBase & {
  __typename?: 'OvertimePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  OvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  OTLimitDayType?: Maybe<Scalars['JSON']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  otRounding?: Maybe<OtRoundingEntity>;
};

export type OvertimePolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  OTLimitDayType?: Maybe<Array<Scalars['JSON']>>;
};

export type OvertimeReasonEntity = InterfaceBase & {
  __typename?: 'OvertimeReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeReasonInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingEntity = InterfaceBase & {
  __typename?: 'OvertimeRoundingSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeRoundingSettingID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingInput = {
  SubscriptionAccountID: Scalars['String'];
  OvertimeRoundingSettingID: Scalars['String'];
  Name: Scalars['String'];
};

export enum OvertimeStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type OvertimeTemplateEntity = InterfaceBase & {
  __typename?: 'OvertimeTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeTemplateID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type OvertimeTemplateInput = {
  OvertimeTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type PcbCalculationInput = {
  EmployeeID: Scalars['String'];
  AccumulatedGrossTaxable: Scalars['Float'];
  AccumulatedEPF: Scalars['Float'];
  AccumulatedPCB: Scalars['Float'];
  AccumulatedZakat: Scalars['Float'];
};

export type PeApprovedClaimDetail = {
  __typename?: 'PEApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type PeClaimDuplicateList = {
  __typename?: 'PEClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export type PeClaimPostedListingOutput = {
  __typename?: 'PEClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PeClaimPostingListingOutput = {
  __typename?: 'PEClaimPostingListingOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<PeClaimPostedListingOutput>;
};

export type PeClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type PeClaimPostingPeriodListingOutput = {
  __typename?: 'PEClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<PeClaimPostingPeriodStatus>>;
};

export type PeClaimPostingPeriodStatus = {
  __typename?: 'PEClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type PeClaimRecordsDetailHeaderOutput = {
  __typename?: 'PEClaimRecordsDetailHeaderOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Project?: Maybe<Array<ProjectMasterEntity>>;
};

export type PeglClaimRecordsDetail = {
  __typename?: 'PEGLClaimRecordsDetail';
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type PePostedClaimDetail = {
  __typename?: 'PEPostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export type PeVerifiedClaimDetail = {
  __typename?: 'PEVerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export enum PayCategoryType {
  General = 'General',
  PayItem = 'Pay_Item'
}

export type PayGroupAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayGroupID: Scalars['String'];
  PayGroup?: Maybe<PayGroupEntity>;
};

export type PayGroupAssignmentInput = {
  CompanyID: Scalars['String'];
  PayGroupIDs: Array<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayGroupEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  PayGroupAssignment?: Maybe<Array<PayGroupAssignmentEntity>>;
};

export type PayGroupInput = {
  PayGroupID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayItemConditionEntity = InterfaceBase & {
  __typename?: 'PayItemConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
};

export type PayItemConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsList = {
  __typename?: 'PayItemEmployeeDetailsList';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsOutput = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEntity = {
  __typename?: 'PayItemEntity';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
};

export type PayItemFilterInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayItemGlEntity = InterfaceBase & {
  __typename?: 'PayItemGLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type PayItemInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
};

export type PayItemListingByTlaOutput = {
  __typename?: 'PayItemListingByTLAOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
  EmployeeIds?: Maybe<Array<Scalars['String']>>;
};

export type PayItemMapping = {
  __typename?: 'PayItemMapping';
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemMappingInput = {
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemOutput = {
  __typename?: 'PayItemOutput';
  TransactionID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type PayItemTransactionOutput = {
  __typename?: 'PayItemTransactionOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Employees?: Maybe<Array<EmployeeTransactionOutput>>;
  IsAllProcessed: Scalars['Boolean'];
};

export enum PayItemType {
  Earning = 'Earning',
  Deduction = 'Deduction',
  NonPayable = 'Non_Payable',
  Overtime = 'Overtime'
}

export type PayItemsWithAmountList = {
  __typename?: 'PayItemsWithAmountList';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsList>>;
};

export type PayItemsWithAmountOutput = {
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsOutput>>;
};

export type PayPeriodEntity = InterfaceBase & {
  __typename?: 'PayPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ActualWorkDays?: Maybe<Scalars['Float']>;
  PayGroupID?: Maybe<Scalars['String']>;
};

export type PayPeriodInput = {
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
};

export type PayPeriodSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PaymentMethod {
  Payroll = 'Payroll',
  BankTransfer = 'Bank_Transfer',
  Cash = 'Cash',
  Cheque = 'Cheque'
}

export type PayrollCalculationInput = {
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  IsLastProceed: Scalars['Boolean'];
  IsForecasted?: Maybe<Scalars['Boolean']>;
  SequenceNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  IsIncludedSocso?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SalaryInfo?: Maybe<Scalars['JSON']>;
  PenaltyBreakdownInfo?: Maybe<Scalars['JSON']>;
  ParcelSupplementaryInfo?: Maybe<Scalars['JSON']>;
};

export type PayrollCalculationItemOutput = {
  __typename?: 'PayrollCalculationItemOutput';
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
  accPreviousMonthOutput?: Maybe<AccumulatedPreviousMonthOutput>;
  AccCurrentMonthPreviousPayrollCycle?: Maybe<AccCurrentMonthPreviousPayrollCycleOutput>;
  EmployeeID?: Maybe<Scalars['String']>;
  NonResidentNonPayable?: Maybe<Scalars['Float']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  totalDu?: Maybe<Scalars['Float']>;
  totalSu?: Maybe<Scalars['Float']>;
  total100UnderAge?: Maybe<Scalars['Float']>;
  total50UnderAge?: Maybe<Scalars['Float']>;
  total100Certificate?: Maybe<Scalars['Float']>;
  total50Certificate?: Maybe<Scalars['Float']>;
  total100Diploma?: Maybe<Scalars['Float']>;
  total50Diploma?: Maybe<Scalars['Float']>;
  total100Disabled?: Maybe<Scalars['Float']>;
  total50Disabled?: Maybe<Scalars['Float']>;
  total100DisabledDiploma?: Maybe<Scalars['Float']>;
  total50DisabledDiploma?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ActualWorkday?: Maybe<Scalars['Float']>;
  RequiredWorkDay?: Maybe<Scalars['Float']>;
  EPFRoundingAmount?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFRate?: Maybe<Scalars['Float']>;
  EmployerEPFRate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  TotalChargableEPFEmployer?: Maybe<Scalars['Float']>;
  EmployeeEPF2?: Maybe<Scalars['Float']>;
  TaxableEPF?: Maybe<Scalars['Float']>;
  TaxableEPF2?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  TotalEmployerEPFRate?: Maybe<Scalars['Float']>;
  TotalEmployeeEPFRate?: Maybe<Scalars['Float']>;
  SocsoRequirement?: Maybe<Scalars['String']>;
  EISRequirement?: Maybe<Scalars['String']>;
  SocsoRule?: Maybe<Scalars['String']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax1?: Maybe<Scalars['Float']>;
  Tax2?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxZakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
  ContributeHRDF?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  SalaryType?: Maybe<Scalars['String']>;
  TaxBasicSalary?: Maybe<Scalars['Float']>;
  GrossSalary?: Maybe<Scalars['Float']>;
  TotalChargableTax?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  NetSalary?: Maybe<Scalars['Float']>;
  TotalEarningPayItem?: Maybe<Scalars['Float']>;
  TotalDeductionPayItem?: Maybe<Scalars['Float']>;
  TotalNonPayablePayItem?: Maybe<Scalars['Float']>;
  TotalBIKAmount?: Maybe<Scalars['Float']>;
  TotalVOLAAmount?: Maybe<Scalars['Float']>;
  TotalBIKVOLA?: Maybe<Scalars['Float']>;
  TotalChargableTax1?: Maybe<Scalars['Float']>;
  TotalChargableTax2?: Maybe<Scalars['Float']>;
  TotalChargableAddRemuneration?: Maybe<Scalars['Float']>;
  TotalChargableEPF?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployee?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployer?: Maybe<Scalars['Float']>;
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  CurrentMonthAdditionalRemunerationEPFEmployer?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPFEmployee?: Maybe<Scalars['Float']>;
  TotalChargableEPFVE?: Maybe<Scalars['Float']>;
  TotalChargableSocso?: Maybe<Scalars['Float']>;
  TotalChargableEIS?: Maybe<Scalars['Float']>;
  TotalChargableHRDF?: Maybe<Scalars['Float']>;
  TotalChargableBonus?: Maybe<Scalars['Float']>;
  TaxRelief?: Maybe<Scalars['Float']>;
  MTDCalc?: Maybe<MtdOutput>;
  CurrentMonthNormalRemunerationEPF?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPF?: Maybe<Scalars['Float']>;
  ProcessLog?: Maybe<Scalars['String']>;
  ContributeTax?: Maybe<Scalars['Boolean']>;
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['Float']>;
  NormalD2DLarge?: Maybe<Scalars['Float']>;
  NormalD2DBulky?: Maybe<Scalars['Float']>;
  PublicD2DSmall?: Maybe<Scalars['Float']>;
  PublicD2DLarge?: Maybe<Scalars['Float']>;
  PublicD2DBulky?: Maybe<Scalars['Float']>;
  CampaignD2DSmall?: Maybe<Scalars['Float']>;
  CampaignD2DLarge?: Maybe<Scalars['Float']>;
  CampaignD2DBulky?: Maybe<Scalars['Float']>;
  NormalBSCSmall?: Maybe<Scalars['Float']>;
  NormalBSCLarge?: Maybe<Scalars['Float']>;
  NormalBSCBulky?: Maybe<Scalars['Float']>;
  PublicBSCSmall?: Maybe<Scalars['Float']>;
  PublicBSCLarge?: Maybe<Scalars['Float']>;
  PublicBSCBulky?: Maybe<Scalars['Float']>;
  CampaignBSCSmall?: Maybe<Scalars['Float']>;
  CampaignBSCLarge?: Maybe<Scalars['Float']>;
  CampaignBSCBulky?: Maybe<Scalars['Float']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayGroupID?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<Scalars['String']>;
  StartPayPeriod: Scalars['DateTime'];
  EndPayPeriod: Scalars['DateTime'];
};

export type PayrollCalculationOutput = {
  __typename?: 'PayrollCalculationOutput';
  Result?: Maybe<Scalars['Boolean']>;
  TotalRecord?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Listing?: Maybe<Array<PayrollCalculationItemOutput>>;
  Entity?: Maybe<Array<PayrollHeaderEntity>>;
};

export type PayrollConditionListInput = {
  Formula: Scalars['JSON'];
  Criteria: Array<PayrollCriteriaListInput>;
  Description: Scalars['String'];
  UserLogs?: Maybe<Array<UserLogInput>>;
};

export type PayrollCriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['JSON']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['JSON']>;
  Description: Scalars['String'];
};

export type PayrollCycleAssignment = {
  PayrollCycleID: Scalars['String'];
  PayGroupID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  AllowBasicSalary?: Maybe<Scalars['Boolean']>;
};

export type PayrollCycleAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'PayrollCycleAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayrollCycleAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayGroupID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  AllowBasicSalary?: Maybe<Scalars['Float']>;
  PayrollCycle: PayrollCycleEntity;
  PayGroupTitle: Scalars['String'];
  PayrollCycleName: Scalars['String'];
};

export type PayrollCycleAssignmentInput = {
  CompanyID: Scalars['String'];
  PayrollCycleAssignment: Array<PayrollCycleAssignment>;
  UserLogs: Array<UserLogInput>;
};

export type PayrollCycleEntity = InterfaceBase & {
  __typename?: 'PayrollCycleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  PayrollCyclePeriod?: Maybe<Array<PayrollCyclePeriodEntity>>;
};

export type PayrollCycleInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodEntity = InterfaceBase & {
  __typename?: 'PayrollCyclePeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCyclePeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsDeferred?: Maybe<Scalars['Boolean']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  IsProccess?: Maybe<Scalars['Boolean']>;
  PayrollCycleName: Scalars['String'];
};

export type PayrollCyclePeriodInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsDeferred?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodListingOutput = {
  __typename?: 'PayrollCyclePeriodListingOutput';
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  RowNumber?: Maybe<Scalars['Float']>;
};

export type PayrollCycleSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PayrollCycleStatus {
  Calculated = 'Calculated',
  Locked = 'Locked',
  Unlock = 'Unlock',
  NoRecord = 'NoRecord'
}

export type PayrollDetailEntity = InterfaceBase & {
  __typename?: 'PayrollDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDetailID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage: Scalars['Float'];
  Amount: Scalars['String'];
  Quantity: Scalars['String'];
  ExemptNormalRemunerationAmount: Scalars['String'];
  ExemptAdditionalRemunerationAmount: Scalars['String'];
  TotalAmount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayItem: PayItemEntity;
};

export type PayrollDetailInput = {
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollFixedAllowanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayrollGlExportEntity = InterfaceBase & {
  __typename?: 'PayrollGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  GLCode?: Maybe<Scalars['String']>;
  FinancialYear?: Maybe<Scalars['String']>;
  DR?: Maybe<Scalars['String']>;
  CR?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  PayCycle?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
};

export type PayrollHeaderEntity = InterfaceBase & {
  __typename?: 'PayrollHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  TaxRelief: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  TotalChargableAddRemuneration: Scalars['String'];
  TotalChargableNormalRemunerationEPF: Scalars['String'];
  TotalChargableAdditionalRemunerationEPF: Scalars['String'];
  TotalChargableEPF: Scalars['String'];
  TotalChargableEPFVE: Scalars['String'];
  TotalChargableSocso: Scalars['String'];
  TotalChargableEIS: Scalars['String'];
  TotalChargableHRDF: Scalars['String'];
  TotalChargableBonus: Scalars['String'];
  EPFPaid: Scalars['String'];
  EPFPaidByAdditionalRemuneration: Scalars['String'];
  EPFPaidByNormalRemunerationPaid: Scalars['String'];
  EPFVEPaid: Scalars['String'];
  SOCSOPaid: Scalars['String'];
  EISPaid: Scalars['String'];
  ZAKATPaid: Scalars['String'];
  TAXPaid: Scalars['String'];
  TotalBIK: Scalars['String'];
  TotalVOLA: Scalars['String'];
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['String']>;
  NormalD2DLarge?: Maybe<Scalars['String']>;
  NormalD2DBulky?: Maybe<Scalars['String']>;
  PublicD2DSmall?: Maybe<Scalars['String']>;
  PublicD2DLarge?: Maybe<Scalars['String']>;
  PublicD2DBulky?: Maybe<Scalars['String']>;
  CampaignD2DSmall?: Maybe<Scalars['String']>;
  CampaignD2DLarge?: Maybe<Scalars['String']>;
  CampaignD2DBulky?: Maybe<Scalars['String']>;
  NormalBSCSmall?: Maybe<Scalars['String']>;
  NormalBSCLarge?: Maybe<Scalars['String']>;
  NormalBSCBulky?: Maybe<Scalars['String']>;
  PublicBSCSmall?: Maybe<Scalars['String']>;
  PublicBSCLarge?: Maybe<Scalars['String']>;
  PublicBSCBulky?: Maybe<Scalars['String']>;
  CampaignBSCSmall?: Maybe<Scalars['String']>;
  CampaignBSCLarge?: Maybe<Scalars['String']>;
  CampaignBSCBulky?: Maybe<Scalars['String']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayrollDetails: Array<PayrollDetailEntity>;
  PayGroupID?: Maybe<Scalars['String']>;
  StartPayPeriod?: Maybe<Scalars['DateTime']>;
  EndPayPeriod?: Maybe<Scalars['DateTime']>;
  Islocked?: Maybe<Scalars['Boolean']>;
};

export type PayrollHeaderInput = {
  EmployeeID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay: Scalars['String'];
  TotalGross: Scalars['String'];
  TotalDeduction: Scalars['String'];
  NormalRemn: Scalars['String'];
  AdditionalRemn: Scalars['String'];
  ChargeSOCSO: Scalars['String'];
  ChargeEPF: Scalars['String'];
  ChargeEIS: Scalars['String'];
  ChargeZAKAT: Scalars['String'];
  TaxRelief: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollHomeInfoLinesOutput = {
  __typename?: 'PayrollHomeInfoLinesOutput';
  OutstandingRecord?: Maybe<Scalars['Float']>;
  NewEmpPending?: Maybe<Scalars['Float']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  NoOfRecords?: Maybe<Scalars['Float']>;
  NoOfRecords1?: Maybe<Scalars['Float']>;
  TotalRecordsFixed?: Maybe<Scalars['Float']>;
  DeductionRecords?: Maybe<Scalars['Float']>;
  DeductionRecordsMonthly?: Maybe<Scalars['Float']>;
  EarningRecords?: Maybe<Scalars['Float']>;
  AllowanceRecordsMonthly?: Maybe<Scalars['Float']>;
  NonpayableRecords?: Maybe<Scalars['Float']>;
  NonpayableRecordsMonthly?: Maybe<Scalars['Float']>;
  OvertimeRecords?: Maybe<Scalars['Float']>;
  OvertimeRecordsMonthly?: Maybe<Scalars['Float']>;
  PayrollInitiateDate?: Maybe<Scalars['String']>;
  MthTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  PayrollFreeze?: Maybe<Scalars['Float']>;
  ProcessDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDate?: Maybe<Scalars['DateTime']>;
  PayrollGross?: Maybe<Scalars['Float']>;
  PayrollNetPay?: Maybe<Scalars['Float']>;
  ClosingDate?: Maybe<Scalars['DateTime']>;
  ClosingModifiedBy?: Maybe<Scalars['String']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  PublishedModifiedBy?: Maybe<Scalars['String']>;
  PublishedEADate?: Maybe<Scalars['DateTime']>;
  PublishedEAModifiedBy?: Maybe<Scalars['String']>;
  PCBDate?: Maybe<Scalars['DateTime']>;
  GLDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessEmployeeListingOutput = {
  __typename?: 'PayrollProcessEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  Status?: Maybe<Status>;
  IsChecked?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
};

export type PayrollProcessPayItemListingOutput = {
  __typename?: 'PayrollProcessPayItemListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  EarningPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  DeductionPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  NonPayablePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  OvertimePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
};

export type PayrollProcessPayItemOutput = {
  __typename?: 'PayrollProcessPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type PayrollProcessPayrollCycleListingOutput = {
  __typename?: 'PayrollProcessPayrollCycleListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  ShowDelete?: Maybe<Scalars['Boolean']>;
  TotalRecords?: Maybe<Scalars['Float']>;
  PayrollCyclePeriodID?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsProjectGLProcessed?: Maybe<Scalars['Boolean']>;
  LastProjectGLProcessedDate?: Maybe<Scalars['DateTime']>;
  IsPayrollGLProcessed?: Maybe<Scalars['Boolean']>;
  LastPayrollGLProcessedDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessPeriodListingOutput = {
  __typename?: 'PayrollProcessPeriodListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  TotalNetPay?: Maybe<Scalars['Float']>;
  TotalEmployee?: Maybe<Scalars['Float']>;
  TotalPayrollCyclePeriodSetting?: Maybe<Scalars['Float']>;
  LastPayrollGLProcess?: Maybe<Scalars['DateTime']>;
  PayGroupID?: Maybe<Scalars['String']>;
};

export type PayrollProcessPeriodListingStatusOutput = {
  __typename?: 'PayrollProcessPeriodListingStatusOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TotalLastProcess?: Maybe<Scalars['Float']>;
};

export type PayrollReportEntity = InterfaceBase & {
  __typename?: 'PayrollReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollReportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollData?: Maybe<Scalars['String']>;
};

export type PayrollReportOutput = {
  __typename?: 'PayrollReportOutput';
  PayrollReportID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
};

export type PayrollSnapshotEntity = InterfaceBase & {
  __typename?: 'PayrollSnapshotEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
};

export type PayrollSnapshotOutput = {
  __typename?: 'PayrollSnapshotOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
};

export type PayrollSummmaryListingOutput = {
  __typename?: 'PayrollSummmaryListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
};

export type PenaltyBreakdownInfo = {
  __typename?: 'PenaltyBreakdownInfo';
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PenaltyBreakdownInfoInput = {
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PerformanceGroupMatrixEntity = InterfaceBase & {
  __typename?: 'PerformanceGroupMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList: Array<Scalars['JSON']>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  RewardGroup?: Maybe<RewardGroupEntity>;
  TotalCriteria: Scalars['Float'];
  CriteriaDesc: Scalars['String'];
};

export type PerformanceGroupMatrixInput = {
  ID?: Maybe<Scalars['String']>;
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList?: Maybe<Array<ConditionListInput>>;
};

export enum PerformanceGroupType {
  Review = 'Review',
  Reward = 'Reward'
}

export type PerformanceManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum PerformanceManagementDelegation {
  KpiProcessApproval = 'KPI_Process_Approval',
  ReviewProcessApproval = 'Review_Process_Approval'
}

export type PeriodicAllowancePolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowancePolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  MonthlyAllowanceID: Scalars['String'];
  MonthlyAllowance?: Maybe<MonthlyAllowancePolicyEntity>;
};

export type PeriodicAllowancePolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  MonthlyAllowanceID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicAllowanceTypeEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowanceTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicAllowanceTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type PeriodicAllowanceTypeInput = {
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PeriodicClaimType {
  Allowance = 'Allowance',
  Deduction = 'Deduction'
}

export type PeriodicDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type PeriodicDeductionPolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PeriodicDeductionID: Scalars['String'];
  PeriodicDeduction?: Maybe<PeriodicDeductionPolicyEntity>;
};

export type PeriodicDeductionPolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodicDeductionID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicDeductionPolicyEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicDeductionPolicyCondition?: Maybe<Array<PeriodicDeductionPolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type PersonnelCertificateEntity = InterfaceBase & {
  __typename?: 'PersonnelCertificateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelCertificateID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelCertificateInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type PersonnelEFileListingOutput = {
  __typename?: 'PersonnelEFileListingOutput';
  Employee?: Maybe<EmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelEducationEntity = InterfaceBase & {
  __typename?: 'PersonnelEducationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelEducationID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelEducationInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelHealthInfoEntity = InterfaceBase & {
  __typename?: 'PersonnelHealthInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecords?: Maybe<Array<MedicalRecordEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  VaccinationListSummary?: Maybe<Array<VaccinationListItemOutput>>;
};

export type PersonnelHealthInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
};

export type PersonnelHomeInfoLinesOutput = {
  __typename?: 'PersonnelHomeInfoLinesOutput';
  EmpBySubsTotal?: Maybe<Scalars['Float']>;
  EmpTotal?: Maybe<Scalars['Float']>;
  MthNewEmpTotal?: Maybe<Scalars['Float']>;
  OverdueConfTotal?: Maybe<Scalars['Float']>;
  UpcomingConfTotal?: Maybe<Scalars['Float']>;
  MthProbExtTotal?: Maybe<Scalars['Float']>;
  MthContractDocExpTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  MthDemoTotal?: Maybe<Scalars['Float']>;
  MthSecondTotal?: Maybe<Scalars['Float']>;
  MthRedesTotal?: Maybe<Scalars['Float']>;
  MthIntTransTotal?: Maybe<Scalars['Float']>;
  MthIntCompTransTotal?: Maybe<Scalars['Float']>;
  MthTerminationTotal?: Maybe<Scalars['Float']>;
  MthRetireTotal?: Maybe<Scalars['Float']>;
  MthOffBoardTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  DataChangesApplicationPendingTotal?: Maybe<Scalars['Float']>;
};

export type PersonnelJobHistoryEntity = InterfaceBase & {
  __typename?: 'PersonnelJobHistoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelJobHistoryID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  CompanyAddress?: Maybe<Address2>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelJobHistoryInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  CompanyAddress?: Maybe<AddressInput2>;
};

export type PersonnelLanguageEntity = {
  __typename?: 'PersonnelLanguageEntity';
  PersonnelLanguageID: Scalars['String'];
  ContactID: Scalars['String'];
  LanguageID: Scalars['String'];
  Language: Scalars['String'];
  WrittenLevel?: Maybe<LanguageLevel>;
  ReadLevel?: Maybe<LanguageLevel>;
  SpokenLevel?: Maybe<LanguageLevel>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelLanguageInput = {
  PersonnelLanguageID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  LanguageID: Scalars['String'];
  Language: Scalars['String'];
  WrittenLevel?: Maybe<LanguageLevel>;
  ReadLevel?: Maybe<LanguageLevel>;
  SpokenLevel?: Maybe<LanguageLevel>;
  Status?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum PersonnelManagementDelegation {
  DataChangesApproval = 'Data_Changes_Approval'
}

export type PersonnelMembershipEntity = {
  __typename?: 'PersonnelMembershipEntity';
  PersonnelMembershipID: Scalars['String'];
  ContactID: Scalars['String'];
  DateAdmitted: Scalars['DateTime'];
  ProfessionalInstitution: Scalars['String'];
  MembershipStatus: Scalars['String'];
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelMembershipInput = {
  PersonnelMembershipID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DateAdmitted: Scalars['DateTime'];
  ProfessionalInstitution: Scalars['String'];
  MembershipStatus: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelParameterEntity = InterfaceBase & {
  __typename?: 'PersonnelParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
};

export type PersonnelParameterInput = {
  PersonnelParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PersonnelParameterType {
  ExitCompanyReason = 'Exit_Company_Reason',
  Salutation = 'Salutation',
  EducationLevel = 'Education_Level',
  Major = 'Major',
  Grade = 'Grade',
  StaffCategory = 'Staff_Category',
  Specialization = 'Specialization',
  Industry = 'Industry',
  Language = 'Language',
  DocumentType = 'Document_Type',
  VaccinationType = 'Vaccination_Type'
}

export type PersonnelReferenceEntity = InterfaceBase & {
  __typename?: 'PersonnelReferenceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelReferenceID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  YearsKnown?: Maybe<Scalars['Float']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
};

export type PersonnelReferenceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  YearsKnown?: Maybe<Scalars['Float']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};

export type PersonnelSecurityPolicyEntity = InterfaceBase & {
  __typename?: 'PersonnelSecurityPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelSecurityPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export enum PersonnelSecurityPolicyOption {
  Default = 'Default',
  EnforceByCompany = 'Enforce_By_Company',
  EnforceByAccessPermission = 'Enforce_By_Access_Permission',
  HideNotInUseRecords = 'Hide_Not_In_Use_Records'
}

export type PersonnelTemplateLibraryAssignmentOutput = {
  __typename?: 'PersonnelTemplateLibraryAssignmentOutput';
  JobGrade?: Maybe<Array<JobGradeEntity>>;
  SkillSet?: Maybe<Array<SkillSetEntity>>;
  JobPosition?: Maybe<Array<JobDesignationEntity>>;
  InsuranceScheme?: Maybe<Array<InsuranceSchemeEntity>>;
  RecruitmentSource?: Maybe<Array<RecruitmentSourceEntity>>;
  UserDefine?: Maybe<Array<UserDefineEntity>>;
};

export type PlatformLeaveItemEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveItemID: Scalars['String'];
  PlatformLeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveFrequency: LeaveFrequency;
  CutOffDate: Scalars['DateTime'];
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay: Scalars['Float'];
  ResignCutOffDay: Scalars['Float'];
  MonthlyCutOffDay: Scalars['Float'];
  ShortNoticeType: ShortNoticeType;
  ShortNoticePeriod: Scalars['Float'];
  CFMethod: CfMethod;
  CFValue: Scalars['Float'];
  CFMonth: Scalars['Float'];
  IsOffDay: Scalars['Boolean'];
  IsRestDay: Scalars['Boolean'];
  IsPublicHoliday: Scalars['Boolean'];
  IsHalfDay: Scalars['Boolean'];
  IsAttachment: Scalars['Boolean'];
  IsRemarks: Scalars['Boolean'];
  IsRounding: Scalars['Boolean'];
  IsConfirmedStaff: Scalars['Boolean'];
};

export type PlatformLeaveItemInput = {
  PlatformLeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLeaveTypeEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveTypeID: Scalars['String'];
  IntegratedLeaveTypeID: Scalars['String'];
  LeaveTypeCode: Scalars['String'];
  Description: Scalars['String'];
};

export type PlatformLeaveTypeInput = {
  IntegratedLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type PolicyOutput = {
  __typename?: 'PolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export enum PolicyType {
  EmploymentConfirmation = 'EmploymentConfirmation',
  Transfer = 'Transfer',
  Exit = 'Exit',
  ShortNotice = 'ShortNotice',
  NoOfDayPerApplication = 'NoOfDayPerApplication',
  LeavePendingApproval = 'LeavePendingApproval',
  ClaimSubmissionAmount = 'ClaimSubmissionAmount',
  ClaimPendingApproval = 'ClaimPendingApproval',
  RequisitionAmount = 'RequisitionAmount',
  RequisitionPendingApproval = 'RequisitionPendingApproval'
}

export type PossibleAnswerInput = {
  possible_answer_id?: Maybe<Scalars['String']>;
  answer_label?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Float']>;
  correct_answer?: Maybe<Scalars['Boolean']>;
};

export type PostClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PostPeClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export enum PostStatus {
  Draft = 'Draft',
  Posted = 'Posted'
}

export type PostedClaimDetail = {
  __typename?: 'PostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export enum PrehiringStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Invited = 'Invited'
}

export type ProbationExtendedEmployeeOutput = {
  __typename?: 'ProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<CareerLogEntity>;
};

export type ProcessAttendanceEntity = {
  __typename?: 'ProcessAttendanceEntity';
  ProcessAttendanceID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IntergrationClockType?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  AttendanceRecord?: Maybe<AttendanceRecordEntity>;
};

export type ProcessFixedAllowanceEntity = {
  __typename?: 'ProcessFixedAllowanceEntity';
  ProcessAllowanceID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeriodID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  RepeatedCount?: Maybe<Scalars['Float']>;
  HasRecurringPay?: Maybe<Scalars['Boolean']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type ProcessMonthlyTransactionEntity = {
  __typename?: 'ProcessMonthlyTransactionEntity';
  ProcessTransactionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  RepeatedCount?: Maybe<Scalars['Float']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
};

export type ProcessSalaryEntity = {
  __typename?: 'ProcessSalaryEntity';
  ProcessSalaryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CareerLogID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  RepeatedCount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  CareerLog?: Maybe<CareerLogEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type ProjectAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
  ProjectAccountHeader?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimCcMappingListEntity = InterfaceBase & {
  __typename?: 'ProjectClaimCCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  PeriodID?: Maybe<Scalars['String']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  ClaimItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailList = {
  __typename?: 'ProjectClaimGLAccountDetailList';
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'ProjectClaimGLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderList = {
  __typename?: 'ProjectClaimGLAccountHeaderList';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimProcessingPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectClaimProcessingPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  CCMappingDetails: Array<ProjectClaimCcMappingListEntity>;
  AccountDetails: Array<ProjectClaimGlAccountDetailOutputEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationDetailEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectCostAllocationID: Scalars['String'];
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  ProjectCostAllocation?: Maybe<ProjectCostAllocationEntity>;
};

export type ProjectCostAllocationDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectCostAllocationID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
};

export type ProjectCostAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetailEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseClaimHeaderID: Scalars['String'];
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectExpenseClaimHeaderEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  ProjectExpenseType?: Maybe<ProjectExpenseTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseClaimDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetails = {
  __typename?: 'ProjectExpenseClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimDetailsListing?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseClaimGlExportEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  CostCenterName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  AccountDescription?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  FinancialYear?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ClaimDetails: Array<ProjectExpenseClaimDetailEntity>;
  Approver?: Maybe<EmployeeEntity>;
};

export type ProjectExpenseClaimHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ProjectExpenseClaimRecordsItemInput = {
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
};

export type ProjectExpenseClaimRecordsListing = {
  __typename?: 'ProjectExpenseClaimRecordsListing';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ProjectExpenseHistoryListingOutput = {
  __typename?: 'ProjectExpenseHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum ProjectExpenseManagementDelegation {
  ProjectExpenseSubmissionApproval = 'Project_Expense_Submission_Approval',
  ProjectExpenseCancellationApproval = 'Project_Expense_Cancellation_Approval'
}

export type ProjectExpenseToApproveListingOutput = {
  __typename?: 'ProjectExpenseToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  ClaimHistory?: Maybe<Array<ProjectExpenseHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseTypeEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseType: Scalars['String'];
  PaymentType: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil: Scalars['DateTime'];
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectExpenseClaimDetail?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
  PayItem?: Maybe<PayItemEntity>;
};

export type ProjectExpenseTypeInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingDetailEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectGLAccountMappingID: Scalars['String'];
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID: Scalars['String'];
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectGlAccountMappingDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectGLAccountMappingID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Title: Scalars['String'];
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectGLAccountMappingDetail?: Maybe<Array<ProjectGlAccountMappingDetailEntity>>;
  ProjectAccountAssignment?: Maybe<Array<ProjectAccountAssignmentEntity>>;
};

export type ProjectGlAccountMappingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutput = {
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutputList = {
  __typename?: 'ProjectGLDetailsOutputList';
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderList = {
  __typename?: 'ProjectGLHeaderList';
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderOutput = {
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectMasterEntity = InterfaceBase & {
  __typename?: 'ProjectMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectName: Scalars['String'];
  CostCenter: Scalars['String'];
  CostCenterDescription: Scalars['String'];
  ValidUntil: Scalars['DateTime'];
  ProjectOwnerID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectOwner?: Maybe<EmployeeEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
  ProjectExpenseClaimHeader?: Maybe<Array<ProjectExpenseClaimHeaderEntity>>;
};

export type ProjectMasterInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  CostCenterDescription?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  ProjectOwnerID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetApproverAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetPeriod?: Maybe<ProjectTimesheetPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type ProjectTimesheetApproverAssignmentInput = {
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverAssignmentID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodFrom?: Maybe<Scalars['String']>;
  PeriodTo?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['JSON']>>;
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectTimesheetApproverMasterEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Name: Scalars['String'];
  EmailAddress: Scalars['String'];
  ClientName: Scalars['String'];
  Department: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  LastUpdatedDate?: Maybe<Scalars['DateTime']>;
  ConfirmationDT?: Maybe<Scalars['DateTime']>;
  ProjectTimesheetPeriod?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
};

export type ProjectTimesheetApproverMasterInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetCheckOverlapOutput = {
  __typename?: 'ProjectTimesheetCheckOverlapOutput';
  ApproverPeriodRangeOverlap?: Maybe<Scalars['String']>;
  EmployeeListingBySubscriptionAccount?: Maybe<Array<EmployeeEntity>>;
};

export type ProjectTimesheetDailyEntryEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetDailyEntryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
  EntryDate: Scalars['DateTime'];
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetMonthlyRecord?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
};

export type ProjectTimesheetDailyEntryInput = {
  ProjectTimesheetDailyEntryID?: Maybe<Scalars['String']>;
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  EntryDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetMonthlyRecordEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetMonthlyRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  CompanyID: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYearMonth: Scalars['DateTime'];
  ApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetDailyEntry?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
};

export type ProjectTimesheetMonthlyRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ApprovalLog?: Maybe<Array<ApprovalLog>>;
};

export type ProjectTimesheetPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  PeriodFrom: Scalars['String'];
  PeriodTo: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetApproverAssignment?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
  InUse: Scalars['Boolean'];
};

export type ProjectTimesheetPeriodListingOutput = {
  __typename?: 'ProjectTimesheetPeriodListingOutput';
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  PeriodInfoline?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  isAssignedToApprover?: Maybe<Scalars['Boolean']>;
};

export type ProjectTimesheetReportForApproverInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<DocumentsInput>;
  EmployeeList?: Maybe<Array<Scalars['JSON']>>;
};

export type PublishEaEntity = InterfaceBase & {
  __typename?: 'PublishEAEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type PublishEaInput = {
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishEaSingleEmployeeListingOutput = {
  __typename?: 'PublishEASingleEmployeeListingOutput';
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  CompanyName?: Maybe<Scalars['String']>;
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishPayslipEntity = InterfaceBase & {
  __typename?: 'PublishPayslipEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishPaySlipID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayPeriod: PayPeriodEntity;
  PayrollCycle: PayrollCycleEntity;
};

export type PublishPayslipInput = {
  CompanyID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum QuantitativeType {
  Value = 'Value',
  Unit = 'Unit',
  Date = 'Date',
  Percentage = 'Percentage'
}

export type Query = {
  __typename?: 'Query';
  GetPrivateDocumentByFileName: Array<Scalars['JSON']>;
  TestLoader: Array<DutyRosterEntity>;
  getDutyRoster: Array<DutyRosterEntity>;
  DutyRosterInfoLine: Scalars['JSON'];
  DutyRosterEmployeeSum: Scalars['JSON'];
  DutyRosterDates: Scalars['JSON'];
  DutyRosterSummaryByEmployee: Scalars['JSON'];
  DutyRosterSummary: Scalars['JSON'];
  DutyRosterByExcel: Scalars['JSON'];
  getDutyRosterByEmployeeListV2: Scalars['JSON'];
  getDutyRosterByEmployee: Scalars['JSON'];
  getDutyRosterByEmployeeList: Scalars['JSON'];
  NotificationListing: Array<NotificationEntity>;
  NewsReadListing: Array<NewsReadEntity>;
  EmployeeCalendar?: Maybe<EmployeeCalendarOutput>;
  GetAttendanceRecordPairedData: Scalars['JSON'];
  GetAttendanceRecordPairedDataCount: Scalars['JSON'];
  GetFinalizeAnalysisDataByEmployee: Scalars['JSON'];
  GetFinalizeAnalysisData: Scalars['JSON'];
  GetFinalizeAnalysisDataCount: Scalars['JSON'];
  GetClockPairing: Scalars['String'];
  GetClockPairingRedirect: Scalars['String'];
  getAttenRecords: Array<AttendanceRecordEntity>;
  AttendanceRecordByHikvision: Scalars['JSON'];
  AttendanceRecordByHikvisionByCompany: Scalars['JSON'];
  getAttendanceRecord: Array<AttendanceRecordEntity>;
  getAttendanceRecordwithHikvision: Array<Scalars['JSON']>;
  AttendanceRecordSummary?: Maybe<Scalars['JSON']>;
  AttendanceRecordByExcel: Scalars['JSON'];
  AttendanceRecordListingByClockType: AttendanceRecordEntity;
  AttendanceRecordListing: Array<AttendanceRecordEntity>;
  AttendanceRecordByPeriod: Array<AttendanceRecordEntity>;
  getAttendanceRecordLastModified: Array<Scalars['DateTime']>;
  getAttendanceRecordTotalCount: Array<Scalars['JSON']>;
  getContactTracing: Scalars['JSON'];
  GetAttendanceRecordManualEntryPageTotalCount?: Maybe<Scalars['Float']>;
  GetServerDateTime: Scalars['JSON'];
  AppraisalCategoryListing: Array<AppraisalCategoryEntity>;
  AppraisalCategoryListingAll: Array<AppraisalCategoryEntity>;
  AppraisalCategoryItemListingAll: Array<AppraisalCategoryItemEntity>;
  AppraisalPolicyListing: Array<AppraisalPolicyEntity>;
  AppraisalTemplateByJobPositionListing: Array<AppraisalTemplateHeaderEntity>;
  GetAppraisalTemplateByJobPosition: AppraisalTempleteByJobPositionEntity;
  AppraisalScheduleListing: Array<AppraisalScheduleHeaderEntity>;
  GetAppraisalCompanySetting: AppraisalCompanySettingEntity;
  GetCompanyAppraisalTemplete: Array<AppraisalTempleteByJobPositionEntity>;
  GetCompanyAppraisalPolicy: AppraisalCompanySettingEntity;
  FinalRatingListing: Array<FinalRatingHeaderEntity>;
  ScaleRatingListing: Array<ScaleRatingHeaderEntity>;
  BonusSimulationListing: Array<BonusSimulationEntity>;
  IncrementSimulationListing: Array<IncrementSimulationEntity>;
  GetBonusRecordByCompanyOrgUnitList: Scalars['JSON'];
  GetIncrementRecordByCompanyOrgUnitList: Scalars['JSON'];
  AppraisalTempleteListing: Array<AppraisalTempleteEntity>;
  AppraisalTempleteListingAll: Array<AppraisalTempleteEntity>;
  GetEmployeeListingByscheduledAndQuater: Array<AppraisalEmployeeLisitngOutput>;
  GetEmployeeAppraisalHeader: Array<EmployeeAppraisalHeaderEntity>;
  GetEmployeeAppraisalDetails: Array<EmployeeAppraisalDetailEntity>;
  ModerationListing: Array<ModerationRatingEntity>;
  GetAppraisalDefaultSettingByJobPosition: AppraisalTempleteByJobPositionEntity;
  GetEmployeeKPITempleteLatestVersion: EmployeeKpiHeaderEntity;
  GetEmployeeKPIPlanningRecord: EmployeePlanningOutput;
  GetSubordinateKPIPlanning: Array<Scalars['JSON']>;
  GetEmployeeKPIPlanningByYear: Array<EmployeePlanningOutput>;
  GetKPIPlanningDetails: Array<EmployeeKpiCategoryEntity>;
  StaffPerformanceAnalysisSummaryListing: Array<StaffPerformanceAnalysisSummaryOutput>;
  ApprovalPolicySummary: Scalars['JSON'];
  ApprovalPolicyAssignmentSummary: Scalars['JSON'];
  ApprovalPolicyList: Array<ApprovalPolicyEntity>;
  ApprovalPolicyDetails: ApprovalPolicyEntity;
  IsApprovalPolicyTitleExist: Scalars['Boolean'];
  ApprovalPolicyAssignmentList?: Maybe<ApprovalPolicyAssignmentEntity>;
  NewApprovalPolicyAssignmentList?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  EmployeeListingByRoleID: Array<EmployeeEntity>;
  ApproverListByCompany: Scalars['JSON'];
  GetAttendanceAnalysisTemplate?: Maybe<Array<AttendanceAnalysisTemplateEntity>>;
  EmployeeAttendanceCorrectionRequestListing: Array<AttendanceCorrectionHeaderEntity>;
  EmployeeAttendanceCorrectionRequestDetail: AttendanceCorrectionHeaderEntity;
  IsAttCorReqRecordAlreadyExistAndPendingApproval: Scalars['Boolean'];
  AdminAttendanceCorrectionRequestListing?: Maybe<Array<AttendanceCorrectionHeaderEntity>>;
  AdminAttendanceCorrectionRequestListingTotalCount?: Maybe<Scalars['Float']>;
  GetAttendanceCorrectionToDo?: Maybe<Array<AttendanceCorrectionHeaderEntity>>;
  AttendancePostingListing: Array<AttendancePostingOutput>;
  AttendancePostingListingCount: Scalars['JSON'];
  ExportAttendanceProcessing: Array<ExportAttendanceProcessing>;
  AdjustedValueQuantityDetails: Array<AttendancePostingOutput>;
  getLastPostedAttendanceProccess?: Maybe<Scalars['JSON']>;
  AttendancePeriodSummary?: Maybe<Scalars['JSON']>;
  GetLatestAttandanceCalculationDateByCompany?: Maybe<Scalars['JSON']>;
  getOvertime: Array<OvertimeEntity>;
  GetTotalOverTime: Scalars['JSON'];
  OvertimeListing: Array<OvertimeEntity>;
  OvertimeWithdrawalChecking: Scalars['Boolean'];
  OvertimeListingByExcel: Scalars['JSON'];
  OvertimeListingByYear: Array<OvertimeEntity>;
  OvertimeListingByDateTimeV2: Array<OvertimeEntity>;
  OvertimeListingByDateTime: Array<OvertimeEntity>;
  OvertimeListingByPeriodV2: Array<OvertimeEntity>;
  OvertimeListingByPeriod: Array<OvertimeEntity>;
  ViewOvertimeApprovalHistory?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeApproveToDo?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeToApprove?: Maybe<Array<OvertimeEntity>>;
  OvertimePeriodSummary?: Maybe<Scalars['JSON']>;
  OvertimeMenuSummary?: Maybe<Scalars['JSON']>;
  EmployeeOvertimeChecking: Scalars['JSON'];
  BatchOvertimeListing: Array<BatchOvertimeEntity>;
  AdminBatchOvertimeListing: Array<BatchOvertimeEntity>;
  GetBatchOvertimeToDo?: Maybe<Array<BatchOvertimeEntity>>;
  CompanyBusinessInsight: Array<BusinessInsightCompanyOutput>;
  EmployeeBusinessInsightSummary: BusinessInsightSummaryOutput;
  EmployeeBusinessInsightRawListing: Array<BusinessInsightListingOutput>;
  EmployeeBusinessInsightListing?: Maybe<Array<BusinessInsightOutput>>;
  PayrollBusinessInsightStatutoryListing: Array<BusinessInsightPayrollStatutoryOutput>;
  PayrollBusinessInsightSalaryOverheadListing: Array<BusinessInsightPayrollSalaryOverheadOutput>;
  PayrollBusinessInsightPayItemListing: Array<BusinessInsightPayrollPayItemOutput>;
  PayrollBusinessInsightSummary: BusinessInsightSummaryPayrollOutput;
  TopLeaveTakerByType: Array<LeaveEntitlementByLeaveTypeListing>;
  LeaveBILeaveTakenListing: Array<BusinessInsightLeaveTakenOutput>;
  TopClaimTaker: Array<TopClaimTakerOutput>;
  ClaimBIClaimTaken: Array<BusinessInsightClaimTakenOuput>;
  TopRequisitionTaker: Array<TopRequisitionTakerOutput>;
  RequisitionTakenSummaryByType: Array<RequisitionTakenSummaryByTypeOutput>;
  BIManagementListing: Scalars['JSON'];
  HeadCountSummary: Scalars['JSON'];
  OverHeadSummary: Scalars['JSON'];
  TurnOverSummary: Scalars['JSON'];
  OvertimeSummary: Scalars['JSON'];
  LeaveSummary: Scalars['JSON'];
  ClaimSummary: Scalars['JSON'];
  ClaimByMonth: Scalars['JSON'];
  HeadCountAndBasicSalaryByMonth: Scalars['JSON'];
  EmployeeYOS: Scalars['JSON'];
  EmployeeSalary: Scalars['JSON'];
  EmployeeAge: Scalars['JSON'];
  EmpGender: Scalars['JSON'];
  EmpRace: Scalars['JSON'];
  EmpEmploymentType: Scalars['JSON'];
  LeaveByMonth: Scalars['JSON'];
  OverheadByMonth: Scalars['JSON'];
  ContributionByMonth: Scalars['JSON'];
  OvertimeHrsAndCost: Scalars['JSON'];
  StaffTurnOverByMonth: Scalars['JSON'];
  GetClaimManagementListing?: Maybe<Array<ClaimManagementListing>>;
  GetClaimRecordsListing?: Maybe<Array<ClaimRecordsListing>>;
  GetClaimRecordsTotalCount: Scalars['Float'];
  ClaimMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimRecordsDetails?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimRecordsHistoryListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimVerificationListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimCount: Scalars['JSON'];
  GetApprovedClaimVerificationDetails?: Maybe<ApprovedClaimVerificationDetailsOutput>;
  GetClaimPostingListing?: Maybe<Array<ClaimPostingListingOutput>>;
  GetClaimPostingCount?: Maybe<Scalars['JSON']>;
  GetClaimPostingDetail?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimPostingPeriodListing?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimPostingPeriodListingV2?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimEntitlementByClaimTypeListing?: Maybe<Array<EmpClaimEntitlementByClaimTypeOutput>>;
  GetClaimEntitlementByClaimTypeListingCount?: Maybe<Scalars['JSON']>;
  GetClaimEntitlementByEmployeeListing?: Maybe<Array<EmpClaimEntitlementByEmployeeOutput>>;
  GetClaimToApprove?: Maybe<Array<ClaimToApproveListingOutput>>;
  GetClaimApprovalHistory?: Maybe<Array<ClaimToApproveListingOutput>>;
  ClaimHomeMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimReportDetails?: Maybe<Scalars['JSON']>;
  ClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetEmpListingbyCompany: Array<EmployeeEntity>;
  GetEmpsClaimEntitlement: Array<EmpClaimEntitlementOutput>;
  GetMyClaimEntitlement: EmpClaimEntitlementOutput;
  GetEmpsClaimBalance: Array<EmpClaimBalanceOutput>;
  GetMyClaimBalance: EmpClaimBalanceOutput;
  GetMyClaimPoolBalance: EmpClaimPoolBalanceOutput;
  GetMyClaimListing: MyClaimListingOutput;
  GetMyClaimDetails: MyClaimHeaderDetails;
  VerifyMyClaim: Scalars['Boolean'];
  GetMainClaimListing: Array<GetMainClaimListingOutput>;
  GetEmpClaimListing: Array<GetEmpClaimListingOutput>;
  GetApprovedDetail: Array<GetApprovedDetailOutput>;
  GetClaimRecordListing: Array<ClaimRecordOutput>;
  GetApproveClaimVerification: Array<ApproveClaimVerificationOutput>;
  GetClaimPostingManagement: Array<ClaimPostingManagementOutput>;
  GetClaimRecordHistory: Array<ClaimRecordHistoryOutput>;
  GetCompletedDetail: Array<GetCompletedDetailOutput>;
  MyClaimDetail: ClaimDetailsEntity;
  ClaimListing: Array<ClaimHeaderEntity>;
  GetAllExpenseAccount: Array<JobAccountEntity>;
  ClaimValidityDetails: ClaimSettingEntity;
  ClaimTypeListing: Array<ClaimTypeEntity>;
  ClaimTypeListingAll: Array<ClaimTypeEntity>;
  ClaimTypeTemplateListing: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateListingForChecking: Array<ClaimBenefitEntity>;
  ClaimPolicyListing: Array<ClaimBenefitEntity>;
  ClaimPolicyListingAll: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateDetail: ClaimBenefitEntity;
  GetClaimSettingsListing: Array<ClaimSettingsListing>;
  ClaimTypeConditionList: Array<ClaimTemplateEntity>;
  ClaimTemplateListing: Array<ClaimTemplateEntity>;
  IsClaimSubjectExists: Scalars['Boolean'];
  ClaimPoolListing: Array<ClaimPoolEntity>;
  GetEmployeePolicies?: Maybe<Scalars['JSON']>;
  GetEmployeeClaimPolicies?: Maybe<Scalars['JSON']>;
  BankDetail: Array<BankInfoEntity>;
  BankListing: Array<BankInfoEntity>;
  IsAccountNoExists: Scalars['Boolean'];
  IsAccountNameExists: Scalars['Boolean'];
  HolidayListing: Array<HolidayEntity>;
  CompanyOffDaysListing: Array<CompanyOffDayEntity>;
  CompanyOffDaysListingWithFilters: Scalars['JSON'];
  OffRestDayListing: Array<RecurringRestOffDayEntity>;
  CalendarStateListing: Array<CalendarOffDayOutput>;
  EmployeeStateAssignListing: Array<CalendarOffDayEmployeeOutput>;
  CalendarPolicyListing: Array<CalendarPolicyOutput>;
  EmployeeHomeCalendar?: Maybe<Array<HomeCalendarOutput>>;
  CompanyDeclaredHolidayListing: Array<CompanyDelaredHolidayEntity>;
  CompanyDeclaredHolidayListingByCalendarPolicyID: Array<CompanyDelaredHolidayEntity>;
  DocumentListing: Array<DocumentsEntity>;
  DocumentListingCount: Scalars['JSON'];
  PersonnelDocumentListing: Array<DocumentsEntity>;
  CompanyFolderListing: Array<FolderEntity>;
  CompanyListingByEmail: Scalars['JSON'];
  EmployeeCompanyListing: Scalars['JSON'];
  CompanyListWithAccessLevel?: Maybe<Array<Scalars['JSON']>>;
  CompanyListing: Array<CompanyEntity>;
  CompanySummary: CompanyEntity;
  CompanySummarySimplified: CompanyEntity;
  JobPositionListing: Array<JobDesignationEntity>;
  JobDesignationDetail: JobDesignationEntity;
  JobDesignationGradeListing: Array<JobDesignationGradeEntity>;
  JobGradeByJobDesignationListingV2: Scalars['JSON'];
  JobGradeByJobDesignationListing: Array<JobGradeByJobDesignationOutput>;
  JobDesignationListing: Array<JobDesignationEntity>;
  RecruitmentSourceListing: Array<RecruitmentSourceEntity>;
  GetCompanyCyclePeriodList?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  GetCompanyCyclePeriodListV2?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  CompanyAltAddressListing: Scalars['JSON'];
  getCompanyStatutoryAccountListing?: Maybe<Array<CompanyStatutoryAccountOutput>>;
  CompanyStatutoryDetail?: Maybe<CompanyStatutoryEntity>;
  IsCompanyHierarchyExists: Scalars['Boolean'];
  CompanyHierarchyList: Scalars['JSON'];
  CompanyOrgUnitListV2: Scalars['JSON'];
  CompanyOrgUnitList: Scalars['JSON'];
  CompanyOrgUnitListWithFilter: Scalars['JSON'];
  CompanyOrgUnitListWithEmpCheck: Scalars['JSON'];
  JobPositionByOrgUnitListing: Scalars['JSON'];
  JobPositionAssignmentList: Scalars['JSON'];
  CompanyAccessPermList: Scalars['JSON'];
  GetAllCoOrgUnitListBySubscription: Scalars['JSON'];
  GetEmpCoOrgUnitList: Scalars['JSON'];
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentDivisionListing: Array<DepartmentEntity>;
  DepartmentDetail: Array<DepartmentEntity>;
  DivisionListing: Array<DepartmentEntity>;
  DivisionDetail: Array<DepartmentEntity>;
  GetInsuranceScheme: Array<InsuranceSchemeEntity>;
  InsuranceSchemeListing: Array<InsuranceSchemeEntity>;
  InterbankDetail: Array<InterbankGiroEntity>;
  InterbankListingBulk: Array<InterbankGiroEntity>;
  InterbankListing: Array<InterbankGiroEntity>;
  GetBankInfoListing: Array<BankInfoOutput>;
  GetBankInfoListingV2: Array<BankInfoOutput>;
  MyJobAccountListing: Array<MyJobAccountEntity>;
  JobAccountListing: Array<JobAccountEntity>;
  JobAccountDetail: Array<JobAccountEntity>;
  JobGradeDetail: JobGradeEntity;
  JobGradeListing: Array<JobGradeEntity>;
  JobGradeListingByCompany: Array<JobGradeEntity>;
  JobGradePolicyListing: Array<JobGradePolicyEntity>;
  JobGradePolicyListingAll: Array<JobGradePolicyEntity>;
  LeaveRoundingListing: Array<LeaveRoundingEntity>;
  LeaveCompulsoryPolicyListing: Array<LeaveCompulsoryPolicyEntity>;
  LeaveTemplateListing: Array<LeaveTemplateEntity>;
  LHDNReportListing: Array<PayItemEntity>;
  LHDNReportDropdownListing: Array<LhdnReportEntity>;
  LHDNReportDetail: PayItemEntity;
  GetLHDNReportListing?: Maybe<Array<DisplayReportTypeSequenceOutput>>;
  NotificationPolicyDetail: Array<NotificationPolicyEntity>;
  NotificationPolicyListing: Array<NotificationPolicyEntity>;
  NotificationPolicyConditionListing: Array<NotificationPolicyConditionEntity>;
  NotificationPolicyConditionDetail: NotificationPolicyConditionEntity;
  NotificationPolicySummaryDates: NotificationPolicyLastUpdatedOutput;
  PayrollCycleDetail: PayrollCycleEntity;
  PayrollProcessPayrollCycleListingByTLA: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollCycleByPayPeriod: Scalars['JSON'];
  PayPeriodsByCompany: Scalars['JSON'];
  PayItemListingByTLA: Array<PayItemListingByTlaOutput>;
  PayrollCycleListing: Array<PayrollCycleEntity>;
  PayrollCycleListingV2: Array<PayrollCycleEntity>;
  PayrollCycleListingV3: Array<PayrollCycleEntity>;
  PayrollCycleListingWithAudit: Scalars['JSON'];
  IsPayrollCycleLocked: Scalars['Boolean'];
  ReportingGroupListing: Array<ReportingGroupEntity>;
  ReportingStructureGroupOptionList: Scalars['JSON'];
  ReportingCompanyGroupListing: Scalars['JSON'];
  ReportingGroupTitleExist: Scalars['Boolean'];
  GetReportingStructureListing: ReportingStructureOutput;
  GetSuperior?: Maybe<Scalars['JSON']>;
  GetSkillSet: Array<SkillSetEntity>;
  GetSkillSetDetail: Array<SkillSetDetailEntity>;
  UserDefineListing: Array<UserDefineEntity>;
  CoolbinetOpenDoor: Scalars['JSON'];
  GetLeaveOffDay: Scalars['JSON'];
  GetLeaveOffDayByAllStateWithCompany: Scalars['JSON'];
  GetLeavePolicyByEmployee?: Maybe<Scalars['JSON']>;
  GetEmployeeLeavePolicyListByDate?: Maybe<Scalars['JSON']>;
  GetPayItemPolicy?: Maybe<Scalars['JSON']>;
  GetLeavePolicyByJobGradeID?: Maybe<Array<LeaveItemEntity>>;
  GetOffboardEncashPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetPlatformNewLeaveTypePolicy?: Maybe<LeaveEntitlementTemplate>;
  GetMyLeaveEntitlement?: Maybe<EmpLeaveEntitlementOuput>;
  GetEmpLeaveBalance?: Maybe<Array<EmpLeaveBalanceOuput>>;
  GetMyLeaveBalance?: Maybe<EmpLeaveBalanceOuput>;
  EmployeeCalendarLeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  EmployeeLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetEmpLeaveDetail?: Maybe<EmpLeaveDetailOuput>;
  GetLeaveRoundedValue?: Maybe<Scalars['Float']>;
  LeaveTypeListing: Array<LeaveTypeEntity>;
  LeaveReasonListing: Array<LeaveReasonEntity>;
  LeaveDiseaseListing: Array<LeaveDiseaseEntity>;
  LeaveCompanySettingSummary?: Maybe<Scalars['JSON']>;
  LeaveTypeConditionList: Array<LeaveTemplateEntity>;
  LeavePolicyListing?: Maybe<Array<LeaveItemEntity>>;
  LeavePolicyByID?: Maybe<LeaveItemEntity>;
  LeaveTypeTemplateListing: Array<LeaveBenefitEntity>;
  LeaveTypeTemplateDetail: LeaveBenefitEntity;
  GetIntegratedLeaveBalance: Scalars['String'];
  GetEmployeesLeaveSummaryReport?: Maybe<Array<EmployeeLeavesSummary>>;
  GetEmployeesLeaveSummaryReportNew?: Maybe<Array<EmployeeLeavesSummary>>;
  GetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  EnhanceGetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  GetLeaveEntitlementByLeaveTypeListingTotalCount: Scalars['Float'];
  GetLeaveEntitlementByLeaveTypeListing?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  GetALBalanceForResignedEmployees?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  getEmployeeCalendar: Scalars['JSON'];
  GetTakenLeaveByEmployeeID?: Maybe<Array<LeaveDetailsEntity>>;
  GetEmployeeEntitlementByYear?: Maybe<Scalars['JSON']>;
  GetLeaveAdjustmentEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  GetLeaveAdjustmentEmployeeListingCount?: Maybe<Scalars['JSON']>;
  GetEmployeeLeaveCriteriaByEmpID?: Maybe<Scalars['JSON']>;
  GetUserDashboardSetting?: Maybe<UserDashboardControlEntity>;
  GetOffboardingList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveSummary?: Maybe<Array<Scalars['JSON']>>;
  GetBirthdayList: Array<BasicDashboardCardOutput>;
  GetConfirmationDueList: Array<BasicDashboardCardOutput>;
  GetAnnouncementList: Array<AnnouncementDashboardCardOutput>;
  GetAttendanceList: Array<AttendanceDashboardCardOutput>;
  getDataChangesListing?: Maybe<Array<DataChangesEntity>>;
  getDocument: Array<DocumentsEntity>;
  getDocumentsByParentID: Array<DocumentsEntity>;
  getMedicalRecords: Array<MedicalRecordEntity>;
  getDocuments: Array<DocumentsEntity>;
  EmployeeDependentsSummary: EmployeeDependentsEntity;
  SelectedDataChangesApprovalPolicy: Scalars['JSON'];
  GetDataChangesToDo?: Maybe<Array<DataChangesEntity>>;
  ViewDataChangesApprovalHistory?: Maybe<Array<DataChangesEntity>>;
  GetDataImportTemplate: Scalars['JSONObject'];
  ShopeeImport: Scalars['Boolean'];
  ShopeeImportRedirect: Scalars['Boolean'];
  EmployeePendingActivateListing: Array<ContactEntity>;
  DelegationList?: Maybe<Array<DelegationEntity>>;
  DelegationInfoLine?: Maybe<Array<DelegationEntity>>;
  BulletinListing: Scalars['JSON'];
  NewsListing: Array<NewsEntity>;
  ELearningNewsCount?: Maybe<Scalars['Float']>;
  NewsCount?: Maybe<Scalars['Float']>;
  ClaimDetailReportListing: Array<Scalars['JSON']>;
  TTDA_ClaimDetailReportListing: Array<Scalars['JSON']>;
  GetPayrollReportListing?: Maybe<Array<PayrollReportOutput>>;
  GetCompanyStatutoryListing?: Maybe<Array<CompanyStatutoryOutput>>;
  GetCompanyReportYearListing?: Maybe<Array<ReportYearOutput>>;
  GetPayItemListing?: Maybe<Array<ReportPayItemOutput>>;
  EmployeeMasterExcelReport: Array<Scalars['JSON']>;
  GetLeaveAnalysisExcelData?: Maybe<Array<Scalars['JSON']>>;
  SimpleShiftGroupList: Array<ShiftGroupEntity>;
  SimpleShiftList: Array<ShiftEntity>;
  SimpleJobPositionListing: Array<JobDesignationEntity>;
  AttendanceListingReport: Array<Scalars['JSON']>;
  EmployeeClockingListingReport: Array<Scalars['JSON']>;
  AbsenteeismListingReport: Array<Scalars['JSON']>;
  IncompleteClockingListingReport: Array<Scalars['JSON']>;
  IrregularAttendanceListingReport: Array<Scalars['JSON']>;
  LateInEarlyOutListingReport: Array<Scalars['JSON']>;
  GetNextDocNumber: Scalars['JSON'];
  DocumentNumberingList: Array<DocumentNumberHeaderEntity>;
  DocumentNumberingAssignmentList: Scalars['JSON'];
  HasEmployeeDocNumber: Scalars['Boolean'];
  getBatchEntryByShift: Array<BatchEntryByShiftEntity>;
  getBatchEntryByPattern: Array<BatchEntryByShiftPatternEntity>;
  GetEmployeeAppraisal: Array<EmployeeAppraisalEntity>;
  CareerLogListing: Array<CareerLogEntity>;
  GetEmployeeDiary: Array<EmployeeDiaryEntity>;
  EmployeeDocumentListing: Array<DocumentsEntity>;
  TestNotification: Scalars['Boolean'];
  EmployeeListForSpecificSection: Scalars['JSON'];
  EmployeeListForSpecificSectionCount: Scalars['JSON'];
  EmployeeListForReporting: Scalars['JSON'];
  EmployeeListForReportingCount: Scalars['JSON'];
  EmployeeListing: Array<EmployeeEntity>;
  EmployeeListingv2: Array<EmployeeListingOutputv2>;
  EmployeeListingCount?: Maybe<Scalars['JSON']>;
  SubordinateMenuSummary: Scalars['JSON'];
  GetOrgChart: Scalars['JSON'];
  SubordinateTree: Scalars['JSON'];
  SubordinateTreeTimeAttendance: Scalars['JSON'];
  TMSEmployeeListing: Array<EmployeeEntity>;
  TMSEmployeeListingCount?: Maybe<Scalars['JSON']>;
  EmployeeListingBySubscriptionAccount: Array<EmployeeEntity>;
  EmployeeListingBySubscriptionAccountCount?: Maybe<Scalars['JSON']>;
  PersonnelEmployementInfoListing: Scalars['JSON'];
  PersonnelEmployementInfoListingCount: Scalars['JSON'];
  EmployeeListForJobInfo: Scalars['JSON'];
  EmployeeAppSummary: Scalars['JSON'];
  EmployeeAppDocSummary: Scalars['JSON'];
  EmployeeSummary: EmployeeEntity;
  EmployeeDelegationSummary: Scalars['JSON'];
  EmployeeContactID: EmployeeEntity;
  EmployeeInfoKpi: EmployeeEntity;
  ZakatSummary?: Maybe<Scalars['String']>;
  EmployeeSummaryBySubscription: EmployeeEntity;
  GetEmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  IsSuperUserEmployee: Scalars['Boolean'];
  FilterRequisitionListing: Array<RequisitionEntity>;
  RequisitionListing: Array<RequisitionEntity>;
  FixedAllowanceListing: Array<RecurringPayEntity>;
  FilterEmployeeLeaveRecords: Array<LeaveHeaderEntity>;
  TaxReliefListingDefaultExcluded: Array<TaxReliefEntity>;
  EmployeeTaxReliefListing: Array<EmployeeTaxReliefEntity>;
  EmployeeTaxReliefDetails: Array<EmployeeTaxReliefEntity>;
  EmployeeAssetListing: Array<EmployeeAssetEntity>;
  EmployeeAssetDetail: EmployeeAssetEntity;
  EmployeeBenefitInKindListing: Array<EmployeeBenefitInKindEntity>;
  EmployeeBenefitInKindDetailForYear: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindDetail: EmployeeBenefitInKindEntity;
  EmployeeDependentsListing: Array<EmployeeDependentsEntity>;
  hasEmergencyContact: Scalars['Boolean'];
  MyEmployeeInfo?: Maybe<EmployeeEntity>;
  MyNewsList: Scalars['Float'];
  MyApplicationDetail: MyApplicationOutput;
  EmployeeCP22Listing: Array<EmployeeCp22Output>;
  GetEmployeeListing?: Maybe<Array<EmployeeListingOutput>>;
  EmployeeFilterListingBySubscriptionAccount?: Maybe<Array<EmployeeFilterListingBySubscriptionAccountOutput>>;
  GetSignatoryListing?: Maybe<Array<EmployeeListingOutput>>;
  GetEmployeeListingWithPayrollInput?: Maybe<Array<EmployeeListingOutput>>;
  EmployeeDirectoryListing: Scalars['JSON'];
  EmployeeDirectoryListingCount: Scalars['JSON'];
  EmployeeDirectoryDetails: Scalars['JSON'];
  GetEmployeeInsuranceScheme: Array<EmployeeInsuranceSchemeEntity>;
  EmployeeNotificationListing: Array<EmployeeNotificationOutput>;
  SubordinateListing: Array<EmployeeEntity>;
  GetEmployeeTraining: Array<EmployeeTrainingEntity>;
  EmployeeUserDefineFieldsListing: Array<EmployeeUserDefineFieldsEntity>;
  EmployeeUserDefineFieldsDetailListing: Array<EmployeeUserDefineFieldsDetailEntity>;
  EmployeeUserDefineFieldsDetailSummary?: Maybe<Scalars['String']>;
  ZakatContributionListing: Array<ZakatContributionEntity>;
  ZakatContributionDetails: Array<ZakatContributionEntity>;
  IsZakatPeriodOverlap: Scalars['Boolean'];
  GetEmployeeAppSecurityListing?: Maybe<Array<EmployeeAppSecurityOutput>>;
  GetEmployeeAppSecurityAssignmentListing?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeAppSecurityPermissionsByContactID?: Maybe<Scalars['JSON']>;
  EaDocumentsByEmployeeID: Array<Scalars['JSON']>;
  PayslipDocumentsByEmployeeID: Array<Scalars['JSON']>;
  EonDocumentsByBatch: Array<Scalars['JSON']>;
  SystemEonDocumentsCount: Scalars['Float'];
  HelpdeskListingReport?: Maybe<Array<Scalars['JSON']>>;
  HelpdeskSubjectListing: Array<HelpdeskSubjectEntity>;
  HelpdeskAssigneeListing: Array<EmployeeEntity>;
  HelpdeskSubjectDetail: HelpdeskSubjectEntity;
  IsHelpdeskSubjectExist: Scalars['Boolean'];
  getHelpdeskTicketSummary?: Maybe<Scalars['JSON']>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  HelpdeskTicketListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskToDoListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskAssigneeApprovalHistory?: Maybe<Array<HelpdeskTicketEntity>>;
  BlockLeaveListing: Array<BlockLeaveEntity>;
  BlockLeaveEmpListing?: Maybe<Array<BlockLeaveEntity>>;
  GetLeaveEntitlementTable: Array<EmployeeLeaveEntitlementEntity>;
  GetCarryForwardEncashmentPostedListing?: Maybe<Array<EncashCarryForwardLeaveOutput>>;
  GetCarryForwardEncashmentPendingListing?: Maybe<Array<YecfListingOutput>>;
  ViewLeaveApprovalHistory?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveRecordsListing?: Maybe<Array<LeaveRecordsOutput>>;
  GetLeaveRecordsTotalCount?: Maybe<Scalars['Float']>;
  GetLeaveReplacementListingByEmployee?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetLeaveAdjustmentListing?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetCompulsoryLeaveListing?: Maybe<Array<CompulsoryLeaveOutput>>;
  GetCompulsoryLeaveTotalCount?: Maybe<Scalars['Float']>;
  GetCompulsoryLeaveDetail?: Maybe<CompulsoryLeaveOutput>;
  GetLeavePostingListing?: Maybe<Array<LeavePostingOutput>>;
  GetLeaveApproveToDo?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveToAprove?: Maybe<Array<LeaveHeaderEntity>>;
  LeaveAdjustmentDetail: LeaveHeaderEntity;
  GetLeavePostingPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetYECFPendingListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPendingListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  FilterGetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  LeaveMenuSummary?: Maybe<Scalars['JSON']>;
  LeaveHomeSummary: LeaveHomeSummaryOutput;
  LeaveTakenListing?: Maybe<Array<LeaveTakenOutput>>;
  GetLeaveByMonthCalendar?: Maybe<Scalars['JSON']>;
  GetLeaveByMonthCalendarToDo?: Maybe<Scalars['JSON']>;
  GetLeaveMaxBalancePendingListingWithFilter?: Maybe<Array<Scalars['JSON']>>;
  GetLeaveMaxBalancePostedListingWithFilter?: Maybe<Array<Scalars['JSON']>>;
  CheckLeaveWithdrawalValidity: Scalars['Boolean'];
  GetCareerMovementEmployees: Array<CareerLogEntity>;
  GetCareerMovementEmployeesCount: Scalars['Float'];
  LeaveOutOfStateListing: Array<LeaveOutOfStateEntity>;
  GLAccountMappingList: Scalars['JSON'];
  GLAccountHeaderList: Array<GlAccountHeaderEntity>;
  GetAccountMappingAssignment: GlAccountAssignmentEntity;
  GLAccountHeaderListWithDetails: Scalars['JSON'];
  PayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ClaimAccountMappingList: Scalars['JSON'];
  ClaimAccountHeaderList: Array<ClaimAccountHeaderOutput>;
  GetClaimAccountMappingAssignment: Array<ClaimAccountHeaderEntity>;
  ClaimAccountHeaderListWithDetails: Scalars['JSON'];
  PEClaimAccountHeaderListWithDetails: Scalars['JSON'];
  GetClaimAccountAssignment?: Maybe<ClaimAccountAssignmentEntity>;
  GetGLClaimRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  ClaimGLCycleProcess: Scalars['Boolean'];
  CostCenterMappingList: Scalars['JSON'];
  IsGLProcessed: Scalars['Boolean'];
  GetGLData: Scalars['JSON'];
  ProjectCostCenterMappingListForGLExport: Scalars['JSON'];
  CostCenterMappingListForGLExport: Scalars['JSON'];
  CurrentMobileVersion?: Maybe<Scalars['JSON']>;
  MobileVersionListing?: Maybe<Array<Scalars['JSON']>>;
  getMultiCurrencySetup: Array<MultiCurrencySetupEntity>;
  CheckMultiCurrencyAlreadyExist: Scalars['String'];
  GetEmployeeRoleName: Array<EmployeeEntity>;
  MultiCurrencyDetailsListing: Array<MultiCurrencyDetailsEntity>;
  MultiCurrencyListingByExcel: Scalars['JSON'];
  MultiCurrencyInfoline: Scalars['JSON'];
  CheckMultiCurrencyInUse: Scalars['Boolean'];
  OnBehalfMenuInfoline: Scalars['JSON'];
  TimeAttendanceMenuInfoline: Scalars['JSON'];
  OnBehalfCompanyListing: Array<OnBehalfCompanyOutput>;
  OnBehalfSubordinateListing: Array<OnBehalfSubordinateOutput>;
  GetOnBehalfPolicyTypeIDs: Array<Scalars['String']>;
  TmsOnBehalfPolicySummary?: Maybe<Scalars['JSON']>;
  OnBehalfPolicyListingByType?: Maybe<Array<OnBehalfEntity>>;
  OvertimeForecastReport?: Maybe<Scalars['JSON']>;
  OvertimeRoundingListing: Array<OvertimeRoundingSettingEntity>;
  OvertimeTemplateListing: Array<OvertimeTemplateEntity>;
  AdvancePolicyListing: Array<AdvancePolicyEntity>;
  GetSingleAdvancePolicyDetail: AdvancePolicyEntity;
  DuplicateAdvancePolicyName: Scalars['Boolean'];
  AdvancePolicyByCompanyAssignment?: Maybe<Scalars['JSON']>;
  PostedAdvanceProcessingListing: Array<AdvanceProcessingEntity>;
  PayGroupListing: Array<BasicPayGroupListingOutput>;
  GetSinglePayGroupDetail: PayGroupEntity;
  CompanyPayGroupListing: Array<CompanyPayGroupListingOutput>;
  CompanyAssignPayrollCycle: Array<PayrollCycleAssignmentEntity>;
  StatutoryAdjustmentListing?: Maybe<Array<Scalars['JSON']>>;
  StatutoryAdjFormEmpListing: Array<Scalars['JSON']>;
  GetFormulaTest: Scalars['String'];
  PayrollCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetSalaryTest: Scalars['JSON'];
  SalaryPayoutAnalysisSummaryExportExcel: Scalars['JSON'];
  PayrollDetailListingExportExcel: Scalars['JSON'];
  EmployeeOutstandingSalaryListing: Array<EmployeeEntity>;
  EmployeeOutstandingSalaryListingAndCount?: Maybe<Scalars['JSON']>;
  CP22Count: Array<EmployeeCp22Entity>;
  GetPayrollCyclePeriodSetting: Array<PayrollCyclePeriodEntity>;
  ViewPayrollHistorySummary: ViewPayrollHistorySummaryOutput;
  ViewPayrollHistory?: Maybe<PayrollHeaderEntity>;
  ViewPayrollHistoryByMonth: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryByPayrollCycleMenu: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryCount?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryTotal?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryMyPayrollInfo: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryByEmployee: Scalars['JSON'];
  PayrollProcessPeriodListing: Array<PayrollProcessPeriodListingOutput>;
  PayrollHistoryPeriodListing: Array<PayrollProcessPeriodListingOutput>;
  PayrollProcessPayrollCycleListing: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollProcessPayItemListing: PayrollProcessPayItemListingOutput;
  PayrollProcessEmployeeListing: Array<PayrollProcessEmployeeListingOutput>;
  CalculatePayroll: PayrollCalculationOutput;
  CalculateEPFRate?: Maybe<EpfRateCalculationOutput>;
  Rounding: Scalars['String'];
  CalculateHRDF: HrdfCalculationOutput;
  CalculateEPFV2: EpfCalculationOutput;
  CalculateEIS: EisCalculationOutput;
  CalculateSocso: SocsoCalculationOutput;
  PayPeriodListing: Array<PayPeriodEntity>;
  StartPayPeriodListingByYear: Array<PayPeriodEntity>;
  EndPayPeriodListingByYear: Array<PayPeriodEntity>;
  PayItemByPayType: Array<PayItemEntity>;
  PayItemListing: Array<PayItemEntity>;
  PayItemConditionListTemplateLibrary: PayItemConditionEntity;
  PayItemConditionList: PayItemConditionEntity;
  PayrollProcessingPayItemListing: Array<PayItemEntity>;
  PayrollProcessingPayItemListing2: Array<PayItemEntity>;
  LeaveTypePayItemListing: Array<PayItemEntity>;
  SalaryListingByExcel: Scalars['JSON'];
  SalaryListingByPeriod: Array<CareerLogEntity>;
  ClaimTypePayItemListing: Array<PayItemEntity>;
  PayItemDetail?: Maybe<PayItemEntity>;
  PayrollFixedAllowanceListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListingCount?: Maybe<Scalars['JSON']>;
  PayrollFixedAllowanceDetails: Array<RecurringPayEntity>;
  CP159Listing: Array<Cp159Entity>;
  OneTimePayListing: Array<OneTimePayEntity>;
  OneTimePayListingByPayPeriodAndPayCycle: Array<OneTimePayEntity>;
  PublishPaySlipListing: Array<PublishPayslipEntity>;
  PublishPaySlipListingByEmployee: Array<PublishPayslipEntity>;
  GetSingleUser: Array<UserEntity>;
  PublishEAListing: Array<PublishEaEntity>;
  PublishEAListingByEmployee: Scalars['JSON'];
  IsMonthlyTrxInUse: Scalars['Boolean'];
  MonthlyTransactionByEmployee: Array<EmployeeTransactionOutput>;
  MonthlyTransactionByEmployeeCount?: Maybe<Scalars['JSON']>;
  MonthlyTransactionByPayItem: Array<PayItemTransactionOutput>;
  MonthlyTransactionByExcel: Array<ExcelPayItemTransactionOutput>;
  FreezepayrollListing: Array<FreezePayrollEntity>;
  PayrollFixedAllowanceByExcel: Array<ExcelPayItemTransactionOutput>;
  PublishedEACompanyListing: Array<PublishEaSingleEmployeeListingOutput>;
  CheckOldDataForSalaryBatch: Array<Scalars['JSON']>;
  CheckOldDataForOneTimePayInsert: Array<Scalars['JSON']>;
  CheckOldDataForRecurringPayInsert?: Maybe<Array<Scalars['JSON']>>;
  EmployeeSalaryLogs: Array<EmployeeEntity>;
  EmployeeSalaryLogsCount?: Maybe<Scalars['JSON']>;
  EmployeeSalaryListing: EmployeeEntity;
  PayrollHomeInfoLinesOutput?: Maybe<Scalars['JSON']>;
  PayrollCommonSettingsInfoLine?: Maybe<Scalars['JSON']>;
  OtherPayrollRelatedInfoLine: Scalars['JSON'];
  EmployeeKpiListing: Array<EmployeeKpiEntity>;
  EmployeeKpiDetails: EmployeeKpiEntity;
  EmployeeKpiRepoListing: Array<EmployeeKpiRepositoryEntity>;
  RetrieveEmployeeKpiDetailFromRepo: EmployeeKpiRepositoryEntity;
  IsKpiExistInRepo: Scalars['Boolean'];
  KpiExerciseListing: Array<KpiExerciseDetailsEntity>;
  KpiExerciseSubmenuSummary: Scalars['JSON'];
  KpiExerciseSetupListing: Array<KpiExerciseSetupEntity>;
  KpiExerciseSetupInfo: KpiExerciseSetupEntity;
  IsKpiExerciseIDExistByCompany: Scalars['Boolean'];
  IsKpiExerciseDateRangeOverlappingByCompany: Scalars['Boolean'];
  KpiCategoryWithOrgGoalListing: Array<KpiCategoryEntity>;
  OrganizationalGoalByCategoryListing: Array<OrganizationalGoalEntity>;
  OrganizationalGoalDetails: OrganizationalGoalEntity;
  IsOrgGoalExistByCategory: Scalars['Boolean'];
  OrgKpiPlanningTreeViewListing: Array<OrgKpiPlanningEntity>;
  KpiCategoryWithOrgKpiPlanningListing: Array<KpiCategoryEntity>;
  OrgKpiPlanningDetailsByKpiCategoryListing: Array<OrgKpiPlanningDetailsEntity>;
  OrgKpiPlanningInfo: OrgKpiPlanningDetailsEntity;
  IsOrgKpiExistByCategory: Scalars['Boolean'];
  PerformanceGroupMatrixListing: Array<PerformanceGroupMatrixEntity>;
  PerformanceMainMenuSummary: Scalars['JSON'];
  MyPerformanceSummary: Scalars['JSON'];
  GetPerformanceToDo: ToDoPerformanceOutput;
  GetPerformanceApprovalHistory: ToDoPerformanceOutput;
  PerformanceEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  ReviewExerciseListing: Array<ReviewExerciseListingOutput>;
  GetEmployeeReviewListing: Array<ReviewExerciseEntity>;
  GetEmployeeReviewActionListing: Array<MyReviewActionOutput>;
  GetEmployeeReviewForm: EmployeeReviewEntity;
  GetConversationLogByReviewFormItem: Array<ReviewConversationLogEntity>;
  ReviewExerciseSubmenuSummary: Scalars['JSON'];
  ReviewExerciseSetupListing: Array<ReviewExerciseSetupEntity>;
  ReviewExerciseSetupInfo: ReviewExerciseSetupEntity;
  IsReviewExerciseIDExist: Scalars['Boolean'];
  PeerRegistrationListing: Array<ReviewPeerRegistrationEntity>;
  IsSuperiorAlreadyExist: Scalars['Boolean'];
  MySubordinatePerformanceSummary: Scalars['JSON'];
  SubordinateKpiEmpListing: Array<SubordinateKpiEmpOutput>;
  PeriodicAllowanceTypeListing: Array<PeriodicAllowanceTypeEntity>;
  PersonnelCertificateListing: Array<PersonnelCertificateEntity>;
  ContractInfoListing: Array<ContractInfoEntity>;
  ContractInfoDueListing: Array<ContractEmployeeOutput>;
  ContractInfoDueListingCount?: Maybe<Scalars['JSON']>;
  ContractInfoExistingListing: Array<ContractEmployeeOutput>;
  ContractInfoExistingListingCount?: Maybe<Scalars['JSON']>;
  PersonnelEducationListing: Array<PersonnelEducationEntity>;
  IsEmailExists: Scalars['Boolean'];
  IsMobileNoExists: Scalars['Boolean'];
  IsEmployeeNoExists: Scalars['Boolean'];
  DocumentProfilePicture?: Maybe<Array<DocumentProfilePictureOutput>>;
  PersonnelEFileListing?: Maybe<Scalars['JSON']>;
  PersonnelEFileListingCount?: Maybe<Scalars['Float']>;
  PersonnelHealthInfoSummary: PersonnelHealthInfoEntity;
  PersonnelHomeInfoLines: PersonnelHomeInfoLinesOutput;
  ELetterTempleteInfolines: Array<ReportTemplateEntity>;
  CommonSettingsInfoLines: CommonSettingsInfoLinesOutput;
  PersonnelJobHistoryListing: Array<PersonnelJobHistoryEntity>;
  PersonnelReferenceListing: Array<PersonnelReferenceEntity>;
  UserDefineDetailListing: Array<UserDefineDetailEntity>;
  InsuranceSchemeInUse: Scalars['JSON'];
  PersonnelInsuranceSchemePersonnelListing: Array<EmployeeInsuranceSchemeEntity>;
  PersonnelInsuranceSchemePersonnelListingCount?: Maybe<Scalars['JSON']>;
  ProbationExtensionListing: Array<ProbationExtendedEmployeeOutput>;
  StaffConfirmationProbationListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedCareerLogListing: Array<CareerLogEntity>;
  StaffTerminationListing: Array<CareerLogEntity>;
  StaffTerminationListingBySubscription: Array<CareerLogEntity>;
  StaffRetirementRetiredListing: Array<CareerLogEntity>;
  StaffRetirementDueListing: Array<StaffDueForRetirementOutput>;
  StaffExitCompanyListing: Array<CareerLogEntity>;
  StaffRetirementDueListingCount?: Maybe<Scalars['JSON']>;
  StaffRetirementRetiredListingCount?: Maybe<Scalars['JSON']>;
  StaffPromotionDemotionListing: Array<CareerLogEntity>;
  StaffSecondmentListing: Array<CareerLogEntity>;
  StaffRedesignationListing: Array<CareerLogEntity>;
  StaffInternalTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferCopyTransferData: Scalars['JSON'];
  StaffInterCompanyTransferDetails: CareerLogEntity;
  InterCoTransferLeavePolicyData: Scalars['JSON'];
  GetProjectExpenseClaimRecordsListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEClaimRecordsDetails?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetPEClaimRecordsTotalCount: Scalars['Float'];
  ProjecExpenseSummary: Scalars['JSON'];
  VerifyMyPEClaim: Scalars['Boolean'];
  IsProjectExpenseSubjectExists: Scalars['Boolean'];
  GetPEClaimPostingPeriodListingV2?: Maybe<Array<PeClaimPostingPeriodListingOutput>>;
  GetPEClaimPostingListing?: Maybe<Array<PeClaimPostingListingOutput>>;
  GetPEClaimPostingDetail?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetApprovedProjectExpenseClaimVerificationListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEApprovedClaimCount: Scalars['JSON'];
  GetApprovedProjectExpenseClaimVerificationDetails?: Maybe<ApprovedProjectExpenseClaimVerificationDetailsOutput>;
  PEClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetProjectToApprove?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  GetProjectExpenseApprovalHistory?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  ProjectExpenseTypeListing: Array<ProjectExpenseTypeEntity>;
  ProjectGLAccountMappingListing: Array<ProjectGlAccountMappingEntity>;
  GetProjectAccountAssignment: ProjectAccountAssignmentEntity;
  ProjectAccountMappingList: Scalars['JSON'];
  ProjectGLAccountHeaderListWithDetails: Scalars['JSON'];
  ProjectPayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ProjectPayrollGLExport: Scalars['Boolean'];
  ProjectGLClaimCycleProcess: Scalars['Boolean'];
  GetGLClaimCycleList: Scalars['JSON'];
  ProjectMasterListing: Array<ProjectMasterEntity>;
  ProjecCommonSettingSummary: Scalars['JSON'];
  ProjectCostAllocationListing: Array<ProjectCostAllocationEntity>;
  ProjectCostAllocationEmployeeListing: Array<EmployeeEntity>;
  ProjectCostAllocationDetailListing: Array<ProjectCostAllocationDetailEntity>;
  getApproverMasterListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  getApproverAssignmentListing?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  IsApproverEmailAlreadyExist: Scalars['Boolean'];
  ProjectTimesheetPeriodListing: Array<ProjectTimesheetPeriodListingOutput>;
  DailyEntryListing: Array<ProjectTimesheetDailyEntryEntity>;
  ProjectTimesheetSubmissionListing?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApprovalListing: Scalars['JSON'];
  ProjectTimesheetCheckPeriodOverlapping: ProjectTimesheetCheckOverlapOutput;
  ProjectTimesheetCheckPeriodOverlappingCount: Scalars['JSON'];
  ProjectTimesheetPeriod: Array<ProjectTimesheetMonthlyRecordEntity>;
  ProjectTimesheetApprover: Array<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetEmployeeListing?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  AdminDailyEntryListing?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
  ProjectTimesheeTApproverReportListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  GetProjectTimesheetTakenLeaveByEmployeeID?: Maybe<Array<LeaveDetailsEntity>>;
  AdminRecruitmentSummary: AdminRecruitmentSummaryOutput;
  RecruitmentExpensesListing?: Maybe<Array<RecruitmentExpensesEntity>>;
  ELetterTemplateListing?: Maybe<Array<RecruitmentE_LetterEntity>>;
  RecruitmentApprovalPolicySummary: Scalars['JSON'];
  RecruitmentApprovalPolicyListing?: Maybe<Scalars['JSON']>;
  Rec_EApplicationFormSetupListing: Array<Rec_EApplicationFormSetupEntity>;
  PreOfferChecklistListing?: Maybe<Array<RecruitmentPre_OfferChecklistEntity>>;
  TalentPoolUserFieldsListing: Array<Rec_TalentPoolUserFieldsEntity>;
  DeclineReasonListing: Array<Rec_DeclineReasonEntity>;
  Rec_PrehiringFormSetupListing: Array<Rec_PrehiringFormSetupEntity>;
  JobPortalSetupListing?: Maybe<Array<Scalars['JSON']>>;
  JobPortalSetupDetail: Rec_JobPortalSetupEntity;
  JobPortalQrCode: Scalars['JSON'];
  GetRecRequisitionToDo?: Maybe<Array<Rec_RequisitionEntity>>;
  ViewRec_RequisitionApprovalHistory?: Maybe<Array<Rec_RequisitionEntity>>;
  GetRecOfferToDo?: Maybe<Array<Rec_OfferOutput>>;
  ViewRec_OfferApprovalHistory?: Maybe<Array<Rec_OfferOutput>>;
  GetRecHiringToDo?: Maybe<Array<Rec_HiringOutput>>;
  ViewRec_HiringApprovalHistory?: Maybe<Array<Rec_HiringOutput>>;
  JobPortalInfo?: Maybe<Scalars['JSON']>;
  JobPostingListByJobPortal?: Maybe<Array<Scalars['JSON']>>;
  JobPostingListByJobPortalCount?: Maybe<Scalars['Float']>;
  JobPostingInfoByJobPortal?: Maybe<Scalars['JSON']>;
  TokenValidation?: Maybe<Scalars['JSON']>;
  EApplicationListing: Array<Rec_EApplicationFormSetupEntity>;
  EApplicationSummary?: Maybe<EApplicationSummaryOutput>;
  DataUpdateEApplicationSummary?: Maybe<Scalars['JSON']>;
  RecruitmentParameterListing: Array<PersonnelParameterEntity>;
  TalentUserDefineFieldsListing: Array<Rec_TalentPoolUserFieldsEntity>;
  DataUpdateTalentPersonal: Scalars['JSON'];
  DataUpdateHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  LanguageParameterListing: Array<PersonnelParameterEntity>;
  MyRecruitmentMenuSummary?: Maybe<Scalars['JSON']>;
  MyRecRequisitionListing: Array<Rec_Requisition_Output>;
  MyRecRequisitionListingTotalCount?: Maybe<Scalars['Float']>;
  MyApprovedJobVacancyListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  MyApprovedJobVacancyListingCount?: Maybe<Scalars['Float']>;
  MyApprovedJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  MyApprovedJobVacancyApplicantListing: Array<Rec_ApplicantOutput>;
  MyApprovedJobVacancyApplicantListingCount?: Maybe<Scalars['Float']>;
  MyRecInterviewListing?: Maybe<Array<Rec_InterviewListing_Output>>;
  MyRecInterviewListingCount?: Maybe<Scalars['Float']>;
  MyOfferListing?: Maybe<Array<Rec_OfferOutput>>;
  MyOfferListingCount?: Maybe<Scalars['Float']>;
  MyOfferDetail: Array<Rec_OfferEntity>;
  MyTalentSearchApplicationListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  AdminRecruitmentMenuSummary?: Maybe<Scalars['JSON']>;
  SettingSummary?: Maybe<Scalars['JSON']>;
  TalentPoolSummary?: Maybe<Scalars['JSON']>;
  JobVacancyManagementSummary?: Maybe<Scalars['JSON']>;
  JobOfferManagementSummary?: Maybe<Scalars['JSON']>;
  HiringProcessSummary?: Maybe<Scalars['JSON']>;
  GetRecRequisitionInfo?: Maybe<Rec_Requisition_Output>;
  RecRequisitionListing: Array<Rec_Requisition_Output>;
  RecRequisitionListingTotalCount?: Maybe<Scalars['Float']>;
  RecRequisitionNotInUseListing: Array<Rec_Requisition_Output>;
  RecRequisitionNotInUseListingCount: Scalars['JSON'];
  JobVacancyListing: Array<Rec_JobVacancyOutput>;
  JobVacancyListingCount?: Maybe<Scalars['Float']>;
  JobVacancyInfo: Rec_JobVacancyOutput;
  JobVacancyWithApplicantListing?: Maybe<Array<Rec_JobVacancyWithApplicantOutput>>;
  InterviewerInUse?: Maybe<Array<Scalars['JSON']>>;
  InterviewScheduleListing?: Maybe<Array<Rec_InterviewScheduleOutput>>;
  JobVacancyNotInUseListing: Array<Rec_JobVacancyOutput>;
  JobVacancyNotInUseListingCount: Scalars['Float'];
  JobPostingListing: Array<Rec_JobPostingOutput>;
  JobPostingListingCount?: Maybe<Scalars['Float']>;
  JobPostingInfo: Rec_JobPostingOutput;
  PostedJobVacancyListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  PostedJobVacancyListingCount?: Maybe<Scalars['Float']>;
  PostedJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  ApplicantListing: Array<Rec_ApplicantOutput>;
  ApplicantListingCount?: Maybe<Scalars['Float']>;
  ActiveJobVacancyListing: Array<Rec_JobVacancyOutput>;
  ActiveJobVacancyListingCount?: Maybe<Scalars['Float']>;
  ActiveJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  FinalApplicantSelectionListing: Array<Rec_ApplicantOutput>;
  FinalApplicantSelectionListingCount?: Maybe<Scalars['Float']>;
  OfferProposalListing?: Maybe<Array<Rec_OfferOutput>>;
  OfferProposalListingCount?: Maybe<Scalars['Float']>;
  OfferProposalInfo: Rec_OfferEntity;
  OfferProposalDetailListing?: Maybe<Array<Rec_OfferEntity>>;
  OfferApprovalListing?: Maybe<Array<Rec_OfferOutput>>;
  OfferApprovalListingCount?: Maybe<Scalars['Float']>;
  OfferApprovalDetailListing?: Maybe<Array<Rec_OfferEntity>>;
  ApprovedJobOfferListing?: Maybe<Array<Rec_OfferOutput>>;
  ApprovedJobOfferListingCount?: Maybe<Scalars['Float']>;
  ApprovedJobOfferInfo: Scalars['JSON'];
  ProcessedOfferInfo: Array<Rec_OfferEntity>;
  OfferChecklistListing?: Maybe<Array<RecruitmentPre_OfferChecklistEntity>>;
  ELetterTemplateInfo?: Maybe<Array<RecruitmentE_LetterEntity>>;
  ApplicantOfferPreHiringListing: Array<Rec_HiringOutput>;
  ApplicantOfferPreHiringListingCount: Scalars['Float'];
  QuickHiringListing: Array<Rec_HiringOutput>;
  QuickHiringListingCount: Scalars['Float'];
  QuickHiringDetails: QuickHiringDetails;
  PreHiringDataUpdateListing: Array<Rec_HiringOutput>;
  PreHiringDataUpdateListingCount: Scalars['Float'];
  NewHireProcessListing: Array<Rec_HiringOutput>;
  NewHireProcessListingCount: Scalars['Float'];
  NewHireProcessOnboardInfo: NewHireProcessOnboardInfoOutput;
  TalentPoolListing?: Maybe<Array<Rec_TalentPoolOutput>>;
  TalentPoolListingCount?: Maybe<Scalars['Float']>;
  TalentProfileSummary?: Maybe<Scalars['JSON']>;
  TalentProfileDetail?: Maybe<Scalars['JSON']>;
  TalentHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  TalentApplicationListing: Array<Rec_JobVacancyOutput>;
  TalentApplicationListingCount: Scalars['Float'];
  TalentJobVacancyAppliedListing: Array<Rec_JobVacancyOutput>;
  TalentSearchListing: Array<Rec_TalentSearchOutput>;
  TalentInvitationListing: Array<Rec_TalentInvitationOutput>;
  TalentInvitationListingCount: Scalars['Float'];
  ApprovalPolicyMatrixSummary?: Maybe<Scalars['JSON']>;
  RecruitmentApprovalPolicyByType?: Maybe<Array<ApprovalPolicyMatrixEntity>>;
  WorkFlowDetail: WorkFlowDetail;
  RequisitionMenuSummary: Scalars['JSON'];
  RequisitionManagementListing?: Maybe<Array<RequisitionOutput>>;
  RequisitionManagementListingTotalCount?: Maybe<Scalars['Float']>;
  EmployeeRequisitionListing: Array<RequisitionEntity>;
  RequisitionDetails: Scalars['JSON'];
  FilterEmployeeRequisitionListing: Array<RequisitionEntity>;
  TravelRequsitionListing: Array<RequisitionEntity>;
  RequisitionDetail: Array<RequisitionEntity>;
  CheckVerifyEndIf: Scalars['Boolean'];
  GetRequisitionApproveToDo?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionToAprove?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionApprovalHistory?: Maybe<Array<RequisitionOutput>>;
  RequisitionTypeList: RequisitionTypeEntity;
  ClaimParameterListing: Array<ClaimParameterEntity>;
  ClaimParameterListingAll: Array<ClaimParameterEntity>;
  ClaimUserDefineFieldsListing: Array<ClaimUserDefineFieldsEntity>;
  VehicleRateListing: Array<ClaimVehicleRateEntity>;
  PersonnelParameterListingByType: Array<Scalars['JSON']>;
  PersonnelParameterListing: Array<PersonnelParameterEntity>;
  ReportTemplateListing: Array<ReportTemplateEntity>;
  ReportTemplateAssignmentListing: Scalars['JSON'];
  StatesListing: Array<StatesEntity>;
  ViewGeneralSettingSummary: GeneralSettingsLastUpdatedOutput;
  StaffMovementRequestList: StaffMovementRequestEntity;
  HomeSummary: Scalars['JSON'];
  EmployeeMainMenuSummary: Scalars['JSON'];
  EmploymentInfoSummary: Scalars['JSON'];
  LoginUserPerms?: Maybe<Scalars['JSON']>;
  PersonnelMenuSummary: Scalars['JSON'];
  EAPartFListing: Array<EaPartFEntity>;
  EAPartFListingByYear: Array<EaPartFEntity>;
  EISPolicyListing: Array<EisPolicyEntity>;
  EISPolicyDetail: EisPolicyEntity;
  EPFPolicyListing: Array<EpfPolicyEntity>;
  EPFPolicyDetail: EpfPolicyEntity;
  FormItemRatingListing: Array<FormItemRatingEntity>;
  FormItemRatingDetails: FormItemRatingEntity;
  IsFormItemRatingTitleExist: Scalars['Boolean'];
  KpiCategoryListing: Array<KpiCategoryEntity>;
  KpiCategoryDetails: KpiCategoryEntity;
  IsKpiCategoryTitleExist: Scalars['Boolean'];
  KpiPredefinedItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedTemplateItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedItemDetails: KpiPredefinedItemEntity;
  IsKpiPredefinedItemTitleExist: Scalars['Boolean'];
  KpiPredefinedTemplateListing: Array<KpiPredefinedTemplateEntity>;
  KpiPredefinedTemplateDetails: KpiPredefinedTemplateEntity;
  IsKpiPredefinedTemplateTitleExist: Scalars['Boolean'];
  PerformanceMenuSummary: Scalars['JSON'];
  ReviewFormTemplateListing: Array<ReviewFormTemplateEntity>;
  ReviewFormTemplateDetails: ReviewFormTemplateEntity;
  IsReviewFormTemplateTitleExist: Scalars['Boolean'];
  ReviewGradingTableListing: Array<ReviewGradingTableEntity>;
  ReviewGradingTableDetails: ReviewGradingTableEntity;
  IsReviewGradingTitleExist: Scalars['Boolean'];
  ReviewGroupListing: Array<ReviewGroupEntity>;
  ReviewGroupDetails: ReviewGroupEntity;
  IsReviewGroupTitleExist: Scalars['Boolean'];
  ReviewItemListing: Array<ReviewItemEntity>;
  ReviewItemDetails: ReviewItemEntity;
  IsReviewItemCodeExist: Scalars['Boolean'];
  ReviewPolicyListing: Array<ReviewPolicyEntity>;
  ReviewPolicyDetails: ReviewPolicyEntity;
  IsReviewPolicyTitleExist: Scalars['Boolean'];
  ReviewProcessPolicyListing: Array<ReviewProcessPolicyEntity>;
  ReviewProcessPolicyDetails: ReviewProcessPolicyEntity;
  IsReviewProcessPolicyTitleExist: Scalars['Boolean'];
  ReviewTemplateListing: Array<ReviewTemplateEntity>;
  ReviewTemplateDetails: ReviewTemplateEntity;
  IsReviewTemplateTitleExist: Scalars['Boolean'];
  RewardGroupListing: Array<RewardGroupEntity>;
  RewardGroupDetails: RewardGroupEntity;
  IsRewardGroupTitleExist: Scalars['Boolean'];
  PersonnelSecurityPolicyInfoByRole?: Maybe<PersonnelSecurityPolicyEntity>;
  PublicHolidayCalendarListing: Array<HolidayEntity>;
  PublicHolidayListing: Array<HolidayEntity>;
  PublicHolidayForStatesListing: Array<HolidayEntity>;
  SocsoPolicyListing: Array<SocsoPolicyEntity>;
  SocsoPolicyDetail: SocsoPolicyEntity;
  SystemAdminSummaryListing: SystemAdminSummaryOutput;
  SystemAdminMenuSummary: Scalars['JSON'];
  TaxReliefListing: Array<TaxReliefEntity>;
  TaxReliefListingByYear: Array<TaxReliefEntity>;
  AttendanceAnalysis: Array<AttendanceDetailOutput>;
  AttendanceProcessingList: Array<Scalars['JSON']>;
  MonthlyAttendanceProcessingList: Array<Scalars['JSON']>;
  ProcessingListing: Array<AttendanceProcessingBatchEntity>;
  GetAllAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetBeaconDevicesListing?: Maybe<Array<BluetoothBeaconDevicesEntity>>;
  IsDeviceExists: Scalars['Boolean'];
  getChangeShift: Array<ChangeShiftEntity>;
  getShiftByEmployee: Scalars['JSON'];
  getShiftByTLA: Array<ShiftEntity>;
  getShiftGroupByTLA: Array<ShiftGroupEntity>;
  getShiftGroupByShiftPattern: Array<ShiftGroupEntity>;
  getShiftPatternByTLA: Array<ShiftPatternEntity>;
  getEmpByDuty: Array<Scalars['JSON']>;
  getChangeShiftListing: Array<ChangeShiftEntity>;
  ViewChangeShiftApprovalHistory?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftApproveToDo?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftToApprove?: Maybe<Array<ChangeShiftEntity>>;
  getChangeShiftByYear: Array<ChangeShiftEntity>;
  ChangeShiftMenuSummary?: Maybe<Scalars['JSON']>;
  getDailyAllowance: Array<DailyAllowancePolicyEntity>;
  getDailyAllowanceByTLA: Array<DailyAllowancePolicyEntity>;
  DailyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getDeductionPolicy: Array<DeductionPolicyEntity>;
  getDeductionPolicyByTLA: Array<DeductionPolicyEntity>;
  DeductionPolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  EarnLeavePosting: Scalars['JSON'];
  EarnLeavePeriodSummary?: Maybe<Scalars['JSON']>;
  EmployeeTMSSummary?: Maybe<EmployeeSummary>;
  getJobGradePolicyMatrix: Array<JobGradePolicyMatrixEntity>;
  getMobileCheckIn?: Maybe<Array<MobileCheckInEntity>>;
  QRListing: DocumentsEntity;
  EmpAttendanceHistory: Scalars['JSON'];
  AttendanceHistory: Array<Scalars['JSON']>;
  VerifyScan: Scalars['Boolean'];
  GetLocationRadiusSetting: MobileCheckInEntity;
  getMonthlyAllowanceByTLA: Array<MonthlyAllowancePolicyEntity>;
  MonthlyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getMonthlyAllowance: Array<MonthlyAllowancePolicyEntity>;
  getOvertimeClaimTable: Array<OvertimeClaimTableEntity>;
  OvertimeClaimTableInUseListing?: Maybe<Array<Scalars['String']>>;
  getOvertimeReason: Array<OvertimeReasonEntity>;
  OTReasonListing: Array<OvertimeReasonEntity>;
  OTReasonByTLA: Array<OvertimeReasonEntity>;
  getOvertimePolicy?: Maybe<Array<OvertimePolicyEntity>>;
  OvertimePolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  PeriodicDeductionInUseListing?: Maybe<Array<Scalars['String']>>;
  getPeriodicDeduction: Array<PeriodicDeductionPolicyEntity>;
  getAllowanceDeduction: Array<AllowanceDeductionRoundingEntity>;
  getOTRounding: Array<OtRoundingEntity>;
  getShift: Array<ShiftEntity>;
  getShiftDay: Array<ShiftDayEntity>;
  getShiftList: Array<ShiftEntity>;
  getShiftGroup: Array<ShiftGroupEntity>;
  EmployeeShiftGroup?: Maybe<Scalars['JSON']>;
  EmployeeShiftGroupAssignmentLog: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  getShiftPattern: Array<ShiftPatternEntity>;
  ShiftPatternListing: Array<ShiftPatternEntity>;
  ValidShiftPatternListing: Array<ShiftPatternEntity>;
  TemplateLibrarySummary?: Maybe<TemplateLibrarySummaryOutput>;
  TMSSummary?: Maybe<TmsSummary>;
  UserDefineEPFPolicyListing: Array<UserDefineEpfPolicyEntity>;
  UserDefineEPFPolicyConditionList: Array<UserDefineEpfPolicyMatrixEntity>;
  getTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getTemplateLibraryAssignment: Array<TemplateLibraryAssignmentEntity>;
  getJobGradeByTLA: Array<Scalars['JSON']>;
  getPayrollTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  GetTemplateLibraryAssignmentInfoline: TemplateLibraryAssignmentInfolineOutput;
  PersonnelTemplateLibraryAssignmentListing: PersonnelTemplateLibraryAssignmentOutput;
  DailyTypeConditionList: Array<DailyTemplateEntity>;
  GetEmployeeDailyPolicies?: Maybe<Scalars['JSON']>;
  DeductionTypeConditionList: Array<DeductionTemplateEntity>;
  GetEmployeeDeductionPolicies?: Maybe<Scalars['JSON']>;
  TMSTypeConditionList: Array<ClaimTemplateEntity>;
  OTPayItemConditionList: Array<TmsotConditionEntity>;
  GetEmployeeOtPolicies?: Maybe<Scalars['JSON']>;
  TMSTimeOffConditionListing?: Maybe<Array<TmsTimeOffConditionEntity>>;
  MonthlyTypeConditionList: Array<MonthlyTemplateEntity>;
  TmsPolicyMenuInfoLinesOutput?: Maybe<Scalars['JSON']>;
  TimeOffListing: Array<TimeOffEntity>;
  TimeOffListingByYear: Array<TimeOffEntity>;
  ViewTimeOffApprovalHistory?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffAproveToDo?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffToApprove?: Maybe<Array<TimeOffEntity>>;
  TimeOffApplicationPeriodSummary?: Maybe<Scalars['JSON']>;
  TimeOffMenuSummary?: Maybe<Scalars['JSON']>;
  TimeOffListingByPeriod: Array<TimeOffEntity>;
  TimeOffPolicyDetailsByEmployeeID: Array<Scalars['JSON']>;
  getTimeOff: Array<TimeOffPolicyEntity>;
  TimeOffReasonByTLA: Array<TimeOffReasonEntity>;
  TimeOffReasonListing: Array<TimeOffReasonEntity>;
  TimeOffSettingListing: Array<TimeOffSettingEntity>;
  TsAccountTypeList: Array<TsAccountTypeEntity>;
  TsAccountCodeList: Array<TsAccountCodeEntity>;
  StaffTimesheetList: Array<StaffTimesheetEntity>;
  TimesheetHours?: Maybe<Scalars['JSON']>;
  TimesheetDates: Scalars['JSON'];
  TimesheetMandayAnalysisByAccCode: Array<TimesheetMandayAnalysisByAccCodeDto>;
  TimesheetMandayAnalysisByActivity: Array<TimesheetMandayAnalysisDto>;
  TimesheetMandayAnalysisByAccType: Array<TimesheetMandayAnalysisByAccTypeDto>;
  TimesheetMandayAnalysisByAccount: Array<TimesheetMandayAnalysisDto>;
  TimesheetCompanyDate: Scalars['JSON'];
  TimesheetSummary: Scalars['JSON'];
  IsActvitiyInUse: Scalars['JSON'];
  ToDoSummary: Scalars['JSON'];
  TrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  GetEmployeeTrainingRecord: Array<EmployeeAttendanceOutput>;
  EmployeeTrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  CompulsaryCourseByJobGradeListing: Array<CompulsaryCourseByJobGradeEntity>;
  GetCompulsaryCourseByJobGradeListingWithCompany: Array<CompulsaryCourseByJobGradeEntity>;
  CourseClassificationListing: Array<CourseClassificationEntity>;
  TrainingCourseListing: Array<TrainingCourseEntity>;
  TrainingCourseListingAll: Array<TrainingCourseEntity>;
  TrainingCourseListingByJobGrade: Array<TrainingCourseEntity>;
  AuthorisedTrainingProviderListing: Array<AuthorisedTrainingProviderEntity>;
  GetProviderListByCourse: Array<CourseProviderOutput>;
  TrainingCostItemListing: Array<TrainingCostItemEntity>;
  GetLatestUpdateForMainMenuModule: TraningModuleLatestUpdateOutput;
  TraineeEvalListing: Array<TraineeEvalEntity>;
  TraineeEvalListingAll: Array<TraineeEvalEntity>;
  SupervisorEvalListing: Array<SupervisorEvalEntity>;
  CourseEvalListing: Array<CourseEvalEntity>;
  TrainerEvalListing: Array<TrainerEvalEntity>;
  GetTrainerEvalLuation: Array<TrainerEvaluationListingOutput>;
  GetSupervisorEvalLuation: Array<SupervisorEvaluationListingOutput>;
  GetFulfilmentByJobGrade: Array<EmployeeFulfilmentOutput>;
  GetFulfilmentByCourse: Array<CourseFulfilmentOutput>;
  GetOSFulfilmentByJobGrade: Array<GetOsFulfilmentByJobGradeOutput>;
  ScheduleTrainingListing: Array<ScheduleTrainingEntity>;
  RegisteredTraineeListing: Array<EmployeeEntity>;
  EmployeeScheduledTrainingListing: Array<ScheduleTrainingEntity>;
  AvailableCoursesListing: Array<ScheduleTrainingEntity>;
  CompulsoryCourseListing: Array<ScheduleTrainingEntity>;
  EmpTrainingAttendanceHistory: Scalars['JSON'];
  TrainingCostBudgetListing: Array<TrainingBudgetEntity>;
  TrainingCostListing: Array<TrainingCostEntity>;
  CostAllocationListing: Array<CostAllocationEntity>;
  HRDFClaimListing: Array<HrdfClaimOutput>;
  TCourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  TCourseLibraryListingByCategory?: Maybe<Array<CourseCategoryEntity>>;
  AdminTrainingMenuSummary?: Maybe<Scalars['JSON']>;
  TrainingSettingsSummary?: Maybe<Scalars['JSON']>;
  BudgetGroupMatrixListing?: Maybe<Array<BudgetGroupMatrixEntity>>;
  TargetGroupMatrixListing?: Maybe<Array<TargetGroupMatrixEntity>>;
  ApprovalPolicyMatrixListing?: Maybe<Scalars['JSON']>;
  ApprovalPolicyByType?: Maybe<Array<ApprovalPolicyMatrixEntity>>;
  TrainingMaintenanceSummary?: Maybe<Scalars['JSON']>;
  MaintenanceBudgetGroupListing?: Maybe<Array<NewTrainingBudgetEntity>>;
  MaintenanceCourseListing?: Maybe<Array<TargetGroupEntity>>;
  MaintenanceTrainingCourseLibraryListing?: Maybe<Array<CourseCategoryEntity>>;
  MaintenanceTargetHoursListing?: Maybe<Array<TrainingTargetHoursEntity>>;
  TrainingRequestListing?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  TrainingRequestBudgetInfo?: Maybe<Scalars['JSON']>;
  ScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingRegistrationListing?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  ScheduledTrainingVenueListing?: Maybe<Array<ScheduledTrainingVenueEntity>>;
  getTrainingQRCode: DocumentsEntity;
  OngoingTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  getOngoingTrainingAttendance?: Maybe<ScheduledTrainingEntity>;
  TrainingEvaluationSummary?: Maybe<Scalars['JSON']>;
  TrainingEvaluationListing?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingEvaluationSurveyFormListing?: Maybe<ScheduledTrainingEntity>;
  TrainingEvaluationAttendeesListing?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  getSurveyFormStructure?: Maybe<SurveyFormEntity>;
  EvaluationResultListing?: Maybe<Array<ScheduledTrainingEntity>>;
  EvaluationResultParticipantListing?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeEvaluationResult?: Maybe<ScheduledTrainingEntity>;
  AdminExpensesListing?: Maybe<Array<ScheduledTrainingEntity>>;
  AdminExpensesFilterListing?: Maybe<Array<ScheduledTrainingEntity>>;
  HRDCClaimListing?: Maybe<Array<HrdcClaimEntity>>;
  TrainingFulfillmentSummary?: Maybe<Scalars['JSON']>;
  TrainingFulfillmentByCC?: Maybe<Array<Scalars['JSON']>>;
  TrainingFulfillmentByCCDetails?: Maybe<Scalars['JSON']>;
  TrainingFulfillmentByTH?: Maybe<Array<Scalars['JSON']>>;
  TrainingFulfillmentByTHDetails?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  getEmployeeTrainingRecords?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeTrainingRequestListing?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  GetTodoTrainingEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  MySubordinateTrainingSummary: Scalars['JSON'];
  SubordinateTrainingListingSummary: Scalars['JSON'];
  MyTrainingScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateTrainingRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  MySubordinateTrainingFulfillmentSummary?: Maybe<Scalars['JSON']>;
  MySubordinateTrainingFulfillmentByCC?: Maybe<Array<Scalars['JSON']>>;
  MySubordinateTrainingFulfillmentByCCDetails?: Maybe<Scalars['JSON']>;
  MySubordinateTrainingFulfillmentByTH?: Maybe<Array<Scalars['JSON']>>;
  MyTrainingSummary: Scalars['JSON'];
  TrainingCheckInHistory: Scalars['JSON'];
  MyTrainingRequiredTraining: Scalars['JSON'];
  MyTrainingRequiredTrainingCalendarListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateTrainingRegistrationListing?: Maybe<ScheduledTrainingEntity>;
  TrainingCalendarEmployee?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingCalendarSubordinate?: Maybe<Array<ScheduledTrainingEntity>>;
  GetTrainingToDo?: Maybe<TrainingToDoOutput>;
  TrainingMenuSummary?: Maybe<Scalars['JSON']>;
  CourseCategoryListing?: Maybe<Array<CourseCategoryEntity>>;
  TrainingCourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  getCourseListingByCategory?: Maybe<Scalars['JSON']>;
  getCourseDetail?: Maybe<TrainingCourseLibraryEntity>;
  TrainingProvidersListing?: Maybe<Scalars['JSON']>;
  getTrainingProviderDetail?: Maybe<TrainingProviderEntity>;
  TrainingProviderHistory?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingSurveyMenuSummary?: Maybe<Scalars['JSON']>;
  SurveyTypeListing?: Maybe<Array<SurveyTypeEntity>>;
  SurveyItemsListing?: Maybe<Array<SurveyItemEntity>>;
  getSurveyItemDetail?: Maybe<SurveyItemEntity>;
  SurveyFormListing?: Maybe<Array<SurveyFormEntity>>;
  getSurveyFormDetail?: Maybe<SurveyFormEntity>;
  TrainingExpensesListing?: Maybe<Array<TrainingExpenseEntity>>;
  BudgetGroupListing?: Maybe<Array<BudgetGroupEntity>>;
  TargetGroupListing?: Maybe<Array<TargetGroupEntity>>;
  EmployeeTrainerProfilePersonnelInfo: EmployeeEntity;
  TrainerProfileListing?: Maybe<Array<TrainerProfileEntity>>;
  TrainerProfileHistory?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingApprovalPoliciesSummary?: Maybe<Scalars['JSON']>;
  TrainingApprovalPoliciesListing?: Maybe<Scalars['JSON']>;
  IsTrainingProviderCompanyIDExist: Scalars['Boolean'];
  IsTrainingCourseLibraryCodeExist: Scalars['Boolean'];
  IsTrainingProviderNameExist: Scalars['Boolean'];
  IsTrainingEventNameExist: Scalars['Boolean'];
  IsTrainerProfileEmailExist: Scalars['Boolean'];
  CourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  GetPersonalTTDAInfo: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRoleList?: Maybe<Array<Scalars['JSON']>>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  userRoleIDbyUser: Array<RoleUserEntAssignmentEntity>;
  getCompanyPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getRolePermissionByUser: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetUserRolePermissionByRole: Scalars['JSON'];
  GetMileageLogListGroup?: Maybe<Array<GroupedMileageLogs>>;
  GetMileageLogListGroupCount?: Maybe<Scalars['Int']>;
  GetMileageLogList?: Maybe<Array<MileageLogEntity>>;
  GetMileageLogDetail: MileageLogEntity;
  GetMileageLogsByIDs?: Maybe<Array<MileageLogEntity>>;
  AllowancePolicy: Array<AllowanceBenfitEntity>;
  AllowanceEntitlementListing: Array<AllowanceItemEntity>;
  AllowanceEntitlementDetail: Array<AllowanceItemEntity>;
  CompanySummaryDates: CompanyViewLastUpdatedOutput;
  CurrentCountry?: Maybe<Scalars['JSON']>;
  CountryListing?: Maybe<Array<CountryEntity>>;
  PayProcessSelectPayPeriod: Array<PayPeriodEntity>;
  PayProcessSelectPayrollCycle: Array<PayrollCycleEntity>;
  PayProcessEmployeeList: Array<EmployeeEntity>;
  PayProcessDetail: Array<PayrollDetailEntity>;
  loggedInEmployeeProfile: EmployeeResponse;
  loggedInUserProfile: UserEntity;
  TestFCM: Scalars['Boolean'];
  TestEmail: Scalars['Boolean'];
  MobileNotification: Scalars['Boolean'];
  loginSSO: Scalars['JSON'];
  VerifyRedirectDomainToken: Scalars['String'];
  activeUser: Scalars['Float'];
  getUserListing?: Maybe<Array<Scalars['JSON']>>;
  getEmployeeUserListing?: Maybe<Array<Scalars['JSON']>>;
  getUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getActiveUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  GetExceededUserWarning?: Maybe<Scalars['Boolean']>;
  UserProfilePic?: Maybe<Scalars['JSON']>;
  GetNonAccessUsers?: Maybe<Array<Scalars['JSON']>>;
  GetNonAccessUsersCount?: Maybe<Scalars['JSON']>;
  CheckOnPremServer?: Maybe<Scalars['String']>;
  testWorkFlowServices: Scalars['JSON'];
};


export type QueryGetPrivateDocumentByFileNameArgs = {
  Documents: Array<Scalars['JSON']>;
};


export type QueryGetDutyRosterArgs = {
  IsExcelFile?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  DutyRosterID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DutyDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterEmployeeSumArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterDatesArgs = {
  date: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryByEmployeeArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeListV2Args = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
};


export type QueryGetDutyRosterByEmployeeArgs = {
  GetLeaveTimeOffOvertimeData?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeListArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
};


export type QueryNotificationListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryNewsReadListingArgs = {
  NewsID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCalendarArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  isSelectAll?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataCountArgs = {
  isSelectAll?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  isSelectAll?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataCountArgs = {
  isSelectAll?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetClockPairingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
};


export type QueryGetClockPairingRedirectArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
  payload?: Maybe<Scalars['JSON']>;
};


export type QueryGetAttenRecordsArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryAttendanceRecordByHikvisionArgs = {
  userID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByHikvisionByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetAttendanceRecordwithHikvisionArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryAttendanceRecordSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByExcelArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingByClockTypeArgs = {
  ClockType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordLastModifiedArgs = {
  companyID: Scalars['String'];
  timezoneOffset: Scalars['Float'];
  year: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordTotalCountArgs = {
  companyID: Scalars['String'];
  year: Scalars['DateTime'];
};


export type QueryGetContactTracingArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordManualEntryPageTotalCountArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryAppraisalCategoryListingArgs = {
  AppraisalType: Scalars['String'];
};


export type QueryGetAppraisalTemplateByJobPositionArgs = {
  JobPositionID: Scalars['String'];
};


export type QueryGetAppraisalCompanySettingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalTempleteArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBonusSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIncrementSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBonusRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetIncrementRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAppraisalTempleteListingArgs = {
  TempleteType: Scalars['String'];
};


export type QueryAppraisalTempleteListingAllArgs = {
  TempleteType: Scalars['String'];
};


export type QueryGetEmployeeListingByscheduledAndQuaterArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  ScheduledID: Scalars['String'];
  QuaterID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalHeaderArgs = {
  PeriodYear: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalDetailsArgs = {
  EmployeeAppraisalHeaderID: Scalars['String'];
};


export type QueryModerationListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetAppraisalDefaultSettingByJobPositionArgs = {
  JobPositionID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeKpiTempleteLatestVersionArgs = {
  KPIPlanningID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeKpiPlanningRecordArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetSubordinateKpiPlanningArgs = {
  EmployeeID: Array<Scalars['String']>;
};


export type QueryGetEmployeeKpiPlanningByYearArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetKpiPlanningDetailsArgs = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
};


export type QueryStaffPerformanceAnalysisSummaryListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
};


export type QueryApprovalPolicySummaryArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyAssignmentSummaryArgs = {
  Category: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryApprovalPolicyListArgs = {
  Category: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyDetailsArgs = {
  Category: Scalars['String'];
  ApprovalPolicyID: Scalars['String'];
};


export type QueryIsApprovalPolicyTitleExistArgs = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  Title: Scalars['String'];
};


export type QueryApprovalPolicyAssignmentListArgs = {
  Category: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryNewApprovalPolicyAssignmentListArgs = {
  Category: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeListingByRoleIdArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryApproverListByCompanyArgs = {
  Category?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeAttendanceCorrectionRequestListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeAttendanceCorrectionRequestDetailArgs = {
  AttendanceCorrectionRequestHeaderID: Scalars['String'];
};


export type QueryIsAttCorReqRecordAlreadyExistAndPendingApprovalArgs = {
  EmployeeID: Scalars['String'];
  SelectedDate: Scalars['String'];
};


export type QueryAdminAttendanceCorrectionRequestListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  filterText: Scalars['String'];
  ApStatus: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAdminAttendanceCorrectionRequestListingTotalCountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  ApStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceCorrectionToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryAttendancePostingListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  isAttendanceGrouped?: Maybe<Scalars['Boolean']>;
  PostStatus?: Maybe<Array<Scalars['String']>>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendancePostingListingCountArgs = {
  PostStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  isAttendanceGrouped?: Maybe<Scalars['Boolean']>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryExportAttendanceProcessingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAdjustedValueQuantityDetailsArgs = {
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLastPostedAttendanceProccessArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAttendancePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryGetLatestAttandanceCalculationDateByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOvertimeArgs = {
  WorkDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
};


export type QueryGetTotalOverTimeArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CutOffDay: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeWithdrawalCheckingArgs = {
  WorkDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryOvertimeListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeV2Args = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodV2Args = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryViewOvertimeApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetOvertimeApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetOvertimeToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryOvertimePeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOvertimeCheckingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  Duration?: Maybe<Scalars['Float']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
};


export type QueryBatchOvertimeListingArgs = {
  BatchOvertimeStatus?: Maybe<Array<OvertimeStatus>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAdminBatchOvertimeListingArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  BatchOvertimeStatus?: Maybe<Array<OvertimeStatus>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBatchOvertimeToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryCompanyBusinessInsightArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightRawListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightStatutoryListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSalaryOverheadListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightPayItemListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopLeaveTakerByTypeArgs = {
  LeaveTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveBiLeaveTakenListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopClaimTakerArgs = {
  ClaimTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryClaimBiClaimTakenArgs = {
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs: Array<Scalars['String']>;
  Year: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTopRequisitionTakerArgs = {
  RequisitionType: Array<RequisitionType>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryRequisitionTakenSummaryByTypeArgs = {
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryHeadCountSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverHeadSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryTurnOverSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryHeadCountAndBasicSalaryByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeYosArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeSalaryArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeAgeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpGenderArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpRaceArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpEmploymentTypeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverheadByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryContributionByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeHrsAndCostArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryStaffTurnOverByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryGetClaimManagementListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ClaimRecordsInput;
};


export type QueryGetClaimRecordsTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
};


export type QueryClaimMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordsHistoryListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetApprovedClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimCountArgs = {
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingCountArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingPeriodListingArgs = {
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimEntitlementByClaimTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByClaimTypeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByEmployeeListingArgs = {
  IsV2?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetClaimToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetClaimApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimHomeMenuSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimReportDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpListingbyCompanyArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmpsClaimEntitlementArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyClaimEntitlementArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpsClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  input: EmpClaimEntitlementInput;
};


export type QueryGetMyClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimPoolBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetMyClaimDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryVerifyMyClaimArgs = {
  VerfiyClaimInput: VerfiyClaimInput;
};


export type QueryGetMainClaimListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpClaimListingArgs = {
  Input: Array<GetEmpClaimListingInput>;
};


export type QueryGetApprovedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetApproveClaimVerificationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimPostingManagementArgs = {
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordHistoryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetCompletedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryMyClaimDetailArgs = {
  ClaimDetailID: Scalars['String'];
};


export type QueryClaimListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetAllExpenseAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimValidityDetailsArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingForCheckingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateDetailArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type QueryGetClaimSettingsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryIsClaimSubjectExistsArgs = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryClaimPoolListingArgs = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeePoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeClaimPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryBankDetailArgs = {
  BankInfoIDs: Array<Scalars['String']>;
};


export type QueryBankListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsAccountNoExistsArgs = {
  CompanyID: Scalars['String'];
  AccountNo: Scalars['String'];
};


export type QueryIsAccountNameExistsArgs = {
  CompanyID: Scalars['String'];
  AccountName: Scalars['String'];
};


export type QueryHolidayListingArgs = {
  States: Scalars['String'];
  PeriodYear: Scalars['Float'];
};


export type QueryCompanyOffDaysListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyOffDaysListingWithFiltersArgs = {
  StateID: Scalars['String'];
  Title: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryOffRestDayListingArgs = {
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeStateAssignListingArgs = {
  IsDefault: Scalars['Boolean'];
  EmployeeFormStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeHomeCalendarArgs = {
  CompanyID: Scalars['String'];
  LeaveDate: Scalars['DateTime'];
};


export type QueryCompanyDeclaredHolidayListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCompanyDeclaredHolidayListingByCalendarPolicyIdArgs = {
  CalendarPolicyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  FolderID?: Maybe<Scalars['String']>;
  ParentIDs?: Maybe<Array<Scalars['String']>>;
  ParentID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryDocumentListingCountArgs = {
  ParentIDs?: Maybe<Array<Scalars['String']>>;
  ParentID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelDocumentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryCompanyFolderListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyListingByEmailArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCompanyListingArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryCompanyListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCompanySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanySummarySimplifiedArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionListingArgs = {
  JobDesignationID?: Maybe<Scalars['String']>;
};


export type QueryJobDesignationDetailArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationGradeListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingV2Args = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryRecruitmentSourceListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetCompanyCyclePeriodListV2Args = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAltAddressListingArgs = {
  IsStatutoryInfo?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyStatutoryDetailArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsCompanyHierarchyExistsArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyHierarchyListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListV2Args = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithFilterArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithEmpCheckArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJobPositionByOrgUnitListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionAssignmentListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  JobDesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAccessPermListArgs = {
  usePayload?: Maybe<Scalars['Boolean']>;
  RoleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmpCoOrgUnitListArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryDepartmentListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDivisionListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionListingArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryGetInsuranceSchemeArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeListingArgs = {
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInterbankDetailArgs = {
  InterbankGiroIDs: Array<Scalars['String']>;
};


export type QueryInterbankListingBulkArgs = {
  BankInfoID: Array<Scalars['String']>;
};


export type QueryInterbankListingArgs = {
  BankInfoID: Scalars['String'];
};


export type QueryGetBankInfoListingV2Args = {
  BankName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryMyJobAccountListingArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobAccountDetailArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobGradeDetailArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryJobGradeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradeListingByCompanyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradePolicyListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryJobGradePolicyListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveRoundingListingArgs = {
  LeaveRoundingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveCompulsoryPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLhdnReportDropdownListingArgs = {
  ReportYear: Scalars['Float'];
};


export type QueryLhdnReportDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryGetLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  periodYear: Scalars['Float'];
};


export type QueryNotificationPolicyDetailArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionListingArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionDetailArgs = {
  NotificationPolicyConditionID: Scalars['String'];
};


export type QueryNotificationPolicySummaryDatesArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleDetailArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingByTlaArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleByPayPeriodArgs = {
  ToPayPeriod: Scalars['DateTime'];
  FromPayPeriod: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodsByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayItemListingByTlaArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV2Args = {
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV3Args = {
  CompanyID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
};


export type QueryIsPayrollCycleLockedArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryReportingStructureGroupOptionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryReportingCompanyGroupListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReportingGroupTitleExistArgs = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryGetReportingStructureListingArgs = {
  CompanyID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
};


export type QueryGetSuperiorArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetSkillSetArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetSkillSetDetailArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};


export type QueryCoolbinetOpenDoorArgs = {
  CoolbinetID: Scalars['String'];
};


export type QueryGetLeaveOffDayArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveOffDayByAllStateWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePolicyByEmployeeArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeLeavePolicyListByDateArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPayItemPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetLeavePolicyByJobGradeIdArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
};


export type QueryGetOffboardEncashPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetPlatformNewLeaveTypePolicyArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetMyLeaveEntitlementArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeCalendarLeaveDetailsArgs = {
  input: EmpCalendarLeaveListing;
};


export type QueryEmployeeLeaveListingArgs = {
  input: EmpLeaveListingInput;
};


export type QueryGetEmpLeaveDetailArgs = {
  input: EmpLeaveDetailInput;
};


export type QueryGetLeaveRoundedValueArgs = {
  LeaveValue: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveReasonListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveDiseaseListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeavePolicyListingArgs = {
  ShortNoticeOptioIDs?: Maybe<Array<Scalars['String']>>;
  AvailableBasisIDs?: Maybe<Array<Scalars['String']>>;
  EntitlementMethodIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeavePolicyByIdArgs = {
  LeavePolicyID: Scalars['String'];
};


export type QueryLeaveTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypeTemplateDetailArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type QueryGetIntegratedLeaveBalanceArgs = {
  sTaken: Scalars['Float'];
  hTaken: Scalars['Float'];
  sEntilement: Scalars['Float'];
  hEntitlement: Scalars['Float'];
};


export type QueryGetEmployeesLeaveSummaryReportArgs = {
  IsReport?: Maybe<Scalars['Boolean']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  Sort?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeesLeaveSummaryReportNewArgs = {
  IsReport?: Maybe<Scalars['Boolean']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  Sort?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeesLeaveSummaryArgs = {
  AnnualLeaveOnly?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsV2?: Maybe<Scalars['Boolean']>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEnhanceGetEmployeesLeaveSummaryArgs = {
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAlBalanceForResignedEmployeesArgs = {
  toDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeCalendarArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTakenLeaveByEmployeeIdArgs = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeEntitlementByYearArgs = {
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetLeaveAdjustmentEmployeeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentEmployeeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeLeaveCriteriaByEmpIdArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetUserDashboardSettingArgs = {
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryGetOffboardingListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  OffboardingCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  LeaveCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetConfirmationDueListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ConfirmationCardType: DashboardCardType;
};


export type QueryGetAttendanceListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  AttendanceCardType: DashboardCardType;
};


export type QueryGetDataChangesListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentArgs = {
  DocumentID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentsByParentIdArgs = {
  ParentID?: Maybe<Scalars['String']>;
};


export type QueryGetMedicalRecordsArgs = {
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetDocumentsArgs = {
  DocumentIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDependentsSummaryArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type QuerySelectedDataChangesApprovalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDataChangesToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewDataChangesApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetDataImportTemplateArgs = {
  Type: Scalars['String'];
};


export type QueryShopeeImportArgs = {
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryShopeeImportRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryEmployeePendingActivateListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryDelegationListArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryDelegationInfoLineArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBulletinListingArgs = {
  NewsID: Scalars['String'];
};


export type QueryNewsListingArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryELearningNewsCountArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryNewsCountArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryTtda_ClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryGetPayrollReportListingArgs = {
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryListingArgs = {
  Types?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeMasterExcelReportArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetLeaveAnalysisExcelDataArgs = {
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  LeaveTypeIDs: Array<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryEmployeeClockingListingReportArgs = {
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryAbsenteeismListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIncompleteClockingListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIrregularAttendanceListingReportArgs = {
  IsExcludeAbsent?: Maybe<Scalars['Boolean']>;
  IsExcludeIncomplete?: Maybe<Scalars['Boolean']>;
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryLateInEarlyOutListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetNextDocNumberArgs = {
  RefTableName: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryDocumentNumberingListArgs = {
  IsStatusActive?: Maybe<Scalars['Boolean']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
};


export type QueryDocumentNumberingAssignmentListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryHasEmployeeDocNumberArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBatchEntryByShiftArgs = {
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBatchEntryByPatternArgs = {
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppraisalArgs = {
  EmployeeAppraisalID?: Maybe<Scalars['String']>;
};


export type QueryCareerLogListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeDiaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeDocumentListingArgs = {
  ParentID: Scalars['String'];
};


export type QueryTestNotificationArgs = {
  msg: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeListForSpecificSectionArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListForSpecificSectionCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListForReportingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListForReportingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingv2Args = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListingCountArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QuerySubordinateMenuSummaryArgs = {
  ApproverID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryGetOrgChartArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeArgs = {
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeTimeAttendanceArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTmsEmployeeListingArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryTmsEmployeeListingCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingBySubscriptionAccountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListingBySubscriptionAccountCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListForJobInfoArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryEmployeeAppSummaryArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAppDocSummaryArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeDelegationSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeContactIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeInfoKpiArgs = {
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
};


export type QueryZakatSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryBySubscriptionArgs = {
  SubscriptionUserID: Scalars['String'];
};


export type QueryGetEmployeePrevContributionArgs = {
  PeriodYear?: Maybe<Scalars['Float']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryIsSuperUserEmployeeArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterRequisitionListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryFixedAllowanceListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterEmployeeLeaveRecordsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  LeaveTypeIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTaxReliefListingDefaultExcludedArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEmployeeTaxReliefListingArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTaxReliefDetailsArgs = {
  EmployeeTaxReliefID: Scalars['String'];
};


export type QueryEmployeeAssetListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAssetDetailArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailForYearArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type QueryEmployeeDependentsListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryHasEmergencyContactArgs = {
  DependentID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryMyEmployeeInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyNewsListArgs = {
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyApplicationDetailArgs = {
  RequestID: Scalars['String'];
  RequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeCp22ListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeListingWithPayrollInputArgs = {
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeDirectoryListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDirectoryListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDirectoryDetailsArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeNotificationListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateListingArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeTrainingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeUserDefineFieldsListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryZakatContributionListingArgs = {
  input: ZakatContributionInput;
};


export type QueryZakatContributionDetailsArgs = {
  ID: Scalars['String'];
};


export type QueryIsZakatPeriodOverlapArgs = {
  PayrollCycleID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ZakatID?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppSecurityListingArgs = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityAssignmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityPermissionsByContactIdArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Permissions?: Maybe<Array<HrPermission>>;
  ContactID: Scalars['String'];
};


export type QueryEaDocumentsByEmployeeIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPayslipDocumentsByEmployeeIdArgs = {
  Year?: Maybe<Scalars['Float']>;
  EmployeeID: Scalars['String'];
};


export type QueryEonDocumentsByBatchArgs = {
  Type: Scalars['String'];
};


export type QuerySystemEonDocumentsCountArgs = {
  Type: Scalars['String'];
};


export type QueryHelpdeskListingReportArgs = {
  input: HelpdeskListingReportInput;
};


export type QueryHelpdeskSubjectDetailArgs = {
  SubjectID: Scalars['String'];
};


export type QueryIsHelpdeskSubjectExistArgs = {
  SubjectID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
};


export type QueryGetHelpdeskTicketSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryHelpdeskTicketDetailArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type QueryHelpdeskTicketListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Assignee?: Maybe<Array<Scalars['String']>>;
  HelpdeskSubjectType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RequestType: Scalars['String'];
};


export type QueryHelpdeskToDoListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryBlockLeaveListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBlockLeaveEmpListingArgs = {
  UntilDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementTableArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  TargetDate: Scalars['DateTime'];
  LeaveYear: Scalars['Float'];
};


export type QueryGetCarryForwardEncashmentPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetCarryForwardEncashmentPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryViewLeaveApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetLeaveRecordsListingArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  effectiveMonth?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveRecordsTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveReplacementListingByEmployeeArgs = {
  AsToDate?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  input: LeaveAdjustmentListingInput;
};


export type QueryGetCompulsoryLeaveListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveDetailArgs = {
  LeaveCompulsoryID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePostingListingArgs = {
  input: LeavePostingListingInput;
};


export type QueryGetLeaveApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveListingArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryLeaveAdjustmentDetailArgs = {
  LeaveHeaderID: Scalars['String'];
};


export type QueryGetLeavePostingPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYearEndCarryForwardListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryFilterGetYearEndCarryForwardListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveHomeSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveTakenListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarArgs = {
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarToDoArgs = {
  CalendarDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveMaxBalancePendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
};


export type QueryGetLeaveMaxBalancePostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
};


export type QueryCheckLeaveWithdrawalValidityArgs = {
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubmitDate: Scalars['DateTime'];
};


export type QueryGetCareerMovementEmployeesArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetCareerMovementEmployeesCountArgs = {
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryLeaveOutOfStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountMappingListArgs = {
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetAccountMappingAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryClaimAccountMappingListArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountMappingAssignmentArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryClaimAccountHeaderListWithDetailsArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPeClaimAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimRecordsListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryClaimGlCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
};


export type QueryCostCenterMappingListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsGlProcessedArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type QueryProjectCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMultiCurrencySetupArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCheckMultiCurrencyAlreadyExistArgs = {
  CurrencyName: Scalars['String'];
  CurrencyCode: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetEmployeeRoleNameArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyDetailsListingArgs = {
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckMultiCurrencyInUseArgs = {
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryOnBehalfMenuInfolineArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTimeAttendanceMenuInfolineArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryOnBehalfCompanyListingArgs = {
  Module: RequestType;
};


export type QueryOnBehalfSubordinateListingArgs = {
  ReportingGroupIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetOnBehalfPolicyTypeIDsArgs = {
  ReportingGroupID: Scalars['String'];
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type QueryTmsOnBehalfPolicySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOnBehalfPolicyListingByTypeArgs = {
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeForecastReportArgs = {
  FormulaFormat: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetSingleAdvancePolicyDetailArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type QueryDuplicateAdvancePolicyNameArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  AdvancePolicyName: Scalars['String'];
};


export type QueryAdvancePolicyByCompanyAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPostedAdvanceProcessingListingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayGroupListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  WithDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSinglePayGroupDetailArgs = {
  PayGroupID: Scalars['String'];
};


export type QueryCompanyPayGroupListingArgs = {
  CheckAccess?: Maybe<Scalars['Boolean']>;
  WithDefault?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
};


export type QueryCompanyAssignPayrollCycleArgs = {
  Page?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjustmentListingArgs = {
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjFormEmpListingArgs = {
  ExcludeEmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFormulaTestArgs = {
  formula: Scalars['String'];
};


export type QueryPayrollCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSalaryTestArgs = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySalaryPayoutAnalysisSummaryExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollDetailListingExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingAndCountArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCp22CountArgs = {
  ID: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollCyclePeriodSettingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryViewPayrollHistorySummaryArgs = {
  EmployeeID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryViewPayrollHistoryArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryByMonthArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInputByMonth;
};


export type QueryViewPayrollHistoryByPayrollCycleMenuArgs = {
  input: ViewPayrollHistoryInputByMonth;
};


export type QueryViewPayrollHistoryCountArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInputByMonth;
};


export type QueryViewPayrollHistoryTotalArgs = {
  input: ViewPayrollHistoryInputByMonth;
};


export type QueryViewPayrollHistoryMyPayrollInfoArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryByEmployeeArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryPayrollProcessPeriodListingArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  PayGroupID: Scalars['String'];
  IsViewHistory: Scalars['Boolean'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollHistoryPeriodListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayItemListingArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessEmployeeListingArgs = {
  input: EmployeeFilterInput;
};


export type QueryCalculatePayrollArgs = {
  PayrollCalculationInput: PayrollCalculationInput;
};


export type QueryCalculateEpfRateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRoundingArgs = {
  input: Scalars['Float'];
};


export type QueryCalculateHrdfArgs = {
  HRDFCalculationInput: HrdfCalculationInput;
};


export type QueryCalculateEpfv2Args = {
  EPFCalculationInput: EpfCalculationInputV2;
};


export type QueryCalculateEisArgs = {
  EISCalculationInput: EisCalculationInput;
};


export type QueryCalculateSocsoArgs = {
  SocsoCalculationInput: SocsoCalculationInput;
};


export type QueryPayPeriodListingArgs = {
  PayGroupID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryStartPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryEndPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryPayItemByPayTypeArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  PayType: Array<Scalars['String']>;
};


export type QueryPayItemListingArgs = {
  OnlySubscription?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListArgs = {
  PayItemID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListing2Args = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QuerySalaryListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySalaryListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryClaimTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryPayrollFixedAllowanceListingArgs = {
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceDetailsArgs = {
  RecurringPayID: Scalars['String'];
};


export type QueryCp159ListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingByPayPeriodAndPayCycleArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPublishPaySlipListingArgs = {
  CompanyID: Scalars['String'];
  PayGroupID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryPublishPaySlipListingByEmployeeArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetSingleUserArgs = {
  ID: Array<Scalars['String']>;
};


export type QueryPublishEaListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPublishEaListingByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIsMonthlyTrxInUseArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMonthlyTransactionByEmployeeArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByEmployeeCountArgs = {
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByPayItemArgs = {
  input: PayItemFilterInput;
};


export type QueryMonthlyTransactionByExcelArgs = {
  input: MonthlyTransactionInput;
};


export type QueryFreezepayrollListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollFixedAllowanceByExcelArgs = {
  PayGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPublishedEaCompanyListingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryCheckOldDataForSalaryBatchArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForOneTimePayInsertArgs = {
  PayPeriodID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForRecurringPayInsertArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryEmployeeSalaryLogsArgs = {
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryLogsCountArgs = {
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryPayrollHomeInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryOtherPayrollRelatedInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeKpiListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeKpiDetailsArgs = {
  EmployeeKpiID: Scalars['String'];
};


export type QueryEmployeeKpiRepoListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRetrieveEmployeeKpiDetailFromRepoArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type QueryIsKpiExistInRepoArgs = {
  KpiRepoID?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryKpiExerciseListingArgs = {
  KpiFormStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type QueryKpiExerciseSetupInfoArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type QueryIsKpiExerciseIdExistByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
};


export type QueryIsKpiExerciseDateRangeOverlappingByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
};


export type QueryKpiCategoryWithOrgGoalListingArgs = {
  Year: Scalars['Float'];
};


export type QueryOrganizationalGoalByCategoryListingArgs = {
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
};


export type QueryOrganizationalGoalDetailsArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type QueryIsOrgGoalExistByCategoryArgs = {
  OrgGoalID?: Maybe<Scalars['String']>;
  KpiCategoryID: Scalars['String'];
  Year: Scalars['Float'];
  Goal: Scalars['String'];
};


export type QueryOrgKpiPlanningTreeViewListingArgs = {
  Year: Scalars['Float'];
};


export type QueryKpiCategoryWithOrgKpiPlanningListingArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningDetailsByKpiCategoryListingArgs = {
  KpiCategoryID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningInfoArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryIsOrgKpiExistByCategoryArgs = {
  OrgKpiPlanningDetailslID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryPerformanceGroupMatrixListingArgs = {
  GroupType: Scalars['String'];
};


export type QueryMyPerformanceSummaryArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetPerformanceToDoArgs = {
  PerformanceRequestType: Scalars['String'];
  ContactID: Scalars['String'];
};


export type QueryGetPerformanceApprovalHistoryArgs = {
  PerformanceRequestType: Scalars['String'];
  ApprovalContactID: Scalars['String'];
};


export type QueryPerformanceEmployeeListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequestType: Scalars['String'];
};


export type QueryReviewExerciseListingArgs = {
  ReviewFormStatus?: Maybe<Array<Scalars['String']>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ReviewExerciseIDs: Array<Scalars['String']>;
};


export type QueryGetEmployeeReviewListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewActionListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ReviewID?: Maybe<Array<Scalars['String']>>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewFormArgs = {
  EmployeeReviewID: Scalars['String'];
};


export type QueryGetConversationLogByReviewFormItemArgs = {
  EmployeeReviewSectionItemID: Scalars['String'];
};


export type QueryReviewExerciseSetupInfoArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsReviewExerciseIdExistArgs = {
  ReviewExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
};


export type QueryPeerRegistrationListingArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsSuperiorAlreadyExistArgs = {
  NewSuperiorID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
};


export type QueryMySubordinatePerformanceSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySubordinateKpiEmpListingArgs = {
  ContactID: Scalars['String'];
};


export type QueryPeriodicAllowanceTypeListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryPersonnelCertificateListingArgs = {
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoListingArgs = {
  ContractInfoID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoDueListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoDueListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelEducationListingArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryIsEmailExistsArgs = {
  Email: Scalars['String'];
};


export type QueryIsMobileNoExistsArgs = {
  MobileNo: Scalars['String'];
};


export type QueryIsEmployeeNoExistsArgs = {
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
};


export type QueryDocumentProfilePictureArgs = {
  ContactID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEFileListingArgs = {
  Keyword?: Maybe<Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelEFileListingCountArgs = {
  Keyword?: Maybe<Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelHealthInfoSummaryArgs = {
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelHomeInfoLinesArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelJobHistoryListingArgs = {
  isInactive?: Maybe<Scalars['Boolean']>;
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelReferenceListingArgs = {
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryUserDefineDetailListingArgs = {
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeInUseArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  InsuranceSchemeID: Scalars['String'];
};


export type QueryPersonnelInsuranceSchemePersonnelListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type QueryPersonnelInsuranceSchemePersonnelListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type QueryProbationExtensionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationProbationListingArgs = {
  IsCompleted?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedCareerLogListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingBySubscriptionArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryStaffRetirementRetiredListingArgs = {
  Status?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRetirementDueListingArgs = {
  dueStatus?: Maybe<Scalars['String']>;
  retireDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffExitCompanyListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStaffRetirementDueListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  dueStatus?: Maybe<Scalars['String']>;
  retireDueDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRetirementRetiredListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffPromotionDemotionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PromotionOrDemotion: MovementType;
  CompanyID: Scalars['String'];
};


export type QueryStaffSecondmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRedesignationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInternalTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferCopyTransferDataArgs = {
  EffectiveDate: Scalars['DateTime'];
  CurrentCompanyID: Scalars['String'];
  CurrentEmployeeID: Scalars['String'];
  NewCompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferDetailsArgs = {
  CareerLogID: Scalars['String'];
};


export type QueryInterCoTransferLeavePolicyDataArgs = {
  EmployeeDetailsInput: EmployeeDetailsInput;
  EmployeeID: Scalars['String'];
  CurrentCompanyID: Scalars['String'];
};


export type QueryGetProjectExpenseClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryGetPeClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetPeClaimRecordsTotalCountArgs = {
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryProjecExpenseSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryVerifyMyPeClaimArgs = {
  VerfiyPEClaimInput: VerfiyPeClaimInput;
};


export type QueryIsProjectExpenseSubjectExistsArgs = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  input: ProjectExpenseClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryGetPeClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: PeClaimPostingPeriodListingInput;
};


export type QueryGetPeClaimPostingListingArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetApprovedProjectExpenseClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeApprovedClaimCountArgs = {
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetApprovedProjectExpenseClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryPeClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetProjectToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetProjectExpenseApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetProjectAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectAccountMappingListArgs = {
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectPayrollGlExportArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectGlClaimCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimCycleListArgs = {
  Type?: Maybe<Scalars['String']>;
  AllClaimCycles?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryProjectCostAllocationListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationEmployeeListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationDetailListingArgs = {
  ProjectCostAllocationID: Scalars['String'];
};


export type QueryGetApproverAssignmentListingArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type QueryIsApproverEmailAlreadyExistArgs = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  EmailAddress: Scalars['String'];
};


export type QueryProjectTimesheetPeriodListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryDailyEntryListingArgs = {
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetSubmissionListingArgs = {
  EmployeeCalendar: Scalars['Boolean'];
  DutyRoster: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetApprovalListingArgs = {
  token: Scalars['String'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingCountArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheetEmployeeListingArgs = {
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryAdminDailyEntryListingArgs = {
  PeriodYearMonth: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheeTApproverReportListingArgs = {
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryGetProjectTimesheetTakenLeaveByEmployeeIdArgs = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryELetterTemplateListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  PreofferChecklistID?: Maybe<Scalars['String']>;
};


export type QueryRecruitmentApprovalPolicyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  type: RecruitmentPolicyType;
};


export type QueryJobPortalSetupListingArgs = {
  isOption?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryJobPortalSetupDetailArgs = {
  JobPortalID: Scalars['String'];
};


export type QueryJobPortalQrCodeArgs = {
  JobPortalID: Scalars['String'];
};


export type QueryGetRecRequisitionToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_RequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetRecOfferToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_OfferApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetRecHiringToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_HiringApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryJobPortalInfoArgs = {
  PortalToken: Scalars['String'];
};


export type QueryJobPostingListByJobPortalArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  PortalToken: Scalars['String'];
};


export type QueryJobPostingListByJobPortalCountArgs = {
  PortalToken: Scalars['String'];
};


export type QueryJobPostingInfoByJobPortalArgs = {
  JobPostingID: Scalars['String'];
};


export type QueryTokenValidationArgs = {
  type?: Maybe<Scalars['String']>;
  portalToken?: Maybe<Scalars['String']>;
  talentToken?: Maybe<Scalars['String']>;
};


export type QueryEApplicationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEApplicationSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  TalentPoolID?: Maybe<Scalars['String']>;
};


export type QueryDataUpdateEApplicationSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  TalentPoolID?: Maybe<Scalars['String']>;
};


export type QueryRecruitmentParameterListingArgs = {
  type: Array<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTalentUserDefineFieldsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryDataUpdateTalentPersonalArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryDataUpdateHealthInfoArgs = {
  ContactID: Scalars['String'];
};


export type QueryLanguageParameterListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyRecruitmentMenuSummaryArgs = {
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyRecRequisitionListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyRecRequisitionListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyApprovedJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyApplicantListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyApplicantListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryMyRecInterviewListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  interview_status?: Maybe<Scalars['String']>;
  interviewer_id: Scalars['String'];
};


export type QueryMyRecInterviewListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  interview_status?: Maybe<Scalars['String']>;
  interviewer_id: Scalars['String'];
};


export type QueryMyOfferListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus>>;
  RequestorID: Scalars['String'];
};


export type QueryMyOfferListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus>>;
  RequestorID: Scalars['String'];
};


export type QueryMyOfferDetailArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryMyTalentSearchApplicationListingArgs = {
  TalentPoolID: Scalars['String'];
  RequestorID: Scalars['String'];
};


export type QueryTalentPoolSummaryArgs = {
  employeeID?: Maybe<Scalars['String']>;
};


export type QueryGetRecRequisitionInfoArgs = {
  RecRequisitionID: Scalars['String'];
};


export type QueryRecRequisitionListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecRequisitionListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecRequisitionNotInUseListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryRecRequisitionNotInUseListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryJobVacancyWithApplicantListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  application_stage: Scalars['String'];
  tabStatus: Scalars['String'];
};


export type QueryInterviewScheduleListingArgs = {
  rec_applicant_id?: Maybe<Scalars['String']>;
};


export type QueryJobVacancyNotInUseListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobVacancyNotInUseListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobPostingListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobPostingListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobPostingInfoArgs = {
  jobPostingId: Scalars['String'];
};


export type QueryPostedJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPostedJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPostedJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryApplicantListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryApplicantListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryActiveJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryActiveJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryActiveJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryFinalApplicantSelectionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryFinalApplicantSelectionListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryOfferProposalListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferProposalListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferProposalInfoArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryOfferProposalDetailListingArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryOfferApprovalListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferApprovalListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferApprovalDetailListingArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryApprovedJobOfferListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus>;
};


export type QueryApprovedJobOfferListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus>;
};


export type QueryApprovedJobOfferInfoArgs = {
  OfferProcessID: Scalars['String'];
};


export type QueryProcessedOfferInfoArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryELetterTemplateInfoArgs = {
  OfferChecklistID: Scalars['String'];
};


export type QueryApplicantOfferPreHiringListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryApplicantOfferPreHiringListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['DateTime']>;
};


export type QueryQuickHiringListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryQuickHiringListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryQuickHiringDetailsArgs = {
  hiringId: Scalars['String'];
};


export type QueryPreHiringDataUpdateListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  dataUpdateStatus: DataUpdateStatus;
};


export type QueryPreHiringDataUpdateListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  dataUpdateStatus: DataUpdateStatus;
};


export type QueryNewHireProcessListingArgs = {
  OnboardStatus: Array<OnboardStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryNewHireProcessListingCountArgs = {
  OnboardStatus: Array<OnboardStatus>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryNewHireProcessOnboardInfoArgs = {
  hiringId: Scalars['String'];
};


export type QueryTalentPoolListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  talentStatus?: Maybe<Array<RecruitmentTalentStatus>>;
  extraFilters?: Maybe<Array<Scalars['JSON']>>;
  preferLocations?: Maybe<Array<Scalars['String']>>;
  jobPositions?: Maybe<Array<Scalars['String']>>;
};


export type QueryTalentPoolListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  talentStatus?: Maybe<Array<RecruitmentTalentStatus>>;
  extraFilters?: Maybe<Array<Scalars['JSON']>>;
  preferLocations?: Maybe<Array<Scalars['String']>>;
  jobPositions?: Maybe<Array<Scalars['String']>>;
};


export type QueryTalentProfileSummaryArgs = {
  type?: Maybe<Scalars['String']>;
  TalentPoolID: Scalars['String'];
};


export type QueryTalentProfileDetailArgs = {
  formType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeID: Scalars['String'];
};


export type QueryTalentHealthInfoArgs = {
  PersonnelHealthInfoID: Scalars['String'];
};


export type QueryTalentApplicationListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  TalentPoolID: Scalars['String'];
};


export type QueryTalentApplicationListingCountArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryTalentJobVacancyAppliedListingArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryTalentSearchListingArgs = {
  employee_id?: Maybe<Scalars['String']>;
};


export type QueryTalentInvitationListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  inviteStatus?: Maybe<InviteStatus>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryTalentInvitationListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  inviteStatus?: Maybe<InviteStatus>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecruitmentApprovalPolicyByTypeArgs = {
  ApprovalPolicyType: Scalars['String'];
};


export type QueryWorkFlowDetailArgs = {
  typeID: Scalars['String'];
  type: Scalars['String'];
};


export type QueryRequisitionMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  filterText: Scalars['String'];
  ApStatus: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingTotalCountArgs = {
  ApStatus: Array<Scalars['String']>;
  DepartmentIDs: Array<Scalars['String']>;
  EmployeeStatus: Array<Scalars['String']>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeRequisitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailsArgs = {
  RequestCompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};


export type QueryFilterEmployeeRequisitionListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTravelRequsitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type QueryCheckVerifyEndIfArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRequisitionApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetRequisitionToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetRequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimParameterListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimParameterListingAllArgs = {
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimUserDefineFieldsListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryVehicleRateListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryPersonnelParameterListingByTypeArgs = {
  TypeList?: Maybe<Array<Scalars['String']>>;
};


export type QueryReportTemplateListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
};


export type QueryReportTemplateAssignmentListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStatesListingArgs = {
  Country?: Maybe<Scalars['String']>;
};


export type QueryViewGeneralSettingSummaryArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryHomeSummaryArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeMainMenuSummaryArgs = {
  JobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmploymentInfoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPersonnelMenuSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEaPartFListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEisPolicyDetailArgs = {
  EISPolicyID: Scalars['String'];
};


export type QueryEpfPolicyDetailArgs = {
  EPFPolicyID: Scalars['String'];
};


export type QueryFormItemRatingDetailsArgs = {
  FormItemRatingID: Scalars['String'];
};


export type QueryIsFormItemRatingTitleExistArgs = {
  FormItemRatingID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiCategoryDetailsArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryIsKpiCategoryTitleExistArgs = {
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedItemListingArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryKpiPredefinedItemDetailsArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type QueryIsKpiPredefinedItemTitleExistArgs = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedTemplateDetailsArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type QueryIsKpiPredefinedTemplateTitleExistArgs = {
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewFormTemplateDetailsArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type QueryIsReviewFormTemplateTitleExistArgs = {
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGradingTableDetailsArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type QueryIsReviewGradingTitleExistArgs = {
  ReviewGradingTableID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGroupDetailsArgs = {
  ReviewGroupID: Scalars['String'];
};


export type QueryIsReviewGroupTitleExistArgs = {
  ReviewGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewItemDetailsArgs = {
  ReviewItemID: Scalars['String'];
};


export type QueryIsReviewItemCodeExistArgs = {
  ReviewItemID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
};


export type QueryReviewPolicyDetailsArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type QueryIsReviewPolicyTitleExistArgs = {
  ReviewPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewProcessPolicyDetailsArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type QueryIsReviewProcessPolicyTitleExistArgs = {
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewTemplateDetailsArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type QueryIsReviewTemplateTitleExistArgs = {
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryRewardGroupDetailsArgs = {
  RewardGroupID: Scalars['String'];
};


export type QueryIsRewardGroupTitleExistArgs = {
  RewardGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryPublicHolidayCalendarListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayForStatesListingArgs = {
  Description: Scalars['String'];
  HolidayDate: Scalars['DateTime'];
};


export type QuerySocsoPolicyDetailArgs = {
  SocsoPolicyID: Scalars['String'];
};


export type QuerySystemAdminMenuSummaryArgs = {
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryTaxReliefListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryAttendanceAnalysisArgs = {
  ToDate?: Maybe<Scalars['String']>;
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryMonthlyAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryProcessingListingArgs = {
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAutomatedClockDataImportListingArgs = {
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
};


export type QueryGetBeaconDevicesListingArgs = {
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
};


export type QueryGetChangeShiftArgs = {
  CompanyID: Scalars['String'];
  ChangeShiftID?: Maybe<Scalars['String']>;
};


export type QueryGetShiftByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByTlaArgs = {
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByShiftPatternArgs = {
  CompanyID: Scalars['String'];
  ShiftPatternID: Scalars['String'];
};


export type QueryGetShiftPatternByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpByDutyArgs = {
  DutyDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetChangeShiftListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryViewChangeShiftApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetChangeShiftToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Date: Scalars['DateTime'];
};


export type QueryChangeShiftMenuSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDailyAllowanceArgs = {
  DailyAllowanceID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};


export type QueryGetDailyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDeductionPolicyArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetDeductionPolicyByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePostingArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryEmployeeTmsSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradePolicyMatrixArgs = {
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  JobGradeID?: Maybe<Scalars['String']>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetMobileCheckInArgs = {
  Status?: Maybe<Status>;
  MobileCheckInID?: Maybe<Scalars['String']>;
};


export type QueryQrListingArgs = {
  DocumentID: Scalars['String'];
};


export type QueryEmpAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryVerifyScanArgs = {
  Location: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOvertimeClaimTableArgs = {
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetOvertimeReasonArgs = {
  OTReason?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryOtReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryOtReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPeriodicDeductionArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllowanceDeductionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AllowanceDeductionID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOtRoundingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftDayArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftGroupArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeShiftGroupArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeShiftGroupAssignmentLogArgs = {
  Today?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetShiftPatternArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryShiftPatternListingArgs = {
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryValidShiftPatternListingArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryTemplateLibrarySummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTmsSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryUserDefineEpfPolicyConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetTemplateLibraryArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetTemplateLibraryAssignmentArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradeByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetTemplateLibraryAssignmentInfolineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDailyTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDailyPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryDeductionTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDeductionPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTmsTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOtPayItemConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeOtPoliciesArgs = {
  WorkDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTmsTimeOffConditionListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryMonthlyTypeConditionListArgs = {
  ClaimType: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTmsPolicyMenuInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByYearArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryViewTimeOffApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetTimeOffAproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetTimeOffToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryTimeOffApplicationPeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryTimeOffPolicyDetailsByEmployeeIdArgs = {
  Date?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetTimeOffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTimeOffSettingListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTsAccountTypeListArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTsAccountCodeListArgs = {
  AccountTypeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTimesheetListArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetHoursArgs = {
  SheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetDatesArgs = {
  sheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccCodeArgs = {
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByActivityArgs = {
  Activity: Scalars['String'];
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccTypeArgs = {
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccountArgs = {
  Account: Scalars['String'];
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsActvitiyInUseArgs = {
  TsAccountTypeID: Scalars['String'];
  Activities: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryToDoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainingAttendanceListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeTrainingRecordArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeTrainingAttendanceListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsaryCourseByJobGradeListingArgs = {
  CourseID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsaryCourseByJobGradeListingWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTrainingCourseListingArgs = {
  CourseClassificationID: Scalars['String'];
};


export type QueryTrainingCourseListingByJobGradeArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryGetLatestUpdateForMainMenuModuleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySupervisorEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCourseEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainerEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTrainerEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSupervisorEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByJobGradeArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByCourseArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOsFulfilmentByJobGradeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryScheduleTrainingListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRegisteredTraineeListingArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type QueryEmployeeScheduledTrainingListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAvailableCoursesListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsoryCourseListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmpTrainingAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCostBudgetListingArgs = {
  Year: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainingCostListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryCostAllocationListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryHrdfClaimListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApprovalPolicyByTypeArgs = {
  TrainingApprovalPolicyType: Training_Policy_Type;
};


export type QueryTrainingRequestListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingRequestBudgetInfoArgs = {
  EmployeeID: Scalars['String'];
  TrainingRequestID: Scalars['String'];
};


export type QueryScheduledTrainingListingArgs = {
  EventStatus?: Maybe<Array<Event_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryTrainingRegistrationListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryScheduledTrainingVenueListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryGetTrainingQrCodeArgs = {
  ScheduledTrainingVenueID: Scalars['String'];
};


export type QueryOngoingTrainingListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryGetOngoingTrainingAttendanceArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingEvaluationListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingEvaluationSurveyFormListingArgs = {
  SurveyFormIDs?: Maybe<Array<Scalars['String']>>;
  SurveyTypeIDs?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingEvaluationAttendeesListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingSurveyID: Scalars['String'];
};


export type QueryGetSurveyFormStructureArgs = {
  SurveyFormID: Scalars['String'];
};


export type QueryEvaluationResultListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryEvaluationResultParticipantListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryEmployeeEvaluationResultArgs = {
  SurveyFormIDs?: Maybe<Array<Scalars['String']>>;
  SurveyTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type QueryAdminExpensesFilterListingArgs = {
  input?: Maybe<AdminExpenseFilterInput>;
};


export type QueryHrdcClaimListingArgs = {
  HRDCClaimStatus?: Maybe<Array<Approval_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingFulfillmentByCcArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
};


export type QueryTrainingFulfillmentByCcDetailsArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  TrainingCourseLibraryID: Scalars['String'];
};


export type QueryTrainingFulfillmentByThArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
};


export type QueryTrainingFulfillmentByThDetailsArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTrainingRecordsListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetEmployeeTrainingRecordsArgs = {
  ParticipationStatus?: Maybe<Array<Participation_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTrainingRequestListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryGetTodoTrainingEvaluationArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryMySubordinateTrainingSummaryArgs = {
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySubordinateTrainingListingSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingScheduledTrainingListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTrainingRecordsListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentSummaryArgs = {
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByCcArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByCcDetailsArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  TrainingCourseLibraryID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByThArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMyTrainingSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCheckInHistoryArgs = {
  PeriodYearMonth: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingRequiredTrainingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingRequiredTrainingCalendarListingArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  TrainingCourseLibraryID: Scalars['String'];
  TargetGroupIDs: Array<Scalars['String']>;
};


export type QuerySubordinateScheduledTrainingListingArgs = {
  EventStatus?: Maybe<Array<Event_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTrainingRegistrationListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingCalendarEmployeeArgs = {
  CalendarDate?: Maybe<Scalars['DateTime']>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCalendarSubordinateArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  TrainingEventID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetTrainingToDoArgs = {
  TrainingRequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCourseLibraryListingArgs = {
  course_category_id: Scalars['String'];
};


export type QueryGetCourseDetailArgs = {
  training_course_library_id: Scalars['String'];
};


export type QueryGetTrainingProviderDetailArgs = {
  training_provider_id: Scalars['String'];
};


export type QueryTrainingProviderHistoryArgs = {
  training_provider_id: Scalars['String'];
};


export type QueryGetSurveyItemDetailArgs = {
  survey_item_id: Scalars['String'];
};


export type QueryGetSurveyFormDetailArgs = {
  survey_form_id: Scalars['String'];
};


export type QueryEmployeeTrainerProfilePersonnelInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainerProfileHistoryArgs = {
  trainer_profile_id: Scalars['String'];
};


export type QueryTrainingApprovalPoliciesListingArgs = {
  policy_category: Training_Policy_Type;
};


export type QueryIsTrainingProviderCompanyIdExistArgs = {
  TrainingProviderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingCourseLibraryCodeExistArgs = {
  TrainingCourseLibraryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingProviderNameExistArgs = {
  TrainingProviderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingEventNameExistArgs = {
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainerProfileEmailExistArgs = {
  TrainerProfileID?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type QueryGetPersonalTtdaInfoArgs = {
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  IsApprovalPolicy?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryUserRoleIDbyUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
};


export type QueryGetCompanyPermissionArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetRolePermissionByUserArgs = {
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetUsersRoleContractArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetUserRolePermissionByRoleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetMileageLogListGroupArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMileageLogListGroupCountArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMileageLogListArgs = {
  VehicleTypeID: Scalars['String'];
  Period: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMileageLogDetailArgs = {
  MileageLogID: Scalars['String'];
};


export type QueryGetMileageLogsByIDsArgs = {
  MileageLogIDs: Array<Scalars['String']>;
};


export type QueryAllowancePolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowanceEntitlementListingArgs = {
  AllowanceBenefitID: Scalars['String'];
};


export type QueryAllowanceEntitlementDetailArgs = {
  AllowanceItemIDs: Array<Scalars['String']>;
};


export type QueryCompanySummaryDatesArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayProcessSelectPayPeriodArgs = {
  input: PayPeriodSelectionInput;
};


export type QueryPayProcessSelectPayrollCycleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessEmployeeListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessDetailArgs = {
  PayrollDetailID: Scalars['String'];
};


export type QueryMobileNotificationArgs = {
  Body: Scalars['String'];
  Title: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryLoginSsoArgs = {
  DeviceToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryVerifyRedirectDomainTokenArgs = {
  Token: Scalars['String'];
};


export type QueryGetUserListingArgs = {
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  UserStatus?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeUserListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
  accountID: Scalars['String'];
};


export type QueryGetActiveUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryUserProfilePicArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  UserID: Scalars['String'];
};


export type QueryGetNonAccessUsersArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetNonAccessUsersCountArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryTestWorkFlowServicesArgs = {
  input?: Maybe<Scalars['Float']>;
};

export type QuickHiringDetails = {
  __typename?: 'QuickHiringDetails';
  rec_hiring_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  acceptance_date?: Maybe<Scalars['DateTime']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  prehiring_status?: Maybe<PrehiringStatus>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  superior_id?: Maybe<Scalars['String']>;
  superior_full_name?: Maybe<Scalars['String']>;
  superior_no?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type QuickHiringInput = {
  company_id: Scalars['String'];
  org_unit_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  email: Scalars['String'];
  mobile_no: Scalars['String'];
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status?: Maybe<PrehiringStatus>;
  data_update_status?: Maybe<DataUpdateStatus>;
  data_update_status_log?: Maybe<Scalars['JSON']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_file_input?: Maybe<DocumentsInput>;
  talentStatusLog?: Maybe<TalentStatusLog>;
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Iban = 'Iban',
  Bidayuh = 'Bidayuh',
  Melanau = 'Melanau',
  OrangUlu = 'Orang_Ulu',
  Bajau = 'Bajau',
  Murut = 'Murut',
  KadazanDusun = 'Kadazan_Dusun',
  OthersSarawakNative = 'Others_Sarawak_Native',
  OthersSabahNative = 'Others_Sabah_Native',
  Others = 'Others'
}

export enum RatingInputMethod {
  Fixed = 'Fixed',
  Range = 'Range'
}

export type RatingScaleTable = {
  __typename?: 'RatingScaleTable';
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type RatingScaleTableInput = {
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export enum ReadStatus {
  Read = 'Read',
  Unread = 'Unread'
}

export type Reason = InterfaceBase & {
  __typename?: 'Reason';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type ReasonInput = {
  Name: Scalars['String'];
};

export type Rec_ApplicantEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_ApplicantEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_applicant_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  rec_job_posting_id: Scalars['String'];
  application_stage?: Maybe<Scalars['String']>;
  availability_date: Scalars['DateTime'];
  application_status: Scalars['String'];
  application_status_log?: Maybe<Array<Scalars['JSON']>>;
  expected_salary: Scalars['Float'];
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  interview_remark_voice?: Maybe<Scalars['String']>;
  interview_remark_voice_file?: Maybe<DocumentsEntity>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  rec_job_posting?: Maybe<Rec_JobPostingEntity>;
};

export type Rec_ApplicantInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  application_status?: Maybe<Scalars['String']>;
  application_status_log?: Maybe<Array<Scalars['JSON']>>;
  expected_salary?: Maybe<Scalars['Float']>;
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  interview_remark_voice?: Maybe<Scalars['String']>;
  interview_remark_voice_input?: Maybe<DocumentsInput>;
};

export type Rec_ApplicantOutput = {
  __typename?: 'Rec_ApplicantOutput';
  rec_applicant_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  preferred_location?: Maybe<Scalars['String']>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  application_status?: Maybe<Scalars['String']>;
  application_status_log?: Maybe<Scalars['JSON']>;
  applicant_count_by_stage?: Maybe<Scalars['JSON']>;
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  expected_salary?: Maybe<Scalars['Float']>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  contact?: Maybe<ContactEntity>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  personnel_job_history?: Maybe<PersonnelJobHistoryEntity>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_requisition?: Maybe<Scalars['JSON']>;
  requisition_no?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_offer_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  latest_offer_status?: Maybe<Scalars['String']>;
  offer_count?: Maybe<Scalars['Float']>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  offer_process_status?: Maybe<RecruitmentOfferProcessStatus>;
  requestor_name?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  rec_offer_content?: Maybe<Array<Rec_OfferContentEntity>>;
};

export type Rec_DeclineReasonEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_DeclineReasonEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_decline_reason_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  reason: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type Rec_DeclineReasonInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_decline_reason_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type Rec_EApplicationFormSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_EApplicationFormSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_eapplication_form_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  section_name: Scalars['String'];
  sequence: Scalars['Float'];
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  content: Scalars['String'];
  min_record: Scalars['Float'];
};

export type Rec_EApplicationFormSetupInput = {
  section_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  min_record?: Maybe<Scalars['Float']>;
  rec_eapplication_form_setup_id?: Maybe<Scalars['String']>;
};

export type Rec_HiringEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_HiringEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_hiring_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  company_id: Scalars['String'];
  org_unit_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  prehiring_status: Scalars['String'];
  is_cancel?: Maybe<Scalars['Boolean']>;
  cancel_remark?: Maybe<Scalars['String']>;
  data_update_status?: Maybe<Scalars['String']>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_status?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<Scalars['String']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  rec_applicant?: Maybe<Rec_ApplicantEntity>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  superior?: Maybe<EmployeeEntity>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_source?: Maybe<RecruitmentSourceEntity>;
  onboard_approval_remark_voice_file?: Maybe<DocumentsEntity>;
  employee?: Maybe<EmployeeEntity>;
  submitter?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecHiringHistory?: Maybe<Array<Rec_HiringEntity>>;
};

export type Rec_HiringOutput = {
  __typename?: 'Rec_HiringOutput';
  rec_hiring_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  org_unit_name: Scalars['String'];
  job_designation_id: Scalars['String'];
  job_designation_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status: Scalars['String'];
  data_update_status?: Maybe<Scalars['String']>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  requisition_no?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  in_use?: Maybe<Scalars['Boolean']>;
  onboard_status?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<DocumentsEntity>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecHiringHistory?: Maybe<Array<Rec_HiringOutput>>;
};

export type Rec_InterviewListing_Output = {
  __typename?: 'Rec_InterviewListing_Output';
  rec_applicant_id?: Maybe<Scalars['String']>;
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  applicant_name?: Maybe<Scalars['String']>;
  applicant_image?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  requisition_no?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  schedule_remark?: Maybe<Scalars['String']>;
  rec_interviewer_id?: Maybe<Scalars['String']>;
  interviewer_id?: Maybe<Scalars['String']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<InterviewerRating>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  interviewer_remark?: Maybe<Scalars['String']>;
  attachment_id?: Maybe<Scalars['String']>;
  attachment_file?: Maybe<Scalars['String']>;
  attachment_file_name?: Maybe<Scalars['String']>;
  schedule_round?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type Rec_InterviewScheduleEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_InterviewScheduleEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_interview_schedule_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  interview_date: Scalars['DateTime'];
  start_time: Scalars['DateTime'];
  end_time: Scalars['DateTime'];
  venue: Scalars['String'];
  is_complete: Scalars['Boolean'];
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  applicant?: Maybe<Rec_ApplicantEntity>;
  interviewers?: Maybe<Array<Rec_InterviewerEntity>>;
};

export type Rec_InterviewScheduleInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_applicant_id: Scalars['String'];
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  interviewers?: Maybe<Array<Scalars['ID']>>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_input?: Maybe<DocumentsInput>;
};

export type Rec_InterviewScheduleOutput = {
  __typename?: 'Rec_InterviewScheduleOutput';
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  interviewers?: Maybe<Array<Rec_InterviewerEntity>>;
  applicant?: Maybe<Rec_ApplicantEntity>;
  remark_voice_file?: Maybe<DocumentsEntity>;
};

export type Rec_InterviewerEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_InterviewerEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_interviewer_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_interview_schedule_id: Scalars['String'];
  interviewer_id: Scalars['String'];
  rating?: Maybe<InterviewerRating>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  interview_schedule?: Maybe<Rec_InterviewScheduleEntity>;
  interviewer?: Maybe<EmployeeEntity>;
};

export type Rec_InterviewerInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_interviewer_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_interview_schedule_id: Scalars['String'];
  interviewer_id: Scalars['String'];
  rating?: Maybe<InterviewerRating>;
  remark?: Maybe<Scalars['String']>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  remark_voice_input?: Maybe<DocumentsInput>;
  attachment_input?: Maybe<DocumentsInput>;
};

export type Rec_JobPortalSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPortalSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_portal_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  desktop_banner_attachment?: Maybe<Scalars['String']>;
  mobile_banner_attachment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  theme_color?: Maybe<Scalars['String']>;
  job_portal_qr?: Maybe<Scalars['String']>;
  job_portal_token: Scalars['String'];
  desktop_banner?: Maybe<DocumentsEntity>;
  mobile_banner?: Maybe<DocumentsEntity>;
  qrcode?: Maybe<DocumentsEntity>;
};

export type Rec_JobPortalSetupInput = {
  name: Scalars['String'];
  desktop_banner_attachment?: Maybe<Scalars['String']>;
  mobile_banner_attachment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  theme_color?: Maybe<Scalars['String']>;
  job_portal_qr?: Maybe<Scalars['String']>;
  job_portal_token?: Maybe<Scalars['String']>;
  desktop_banner_input?: Maybe<DocumentsInput>;
  mobile_banner_input?: Maybe<DocumentsInput>;
  qrcode_input?: Maybe<DocumentsInput>;
};

export type Rec_JobPostingEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPostingEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_posting_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_posting_status: Scalars['String'];
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  rec_job_vacancy: Rec_JobVacancyEntity;
  rec_job_posting_portal: Array<Rec_JobPostingPortalEntity>;
};

export type Rec_JobPostingInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_posting_status: RecruitmentJobPostingStatus;
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  rec_job_portal_setup_id?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Scalars['String']>;
};

export type Rec_JobPostingOutput = {
  __typename?: 'Rec_JobPostingOutput';
  rec_job_posting_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  job_posting_status?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  job_closing_date?: Maybe<Scalars['DateTime']>;
  remark?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_job_posting_portal?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
  applicant_count?: Maybe<Scalars['JSON']>;
};

export type Rec_JobPostingPortalEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPostingPortalEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_posting_portal_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_posting_id: Scalars['String'];
  rec_job_portal_setup_id: Scalars['String'];
  rec_job_posting: Rec_JobPostingEntity;
  rec_job_portal_setup: Rec_JobPortalSetupEntity;
};

export type Rec_JobPostingUpdate = {
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  rec_job_portal_setup_id: Array<Scalars['String']>;
};

export type Rec_JobPostingUpdateStatus = {
  job_posting_status: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type Rec_JobVacancyEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancyEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_requisition_id: Scalars['String'];
  job_vacancy_no: Scalars['String'];
  job_vacancy_status: Scalars['String'];
  location: Scalars['String'];
  target_hiring_date: Scalars['DateTime'];
  job_vacancy_status_log: Array<Scalars['JSON']>;
  requisition?: Maybe<Rec_RequisitionEntity>;
};

export type Rec_JobVacancyExpenseEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancyExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_expense_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  rec_expense_id: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  rec_job_vacancy?: Maybe<Rec_JobVacancyEntity>;
  rec_expense?: Maybe<RecruitmentExpensesEntity>;
};

export type Rec_JobVacancyExpenseInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_expense_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_expense_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type Rec_JobVacancyInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  target_hiring_date: Scalars['DateTime'];
  job_vacancy_status_log?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_JobVacancyOutput = {
  __typename?: 'Rec_JobVacancyOutput';
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  job_vacancy_status_log?: Maybe<Array<Scalars['JSON']>>;
  total_amount?: Maybe<Scalars['Float']>;
  rec_requisition?: Maybe<Scalars['JSON']>;
  requisition_no?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_job_vacancy_source?: Maybe<Array<Rec_JobVacancySourceEntity>>;
  rec_job_vacancy_expense?: Maybe<Array<Rec_JobVacancyExpenseEntity>>;
  applicant_count_by_stage?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
  application_stage?: Maybe<Scalars['String']>;
  is_applied?: Maybe<Scalars['Boolean']>;
  has_access?: Maybe<Scalars['Boolean']>;
};

export type Rec_JobVacancySourceEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancySourceEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_source_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  source_id: Scalars['String'];
  rec_job_vacancy?: Maybe<Rec_JobVacancyEntity>;
  rec_source?: Maybe<RecruitmentSourceEntity>;
};

export type Rec_JobVacancySourceInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_source_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
};

export type Rec_JobVacancyWithApplicantOutput = {
  __typename?: 'Rec_JobVacancyWithApplicantOutput';
  job_vacancy_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_posting_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  applicant_name?: Maybe<Scalars['String']>;
  applicant_image?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  expected_salary?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  final_selection_status?: Maybe<Scalars['String']>;
};

export type Rec_OfferContentEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferContentEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_content_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_offer_process_id: Scalars['String'];
  rec_offer_checklist_id: Scalars['String'];
  eletter_document?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  offer_content_status: RecruitmentOfferContentStatus;
  rec_offer_process?: Maybe<Rec_OfferProcessEntity>;
  eletter_document_file?: Maybe<DocumentsEntity>;
};

export type Rec_OfferContentInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_content_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  eletter_document?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  offer_content_status?: Maybe<RecruitmentOfferContentStatus>;
  eletter_document_input?: Maybe<DocumentsInput>;
};

export type Rec_OfferEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  salary?: Maybe<Scalars['Float']>;
  probation_month?: Maybe<Scalars['Float']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice?: Maybe<Scalars['String']>;
  offer_attachment?: Maybe<Scalars['String']>;
  submit_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  response_date?: Maybe<Scalars['DateTime']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  response_attachment?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
  rec_applicant?: Maybe<Rec_ApplicantEntity>;
  offer_remark_voice_file?: Maybe<DocumentsEntity>;
  offer_attachment_file?: Maybe<DocumentsEntity>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  response_remark_voice_file?: Maybe<DocumentsEntity>;
  response_attachment_file?: Maybe<DocumentsEntity>;
  rec_decline_reason?: Maybe<Rec_DeclineReasonEntity>;
  submitter?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecOfferHistory?: Maybe<Array<Rec_OfferEntity>>;
};

export type Rec_OfferInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  probation_month?: Maybe<Scalars['Float']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice?: Maybe<Scalars['String']>;
  offer_remark_voice_input?: Maybe<DocumentsInput>;
  offer_attachment?: Maybe<Scalars['String']>;
  offer_attachment_input?: Maybe<DocumentsInput>;
  submit_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  approval_id?: Maybe<Scalars['String']>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  approval_remark_voice_input?: Maybe<DocumentsInput>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  response_date?: Maybe<Scalars['DateTime']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  response_remark_voice_input?: Maybe<DocumentsInput>;
  response_attachment?: Maybe<Scalars['String']>;
  response_attachment_input?: Maybe<DocumentsInput>;
  decline_reason?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
};

export type Rec_OfferOutput = {
  __typename?: 'Rec_OfferOutput';
  rec_offer_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  probation_month?: Maybe<Scalars['Float']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice_file?: Maybe<DocumentsEntity>;
  offer_attachment_file?: Maybe<DocumentsEntity>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary: Scalars['Float'];
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  offer_count?: Maybe<Scalars['Float']>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  offer_process_status?: Maybe<RecruitmentOfferProcessStatus>;
  send_log?: Maybe<Array<Scalars['JSON']>>;
  requestor_name?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  rec_offer_content?: Maybe<Array<Rec_OfferContentEntity>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  submit_date?: Maybe<Scalars['DateTime']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecOfferHistory?: Maybe<Array<Rec_OfferOutput>>;
};

export type Rec_OfferProcessEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferProcessEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_process_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_offer_id: Scalars['String'];
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  offer_process_status: RecruitmentOfferProcessStatus;
  send_log?: Maybe<Array<Scalars['JSON']>>;
  rec_offer?: Maybe<Rec_OfferEntity>;
};

export type Rec_OfferProcessInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  rec_offer_id?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<OfferChecklistSnapshot>>;
  offer_process_status: RecruitmentOfferProcessStatus;
  send_log?: Maybe<Array<SendLog>>;
};

export type Rec_PrehiringFormSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_PrehiringFormSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_prehiring_form_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  section_name: Scalars['String'];
  sequence: Scalars['Float'];
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  min_record: Scalars['Float'];
};

export type Rec_PrehiringFormSetupInput = {
  section_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
  min_record?: Maybe<Scalars['Float']>;
  rec_prehiring_form_setup_id?: Maybe<Scalars['String']>;
};

export type Rec_RequisitionEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_RequisitionEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_requisition_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type: EmploymentType;
  recruitment_type: RecruitmentType;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<Scalars['String']>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  approval_log: Array<Scalars['JSON']>;
  requestor?: Maybe<EmployeeEntity>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation?: Maybe<JobDesignationEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  justification_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  min_education?: Maybe<PersonnelParameterEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecRequisitionHistory?: Maybe<Array<Rec_RequisitionEntity>>;
  org_unit_name: Scalars['String'];
};

export type Rec_RequisitionInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type?: Maybe<Scalars['String']>;
  recruitment_type?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_voice_input?: Maybe<DocumentsInput>;
  justification_voice_id?: Maybe<Scalars['String']>;
  attachment_input?: Maybe<DocumentsInput>;
  attachment_id?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<ApprovalStatus>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_voice_input?: Maybe<DocumentsInput>;
  approval_voice_id?: Maybe<Scalars['String']>;
  approval_log?: Maybe<Array<Approval_Log>>;
  approval_id?: Maybe<Scalars['String']>;
};

export type Rec_Requisition_Output = {
  __typename?: 'Rec_Requisition_Output';
  rec_requisition_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  requestor_id?: Maybe<Scalars['String']>;
  requestor?: Maybe<EmployeeEntity>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type?: Maybe<Scalars['String']>;
  recruitment_type?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_remark_voice_file?: Maybe<DocumentsEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  min_education?: Maybe<PersonnelParameterEntity>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<Scalars['String']>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  RecRequisitionHistory?: Maybe<Array<Rec_Requisition_Output>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type Rec_TalentInvitationEntity = {
  __typename?: 'Rec_TalentInvitationEntity';
  rec_talent_invitation_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  source_id?: Maybe<Scalars['String']>;
  invite_date: Scalars['DateTime'];
  invite_status: InviteStatus;
  is_cancel?: Maybe<Scalars['Boolean']>;
  cancel_remark?: Maybe<Scalars['String']>;
  talent_invitation_log?: Maybe<Array<Scalars['JSON']>>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
};

export type Rec_TalentInvitationOutput = {
  __typename?: 'Rec_TalentInvitationOutput';
  rec_talent_invitation_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  invite_date?: Maybe<Scalars['DateTime']>;
  talent_invitation_log?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentPoolEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentPoolEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_pool_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  contact_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  invite_token?: Maybe<Scalars['String']>;
  talent_status?: Maybe<RecruitmentTalentStatus>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_blacklist: Scalars['Boolean'];
  is_hire: Scalars['Boolean'];
  declaration_dt?: Maybe<Scalars['DateTime']>;
  no_of_children?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  current_location?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  additional_user_field?: Maybe<Array<Scalars['JSON']>>;
  contact?: Maybe<ContactEntity>;
};

export type Rec_TalentPoolInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  invite_token?: Maybe<Scalars['String']>;
  talent_status?: Maybe<Scalars['String']>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_blacklist?: Maybe<Scalars['Boolean']>;
  is_hire?: Maybe<Scalars['Boolean']>;
  declaration_dt?: Maybe<Scalars['DateTime']>;
  no_of_children?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  current_location?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  additional_user_field?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentPoolOutput = {
  __typename?: 'Rec_TalentPoolOutput';
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  talent_status?: Maybe<Scalars['String']>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_hire?: Maybe<Scalars['Boolean']>;
  is_blacklist?: Maybe<Scalars['Boolean']>;
  application_count?: Maybe<Scalars['Float']>;
  applicant_ids?: Maybe<Array<Scalars['String']>>;
  talent_image?: Maybe<Scalars['String']>;
  talent_name?: Maybe<Scalars['String']>;
  job_position?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_drawn_salary?: Maybe<Scalars['Float']>;
  is_current_job?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentPoolUserFieldsEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentPoolUserFieldsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_additional_user_field_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  field_label: Scalars['String'];
  field_type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['JSON']>>;
  is_required?: Maybe<Scalars['Boolean']>;
  is_active?: Maybe<Scalars['Boolean']>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentPoolUserFieldsInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_additional_user_field_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  field_label?: Maybe<Scalars['String']>;
  field_type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['JSON']>>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentSearchEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentSearchEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_search_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  predefined_search_name: Scalars['String'];
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentSearchInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  predefined_search_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentSearchOutput = {
  __typename?: 'Rec_TalentSearchOutput';
  rec_talent_search_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  predefined_search_name?: Maybe<Scalars['String']>;
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentStatutoryEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentStatutoryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_statutory_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  bank: Scalars['String'];
  account_no: Scalars['String'];
  EPF_no?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  tax_marital_status?: Maybe<TaxMaritialStatus>;
  tax_resident_status?: Maybe<ResidentStatus>;
  country_code: Scalars['String'];
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
};

export type Rec_TalentStatutoryInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  bank: Scalars['String'];
  account_no: Scalars['String'];
  EPF_no?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  tax_marital_status?: Maybe<TaxMaritialStatus>;
  tax_resident_status?: Maybe<ResidentStatus>;
  country_code?: Maybe<Scalars['String']>;
};

export enum RecommendOption {
  AnnualIncrement = 'Annual_Increment',
  SalaryAdjustment = 'Salary_Adjustment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  WitholdIncrement = 'Withold_Increment',
  Termination = 'Termination'
}

export type RecommendSectionInput = {
  RecommendOption?: Maybe<RecommendOption>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  EffectiveUntilDate?: Maybe<Scalars['DateTime']>;
  CurrentSalary?: Maybe<Scalars['Float']>;
  ProposedIncrement?: Maybe<Scalars['Float']>;
  CurrentJobPosition?: Maybe<Scalars['String']>;
  NewJobPosition?: Maybe<Scalars['String']>;
  CurrentJobGrade?: Maybe<Scalars['String']>;
  NewJobGrade?: Maybe<Scalars['String']>;
};

export enum RecordSource {
  ExcelUpload = 'ExcelUpload',
  ManualByPayItem = 'ManualByPayItem',
  ManualByEmployee = 'ManualByEmployee',
  RecurringPay = 'RecurringPay',
  RecurringZakat = 'RecurringZakat',
  AdvancePolicy = 'AdvancePolicy'
}

export enum RecruitmentApplicationStage {
  New = 'New',
  Prescreen = 'Prescreen',
  Shortlist = 'Shortlist',
  Interview = 'Interview',
  Offer = 'Offer',
  Accept = 'Accept',
  NotSuitable = 'Not_Suitable'
}

export enum RecruitmentApplicationStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type RecruitmentDocumentEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentDocumentEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_document_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  document_type?: Maybe<DocumentType>;
  description?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
  bucket_file_name?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentDocumentInput = {
  rec_document_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  document_type?: Maybe<DocumentType>;
  description?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
  bucket_file_name?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentE_LetterEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentE_LetterEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_eletter_template_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  content: Scalars['String'];
  type: Scalars['String'];
  is_default: Scalars['Boolean'];
  rec_offer_checklist_id: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  rec_document_id?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
};

export type RecruitmentE_LetterInput = {
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['Boolean']>;
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  rec_document_id?: Maybe<Scalars['String']>;
  RecruitmentDocumentInput?: Maybe<DocumentsInput>;
};

export type RecruitmentExpensesEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentExpensesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_expense_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type RecruitmentExpensesInput = {
  name?: Maybe<Scalars['String']>;
  rec_expense_id?: Maybe<Scalars['String']>;
};

export enum RecruitmentFinalSelectionStatus {
  Pending = 'Pending',
  Offered = 'Offered',
  Declined = 'Declined'
}

export enum RecruitmentJobPostingStatus {
  Pending = 'Pending',
  Published = 'Published',
  Closed = 'Closed'
}

export enum RecruitmentJobVacancyStatus {
  Active = 'Active',
  Closed = 'Closed',
  Cancelled = 'Cancelled'
}

export enum RecruitmentOfferContentStatus {
  Draft = 'Draft',
  Completed = 'Completed'
}

export enum RecruitmentOfferProcessStatus {
  Incomplete = 'Incomplete',
  Ready = 'Ready',
  Finalized = 'Finalized',
  Completed = 'Completed'
}

export enum RecruitmentOfferResponseStatus {
  Accepted = 'Accepted',
  Declined = 'Declined'
}

export enum RecruitmentOfferStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum RecruitmentPolicyType {
  RecruitmentRequisition = 'Recruitment_Requisition',
  Offer = 'Offer',
  Onboard = 'Onboard'
}

export type RecruitmentPre_OfferChecklistEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentPre_offerChecklistEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_checklist_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  sequence: Scalars['Float'];
  in_use?: Maybe<Scalars['Boolean']>;
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
};

export type RecruitmentPre_OfferChecklistInput = {
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentSourceBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type RecruitmentSourceEntity = InterfaceBase & {
  __typename?: 'RecruitmentSourceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecruitmentSourceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeInfo?: Maybe<EmployeeEntity>;
};

export type RecruitmentSourceInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum RecruitmentTalentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Hired = 'Hired',
  Blacklisted = 'Blacklisted'
}

export enum RecruitmentType {
  New = 'New',
  Replacement = 'Replacement'
}

export type RecurringPayEntity = InterfaceBase & {
  __typename?: 'RecurringPayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringPayID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type RecurringPayInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OldRecurringPayID?: Maybe<Array<Scalars['String']>>;
};

export type RecurringRestOffDayEntity = InterfaceBase & {
  __typename?: 'RecurringRestOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringRestOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType: RestOffType;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  States?: Maybe<StatesEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type RecurringRestOffDayInput = {
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType?: Maybe<RestOffType>;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayIDs?: Maybe<Array<Scalars['String']>>;
};

export enum Relationship {
  Aunt = 'Aunt',
  Brother = 'Brother',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  Father = 'Father',
  Husband = 'Husband',
  Mother = 'Mother',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Sister = 'Sister',
  Son = 'Son',
  Uncle = 'Uncle',
  Wife = 'Wife',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Sikh = 'Sikh',
  Others = 'Others'
}

export type ReplacementLeaveClaimType = {
  __typename?: 'ReplacementLeaveClaimType';
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeList>>;
};

export type ReplacementLeaveClaimTypeInput = {
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeListInput>>;
};

export type ReplacementLeaveClaimTypeList = {
  __typename?: 'ReplacementLeaveClaimTypeList';
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReplacementLeaveClaimTypeListInput = {
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReportPayItemOutput = {
  __typename?: 'ReportPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  PayItemTypeName?: Maybe<Scalars['String']>;
};

export type ReportTemplateAssignmentEntity = InterfaceBase & {
  __typename?: 'ReportTemplateAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateAssignmentID: Scalars['String'];
  ReportTemplateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
};

export type ReportTemplateAssignmentInput = {
  ReportTemplateAssignmentID?: Maybe<Scalars['String']>;
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ReportTemplateEntity = InterfaceBase & {
  __typename?: 'ReportTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Documents?: Maybe<DocumentsEntity>;
};

export type ReportTemplateInput = {
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ReportTemplateType {
  Confirmation = 'Confirmation',
  ProbationExtension = 'Probation_Extension',
  Demotion = 'Demotion',
  Secondment = 'Secondment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer',
  Termination = 'Termination',
  Retirement = 'Retirement',
  ExitCompany = 'Exit_Company',
  OfferLetter = 'Offer_Letter',
  HealthScreening = 'Health_Screening',
  BankAccountOpening = 'Bank_Account_Opening'
}

export enum ReportType {
  EaForm = 'EAForm',
  Cp21 = 'CP21',
  Cp22A = 'CP22A',
  Cp22B = 'CP22B',
  Cp22 = 'CP22'
}

export type ReportYearOutput = {
  __typename?: 'ReportYearOutput';
  Year?: Maybe<Scalars['Float']>;
};

export type ReportingGroupEntity = NewInterfaceBase & {
  __typename?: 'ReportingGroupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_group_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  GroupInUse: Scalars['Boolean'];
  CompanyInUse: Scalars['Boolean'];
};

export type ReportingGroupInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export enum ReportingRecordType {
  Active = 'Active',
  Future = 'Future',
  Expired = 'Expired'
}

export type ReportingStructureEntity = InterfaceBase & {
  __typename?: 'ReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  FromJobPosition?: Maybe<JobDesignationEntity>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToJobPosition?: Maybe<JobDesignationEntity>;
  SubordinateID?: Maybe<Scalars['String']>;
  Subordinate?: Maybe<EmployeeEntity>;
  SuperiorID?: Maybe<Scalars['String']>;
  Superior?: Maybe<EmployeeEntity>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ReportingStructureGroupInput = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
  CompanyID: Scalars['String'];
  JobPositionReportingInput?: Maybe<Array<JobPositionReportingInput>>;
  EmployeeReportingInput?: Maybe<Array<EmployeeReportingInput>>;
};

export type ReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export type ReportingStructureOutput = {
  __typename?: 'ReportingStructureOutput';
  ReportingGroupID: Scalars['String'];
  ReportingGroup?: Maybe<ReportingGroupEntity>;
  ItemActionType: ItemActionType;
  Title: Scalars['String'];
  JobPositionReporting?: Maybe<Array<JobPositionReportingOutput>>;
  EmployeeReporting?: Maybe<Array<EmployeeReportingOutput>>;
};

export enum ReportingType {
  ByEmployee = 'By_Employee',
  ByJobPosition = 'By_JobPosition'
}

export enum RequestType {
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition',
  Overtime = 'Overtime',
  ChangeShift = 'Change_Shift',
  TimeOff = 'Time_Off',
  Request = 'Request',
  ProjectExpenseClaim = 'Project_Expense_Claim',
  DataChanges = 'Data_Changes',
  KpiSetting = 'KPI_Setting',
  KpiApproval = 'KPI_Approval',
  ReviewSetting = 'Review_Setting',
  ReviewApproval = 'Review_Approval',
  HelpdeskTicket = 'Helpdesk_Ticket',
  ProjectTimesheet = 'Project_Timesheet',
  TrainingRequest = 'Training_Request',
  TrainingSelfRegistration = 'Training_SelfRegistration',
  TrainingNomination = 'Training_Nomination',
  TrainingWithdrawal = 'Training_Withdrawal',
  BatchOvertime = 'Batch_Overtime',
  AttendanceCorrectionRequest = 'Attendance_Correction_Request',
  TrainingEvaluation = 'Training_Evaluation',
  RecruitmentRequisition = 'Recruitment_Requisition',
  RecruitmentOffer = 'Recruitment_Offer',
  RecruitmentOnboard = 'Recruitment_Onboard'
}

export type RequisitionAppOutput = {
  __typename?: 'RequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type RequisitionDetails = {
  __typename?: 'RequisitionDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TotalDays?: Maybe<Scalars['Float']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type RequisitionEntity = InterfaceBase & {
  __typename?: 'RequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  TravelRequestID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  MovementType?: Maybe<MovementType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SubordinateDetail?: Maybe<EmployeeEntity>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
  RequisitionHistory?: Maybe<Array<RequisitionEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type RequisitionInput = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  RequisitionType: RequisitionType;
  TravelRequestID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
};

export type RequisitionManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum RequisitionManagementDelegation {
  AssetRequisitionSubmissionApproval = 'Asset_Requisition_Submission_Approval',
  AssetRequisitionCancellationApproval = 'Asset_Requisition_Cancellation_Approval',
  CashRequisitionSubmissionApproval = 'Cash_Requisition_Submission_Approval',
  CashRequisitionCancellationApproval = 'Cash_Requisition_Cancellation_Approval',
  TravelRequisitionSubmissionApproval = 'Travel_Requisition_Submission_Approval',
  TravelRequisitionCancellationApproval = 'Travel_Requisition_Cancellation_Approval',
  OtherRequisitionSubmissionApproval = 'Other_Requisition_Submission_Approval',
  OtherRequisitionCancellationApproval = 'Other_Requisition_Cancellation_Approval',
  StaffMovementRequisitionSubmissionApproval = 'Staff_Movement_Requisition_Submission_Approval',
  StaffMovementRequisitionCancellationApproval = 'Staff_Movement_Requisition_Cancellation_Approval'
}

export type RequisitionOutput = {
  __typename?: 'RequisitionOutput';
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelRequestID?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobPositionName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  OrgUnitName?: Maybe<Scalars['String']>;
  ReqOrgUnitID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  EmpSubordinateName?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  RequestCompanyName?: Maybe<Scalars['String']>;
};

export type RequisitionTakenSummaryByTypeOutput = {
  __typename?: 'RequisitionTakenSummaryByTypeOutput';
  RequisitionType?: Maybe<RequisitionType>;
  TotalRequisitionCount?: Maybe<Scalars['Float']>;
  TotalRequisitionAmount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
};

export enum RequisitionType {
  AssetRequisition = 'Asset_Requisition',
  TravelRequest = 'Travel_Request',
  CashAdvance = 'Cash_Advance',
  Others = 'Others',
  StaffMovementRequest = 'Staff_Movement_Request'
}

export type RequisitionTypeEntity = InterfaceBase & {
  __typename?: 'RequisitionTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionTypeInput = {
  ID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionUpdateDocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
};

export type RequisitionUpdateInput = {
  RequisitionHeaderID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type ResBiyearly = {
  __typename?: 'ResBiyearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResLifetimely = {
  __typename?: 'ResLifetimely';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResMonthly = {
  __typename?: 'ResMonthly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResYearly = {
  __typename?: 'ResYearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum ResidentStatus {
  Resident = 'Resident',
  NonResident = 'Non_Resident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker',
  CSuite = 'C_Suite'
}

export type RestOffDayOutput = {
  __typename?: 'RestOffDayOutput';
  DayOfWeek?: Maybe<Scalars['Float']>;
  Label?: Maybe<Scalars['String']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Disabled?: Maybe<Scalars['Boolean']>;
};

export enum RestOffType {
  RestDay = 'Rest_Day',
  OffDay = 'Off_Day',
  PublicHoliday = 'Public_Holiday'
}

export type ReversePostedLcfeInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReversePostedYecfInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReviewConversationLogEntity = InterfaceBase & {
  __typename?: 'ReviewConversationLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  DocumentIDs?: Maybe<Scalars['JSON']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItem?: Maybe<EmployeeReviewSectionItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  CommentBy: Scalars['JSON'];
  Documents?: Maybe<Array<DocumentsEntity>>;
  VoiceDocument?: Maybe<DocumentsEntity>;
};

export type ReviewConversationLogInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  VoiceInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
};

export type ReviewExerciseEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type ReviewExerciseInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewInput>;
};

export type ReviewExerciseListingOutput = {
  __typename?: 'ReviewExerciseListingOutput';
  ReviewExerciseSetupID: Scalars['String'];
  ReviewExerciseName: Scalars['String'];
  ReviewExercise: Array<ReviewExerciseEntity>;
};

export type ReviewExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorReviewFormTemplateID?: Maybe<Scalars['String']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  ReviewGroupName: Scalars['String'];
  ReviewFormTemplateName: Scalars['JSON'];
};

export type ReviewExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorReviewFormTemplateID?: Maybe<Scalars['String']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export type ReviewExerciseSetupEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  GenerateForCurrentReporting?: Maybe<Scalars['Boolean']>;
  UseRecentKpi?: Maybe<Scalars['Boolean']>;
  ExcludeResigning?: Maybe<Scalars['Boolean']>;
  AdhocSetupDetails?: Maybe<Scalars['JSON']>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsEntity>;
  ReviewExercise: Array<ReviewExerciseEntity>;
  InUse: Scalars['Boolean'];
};

export type ReviewExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  GenerateForCurrentReporting: Scalars['Boolean'];
  UseRecentKpi: Scalars['Boolean'];
  ExcludeResigning: Scalars['Boolean'];
  AdhocSetupDetails?: Maybe<AdhocSetupDetails>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsInput>;
};

export type ReviewFormItemSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type ReviewFormSectionSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  Recommendation?: Maybe<RecommendSectionInput>;
  ReviewFormItem?: Maybe<Array<ReviewFormItemSubmitInput>>;
};

export type ReviewFormSubmitInput = {
  ID: Scalars['String'];
  ReviewDate: Scalars['DateTime'];
  Status: Status;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog: Array<ReviewProcessLog>;
  ReviewFormSection: Array<ReviewFormSectionSubmitInput>;
};

export type ReviewFormTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewFormTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  WeightageType: QuantitativeType;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID: Scalars['String'];
  ReviewType: ReviewFormTemplateType;
  ApplicableTo: Array<AppraiseeType>;
  Description: Scalars['String'];
  ReviewProcessPolicy?: Maybe<ApprovalPolicyEntity>;
  ReviewGradingTable: ReviewGradingTableEntity;
  ReviewSection: Array<ReviewSectionEntity>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewFormTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewType?: Maybe<ReviewFormTemplateType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewSections?: Maybe<Array<ReviewSectionInput>>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewFormTemplateType {
  Periodic = 'Periodic',
  Confirmation = 'Confirmation',
  Adhoc = 'Adhoc'
}

export type ReviewGradingTableEntity = InterfaceBase & {
  __typename?: 'ReviewGradingTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  GradingTable: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewGradingTableInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  GradingTable?: Maybe<Array<GradingTableInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewGroupEntity = InterfaceBase & {
  __typename?: 'ReviewGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  KpiExercises?: Maybe<Array<KpiExerciseDetailsEntity>>;
  InUse: Scalars['Boolean'];
};

export type ReviewGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemDetail = {
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  KpiItemWeightage?: Maybe<Scalars['Float']>;
  WeightageType: Scalars['String'];
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<Scalars['String']>;
  ratingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  TemplateID: Scalars['String'];
  ReviewItemID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  FormItemRatingSetup?: Maybe<FormItemRatingSetup>;
};

export type ReviewItemEntity = InterfaceBase & {
  __typename?: 'ReviewItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Code: Scalars['String'];
  Description: Scalars['String'];
  ItemRatingMethod: ItemRatingMethod;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo: Array<AppraiseeType>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<ItemRatingMethod>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemList = {
  __typename?: 'ReviewItemList';
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewItemListInput = {
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewPeerRegistrationEntity = InterfaceBase & {
  __typename?: 'ReviewPeerRegistrationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
  Employee: EmployeeEntity;
  PeerInfoListing: Array<EmployeeEntity>;
};

export type ReviewPeerRegistrationInput = {
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
};

export type ReviewPolicyDetail = {
  __typename?: 'ReviewPolicyDetail';
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyDetailInput = {
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ReviewPolicyDetails: Array<Scalars['JSON']>;
  ReviewGradingID: Scalars['String'];
  ReviewGrading: ReviewGradingTableEntity;
  InUse: Scalars['Boolean'];
};

export type ReviewPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewPolicyDetails?: Maybe<Array<ReviewPolicyDetailInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewProcessLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeRoleID?: Maybe<Scalars['String']>;
  EmployeeRole?: Maybe<Scalars['String']>;
  Status: WorkflowLogStatus;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
};

export type ReviewProcessPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewProcessPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ApprovalList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewProcessPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewSectionEntity = InterfaceBase & {
  __typename?: 'ReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  Title: Scalars['String'];
  Type: ReviewSectionType;
  Weightage: Scalars['Float'];
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  AccessControl: Array<Scalars['JSON']>;
  Sequence: Scalars['Float'];
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  ReviewTemplate?: Maybe<ReviewTemplateEntity>;
  ReviewFormTemplate: ReviewFormTemplateEntity;
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
};

export type ReviewSectionInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Type?: Maybe<ReviewSectionType>;
  Weightage?: Maybe<Scalars['Float']>;
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
  AccessControl?: Maybe<Array<AccessControlInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewSectionType {
  Kpi = 'Kpi',
  Review = 'Review',
  Recommendation = 'Recommendation'
}

export type ReviewTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  ApplicableTo: Array<AppraiseeType>;
  WeightageType: QuantitativeType;
  ReviewItemList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  ItemCount: Scalars['Float'];
};

export type ReviewTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewItemList?: Maybe<Array<ReviewItemListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RewardGroupEntity = InterfaceBase & {
  __typename?: 'RewardGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  InUse: Scalars['Boolean'];
};

export type RewardGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
  roleUserAssignments?: Maybe<Array<RoleUserEntAssignmentEntity>>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  role: RoleEntity;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingCriteria = {
  __typename?: 'RoundingCriteria';
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingCriteriaInput = {
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingMethodEntity = InterfaceBase & {
  __typename?: 'RoundingMethodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoundingMethodID: Scalars['String'];
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export type RoundingMethodInput = {
  RoundingMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export enum RoundingType {
  Time = 'TIME',
  Unit = 'UNIT'
}

export type SdTrainingCoursesEntity = InterfaceBase & {
  __typename?: 'SDTrainingCoursesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SDTrainingCoursesID: Scalars['String'];
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  StaffDevelopmentProgram?: Maybe<StaffDevelopmentProgramEntity>;
};

export type SdTrainingCoursesInput = {
  SDTrainingCoursesID?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SalaryType {
  Monthly = 'Monthly',
  Daily = 'Daily'
}

export type ScaleRatingDetailEntity = InterfaceBase & {
  __typename?: 'ScaleRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingDetailID: Scalars['String'];
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
};

export type ScaleRatingDetailInput = {
  ScaleRatingDetailID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScaleRatingHeaderEntity = InterfaceBase & {
  __typename?: 'ScaleRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  ScaleRatingDetails?: Maybe<Array<ScaleRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type ScaleRatingHeaderInput = {
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ScaleRatingDetailInput>>;
};

export type ScheduleTrainingEntity = InterfaceBase & {
  __typename?: 'ScheduleTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScheduledTrainingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Registered?: Maybe<Scalars['Boolean']>;
  Document?: Maybe<DocumentsEntity>;
  QRCodeDocument?: Maybe<DocumentsEntity>;
  GetTrainingCostItemTotal?: Maybe<Scalars['Int']>;
};

export type ScheduleTrainingInput = {
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingEmployeeEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingEmployeeEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  company_id: Scalars['String'];
  employee_id: Scalars['String'];
  participation_status?: Maybe<Participation_Status>;
  queue_number?: Maybe<Scalars['Float']>;
  total_hours?: Maybe<Scalars['Float']>;
  finalized_attendance?: Maybe<Scalars['Boolean']>;
  is_charged?: Maybe<Scalars['Boolean']>;
  withdraw_remarks?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  registration_method?: Maybe<Registration_Method>;
  registered_by?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainingAttendance?: Maybe<Array<NewTrainingAttendanceEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  TrainingHistory?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
};

export type ScheduledTrainingEmployeeEvaluationEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingEmployeeEvaluationEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  employee_id: Scalars['String'];
  scheduled_training_survey_id: Scalars['String'];
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  survey_answer?: Maybe<Array<Scalars['JSON']>>;
  process_status: Process_Status;
  process_status_log: Array<Scalars['JSON']>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  ScheduledTrainingSurvey?: Maybe<ScheduledTrainingSurveyEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  EmployeeEvaluationAnswer?: Maybe<Array<EmployeeEvaluationAnswerEntity>>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type ScheduledTrainingEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_event_name: Scalars['String'];
  training_course_library_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  training_provider_id: Scalars['String'];
  cost_method: Cost_Method;
  course_fee: Scalars['Float'];
  min_pax: Scalars['Float'];
  max_pax: Scalars['Float'];
  is_allow_registration: Scalars['Boolean'];
  open_registration_dt?: Maybe<Scalars['String']>;
  close_registration_dt?: Maybe<Scalars['String']>;
  remarks: Scalars['String'];
  event_status: Event_Status;
  event_status_log: Array<Scalars['JSON']>;
  email_template?: Maybe<Scalars['JSON']>;
  document_id: Scalars['String'];
  document?: Maybe<DocumentsEntity>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  TrainingCourseLibrary?: Maybe<TrainingCourseLibraryEntity>;
  TrainingProvider?: Maybe<TrainingProviderEntity>;
  ScheduledTrainingTimetable?: Maybe<Array<ScheduledTrainingTimetableEntity>>;
  ScheduledTrainingSurvey?: Maybe<Array<ScheduledTrainingSurveyEntity>>;
  ScheduledTrainingTarget?: Maybe<Array<ScheduledTrainingTargetEntity>>;
  EmployeeScheduledTraining?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  ScheduledTrainingExpense?: Maybe<Array<ScheduledTrainingExpenseEntity>>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  ScheduledTrainingVenue?: Maybe<Array<ScheduledTrainingVenueEntity>>;
};

export type ScheduledTrainingExpenseEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  training_expense_id: Scalars['String'];
  amount: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainingExpense?: Maybe<TrainingExpenseEntity>;
};

export type ScheduledTrainingExpenseInput = {
  scheduled_training_expense_id?: Maybe<Scalars['String']>;
  training_expense_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingInput = {
  scheduled_training_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  training_event_name?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  training_provider_id?: Maybe<Scalars['String']>;
  cost_method?: Maybe<Cost_Method>;
  course_fee?: Maybe<Scalars['Float']>;
  min_pax?: Maybe<Scalars['Float']>;
  max_pax?: Maybe<Scalars['Float']>;
  is_allow_registration?: Maybe<Scalars['Boolean']>;
  open_registration_dt?: Maybe<Scalars['String']>;
  close_registration_dt?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  event_status?: Maybe<Event_Status>;
  attachment?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  schedule_items?: Maybe<Array<ScheduledTrainingTimetableInput>>;
  target_group_items?: Maybe<Array<ScheduledTrainingTargetInput>>;
  survey_items?: Maybe<Array<ScheduledTrainingSurveyInput>>;
  AdminUserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AdminContactID?: Maybe<Scalars['String']>;
  AdminEmployeeID?: Maybe<Scalars['String']>;
  AdminEmployeeName?: Maybe<Scalars['String']>;
  AdminEmployeeNo?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingSurveyEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingSurveyEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  survey_type_id: Scalars['String'];
  survey_form_id: Scalars['String'];
  SurveyForm?: Maybe<SurveyFormEntity>;
  SurveyType?: Maybe<SurveyTypeEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
};

export type ScheduledTrainingSurveyInput = {
  scheduled_training_survey_id?: Maybe<Scalars['String']>;
  survey_type_id?: Maybe<Scalars['String']>;
  survey_form_id?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingTargetEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingTargetEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  target_group_id: Scalars['String'];
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
};

export type ScheduledTrainingTargetInput = {
  scheduled_training_target_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingTimetableEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingTimetableEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  date: Scalars['String'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
  venue: Scalars['String'];
  hours?: Maybe<Scalars['Float']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TotalAttended: Scalars['Float'];
};

export type ScheduledTrainingTimetableInput = {
  scheduled_training_timetable_id?: Maybe<Scalars['String']>;
  trainer_profile_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export type ScheduledTrainingVenueEntity = NewInterfaceBase & {
  __typename?: 'ScheduledTrainingVenueEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  venue_name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
};

export type ScheduledTrainingVenueInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  scheduled_training_venue_id?: Maybe<Scalars['String']>;
  venue_name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

export type SendLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OfferChecklistName?: Maybe<Array<Scalars['JSON']>>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export enum Sequence {
  FirstDeduction = 'FIRST_DEDUCTION',
  SecondDeduction = 'SECOND_DEDUCTION',
  ThirdDeduction = 'THIRD_DEDUCTION'
}

export enum SequenceOrderType {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum SessionType {
  Full = 'Full',
  FirstHalf = 'First_Half',
  SecondHalf = 'Second_Half'
}

export type ShiftDayEntity = InterfaceBase & {
  __typename?: 'ShiftDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  DayType: DayType;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay: CrossDayType;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak: Scalars['Boolean'];
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  IsPreOvertime: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime: Scalars['Boolean'];
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
};

export type ShiftDayInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay?: Maybe<CrossDayType>;
  DayType?: Maybe<DayType>;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak?: Maybe<Scalars['Boolean']>;
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  BreakTimeList?: Maybe<Array<BreakTimeListInput>>;
  IsPreOvertime?: Maybe<Scalars['Boolean']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime?: Maybe<Scalars['Boolean']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime?: Maybe<Scalars['Boolean']>;
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
};

export type ShiftEntity = InterfaceBase & {
  __typename?: 'ShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  DutyRosterDisplayColour: Scalars['String'];
  IsPreOvertimeMin: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertimeMin: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertimeMin: Scalars['Boolean'];
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  BatchOvertime?: Maybe<Array<OvertimeEntity>>;
  fromShiftList?: Maybe<Array<ChangeShiftEntity>>;
  toShiftList?: Maybe<Array<ChangeShiftEntity>>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
  shiftDayList?: Maybe<Array<ShiftDayEntity>>;
  shiftPatternDetailList?: Maybe<Array<ShiftPatternDetailEntity>>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupEmployeeAssignmentLogEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEmployeeAssignmentLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupEmployeeAssignmentLogID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupOBJ: ShiftGroupEntity;
  hasDutyRoster: Scalars['Boolean'];
};

export type ShiftGroupEmployeeAssignmentLogInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ShiftGroupEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  ShiftGroupShift: Array<ShiftGroupShiftEntity>;
  ShiftGroupEmployeeAssignmentLog?: Maybe<Array<ShiftGroupEmployeeAssignmentLogEntity>>;
  ShiftGroupPattern?: Maybe<Array<ShiftGroupPatternEntity>>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
  Shift?: Maybe<Array<ShiftEntity>>;
  ShiftCount: Scalars['Float'];
  Employee?: Maybe<Array<EmployeeEntity>>;
  EmployeeCount: Scalars['Float'];
};

export type ShiftGroupInput = {
  ShiftGroupID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupPatternEntity = InterfaceBase & {
  __typename?: 'ShiftGroupPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};

export type ShiftGroupPatternInput = {
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type ShiftGroupShiftEntity = InterfaceBase & {
  __typename?: 'ShiftGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  ShiftID: Scalars['String'];
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroup: ShiftGroupEntity;
};

export type ShiftGroupShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  DutyRosterDisplayColour: Scalars['String'];
  ShiftID?: Maybe<Scalars['String']>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftPatternDetail = {
  __typename?: 'ShiftPatternDetail';
  days?: Maybe<Scalars['String']>;
  ShiftDay?: Maybe<ShiftDayEntity>;
};

export type ShiftPatternDetailEntity = {
  __typename?: 'ShiftPatternDetailEntity';
  ShiftPatternDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  shiftList?: Maybe<Array<ShiftPatternEntity>>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  shiftType?: Maybe<Array<ShiftEntity>>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type ShiftPatternDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
};

export type ShiftPatternEntity = InterfaceBase & {
  __typename?: 'ShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ShiftPatternDetailEntity>>;
};

export type ShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
};

export type ShopeePayItemEntity = {
  __typename?: 'ShopeePayItemEntity';
  ShopeePayItemID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ShortNoticeType {
  CalendarDay = 'Calendar_Day',
  WorkingDay = 'Working_Day'
}

export type SkillSetDetailEntity = InterfaceBase & {
  __typename?: 'SkillSetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetDetailID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetID?: Maybe<Scalars['String']>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetDetailInput = {
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SkillSetEntity = InterfaceBase & {
  __typename?: 'SkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SkillSetDetails?: Maybe<Array<SkillSetDetailEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetInput = {
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  NationalityType?: Maybe<NationalityType>;
  SocsoChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type SocsoCalculationOutput = {
  __typename?: 'SocsoCalculationOutput';
  EmployeeID: Scalars['String'];
  SocsoAmount: Scalars['Float'];
  FirstCatCmpAmount?: Maybe<Scalars['Float']>;
  FirstCatEmpAmount?: Maybe<Scalars['Float']>;
  SecondCatCmpAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  SocsoRule?: Maybe<SocsoRule>;
  SocsoRequirement?: Maybe<Scalars['String']>;
};

export type SocsoPolicyEntity = InterfaceBase & {
  __typename?: 'SocsoPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  SocsoRule: Scalars['String'];
  Formula: Scalars['String'];
};

export type SocsoPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<Scalars['String']>;
  Age: Scalars['Float'];
  SocsoRule?: Maybe<SocsoRule>;
  Formula?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoRefEntity = InterfaceBase & {
  __typename?: 'SocsoRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoReflD: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
};

export type SocsoRefInput = {
  SocsoRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SocsoRule {
  FirstCategory = 'FirstCategory',
  SecondCategory = 'SecondCategory'
}

export enum SoftDelete {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION'
}

export type StaffDevelopmentProgramEntity = InterfaceBase & {
  __typename?: 'StaffDevelopmentProgramEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type StaffDevelopmentProgramInput = {
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffDueForRetirementOutput = {
  __typename?: 'StaffDueForRetirementOutput';
  Employee?: Maybe<EmployeeEntity>;
  RetirementDate?: Maybe<Scalars['DateTime']>;
  IsRetirementOverdue?: Maybe<Scalars['Boolean']>;
};

export type StaffMovementRequestEntity = InterfaceBase & {
  __typename?: 'StaffMovementRequestEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffMovementRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffPerformanceAnalysisSummaryEntity = InterfaceBase & {
  __typename?: 'StaffPerformanceAnalysisSummaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffPerformanceAnalysisSummaryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type StaffPerformanceAnalysisSummaryInput = {
  StaffPerformanceAnalysisSummaryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffPerformanceAnalysisSummaryOutput = {
  __typename?: 'StaffPerformanceAnalysisSummaryOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeSalary?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  FinalScore?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<EmployeeEntity>;
  RatingAdjustment?: Maybe<StaffPerformanceAnalysisSummaryEntity>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
};

export type StaffTimesheetDetailEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StaffTimesheetID?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Activity?: Maybe<Scalars['String']>;
  Other?: Maybe<Scalars['String']>;
  StaffTimesheet: StaffTimesheetEntity;
};

export type StaffTimesheetDetailInput = {
  Description?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Other?: Maybe<Scalars['String']>;
};

export type StaffTimesheetEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
  TsAccountCode: TsAccountCodeEntity;
  StaffTimesheetDetail: Array<StaffTimesheetDetailEntity>;
};

export type StaffTimesheetInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffTimesheetDetail?: Maybe<Array<StaffTimesheetDetailInput>>;
};

export type StatesEntity = InterfaceBase & {
  __typename?: 'StatesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatesID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CalendarOffDay?: Maybe<Array<CalendarOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Confirmed = 'Confirmed',
  Probation = 'Probation',
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Illness = 'Illness',
  Absconded = 'Absconded',
  Draft = 'Draft',
  SoftDelete = 'SoftDelete',
  Hidden = 'Hidden',
  InUse = 'InUse',
  NotInUse = 'NotInUse',
  Initiated = 'Initiated',
  Submitted = 'Submitted',
  Finalised = 'Finalised',
  Open = 'Open',
  Closed = 'Closed',
  Withdraw = 'Withdraw',
  Overdue = 'Overdue'
}

export type StatutoryAccount = {
  __typename?: 'StatutoryAccount';
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAccountInput = {
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAdjustmentEntity = InterfaceBase & {
  __typename?: 'StatutoryAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatutoryAdjustmentID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  EPFPaidEE: Scalars['String'];
  EPFVEPaidEE: Scalars['String'];
  SOCSOPaidEE: Scalars['String'];
  EISPaidEE: Scalars['String'];
  TAXPaidEE: Scalars['String'];
  EPFPaidER: Scalars['String'];
  EPFVEPaidER: Scalars['String'];
  SOCSOPaidER: Scalars['String'];
  EISPaidER: Scalars['String'];
  HRDFPaidER: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayrollCycle: PayrollCycleEntity;
  Employee: EmployeeEntity;
};

export type StatutoryAdjustmentInput = {
  PayrollHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  TotalDeduction?: Maybe<Scalars['String']>;
  EPFPaidEE?: Maybe<Scalars['String']>;
  EPFVEPaidEE?: Maybe<Scalars['String']>;
  SOCSOPaidEE?: Maybe<Scalars['String']>;
  EISPaidEE?: Maybe<Scalars['String']>;
  TAXPaidEE?: Maybe<Scalars['String']>;
  EPFPaidER?: Maybe<Scalars['String']>;
  EPFVEPaidER?: Maybe<Scalars['String']>;
  SOCSOPaidER?: Maybe<Scalars['String']>;
  EISPaidER?: Maybe<Scalars['String']>;
  HRDFPaidER?: Maybe<Scalars['String']>;
  TotalChargableTax?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type SubmitClaimRecordsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ExpenseAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimRecordsItems?: Maybe<Array<ClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
};

export type SubmitProjectClaimRecordsInput = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ProjectClaimRecordsItems?: Maybe<Array<ProjectExpenseClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
};

export type SubordinateKpiEmpOutput = InterfaceBase & {
  __typename?: 'SubordinateKpiEmpOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  ContractDueDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  KpiPendingActionCount: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotifications: NotificationEntity;
};


export type SubscriptionNewNotificationsArgs = {
  ToEmployeeID: Scalars['String'];
};

export type SuccessionPlanningEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type SuccessionPlanningInput = {
  SuccessionPlanningID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SuccessionPlanningPositionEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningPositionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type SuccessionPlanningPositionInput = {
  SuccessionPlanningPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvalEntity = InterfaceBase & {
  __typename?: 'SupervisorEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SupervisorEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type SupervisorEvalInput = {
  SupervisorEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvaluationListingOutput = {
  __typename?: 'SupervisorEvaluationListingOutput';
  ScheduleTraining?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type SurveyAnswerInput = {
  survey_item_id?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type SurveyFormDetailEntity = NewInterfaceBase & {
  __typename?: 'SurveyFormDetailEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  survey_form_id: Scalars['String'];
  section_name: Scalars['String'];
  header: Scalars['String'];
  course_evaluation?: Maybe<Scalars['Boolean']>;
  provider_evaluation?: Maybe<Scalars['Boolean']>;
  trainer_evaluation?: Maybe<Scalars['Boolean']>;
  survey_items: Array<Scalars['JSON']>;
  sequence: Scalars['Float'];
  survey_form?: Maybe<SurveyFormEntity>;
};

export type SurveyFormDetailInput = {
  survey_form_detail_id?: Maybe<Scalars['String']>;
  section_name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  course_evaluation?: Maybe<Scalars['Boolean']>;
  provider_evaluation?: Maybe<Scalars['Boolean']>;
  trainer_evaluation?: Maybe<Scalars['Boolean']>;
  survey_items?: Maybe<Array<Scalars['JSON']>>;
  sequence?: Maybe<Scalars['Float']>;
};

export type SurveyFormEntity = NewInterfaceBase & {
  __typename?: 'SurveyFormEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  purpose: Scalars['String'];
  valid_for: Valid_For;
  in_use?: Maybe<Scalars['Boolean']>;
  survey_section?: Maybe<Array<SurveyFormDetailEntity>>;
};

export type SurveyFormInput = {
  survey_form_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  valid_for?: Maybe<Valid_For>;
  survey_section?: Maybe<Array<SurveyFormDetailInput>>;
};

export type SurveyItemEntity = NewInterfaceBase & {
  __typename?: 'SurveyItemEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  display_label: Scalars['String'];
  code: Scalars['String'];
  type: Survey_Type;
  layout_format?: Maybe<Layout_Format>;
  require_remark: Scalars['Boolean'];
  remark_label?: Maybe<Scalars['String']>;
  possible_answer?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type SurveyItemInput = {
  survey_item_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display_label?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Survey_Type>;
  layout_format?: Maybe<Layout_Format>;
  require_remark?: Maybe<Scalars['Boolean']>;
  remark_label?: Maybe<Scalars['String']>;
  possible_answer?: Maybe<Array<PossibleAnswerInput>>;
};

export type SurveyTypeEntity = NewInterfaceBase & {
  __typename?: 'SurveyTypeEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  valid_for: Valid_For;
  description: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type SurveyTypeInput = {
  survey_type_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valid_for?: Maybe<Valid_For>;
  description?: Maybe<Scalars['String']>;
};

export type SystemAdminSummaryOutput = {
  __typename?: 'SystemAdminSummaryOutput';
  PublicHolidayCalendarLastPostedDate?: Maybe<Scalars['DateTime']>;
  TaxReliefLastPostedDate?: Maybe<Scalars['DateTime']>;
  EAPartFLastPostedDate?: Maybe<Scalars['DateTime']>;
  EPFPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  SocsoPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EISPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LatestMobileVersion?: Maybe<Scalars['String']>;
};

export type TaxzakatCalculationInput = {
  PayrollCycleIsZakat: Scalars['Boolean'];
  IsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  IsLastProcess: Scalars['Boolean'];
  CurrentMTD: Scalars['Float'];
  AccumulatedTAXPaid: Scalars['Float'];
  AccumulatedZAKATPaid: Scalars['Float'];
  AccumulatedTax: Scalars['Float'];
};

export type TmsotConditionEntity = InterfaceBase & {
  __typename?: 'TMSOTConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type TmsotConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TmsSummary = {
  __typename?: 'TMSSummary';
  ShiftGroup?: Maybe<Scalars['Float']>;
  AttendanceExclusion?: Maybe<Scalars['Float']>;
  EarnLeave?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  AttendanceRecord?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  Absent?: Maybe<Scalars['Float']>;
  IncompleteClocking?: Maybe<Scalars['Float']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  AttendanceProcessing?: Maybe<Scalars['DateTime']>;
  AttendancePosting?: Maybe<Scalars['DateTime']>;
  IncompleteClockingEmployees?: Maybe<Array<Scalars['JSON']>>;
  LateInEmployees?: Maybe<Array<Scalars['JSON']>>;
  AbsentEmployee?: Maybe<Array<Scalars['JSON']>>;
  TodayData?: Maybe<Array<Scalars['JSON']>>;
  AttendanceCorrectionRequest?: Maybe<Scalars['Float']>;
};

export type TmsTimeOffConditionEntity = InterfaceBase & {
  __typename?: 'TMSTimeOffConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type TmsTimeOffConditionInput = {
  TimeOffPolicyConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TalentAdditionalUserField = {
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TalentInvitationStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type TalentStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentTalentStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type TargetGroupCoursesEntity = NewInterfaceBase & {
  __typename?: 'TargetGroupCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  target_group_id: Scalars['String'];
  compulsary_course_items: Array<Scalars['JSON']>;
  TargetGroup?: Maybe<TargetGroupEntity>;
};

export type TargetGroupEntity = NewInterfaceBase & {
  __typename?: 'TargetGroupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  TargetGroupCourse?: Maybe<TargetGroupCoursesEntity>;
  TrainingTargetHoursItems?: Maybe<Array<TrainingTargetHoursItemsEntity>>;
  compulsary_course_count?: Maybe<Scalars['String']>;
  in_use: Scalars['Boolean'];
};

export type TargetGroupInput = {
  name?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
};

export type TargetGroupMatrixEntity = NewInterfaceBase & {
  __typename?: 'TargetGroupMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  description: Scalars['String'];
  target_group_id: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  TargetGroup?: Maybe<TargetGroupEntity>;
};

export type TargetGroupMatrixInput = {
  target_group_matrix_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
};

export enum TargetType {
  Company = 'COMPANY',
  Designation = 'DESIGNATION',
  Department = 'DEPARTMENT',
  JobGrade = 'JOB_GRADE',
  DailyAllowancePolicy = 'DAILY_ALLOWANCE_POLICY',
  MonthlyAllowancePolicy = 'MONTHLY_ALLOWANCE_POLICY',
  DeductionPolicy = 'DEDUCTION_POLICY',
  ShiftSetup = 'SHIFT_SETUP',
  ShiftPattern = 'SHIFT_PATTERN',
  ShiftGroup = 'SHIFT_GROUP',
  TimeOff = 'TIME_OFF',
  OvertimeRoundingPolicy = 'OVERTIME_ROUNDING_POLICY',
  AllowanceDeductionRoundingPolicy = 'ALLOWANCE_DEDUCTION_ROUNDING_POLICY',
  OvertimeReasonCodes = 'OVERTIME_REASON_CODES',
  TimeOffReasonCodes = 'TIME_OFF_REASON_CODES',
  MobileCheckInLocation = 'MOBILE_CHECK_IN_LOCATION',
  OvertimeClaimTable = 'OVERTIME_CLAIM_TABLE',
  OvertimePolicy = 'OVERTIME_POLICY',
  Employee = 'EMPLOYEE',
  AttendanceRecord = 'ATTENDANCE_RECORD',
  SkillSet = 'SKILL_SET',
  InsuranceScheme = 'INSURANCE_SCHEME',
  RecruitmentSource = 'RECRUITMENT_SOURCE',
  UserDefineField = 'USER_DEFINE_FIELD',
  OvertimePayitem = 'OVERTIME_PAYITEM',
  EarningPayitem = 'EARNING_PAYITEM',
  DeductionPayitem = 'DEDUCTION_PAYITEM',
  NonPayablePayitem = 'NON_PAYABLE_PAYITEM',
  PayrollCycle = 'PAYROLL_CYCLE',
  OvertimePolicyMatrix = 'OVERTIME_POLICY_MATRIX',
  OvertimeClaimMatrix = 'OVERTIME_CLAIM_MATRIX',
  BluetoothBeaconDevices = 'BLUETOOTH_BEACON_DEVICES'
}

export type TargetUnion = CompanyEntity | DepartmentEntity | JobDesignationEntity | JobGradeEntity | DailyAllowancePolicyEntity | MonthlyAllowancePolicyEntity | DeductionPolicyEntity | ShiftEntity | ShiftPatternEntity | ShiftGroupEntity | TimeOffPolicyEntity | OvertimeRoundingSettingEntity | AllowanceDeductionRoundingEntity | OvertimeReasonEntity | TimeOffReasonEntity | MobileCheckInEntity | OvertimeClaimTableEntity | OvertimePolicyEntity | EmployeeEntity | AttendanceRecordEntity | SkillSetEntity | InsuranceSchemeEntity | RecruitmentSourceEntity | UserDefineEntity;

export type TaxExemptionMapping = {
  __typename?: 'TaxExemptionMapping';
  ColumnID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TaxExemptionID?: Maybe<Scalars['String']>;
};

export type TaxExemptionMappingInput = {
  ColumnID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TaxExemptionID?: Maybe<Scalars['String']>;
};

export enum TaxMaritialStatus {
  SingleSpouseNotClaimedChildren = 'Single_Spouse_Not_Claimed_Children',
  MarriedAndSpouseNotWorking = 'Married_and_Spouse_Not_Working',
  MarriedAndSpouseWorking = 'Married_and_Spouse_Working',
  DivorceWidowWidower = 'Divorce_Widow_Widower'
}

export type TaxOutput = {
  __typename?: 'TaxOutput';
  FinalTax1: Scalars['Float'];
  FinalTax2: Scalars['Float'];
  NetMTD: Scalars['Float'];
  MTD_A: Scalars['Float'];
  MTD_B: Scalars['Float'];
  YearlyTax_CS: Scalars['Float'];
  MTD_C: Scalars['Float'];
  FinalZakat: Scalars['Float'];
  TaxZakat: Scalars['Float'];
  Tax1: Scalars['Float'];
  Tax2: Scalars['Float'];
};

export type TaxReisscosInput = {
  __typename?: 'TaxREISSCOSInput';
  currentEISSCOS?: Maybe<Scalars['Float']>;
  TaxReliefSocsoEISLimit?: Maybe<Scalars['Float']>;
  PreviousMonthTaxRelief?: Maybe<Array<EmployeeTaxReliefEntity>>;
  CurrentMonthReliefAmount?: Maybe<Scalars['Float']>;
};

export type TaxRefEntity = InterfaceBase & {
  __typename?: 'TaxRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReflD: Scalars['String'];
  TaxRefType: TaxRefType;
  PeriodYear: Scalars['Float'];
  PFromAmount: Scalars['Float'];
  PToAmount: Scalars['Float'];
  MAmount: Scalars['Float'];
  RTaxRate: Scalars['Float'];
  BIN3Amount: Scalars['Float'];
  B2Amount: Scalars['Float'];
  NonResidentRate: Scalars['Float'];
};

export enum TaxRefType {
  ResidentNonResident = 'ResidentNonResident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker'
}

export type TaxReliefEntity = InterfaceBase & {
  __typename?: 'TaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReliefID: Scalars['String'];
  ReliefCode: Scalars['String'];
  Description: Scalars['String'];
  PeriodYear: Scalars['Float'];
  MaxAmount: Scalars['Float'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type TaxReliefInput = {
  ReliefCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  MaxAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxZakatCalculationOutput = {
  __typename?: 'TaxZakatCalculationOutput';
  NetMTD: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  PayrollCycleIsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  IsZakat: Scalars['Boolean'];
  FinalTotalTAXPaid: Scalars['Float'];
  FinalTotalZAKATPaid: Scalars['Float'];
  FinalTotalMTD: Scalars['Float'];
};

export type TempAttendanceEntity = {
  __typename?: 'TempAttendanceEntity';
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  integration_clock_type?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempCareerMovementEntity = {
  __typename?: 'TempCareerMovementEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  MovementType: Scalars['String'];
  Sequence: Scalars['Float'];
  ToOrgUnit?: Maybe<Scalars['String']>;
  ToPosition: Scalars['String'];
  ToJobGrade: Scalars['String'];
  ToEmploymentType: Scalars['String'];
  StaffCategory: Scalars['String'];
  ExitReasonID?: Maybe<Scalars['String']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsCareerLog: Scalars['Boolean'];
  IsSalaryLog: Scalars['Boolean'];
  FromCompany?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  NewEmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentContactEntity = {
  __typename?: 'TempDependentContactEntity';
  TempDependentContactID: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  Nationality: Scalars['String'];
  BirthDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentEntity = {
  __typename?: 'TempDependentEntity';
  TempDependentID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  Relationship: Scalars['String'];
  IsEmergencyContact: Scalars['Boolean'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  IsWorking: Scalars['Boolean'];
  Education: Scalars['String'];
  ChildEligibleRate: Scalars['Float'];
  IsOKU: Scalars['Boolean'];
  PCBNo?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmployeeSalaryRecordsEntity = {
  __typename?: 'TempEmployeeSalaryRecordsEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromSalary: Scalars['String'];
  ToSalary: Scalars['String'];
  Reason: Scalars['String'];
  SalaryType: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  IsSalaryLog: Scalars['Boolean'];
  IsCareerLog: Scalars['Boolean'];
  MovementType?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmploymentInfoEntity = {
  __typename?: 'TempEmploymentInfoEntity';
  TempEmploymentInfoID: Scalars['String'];
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  DesignationID: Scalars['String'];
  JobGradeID: Scalars['String'];
  StaffCategoryID: Scalars['String'];
  RoleID: Scalars['String'];
  RoleName: Scalars['String'];
  EmployeeStatus: Status;
  Status: Status;
  EmploymentType: EmploymentType;
  JoinedDate: Scalars['DateTime'];
  JoinedReferenceDate: Scalars['DateTime'];
  Probation?: Maybe<Scalars['Float']>;
  ConfirmedDate: Scalars['DateTime'];
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyID: Scalars['String'];
  CalendarTitle: Scalars['String'];
  RecuritmentSourceID: Scalars['String'];
  IsOKU?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  RetirementAge: Scalars['Float'];
};

export type TempFixedAllowanceEntity = {
  __typename?: 'TempFixedAllowanceEntity';
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  pay_category?: Maybe<Scalars['String']>;
  pay_item_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  start_month?: Maybe<Scalars['DateTime']>;
  end_month?: Maybe<Scalars['DateTime']>;
  payroll_cycle_name?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempLeaveAdjustmentEntity = {
  __typename?: 'TempLeaveAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  PeriodYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempManualAdjustmentEntity = {
  __typename?: 'TempManualAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  FromDate: Scalars['DateTime'];
  UntilDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempMonthlyTransactionEntity = {
  __typename?: 'TempMonthlyTransactionEntity';
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  seq_no?: Maybe<Scalars['Float']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  pay_category?: Maybe<Scalars['String']>;
  pay_item_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['DateTime']>;
  payroll_cycle_name?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempPayrollHistoryEntity = {
  __typename?: 'TempPayrollHistoryEntity';
  Id: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type TempPersonnelProfileEntity = {
  __typename?: 'TempPersonnelProfileEntity';
  TempPersonnelProfileID: Scalars['String'];
  FullName: Scalars['String'];
  Salutation: Scalars['String'];
  NickName?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Gender: Scalars['String'];
  Marital: Scalars['String'];
  Race: Scalars['String'];
  Religion: Scalars['String'];
  PermanentAddress: Address2;
  SameAddress?: Maybe<Scalars['Boolean']>;
  Address?: Maybe<Address2>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempReportingStructureEntity = {
  __typename?: 'TempReportingStructureEntity';
  TempReportingStructureID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubordinateID: Scalars['String'];
  SuperiorID: Scalars['Float'];
  ReportingType: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate: Scalars['DateTime'];
};

export type TempSalaryEntity = {
  __typename?: 'TempSalaryEntity';
  id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempStatutoryInfoEntity = {
  __typename?: 'TempStatutoryInfoEntity';
  TempStatutoryInfoID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PaymentMethod: PaymentMethod;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  IsEPF: Scalars['Boolean'];
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['String']>;
  EPFVERateCmp?: Maybe<Scalars['String']>;
  IsSocso: Scalars['Boolean'];
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB: Scalars['Boolean'];
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS: Scalars['Boolean'];
  CompanyEISAccount?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsASB: Scalars['Boolean'];
  ASBNo?: Maybe<Scalars['String']>;
  IsAngkasa: Scalars['Boolean'];
  AngkasaNo?: Maybe<Scalars['String']>;
  IsKWAP: Scalars['Boolean'];
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH: Scalars['Boolean'];
  TBHNo?: Maybe<Scalars['String']>;
  IsHRDF: Scalars['Boolean'];
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempTp3Entity = {
  __typename?: 'TempTP3Entity';
  TempTP3ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  TaxExemptionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempUserAccessEntity = InterfaceBase & {
  __typename?: 'TempUserAccessEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyAccess: Scalars['JSON'];
  EmployeeIDs: Scalars['JSON'];
  SubordinateIDs: Scalars['JSON'];
};

export type TempZakatContributionEntity = {
  __typename?: 'TempZakatContributionEntity';
  TempZakatContributionID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  StartPeriodID: Scalars['String'];
  EndPeroidID?: Maybe<Scalars['String']>;
  ZakatStateID: Scalars['String'];
  AccountNo: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TemplateLibraryAssignmentEntity = InterfaceBase & {
  __typename?: 'TemplateLibraryAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Target?: Maybe<TargetUnion>;
};

export type TemplateLibraryAssignmentInfolineOutput = {
  __typename?: 'TemplateLibraryAssignmentInfolineOutput';
  ShiftAssignment?: Maybe<Scalars['DateTime']>;
  ShiftPatternAssignment?: Maybe<Scalars['DateTime']>;
  ShiftGroupAssignment?: Maybe<Scalars['DateTime']>;
  DailyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  MonthlyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  DeductionAssignment?: Maybe<Scalars['DateTime']>;
  TimeOffAssignment?: Maybe<Scalars['DateTime']>;
  OvertimeReason?: Maybe<Scalars['DateTime']>;
  TimeOffReason?: Maybe<Scalars['DateTime']>;
  MobileCheckInSite?: Maybe<Scalars['Float']>;
  MobileCheckInRadius?: Maybe<Scalars['Float']>;
  BluetoothBeaconDevices?: Maybe<Scalars['Float']>;
  PeriodicAllowanceType?: Maybe<Scalars['DateTime']>;
};

export type TemplateLibraryAssignmentInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type TemplateLibrarySummaryOutput = {
  __typename?: 'TemplateLibrarySummaryOutput';
  ShiftSetup?: Maybe<Scalars['Float']>;
  ShiftPattern?: Maybe<Scalars['Float']>;
  ShiftGroup?: Maybe<Scalars['Float']>;
  OvertimePolicy?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['Float']>;
  OvertimeReasonCode?: Maybe<Scalars['Float']>;
  OvertimeRoundingPolicy?: Maybe<Scalars['Float']>;
  DailyAllowancePolicy?: Maybe<Scalars['Float']>;
  MonthlyAllowancePolicy?: Maybe<Scalars['Float']>;
  PeriodicDeductionPolicy?: Maybe<Scalars['Float']>;
  DeudctionPolicy?: Maybe<Scalars['Float']>;
  DeductionRoundingPolicy?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  JobGrade?: Maybe<Scalars['Float']>;
  assignedJob?: Maybe<Scalars['Float']>;
  TimeOffReasonCode?: Maybe<Scalars['Float']>;
  MobileCheckInLocation?: Maybe<Scalars['Float']>;
  templateDate?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['JSON']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  OTRoundingPolicy?: Maybe<Scalars['String']>;
  AutomatedClockDataImport?: Maybe<Scalars['Float']>;
  PeriodicDate?: Maybe<Scalars['DateTime']>;
  AttendanceAnalysisTemplateDate?: Maybe<Scalars['DateTime']>;
};

export type TestingBusinessInsightCompanyOutput = {
  __typename?: 'TestingBusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDepartmentOutput>>;
};

export type TestingBusinessInsightDepartmentOutput = {
  __typename?: 'TestingBusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDivisionOutput>>;
};

export type TestingBusinessInsightDivisionOutput = {
  __typename?: 'TestingBusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TestingBusinessInsightGroupingOutput = {
  __typename?: 'TestingBusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTakenOutput = {
  __typename?: 'TestingBusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTypeOutput = {
  __typename?: 'TestingBusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightListingOutput = {
  __typename?: 'TestingBusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightOutput = {
  __typename?: 'TestingBusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollPayItemOutput = {
  __typename?: 'TestingBusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'TestingBusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollStatutoryOutput = {
  __typename?: 'TestingBusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightSummaryOutput = {
  __typename?: 'TestingBusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
};

export type TestingBusinessInsightSummaryPayrollOutput = {
  __typename?: 'TestingBusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<TestingBusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<TestingBusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<TestingBusinessInsightPayrollPayItemOutput>>;
};

export type TestingCareerLogEntity = InterfaceBase & {
  __typename?: 'TestingCareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
};

export type TestingCareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
};

export type TestingClaimAppDetailsOutput = {
  __typename?: 'TestingClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingClaimAppOutput = {
  __typename?: 'TestingClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<TestingClaimAppDetailsOutput>>;
};

export type TestingConditionObject = {
  __typename?: 'TestingConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type TestingContactEntity = InterfaceBase & {
  __typename?: 'TestingContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type TestingEmployeeConditionsDetails = {
  __typename?: 'TestingEmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<TestingConditionObject>;
  JobPosition?: Maybe<TestingConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<TestingConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<TestingConditionObject>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<TestingPolicyOutput>>;
};

export type TestingEmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Contact?: Maybe<TestingContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<TestingContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<TestingEmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  Subordinate?: Maybe<Array<TestingEmployeeEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<TestingEmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
};

export type TestingEmployeeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeListOutput = {
  __typename?: 'TestingEmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type TestingEmployeeListingOutput = {
  __typename?: 'TestingEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
};

export type TestingEmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'TestingEmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeStatutoryEntity = {
  __typename?: 'TestingEmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingGroupCountEmployeeRoleListingOutput = {
  __typename?: 'TestingGroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type TestingLastUpdatedDatesOutput = {
  __typename?: 'TestingLastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type TestingLeaveAppOutput = {
  __typename?: 'TestingLeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingLeaveHeaderEntity = InterfaceBase & {
  __typename?: 'TestingLeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<TestingEmployeeEntity>;
  LeaveHistory?: Maybe<Array<TestingLeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingMyApplicationOutput = {
  __typename?: 'TestingMyApplicationOutput';
  leave?: Maybe<TestingLeaveAppOutput>;
  claim?: Maybe<TestingClaimAppOutput>;
  requisition?: Maybe<TestingRequisitionAppOutput>;
};

export type TestingMySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export type TestingPersonnelEFileListingOutput = {
  __typename?: 'TestingPersonnelEFileListingOutput';
  Employee?: Maybe<TestingEmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type TestingPolicyOutput = {
  __typename?: 'TestingPolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export type TestingProbationExtendedEmployeeOutput = {
  __typename?: 'TestingProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<TestingCareerLogEntity>;
};

export type TestingReportingStructureEntity = InterfaceBase & {
  __typename?: 'TestingReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingRequisitionAppOutput = {
  __typename?: 'TestingRequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type TestingZakatContributionEntity = InterfaceBase & {
  __typename?: 'TestingZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TimeAttendance = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum TimeAttendanceDelegation {
  OvertimeSubmissionApproval = 'Overtime_Submission_Approval',
  OvertimeCancellationApproval = 'Overtime_Cancellation_Approval',
  TimeOffSubmissionApproval = 'Time_Off_Submission_Approval',
  TimeOffCancellationApproval = 'Time_Off_Cancellation_Approval',
  ChangeShiftRequestApproval = 'Change_Shift_Request_Approval'
}

export type TimeAttendanceTemplateEntity = InterfaceBase & {
  __typename?: 'TimeAttendanceTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TMSTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TMSConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type TimeAttendanceTemplateInput = {
  TMSTemplateID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TimeOffApConditionList = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
};

export type TimeOffEntity = InterfaceBase & {
  __typename?: 'TimeOffEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOffStatus: TimeOffStatus;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToReasonID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  WorkDate: Scalars['DateTime'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ToReason?: Maybe<TimeOffReasonEntity>;
  TimeOffHistory?: Maybe<Array<TimeOffEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type TimeOffInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  EmployeeID?: Maybe<Scalars['String']>;
  TimeOffID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  ToReasonID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TimeOffStatus?: Maybe<TimeOffStatus>;
  Approver?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveApprovalStatus?: Maybe<ApprovalStatus>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  DailyWorkingHours?: Maybe<Scalars['Float']>;
};

export type TimeOffPolicyEntity = InterfaceBase & {
  __typename?: 'TimeOffPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  RequireTimeOffReason: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type TimeOffPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
};

export type TimeOffReasonEntity = InterfaceBase & {
  __typename?: 'TimeOffReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffReasonInput = {
  TimeOffReasonID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffSettingEntity = InterfaceBase & {
  __typename?: 'TimeOffSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffSettingID: Scalars['String'];
  Name: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  TimeOffReasonID?: Maybe<Scalars['String']>;
  BackdatedTimeOff?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Array<Scalars['JSON']>>;
  Duration?: Maybe<Scalars['String']>;
  DailyWorkingHours?: Maybe<Scalars['String']>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  TimeOffReasonEntity?: Maybe<TimeOffReasonEntity>;
};

export type TimeOffSettingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffSettingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  Reasons?: Maybe<Scalars['String']>;
  ReasonIDs?: Maybe<Scalars['String']>;
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  BackdatedTimeOff?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Array<Scalars['JSON']>>;
  Duration?: Maybe<Scalars['String']>;
  DailyWorkingHours?: Maybe<Scalars['String']>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
};

export enum TimeOffStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type TimeSheetAgingDto = {
  __typename?: 'TimeSheetAgingDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccCodeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccCodeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Activity?: Maybe<Scalars['String']>;
  AccountCodeID?: Maybe<Scalars['String']>;
  AccountCodeName?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccTypeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccTypeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Account?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisDto = {
  __typename?: 'TimesheetMandayAnalysisDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type TmsPolicyMenuInfoLinesOutput = {
  __typename?: 'TmsPolicyMenuInfoLinesOutput';
  OvertimeDateCreate?: Maybe<Scalars['DateTime']>;
  OvertimeDateModified?: Maybe<Scalars['DateTime']>;
  DailyDateCreate?: Maybe<Scalars['DateTime']>;
  DailyDateModified?: Maybe<Scalars['DateTime']>;
  MonthlyDateCreate?: Maybe<Scalars['DateTime']>;
  MonthlyDateModified?: Maybe<Scalars['DateTime']>;
  DeductionDateCreate?: Maybe<Scalars['DateTime']>;
  DeductionDateModified?: Maybe<Scalars['DateTime']>;
};

export type ToDoKpiExerciseOutput = InterfaceBase & {
  __typename?: 'ToDoKpiExerciseOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
  EmployeeName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  SubmitDate?: Maybe<Scalars['DateTime']>;
  ApproverName: Scalars['String'];
  IsDelegation?: Maybe<Scalars['Boolean']>;
  ApprovalHistory: Array<KpiExerciseEntity>;
};

export type ToDoPerformanceOutput = {
  __typename?: 'ToDoPerformanceOutput';
  KpiExercise?: Maybe<Array<ToDoKpiExerciseOutput>>;
  EmployeeReview?: Maybe<Array<EmployeeReviewEntity>>;
};

export type TopClaimTakerOutput = {
  __typename?: 'TopClaimTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type TopRequisitionTakerOutput = {
  __typename?: 'TopRequisitionTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  RequisitionTaken?: Maybe<Scalars['Float']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TraineeEvalEntity = InterfaceBase & {
  __typename?: 'TraineeEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TraineeEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TraineeEvalInput = {
  TraineeEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvalEntity = InterfaceBase & {
  __typename?: 'TrainerEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainerEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainerEvalInput = {
  TrainerEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvaluationListingOutput = {
  __typename?: 'TrainerEvaluationListingOutput';
  Provider?: Maybe<AuthorisedTrainingProviderEntity>;
  TrainingCourse?: Maybe<Array<TrainingCourseOutput>>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainerProfileEntity = NewInterfaceBase & {
  __typename?: 'TrainerProfileEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  salutation: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  nationality?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  birthday_dt?: Maybe<Scalars['DateTime']>;
  address: Address2;
  overall_rating?: Maybe<Scalars['Float']>;
  document_id: Scalars['String'];
  document?: Maybe<DocumentsEntity>;
  TTProviders?: Maybe<Array<TrainerTrainingProvidersEntity>>;
  TTCourses?: Maybe<Array<TrainerTrainingCoursesEntity>>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type TrainerProfileInput = {
  trainer_name?: Maybe<Scalars['String']>;
  trainer_profile_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  nationality?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  birthday_dt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressInput2>;
  attachment?: Maybe<DocumentsInput>;
  training_providers?: Maybe<Array<TrainerTrainingProviderInput>>;
  training_courses?: Maybe<Array<TrainerTrainingCourseInput>>;
  trainer_training_courses_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingCourseInput = {
  trainer_profile_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  trainer_training_courses_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingCoursesEntity = NewInterfaceBase & {
  __typename?: 'TrainerTrainingCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  training_course_library_id: Scalars['String'];
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TrainingCourses?: Maybe<TrainingCourseLibraryEntity>;
};

export type TrainerTrainingProviderInput = {
  trainer_profile_id?: Maybe<Scalars['String']>;
  training_provider_id?: Maybe<Scalars['String']>;
  trainer_training_providers_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingProvidersEntity = NewInterfaceBase & {
  __typename?: 'TrainerTrainingProvidersEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  training_provider_id: Scalars['String'];
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TrainingProviders?: Maybe<TrainingProviderEntity>;
};

export type TrainingAttendanceEntity = InterfaceBase & {
  __typename?: 'TrainingAttendanceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingAttendanceID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type TrainingAttendanceInput = {
  TrainingAttendanceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingBudgetEntity = InterfaceBase & {
  __typename?: 'TrainingBudgetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingBudgetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TrainingBudgetInput = {
  TrainingBudgetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingBudgetItemsEntity = NewInterfaceBase & {
  __typename?: 'TrainingBudgetItemsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_budget_id: Scalars['String'];
  budget_group_id: Scalars['String'];
  amount: Scalars['Float'];
  BudgetGroup?: Maybe<BudgetGroupEntity>;
  TrainingBudget?: Maybe<NewTrainingBudgetEntity>;
};

export type TrainingCheckInInput = {
  scheduled_training_data?: Maybe<Scalars['JSON']>;
  employee_check_in_time?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
};

export type TrainingCostEntity = InterfaceBase & {
  __typename?: 'TrainingCostEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingCostID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TrainingCostItem?: Maybe<TrainingCostItemEntity>;
};

export type TrainingCostInput = {
  TrainingCostID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCostItemEntity = InterfaceBase & {
  __typename?: 'TrainingCostItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type TrainingCostItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseEntity = InterfaceBase & {
  __typename?: 'TrainingCourseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CourseClassificationID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CourseClassification?: Maybe<CourseClassificationEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainingCourseInput = {
  ID?: Maybe<Scalars['String']>;
  CourseClassificationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseLibraryEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingCourseLibraryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  course_category_id: Scalars['String'];
  course_title: Scalars['String'];
  description: Scalars['String'];
  duration_type: Duration_Type;
  duration: Scalars['Float'];
  document?: Maybe<DocumentsEntity>;
  document_id?: Maybe<Scalars['String']>;
  hrdc_claimable?: Maybe<Scalars['Boolean']>;
  require_recertification?: Maybe<Scalars['Boolean']>;
  recertification_frequency_type?: Maybe<Recertification_Frequency_Type>;
  recertification_frequency?: Maybe<Scalars['Float']>;
  internal?: Maybe<Scalars['Boolean']>;
  local?: Maybe<Scalars['Boolean']>;
  min_pax: Scalars['Float'];
  max_pax: Scalars['Float'];
  estimated_cost: Scalars['Float'];
  cost_method: Cost_Method;
  code?: Maybe<Scalars['String']>;
  CourseCategory?: Maybe<CourseCategoryEntity>;
  TTCourses?: Maybe<TrainerTrainingCoursesEntity>;
  ScheduledTraining?: Maybe<Array<ScheduledTrainingEntity>>;
  in_use: Scalars['Boolean'];
};

export type TrainingCourseLibraryInput = {
  training_course_library_id?: Maybe<Scalars['String']>;
  course_category_id?: Maybe<Scalars['String']>;
  course_title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration_type?: Maybe<Duration_Type>;
  duration?: Maybe<Scalars['Float']>;
  attachment?: Maybe<DocumentsInput>;
  hrdc_claimable?: Maybe<Scalars['Boolean']>;
  require_recertification?: Maybe<Scalars['Boolean']>;
  recertification_frequency_type?: Maybe<Recertification_Frequency_Type>;
  recertification_frequency?: Maybe<Scalars['Float']>;
  internal?: Maybe<Scalars['Boolean']>;
  local?: Maybe<Scalars['Boolean']>;
  min_pax?: Maybe<Scalars['Float']>;
  max_pax?: Maybe<Scalars['Float']>;
  estimated_cost?: Maybe<Scalars['Float']>;
  cost_method?: Maybe<Cost_Method>;
  code?: Maybe<Scalars['String']>;
};

export type TrainingCourseOutput = {
  __typename?: 'TrainingCourseOutput';
  Course?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainingExpenseEntity = NewInterfaceBase & {
  __typename?: 'TrainingExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
};

export type TrainingExpenseInput = {
  name?: Maybe<Scalars['String']>;
  training_expense_id?: Maybe<Scalars['String']>;
};

export type TrainingProviderCoursesEntity = NewInterfaceBase & {
  __typename?: 'TrainingProviderCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_provider_id: Scalars['String'];
  training_course_library_id: Scalars['String'];
  provider_cost: Scalars['Float'];
  cost_method: Cost_Method;
  hrdc_title: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  TrainingProvider?: Maybe<TrainingProviderEntity>;
};

export type TrainingProviderCoursesInput = {
  training_provider_courses_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  provider_cost?: Maybe<Scalars['Float']>;
  cost_method?: Maybe<Cost_Method>;
  hrdc_title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  courses_provided?: Maybe<Array<TrainingProviderCoursesInput>>;
};

export type TrainingProviderEntity = NewInterfaceBase & {
  __typename?: 'TrainingProviderEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  document_id?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentsEntity>;
  training_provider_name: Scalars['String'];
  training_provider_company_id: Scalars['String'];
  provider_type: Provider_Type;
  contact_person: Scalars['String'];
  mobile_no: Scalars['String'];
  web_url: Scalars['String'];
  hrdc_registered: Scalars['Boolean'];
  address: Address2;
  description: Scalars['String'];
  overall_rating?: Maybe<Scalars['Float']>;
  TTProviders?: Maybe<TrainerTrainingProvidersEntity>;
  TrainingProviderCourses?: Maybe<Array<TrainingProviderCoursesEntity>>;
  ScheduledTraining?: Maybe<Array<ScheduledTrainingEntity>>;
};

export type TrainingProviderInput = {
  training_provider_id?: Maybe<Scalars['String']>;
  attachment?: Maybe<DocumentsInput>;
  training_provider_name?: Maybe<Scalars['String']>;
  training_provider_company_id?: Maybe<Scalars['String']>;
  provider_type?: Maybe<Provider_Type>;
  contact_person?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  web_url?: Maybe<Scalars['String']>;
  hrdc_registered?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput2>;
  description?: Maybe<Scalars['String']>;
  courses_provided?: Maybe<Array<TrainingProviderCoursesInput>>;
};

export type TrainingRequestInput = {
  employee_training_request_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  attachment_input?: Maybe<Array<DocumentsInput>>;
  voice_input?: Maybe<DocumentsInput>;
  training_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  training_provider_name?: Maybe<Scalars['String']>;
  contact_info?: Maybe<Scalars['String']>;
  training_dt?: Maybe<Scalars['DateTime']>;
  estimated_course_fee?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
  justification?: Maybe<Scalars['String']>;
  web_url?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approval_status?: Maybe<Request_Approval_Status>;
  comment?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
};

export type TrainingScheduleTableInput = {
  Date?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Hour?: Maybe<Scalars['Float']>;
};

export type TrainingTargetHoursEntity = NewInterfaceBase & {
  __typename?: 'TrainingTargetHoursEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  TrainingTargetHoursItems?: Maybe<Array<TrainingTargetHoursItemsEntity>>;
};

export type TrainingTargetHoursItemsEntity = NewInterfaceBase & {
  __typename?: 'TrainingTargetHoursItemsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_target_hours_id: Scalars['String'];
  target_group_id: Scalars['String'];
  hours: Scalars['Float'];
  TargetGroup?: Maybe<TargetGroupEntity>;
  TrainingTargetHours?: Maybe<TrainingTargetHoursEntity>;
};

export type TrainingToDoOutput = {
  __typename?: 'TrainingToDoOutput';
  ScheduledTrainingEmployee?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeTrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
};

export enum Training_Evaluation {
  CourseEvaluation = 'Course_Evaluation',
  ProviderEvaluation = 'Provider_Evaluation',
  TrainerEvaluation = 'Trainer_Evaluation'
}

export type TraningModuleLatestUpdateOutput = {
  __typename?: 'TraningModuleLatestUpdateOutput';
  CostBudget?: Maybe<Scalars['DateTime']>;
  ScheduledTrainig?: Maybe<Scalars['DateTime']>;
  ScheduledTrainingCount?: Maybe<Scalars['Float']>;
  TraineeEval?: Maybe<Scalars['DateTime']>;
  CourseEval?: Maybe<Scalars['DateTime']>;
  TrainerEval?: Maybe<Scalars['DateTime']>;
  SupervisorEval?: Maybe<Scalars['DateTime']>;
  TrainingCost?: Maybe<Scalars['DateTime']>;
  CostAllocation?: Maybe<Scalars['DateTime']>;
  HRDFClaim?: Maybe<Scalars['DateTime']>;
};

export enum TransportMode {
  Flight = 'Flight',
  Car = 'Car',
  Bus = 'Bus',
  Train = 'Train',
  Motorcycle = 'Motorcycle'
}

export type TrxIDs = {
  ID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  Type?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
};

export type TsAccountCodeEntity = InterfaceBase & {
  __typename?: 'TsAccountCodeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
};

export type TsAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TsAccountTypeEntity = InterfaceBase & {
  __typename?: 'TsAccountTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ActivityList: Array<ActivityListJson>;
  TsAccountCode: Array<TsAccountCodeEntity>;
};

export type TsAccountTypeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ActivityList?: Maybe<Array<ActivityListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserDashboardControlEntity = InterfaceBase & {
  __typename?: 'UserDashboardControlEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Scalars['JSON'];
};

export type UserDashboardControlInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Array<DashboardCardSetting>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type UserDefineBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type UserDefineDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineDetailID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  UserDefine?: Maybe<UserDefineEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type UserDefineDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineDetailID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type UserDefineEpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetailID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyDetailInput = {
  UserDefineEPFPolicyDetaiID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID: Scalars['String'];
  Title: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetail?: Maybe<Array<UserDefineEpfPolicyDetailEntity>>;
  UserDefineEPFPolicyMatrix?: Maybe<Array<UserDefineEpfPolicyMatrixEntity>>;
};

export type UserDefineEpfPolicyInput = {
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyMatrixEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyMatrixID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyMatrixInput = {
  UserDefineEPFPolicyMatrixID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEntity = InterfaceBase & {
  __typename?: 'UserDefineEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  employeeID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  lastAccessDate?: Maybe<Scalars['DateTime']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
  NavToken?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  StaffID: Scalars['String'];
  UserName: Scalars['String'];
  StaffName: Scalars['String'];
  Dept: Scalars['String'];
  CostCenter: Scalars['String'];
  EmailID: Scalars['String'];
  Status: Scalars['String'];
  Shop: Scalars['String'];
  StaffCode: Scalars['String'];
  StaffType: Scalars['String'];
  UserRank: Scalars['String'];
  UserJoindDate: Scalars['DateTime'];
  UserStatus: Scalars['String'];
  TranStatus: Scalars['String'];
  LastUpdatedDate: Scalars['DateTime'];
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type UserLogInput = {
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserLogs = {
  __typename?: 'UserLogs';
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
  roleID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type VaccinationInput = {
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type VaccinationListItemOutput = {
  __typename?: 'VaccinationListItemOutput';
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsEntity>;
};

export type VaccinationRecordInput = {
  DocumentID?: Maybe<Scalars['String']>;
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsInput>;
};

export type ValidateResult = {
  __typename?: 'ValidateResult';
  subscriptionaccountid?: Maybe<Scalars['String']>;
  datamodule?: Maybe<Scalars['String']>;
  datarow?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rowno?: Maybe<Scalars['String']>;
  totalrecord?: Maybe<Scalars['String']>;
  exceltotalrecord?: Maybe<Scalars['String']>;
};

export type VerfiyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type VerfiyPeClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ProjectTypeID?: Maybe<Scalars['String']>;
};

export type VerifiedClaimDetail = {
  __typename?: 'VerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type VerifyApprovedClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyApprovedProjectExpenseClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyCompany = {
  __typename?: 'VerifyCompany';
  Company?: Maybe<Array<ValidateResult>>;
  Department?: Maybe<Array<ValidateResult>>;
  JobGrade?: Maybe<Array<ValidateResult>>;
  RecruitmentSource?: Maybe<Array<ValidateResult>>;
};

export type VerifyEmployee = {
  __typename?: 'VerifyEmployee';
  Employee?: Maybe<Array<ValidateResult>>;
  JobInfo?: Maybe<Array<ValidateResult>>;
  Statutory?: Maybe<Array<ValidateResult>>;
  Dependent?: Maybe<Array<ValidateResult>>;
};

export type VerifyPayroll = {
  __typename?: 'VerifyPayroll';
  CutOff?: Maybe<Array<ValidateResult>>;
  Cycle?: Maybe<Array<ValidateResult>>;
  Bank?: Maybe<Array<ValidateResult>>;
  PayItem?: Maybe<Array<ValidateResult>>;
  EmpSalary?: Maybe<Array<ValidateResult>>;
  FixedAllowDec?: Maybe<Array<ValidateResult>>;
  History?: Maybe<Array<ValidateResult>>;
};

export type ViewPayrollHistoryDetailListingOutput = {
  __typename?: 'ViewPayrollHistoryDetailListingOutput';
  Description?: Maybe<Scalars['String']>;
  PayrollCycleId?: Maybe<Scalars['String']>;
  TotalEmployee?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  AdditionalEarnings?: Maybe<Scalars['Float']>;
  GrossPay?: Maybe<Scalars['Float']>;
  Deduction?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
};

export type ViewPayrollHistoryInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export type ViewPayrollHistoryInputByMonth = {
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
  EmployeeStatus: Array<Scalars['String']>;
  EmployeeType: Array<Scalars['String']>;
  OrgUnitID: Array<Scalars['String']>;
  StaffCategoryID: Array<Scalars['String']>;
  PayrollCycleIDs: Array<Scalars['String']>;
  PayGroupIDs: Array<Scalars['String']>;
};

export type ViewPayrollHistorySummaryOutput = {
  __typename?: 'ViewPayrollHistorySummaryOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TotalPayrollCycle?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<ViewPayrollHistoryDetailListingOutput>>;
};

export type WorkFlowApprovedStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowApprovedStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: WorkFlowStepEntity;
  ApproverName?: Maybe<Scalars['String']>;
};

export type WorkFlowDetail = {
  __typename?: 'WorkFlowDetail';
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowEntity = InterfaceBase & {
  __typename?: 'WorkFlowEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  WorkFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApproverPendingList?: Maybe<Array<Scalars['JSON']>>;
  GroupWorkFlowStep?: Maybe<Scalars['JSON']>;
};

export type WorkFlowInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: WorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<WorkFlowApprovedStepEntity>>;
  RoleName?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  ApproverID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
};

export type WorkGroupShiftEntity = InterfaceBase & {
  __typename?: 'WorkGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkGroupShiftID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
};

export type WorkdayAdjustmentEntity = InterfaceBase & {
  __typename?: 'WorkdayAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkdayAdjustmentID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays: Scalars['Float'];
  AdjustedWorkDays: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type WorkdayAdjustmentInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays?: Maybe<Scalars['Float']>;
  AdjustedWorkDays?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum WorkflowLogStatus {
  Initiated = 'Initiated',
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  SendBack = 'Send_Back',
  ReInitiated = 'ReInitiated',
  RevertDraft = 'RevertDraft',
  Rejected = 'Rejected'
}

export type YecfListingOutput = {
  __typename?: 'YECFListingOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  NoOfPendingApprovalApplication?: Maybe<Scalars['Float']>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  Employee?: Maybe<EmployeeEntity>;
  InUse?: Maybe<Scalars['Boolean']>;
};

export type YearEndCarryForwardOutput = {
  __typename?: 'YearEndCarryForwardOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypes?: Maybe<Array<LeaveIndentity>>;
};

export type YearlyError = {
  __typename?: 'YearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type YearlyHolidaysGroup = {
  __typename?: 'YearlyHolidaysGroup';
  PeriodYear?: Maybe<Scalars['Float']>;
  RestDay?: Maybe<Array<RestOffDayOutput>>;
  OffDay?: Maybe<Array<RestOffDayOutput>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  CompanyOffDay?: Maybe<Array<CompanyOffDayEntity>>;
};

export type ZakatContributionEntity = InterfaceBase & {
  __typename?: 'ZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ZakatContributionInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['String']>;
  EndMonth?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ActionStatus = {
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type Approval_Log = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status: Request_Approval_Status;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export enum Approval_Status {
  Initial = 'Initial',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Disbursed = 'Disbursed',
  Completed = 'Completed'
}

export type ArrMesg = {
  __typename?: 'arrMesg';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  JoinedDateError?: Maybe<Array<JoinedDateError>>;
  ClaimTrxError?: Maybe<Array<ClaimTrxError>>;
  BiYearlyError?: Maybe<Array<BiYearlyError>>;
  YearlyError?: Maybe<Array<YearlyError>>;
  MonthlyError?: Maybe<Array<MonthlyError>>;
  LifeTimeError?: Maybe<Array<LifeTimeError>>;
};

export enum Cost_Method {
  PerPax = 'Per_Pax',
  LumpSum = 'Lump_Sum'
}

export type DropDownItem = {
  __typename?: 'dropDownItem';
  Value?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export enum Duration_Type {
  Hour = 'Hour',
  Day = 'Day'
}

export enum Event_Status {
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Ongoing = 'Ongoing',
  Confirmed = 'Confirmed',
  Closed = 'Closed',
  Open = 'Open',
  Planned = 'Planned'
}

export enum Layout_Format {
  Dropdown = 'Dropdown',
  List = 'List'
}

export enum Participation_Status {
  Submitted = 'Submitted',
  Confirmed = 'Confirmed',
  Attended = 'Attended',
  Withdraw = 'Withdraw',
  Withdrawn = 'Withdrawn',
  Rejected = 'Rejected'
}

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmedPassword?: Maybe<Scalars['String']>;
};

export type Process_LeaveDetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveDetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveDetailsID: Scalars['String'];
  ProcessLeaveHeaderID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProcessLeaveHeader?: Maybe<Process_LeaveHeader_ImportEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type Process_LeaveHeader_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveHeader_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  BlockLeaveID?: Maybe<Scalars['String']>;
  BlockLeave?: Maybe<BlockLeaveEntity>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type Process_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Process_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Status>;
  Index?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Process_Multicurrencydetails_ImportEntity>>;
};

export type Process_Overtime_ImportEntity = {
  __typename?: 'process_overtime_importEntity';
  ProcessOvertimeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Duration?: Maybe<Scalars['Float']>;
  HasDutyRoster?: Maybe<Scalars['Boolean']>;
  OvertimeReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export enum Process_Status {
  Draft = 'Draft',
  Initiated = 'Initiated',
  Finalised = 'Finalised'
}

export type Process_Status_Log = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status: Process_Status;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export enum Provider_Type {
  Internal = 'Internal',
  External = 'External'
}

export enum Recertification_Frequency_Type {
  Day = 'Day',
  Months = 'Months',
  Year = 'Year'
}

export enum Registration_Method {
  TrainingPlan = 'training_plan',
  Nominated = 'nominated',
  RegisteredByHr = 'registered_by_hr'
}

export enum Request_Approval_Status {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Withdraw = 'Withdraw',
  Cancelled = 'Cancelled'
}

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export enum Survey_Type {
  ObjectiveTest = 'Objective_Test',
  ObjectiveEvaluation = 'Objective_Evaluation',
  ObjectiveCount = 'Objective_Count',
  Subjective = 'Subjective',
  YesOrNo = 'Yes_Or_No',
  Text = 'Text'
}

export type Temp_Leave_TakenEntity = InterfaceBase & {
  __typename?: 'temp_leave_takenEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  leave_type?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  session?: Maybe<Scalars['String']>;
  total_leave?: Maybe<Scalars['Float']>;
  leave_reason?: Maybe<Scalars['String']>;
  disease_code?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  is_short_period_notice?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Float']>;
};

export type Temp_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  details_id: Scalars['String'];
  subscriptionaccountID?: Maybe<Scalars['String']>;
  setup_id?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Temp_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  currencyid: Scalars['String'];
  subscriptionaccountid?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  currency_name?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  soft_delete?: Maybe<Status>;
  index?: Maybe<Scalars['Float']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Temp_Multicurrencydetails_ImportEntity>>;
};

export type Temp_OvertimeEntity = {
  __typename?: 'temp_overtimeEntity';
  id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  overtime_reason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export enum Training_Policy_Type {
  RequestPolicy = 'Request_Policy',
  NominationPolicy = 'Nomination_Policy',
  SelfRegistrationPolicy = 'Self_Registration_Policy',
  WithdrawalPolicy = 'Withdrawal_Policy'
}

export type UpdateHelpdeskAssigneeInput = {
  ID?: Maybe<Scalars['String']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export enum Valid_For {
  Evaluation = 'Evaluation',
  Survey = 'Survey',
  Test = 'Test'
}

export type EmployeeProfileInsertUpdateMutationVariables = Exact<{
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type EmployeeProfileInsertUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfileInsertUpdate'>
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'superUser' | 'lastestAccessedEntity' | 'roleID' | 'isEmployee' | 'isEmployeeAuth' | 'employeeID' | 'helpdeskAssignee'>
  ) }
);

export type IsSuperUserEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type IsSuperUserEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsSuperUserEmployee'>
);

export type LoginSsoQueryVariables = Exact<{
  token: Scalars['String'];
  deviceToken?: Maybe<Scalars['String']>;
}>;


export type LoginSsoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loginSSO'>
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
  softwareCode: SoftwareCode;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'isEmployeeSuperUser'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'accountID' | 'ID' | 'name' | 'superUser' | 'employeeID'>
    ) }
  ) }
);

export type EmployeeAppLoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type EmployeeAppLoginMutation = (
  { __typename?: 'Mutation' }
  & { employeeAppLogin: (
    { __typename?: 'EmployeeAppLoginResponse' }
    & Pick<EmployeeAppLoginResponse, 'accessToken'>
    & { employee?: Maybe<(
      { __typename?: 'EmployeeResponse' }
      & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'name' | 'email' | 'mobileNo'>
    )> }
  ) }
);

export type LoggedInEmployeeProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInEmployeeProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInEmployeeProfile: (
    { __typename?: 'EmployeeResponse' }
    & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'companyID' | 'name' | 'email' | 'mobileNo' | 'LastestAccessedEntity' | 'SuperUserID' | 'Salutation' | 'contactID'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ChangeEmployeePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangeEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeEmployeePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ForgotEmployeePasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotEmployeePassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResetEmployeePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetEmployeePassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type EmployeeLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type EmployeeLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'employeeLogout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type UserProfilePicQueryVariables = Exact<{
  UserID: Scalars['String'];
  IsEmployee?: Maybe<Scalars['Boolean']>;
}>;


export type UserProfilePicQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserProfilePic'>
);

export type CheckOnPremServerQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckOnPremServerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckOnPremServer'>
);

export type RecruitmentExpensesListingQueryVariables = Exact<{ [key: string]: never; }>;


export type RecruitmentExpensesListingQuery = (
  { __typename?: 'Query' }
  & { RecruitmentExpensesListing?: Maybe<Array<(
    { __typename?: 'RecruitmentExpensesEntity' }
    & Pick<RecruitmentExpensesEntity, 'rec_expense_id' | 'name' | 'in_use'>
  )>> }
);

export type RecruitmentExpensesInsertMutationVariables = Exact<{
  input: Array<RecruitmentExpensesInput> | RecruitmentExpensesInput;
}>;


export type RecruitmentExpensesInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecruitmentExpensesInsert'>
);

export type RecruitmentExpensesDeleteMutationVariables = Exact<{
  recruitment_expense_ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type RecruitmentExpensesDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecruitmentExpensesDelete'>
);

export type RecruitmentApprovalPolicySummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type RecruitmentApprovalPolicySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RecruitmentApprovalPolicySummary'>
);

export type RecruitmentApprovalPolicyListingQueryVariables = Exact<{
  type: RecruitmentPolicyType;
  keyword?: Maybe<Scalars['String']>;
}>;


export type RecruitmentApprovalPolicyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RecruitmentApprovalPolicyListing'>
);

export type RecruitmentApprovalPolicyEmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RecruitmentApprovalPolicyEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'SubscriptionUserID' | 'Status'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type Rec_EApplicationFormSetupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type Rec_EApplicationFormSetupListingQuery = (
  { __typename?: 'Query' }
  & { Rec_EApplicationFormSetupListing: Array<(
    { __typename?: 'Rec_EApplicationFormSetupEntity' }
    & Pick<Rec_EApplicationFormSetupEntity, 'rec_eapplication_form_setup_id' | 'section_name' | 'sequence' | 'is_show' | 'is_required' | 'content' | 'min_record' | 'status'>
  )> }
);

export type Rec_EApplicationFormSetupInsertMutationVariables = Exact<{
  input: Array<Rec_EApplicationFormSetupInput> | Rec_EApplicationFormSetupInput;
}>;


export type Rec_EApplicationFormSetupInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'Rec_EApplicationFormSetupInsert'>
);

export type ELetterTemplateListingQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
  PreofferChecklistID?: Maybe<Scalars['String']>;
}>;


export type ELetterTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ELetterTemplateListing?: Maybe<Array<(
    { __typename?: 'RecruitmentE_LetterEntity' }
    & Pick<RecruitmentE_LetterEntity, 'rec_eletter_template_id' | 'name' | 'type' | 'content' | 'is_default' | 'created_dt' | 'modified_dt' | 'rec_offer_checklist_id' | 'in_use'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Description' | 'DocumentFile'>
    )> }
  )>> }
);

export type ELetterTemplateInsertMutationVariables = Exact<{
  input: RecruitmentE_LetterInput;
}>;


export type ELetterTemplateInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ELetterTemplateInsert'>
);

export type ELetterTemplateUpdateMutationVariables = Exact<{
  input: RecruitmentE_LetterInput;
  ELetterTemplateID: Scalars['String'];
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
}>;


export type ELetterTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ELetterTemplateUpdate'>
);

export type ELetterTemplateUpdateDefaultMutationVariables = Exact<{
  ELetterTemplateID: Scalars['String'];
  PreofferChecklistID: Scalars['String'];
}>;


export type ELetterTemplateUpdateDefaultMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ELetterTemplateUpdateDefault'>
);

export type ELetterTemplateDeleteMutationVariables = Exact<{
  ELetterTemplateID: Scalars['String'];
}>;


export type ELetterTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ELetterTemplateDelete'>
);

export type PreOfferChecklistListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PreOfferChecklistListingQuery = (
  { __typename?: 'Query' }
  & { PreOfferChecklistListing?: Maybe<Array<(
    { __typename?: 'RecruitmentPre_offerChecklistEntity' }
    & Pick<RecruitmentPre_OfferChecklistEntity, 'rec_offer_checklist_id' | 'name' | 'sequence' | 'created_dt' | 'modified_dt' | 'is_show' | 'is_required' | 'in_use'>
  )>> }
);

export type PreOfferChecklistInsertMutationVariables = Exact<{
  input: RecruitmentPre_OfferChecklistInput;
}>;


export type PreOfferChecklistInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PreOfferChecklistInsert'>
);

export type PreOfferChecklistUpdateMutationVariables = Exact<{
  input: Array<RecruitmentPre_OfferChecklistInput> | RecruitmentPre_OfferChecklistInput;
}>;


export type PreOfferChecklistUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PreOfferChecklistUpdate'>
);

export type PreOfferChecklistSequenceUpdateMutationVariables = Exact<{
  PreOfferChecklistIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PreOfferChecklistSequenceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PreOfferChecklistSequenceUpdate'>
);

export type PreOfferChecklistDeleteMutationVariables = Exact<{
  PreOfferChecklistID: Scalars['String'];
}>;


export type PreOfferChecklistDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PreOfferChecklistDelete'>
);

export type TalentPoolAdditionalUserInsertMutationVariables = Exact<{
  input: Array<Rec_TalentPoolUserFieldsInput> | Rec_TalentPoolUserFieldsInput;
}>;


export type TalentPoolAdditionalUserInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentPoolAdditionalUserInsert'>
);

export type TalentPoolUserFieldsListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TalentPoolUserFieldsListingQuery = (
  { __typename?: 'Query' }
  & { TalentPoolUserFieldsListing: Array<(
    { __typename?: 'Rec_TalentPoolUserFieldsEntity' }
    & Pick<Rec_TalentPoolUserFieldsEntity, 'rec_additional_user_field_id' | 'subscription_account_id' | 'field_label' | 'status' | 'created_dt' | 'created_by' | 'modified_dt' | 'modified_by' | 'is_required' | 'values' | 'is_active' | 'field_type' | 'in_use'>
  )> }
);

export type DeclineReasonInsertMutationVariables = Exact<{
  input: Array<Rec_DeclineReasonInput> | Rec_DeclineReasonInput;
}>;


export type DeclineReasonInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeclineReasonInsert'>
);

export type DeclineReasonListingQueryVariables = Exact<{ [key: string]: never; }>;


export type DeclineReasonListingQuery = (
  { __typename?: 'Query' }
  & { DeclineReasonListing: Array<(
    { __typename?: 'Rec_DeclineReasonEntity' }
    & Pick<Rec_DeclineReasonEntity, 'rec_decline_reason_id' | 'subscription_account_id' | 'reason' | 'status' | 'created_dt' | 'created_by' | 'modified_dt' | 'modified_by' | 'in_use'>
  )> }
);

export type Rec_PrehiringFormSetupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type Rec_PrehiringFormSetupListingQuery = (
  { __typename?: 'Query' }
  & { Rec_PrehiringFormSetupListing: Array<(
    { __typename?: 'Rec_PrehiringFormSetupEntity' }
    & Pick<Rec_PrehiringFormSetupEntity, 'rec_prehiring_form_setup_id' | 'section_name' | 'sequence' | 'is_show' | 'is_required' | 'min_record' | 'status'>
  )> }
);

export type Rec_PrehiringFormSetupInsertMutationVariables = Exact<{
  input: Array<Rec_PrehiringFormSetupInput> | Rec_PrehiringFormSetupInput;
}>;


export type Rec_PrehiringFormSetupInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'Rec_PrehiringFormSetupInsert'>
);

export type JobPortalSetupListingQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
  isOption?: Maybe<Scalars['Boolean']>;
}>;


export type JobPortalSetupListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPortalSetupListing'>
);

export type JobPortalSetupDetailQueryVariables = Exact<{
  JobPortalID: Scalars['String'];
}>;


export type JobPortalSetupDetailQuery = (
  { __typename?: 'Query' }
  & { JobPortalSetupDetail: (
    { __typename?: 'Rec_JobPortalSetupEntity' }
    & Pick<Rec_JobPortalSetupEntity, 'rec_job_portal_setup_id' | 'name' | 'description' | 'theme_color'>
    & { desktop_banner?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile'>
    )>, mobile_banner?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile'>
    )> }
  ) }
);

export type JobPortalSetupInsertMutationVariables = Exact<{
  input: Rec_JobPortalSetupInput;
}>;


export type JobPortalSetupInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPortalSetupInsert'>
);

export type JobPortalSetupUpdateMutationVariables = Exact<{
  input: Rec_JobPortalSetupInput;
  JobPortalID: Scalars['String'];
}>;


export type JobPortalSetupUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPortalSetupUpdate'>
);

export type JobPortalSetupDeleteMutationVariables = Exact<{
  JobPortalID: Scalars['String'];
}>;


export type JobPortalSetupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPortalSetupDelete'>
);

export type JobPortalQrCodeQueryVariables = Exact<{
  JobPortalID: Scalars['String'];
}>;


export type JobPortalQrCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPortalQrCode'>
);

export type AdminRecruitmentSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminRecruitmentSummaryQuery = (
  { __typename?: 'Query' }
  & { AdminRecruitmentSummary: (
    { __typename?: 'AdminRecruitmentSummaryOutput' }
    & Pick<AdminRecruitmentSummaryOutput, 'ApprovalPolicyLastUpdated' | 'ExpenseTotal' | 'JobPortalTotal' | 'EApplicationFormSetupLastUpdated' | 'PrehiringFormSetupLastUpdated' | 'OfferChecklistTotal' | 'ELetterLastUpdated' | 'AdditionalFieldsTotal' | 'DeclineReasonTotal'>
  ) }
);

export type JobPositionByOrgUnitListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
}>;


export type JobPositionByOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPositionAssignmentList'>
);

export type UpdateEmployeeProfilePicMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type UpdateEmployeeProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfilePicUpdate'>
);

export type PersonnelParameterListingByTypeQueryVariables = Exact<{
  TypeList?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PersonnelParameterListingByTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelParameterListingByType'>
);

export type GetRolePermissionByUserQueryVariables = Exact<{
  hrPermissions?: Maybe<Array<HrPermission> | HrPermission>;
}>;


export type GetRolePermissionByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermissionByUser'>
);

export type GetEmployeeAppSecurityPermissionsByContactIdQueryVariables = Exact<{
  ContactID: Scalars['String'];
  Permissions?: Maybe<Array<HrPermission> | HrPermission>;
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityPermissionsByContactIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeAppSecurityPermissionsByContactID'>
);

export type MyEmployeeInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type MyEmployeeInfoQuery = (
  { __typename?: 'Query' }
  & { MyEmployeeInfo?: Maybe<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DesignationID' | 'CalendarPolicyID' | 'OrgUnitID' | 'ContactID' | 'JobGradeID' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalUnpaidLeave' | 'TotalClaimApproved' | 'TotalClaimAmountApproved' | 'TotalTravelRequest' | 'TotalPurchaseRequisition' | 'TotalCashAdvanceRequisition' | 'TotalOtherRequisition' | 'RoleID' | 'RoleName' | 'Status' | 'ReportTo' | 'MyTodo' | 'MyNotification' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Salutation'>
      & { EmployeeSalutation?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'Name'>
      )> }
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Description'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'SubscriptionAccountID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'CareerLastDate'>
    ), EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CompanyID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'ParentID' | 'DocumentType' | 'FileSize' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type EmployeeListForSpecificSectionQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type EmployeeListForSpecificSectionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListForSpecificSection' | 'EmployeeListForSpecificSectionCount'>
);

export type GetEmpCoOrgUnitListQueryVariables = Exact<{
  EmployeeEmail?: Maybe<Scalars['String']>;
}>;


export type GetEmpCoOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmpCoOrgUnitList'>
);

export type JobPortalInfoQueryVariables = Exact<{
  PortalToken: Scalars['String'];
}>;


export type JobPortalInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPortalInfo'>
);

export type JobPostingListByJobPortalQueryVariables = Exact<{
  PortalToken: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type JobPostingListByJobPortalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPostingListByJobPortal'>
);

export type JobPostingListByJobPortalCountQueryVariables = Exact<{
  PortalToken: Scalars['String'];
}>;


export type JobPostingListByJobPortalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPostingListByJobPortalCount'>
);

export type JobPostingInfoByJobPortalQueryVariables = Exact<{
  JobPostingID: Scalars['String'];
}>;


export type JobPostingInfoByJobPortalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPostingInfoByJobPortal'>
);

export type JobPortalTalentInsertMutationVariables = Exact<{
  input: ContactInput;
  portalToken: Scalars['String'];
  JobPostingID: Scalars['String'];
}>;


export type JobPortalTalentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPortalTalentInsert'>
);

export type TokenValidationQueryVariables = Exact<{
  talentToken?: Maybe<Scalars['String']>;
  portalToken?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}>;


export type TokenValidationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TokenValidation'>
);

export type EApplicationListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type EApplicationListingQuery = (
  { __typename?: 'Query' }
  & { EApplicationListing: Array<(
    { __typename?: 'Rec_EApplicationFormSetupEntity' }
    & Pick<Rec_EApplicationFormSetupEntity, 'section_name'>
  )> }
);

export type EApplicationSummaryQueryVariables = Exact<{
  TalentPoolID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
}>;


export type EApplicationSummaryQuery = (
  { __typename?: 'Query' }
  & { EApplicationSummary?: Maybe<(
    { __typename?: 'EApplicationSummaryOutput' }
    & Pick<EApplicationSummaryOutput, 'SummaryData'>
    & { EApplicationList: Array<(
      { __typename?: 'Rec_EApplicationFormSetupEntity' }
      & Pick<Rec_EApplicationFormSetupEntity, 'section_name' | 'content' | 'min_record' | 'is_required'>
    )> }
  )> }
);

export type DataUpdateEApplicationSummaryQueryVariables = Exact<{
  TalentPoolID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
}>;


export type DataUpdateEApplicationSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DataUpdateEApplicationSummary'>
);

export type EApplicationInsertMutationVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  userID: Scalars['String'];
  contactInput: ContactInput;
  documentInput?: Maybe<DocumentsInput>;
  talentInput: Rec_TalentPoolInput;
  educationInput: Array<PersonnelEducationInsertInput> | PersonnelEducationInsertInput;
  membershipInput: Array<PersonnelMembershipInput> | PersonnelMembershipInput;
  empHistoryInput: Array<PersonnelJobHistoryInput> | PersonnelJobHistoryInput;
  languageInput: Array<PersonnelLanguageInput> | PersonnelLanguageInput;
  referenceInput: Array<PersonnelReferenceInput> | PersonnelReferenceInput;
  documentsInput: Array<DocumentsInput> | DocumentsInput;
  type: Scalars['String'];
  JobPostingID?: Maybe<Scalars['String']>;
}>;


export type EApplicationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EApplicationInsert'>
);

export type DataUpdateEApplicationInsertMutationVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  userID: Scalars['String'];
  contactInput: ContactInput;
  documentInput?: Maybe<DocumentsInput>;
  talentInput: Rec_TalentPoolInput;
  employeeInput?: Maybe<EmployeeInput>;
  educationInput: Array<PersonnelEducationInsertInput> | PersonnelEducationInsertInput;
  certificateInput: Array<PersonnelCertificateInput> | PersonnelCertificateInput;
  empHistoryInput: Array<PersonnelJobHistoryInput> | PersonnelJobHistoryInput;
  dependentInput: Array<EmployeeDependentsInput> | EmployeeDependentsInput;
  referenceInput: Array<PersonnelReferenceInput> | PersonnelReferenceInput;
  documentsInput: Array<DocumentsInput> | DocumentsInput;
  statutoryInput?: Maybe<Rec_TalentStatutoryInput>;
  healthInput: PersonnelHealthInfoInput;
  attachmentToRemove?: Maybe<Array<DocumentsInput> | DocumentsInput>;
  medicalRecordInput: Array<MedicalRecordInput> | MedicalRecordInput;
  vaccinationRecordInput: Array<VaccinationRecordInput> | VaccinationRecordInput;
}>;


export type DataUpdateEApplicationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DataUpdateEApplicationInsert'>
);

export type RecruitmentParameterListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  type: Array<Scalars['String']> | Scalars['String'];
}>;


export type RecruitmentParameterListingQuery = (
  { __typename?: 'Query' }
  & { RecruitmentParameterListing: Array<(
    { __typename?: 'PersonnelParameterEntity' }
    & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name' | 'PersonnelParameterType' | 'IsDefault'>
  )> }
);

export type TalentUserDefineFieldsListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type TalentUserDefineFieldsListingQuery = (
  { __typename?: 'Query' }
  & { TalentUserDefineFieldsListing: Array<(
    { __typename?: 'Rec_TalentPoolUserFieldsEntity' }
    & Pick<Rec_TalentPoolUserFieldsEntity, 'rec_additional_user_field_id' | 'field_label' | 'is_required' | 'values' | 'is_active' | 'field_type'>
  )> }
);

export type DataUpdateTalentPersonalQueryVariables = Exact<{
  TalentPoolID: Scalars['String'];
}>;


export type DataUpdateTalentPersonalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DataUpdateTalentPersonal'>
);

export type DataUpdateHealthInfoQueryVariables = Exact<{
  ContactID: Scalars['String'];
}>;


export type DataUpdateHealthInfoQuery = (
  { __typename?: 'Query' }
  & { DataUpdateHealthInfo?: Maybe<(
    { __typename?: 'PersonnelHealthInfoEntity' }
    & Pick<PersonnelHealthInfoEntity, 'PersonnelHealthInfoID' | 'Height' | 'Weight' | 'BMI' | 'BloodType' | 'DietaryPreference' | 'IsSmoker' | 'VaccinationList'>
    & { VaccinationListSummary?: Maybe<Array<(
      { __typename?: 'VaccinationListItemOutput' }
      & Pick<VaccinationListItemOutput, 'vaccinationID' | 'vaccinationDate'>
      & { vaccinationAttachedment?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>>, MedicalRecords?: Maybe<Array<(
      { __typename?: 'MedicalRecordEntity' }
      & Pick<MedicalRecordEntity, 'MedicalRecordID' | 'DocumentID' | 'Description'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>> }
  )> }
);

export type MyRecruitmentMenuSummaryQueryVariables = Exact<{
  RequestorID?: Maybe<Scalars['String']>;
}>;


export type MyRecruitmentMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyRecruitmentMenuSummary'>
);

export type SubmitMyRecruitmentRequisitionMutationVariables = Exact<{
  input: Rec_RequisitionInput;
}>;


export type SubmitMyRecruitmentRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitMyRecruitmentRequisition'>
);

export type MyRecRequisitionListingTotalCountQueryVariables = Exact<{
  RequestorID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyRecRequisitionListingTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyRecRequisitionListingTotalCount'>
);

export type MyRecRequisitionListingQueryVariables = Exact<{
  RequestorID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyRecRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { MyRecRequisitionListing: Array<(
    { __typename?: 'Rec_Requisition_Output' }
    & Pick<Rec_Requisition_Output, 'rec_requisition_id' | 'org_unit_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name' | 'requestor_id' | 'company_id' | 'requisition_no' | 'employee_type' | 'recruitment_type' | 'requested_headcount' | 'is_budget' | 'request_date' | 'required_date' | 'justification_remark' | 'job_description' | 'min_year_experience' | 'min_salary' | 'max_salary' | 'min_education_id' | 'job_specification' | 'rec_requisition_status' | 'approval_log' | 'approval_remark' | 'submitter_id' | 'submitter_name' | 'submitter_no' | 'created_dt' | 'modified_dt' | 'WorkFlowCurrentApproversList'>
    & { min_education?: Maybe<(
      { __typename?: 'PersonnelParameterEntity' }
      & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name'>
    )>, attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, justification_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, requestor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )> }
    )>, approval_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type UpdateMyRecRequisitionApprovalStatusMutationVariables = Exact<{
  input: Rec_RequisitionInput;
}>;


export type UpdateMyRecRequisitionApprovalStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateMyRecRequisitionApprovalStatus'>
);

export type MyRecInterviewListingQueryVariables = Exact<{
  interviewer_id: Scalars['String'];
  interview_status?: Maybe<Scalars['String']>;
  jobPositionFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type MyRecInterviewListingQuery = (
  { __typename?: 'Query' }
  & { MyRecInterviewListing?: Maybe<Array<(
    { __typename?: 'Rec_InterviewListing_Output' }
    & Pick<Rec_InterviewListing_Output, 'rec_applicant_id' | 'rec_interview_schedule_id' | 'applicant_name' | 'applicant_image' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'target_hiring_date' | 'requisition_no' | 'job_vacancy_no' | 'job_vacancy_status' | 'interview_date' | 'start_time' | 'end_time' | 'venue' | 'schedule_remark' | 'rec_interviewer_id' | 'interviewer_id' | 'is_attend' | 'rating' | 'is_recommend' | 'interviewer_remark' | 'attachment_id' | 'attachment_file' | 'attachment_file_name' | 'schedule_round' | 'org_unit_name'>
  )>> }
);

export type MyRecInterviewListingCountQueryVariables = Exact<{
  interviewer_id: Scalars['String'];
  interview_status?: Maybe<Scalars['String']>;
  jobPositionFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type MyRecInterviewListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyRecInterviewListingCount'>
);

export type MyApprovedJobVacancyListingQueryVariables = Exact<{
  RequestorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyApprovedJobVacancyListingQuery = (
  { __typename?: 'Query' }
  & { MyApprovedJobVacancyListing?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )>> }
);

export type MyApprovedJobVacancyListingCountQueryVariables = Exact<{
  RequestorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyApprovedJobVacancyListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyApprovedJobVacancyListingCount'>
);

export type MyApprovedJobVacancyInfoQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
}>;


export type MyApprovedJobVacancyInfoQuery = (
  { __typename?: 'Query' }
  & { MyApprovedJobVacancyInfo?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )>> }
);

export type MyApprovedJobVacancyApplicantListingQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  ApplicationStage?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type MyApprovedJobVacancyApplicantListingQuery = (
  { __typename?: 'Query' }
  & { MyApprovedJobVacancyApplicantListing: Array<(
    { __typename?: 'Rec_ApplicantOutput' }
    & Pick<Rec_ApplicantOutput, 'rec_applicant_id' | 'rec_talent_pool_id' | 'availability_date' | 'rec_job_posting_id' | 'application_stage' | 'application_status' | 'application_status_log' | 'applicant_count_by_stage' | 'interview_status' | 'preferred_location' | 'FullName' | 'Email' | 'DocumentID' | 'DocumentFile' | 'CompanyName' | 'StartDate' | 'EndDate' | 'JobPosition' | 'LastDrawnSalary' | 'IsCurrentJob' | 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'rec_requisition' | 'requisition_no' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'Name' | 'job_designation_name'>
    & { rec_talent_pool?: Maybe<(
      { __typename?: 'Rec_TalentPoolEntity' }
      & Pick<Rec_TalentPoolEntity, 'preferred_location'>
    )>, contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, personnel_job_history?: Maybe<(
      { __typename?: 'PersonnelJobHistoryEntity' }
      & Pick<PersonnelJobHistoryEntity, 'StartDate' | 'EndDate' | 'JobPosition' | 'CompanyName' | 'LastDrawnSalary'>
    )> }
  )> }
);

export type MyApprovedJobVacancyApplicantListingCountQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  ApplicationStage?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type MyApprovedJobVacancyApplicantListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyApprovedJobVacancyApplicantListingCount'>
);

export type MyApprovedJobVacancyApplicantStatusUpdateMutationVariables = Exact<{
  ApplicantIDs: Array<Scalars['String']> | Scalars['String'];
  applicantLog?: Maybe<ApplicantStatusLog>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
}>;


export type MyApprovedJobVacancyApplicantStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MyApprovedJobVacancyApplicantStatusUpdate'>
);

export type MyOfferListingQueryVariables = Exact<{
  RequestorID: Scalars['String'];
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus> | RecruitmentOfferStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus> | RecruitmentOfferResponseStatus>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyOfferListingQuery = (
  { __typename?: 'Query' }
  & { MyOfferListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferOutput' }
    & Pick<Rec_OfferOutput, 'rec_offer_id' | 'target_hiring_date' | 'duty_date' | 'offer_status' | 'offer_count' | 'response_status' | 'offer_process_status' | 'availability_date' | 'rec_applicant_id' | 'expected_salary' | 'rec_talent_pool_id' | 'FullName' | 'DocumentFile' | 'rec_job_vacancy_id' | 'job_vacancy_no' | 'rec_requisition_id' | 'requisition_no' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name'>
  )>> }
);

export type MyOfferListingCountQueryVariables = Exact<{
  RequestorID: Scalars['String'];
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus> | RecruitmentOfferStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus> | RecruitmentOfferResponseStatus>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type MyOfferListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyOfferListingCount'>
);

export type MyOfferDetailQueryVariables = Exact<{
  ApplicantID: Scalars['String'];
}>;


export type MyOfferDetailQuery = (
  { __typename?: 'Query' }
  & { MyOfferDetail: Array<(
    { __typename?: 'Rec_OfferEntity' }
    & Pick<Rec_OfferEntity, 'salary' | 'probation_month' | 'duty_date' | 'offer_remark' | 'submit_date' | 'offer_status' | 'response_status' | 'response_date' | 'response_remark' | 'approval_remark'>
    & { offer_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, rec_decline_reason?: Maybe<(
      { __typename?: 'Rec_DeclineReasonEntity' }
      & Pick<Rec_DeclineReasonEntity, 'reason'>
    )>, response_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )> }
);

export type MyTalentSearchApplicationListingQueryVariables = Exact<{
  TalentPoolID: Scalars['String'];
  RequestorID: Scalars['String'];
}>;


export type MyTalentSearchApplicationListingQuery = (
  { __typename?: 'Query' }
  & { MyTalentSearchApplicationListing?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_name' | 'org_unit_name' | 'application_stage' | 'has_access'>
  )>> }
);

export type GetAllCoOrgUnitListBySubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCoOrgUnitListBySubscriptionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAllCoOrgUnitListBySubscription'>
);

export type AdminRecruitmentMenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminRecruitmentMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AdminRecruitmentMenuSummary'>
);

export type SettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SettingSummary'>
);

export type TalentPoolSummaryQueryVariables = Exact<{
  employeeID?: Maybe<Scalars['String']>;
}>;


export type TalentPoolSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TalentPoolSummary'>
);

export type JobVacancyManagementSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type JobVacancyManagementSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobVacancyManagementSummary'>
);

export type JobOfferManagementSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type JobOfferManagementSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobOfferManagementSummary'>
);

export type HiringProcessSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type HiringProcessSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HiringProcessSummary'>
);

export type SubmitRecruitmentRequisitionMutationVariables = Exact<{
  input: Rec_RequisitionInput;
}>;


export type SubmitRecruitmentRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitRecruitmentRequisition'>
);

export type RecRequisitionListingTotalCountQueryVariables = Exact<{
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type RecRequisitionListingTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RecRequisitionListingTotalCount'>
);

export type RecRequisitionListingQueryVariables = Exact<{
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type RecRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { RecRequisitionListing: Array<(
    { __typename?: 'Rec_Requisition_Output' }
    & Pick<Rec_Requisition_Output, 'rec_requisition_id' | 'org_unit_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name' | 'requestor_id' | 'company_id' | 'requisition_no' | 'employee_type' | 'recruitment_type' | 'requested_headcount' | 'is_budget' | 'request_date' | 'required_date' | 'justification_remark' | 'job_description' | 'min_year_experience' | 'min_salary' | 'max_salary' | 'min_education_id' | 'job_specification' | 'rec_requisition_status' | 'approval_log' | 'approval_remark' | 'submitter_id' | 'submitter_name' | 'submitter_no' | 'created_dt' | 'modified_dt' | 'WorkFlowCurrentApproversList'>
    & { min_education?: Maybe<(
      { __typename?: 'PersonnelParameterEntity' }
      & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name'>
    )>, attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, justification_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, requestor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )> }
    )>, approval_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type GetRecRequisitionInfoQueryVariables = Exact<{
  RecRequisitionID: Scalars['String'];
}>;


export type GetRecRequisitionInfoQuery = (
  { __typename?: 'Query' }
  & { GetRecRequisitionInfo?: Maybe<(
    { __typename?: 'Rec_Requisition_Output' }
    & Pick<Rec_Requisition_Output, 'rec_requisition_id' | 'org_unit_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name' | 'requestor_id' | 'company_id' | 'requisition_no' | 'employee_type' | 'recruitment_type' | 'requested_headcount' | 'is_budget' | 'request_date' | 'required_date' | 'justification_remark' | 'job_description' | 'min_year_experience' | 'min_salary' | 'max_salary' | 'min_education_id' | 'job_specification' | 'rec_requisition_status' | 'approval_log' | 'approval_remark'>
    & { min_education?: Maybe<(
      { __typename?: 'PersonnelParameterEntity' }
      & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name'>
    )>, attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, justification_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, requestor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )> }
    )>, approval_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type DeleteRecRequisitionMutationVariables = Exact<{
  RecRequisitionID: Scalars['String'];
}>;


export type DeleteRecRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRecRequisition'>
);

export type UpdateRecRequisitionApprovalStatusMutationVariables = Exact<{
  input: Rec_RequisitionInput;
}>;


export type UpdateRecRequisitionApprovalStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRecRequisitionApprovalStatus'>
);

export type SimpleCompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SimpleCompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
  )> }
);

export type CompanyOrgUnitByCompanyIdArrayQueryVariables = Exact<{
  CompanyIDArray?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CompanyOrgUnitByCompanyIdArrayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOrgUnitList'>
);

export type JobDesignationListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobDesignationListingQuery = (
  { __typename?: 'Query' }
  & { JobDesignationListing: Array<(
    { __typename?: 'JobDesignationEntity' }
    & Pick<JobDesignationEntity, 'JobDesignationID' | 'SubscriptionAccountID' | 'Name' | 'IsAdmin' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'Status' | 'DesignationID' | 'EmployeeID'>
    )>>, JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobGradeID'>
    )>> }
  )> }
);

export type RecRequisitionNotInUseListingQueryVariables = Exact<{
  ApprovalStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type RecRequisitionNotInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RecRequisitionNotInUseListingCount'>
  & { RecRequisitionNotInUseListing: Array<(
    { __typename?: 'Rec_Requisition_Output' }
    & Pick<Rec_Requisition_Output, 'rec_requisition_id' | 'org_unit_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name' | 'requestor_id' | 'requisition_no' | 'is_budget' | 'request_date' | 'required_date' | 'rec_requisition_status'>
    & { requestor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )> }
    )> }
  )> }
);

export type JobVacancyListingQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobVacancyListingQuery = (
  { __typename?: 'Query' }
  & { JobVacancyListing: Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'location' | 'target_hiring_date' | 'job_vacancy_status_log' | 'total_amount' | 'requisition_no' | 'requested_headcount' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage' | 'in_use'>
  )> }
);

export type JobVacancyListingCountQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobVacancyListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobVacancyListingCount'>
);

export type JobVacancyInfoQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
}>;


export type JobVacancyInfoQuery = (
  { __typename?: 'Query' }
  & { JobVacancyInfo: (
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'location' | 'target_hiring_date' | 'rec_requisition'>
    & { rec_job_vacancy_source?: Maybe<Array<(
      { __typename?: 'Rec_JobVacancySourceEntity' }
      & Pick<Rec_JobVacancySourceEntity, 'rec_job_vacancy_source_id' | 'source_id'>
      & { rec_source?: Maybe<(
        { __typename?: 'RecruitmentSourceEntity' }
        & Pick<RecruitmentSourceEntity, 'RecruitmentSourceID' | 'Name'>
      )> }
    )>>, rec_job_vacancy_expense?: Maybe<Array<(
      { __typename?: 'Rec_JobVacancyExpenseEntity' }
      & Pick<Rec_JobVacancyExpenseEntity, 'rec_job_vacancy_expense_id' | 'rec_expense_id' | 'remark' | 'amount'>
      & { rec_expense?: Maybe<(
        { __typename?: 'RecruitmentExpensesEntity' }
        & Pick<RecruitmentExpensesEntity, 'rec_expense_id' | 'name'>
      )> }
    )>> }
  ) }
);

export type JobVacancyInsertMutationVariables = Exact<{
  jobVacancyInput: Rec_JobVacancyInput;
  recSourceInput?: Maybe<Array<Rec_JobVacancySourceInput> | Rec_JobVacancySourceInput>;
  recExpenseInput?: Maybe<Array<Rec_JobVacancyExpenseInput> | Rec_JobVacancyExpenseInput>;
}>;


export type JobVacancyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobVacancyInsert'>
);

export type JobVacancyStatusUpdateMutationVariables = Exact<{
  JobVacancyID: Scalars['String'];
  input: JobVacancyStatusLog;
}>;


export type JobVacancyStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobVacancyStatusUpdate'>
);

export type JobVacancyDeleteMutationVariables = Exact<{
  JobVacancyID: Scalars['String'];
}>;


export type JobVacancyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobVacancyDelete'>
);

export type PostedJobVacancyListingQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type PostedJobVacancyListingQuery = (
  { __typename?: 'Query' }
  & { PostedJobVacancyListing?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )>> }
);

export type PostedJobVacancyListingCountQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type PostedJobVacancyListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PostedJobVacancyListingCount'>
);

export type PostedJobVacancyInfoQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
}>;


export type PostedJobVacancyInfoQuery = (
  { __typename?: 'Query' }
  & { PostedJobVacancyInfo?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )>> }
);

export type ApplicantListingQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  ApplicationStage?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type ApplicantListingQuery = (
  { __typename?: 'Query' }
  & { ApplicantListing: Array<(
    { __typename?: 'Rec_ApplicantOutput' }
    & Pick<Rec_ApplicantOutput, 'rec_applicant_id' | 'rec_talent_pool_id' | 'availability_date' | 'rec_job_posting_id' | 'application_stage' | 'application_status' | 'application_status_log' | 'applicant_count_by_stage' | 'interview_status' | 'preferred_location' | 'FullName' | 'Email' | 'DocumentID' | 'DocumentFile' | 'CompanyName' | 'StartDate' | 'EndDate' | 'JobPosition' | 'LastDrawnSalary' | 'IsCurrentJob' | 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'rec_requisition' | 'requisition_no' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'Name' | 'job_designation_name'>
    & { rec_talent_pool?: Maybe<(
      { __typename?: 'Rec_TalentPoolEntity' }
      & Pick<Rec_TalentPoolEntity, 'preferred_location'>
    )>, contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, personnel_job_history?: Maybe<(
      { __typename?: 'PersonnelJobHistoryEntity' }
      & Pick<PersonnelJobHistoryEntity, 'StartDate' | 'EndDate' | 'JobPosition' | 'CompanyName' | 'LastDrawnSalary'>
    )> }
  )> }
);

export type ApplicantListingCountQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  ApplicationStage?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type ApplicantListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApplicantListingCount'>
);

export type ApplicantStatusUpdateMutationVariables = Exact<{
  ApplicantIDs: Array<Scalars['String']> | Scalars['String'];
  applicantLog?: Maybe<ApplicantStatusLog>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
}>;


export type ApplicantStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApplicantStatusUpdate'>
);

export type JobPostingInsertMutationVariables = Exact<{
  jobPostingInput: Rec_JobPostingInput;
}>;


export type JobPostingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPostingInsert'>
);

export type JobVacancyNotInUseListingQueryVariables = Exact<{
  JobVacancyStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobVacancyNotInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobVacancyNotInUseListingCount'>
  & { JobVacancyNotInUseListing: Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'location' | 'target_hiring_date' | 'requisition_no' | 'requested_headcount' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name'>
  )> }
);

export type JobPostingListingQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobPostingListingQuery = (
  { __typename?: 'Query' }
  & { JobPostingListing: Array<(
    { __typename?: 'Rec_JobPostingOutput' }
    & Pick<Rec_JobPostingOutput, 'rec_job_posting_id' | 'subscription_account_id' | 'rec_job_vacancy_id' | 'job_posting_status' | 'job_description' | 'job_specification' | 'job_closing_date' | 'remark' | 'created_dt' | 'job_vacancy_no' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'location' | 'target_hiring_date' | 'rec_job_posting_portal' | 'in_use' | 'applicant_count'>
  )> }
);

export type JobPostingListingCountQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobPostingListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPostingListingCount'>
);

export type JobPostingInfoQueryVariables = Exact<{
  jobPostingId: Scalars['String'];
}>;


export type JobPostingInfoQuery = (
  { __typename?: 'Query' }
  & { JobPostingInfo: (
    { __typename?: 'Rec_JobPostingOutput' }
    & Pick<Rec_JobPostingOutput, 'rec_job_posting_id' | 'subscription_account_id' | 'rec_job_vacancy_id' | 'job_posting_status' | 'job_description' | 'job_specification' | 'job_closing_date' | 'remark' | 'created_dt' | 'job_vacancy_no' | 'org_unit_name' | 'requisition_no' | 'job_designation_name' | 'location' | 'target_hiring_date' | 'rec_job_posting_portal'>
  ) }
);

export type JobPostingStatusUpdateMutationVariables = Exact<{
  jobPostingId: Scalars['String'];
  input: Rec_JobPostingUpdateStatus;
}>;


export type JobPostingStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPostingStatusUpdate'>
);

export type JobPostingUpdateMutationVariables = Exact<{
  jobPostingId: Scalars['String'];
  jobPostingInput: Rec_JobPostingUpdate;
}>;


export type JobPostingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPostingUpdate'>
);

export type JobPostingDeleteMutationVariables = Exact<{
  JobPostingID: Scalars['String'];
}>;


export type JobPostingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPostingDelete'>
);

export type JobVacancyWithApplicantListingQueryVariables = Exact<{
  rec_applicant_id?: Maybe<Scalars['String']>;
  tabStatus: Scalars['String'];
  application_stage: Scalars['String'];
  jobPositionFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type JobVacancyWithApplicantListingQuery = (
  { __typename?: 'Query' }
  & { JobVacancyWithApplicantListing?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyWithApplicantOutput' }
    & Pick<Rec_JobVacancyWithApplicantOutput, 'job_vacancy_id' | 'job_vacancy_no' | 'job_posting_id' | 'job_designation_name' | 'company_id' | 'requestor_id' | 'org_unit_id' | 'org_unit_name' | 'rec_applicant_id' | 'applicant_name' | 'applicant_image' | 'application_stage' | 'interview_remark' | 'expected_salary' | 'availability_date' | 'final_selection_status'>
  )>> }
);

export type InterviewerInUseQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewerInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'InterviewerInUse'>
);

export type InterviewScheduleListingQueryVariables = Exact<{
  rec_applicant_id?: Maybe<Scalars['String']>;
}>;


export type InterviewScheduleListingQuery = (
  { __typename?: 'Query' }
  & { InterviewScheduleListing?: Maybe<Array<(
    { __typename?: 'Rec_InterviewScheduleOutput' }
    & Pick<Rec_InterviewScheduleOutput, 'rec_interview_schedule_id' | 'subscription_account_id' | 'rec_applicant_id' | 'start_time' | 'end_time' | 'interview_date' | 'venue' | 'is_complete' | 'remark' | 'remark_voice'>
    & { remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, interviewers?: Maybe<Array<(
      { __typename?: 'Rec_InterviewerEntity' }
      & Pick<Rec_InterviewerEntity, 'rec_interviewer_id' | 'interviewer_id' | 'rec_interview_schedule_id' | 'rating' | 'remark' | 'remark_voice' | 'attachment' | 'is_recommend' | 'is_attend'>
      & { interviewer?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, attachment_file?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )>, remark_voice_file?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )> }
    )>> }
  )>> }
);

export type SubmitInterviewScheduleMutationVariables = Exact<{
  input: Rec_InterviewScheduleInput;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
}>;


export type SubmitInterviewScheduleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitInterviewSchedule'>
);

export type UpdateInterviewResultMutationVariables = Exact<{
  input: Rec_InterviewerInput;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateInterviewResultMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateInterviewResult'>
);

export type CompleteInterviewMutationVariables = Exact<{
  input: Rec_ApplicantInput;
}>;


export type CompleteInterviewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompleteInterview'>
);

export type ReopenInterviewMutationVariables = Exact<{
  rec_applicant_id: Scalars['String'];
}>;


export type ReopenInterviewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReopenInterview'>
);

export type DeleteInterviewScheduleMutationVariables = Exact<{
  rec_interview_schedule_id: Scalars['String'];
}>;


export type DeleteInterviewScheduleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteInterviewSchedule'>
);

export type ActiveJobVacancyListingQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type ActiveJobVacancyListingQuery = (
  { __typename?: 'Query' }
  & { ActiveJobVacancyListing: Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'requested_headcount' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )> }
);

export type ActiveJobVacancyListingCountQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type ActiveJobVacancyListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ActiveJobVacancyListingCount'>
);

export type ActiveJobVacancyInfoQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
}>;


export type ActiveJobVacancyInfoQuery = (
  { __typename?: 'Query' }
  & { ActiveJobVacancyInfo?: Maybe<Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requested_headcount' | 'requisition_no' | 'job_designation_id' | 'job_designation_name' | 'org_unit_id' | 'company_id' | 'org_unit_name' | 'applicant_count_by_stage'>
  )>> }
);

export type FinalApplicantSelectionListingQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type FinalApplicantSelectionListingQuery = (
  { __typename?: 'Query' }
  & { FinalApplicantSelectionListing: Array<(
    { __typename?: 'Rec_ApplicantOutput' }
    & Pick<Rec_ApplicantOutput, 'rec_applicant_id' | 'rec_talent_pool_id' | 'availability_date' | 'rec_job_posting_id' | 'application_stage' | 'application_status' | 'application_status_log' | 'applicant_count_by_stage' | 'interview_status' | 'final_selection_status' | 'expected_salary' | 'FullName' | 'Email' | 'DocumentID' | 'DocumentFile' | 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'rec_requisition' | 'requisition_no'>
    & { contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type FinalApplicantSelectionListingCountQueryVariables = Exact<{
  JobVacancyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type FinalApplicantSelectionListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'FinalApplicantSelectionListingCount'>
);

export type FinalSelectionStatusUpdateMutationVariables = Exact<{
  ApplicantIDs: Array<Scalars['String']> | Scalars['String'];
  applicantLog?: Maybe<ApplicantStatusLog>;
  FinalSelectionStatus?: Maybe<RecruitmentFinalSelectionStatus>;
}>;


export type FinalSelectionStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FinalSelectionStatusUpdate'>
);

export type OfferProposalListingQueryVariables = Exact<{
  OfferStatus: Array<RecruitmentOfferStatus> | RecruitmentOfferStatus;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus> | RecruitmentOfferResponseStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type OfferProposalListingQuery = (
  { __typename?: 'Query' }
  & { OfferProposalListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferOutput' }
    & Pick<Rec_OfferOutput, 'rec_offer_id' | 'target_hiring_date' | 'duty_date' | 'offer_status' | 'response_status' | 'offer_count' | 'availability_date' | 'rec_applicant_id' | 'expected_salary' | 'rec_talent_pool_id' | 'FullName' | 'DocumentFile' | 'rec_job_vacancy_id' | 'job_vacancy_no' | 'rec_requisition_id' | 'requisition_no' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name'>
  )>> }
);

export type OfferProposalListingCountQueryVariables = Exact<{
  OfferStatus: Array<RecruitmentOfferStatus> | RecruitmentOfferStatus;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus> | RecruitmentOfferResponseStatus>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type OfferProposalListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OfferProposalListingCount'>
);

export type OfferProposalInfoQueryVariables = Exact<{
  ApplicantID: Scalars['String'];
}>;


export type OfferProposalInfoQuery = (
  { __typename?: 'Query' }
  & { OfferProposalInfo: (
    { __typename?: 'Rec_OfferEntity' }
    & Pick<Rec_OfferEntity, 'rec_offer_id' | 'rec_applicant_id' | 'salary' | 'probation_month' | 'duty_date' | 'offer_remark'>
    & { offer_remark_voice_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>, offer_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )> }
  ) }
);

export type OfferProposalUpdateMutationVariables = Exact<{
  input: Rec_OfferInput;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
}>;


export type OfferProposalUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfferProposalUpdate'>
);

export type OfferProposalDetailListingQueryVariables = Exact<{
  ApplicantID: Scalars['String'];
}>;


export type OfferProposalDetailListingQuery = (
  { __typename?: 'Query' }
  & { OfferProposalDetailListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferEntity' }
    & Pick<Rec_OfferEntity, 'salary' | 'probation_month' | 'duty_date' | 'offer_remark' | 'submit_date' | 'offer_status' | 'response_status' | 'response_date' | 'response_remark' | 'approval_remark'>
    & { offer_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, rec_decline_reason?: Maybe<(
      { __typename?: 'Rec_DeclineReasonEntity' }
      & Pick<Rec_DeclineReasonEntity, 'reason'>
    )>, response_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )>> }
);

export type OfferProposalInsertMutationVariables = Exact<{
  input: Rec_OfferInput;
}>;


export type OfferProposalInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfferProposalInsert'>
);

export type OfferApprovalListingQueryVariables = Exact<{
  OfferStatus: Array<RecruitmentOfferStatus> | RecruitmentOfferStatus;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type OfferApprovalListingQuery = (
  { __typename?: 'Query' }
  & { OfferApprovalListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferOutput' }
    & Pick<Rec_OfferOutput, 'rec_offer_id' | 'target_hiring_date' | 'duty_date' | 'offer_status' | 'offer_count' | 'availability_date' | 'rec_applicant_id' | 'expected_salary' | 'rec_talent_pool_id' | 'FullName' | 'DocumentFile' | 'rec_job_vacancy_id' | 'job_vacancy_no' | 'rec_requisition_id' | 'requisition_no' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name'>
  )>> }
);

export type OfferApprovalListingCountQueryVariables = Exact<{
  OfferStatus: Array<RecruitmentOfferStatus> | RecruitmentOfferStatus;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type OfferApprovalListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OfferApprovalListingCount'>
);

export type OfferApprovalDetailListingQueryVariables = Exact<{
  ApplicantID: Scalars['String'];
}>;


export type OfferApprovalDetailListingQuery = (
  { __typename?: 'Query' }
  & { OfferApprovalDetailListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferEntity' }
    & Pick<Rec_OfferEntity, 'salary' | 'probation_month' | 'duty_date' | 'offer_remark' | 'submit_date' | 'offer_status' | 'approval_remark'>
    & { offer_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )>> }
);

export type OfferApprovalUpdateMutationVariables = Exact<{
  input: Rec_OfferInput;
}>;


export type OfferApprovalUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfferApprovalUpdate'>
);

export type ApprovedJobOfferListingQueryVariables = Exact<{
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus> | RecruitmentOfferProcessStatus;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type ApprovedJobOfferListingQuery = (
  { __typename?: 'Query' }
  & { ApprovedJobOfferListing?: Maybe<Array<(
    { __typename?: 'Rec_OfferOutput' }
    & Pick<Rec_OfferOutput, 'rec_offer_id' | 'target_hiring_date' | 'duty_date' | 'offer_status' | 'response_status' | 'rec_offer_process_id' | 'offer_process_status' | 'send_log' | 'offer_count' | 'availability_date' | 'rec_applicant_id' | 'expected_salary' | 'rec_talent_pool_id' | 'FullName' | 'Email' | 'DocumentFile' | 'rec_job_vacancy_id' | 'job_vacancy_no' | 'rec_requisition_id' | 'requisition_no' | 'company_id' | 'org_unit_name' | 'job_designation_id' | 'job_designation_name' | 'requestor_name' | 'offer_checklist_snapshot'>
    & { rec_offer_content?: Maybe<Array<(
      { __typename?: 'Rec_OfferContentEntity' }
      & Pick<Rec_OfferContentEntity, 'rec_offer_content_id' | 'rec_offer_checklist_id' | 'offer_content_status'>
      & { eletter_document_file?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'BucketFileName' | 'Content'>
      )> }
    )>> }
  )>> }
);

export type ApprovedJobOfferListingCountQueryVariables = Exact<{
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus> | RecruitmentOfferProcessStatus;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type ApprovedJobOfferListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApprovedJobOfferListingCount'>
);

export type ApprovedJobOfferInfoQueryVariables = Exact<{
  OfferProcessID: Scalars['String'];
}>;


export type ApprovedJobOfferInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApprovedJobOfferInfo'>
);

export type ProcessedOfferInfoQueryVariables = Exact<{
  ApplicantID: Scalars['String'];
}>;


export type ProcessedOfferInfoQuery = (
  { __typename?: 'Query' }
  & { ProcessedOfferInfo: Array<(
    { __typename?: 'Rec_OfferEntity' }
    & Pick<Rec_OfferEntity, 'salary' | 'probation_month' | 'duty_date' | 'offer_remark' | 'submit_date' | 'offer_status' | 'response_status' | 'response_date' | 'response_remark' | 'approval_remark'>
    & { offer_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, rec_decline_reason?: Maybe<(
      { __typename?: 'Rec_DeclineReasonEntity' }
      & Pick<Rec_DeclineReasonEntity, 'reason'>
    )>, response_attachment_file?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )> }
);

export type OfferChecklistListingQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferChecklistListingQuery = (
  { __typename?: 'Query' }
  & { OfferChecklistListing?: Maybe<Array<(
    { __typename?: 'RecruitmentPre_offerChecklistEntity' }
    & Pick<RecruitmentPre_OfferChecklistEntity, 'rec_offer_checklist_id' | 'name' | 'sequence' | 'is_required'>
  )>> }
);

export type ELetterTemplateInfoQueryVariables = Exact<{
  OfferChecklistID: Scalars['String'];
}>;


export type ELetterTemplateInfoQuery = (
  { __typename?: 'Query' }
  & { ELetterTemplateInfo?: Maybe<Array<(
    { __typename?: 'RecruitmentE_LetterEntity' }
    & Pick<RecruitmentE_LetterEntity, 'name' | 'content' | 'is_default'>
  )>> }
);

export type OfferContentInsertMutationVariables = Exact<{
  offerContentInput: Rec_OfferContentInput;
  offerChecklistSnapshot?: Maybe<Array<OfferChecklistSnapshot> | OfferChecklistSnapshot>;
}>;


export type OfferContentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfferContentInsert'>
);

export type FinalizeOfferMutationVariables = Exact<{
  offerContentInput: Array<Rec_OfferContentInput> | Rec_OfferContentInput;
}>;


export type FinalizeOfferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FinalizeOffer'>
);

export type UpdateOfferResponseMutationVariables = Exact<{
  input: Rec_OfferInput;
  applicantLog?: Maybe<ApplicantStatusLog>;
  talentStatusLog?: Maybe<TalentStatusLog>;
}>;


export type UpdateOfferResponseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateOfferResponse'>
);

export type SendLetterMutationVariables = Exact<{
  sendLog: SendLog;
  OfferContent: Array<Rec_OfferContentInput> | Rec_OfferContentInput;
  OfferChecklistSnapshot: Array<OfferChecklistSnapshot> | OfferChecklistSnapshot;
  OfferProcessID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
}>;


export type SendLetterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SendLetter'>
);

export type ApplicantOfferPreHiringListingQueryVariables = Exact<{
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ApplicantOfferPreHiringListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApplicantOfferPreHiringListingCount'>
  & { ApplicantOfferPreHiringListing: Array<(
    { __typename?: 'Rec_HiringOutput' }
    & Pick<Rec_HiringOutput, 'duty_date' | 'acceptance_date' | 'rec_talent_pool_id' | 'FullName' | 'Email' | 'document_file' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'org_unit_name' | 'job_designation_name' | 'rec_hiring_id' | 'prehiring_status' | 'data_update_status'>
  )> }
);

export type InsertAcceptedPreHiringMutationVariables = Exact<{
  hiringInput: AcceptedPreHiringInput;
}>;


export type InsertAcceptedPreHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertAcceptedPreHiring'>
);

export type SendHiringEmailMutationVariables = Exact<{
  HiringIDs: Array<Scalars['String']> | Scalars['String'];
  dataUpdateStatusLog: DataUpdateStatusLog;
  emailRemark?: Maybe<Scalars['String']>;
}>;


export type SendHiringEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SendHiringEmail'>
);

export type QuickHiringListingQueryVariables = Exact<{
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type QuickHiringListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'QuickHiringListingCount'>
  & { QuickHiringListing: Array<(
    { __typename?: 'Rec_HiringOutput' }
    & Pick<Rec_HiringOutput, 'rec_hiring_id' | 'org_unit_name' | 'acceptance_date' | 'duty_date' | 'prehiring_status' | 'FullName' | 'job_designation_name' | 'in_use'>
  )> }
);

export type QuickHiringDetailsQueryVariables = Exact<{
  hiringId: Scalars['String'];
}>;


export type QuickHiringDetailsQuery = (
  { __typename?: 'Query' }
  & { QuickHiringDetails: (
    { __typename?: 'QuickHiringDetails' }
    & Pick<QuickHiringDetails, 'rec_hiring_id' | 'org_unit_id' | 'job_designation_name' | 'company_id' | 'subscription_account_id' | 'acceptance_date' | 'duty_date' | 'prehiring_status' | 'source_id' | 'source_name' | 'superior_id' | 'superior_full_name' | 'superior_no' | 'job_designation_id' | 'remark' | 'remark_voice_file' | 'contact_id' | 'rec_talent_pool_id' | 'full_name' | 'email' | 'mobile_no' | 'org_unit_name'>
  ) }
);

export type InsertQuickHiringMutationVariables = Exact<{
  quickHiringInput: QuickHiringInput;
}>;


export type InsertQuickHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertQuickHiring'>
);

export type UpdateQuickHiringMutationVariables = Exact<{
  quickHiringId: Scalars['String'];
  quickHiringInput: QuickHiringInput;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateQuickHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateQuickHiring'>
);

export type DeleteQuickHiringMutationVariables = Exact<{
  quickHiringId: Scalars['String'];
  talentStatusLog: TalentStatusLog;
}>;


export type DeleteQuickHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteQuickHiring'>
);

export type PreHiringDataUpdateListingQueryVariables = Exact<{
  dataUpdateStatus: DataUpdateStatus;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PreHiringDataUpdateListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PreHiringDataUpdateListingCount'>
  & { PreHiringDataUpdateListing: Array<(
    { __typename?: 'Rec_HiringOutput' }
    & Pick<Rec_HiringOutput, 'data_update_status' | 'data_update_status_log' | 'document_file' | 'duty_date' | 'Email' | 'FullName' | 'job_designation_name' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'org_unit_name' | 'rec_hiring_id' | 'rec_talent_pool_id' | 'rec_applicant_id'>
  )> }
);

export type CancelPreHiringMutationVariables = Exact<{
  hiringId: Scalars['String'];
  cancelRemark?: Maybe<Scalars['String']>;
}>;


export type CancelPreHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelPreHiring'>
);

export type ApprovePreHiringMutationVariables = Exact<{
  hiringIds: Array<Scalars['String']> | Scalars['String'];
  dataUpdateStatusLog: DataUpdateStatusLog;
  talentStatusLog: TalentStatusLog;
}>;


export type ApprovePreHiringMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovePreHiring'>
);

export type DataUpdateStatusLogMutationVariables = Exact<{
  hiringId: Scalars['String'];
  input: DataUpdateStatusLog;
}>;


export type DataUpdateStatusLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DataUpdateStatusLog'>
);

export type NewHireProcessListingQueryVariables = Exact<{
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OnboardStatus: Array<OnboardStatus> | OnboardStatus;
}>;


export type NewHireProcessListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'NewHireProcessListingCount'>
  & { NewHireProcessListing: Array<(
    { __typename?: 'Rec_HiringOutput' }
    & Pick<Rec_HiringOutput, 'data_update_status' | 'data_update_status_log' | 'onboard_status' | 'onboard_approval_log' | 'submitter_id' | 'submitter_name' | 'submitter_no' | 'document_file' | 'duty_date' | 'Email' | 'FullName' | 'job_designation_id' | 'job_designation_name' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'org_unit_name' | 'rec_hiring_id' | 'rec_talent_pool_id' | 'rec_applicant_id' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )> }
);

export type DeclineNewHiringProcessMutationVariables = Exact<{
  hiringId: Scalars['String'];
  talentStatusLog: TalentStatusLog;
  declineDate: Scalars['DateTime'];
  declineReason: Scalars['String'];
  declineRemark?: Maybe<Scalars['String']>;
  declineRemarkVoice?: Maybe<DocumentsInput>;
}>;


export type DeclineNewHiringProcessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeclineNewHiringProcess'>
);

export type NewHireProcessOnboardInfoQueryVariables = Exact<{
  hiringId: Scalars['String'];
}>;


export type NewHireProcessOnboardInfoQuery = (
  { __typename?: 'Query' }
  & { NewHireProcessOnboardInfo: (
    { __typename?: 'NewHireProcessOnboardInfoOutput' }
    & Pick<NewHireProcessOnboardInfoOutput, 'contact_id' | 'org_unit_id' | 'superior_id' | 'source_id' | 'duty_date' | 'probation_month' | 'full_name' | 'job_designation_id' | 'job_designation_name' | 'company_id' | 'superior_full_name' | 'superior_role' | 'recruitment_source_name' | 'org_unit_name'>
  ) }
);

export type OnboardNewHireProcessMutationVariables = Exact<{
  hiringId: Scalars['String'];
  input: OnboardNewHireInput;
}>;


export type OnboardNewHireProcessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OnboardNewHireProcess'>
);

export type TalentPoolListingQueryVariables = Exact<{
  jobPositions?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  preferLocations?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  extraFilters?: Maybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  talentStatus?: Maybe<Array<RecruitmentTalentStatus> | RecruitmentTalentStatus>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type TalentPoolListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TalentPoolListingCount'>
  & { TalentPoolListing?: Maybe<Array<(
    { __typename?: 'Rec_TalentPoolOutput' }
    & Pick<Rec_TalentPoolOutput, 'rec_talent_pool_id' | 'preferred_location' | 'status' | 'talent_status' | 'talent_status_log' | 'availability_date' | 'expected_salary' | 'is_hire' | 'is_blacklist' | 'application_count' | 'applicant_ids' | 'talent_image' | 'talent_name' | 'job_position' | 'company_name' | 'start_date' | 'end_date' | 'last_drawn_salary' | 'is_current_job'>
  )>> }
);

export type TalentPoolDeleteMutationVariables = Exact<{
  TalentPoolID: Scalars['String'];
}>;


export type TalentPoolDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentPoolDelete'>
);

export type TalentProfileSummaryQueryVariables = Exact<{
  TalentPoolID: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}>;


export type TalentProfileSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TalentProfileSummary'>
);

export type TalentProfileDetailQueryVariables = Exact<{
  typeID: Scalars['String'];
  type: Scalars['String'];
  formType?: Maybe<Scalars['String']>;
}>;


export type TalentProfileDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TalentProfileDetail'>
);

export type TalentHealthInfoQueryVariables = Exact<{
  PersonnelHealthInfoID: Scalars['String'];
}>;


export type TalentHealthInfoQuery = (
  { __typename?: 'Query' }
  & { TalentHealthInfo?: Maybe<(
    { __typename?: 'PersonnelHealthInfoEntity' }
    & Pick<PersonnelHealthInfoEntity, 'PersonnelHealthInfoID' | 'Height' | 'Weight' | 'BMI' | 'BloodType' | 'DietaryPreference' | 'IsSmoker' | 'VaccinationList'>
    & { VaccinationListSummary?: Maybe<Array<(
      { __typename?: 'VaccinationListItemOutput' }
      & Pick<VaccinationListItemOutput, 'vaccinationID' | 'vaccinationDate'>
      & { vaccinationAttachedment?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>>, MedicalRecords?: Maybe<Array<(
      { __typename?: 'MedicalRecordEntity' }
      & Pick<MedicalRecordEntity, 'MedicalRecordID' | 'DocumentID' | 'Description'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>> }
  )> }
);

export type TalentPersonalInsertMutationVariables = Exact<{
  contactInput: ContactInput;
  documentInput?: Maybe<DocumentsInput>;
  talentInput: Rec_TalentPoolInput;
  employeeInput?: Maybe<EmployeeInput>;
}>;


export type TalentPersonalInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentPersonalInsert'>
);

export type TalentEducationInsertMutationVariables = Exact<{
  input: PersonnelEducationInsertInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
}>;


export type TalentEducationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentEducationInsert'>
);

export type TalentMembershipInsertMutationVariables = Exact<{
  input: PersonnelMembershipInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelMembershipID?: Maybe<Scalars['String']>;
}>;


export type TalentMembershipInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentMembershipInsert'>
);

export type TalentEmpHistoryInsertMutationVariables = Exact<{
  input: PersonnelJobHistoryInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
}>;


export type TalentEmpHistoryInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentEmpHistoryInsert'>
);

export type TalentReferenceInsertMutationVariables = Exact<{
  input: PersonnelReferenceInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelReferenceID?: Maybe<Scalars['String']>;
}>;


export type TalentReferenceInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentReferenceInsert'>
);

export type TalentHealthInfoInsertMutationVariables = Exact<{
  healthInput: PersonnelHealthInfoInput;
  attachmentToRemove?: Maybe<Array<DocumentsInput> | DocumentsInput>;
  medicalRecordInput: Array<MedicalRecordInput> | MedicalRecordInput;
  vaccinationRecordInput?: Maybe<Array<VaccinationRecordInput> | VaccinationRecordInput>;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
}>;


export type TalentHealthInfoInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentHealthInfoInsert'>
);

export type TalentStatutoryInsertMutationVariables = Exact<{
  input: Rec_TalentStatutoryInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  TalentStatutoryID?: Maybe<Scalars['String']>;
}>;


export type TalentStatutoryInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentStatutoryInsert'>
);

export type TalentCertificateInsertMutationVariables = Exact<{
  input: PersonnelCertificateInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
}>;


export type TalentCertificateInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentCertificateInsert'>
);

export type TalentDependentInsertMutationVariables = Exact<{
  dependentInput: EmployeeDependentsInput;
  contactInput: ContactInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
}>;


export type TalentDependentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentDependentInsert'>
);

export type TalentLanguageInsertMutationVariables = Exact<{
  input: Array<PersonnelLanguageInput> | PersonnelLanguageInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  PersonnelLanguageID?: Maybe<Scalars['String']>;
}>;


export type TalentLanguageInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentLanguageInsert'>
);

export type TalentDocInsertMutationVariables = Exact<{
  input: DocumentsInput;
  TalentPoolID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type TalentDocInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentDocInsert'>
);

export type TalentInfoDeleteMutationVariables = Exact<{
  typeID: Scalars['String'];
  type: Scalars['String'];
}>;


export type TalentInfoDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TalentInfoDelete'>
);

export type UpdateTalentStatusMutationVariables = Exact<{
  talentPoolID: Scalars['String'];
  talentStatus: RecruitmentTalentStatus;
  talentStatusLog: TalentStatusLog;
}>;


export type UpdateTalentStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateTalentStatus'>
);

export type TalentApplicationListingQueryVariables = Exact<{
  TalentPoolID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type TalentApplicationListingQuery = (
  { __typename?: 'Query' }
  & { TalentApplicationListing: Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_name' | 'org_unit_name' | 'application_stage' | 'has_access'>
  )> }
);

export type TalentJobVacancyAppliedListingQueryVariables = Exact<{
  TalentPoolID: Scalars['String'];
}>;


export type TalentJobVacancyAppliedListingQuery = (
  { __typename?: 'Query' }
  & { TalentJobVacancyAppliedListing: Array<(
    { __typename?: 'Rec_JobVacancyOutput' }
    & Pick<Rec_JobVacancyOutput, 'rec_job_vacancy_id' | 'rec_requisition_id' | 'job_vacancy_no' | 'job_vacancy_status' | 'target_hiring_date' | 'requisition_no' | 'job_designation_name' | 'org_unit_name' | 'is_applied'>
  )> }
);

export type AddTalentToPrescreenMutationVariables = Exact<{
  TalentPoolID: Scalars['String'];
  JobVacancyIDs: Array<Scalars['String']> | Scalars['String'];
  applicantLog: ApplicantStatusLog;
  talentStatusLog: TalentStatusLog;
}>;


export type AddTalentToPrescreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddTalentToPrescreen'>
);

export type TalentSearchListingQueryVariables = Exact<{
  employee_id?: Maybe<Scalars['String']>;
}>;


export type TalentSearchListingQuery = (
  { __typename?: 'Query' }
  & { TalentSearchListing: Array<(
    { __typename?: 'Rec_TalentSearchOutput' }
    & Pick<Rec_TalentSearchOutput, 'rec_talent_search_id' | 'subscription_account_id' | 'predefined_search_name' | 'job_position' | 'preferred_location' | 'extra_filter' | 'employee_id'>
  )> }
);

export type SaveTalentSearchMutationVariables = Exact<{
  input: Rec_TalentSearchInput;
}>;


export type SaveTalentSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SaveTalentSearch'>
);

export type DeleteTalentSearchMutationVariables = Exact<{
  rec_talent_search_id: Scalars['String'];
}>;


export type DeleteTalentSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTalentSearch'>
);

export type InsertTalentInvitationMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  talentInvitationLog: TalentInvitationStatusLog;
  companyName: Scalars['String'];
  sourceID?: Maybe<Scalars['String']>;
}>;


export type InsertTalentInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertTalentInvitation'>
);

export type TalentInvitationListingQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  inviteStatus?: Maybe<InviteStatus>;
  keyword?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type TalentInvitationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TalentInvitationListingCount'>
  & { TalentInvitationListing: Array<(
    { __typename?: 'Rec_TalentInvitationOutput' }
    & Pick<Rec_TalentInvitationOutput, 'rec_talent_invitation_id' | 'rec_talent_pool_id' | 'full_name' | 'email' | 'document_file' | 'invite_date' | 'talent_invitation_log'>
  )> }
);

export type SendTalentInvitationEmailMutationVariables = Exact<{
  talentInvitationIDs: Array<Scalars['String']> | Scalars['String'];
  companyName: Scalars['String'];
  talentInvitationLog: TalentInvitationStatusLog;
  emailRemark?: Maybe<Scalars['String']>;
}>;


export type SendTalentInvitationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SendTalentInvitationEmail'>
);

export type CancelTalentInvitationMutationVariables = Exact<{
  talentInvitationID: Scalars['String'];
  cancelRemark?: Maybe<Scalars['String']>;
}>;


export type CancelTalentInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelTalentInvitation'>
);

export type ApproveTalentInvitationMutationVariables = Exact<{
  talentInvitationIDs: Array<Scalars['String']> | Scalars['String'];
  talentInvitationLog: TalentInvitationStatusLog;
  talentStatusLog: TalentStatusLog;
}>;


export type ApproveTalentInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApproveTalentInvitation'>
);

export type ApprovalPolicyMatrixSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type ApprovalPolicyMatrixSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApprovalPolicyMatrixSummary'>
);

export type RecruitmentApprovalPolicyByTypeQueryVariables = Exact<{
  ApprovalPolicyType: Scalars['String'];
}>;


export type RecruitmentApprovalPolicyByTypeQuery = (
  { __typename?: 'Query' }
  & { RecruitmentApprovalPolicyByType?: Maybe<Array<(
    { __typename?: 'ApprovalPolicyMatrixEntity' }
    & Pick<ApprovalPolicyMatrixEntity, 'id' | 'approval_policy_id' | 'condition_list' | 'description'>
    & { ApprovalPolicy?: Maybe<(
      { __typename?: 'ApprovalPolicyEntity' }
      & Pick<ApprovalPolicyEntity, 'ID' | 'Title'>
    )> }
  )>> }
);

export type ApprovalPolicyMatrixInsertMutationVariables = Exact<{
  input: ApprovalPolicyMatrixInput;
}>;


export type ApprovalPolicyMatrixInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyMatrixInsert'>
);

export type ApprovalPolicyMatrixDeleteMutationVariables = Exact<{
  ApprovalPolicyMatrixID: Scalars['String'];
}>;


export type ApprovalPolicyMatrixDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyMatrixDelete'>
);

export type WorkFlowDetailQueryVariables = Exact<{
  type: Scalars['String'];
  typeID: Scalars['String'];
}>;


export type WorkFlowDetailQuery = (
  { __typename?: 'Query' }
  & { WorkFlowDetail: (
    { __typename?: 'WorkFlowDetail' }
    & Pick<WorkFlowDetail, 'submitter_id' | 'submitter_name' | 'submitter_no' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  ) }
);


export const EmployeeProfileInsertUpdateDocument = gql`
    mutation EmployeeProfileInsertUpdate($DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfileInsertUpdate(DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type EmployeeProfileInsertUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;

/**
 * __useEmployeeProfileInsertUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeProfileInsertUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeProfileInsertUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeProfileInsertUpdateMutation, { data, loading, error }] = useEmployeeProfileInsertUpdateMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useEmployeeProfileInsertUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>(EmployeeProfileInsertUpdateDocument, baseOptions);
      }
export type EmployeeProfileInsertUpdateMutationHookResult = ReturnType<typeof useEmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    userName
    email
    contactNo
    department
    accountID
    jobTitle
    superUser
    lastestAccessedEntity
    roleID
    isEmployee
    isEmployeeAuth
    employeeID
    helpdeskAssignee
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const IsSuperUserEmployeeDocument = gql`
    query IsSuperUserEmployee($EmployeeID: String!) {
  IsSuperUserEmployee(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useIsSuperUserEmployeeQuery__
 *
 * To run a query within a React component, call `useIsSuperUserEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSuperUserEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSuperUserEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useIsSuperUserEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
      }
export function useIsSuperUserEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
        }
export type IsSuperUserEmployeeQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeQuery>;
export type IsSuperUserEmployeeLazyQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeLazyQuery>;
export type IsSuperUserEmployeeQueryResult = ApolloReactCommon.QueryResult<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>;
export const LoginSsoDocument = gql`
    query loginSSO($token: String!, $deviceToken: String = null) {
  loginSSO(token: $token, DeviceToken: $deviceToken)
}
    `;

/**
 * __useLoginSsoQuery__
 *
 * To run a query within a React component, call `useLoginSsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginSsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginSsoQuery({
 *   variables: {
 *      token: // value for 'token'
 *      deviceToken: // value for 'deviceToken'
 *   },
 * });
 */
export function useLoginSsoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoginSsoQuery, LoginSsoQueryVariables>) {
        return ApolloReactHooks.useQuery<LoginSsoQuery, LoginSsoQueryVariables>(LoginSsoDocument, baseOptions);
      }
export function useLoginSsoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginSsoQuery, LoginSsoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoginSsoQuery, LoginSsoQueryVariables>(LoginSsoDocument, baseOptions);
        }
export type LoginSsoQueryHookResult = ReturnType<typeof useLoginSsoQuery>;
export type LoginSsoLazyQueryHookResult = ReturnType<typeof useLoginSsoLazyQuery>;
export type LoginSsoQueryResult = ApolloReactCommon.QueryResult<LoginSsoQuery, LoginSsoQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!, $softwareCode: SoftwareCode!) {
  login(password: $password, loginId: $loginId, softwareCode: $softwareCode) {
    accessToken
    isEmployeeSuperUser
    user {
      accountID
      ID
      name
      superUser
      employeeID
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const EmployeeAppLoginDocument = gql`
    mutation employeeAppLogin($loginId: String!, $password: String!) {
  employeeAppLogin(loginId: $loginId, password: $password) {
    accessToken
    employee {
      subscriptionAccountID
      employeeID
      employeeNo
      name
      email
      mobileNo
    }
  }
}
    `;
export type EmployeeAppLoginMutationFn = ApolloReactCommon.MutationFunction<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;

/**
 * __useEmployeeAppLoginMutation__
 *
 * To run a mutation, you first call `useEmployeeAppLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAppLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAppLoginMutation, { data, loading, error }] = useEmployeeAppLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEmployeeAppLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>(EmployeeAppLoginDocument, baseOptions);
      }
export type EmployeeAppLoginMutationHookResult = ReturnType<typeof useEmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationResult = ApolloReactCommon.MutationResult<EmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;
export const LoggedInEmployeeProfileDocument = gql`
    query loggedInEmployeeProfile {
  loggedInEmployeeProfile {
    subscriptionAccountID
    employeeID
    employeeNo
    companyID
    name
    email
    mobileNo
    LastestAccessedEntity
    SuperUserID
    Salutation
    contactID
  }
}
    `;

/**
 * __useLoggedInEmployeeProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInEmployeeProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInEmployeeProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInEmployeeProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInEmployeeProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
      }
export function useLoggedInEmployeeProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
        }
export type LoggedInEmployeeProfileQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileQuery>;
export type LoggedInEmployeeProfileLazyQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileLazyQuery>;
export type LoggedInEmployeeProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeEmployeePasswordDocument = gql`
    mutation changeEmployeePassword($newPassword: String!, $currentPassword: String!) {
  changeEmployeePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangeEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;

/**
 * __useChangeEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useChangeEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmployeePasswordMutation, { data, loading, error }] = useChangeEmployeePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangeEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>(ChangeEmployeePasswordDocument, baseOptions);
      }
export type ChangeEmployeePasswordMutationHookResult = ReturnType<typeof useChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ForgotEmployeePasswordDocument = gql`
    mutation forgotEmployeePassword($email: String!) {
  forgotEmployeePassword(email: $email)
}
    `;
export type ForgotEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;

/**
 * __useForgotEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useForgotEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotEmployeePasswordMutation, { data, loading, error }] = useForgotEmployeePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>(ForgotEmployeePasswordDocument, baseOptions);
      }
export type ForgotEmployeePasswordMutationHookResult = ReturnType<typeof useForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetEmployeePasswordDocument = gql`
    mutation resetEmployeePassword($password: String!, $token: String!) {
  resetEmployeePassword(password: $password, token: $token)
}
    `;
export type ResetEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;

/**
 * __useResetEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useResetEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEmployeePasswordMutation, { data, loading, error }] = useResetEmployeePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>(ResetEmployeePasswordDocument, baseOptions);
      }
export type ResetEmployeePasswordMutationHookResult = ReturnType<typeof useResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmployeeLogoutDocument = gql`
    mutation employeeLogout {
  employeeLogout
}
    `;
export type EmployeeLogoutMutationFn = ApolloReactCommon.MutationFunction<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;

/**
 * __useEmployeeLogoutMutation__
 *
 * To run a mutation, you first call `useEmployeeLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeLogoutMutation, { data, loading, error }] = useEmployeeLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>(EmployeeLogoutDocument, baseOptions);
      }
export type EmployeeLogoutMutationHookResult = ReturnType<typeof useEmployeeLogoutMutation>;
export type EmployeeLogoutMutationResult = ApolloReactCommon.MutationResult<EmployeeLogoutMutation>;
export type EmployeeLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const UserProfilePicDocument = gql`
    query UserProfilePic($UserID: String!, $IsEmployee: Boolean) {
  UserProfilePic(UserID: $UserID, IsEmployee: $IsEmployee)
}
    `;

/**
 * __useUserProfilePicQuery__
 *
 * To run a query within a React component, call `useUserProfilePicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfilePicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfilePicQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      IsEmployee: // value for 'IsEmployee'
 *   },
 * });
 */
export function useUserProfilePicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
      }
export function useUserProfilePicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
        }
export type UserProfilePicQueryHookResult = ReturnType<typeof useUserProfilePicQuery>;
export type UserProfilePicLazyQueryHookResult = ReturnType<typeof useUserProfilePicLazyQuery>;
export type UserProfilePicQueryResult = ApolloReactCommon.QueryResult<UserProfilePicQuery, UserProfilePicQueryVariables>;
export const CheckOnPremServerDocument = gql`
    query CheckOnPremServer {
  CheckOnPremServer
}
    `;

/**
 * __useCheckOnPremServerQuery__
 *
 * To run a query within a React component, call `useCheckOnPremServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOnPremServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOnPremServerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckOnPremServerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckOnPremServerQuery, CheckOnPremServerQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckOnPremServerQuery, CheckOnPremServerQueryVariables>(CheckOnPremServerDocument, baseOptions);
      }
export function useCheckOnPremServerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckOnPremServerQuery, CheckOnPremServerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckOnPremServerQuery, CheckOnPremServerQueryVariables>(CheckOnPremServerDocument, baseOptions);
        }
export type CheckOnPremServerQueryHookResult = ReturnType<typeof useCheckOnPremServerQuery>;
export type CheckOnPremServerLazyQueryHookResult = ReturnType<typeof useCheckOnPremServerLazyQuery>;
export type CheckOnPremServerQueryResult = ApolloReactCommon.QueryResult<CheckOnPremServerQuery, CheckOnPremServerQueryVariables>;
export const RecruitmentExpensesListingDocument = gql`
    query RecruitmentExpensesListing {
  RecruitmentExpensesListing {
    rec_expense_id
    name
    in_use
  }
}
    `;

/**
 * __useRecruitmentExpensesListingQuery__
 *
 * To run a query within a React component, call `useRecruitmentExpensesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentExpensesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentExpensesListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruitmentExpensesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentExpensesListingQuery, RecruitmentExpensesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentExpensesListingQuery, RecruitmentExpensesListingQueryVariables>(RecruitmentExpensesListingDocument, baseOptions);
      }
export function useRecruitmentExpensesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentExpensesListingQuery, RecruitmentExpensesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentExpensesListingQuery, RecruitmentExpensesListingQueryVariables>(RecruitmentExpensesListingDocument, baseOptions);
        }
export type RecruitmentExpensesListingQueryHookResult = ReturnType<typeof useRecruitmentExpensesListingQuery>;
export type RecruitmentExpensesListingLazyQueryHookResult = ReturnType<typeof useRecruitmentExpensesListingLazyQuery>;
export type RecruitmentExpensesListingQueryResult = ApolloReactCommon.QueryResult<RecruitmentExpensesListingQuery, RecruitmentExpensesListingQueryVariables>;
export const RecruitmentExpensesInsertDocument = gql`
    mutation RecruitmentExpensesInsert($input: [RecruitmentExpensesInput!]!) {
  RecruitmentExpensesInsert(input: $input)
}
    `;
export type RecruitmentExpensesInsertMutationFn = ApolloReactCommon.MutationFunction<RecruitmentExpensesInsertMutation, RecruitmentExpensesInsertMutationVariables>;

/**
 * __useRecruitmentExpensesInsertMutation__
 *
 * To run a mutation, you first call `useRecruitmentExpensesInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentExpensesInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruitmentExpensesInsertMutation, { data, loading, error }] = useRecruitmentExpensesInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruitmentExpensesInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruitmentExpensesInsertMutation, RecruitmentExpensesInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruitmentExpensesInsertMutation, RecruitmentExpensesInsertMutationVariables>(RecruitmentExpensesInsertDocument, baseOptions);
      }
export type RecruitmentExpensesInsertMutationHookResult = ReturnType<typeof useRecruitmentExpensesInsertMutation>;
export type RecruitmentExpensesInsertMutationResult = ApolloReactCommon.MutationResult<RecruitmentExpensesInsertMutation>;
export type RecruitmentExpensesInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruitmentExpensesInsertMutation, RecruitmentExpensesInsertMutationVariables>;
export const RecruitmentExpensesDeleteDocument = gql`
    mutation RecruitmentExpensesDelete($recruitment_expense_ids: [String!]!) {
  RecruitmentExpensesDelete(recruitment_expense_ids: $recruitment_expense_ids)
}
    `;
export type RecruitmentExpensesDeleteMutationFn = ApolloReactCommon.MutationFunction<RecruitmentExpensesDeleteMutation, RecruitmentExpensesDeleteMutationVariables>;

/**
 * __useRecruitmentExpensesDeleteMutation__
 *
 * To run a mutation, you first call `useRecruitmentExpensesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentExpensesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruitmentExpensesDeleteMutation, { data, loading, error }] = useRecruitmentExpensesDeleteMutation({
 *   variables: {
 *      recruitment_expense_ids: // value for 'recruitment_expense_ids'
 *   },
 * });
 */
export function useRecruitmentExpensesDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruitmentExpensesDeleteMutation, RecruitmentExpensesDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruitmentExpensesDeleteMutation, RecruitmentExpensesDeleteMutationVariables>(RecruitmentExpensesDeleteDocument, baseOptions);
      }
export type RecruitmentExpensesDeleteMutationHookResult = ReturnType<typeof useRecruitmentExpensesDeleteMutation>;
export type RecruitmentExpensesDeleteMutationResult = ApolloReactCommon.MutationResult<RecruitmentExpensesDeleteMutation>;
export type RecruitmentExpensesDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruitmentExpensesDeleteMutation, RecruitmentExpensesDeleteMutationVariables>;
export const RecruitmentApprovalPolicySummaryDocument = gql`
    query RecruitmentApprovalPolicySummary {
  RecruitmentApprovalPolicySummary
}
    `;

/**
 * __useRecruitmentApprovalPolicySummaryQuery__
 *
 * To run a query within a React component, call `useRecruitmentApprovalPolicySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentApprovalPolicySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentApprovalPolicySummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecruitmentApprovalPolicySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentApprovalPolicySummaryQuery, RecruitmentApprovalPolicySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentApprovalPolicySummaryQuery, RecruitmentApprovalPolicySummaryQueryVariables>(RecruitmentApprovalPolicySummaryDocument, baseOptions);
      }
export function useRecruitmentApprovalPolicySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentApprovalPolicySummaryQuery, RecruitmentApprovalPolicySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentApprovalPolicySummaryQuery, RecruitmentApprovalPolicySummaryQueryVariables>(RecruitmentApprovalPolicySummaryDocument, baseOptions);
        }
export type RecruitmentApprovalPolicySummaryQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicySummaryQuery>;
export type RecruitmentApprovalPolicySummaryLazyQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicySummaryLazyQuery>;
export type RecruitmentApprovalPolicySummaryQueryResult = ApolloReactCommon.QueryResult<RecruitmentApprovalPolicySummaryQuery, RecruitmentApprovalPolicySummaryQueryVariables>;
export const RecruitmentApprovalPolicyListingDocument = gql`
    query RecruitmentApprovalPolicyListing($type: RecruitmentPolicyType!, $keyword: String) {
  RecruitmentApprovalPolicyListing(type: $type, keyword: $keyword)
}
    `;

/**
 * __useRecruitmentApprovalPolicyListingQuery__
 *
 * To run a query within a React component, call `useRecruitmentApprovalPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentApprovalPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentApprovalPolicyListingQuery({
 *   variables: {
 *      type: // value for 'type'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useRecruitmentApprovalPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentApprovalPolicyListingQuery, RecruitmentApprovalPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentApprovalPolicyListingQuery, RecruitmentApprovalPolicyListingQueryVariables>(RecruitmentApprovalPolicyListingDocument, baseOptions);
      }
export function useRecruitmentApprovalPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentApprovalPolicyListingQuery, RecruitmentApprovalPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentApprovalPolicyListingQuery, RecruitmentApprovalPolicyListingQueryVariables>(RecruitmentApprovalPolicyListingDocument, baseOptions);
        }
export type RecruitmentApprovalPolicyListingQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyListingQuery>;
export type RecruitmentApprovalPolicyListingLazyQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyListingLazyQuery>;
export type RecruitmentApprovalPolicyListingQueryResult = ApolloReactCommon.QueryResult<RecruitmentApprovalPolicyListingQuery, RecruitmentApprovalPolicyListingQueryVariables>;
export const RecruitmentApprovalPolicyEmployeeListingDocument = gql`
    query RecruitmentApprovalPolicyEmployeeListing($CompanyID: String!, $EmployeeStatus: [String!]) {
  EmployeeListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus) {
    EmployeeID
    SubscriptionUserID
    Status
    Contact {
      FullName
    }
  }
}
    `;

/**
 * __useRecruitmentApprovalPolicyEmployeeListingQuery__
 *
 * To run a query within a React component, call `useRecruitmentApprovalPolicyEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentApprovalPolicyEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentApprovalPolicyEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *   },
 * });
 */
export function useRecruitmentApprovalPolicyEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentApprovalPolicyEmployeeListingQuery, RecruitmentApprovalPolicyEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentApprovalPolicyEmployeeListingQuery, RecruitmentApprovalPolicyEmployeeListingQueryVariables>(RecruitmentApprovalPolicyEmployeeListingDocument, baseOptions);
      }
export function useRecruitmentApprovalPolicyEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentApprovalPolicyEmployeeListingQuery, RecruitmentApprovalPolicyEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentApprovalPolicyEmployeeListingQuery, RecruitmentApprovalPolicyEmployeeListingQueryVariables>(RecruitmentApprovalPolicyEmployeeListingDocument, baseOptions);
        }
export type RecruitmentApprovalPolicyEmployeeListingQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyEmployeeListingQuery>;
export type RecruitmentApprovalPolicyEmployeeListingLazyQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyEmployeeListingLazyQuery>;
export type RecruitmentApprovalPolicyEmployeeListingQueryResult = ApolloReactCommon.QueryResult<RecruitmentApprovalPolicyEmployeeListingQuery, RecruitmentApprovalPolicyEmployeeListingQueryVariables>;
export const Rec_EApplicationFormSetupListingDocument = gql`
    query Rec_EApplicationFormSetupListing {
  Rec_EApplicationFormSetupListing {
    rec_eapplication_form_setup_id
    section_name
    sequence
    is_show
    is_required
    content
    min_record
    status
  }
}
    `;

/**
 * __useRec_EApplicationFormSetupListingQuery__
 *
 * To run a query within a React component, call `useRec_EApplicationFormSetupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRec_EApplicationFormSetupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRec_EApplicationFormSetupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRec_EApplicationFormSetupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Rec_EApplicationFormSetupListingQuery, Rec_EApplicationFormSetupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<Rec_EApplicationFormSetupListingQuery, Rec_EApplicationFormSetupListingQueryVariables>(Rec_EApplicationFormSetupListingDocument, baseOptions);
      }
export function useRec_EApplicationFormSetupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Rec_EApplicationFormSetupListingQuery, Rec_EApplicationFormSetupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Rec_EApplicationFormSetupListingQuery, Rec_EApplicationFormSetupListingQueryVariables>(Rec_EApplicationFormSetupListingDocument, baseOptions);
        }
export type Rec_EApplicationFormSetupListingQueryHookResult = ReturnType<typeof useRec_EApplicationFormSetupListingQuery>;
export type Rec_EApplicationFormSetupListingLazyQueryHookResult = ReturnType<typeof useRec_EApplicationFormSetupListingLazyQuery>;
export type Rec_EApplicationFormSetupListingQueryResult = ApolloReactCommon.QueryResult<Rec_EApplicationFormSetupListingQuery, Rec_EApplicationFormSetupListingQueryVariables>;
export const Rec_EApplicationFormSetupInsertDocument = gql`
    mutation Rec_EApplicationFormSetupInsert($input: [Rec_EApplicationFormSetupInput!]!) {
  Rec_EApplicationFormSetupInsert(input: $input)
}
    `;
export type Rec_EApplicationFormSetupInsertMutationFn = ApolloReactCommon.MutationFunction<Rec_EApplicationFormSetupInsertMutation, Rec_EApplicationFormSetupInsertMutationVariables>;

/**
 * __useRec_EApplicationFormSetupInsertMutation__
 *
 * To run a mutation, you first call `useRec_EApplicationFormSetupInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRec_EApplicationFormSetupInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recEApplicationFormSetupInsertMutation, { data, loading, error }] = useRec_EApplicationFormSetupInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRec_EApplicationFormSetupInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Rec_EApplicationFormSetupInsertMutation, Rec_EApplicationFormSetupInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<Rec_EApplicationFormSetupInsertMutation, Rec_EApplicationFormSetupInsertMutationVariables>(Rec_EApplicationFormSetupInsertDocument, baseOptions);
      }
export type Rec_EApplicationFormSetupInsertMutationHookResult = ReturnType<typeof useRec_EApplicationFormSetupInsertMutation>;
export type Rec_EApplicationFormSetupInsertMutationResult = ApolloReactCommon.MutationResult<Rec_EApplicationFormSetupInsertMutation>;
export type Rec_EApplicationFormSetupInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<Rec_EApplicationFormSetupInsertMutation, Rec_EApplicationFormSetupInsertMutationVariables>;
export const ELetterTemplateListingDocument = gql`
    query ELetterTemplateListing($keyword: String, $PreofferChecklistID: String) {
  ELetterTemplateListing(keyword: $keyword, PreofferChecklistID: $PreofferChecklistID) {
    rec_eletter_template_id
    name
    type
    content
    is_default
    created_dt
    modified_dt
    rec_offer_checklist_id
    in_use
    Document {
      Description
      DocumentFile
    }
  }
}
    `;

/**
 * __useELetterTemplateListingQuery__
 *
 * To run a query within a React component, call `useELetterTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useELetterTemplateListingQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      PreofferChecklistID: // value for 'PreofferChecklistID'
 *   },
 * });
 */
export function useELetterTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ELetterTemplateListingQuery, ELetterTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ELetterTemplateListingQuery, ELetterTemplateListingQueryVariables>(ELetterTemplateListingDocument, baseOptions);
      }
export function useELetterTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ELetterTemplateListingQuery, ELetterTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ELetterTemplateListingQuery, ELetterTemplateListingQueryVariables>(ELetterTemplateListingDocument, baseOptions);
        }
export type ELetterTemplateListingQueryHookResult = ReturnType<typeof useELetterTemplateListingQuery>;
export type ELetterTemplateListingLazyQueryHookResult = ReturnType<typeof useELetterTemplateListingLazyQuery>;
export type ELetterTemplateListingQueryResult = ApolloReactCommon.QueryResult<ELetterTemplateListingQuery, ELetterTemplateListingQueryVariables>;
export const ELetterTemplateInsertDocument = gql`
    mutation ELetterTemplateInsert($input: RecruitmentE_LetterInput!) {
  ELetterTemplateInsert(input: $input)
}
    `;
export type ELetterTemplateInsertMutationFn = ApolloReactCommon.MutationFunction<ELetterTemplateInsertMutation, ELetterTemplateInsertMutationVariables>;

/**
 * __useELetterTemplateInsertMutation__
 *
 * To run a mutation, you first call `useELetterTemplateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eLetterTemplateInsertMutation, { data, loading, error }] = useELetterTemplateInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useELetterTemplateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ELetterTemplateInsertMutation, ELetterTemplateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ELetterTemplateInsertMutation, ELetterTemplateInsertMutationVariables>(ELetterTemplateInsertDocument, baseOptions);
      }
export type ELetterTemplateInsertMutationHookResult = ReturnType<typeof useELetterTemplateInsertMutation>;
export type ELetterTemplateInsertMutationResult = ApolloReactCommon.MutationResult<ELetterTemplateInsertMutation>;
export type ELetterTemplateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ELetterTemplateInsertMutation, ELetterTemplateInsertMutationVariables>;
export const ELetterTemplateUpdateDocument = gql`
    mutation ELetterTemplateUpdate($input: RecruitmentE_LetterInput!, $ELetterTemplateID: String!, $IsRemovedAttachment: Boolean) {
  ELetterTemplateUpdate(input: $input, ELetterTemplateID: $ELetterTemplateID, IsRemovedAttachment: $IsRemovedAttachment)
}
    `;
export type ELetterTemplateUpdateMutationFn = ApolloReactCommon.MutationFunction<ELetterTemplateUpdateMutation, ELetterTemplateUpdateMutationVariables>;

/**
 * __useELetterTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useELetterTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eLetterTemplateUpdateMutation, { data, loading, error }] = useELetterTemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ELetterTemplateID: // value for 'ELetterTemplateID'
 *      IsRemovedAttachment: // value for 'IsRemovedAttachment'
 *   },
 * });
 */
export function useELetterTemplateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ELetterTemplateUpdateMutation, ELetterTemplateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ELetterTemplateUpdateMutation, ELetterTemplateUpdateMutationVariables>(ELetterTemplateUpdateDocument, baseOptions);
      }
export type ELetterTemplateUpdateMutationHookResult = ReturnType<typeof useELetterTemplateUpdateMutation>;
export type ELetterTemplateUpdateMutationResult = ApolloReactCommon.MutationResult<ELetterTemplateUpdateMutation>;
export type ELetterTemplateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ELetterTemplateUpdateMutation, ELetterTemplateUpdateMutationVariables>;
export const ELetterTemplateUpdateDefaultDocument = gql`
    mutation ELetterTemplateUpdateDefault($ELetterTemplateID: String!, $PreofferChecklistID: String!) {
  ELetterTemplateUpdateDefault(ELetterTemplateID: $ELetterTemplateID, PreofferChecklistID: $PreofferChecklistID)
}
    `;
export type ELetterTemplateUpdateDefaultMutationFn = ApolloReactCommon.MutationFunction<ELetterTemplateUpdateDefaultMutation, ELetterTemplateUpdateDefaultMutationVariables>;

/**
 * __useELetterTemplateUpdateDefaultMutation__
 *
 * To run a mutation, you first call `useELetterTemplateUpdateDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateUpdateDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eLetterTemplateUpdateDefaultMutation, { data, loading, error }] = useELetterTemplateUpdateDefaultMutation({
 *   variables: {
 *      ELetterTemplateID: // value for 'ELetterTemplateID'
 *      PreofferChecklistID: // value for 'PreofferChecklistID'
 *   },
 * });
 */
export function useELetterTemplateUpdateDefaultMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ELetterTemplateUpdateDefaultMutation, ELetterTemplateUpdateDefaultMutationVariables>) {
        return ApolloReactHooks.useMutation<ELetterTemplateUpdateDefaultMutation, ELetterTemplateUpdateDefaultMutationVariables>(ELetterTemplateUpdateDefaultDocument, baseOptions);
      }
export type ELetterTemplateUpdateDefaultMutationHookResult = ReturnType<typeof useELetterTemplateUpdateDefaultMutation>;
export type ELetterTemplateUpdateDefaultMutationResult = ApolloReactCommon.MutationResult<ELetterTemplateUpdateDefaultMutation>;
export type ELetterTemplateUpdateDefaultMutationOptions = ApolloReactCommon.BaseMutationOptions<ELetterTemplateUpdateDefaultMutation, ELetterTemplateUpdateDefaultMutationVariables>;
export const ELetterTemplateDeleteDocument = gql`
    mutation ELetterTemplateDelete($ELetterTemplateID: String!) {
  ELetterTemplateDelete(ELetterTemplateID: $ELetterTemplateID)
}
    `;
export type ELetterTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ELetterTemplateDeleteMutation, ELetterTemplateDeleteMutationVariables>;

/**
 * __useELetterTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useELetterTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eLetterTemplateDeleteMutation, { data, loading, error }] = useELetterTemplateDeleteMutation({
 *   variables: {
 *      ELetterTemplateID: // value for 'ELetterTemplateID'
 *   },
 * });
 */
export function useELetterTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ELetterTemplateDeleteMutation, ELetterTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ELetterTemplateDeleteMutation, ELetterTemplateDeleteMutationVariables>(ELetterTemplateDeleteDocument, baseOptions);
      }
export type ELetterTemplateDeleteMutationHookResult = ReturnType<typeof useELetterTemplateDeleteMutation>;
export type ELetterTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ELetterTemplateDeleteMutation>;
export type ELetterTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ELetterTemplateDeleteMutation, ELetterTemplateDeleteMutationVariables>;
export const PreOfferChecklistListingDocument = gql`
    query PreOfferChecklistListing {
  PreOfferChecklistListing {
    rec_offer_checklist_id
    name
    sequence
    created_dt
    modified_dt
    is_show
    is_required
    in_use
  }
}
    `;

/**
 * __usePreOfferChecklistListingQuery__
 *
 * To run a query within a React component, call `usePreOfferChecklistListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreOfferChecklistListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreOfferChecklistListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreOfferChecklistListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreOfferChecklistListingQuery, PreOfferChecklistListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PreOfferChecklistListingQuery, PreOfferChecklistListingQueryVariables>(PreOfferChecklistListingDocument, baseOptions);
      }
export function usePreOfferChecklistListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreOfferChecklistListingQuery, PreOfferChecklistListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PreOfferChecklistListingQuery, PreOfferChecklistListingQueryVariables>(PreOfferChecklistListingDocument, baseOptions);
        }
export type PreOfferChecklistListingQueryHookResult = ReturnType<typeof usePreOfferChecklistListingQuery>;
export type PreOfferChecklistListingLazyQueryHookResult = ReturnType<typeof usePreOfferChecklistListingLazyQuery>;
export type PreOfferChecklistListingQueryResult = ApolloReactCommon.QueryResult<PreOfferChecklistListingQuery, PreOfferChecklistListingQueryVariables>;
export const PreOfferChecklistInsertDocument = gql`
    mutation PreOfferChecklistInsert($input: RecruitmentPre_offerChecklistInput!) {
  PreOfferChecklistInsert(input: $input)
}
    `;
export type PreOfferChecklistInsertMutationFn = ApolloReactCommon.MutationFunction<PreOfferChecklistInsertMutation, PreOfferChecklistInsertMutationVariables>;

/**
 * __usePreOfferChecklistInsertMutation__
 *
 * To run a mutation, you first call `usePreOfferChecklistInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreOfferChecklistInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preOfferChecklistInsertMutation, { data, loading, error }] = usePreOfferChecklistInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreOfferChecklistInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreOfferChecklistInsertMutation, PreOfferChecklistInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PreOfferChecklistInsertMutation, PreOfferChecklistInsertMutationVariables>(PreOfferChecklistInsertDocument, baseOptions);
      }
export type PreOfferChecklistInsertMutationHookResult = ReturnType<typeof usePreOfferChecklistInsertMutation>;
export type PreOfferChecklistInsertMutationResult = ApolloReactCommon.MutationResult<PreOfferChecklistInsertMutation>;
export type PreOfferChecklistInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PreOfferChecklistInsertMutation, PreOfferChecklistInsertMutationVariables>;
export const PreOfferChecklistUpdateDocument = gql`
    mutation PreOfferChecklistUpdate($input: [RecruitmentPre_offerChecklistInput!]!) {
  PreOfferChecklistUpdate(input: $input)
}
    `;
export type PreOfferChecklistUpdateMutationFn = ApolloReactCommon.MutationFunction<PreOfferChecklistUpdateMutation, PreOfferChecklistUpdateMutationVariables>;

/**
 * __usePreOfferChecklistUpdateMutation__
 *
 * To run a mutation, you first call `usePreOfferChecklistUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreOfferChecklistUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preOfferChecklistUpdateMutation, { data, loading, error }] = usePreOfferChecklistUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreOfferChecklistUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreOfferChecklistUpdateMutation, PreOfferChecklistUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PreOfferChecklistUpdateMutation, PreOfferChecklistUpdateMutationVariables>(PreOfferChecklistUpdateDocument, baseOptions);
      }
export type PreOfferChecklistUpdateMutationHookResult = ReturnType<typeof usePreOfferChecklistUpdateMutation>;
export type PreOfferChecklistUpdateMutationResult = ApolloReactCommon.MutationResult<PreOfferChecklistUpdateMutation>;
export type PreOfferChecklistUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PreOfferChecklistUpdateMutation, PreOfferChecklistUpdateMutationVariables>;
export const PreOfferChecklistSequenceUpdateDocument = gql`
    mutation PreOfferChecklistSequenceUpdate($PreOfferChecklistIDs: [String!]!) {
  PreOfferChecklistSequenceUpdate(PreOfferChecklistIDs: $PreOfferChecklistIDs)
}
    `;
export type PreOfferChecklistSequenceUpdateMutationFn = ApolloReactCommon.MutationFunction<PreOfferChecklistSequenceUpdateMutation, PreOfferChecklistSequenceUpdateMutationVariables>;

/**
 * __usePreOfferChecklistSequenceUpdateMutation__
 *
 * To run a mutation, you first call `usePreOfferChecklistSequenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreOfferChecklistSequenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preOfferChecklistSequenceUpdateMutation, { data, loading, error }] = usePreOfferChecklistSequenceUpdateMutation({
 *   variables: {
 *      PreOfferChecklistIDs: // value for 'PreOfferChecklistIDs'
 *   },
 * });
 */
export function usePreOfferChecklistSequenceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreOfferChecklistSequenceUpdateMutation, PreOfferChecklistSequenceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PreOfferChecklistSequenceUpdateMutation, PreOfferChecklistSequenceUpdateMutationVariables>(PreOfferChecklistSequenceUpdateDocument, baseOptions);
      }
export type PreOfferChecklistSequenceUpdateMutationHookResult = ReturnType<typeof usePreOfferChecklistSequenceUpdateMutation>;
export type PreOfferChecklistSequenceUpdateMutationResult = ApolloReactCommon.MutationResult<PreOfferChecklistSequenceUpdateMutation>;
export type PreOfferChecklistSequenceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PreOfferChecklistSequenceUpdateMutation, PreOfferChecklistSequenceUpdateMutationVariables>;
export const PreOfferChecklistDeleteDocument = gql`
    mutation PreOfferChecklistDelete($PreOfferChecklistID: String!) {
  PreOfferChecklistDelete(PreOfferChecklistID: $PreOfferChecklistID)
}
    `;
export type PreOfferChecklistDeleteMutationFn = ApolloReactCommon.MutationFunction<PreOfferChecklistDeleteMutation, PreOfferChecklistDeleteMutationVariables>;

/**
 * __usePreOfferChecklistDeleteMutation__
 *
 * To run a mutation, you first call `usePreOfferChecklistDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreOfferChecklistDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preOfferChecklistDeleteMutation, { data, loading, error }] = usePreOfferChecklistDeleteMutation({
 *   variables: {
 *      PreOfferChecklistID: // value for 'PreOfferChecklistID'
 *   },
 * });
 */
export function usePreOfferChecklistDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreOfferChecklistDeleteMutation, PreOfferChecklistDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PreOfferChecklistDeleteMutation, PreOfferChecklistDeleteMutationVariables>(PreOfferChecklistDeleteDocument, baseOptions);
      }
export type PreOfferChecklistDeleteMutationHookResult = ReturnType<typeof usePreOfferChecklistDeleteMutation>;
export type PreOfferChecklistDeleteMutationResult = ApolloReactCommon.MutationResult<PreOfferChecklistDeleteMutation>;
export type PreOfferChecklistDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PreOfferChecklistDeleteMutation, PreOfferChecklistDeleteMutationVariables>;
export const TalentPoolAdditionalUserInsertDocument = gql`
    mutation TalentPoolAdditionalUserInsert($input: [Rec_TalentPoolUserFieldsInput!]!) {
  TalentPoolAdditionalUserInsert(input: $input)
}
    `;
export type TalentPoolAdditionalUserInsertMutationFn = ApolloReactCommon.MutationFunction<TalentPoolAdditionalUserInsertMutation, TalentPoolAdditionalUserInsertMutationVariables>;

/**
 * __useTalentPoolAdditionalUserInsertMutation__
 *
 * To run a mutation, you first call `useTalentPoolAdditionalUserInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentPoolAdditionalUserInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentPoolAdditionalUserInsertMutation, { data, loading, error }] = useTalentPoolAdditionalUserInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTalentPoolAdditionalUserInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentPoolAdditionalUserInsertMutation, TalentPoolAdditionalUserInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentPoolAdditionalUserInsertMutation, TalentPoolAdditionalUserInsertMutationVariables>(TalentPoolAdditionalUserInsertDocument, baseOptions);
      }
export type TalentPoolAdditionalUserInsertMutationHookResult = ReturnType<typeof useTalentPoolAdditionalUserInsertMutation>;
export type TalentPoolAdditionalUserInsertMutationResult = ApolloReactCommon.MutationResult<TalentPoolAdditionalUserInsertMutation>;
export type TalentPoolAdditionalUserInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentPoolAdditionalUserInsertMutation, TalentPoolAdditionalUserInsertMutationVariables>;
export const TalentPoolUserFieldsListingDocument = gql`
    query TalentPoolUserFieldsListing {
  TalentPoolUserFieldsListing {
    rec_additional_user_field_id
    subscription_account_id
    field_label
    status
    created_dt
    created_by
    modified_dt
    modified_by
    is_required
    values
    is_active
    field_type
    in_use
  }
}
    `;

/**
 * __useTalentPoolUserFieldsListingQuery__
 *
 * To run a query within a React component, call `useTalentPoolUserFieldsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentPoolUserFieldsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentPoolUserFieldsListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentPoolUserFieldsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentPoolUserFieldsListingQuery, TalentPoolUserFieldsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentPoolUserFieldsListingQuery, TalentPoolUserFieldsListingQueryVariables>(TalentPoolUserFieldsListingDocument, baseOptions);
      }
export function useTalentPoolUserFieldsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentPoolUserFieldsListingQuery, TalentPoolUserFieldsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentPoolUserFieldsListingQuery, TalentPoolUserFieldsListingQueryVariables>(TalentPoolUserFieldsListingDocument, baseOptions);
        }
export type TalentPoolUserFieldsListingQueryHookResult = ReturnType<typeof useTalentPoolUserFieldsListingQuery>;
export type TalentPoolUserFieldsListingLazyQueryHookResult = ReturnType<typeof useTalentPoolUserFieldsListingLazyQuery>;
export type TalentPoolUserFieldsListingQueryResult = ApolloReactCommon.QueryResult<TalentPoolUserFieldsListingQuery, TalentPoolUserFieldsListingQueryVariables>;
export const DeclineReasonInsertDocument = gql`
    mutation DeclineReasonInsert($input: [Rec_DeclineReasonInput!]!) {
  DeclineReasonInsert(input: $input)
}
    `;
export type DeclineReasonInsertMutationFn = ApolloReactCommon.MutationFunction<DeclineReasonInsertMutation, DeclineReasonInsertMutationVariables>;

/**
 * __useDeclineReasonInsertMutation__
 *
 * To run a mutation, you first call `useDeclineReasonInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineReasonInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineReasonInsertMutation, { data, loading, error }] = useDeclineReasonInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineReasonInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineReasonInsertMutation, DeclineReasonInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclineReasonInsertMutation, DeclineReasonInsertMutationVariables>(DeclineReasonInsertDocument, baseOptions);
      }
export type DeclineReasonInsertMutationHookResult = ReturnType<typeof useDeclineReasonInsertMutation>;
export type DeclineReasonInsertMutationResult = ApolloReactCommon.MutationResult<DeclineReasonInsertMutation>;
export type DeclineReasonInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineReasonInsertMutation, DeclineReasonInsertMutationVariables>;
export const DeclineReasonListingDocument = gql`
    query DeclineReasonListing {
  DeclineReasonListing {
    rec_decline_reason_id
    subscription_account_id
    reason
    status
    created_dt
    created_by
    modified_dt
    modified_by
    in_use
  }
}
    `;

/**
 * __useDeclineReasonListingQuery__
 *
 * To run a query within a React component, call `useDeclineReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclineReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclineReasonListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeclineReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeclineReasonListingQuery, DeclineReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeclineReasonListingQuery, DeclineReasonListingQueryVariables>(DeclineReasonListingDocument, baseOptions);
      }
export function useDeclineReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeclineReasonListingQuery, DeclineReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeclineReasonListingQuery, DeclineReasonListingQueryVariables>(DeclineReasonListingDocument, baseOptions);
        }
export type DeclineReasonListingQueryHookResult = ReturnType<typeof useDeclineReasonListingQuery>;
export type DeclineReasonListingLazyQueryHookResult = ReturnType<typeof useDeclineReasonListingLazyQuery>;
export type DeclineReasonListingQueryResult = ApolloReactCommon.QueryResult<DeclineReasonListingQuery, DeclineReasonListingQueryVariables>;
export const Rec_PrehiringFormSetupListingDocument = gql`
    query Rec_PrehiringFormSetupListing {
  Rec_PrehiringFormSetupListing {
    rec_prehiring_form_setup_id
    section_name
    sequence
    is_show
    is_required
    min_record
    status
  }
}
    `;

/**
 * __useRec_PrehiringFormSetupListingQuery__
 *
 * To run a query within a React component, call `useRec_PrehiringFormSetupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRec_PrehiringFormSetupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRec_PrehiringFormSetupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRec_PrehiringFormSetupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Rec_PrehiringFormSetupListingQuery, Rec_PrehiringFormSetupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<Rec_PrehiringFormSetupListingQuery, Rec_PrehiringFormSetupListingQueryVariables>(Rec_PrehiringFormSetupListingDocument, baseOptions);
      }
export function useRec_PrehiringFormSetupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Rec_PrehiringFormSetupListingQuery, Rec_PrehiringFormSetupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Rec_PrehiringFormSetupListingQuery, Rec_PrehiringFormSetupListingQueryVariables>(Rec_PrehiringFormSetupListingDocument, baseOptions);
        }
export type Rec_PrehiringFormSetupListingQueryHookResult = ReturnType<typeof useRec_PrehiringFormSetupListingQuery>;
export type Rec_PrehiringFormSetupListingLazyQueryHookResult = ReturnType<typeof useRec_PrehiringFormSetupListingLazyQuery>;
export type Rec_PrehiringFormSetupListingQueryResult = ApolloReactCommon.QueryResult<Rec_PrehiringFormSetupListingQuery, Rec_PrehiringFormSetupListingQueryVariables>;
export const Rec_PrehiringFormSetupInsertDocument = gql`
    mutation Rec_PrehiringFormSetupInsert($input: [Rec_PrehiringFormSetupInput!]!) {
  Rec_PrehiringFormSetupInsert(input: $input)
}
    `;
export type Rec_PrehiringFormSetupInsertMutationFn = ApolloReactCommon.MutationFunction<Rec_PrehiringFormSetupInsertMutation, Rec_PrehiringFormSetupInsertMutationVariables>;

/**
 * __useRec_PrehiringFormSetupInsertMutation__
 *
 * To run a mutation, you first call `useRec_PrehiringFormSetupInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRec_PrehiringFormSetupInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recPrehiringFormSetupInsertMutation, { data, loading, error }] = useRec_PrehiringFormSetupInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRec_PrehiringFormSetupInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Rec_PrehiringFormSetupInsertMutation, Rec_PrehiringFormSetupInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<Rec_PrehiringFormSetupInsertMutation, Rec_PrehiringFormSetupInsertMutationVariables>(Rec_PrehiringFormSetupInsertDocument, baseOptions);
      }
export type Rec_PrehiringFormSetupInsertMutationHookResult = ReturnType<typeof useRec_PrehiringFormSetupInsertMutation>;
export type Rec_PrehiringFormSetupInsertMutationResult = ApolloReactCommon.MutationResult<Rec_PrehiringFormSetupInsertMutation>;
export type Rec_PrehiringFormSetupInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<Rec_PrehiringFormSetupInsertMutation, Rec_PrehiringFormSetupInsertMutationVariables>;
export const JobPortalSetupListingDocument = gql`
    query JobPortalSetupListing($keyword: String, $isOption: Boolean) {
  JobPortalSetupListing(keyword: $keyword, isOption: $isOption)
}
    `;

/**
 * __useJobPortalSetupListingQuery__
 *
 * To run a query within a React component, call `useJobPortalSetupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPortalSetupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPortalSetupListingQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      isOption: // value for 'isOption'
 *   },
 * });
 */
export function useJobPortalSetupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPortalSetupListingQuery, JobPortalSetupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPortalSetupListingQuery, JobPortalSetupListingQueryVariables>(JobPortalSetupListingDocument, baseOptions);
      }
export function useJobPortalSetupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPortalSetupListingQuery, JobPortalSetupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPortalSetupListingQuery, JobPortalSetupListingQueryVariables>(JobPortalSetupListingDocument, baseOptions);
        }
export type JobPortalSetupListingQueryHookResult = ReturnType<typeof useJobPortalSetupListingQuery>;
export type JobPortalSetupListingLazyQueryHookResult = ReturnType<typeof useJobPortalSetupListingLazyQuery>;
export type JobPortalSetupListingQueryResult = ApolloReactCommon.QueryResult<JobPortalSetupListingQuery, JobPortalSetupListingQueryVariables>;
export const JobPortalSetupDetailDocument = gql`
    query JobPortalSetupDetail($JobPortalID: String!) {
  JobPortalSetupDetail(JobPortalID: $JobPortalID) {
    rec_job_portal_setup_id
    name
    description
    theme_color
    desktop_banner {
      DocumentID
      Description
      DocumentFile
    }
    mobile_banner {
      DocumentID
      Description
      DocumentFile
    }
  }
}
    `;

/**
 * __useJobPortalSetupDetailQuery__
 *
 * To run a query within a React component, call `useJobPortalSetupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPortalSetupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPortalSetupDetailQuery({
 *   variables: {
 *      JobPortalID: // value for 'JobPortalID'
 *   },
 * });
 */
export function useJobPortalSetupDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPortalSetupDetailQuery, JobPortalSetupDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPortalSetupDetailQuery, JobPortalSetupDetailQueryVariables>(JobPortalSetupDetailDocument, baseOptions);
      }
export function useJobPortalSetupDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPortalSetupDetailQuery, JobPortalSetupDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPortalSetupDetailQuery, JobPortalSetupDetailQueryVariables>(JobPortalSetupDetailDocument, baseOptions);
        }
export type JobPortalSetupDetailQueryHookResult = ReturnType<typeof useJobPortalSetupDetailQuery>;
export type JobPortalSetupDetailLazyQueryHookResult = ReturnType<typeof useJobPortalSetupDetailLazyQuery>;
export type JobPortalSetupDetailQueryResult = ApolloReactCommon.QueryResult<JobPortalSetupDetailQuery, JobPortalSetupDetailQueryVariables>;
export const JobPortalSetupInsertDocument = gql`
    mutation JobPortalSetupInsert($input: Rec_JobPortalSetupInput!) {
  JobPortalSetupInsert(input: $input)
}
    `;
export type JobPortalSetupInsertMutationFn = ApolloReactCommon.MutationFunction<JobPortalSetupInsertMutation, JobPortalSetupInsertMutationVariables>;

/**
 * __useJobPortalSetupInsertMutation__
 *
 * To run a mutation, you first call `useJobPortalSetupInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPortalSetupInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPortalSetupInsertMutation, { data, loading, error }] = useJobPortalSetupInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobPortalSetupInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPortalSetupInsertMutation, JobPortalSetupInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPortalSetupInsertMutation, JobPortalSetupInsertMutationVariables>(JobPortalSetupInsertDocument, baseOptions);
      }
export type JobPortalSetupInsertMutationHookResult = ReturnType<typeof useJobPortalSetupInsertMutation>;
export type JobPortalSetupInsertMutationResult = ApolloReactCommon.MutationResult<JobPortalSetupInsertMutation>;
export type JobPortalSetupInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPortalSetupInsertMutation, JobPortalSetupInsertMutationVariables>;
export const JobPortalSetupUpdateDocument = gql`
    mutation JobPortalSetupUpdate($input: Rec_JobPortalSetupInput!, $JobPortalID: String!) {
  JobPortalSetupUpdate(input: $input, JobPortalID: $JobPortalID)
}
    `;
export type JobPortalSetupUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPortalSetupUpdateMutation, JobPortalSetupUpdateMutationVariables>;

/**
 * __useJobPortalSetupUpdateMutation__
 *
 * To run a mutation, you first call `useJobPortalSetupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPortalSetupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPortalSetupUpdateMutation, { data, loading, error }] = useJobPortalSetupUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      JobPortalID: // value for 'JobPortalID'
 *   },
 * });
 */
export function useJobPortalSetupUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPortalSetupUpdateMutation, JobPortalSetupUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPortalSetupUpdateMutation, JobPortalSetupUpdateMutationVariables>(JobPortalSetupUpdateDocument, baseOptions);
      }
export type JobPortalSetupUpdateMutationHookResult = ReturnType<typeof useJobPortalSetupUpdateMutation>;
export type JobPortalSetupUpdateMutationResult = ApolloReactCommon.MutationResult<JobPortalSetupUpdateMutation>;
export type JobPortalSetupUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPortalSetupUpdateMutation, JobPortalSetupUpdateMutationVariables>;
export const JobPortalSetupDeleteDocument = gql`
    mutation JobPortalSetupDelete($JobPortalID: String!) {
  JobPortalSetupDelete(JobPortalID: $JobPortalID)
}
    `;
export type JobPortalSetupDeleteMutationFn = ApolloReactCommon.MutationFunction<JobPortalSetupDeleteMutation, JobPortalSetupDeleteMutationVariables>;

/**
 * __useJobPortalSetupDeleteMutation__
 *
 * To run a mutation, you first call `useJobPortalSetupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPortalSetupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPortalSetupDeleteMutation, { data, loading, error }] = useJobPortalSetupDeleteMutation({
 *   variables: {
 *      JobPortalID: // value for 'JobPortalID'
 *   },
 * });
 */
export function useJobPortalSetupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPortalSetupDeleteMutation, JobPortalSetupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPortalSetupDeleteMutation, JobPortalSetupDeleteMutationVariables>(JobPortalSetupDeleteDocument, baseOptions);
      }
export type JobPortalSetupDeleteMutationHookResult = ReturnType<typeof useJobPortalSetupDeleteMutation>;
export type JobPortalSetupDeleteMutationResult = ApolloReactCommon.MutationResult<JobPortalSetupDeleteMutation>;
export type JobPortalSetupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPortalSetupDeleteMutation, JobPortalSetupDeleteMutationVariables>;
export const JobPortalQrCodeDocument = gql`
    query JobPortalQrCode($JobPortalID: String!) {
  JobPortalQrCode(JobPortalID: $JobPortalID)
}
    `;

/**
 * __useJobPortalQrCodeQuery__
 *
 * To run a query within a React component, call `useJobPortalQrCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPortalQrCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPortalQrCodeQuery({
 *   variables: {
 *      JobPortalID: // value for 'JobPortalID'
 *   },
 * });
 */
export function useJobPortalQrCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPortalQrCodeQuery, JobPortalQrCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPortalQrCodeQuery, JobPortalQrCodeQueryVariables>(JobPortalQrCodeDocument, baseOptions);
      }
export function useJobPortalQrCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPortalQrCodeQuery, JobPortalQrCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPortalQrCodeQuery, JobPortalQrCodeQueryVariables>(JobPortalQrCodeDocument, baseOptions);
        }
export type JobPortalQrCodeQueryHookResult = ReturnType<typeof useJobPortalQrCodeQuery>;
export type JobPortalQrCodeLazyQueryHookResult = ReturnType<typeof useJobPortalQrCodeLazyQuery>;
export type JobPortalQrCodeQueryResult = ApolloReactCommon.QueryResult<JobPortalQrCodeQuery, JobPortalQrCodeQueryVariables>;
export const AdminRecruitmentSummaryDocument = gql`
    query AdminRecruitmentSummary {
  AdminRecruitmentSummary {
    ApprovalPolicyLastUpdated
    ExpenseTotal
    JobPortalTotal
    EApplicationFormSetupLastUpdated
    PrehiringFormSetupLastUpdated
    OfferChecklistTotal
    ELetterLastUpdated
    AdditionalFieldsTotal
    DeclineReasonTotal
  }
}
    `;

/**
 * __useAdminRecruitmentSummaryQuery__
 *
 * To run a query within a React component, call `useAdminRecruitmentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRecruitmentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRecruitmentSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminRecruitmentSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminRecruitmentSummaryQuery, AdminRecruitmentSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminRecruitmentSummaryQuery, AdminRecruitmentSummaryQueryVariables>(AdminRecruitmentSummaryDocument, baseOptions);
      }
export function useAdminRecruitmentSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminRecruitmentSummaryQuery, AdminRecruitmentSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminRecruitmentSummaryQuery, AdminRecruitmentSummaryQueryVariables>(AdminRecruitmentSummaryDocument, baseOptions);
        }
export type AdminRecruitmentSummaryQueryHookResult = ReturnType<typeof useAdminRecruitmentSummaryQuery>;
export type AdminRecruitmentSummaryLazyQueryHookResult = ReturnType<typeof useAdminRecruitmentSummaryLazyQuery>;
export type AdminRecruitmentSummaryQueryResult = ApolloReactCommon.QueryResult<AdminRecruitmentSummaryQuery, AdminRecruitmentSummaryQueryVariables>;
export const JobPositionByOrgUnitListDocument = gql`
    query JobPositionByOrgUnitList($CompanyID: String!, $OrgUnitID: String) {
  JobPositionAssignmentList(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID)
}
    `;

/**
 * __useJobPositionByOrgUnitListQuery__
 *
 * To run a query within a React component, call `useJobPositionByOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionByOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionByOrgUnitListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useJobPositionByOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>(JobPositionByOrgUnitListDocument, baseOptions);
      }
export function useJobPositionByOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>(JobPositionByOrgUnitListDocument, baseOptions);
        }
export type JobPositionByOrgUnitListQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListQuery>;
export type JobPositionByOrgUnitListLazyQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListLazyQuery>;
export type JobPositionByOrgUnitListQueryResult = ApolloReactCommon.QueryResult<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>;
export const UpdateEmployeeProfilePicDocument = gql`
    mutation updateEmployeeProfilePic($EmployeeID: String!, $DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfilePicUpdate(EmployeeID: $EmployeeID, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type UpdateEmployeeProfilePicMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;

/**
 * __useUpdateEmployeeProfilePicMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeProfilePicMutation, { data, loading, error }] = useUpdateEmployeeProfilePicMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useUpdateEmployeeProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>(UpdateEmployeeProfilePicDocument, baseOptions);
      }
export type UpdateEmployeeProfilePicMutationHookResult = ReturnType<typeof useUpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;
export const PersonnelParameterListingByTypeDocument = gql`
    query PersonnelParameterListingByType($TypeList: [String!]) {
  PersonnelParameterListingByType(TypeList: $TypeList)
}
    `;

/**
 * __usePersonnelParameterListingByTypeQuery__
 *
 * To run a query within a React component, call `usePersonnelParameterListingByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterListingByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelParameterListingByTypeQuery({
 *   variables: {
 *      TypeList: // value for 'TypeList'
 *   },
 * });
 */
export function usePersonnelParameterListingByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelParameterListingByTypeQuery, PersonnelParameterListingByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelParameterListingByTypeQuery, PersonnelParameterListingByTypeQueryVariables>(PersonnelParameterListingByTypeDocument, baseOptions);
      }
export function usePersonnelParameterListingByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelParameterListingByTypeQuery, PersonnelParameterListingByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelParameterListingByTypeQuery, PersonnelParameterListingByTypeQueryVariables>(PersonnelParameterListingByTypeDocument, baseOptions);
        }
export type PersonnelParameterListingByTypeQueryHookResult = ReturnType<typeof usePersonnelParameterListingByTypeQuery>;
export type PersonnelParameterListingByTypeLazyQueryHookResult = ReturnType<typeof usePersonnelParameterListingByTypeLazyQuery>;
export type PersonnelParameterListingByTypeQueryResult = ApolloReactCommon.QueryResult<PersonnelParameterListingByTypeQuery, PersonnelParameterListingByTypeQueryVariables>;
export const GetRolePermissionByUserDocument = gql`
    query getRolePermissionByUser($hrPermissions: [HrPermission!]) {
  getRolePermissionByUser(hrPermissions: $hrPermissions)
}
    `;

/**
 * __useGetRolePermissionByUserQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionByUserQuery({
 *   variables: {
 *      hrPermissions: // value for 'hrPermissions'
 *   },
 * });
 */
export function useGetRolePermissionByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
      }
export function useGetRolePermissionByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
        }
export type GetRolePermissionByUserQueryHookResult = ReturnType<typeof useGetRolePermissionByUserQuery>;
export type GetRolePermissionByUserLazyQueryHookResult = ReturnType<typeof useGetRolePermissionByUserLazyQuery>;
export type GetRolePermissionByUserQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>;
export const GetEmployeeAppSecurityPermissionsByContactIdDocument = gql`
    query GetEmployeeAppSecurityPermissionsByContactID($ContactID: String!, $Permissions: [HrPermission!], $EmployeeID: String) {
  GetEmployeeAppSecurityPermissionsByContactID(ContactID: $ContactID, Permissions: $Permissions, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetEmployeeAppSecurityPermissionsByContactIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityPermissionsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityPermissionsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      Permissions: // value for 'Permissions'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityPermissionsByContactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
        }
export type GetEmployeeAppSecurityPermissionsByContactIdQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>;
export const MyEmployeeInfoDocument = gql`
    query MyEmployeeInfo($EmployeeID: String!) {
  MyEmployeeInfo(EmployeeID: $EmployeeID) {
    EmployeeID
    EmployeeNo
    DesignationID
    CalendarPolicyID
    OrgUnitID
    ContactID
    Contact {
      ContactID
      FullName
      Salutation
      EmployeeSalutation {
        Name
      }
    }
    OrgUnit {
      CompanyOrgUnitID
      Description
    }
    Company {
      CompanyID
      SubscriptionAccountID
      CompanyName
    }
    Designation {
      Name
    }
    Department {
      Description
    }
    JobGradeID
    TotalAnnualLeave
    TotalSickLeave
    TotalUnpaidLeave
    TotalClaimApproved
    TotalClaimAmountApproved
    TotalTravelRequest
    TotalPurchaseRequisition
    TotalCashAdvanceRequisition
    TotalOtherRequisition
    LastUpdatedDates {
      CareerLastDate
    }
    EmployeeSubordinates {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
      CompanyID
    }
    Documents {
      ParentID
      DocumentType
      FileSize
      DocumentFile
      DocumentID
    }
    RoleID
    RoleName
    Status
    ReportTo
    MyTodo
    MyNotification
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
  }
}
    `;

/**
 * __useMyEmployeeInfoQuery__
 *
 * To run a query within a React component, call `useMyEmployeeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEmployeeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEmployeeInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useMyEmployeeInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
      }
export function useMyEmployeeInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
        }
export type MyEmployeeInfoQueryHookResult = ReturnType<typeof useMyEmployeeInfoQuery>;
export type MyEmployeeInfoLazyQueryHookResult = ReturnType<typeof useMyEmployeeInfoLazyQuery>;
export type MyEmployeeInfoQueryResult = ApolloReactCommon.QueryResult<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>;
export const EmployeeListForSpecificSectionDocument = gql`
    query EmployeeListForSpecificSection($CompanyID: String, $ExcludeMonth: String, $offset: Float, $limit: Float, $keyword: String) {
  EmployeeListForSpecificSection(CompanyID: $CompanyID, ExcludeMonth: $ExcludeMonth, offset: $offset, limit: $limit, keyword: $keyword)
  EmployeeListForSpecificSectionCount(CompanyID: $CompanyID, ExcludeMonth: $ExcludeMonth, keyword: $keyword)
}
    `;

/**
 * __useEmployeeListForSpecificSectionQuery__
 *
 * To run a query within a React component, call `useEmployeeListForSpecificSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListForSpecificSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListForSpecificSectionQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ExcludeMonth: // value for 'ExcludeMonth'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useEmployeeListForSpecificSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>(EmployeeListForSpecificSectionDocument, baseOptions);
      }
export function useEmployeeListForSpecificSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>(EmployeeListForSpecificSectionDocument, baseOptions);
        }
export type EmployeeListForSpecificSectionQueryHookResult = ReturnType<typeof useEmployeeListForSpecificSectionQuery>;
export type EmployeeListForSpecificSectionLazyQueryHookResult = ReturnType<typeof useEmployeeListForSpecificSectionLazyQuery>;
export type EmployeeListForSpecificSectionQueryResult = ApolloReactCommon.QueryResult<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>;
export const GetEmpCoOrgUnitListDocument = gql`
    query GetEmpCoOrgUnitList($EmployeeEmail: String) {
  GetEmpCoOrgUnitList(EmployeeEmail: $EmployeeEmail)
}
    `;

/**
 * __useGetEmpCoOrgUnitListQuery__
 *
 * To run a query within a React component, call `useGetEmpCoOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmpCoOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmpCoOrgUnitListQuery({
 *   variables: {
 *      EmployeeEmail: // value for 'EmployeeEmail'
 *   },
 * });
 */
export function useGetEmpCoOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmpCoOrgUnitListQuery, GetEmpCoOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmpCoOrgUnitListQuery, GetEmpCoOrgUnitListQueryVariables>(GetEmpCoOrgUnitListDocument, baseOptions);
      }
export function useGetEmpCoOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmpCoOrgUnitListQuery, GetEmpCoOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmpCoOrgUnitListQuery, GetEmpCoOrgUnitListQueryVariables>(GetEmpCoOrgUnitListDocument, baseOptions);
        }
export type GetEmpCoOrgUnitListQueryHookResult = ReturnType<typeof useGetEmpCoOrgUnitListQuery>;
export type GetEmpCoOrgUnitListLazyQueryHookResult = ReturnType<typeof useGetEmpCoOrgUnitListLazyQuery>;
export type GetEmpCoOrgUnitListQueryResult = ApolloReactCommon.QueryResult<GetEmpCoOrgUnitListQuery, GetEmpCoOrgUnitListQueryVariables>;
export const JobPortalInfoDocument = gql`
    query JobPortalInfo($PortalToken: String!) {
  JobPortalInfo(PortalToken: $PortalToken)
}
    `;

/**
 * __useJobPortalInfoQuery__
 *
 * To run a query within a React component, call `useJobPortalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPortalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPortalInfoQuery({
 *   variables: {
 *      PortalToken: // value for 'PortalToken'
 *   },
 * });
 */
export function useJobPortalInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPortalInfoQuery, JobPortalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPortalInfoQuery, JobPortalInfoQueryVariables>(JobPortalInfoDocument, baseOptions);
      }
export function useJobPortalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPortalInfoQuery, JobPortalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPortalInfoQuery, JobPortalInfoQueryVariables>(JobPortalInfoDocument, baseOptions);
        }
export type JobPortalInfoQueryHookResult = ReturnType<typeof useJobPortalInfoQuery>;
export type JobPortalInfoLazyQueryHookResult = ReturnType<typeof useJobPortalInfoLazyQuery>;
export type JobPortalInfoQueryResult = ApolloReactCommon.QueryResult<JobPortalInfoQuery, JobPortalInfoQueryVariables>;
export const JobPostingListByJobPortalDocument = gql`
    query JobPostingListByJobPortal($PortalToken: String!, $offset: Float, $limit: Float) {
  JobPostingListByJobPortal(PortalToken: $PortalToken, offset: $offset, limit: $limit)
}
    `;

/**
 * __useJobPostingListByJobPortalQuery__
 *
 * To run a query within a React component, call `useJobPostingListByJobPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListByJobPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListByJobPortalQuery({
 *   variables: {
 *      PortalToken: // value for 'PortalToken'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useJobPostingListByJobPortalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingListByJobPortalQuery, JobPostingListByJobPortalQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingListByJobPortalQuery, JobPostingListByJobPortalQueryVariables>(JobPostingListByJobPortalDocument, baseOptions);
      }
export function useJobPostingListByJobPortalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingListByJobPortalQuery, JobPostingListByJobPortalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingListByJobPortalQuery, JobPostingListByJobPortalQueryVariables>(JobPostingListByJobPortalDocument, baseOptions);
        }
export type JobPostingListByJobPortalQueryHookResult = ReturnType<typeof useJobPostingListByJobPortalQuery>;
export type JobPostingListByJobPortalLazyQueryHookResult = ReturnType<typeof useJobPostingListByJobPortalLazyQuery>;
export type JobPostingListByJobPortalQueryResult = ApolloReactCommon.QueryResult<JobPostingListByJobPortalQuery, JobPostingListByJobPortalQueryVariables>;
export const JobPostingListByJobPortalCountDocument = gql`
    query JobPostingListByJobPortalCount($PortalToken: String!) {
  JobPostingListByJobPortalCount(PortalToken: $PortalToken)
}
    `;

/**
 * __useJobPostingListByJobPortalCountQuery__
 *
 * To run a query within a React component, call `useJobPostingListByJobPortalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListByJobPortalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListByJobPortalCountQuery({
 *   variables: {
 *      PortalToken: // value for 'PortalToken'
 *   },
 * });
 */
export function useJobPostingListByJobPortalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingListByJobPortalCountQuery, JobPostingListByJobPortalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingListByJobPortalCountQuery, JobPostingListByJobPortalCountQueryVariables>(JobPostingListByJobPortalCountDocument, baseOptions);
      }
export function useJobPostingListByJobPortalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingListByJobPortalCountQuery, JobPostingListByJobPortalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingListByJobPortalCountQuery, JobPostingListByJobPortalCountQueryVariables>(JobPostingListByJobPortalCountDocument, baseOptions);
        }
export type JobPostingListByJobPortalCountQueryHookResult = ReturnType<typeof useJobPostingListByJobPortalCountQuery>;
export type JobPostingListByJobPortalCountLazyQueryHookResult = ReturnType<typeof useJobPostingListByJobPortalCountLazyQuery>;
export type JobPostingListByJobPortalCountQueryResult = ApolloReactCommon.QueryResult<JobPostingListByJobPortalCountQuery, JobPostingListByJobPortalCountQueryVariables>;
export const JobPostingInfoByJobPortalDocument = gql`
    query JobPostingInfoByJobPortal($JobPostingID: String!) {
  JobPostingInfoByJobPortal(JobPostingID: $JobPostingID)
}
    `;

/**
 * __useJobPostingInfoByJobPortalQuery__
 *
 * To run a query within a React component, call `useJobPostingInfoByJobPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingInfoByJobPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingInfoByJobPortalQuery({
 *   variables: {
 *      JobPostingID: // value for 'JobPostingID'
 *   },
 * });
 */
export function useJobPostingInfoByJobPortalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingInfoByJobPortalQuery, JobPostingInfoByJobPortalQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingInfoByJobPortalQuery, JobPostingInfoByJobPortalQueryVariables>(JobPostingInfoByJobPortalDocument, baseOptions);
      }
export function useJobPostingInfoByJobPortalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingInfoByJobPortalQuery, JobPostingInfoByJobPortalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingInfoByJobPortalQuery, JobPostingInfoByJobPortalQueryVariables>(JobPostingInfoByJobPortalDocument, baseOptions);
        }
export type JobPostingInfoByJobPortalQueryHookResult = ReturnType<typeof useJobPostingInfoByJobPortalQuery>;
export type JobPostingInfoByJobPortalLazyQueryHookResult = ReturnType<typeof useJobPostingInfoByJobPortalLazyQuery>;
export type JobPostingInfoByJobPortalQueryResult = ApolloReactCommon.QueryResult<JobPostingInfoByJobPortalQuery, JobPostingInfoByJobPortalQueryVariables>;
export const JobPortalTalentInsertDocument = gql`
    mutation JobPortalTalentInsert($input: ContactInput!, $portalToken: String!, $JobPostingID: String!) {
  JobPortalTalentInsert(input: $input, portalToken: $portalToken, JobPostingID: $JobPostingID)
}
    `;
export type JobPortalTalentInsertMutationFn = ApolloReactCommon.MutationFunction<JobPortalTalentInsertMutation, JobPortalTalentInsertMutationVariables>;

/**
 * __useJobPortalTalentInsertMutation__
 *
 * To run a mutation, you first call `useJobPortalTalentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPortalTalentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPortalTalentInsertMutation, { data, loading, error }] = useJobPortalTalentInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      portalToken: // value for 'portalToken'
 *      JobPostingID: // value for 'JobPostingID'
 *   },
 * });
 */
export function useJobPortalTalentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPortalTalentInsertMutation, JobPortalTalentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPortalTalentInsertMutation, JobPortalTalentInsertMutationVariables>(JobPortalTalentInsertDocument, baseOptions);
      }
export type JobPortalTalentInsertMutationHookResult = ReturnType<typeof useJobPortalTalentInsertMutation>;
export type JobPortalTalentInsertMutationResult = ApolloReactCommon.MutationResult<JobPortalTalentInsertMutation>;
export type JobPortalTalentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPortalTalentInsertMutation, JobPortalTalentInsertMutationVariables>;
export const TokenValidationDocument = gql`
    query TokenValidation($talentToken: String, $portalToken: String, $type: String) {
  TokenValidation(talentToken: $talentToken, portalToken: $portalToken, type: $type)
}
    `;

/**
 * __useTokenValidationQuery__
 *
 * To run a query within a React component, call `useTokenValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenValidationQuery({
 *   variables: {
 *      talentToken: // value for 'talentToken'
 *      portalToken: // value for 'portalToken'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTokenValidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TokenValidationQuery, TokenValidationQueryVariables>) {
        return ApolloReactHooks.useQuery<TokenValidationQuery, TokenValidationQueryVariables>(TokenValidationDocument, baseOptions);
      }
export function useTokenValidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TokenValidationQuery, TokenValidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TokenValidationQuery, TokenValidationQueryVariables>(TokenValidationDocument, baseOptions);
        }
export type TokenValidationQueryHookResult = ReturnType<typeof useTokenValidationQuery>;
export type TokenValidationLazyQueryHookResult = ReturnType<typeof useTokenValidationLazyQuery>;
export type TokenValidationQueryResult = ApolloReactCommon.QueryResult<TokenValidationQuery, TokenValidationQueryVariables>;
export const EApplicationListingDocument = gql`
    query EApplicationListing($SubscriptionAccountID: String!) {
  EApplicationListing(SubscriptionAccountID: $SubscriptionAccountID) {
    section_name
  }
}
    `;

/**
 * __useEApplicationListingQuery__
 *
 * To run a query within a React component, call `useEApplicationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEApplicationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEApplicationListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useEApplicationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EApplicationListingQuery, EApplicationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EApplicationListingQuery, EApplicationListingQueryVariables>(EApplicationListingDocument, baseOptions);
      }
export function useEApplicationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EApplicationListingQuery, EApplicationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EApplicationListingQuery, EApplicationListingQueryVariables>(EApplicationListingDocument, baseOptions);
        }
export type EApplicationListingQueryHookResult = ReturnType<typeof useEApplicationListingQuery>;
export type EApplicationListingLazyQueryHookResult = ReturnType<typeof useEApplicationListingLazyQuery>;
export type EApplicationListingQueryResult = ApolloReactCommon.QueryResult<EApplicationListingQuery, EApplicationListingQueryVariables>;
export const EApplicationSummaryDocument = gql`
    query EApplicationSummary($TalentPoolID: String, $SubscriptionAccountID: String!) {
  EApplicationSummary(TalentPoolID: $TalentPoolID, SubscriptionAccountID: $SubscriptionAccountID) {
    EApplicationList {
      section_name
      content
      min_record
      is_required
    }
    SummaryData
  }
}
    `;

/**
 * __useEApplicationSummaryQuery__
 *
 * To run a query within a React component, call `useEApplicationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEApplicationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEApplicationSummaryQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useEApplicationSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EApplicationSummaryQuery, EApplicationSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EApplicationSummaryQuery, EApplicationSummaryQueryVariables>(EApplicationSummaryDocument, baseOptions);
      }
export function useEApplicationSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EApplicationSummaryQuery, EApplicationSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EApplicationSummaryQuery, EApplicationSummaryQueryVariables>(EApplicationSummaryDocument, baseOptions);
        }
export type EApplicationSummaryQueryHookResult = ReturnType<typeof useEApplicationSummaryQuery>;
export type EApplicationSummaryLazyQueryHookResult = ReturnType<typeof useEApplicationSummaryLazyQuery>;
export type EApplicationSummaryQueryResult = ApolloReactCommon.QueryResult<EApplicationSummaryQuery, EApplicationSummaryQueryVariables>;
export const DataUpdateEApplicationSummaryDocument = gql`
    query DataUpdateEApplicationSummary($TalentPoolID: String, $SubscriptionAccountID: String!) {
  DataUpdateEApplicationSummary(TalentPoolID: $TalentPoolID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;

/**
 * __useDataUpdateEApplicationSummaryQuery__
 *
 * To run a query within a React component, call `useDataUpdateEApplicationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUpdateEApplicationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataUpdateEApplicationSummaryQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useDataUpdateEApplicationSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataUpdateEApplicationSummaryQuery, DataUpdateEApplicationSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DataUpdateEApplicationSummaryQuery, DataUpdateEApplicationSummaryQueryVariables>(DataUpdateEApplicationSummaryDocument, baseOptions);
      }
export function useDataUpdateEApplicationSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataUpdateEApplicationSummaryQuery, DataUpdateEApplicationSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataUpdateEApplicationSummaryQuery, DataUpdateEApplicationSummaryQueryVariables>(DataUpdateEApplicationSummaryDocument, baseOptions);
        }
export type DataUpdateEApplicationSummaryQueryHookResult = ReturnType<typeof useDataUpdateEApplicationSummaryQuery>;
export type DataUpdateEApplicationSummaryLazyQueryHookResult = ReturnType<typeof useDataUpdateEApplicationSummaryLazyQuery>;
export type DataUpdateEApplicationSummaryQueryResult = ApolloReactCommon.QueryResult<DataUpdateEApplicationSummaryQuery, DataUpdateEApplicationSummaryQueryVariables>;
export const EApplicationInsertDocument = gql`
    mutation EApplicationInsert($SubscriptionAccountID: String!, $userID: String!, $contactInput: ContactInput!, $documentInput: DocumentsInput, $talentInput: Rec_TalentPoolInput!, $educationInput: [PersonnelEducationInsertInput!]!, $membershipInput: [PersonnelMembershipInput!]!, $empHistoryInput: [PersonnelJobHistoryInput!]!, $languageInput: [PersonnelLanguageInput!]!, $referenceInput: [PersonnelReferenceInput!]!, $documentsInput: [DocumentsInput!]!, $type: String!, $JobPostingID: String) {
  EApplicationInsert(SubscriptionAccountID: $SubscriptionAccountID, userID: $userID, contactInput: $contactInput, documentInput: $documentInput, talentInput: $talentInput, educationInput: $educationInput, membershipInput: $membershipInput, empHistoryInput: $empHistoryInput, languageInput: $languageInput, referenceInput: $referenceInput, documentsInput: $documentsInput, type: $type, JobPostingID: $JobPostingID)
}
    `;
export type EApplicationInsertMutationFn = ApolloReactCommon.MutationFunction<EApplicationInsertMutation, EApplicationInsertMutationVariables>;

/**
 * __useEApplicationInsertMutation__
 *
 * To run a mutation, you first call `useEApplicationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEApplicationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eApplicationInsertMutation, { data, loading, error }] = useEApplicationInsertMutation({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      userID: // value for 'userID'
 *      contactInput: // value for 'contactInput'
 *      documentInput: // value for 'documentInput'
 *      talentInput: // value for 'talentInput'
 *      educationInput: // value for 'educationInput'
 *      membershipInput: // value for 'membershipInput'
 *      empHistoryInput: // value for 'empHistoryInput'
 *      languageInput: // value for 'languageInput'
 *      referenceInput: // value for 'referenceInput'
 *      documentsInput: // value for 'documentsInput'
 *      type: // value for 'type'
 *      JobPostingID: // value for 'JobPostingID'
 *   },
 * });
 */
export function useEApplicationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EApplicationInsertMutation, EApplicationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EApplicationInsertMutation, EApplicationInsertMutationVariables>(EApplicationInsertDocument, baseOptions);
      }
export type EApplicationInsertMutationHookResult = ReturnType<typeof useEApplicationInsertMutation>;
export type EApplicationInsertMutationResult = ApolloReactCommon.MutationResult<EApplicationInsertMutation>;
export type EApplicationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EApplicationInsertMutation, EApplicationInsertMutationVariables>;
export const DataUpdateEApplicationInsertDocument = gql`
    mutation DataUpdateEApplicationInsert($SubscriptionAccountID: String!, $userID: String!, $contactInput: ContactInput!, $documentInput: DocumentsInput, $talentInput: Rec_TalentPoolInput!, $employeeInput: EmployeeInput, $educationInput: [PersonnelEducationInsertInput!]!, $certificateInput: [PersonnelCertificateInput!]!, $empHistoryInput: [PersonnelJobHistoryInput!]!, $dependentInput: [EmployeeDependentsInput!]!, $referenceInput: [PersonnelReferenceInput!]!, $documentsInput: [DocumentsInput!]!, $statutoryInput: Rec_TalentStatutoryInput, $healthInput: PersonnelHealthInfoInput!, $attachmentToRemove: [DocumentsInput!], $medicalRecordInput: [MedicalRecordInput!]!, $vaccinationRecordInput: [VaccinationRecordInput!]!) {
  DataUpdateEApplicationInsert(SubscriptionAccountID: $SubscriptionAccountID, userID: $userID, contactInput: $contactInput, documentInput: $documentInput, talentInput: $talentInput, employeeInput: $employeeInput, educationInput: $educationInput, certificateInput: $certificateInput, empHistoryInput: $empHistoryInput, dependentInput: $dependentInput, referenceInput: $referenceInput, documentsInput: $documentsInput, statutoryInput: $statutoryInput, healthInput: $healthInput, attachmentToRemove: $attachmentToRemove, medicalRecordInput: $medicalRecordInput, vaccinationRecordInput: $vaccinationRecordInput)
}
    `;
export type DataUpdateEApplicationInsertMutationFn = ApolloReactCommon.MutationFunction<DataUpdateEApplicationInsertMutation, DataUpdateEApplicationInsertMutationVariables>;

/**
 * __useDataUpdateEApplicationInsertMutation__
 *
 * To run a mutation, you first call `useDataUpdateEApplicationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataUpdateEApplicationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataUpdateEApplicationInsertMutation, { data, loading, error }] = useDataUpdateEApplicationInsertMutation({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      userID: // value for 'userID'
 *      contactInput: // value for 'contactInput'
 *      documentInput: // value for 'documentInput'
 *      talentInput: // value for 'talentInput'
 *      employeeInput: // value for 'employeeInput'
 *      educationInput: // value for 'educationInput'
 *      certificateInput: // value for 'certificateInput'
 *      empHistoryInput: // value for 'empHistoryInput'
 *      dependentInput: // value for 'dependentInput'
 *      referenceInput: // value for 'referenceInput'
 *      documentsInput: // value for 'documentsInput'
 *      statutoryInput: // value for 'statutoryInput'
 *      healthInput: // value for 'healthInput'
 *      attachmentToRemove: // value for 'attachmentToRemove'
 *      medicalRecordInput: // value for 'medicalRecordInput'
 *      vaccinationRecordInput: // value for 'vaccinationRecordInput'
 *   },
 * });
 */
export function useDataUpdateEApplicationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataUpdateEApplicationInsertMutation, DataUpdateEApplicationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DataUpdateEApplicationInsertMutation, DataUpdateEApplicationInsertMutationVariables>(DataUpdateEApplicationInsertDocument, baseOptions);
      }
export type DataUpdateEApplicationInsertMutationHookResult = ReturnType<typeof useDataUpdateEApplicationInsertMutation>;
export type DataUpdateEApplicationInsertMutationResult = ApolloReactCommon.MutationResult<DataUpdateEApplicationInsertMutation>;
export type DataUpdateEApplicationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DataUpdateEApplicationInsertMutation, DataUpdateEApplicationInsertMutationVariables>;
export const RecruitmentParameterListingDocument = gql`
    query RecruitmentParameterListing($SubscriptionAccountID: String!, $type: [String!]!) {
  RecruitmentParameterListing(SubscriptionAccountID: $SubscriptionAccountID, type: $type) {
    PersonnelParameterID
    Name
    PersonnelParameterType
    IsDefault
  }
}
    `;

/**
 * __useRecruitmentParameterListingQuery__
 *
 * To run a query within a React component, call `useRecruitmentParameterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentParameterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentParameterListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRecruitmentParameterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentParameterListingQuery, RecruitmentParameterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentParameterListingQuery, RecruitmentParameterListingQueryVariables>(RecruitmentParameterListingDocument, baseOptions);
      }
export function useRecruitmentParameterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentParameterListingQuery, RecruitmentParameterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentParameterListingQuery, RecruitmentParameterListingQueryVariables>(RecruitmentParameterListingDocument, baseOptions);
        }
export type RecruitmentParameterListingQueryHookResult = ReturnType<typeof useRecruitmentParameterListingQuery>;
export type RecruitmentParameterListingLazyQueryHookResult = ReturnType<typeof useRecruitmentParameterListingLazyQuery>;
export type RecruitmentParameterListingQueryResult = ApolloReactCommon.QueryResult<RecruitmentParameterListingQuery, RecruitmentParameterListingQueryVariables>;
export const TalentUserDefineFieldsListingDocument = gql`
    query TalentUserDefineFieldsListing($SubscriptionAccountID: String!) {
  TalentUserDefineFieldsListing(SubscriptionAccountID: $SubscriptionAccountID) {
    rec_additional_user_field_id
    field_label
    is_required
    values
    is_active
    field_type
  }
}
    `;

/**
 * __useTalentUserDefineFieldsListingQuery__
 *
 * To run a query within a React component, call `useTalentUserDefineFieldsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentUserDefineFieldsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentUserDefineFieldsListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useTalentUserDefineFieldsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentUserDefineFieldsListingQuery, TalentUserDefineFieldsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentUserDefineFieldsListingQuery, TalentUserDefineFieldsListingQueryVariables>(TalentUserDefineFieldsListingDocument, baseOptions);
      }
export function useTalentUserDefineFieldsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentUserDefineFieldsListingQuery, TalentUserDefineFieldsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentUserDefineFieldsListingQuery, TalentUserDefineFieldsListingQueryVariables>(TalentUserDefineFieldsListingDocument, baseOptions);
        }
export type TalentUserDefineFieldsListingQueryHookResult = ReturnType<typeof useTalentUserDefineFieldsListingQuery>;
export type TalentUserDefineFieldsListingLazyQueryHookResult = ReturnType<typeof useTalentUserDefineFieldsListingLazyQuery>;
export type TalentUserDefineFieldsListingQueryResult = ApolloReactCommon.QueryResult<TalentUserDefineFieldsListingQuery, TalentUserDefineFieldsListingQueryVariables>;
export const DataUpdateTalentPersonalDocument = gql`
    query DataUpdateTalentPersonal($TalentPoolID: String!) {
  DataUpdateTalentPersonal(TalentPoolID: $TalentPoolID)
}
    `;

/**
 * __useDataUpdateTalentPersonalQuery__
 *
 * To run a query within a React component, call `useDataUpdateTalentPersonalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUpdateTalentPersonalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataUpdateTalentPersonalQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *   },
 * });
 */
export function useDataUpdateTalentPersonalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataUpdateTalentPersonalQuery, DataUpdateTalentPersonalQueryVariables>) {
        return ApolloReactHooks.useQuery<DataUpdateTalentPersonalQuery, DataUpdateTalentPersonalQueryVariables>(DataUpdateTalentPersonalDocument, baseOptions);
      }
export function useDataUpdateTalentPersonalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataUpdateTalentPersonalQuery, DataUpdateTalentPersonalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataUpdateTalentPersonalQuery, DataUpdateTalentPersonalQueryVariables>(DataUpdateTalentPersonalDocument, baseOptions);
        }
export type DataUpdateTalentPersonalQueryHookResult = ReturnType<typeof useDataUpdateTalentPersonalQuery>;
export type DataUpdateTalentPersonalLazyQueryHookResult = ReturnType<typeof useDataUpdateTalentPersonalLazyQuery>;
export type DataUpdateTalentPersonalQueryResult = ApolloReactCommon.QueryResult<DataUpdateTalentPersonalQuery, DataUpdateTalentPersonalQueryVariables>;
export const DataUpdateHealthInfoDocument = gql`
    query DataUpdateHealthInfo($ContactID: String!) {
  DataUpdateHealthInfo(ContactID: $ContactID) {
    PersonnelHealthInfoID
    Height
    Weight
    BMI
    BloodType
    DietaryPreference
    IsSmoker
    VaccinationList
    VaccinationListSummary {
      vaccinationID
      vaccinationDate
      vaccinationAttachedment {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
    MedicalRecords {
      MedicalRecordID
      DocumentID
      Description
      Document {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __useDataUpdateHealthInfoQuery__
 *
 * To run a query within a React component, call `useDataUpdateHealthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUpdateHealthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataUpdateHealthInfoQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useDataUpdateHealthInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataUpdateHealthInfoQuery, DataUpdateHealthInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<DataUpdateHealthInfoQuery, DataUpdateHealthInfoQueryVariables>(DataUpdateHealthInfoDocument, baseOptions);
      }
export function useDataUpdateHealthInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataUpdateHealthInfoQuery, DataUpdateHealthInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataUpdateHealthInfoQuery, DataUpdateHealthInfoQueryVariables>(DataUpdateHealthInfoDocument, baseOptions);
        }
export type DataUpdateHealthInfoQueryHookResult = ReturnType<typeof useDataUpdateHealthInfoQuery>;
export type DataUpdateHealthInfoLazyQueryHookResult = ReturnType<typeof useDataUpdateHealthInfoLazyQuery>;
export type DataUpdateHealthInfoQueryResult = ApolloReactCommon.QueryResult<DataUpdateHealthInfoQuery, DataUpdateHealthInfoQueryVariables>;
export const MyRecruitmentMenuSummaryDocument = gql`
    query MyRecruitmentMenuSummary($RequestorID: String) {
  MyRecruitmentMenuSummary(RequestorID: $RequestorID)
}
    `;

/**
 * __useMyRecruitmentMenuSummaryQuery__
 *
 * To run a query within a React component, call `useMyRecruitmentMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecruitmentMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecruitmentMenuSummaryQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *   },
 * });
 */
export function useMyRecruitmentMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecruitmentMenuSummaryQuery, MyRecruitmentMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecruitmentMenuSummaryQuery, MyRecruitmentMenuSummaryQueryVariables>(MyRecruitmentMenuSummaryDocument, baseOptions);
      }
export function useMyRecruitmentMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecruitmentMenuSummaryQuery, MyRecruitmentMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecruitmentMenuSummaryQuery, MyRecruitmentMenuSummaryQueryVariables>(MyRecruitmentMenuSummaryDocument, baseOptions);
        }
export type MyRecruitmentMenuSummaryQueryHookResult = ReturnType<typeof useMyRecruitmentMenuSummaryQuery>;
export type MyRecruitmentMenuSummaryLazyQueryHookResult = ReturnType<typeof useMyRecruitmentMenuSummaryLazyQuery>;
export type MyRecruitmentMenuSummaryQueryResult = ApolloReactCommon.QueryResult<MyRecruitmentMenuSummaryQuery, MyRecruitmentMenuSummaryQueryVariables>;
export const SubmitMyRecruitmentRequisitionDocument = gql`
    mutation SubmitMyRecruitmentRequisition($input: Rec_RequisitionInput!) {
  SubmitMyRecruitmentRequisition(input: $input)
}
    `;
export type SubmitMyRecruitmentRequisitionMutationFn = ApolloReactCommon.MutationFunction<SubmitMyRecruitmentRequisitionMutation, SubmitMyRecruitmentRequisitionMutationVariables>;

/**
 * __useSubmitMyRecruitmentRequisitionMutation__
 *
 * To run a mutation, you first call `useSubmitMyRecruitmentRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMyRecruitmentRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMyRecruitmentRequisitionMutation, { data, loading, error }] = useSubmitMyRecruitmentRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitMyRecruitmentRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitMyRecruitmentRequisitionMutation, SubmitMyRecruitmentRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitMyRecruitmentRequisitionMutation, SubmitMyRecruitmentRequisitionMutationVariables>(SubmitMyRecruitmentRequisitionDocument, baseOptions);
      }
export type SubmitMyRecruitmentRequisitionMutationHookResult = ReturnType<typeof useSubmitMyRecruitmentRequisitionMutation>;
export type SubmitMyRecruitmentRequisitionMutationResult = ApolloReactCommon.MutationResult<SubmitMyRecruitmentRequisitionMutation>;
export type SubmitMyRecruitmentRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitMyRecruitmentRequisitionMutation, SubmitMyRecruitmentRequisitionMutationVariables>;
export const MyRecRequisitionListingTotalCountDocument = gql`
    query MyRecRequisitionListingTotalCount($RequestorID: String, $EndDate: DateTime, $StartDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $ApprovalStatus: [String!], $keyword: String) {
  MyRecRequisitionListingTotalCount(RequestorID: $RequestorID, EndDate: $EndDate, StartDate: $StartDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, ApprovalStatus: $ApprovalStatus, keyword: $keyword)
}
    `;

/**
 * __useMyRecRequisitionListingTotalCountQuery__
 *
 * To run a query within a React component, call `useMyRecRequisitionListingTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecRequisitionListingTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecRequisitionListingTotalCountQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyRecRequisitionListingTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecRequisitionListingTotalCountQuery, MyRecRequisitionListingTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecRequisitionListingTotalCountQuery, MyRecRequisitionListingTotalCountQueryVariables>(MyRecRequisitionListingTotalCountDocument, baseOptions);
      }
export function useMyRecRequisitionListingTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecRequisitionListingTotalCountQuery, MyRecRequisitionListingTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecRequisitionListingTotalCountQuery, MyRecRequisitionListingTotalCountQueryVariables>(MyRecRequisitionListingTotalCountDocument, baseOptions);
        }
export type MyRecRequisitionListingTotalCountQueryHookResult = ReturnType<typeof useMyRecRequisitionListingTotalCountQuery>;
export type MyRecRequisitionListingTotalCountLazyQueryHookResult = ReturnType<typeof useMyRecRequisitionListingTotalCountLazyQuery>;
export type MyRecRequisitionListingTotalCountQueryResult = ApolloReactCommon.QueryResult<MyRecRequisitionListingTotalCountQuery, MyRecRequisitionListingTotalCountQueryVariables>;
export const MyRecRequisitionListingDocument = gql`
    query MyRecRequisitionListing($RequestorID: String, $EndDate: DateTime, $StartDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $ApprovalStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  MyRecRequisitionListing(RequestorID: $RequestorID, EndDate: $EndDate, StartDate: $StartDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, ApprovalStatus: $ApprovalStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_requisition_id
    org_unit_id
    org_unit_name
    job_designation_id
    job_designation_name
    requestor_id
    company_id
    requisition_no
    employee_type
    recruitment_type
    requested_headcount
    is_budget
    request_date
    required_date
    justification_remark
    job_description
    min_year_experience
    min_salary
    max_salary
    min_education_id
    min_education {
      PersonnelParameterID
      Name
    }
    attachment_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    justification_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    job_specification
    rec_requisition_status
    requestor {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
    }
    approval_log
    approval_remark
    approval_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    submitter_id
    submitter_name
    submitter_no
    created_dt
    modified_dt
    WorkFlowCurrentApproversList
  }
}
    `;

/**
 * __useMyRecRequisitionListingQuery__
 *
 * To run a query within a React component, call `useMyRecRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecRequisitionListingQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyRecRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecRequisitionListingQuery, MyRecRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecRequisitionListingQuery, MyRecRequisitionListingQueryVariables>(MyRecRequisitionListingDocument, baseOptions);
      }
export function useMyRecRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecRequisitionListingQuery, MyRecRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecRequisitionListingQuery, MyRecRequisitionListingQueryVariables>(MyRecRequisitionListingDocument, baseOptions);
        }
export type MyRecRequisitionListingQueryHookResult = ReturnType<typeof useMyRecRequisitionListingQuery>;
export type MyRecRequisitionListingLazyQueryHookResult = ReturnType<typeof useMyRecRequisitionListingLazyQuery>;
export type MyRecRequisitionListingQueryResult = ApolloReactCommon.QueryResult<MyRecRequisitionListingQuery, MyRecRequisitionListingQueryVariables>;
export const UpdateMyRecRequisitionApprovalStatusDocument = gql`
    mutation UpdateMyRecRequisitionApprovalStatus($input: Rec_RequisitionInput!) {
  UpdateMyRecRequisitionApprovalStatus(input: $input)
}
    `;
export type UpdateMyRecRequisitionApprovalStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateMyRecRequisitionApprovalStatusMutation, UpdateMyRecRequisitionApprovalStatusMutationVariables>;

/**
 * __useUpdateMyRecRequisitionApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMyRecRequisitionApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyRecRequisitionApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyRecRequisitionApprovalStatusMutation, { data, loading, error }] = useUpdateMyRecRequisitionApprovalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyRecRequisitionApprovalStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyRecRequisitionApprovalStatusMutation, UpdateMyRecRequisitionApprovalStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMyRecRequisitionApprovalStatusMutation, UpdateMyRecRequisitionApprovalStatusMutationVariables>(UpdateMyRecRequisitionApprovalStatusDocument, baseOptions);
      }
export type UpdateMyRecRequisitionApprovalStatusMutationHookResult = ReturnType<typeof useUpdateMyRecRequisitionApprovalStatusMutation>;
export type UpdateMyRecRequisitionApprovalStatusMutationResult = ApolloReactCommon.MutationResult<UpdateMyRecRequisitionApprovalStatusMutation>;
export type UpdateMyRecRequisitionApprovalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMyRecRequisitionApprovalStatusMutation, UpdateMyRecRequisitionApprovalStatusMutationVariables>;
export const MyRecInterviewListingDocument = gql`
    query MyRecInterviewListing($interviewer_id: String!, $interview_status: String, $jobPositionFilter: [String!], $orgUnitFilter: [String!], $keyword: String, $StartDate: DateTime, $EndDate: DateTime, $offset: Float, $limit: Float) {
  MyRecInterviewListing(interviewer_id: $interviewer_id, interview_status: $interview_status, jobPositionFilter: $jobPositionFilter, orgUnitFilter: $orgUnitFilter, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, offset: $offset, limit: $limit) {
    rec_applicant_id
    rec_interview_schedule_id
    applicant_name
    applicant_image
    job_designation_name
    org_unit_id
    company_id
    target_hiring_date
    requisition_no
    job_vacancy_no
    job_vacancy_status
    interview_date
    start_time
    end_time
    venue
    schedule_remark
    rec_interviewer_id
    interviewer_id
    is_attend
    rating
    is_recommend
    interviewer_remark
    attachment_id
    attachment_file
    attachment_file_name
    schedule_round
    org_unit_name
  }
}
    `;

/**
 * __useMyRecInterviewListingQuery__
 *
 * To run a query within a React component, call `useMyRecInterviewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecInterviewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecInterviewListingQuery({
 *   variables: {
 *      interviewer_id: // value for 'interviewer_id'
 *      interview_status: // value for 'interview_status'
 *      jobPositionFilter: // value for 'jobPositionFilter'
 *      orgUnitFilter: // value for 'orgUnitFilter'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMyRecInterviewListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecInterviewListingQuery, MyRecInterviewListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecInterviewListingQuery, MyRecInterviewListingQueryVariables>(MyRecInterviewListingDocument, baseOptions);
      }
export function useMyRecInterviewListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecInterviewListingQuery, MyRecInterviewListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecInterviewListingQuery, MyRecInterviewListingQueryVariables>(MyRecInterviewListingDocument, baseOptions);
        }
export type MyRecInterviewListingQueryHookResult = ReturnType<typeof useMyRecInterviewListingQuery>;
export type MyRecInterviewListingLazyQueryHookResult = ReturnType<typeof useMyRecInterviewListingLazyQuery>;
export type MyRecInterviewListingQueryResult = ApolloReactCommon.QueryResult<MyRecInterviewListingQuery, MyRecInterviewListingQueryVariables>;
export const MyRecInterviewListingCountDocument = gql`
    query MyRecInterviewListingCount($interviewer_id: String!, $interview_status: String, $jobPositionFilter: [String!], $orgUnitFilter: [String!], $keyword: String, $StartDate: DateTime, $EndDate: DateTime) {
  MyRecInterviewListingCount(interviewer_id: $interviewer_id, interview_status: $interview_status, jobPositionFilter: $jobPositionFilter, orgUnitFilter: $orgUnitFilter, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useMyRecInterviewListingCountQuery__
 *
 * To run a query within a React component, call `useMyRecInterviewListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecInterviewListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecInterviewListingCountQuery({
 *   variables: {
 *      interviewer_id: // value for 'interviewer_id'
 *      interview_status: // value for 'interview_status'
 *      jobPositionFilter: // value for 'jobPositionFilter'
 *      orgUnitFilter: // value for 'orgUnitFilter'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useMyRecInterviewListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyRecInterviewListingCountQuery, MyRecInterviewListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MyRecInterviewListingCountQuery, MyRecInterviewListingCountQueryVariables>(MyRecInterviewListingCountDocument, baseOptions);
      }
export function useMyRecInterviewListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyRecInterviewListingCountQuery, MyRecInterviewListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyRecInterviewListingCountQuery, MyRecInterviewListingCountQueryVariables>(MyRecInterviewListingCountDocument, baseOptions);
        }
export type MyRecInterviewListingCountQueryHookResult = ReturnType<typeof useMyRecInterviewListingCountQuery>;
export type MyRecInterviewListingCountLazyQueryHookResult = ReturnType<typeof useMyRecInterviewListingCountLazyQuery>;
export type MyRecInterviewListingCountQueryResult = ApolloReactCommon.QueryResult<MyRecInterviewListingCountQuery, MyRecInterviewListingCountQueryVariables>;
export const MyApprovedJobVacancyListingDocument = gql`
    query MyApprovedJobVacancyListing($RequestorID: String!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  MyApprovedJobVacancyListing(RequestorID: $RequestorID, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __useMyApprovedJobVacancyListingQuery__
 *
 * To run a query within a React component, call `useMyApprovedJobVacancyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApprovedJobVacancyListingQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyApprovedJobVacancyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApprovedJobVacancyListingQuery, MyApprovedJobVacancyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApprovedJobVacancyListingQuery, MyApprovedJobVacancyListingQueryVariables>(MyApprovedJobVacancyListingDocument, baseOptions);
      }
export function useMyApprovedJobVacancyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApprovedJobVacancyListingQuery, MyApprovedJobVacancyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApprovedJobVacancyListingQuery, MyApprovedJobVacancyListingQueryVariables>(MyApprovedJobVacancyListingDocument, baseOptions);
        }
export type MyApprovedJobVacancyListingQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyListingQuery>;
export type MyApprovedJobVacancyListingLazyQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyListingLazyQuery>;
export type MyApprovedJobVacancyListingQueryResult = ApolloReactCommon.QueryResult<MyApprovedJobVacancyListingQuery, MyApprovedJobVacancyListingQueryVariables>;
export const MyApprovedJobVacancyListingCountDocument = gql`
    query MyApprovedJobVacancyListingCount($RequestorID: String!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $keyword: String) {
  MyApprovedJobVacancyListingCount(RequestorID: $RequestorID, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, keyword: $keyword)
}
    `;

/**
 * __useMyApprovedJobVacancyListingCountQuery__
 *
 * To run a query within a React component, call `useMyApprovedJobVacancyListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApprovedJobVacancyListingCountQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyApprovedJobVacancyListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApprovedJobVacancyListingCountQuery, MyApprovedJobVacancyListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApprovedJobVacancyListingCountQuery, MyApprovedJobVacancyListingCountQueryVariables>(MyApprovedJobVacancyListingCountDocument, baseOptions);
      }
export function useMyApprovedJobVacancyListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApprovedJobVacancyListingCountQuery, MyApprovedJobVacancyListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApprovedJobVacancyListingCountQuery, MyApprovedJobVacancyListingCountQueryVariables>(MyApprovedJobVacancyListingCountDocument, baseOptions);
        }
export type MyApprovedJobVacancyListingCountQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyListingCountQuery>;
export type MyApprovedJobVacancyListingCountLazyQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyListingCountLazyQuery>;
export type MyApprovedJobVacancyListingCountQueryResult = ApolloReactCommon.QueryResult<MyApprovedJobVacancyListingCountQuery, MyApprovedJobVacancyListingCountQueryVariables>;
export const MyApprovedJobVacancyInfoDocument = gql`
    query MyApprovedJobVacancyInfo($JobVacancyID: String!) {
  MyApprovedJobVacancyInfo(JobVacancyID: $JobVacancyID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __useMyApprovedJobVacancyInfoQuery__
 *
 * To run a query within a React component, call `useMyApprovedJobVacancyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApprovedJobVacancyInfoQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *   },
 * });
 */
export function useMyApprovedJobVacancyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApprovedJobVacancyInfoQuery, MyApprovedJobVacancyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApprovedJobVacancyInfoQuery, MyApprovedJobVacancyInfoQueryVariables>(MyApprovedJobVacancyInfoDocument, baseOptions);
      }
export function useMyApprovedJobVacancyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApprovedJobVacancyInfoQuery, MyApprovedJobVacancyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApprovedJobVacancyInfoQuery, MyApprovedJobVacancyInfoQueryVariables>(MyApprovedJobVacancyInfoDocument, baseOptions);
        }
export type MyApprovedJobVacancyInfoQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyInfoQuery>;
export type MyApprovedJobVacancyInfoLazyQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyInfoLazyQuery>;
export type MyApprovedJobVacancyInfoQueryResult = ApolloReactCommon.QueryResult<MyApprovedJobVacancyInfoQuery, MyApprovedJobVacancyInfoQueryVariables>;
export const MyApprovedJobVacancyApplicantListingDocument = gql`
    query MyApprovedJobVacancyApplicantListing($JobVacancyID: String!, $keyword: String, $ApplicationStage: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $offset: Float, $limit: Float, $ApplicationStatus: RecruitmentApplicationStatus, $StartDate: DateTime, $EndDate: DateTime) {
  MyApprovedJobVacancyApplicantListing(JobVacancyID: $JobVacancyID, keyword: $keyword, ApplicationStage: $ApplicationStage, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, offset: $offset, limit: $limit, ApplicationStatus: $ApplicationStatus, StartDate: $StartDate, EndDate: $EndDate) {
    rec_applicant_id
    rec_talent_pool_id
    availability_date
    rec_job_posting_id
    application_stage
    application_status
    application_status_log
    applicant_count_by_stage
    interview_status
    rec_talent_pool {
      preferred_location
    }
    preferred_location
    contact {
      FullName
    }
    FullName
    Email
    DocumentID
    DocumentFile
    personnel_job_history {
      StartDate
      EndDate
      JobPosition
      CompanyName
      LastDrawnSalary
    }
    CompanyName
    StartDate
    EndDate
    JobPosition
    LastDrawnSalary
    IsCurrentJob
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    rec_requisition
    requisition_no
    org_unit_id
    company_id
    org_unit_name
    job_designation_id
    Name
    job_designation_name
  }
}
    `;

/**
 * __useMyApprovedJobVacancyApplicantListingQuery__
 *
 * To run a query within a React component, call `useMyApprovedJobVacancyApplicantListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyApplicantListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApprovedJobVacancyApplicantListingQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      ApplicationStage: // value for 'ApplicationStage'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useMyApprovedJobVacancyApplicantListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApprovedJobVacancyApplicantListingQuery, MyApprovedJobVacancyApplicantListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApprovedJobVacancyApplicantListingQuery, MyApprovedJobVacancyApplicantListingQueryVariables>(MyApprovedJobVacancyApplicantListingDocument, baseOptions);
      }
export function useMyApprovedJobVacancyApplicantListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApprovedJobVacancyApplicantListingQuery, MyApprovedJobVacancyApplicantListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApprovedJobVacancyApplicantListingQuery, MyApprovedJobVacancyApplicantListingQueryVariables>(MyApprovedJobVacancyApplicantListingDocument, baseOptions);
        }
export type MyApprovedJobVacancyApplicantListingQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyApplicantListingQuery>;
export type MyApprovedJobVacancyApplicantListingLazyQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyApplicantListingLazyQuery>;
export type MyApprovedJobVacancyApplicantListingQueryResult = ApolloReactCommon.QueryResult<MyApprovedJobVacancyApplicantListingQuery, MyApprovedJobVacancyApplicantListingQueryVariables>;
export const MyApprovedJobVacancyApplicantListingCountDocument = gql`
    query MyApprovedJobVacancyApplicantListingCount($JobVacancyID: String!, $keyword: String, $ApplicationStage: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $ApplicationStatus: RecruitmentApplicationStatus, $StartDate: DateTime, $EndDate: DateTime) {
  MyApprovedJobVacancyApplicantListingCount(JobVacancyID: $JobVacancyID, keyword: $keyword, ApplicationStage: $ApplicationStage, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, ApplicationStatus: $ApplicationStatus, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useMyApprovedJobVacancyApplicantListingCountQuery__
 *
 * To run a query within a React component, call `useMyApprovedJobVacancyApplicantListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyApplicantListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApprovedJobVacancyApplicantListingCountQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      ApplicationStage: // value for 'ApplicationStage'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useMyApprovedJobVacancyApplicantListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApprovedJobVacancyApplicantListingCountQuery, MyApprovedJobVacancyApplicantListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApprovedJobVacancyApplicantListingCountQuery, MyApprovedJobVacancyApplicantListingCountQueryVariables>(MyApprovedJobVacancyApplicantListingCountDocument, baseOptions);
      }
export function useMyApprovedJobVacancyApplicantListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApprovedJobVacancyApplicantListingCountQuery, MyApprovedJobVacancyApplicantListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApprovedJobVacancyApplicantListingCountQuery, MyApprovedJobVacancyApplicantListingCountQueryVariables>(MyApprovedJobVacancyApplicantListingCountDocument, baseOptions);
        }
export type MyApprovedJobVacancyApplicantListingCountQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyApplicantListingCountQuery>;
export type MyApprovedJobVacancyApplicantListingCountLazyQueryHookResult = ReturnType<typeof useMyApprovedJobVacancyApplicantListingCountLazyQuery>;
export type MyApprovedJobVacancyApplicantListingCountQueryResult = ApolloReactCommon.QueryResult<MyApprovedJobVacancyApplicantListingCountQuery, MyApprovedJobVacancyApplicantListingCountQueryVariables>;
export const MyApprovedJobVacancyApplicantStatusUpdateDocument = gql`
    mutation MyApprovedJobVacancyApplicantStatusUpdate($ApplicantIDs: [String!]!, $applicantLog: ApplicantStatusLog, $talentStatusLog: TalentStatusLog, $ApplicationStatus: RecruitmentApplicationStatus) {
  MyApprovedJobVacancyApplicantStatusUpdate(ApplicantIDs: $ApplicantIDs, applicantLog: $applicantLog, talentStatusLog: $talentStatusLog, ApplicationStatus: $ApplicationStatus)
}
    `;
export type MyApprovedJobVacancyApplicantStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<MyApprovedJobVacancyApplicantStatusUpdateMutation, MyApprovedJobVacancyApplicantStatusUpdateMutationVariables>;

/**
 * __useMyApprovedJobVacancyApplicantStatusUpdateMutation__
 *
 * To run a mutation, you first call `useMyApprovedJobVacancyApplicantStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyApprovedJobVacancyApplicantStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myApprovedJobVacancyApplicantStatusUpdateMutation, { data, loading, error }] = useMyApprovedJobVacancyApplicantStatusUpdateMutation({
 *   variables: {
 *      ApplicantIDs: // value for 'ApplicantIDs'
 *      applicantLog: // value for 'applicantLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *   },
 * });
 */
export function useMyApprovedJobVacancyApplicantStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MyApprovedJobVacancyApplicantStatusUpdateMutation, MyApprovedJobVacancyApplicantStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MyApprovedJobVacancyApplicantStatusUpdateMutation, MyApprovedJobVacancyApplicantStatusUpdateMutationVariables>(MyApprovedJobVacancyApplicantStatusUpdateDocument, baseOptions);
      }
export type MyApprovedJobVacancyApplicantStatusUpdateMutationHookResult = ReturnType<typeof useMyApprovedJobVacancyApplicantStatusUpdateMutation>;
export type MyApprovedJobVacancyApplicantStatusUpdateMutationResult = ApolloReactCommon.MutationResult<MyApprovedJobVacancyApplicantStatusUpdateMutation>;
export type MyApprovedJobVacancyApplicantStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MyApprovedJobVacancyApplicantStatusUpdateMutation, MyApprovedJobVacancyApplicantStatusUpdateMutationVariables>;
export const MyOfferListingDocument = gql`
    query MyOfferListing($RequestorID: String!, $OfferStatus: [RecruitmentOfferStatus!], $OfferResponseStatus: [RecruitmentOfferResponseStatus!], $OfferProcessStatus: RecruitmentOfferProcessStatus, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $offset: Float, $limit: Float, $keyword: String) {
  MyOfferListing(RequestorID: $RequestorID, OfferStatus: $OfferStatus, OfferResponseStatus: $OfferResponseStatus, OfferProcessStatus: $OfferProcessStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_offer_id
    target_hiring_date
    duty_date
    offer_status
    offer_count
    response_status
    offer_process_status
    availability_date
    rec_applicant_id
    expected_salary
    rec_talent_pool_id
    FullName
    DocumentFile
    rec_job_vacancy_id
    job_vacancy_no
    rec_requisition_id
    requisition_no
    company_id
    org_unit_name
    job_designation_id
    job_designation_name
  }
}
    `;

/**
 * __useMyOfferListingQuery__
 *
 * To run a query within a React component, call `useMyOfferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferListingQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      OfferStatus: // value for 'OfferStatus'
 *      OfferResponseStatus: // value for 'OfferResponseStatus'
 *      OfferProcessStatus: // value for 'OfferProcessStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyOfferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyOfferListingQuery, MyOfferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyOfferListingQuery, MyOfferListingQueryVariables>(MyOfferListingDocument, baseOptions);
      }
export function useMyOfferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyOfferListingQuery, MyOfferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyOfferListingQuery, MyOfferListingQueryVariables>(MyOfferListingDocument, baseOptions);
        }
export type MyOfferListingQueryHookResult = ReturnType<typeof useMyOfferListingQuery>;
export type MyOfferListingLazyQueryHookResult = ReturnType<typeof useMyOfferListingLazyQuery>;
export type MyOfferListingQueryResult = ApolloReactCommon.QueryResult<MyOfferListingQuery, MyOfferListingQueryVariables>;
export const MyOfferListingCountDocument = gql`
    query MyOfferListingCount($RequestorID: String!, $OfferStatus: [RecruitmentOfferStatus!], $OfferResponseStatus: [RecruitmentOfferResponseStatus!], $OfferProcessStatus: RecruitmentOfferProcessStatus, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $keyword: String) {
  MyOfferListingCount(RequestorID: $RequestorID, OfferStatus: $OfferStatus, OfferResponseStatus: $OfferResponseStatus, OfferProcessStatus: $OfferProcessStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, keyword: $keyword)
}
    `;

/**
 * __useMyOfferListingCountQuery__
 *
 * To run a query within a React component, call `useMyOfferListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferListingCountQuery({
 *   variables: {
 *      RequestorID: // value for 'RequestorID'
 *      OfferStatus: // value for 'OfferStatus'
 *      OfferResponseStatus: // value for 'OfferResponseStatus'
 *      OfferProcessStatus: // value for 'OfferProcessStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useMyOfferListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyOfferListingCountQuery, MyOfferListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MyOfferListingCountQuery, MyOfferListingCountQueryVariables>(MyOfferListingCountDocument, baseOptions);
      }
export function useMyOfferListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyOfferListingCountQuery, MyOfferListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyOfferListingCountQuery, MyOfferListingCountQueryVariables>(MyOfferListingCountDocument, baseOptions);
        }
export type MyOfferListingCountQueryHookResult = ReturnType<typeof useMyOfferListingCountQuery>;
export type MyOfferListingCountLazyQueryHookResult = ReturnType<typeof useMyOfferListingCountLazyQuery>;
export type MyOfferListingCountQueryResult = ApolloReactCommon.QueryResult<MyOfferListingCountQuery, MyOfferListingCountQueryVariables>;
export const MyOfferDetailDocument = gql`
    query MyOfferDetail($ApplicantID: String!) {
  MyOfferDetail(ApplicantID: $ApplicantID) {
    salary
    probation_month
    duty_date
    offer_remark
    offer_attachment_file {
      DocumentFile
    }
    submit_date
    offer_status
    response_status
    response_date
    response_remark
    rec_decline_reason {
      reason
    }
    response_attachment_file {
      DocumentFile
    }
    approval_remark
  }
}
    `;

/**
 * __useMyOfferDetailQuery__
 *
 * To run a query within a React component, call `useMyOfferDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferDetailQuery({
 *   variables: {
 *      ApplicantID: // value for 'ApplicantID'
 *   },
 * });
 */
export function useMyOfferDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyOfferDetailQuery, MyOfferDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<MyOfferDetailQuery, MyOfferDetailQueryVariables>(MyOfferDetailDocument, baseOptions);
      }
export function useMyOfferDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyOfferDetailQuery, MyOfferDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyOfferDetailQuery, MyOfferDetailQueryVariables>(MyOfferDetailDocument, baseOptions);
        }
export type MyOfferDetailQueryHookResult = ReturnType<typeof useMyOfferDetailQuery>;
export type MyOfferDetailLazyQueryHookResult = ReturnType<typeof useMyOfferDetailLazyQuery>;
export type MyOfferDetailQueryResult = ApolloReactCommon.QueryResult<MyOfferDetailQuery, MyOfferDetailQueryVariables>;
export const MyTalentSearchApplicationListingDocument = gql`
    query MyTalentSearchApplicationListing($TalentPoolID: String!, $RequestorID: String!) {
  MyTalentSearchApplicationListing(TalentPoolID: $TalentPoolID, RequestorID: $RequestorID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_name
    org_unit_name
    application_stage
    has_access
  }
}
    `;

/**
 * __useMyTalentSearchApplicationListingQuery__
 *
 * To run a query within a React component, call `useMyTalentSearchApplicationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTalentSearchApplicationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTalentSearchApplicationListingQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      RequestorID: // value for 'RequestorID'
 *   },
 * });
 */
export function useMyTalentSearchApplicationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyTalentSearchApplicationListingQuery, MyTalentSearchApplicationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyTalentSearchApplicationListingQuery, MyTalentSearchApplicationListingQueryVariables>(MyTalentSearchApplicationListingDocument, baseOptions);
      }
export function useMyTalentSearchApplicationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyTalentSearchApplicationListingQuery, MyTalentSearchApplicationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyTalentSearchApplicationListingQuery, MyTalentSearchApplicationListingQueryVariables>(MyTalentSearchApplicationListingDocument, baseOptions);
        }
export type MyTalentSearchApplicationListingQueryHookResult = ReturnType<typeof useMyTalentSearchApplicationListingQuery>;
export type MyTalentSearchApplicationListingLazyQueryHookResult = ReturnType<typeof useMyTalentSearchApplicationListingLazyQuery>;
export type MyTalentSearchApplicationListingQueryResult = ApolloReactCommon.QueryResult<MyTalentSearchApplicationListingQuery, MyTalentSearchApplicationListingQueryVariables>;
export const GetAllCoOrgUnitListBySubscriptionDocument = gql`
    query GetAllCoOrgUnitListBySubscription {
  GetAllCoOrgUnitListBySubscription
}
    `;

/**
 * __useGetAllCoOrgUnitListBySubscriptionQuery__
 *
 * To run a query within a React component, call `useGetAllCoOrgUnitListBySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoOrgUnitListBySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoOrgUnitListBySubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCoOrgUnitListBySubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCoOrgUnitListBySubscriptionQuery, GetAllCoOrgUnitListBySubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCoOrgUnitListBySubscriptionQuery, GetAllCoOrgUnitListBySubscriptionQueryVariables>(GetAllCoOrgUnitListBySubscriptionDocument, baseOptions);
      }
export function useGetAllCoOrgUnitListBySubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCoOrgUnitListBySubscriptionQuery, GetAllCoOrgUnitListBySubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCoOrgUnitListBySubscriptionQuery, GetAllCoOrgUnitListBySubscriptionQueryVariables>(GetAllCoOrgUnitListBySubscriptionDocument, baseOptions);
        }
export type GetAllCoOrgUnitListBySubscriptionQueryHookResult = ReturnType<typeof useGetAllCoOrgUnitListBySubscriptionQuery>;
export type GetAllCoOrgUnitListBySubscriptionLazyQueryHookResult = ReturnType<typeof useGetAllCoOrgUnitListBySubscriptionLazyQuery>;
export type GetAllCoOrgUnitListBySubscriptionQueryResult = ApolloReactCommon.QueryResult<GetAllCoOrgUnitListBySubscriptionQuery, GetAllCoOrgUnitListBySubscriptionQueryVariables>;
export const AdminRecruitmentMenuSummaryDocument = gql`
    query AdminRecruitmentMenuSummary {
  AdminRecruitmentMenuSummary
}
    `;

/**
 * __useAdminRecruitmentMenuSummaryQuery__
 *
 * To run a query within a React component, call `useAdminRecruitmentMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRecruitmentMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRecruitmentMenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminRecruitmentMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminRecruitmentMenuSummaryQuery, AdminRecruitmentMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminRecruitmentMenuSummaryQuery, AdminRecruitmentMenuSummaryQueryVariables>(AdminRecruitmentMenuSummaryDocument, baseOptions);
      }
export function useAdminRecruitmentMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminRecruitmentMenuSummaryQuery, AdminRecruitmentMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminRecruitmentMenuSummaryQuery, AdminRecruitmentMenuSummaryQueryVariables>(AdminRecruitmentMenuSummaryDocument, baseOptions);
        }
export type AdminRecruitmentMenuSummaryQueryHookResult = ReturnType<typeof useAdminRecruitmentMenuSummaryQuery>;
export type AdminRecruitmentMenuSummaryLazyQueryHookResult = ReturnType<typeof useAdminRecruitmentMenuSummaryLazyQuery>;
export type AdminRecruitmentMenuSummaryQueryResult = ApolloReactCommon.QueryResult<AdminRecruitmentMenuSummaryQuery, AdminRecruitmentMenuSummaryQueryVariables>;
export const SettingSummaryDocument = gql`
    query SettingSummary {
  SettingSummary
}
    `;

/**
 * __useSettingSummaryQuery__
 *
 * To run a query within a React component, call `useSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingSummaryQuery, SettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SettingSummaryQuery, SettingSummaryQueryVariables>(SettingSummaryDocument, baseOptions);
      }
export function useSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingSummaryQuery, SettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SettingSummaryQuery, SettingSummaryQueryVariables>(SettingSummaryDocument, baseOptions);
        }
export type SettingSummaryQueryHookResult = ReturnType<typeof useSettingSummaryQuery>;
export type SettingSummaryLazyQueryHookResult = ReturnType<typeof useSettingSummaryLazyQuery>;
export type SettingSummaryQueryResult = ApolloReactCommon.QueryResult<SettingSummaryQuery, SettingSummaryQueryVariables>;
export const TalentPoolSummaryDocument = gql`
    query TalentPoolSummary($employeeID: String) {
  TalentPoolSummary(employeeID: $employeeID)
}
    `;

/**
 * __useTalentPoolSummaryQuery__
 *
 * To run a query within a React component, call `useTalentPoolSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentPoolSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentPoolSummaryQuery({
 *   variables: {
 *      employeeID: // value for 'employeeID'
 *   },
 * });
 */
export function useTalentPoolSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentPoolSummaryQuery, TalentPoolSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentPoolSummaryQuery, TalentPoolSummaryQueryVariables>(TalentPoolSummaryDocument, baseOptions);
      }
export function useTalentPoolSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentPoolSummaryQuery, TalentPoolSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentPoolSummaryQuery, TalentPoolSummaryQueryVariables>(TalentPoolSummaryDocument, baseOptions);
        }
export type TalentPoolSummaryQueryHookResult = ReturnType<typeof useTalentPoolSummaryQuery>;
export type TalentPoolSummaryLazyQueryHookResult = ReturnType<typeof useTalentPoolSummaryLazyQuery>;
export type TalentPoolSummaryQueryResult = ApolloReactCommon.QueryResult<TalentPoolSummaryQuery, TalentPoolSummaryQueryVariables>;
export const JobVacancyManagementSummaryDocument = gql`
    query JobVacancyManagementSummary {
  JobVacancyManagementSummary
}
    `;

/**
 * __useJobVacancyManagementSummaryQuery__
 *
 * To run a query within a React component, call `useJobVacancyManagementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyManagementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyManagementSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobVacancyManagementSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyManagementSummaryQuery, JobVacancyManagementSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyManagementSummaryQuery, JobVacancyManagementSummaryQueryVariables>(JobVacancyManagementSummaryDocument, baseOptions);
      }
export function useJobVacancyManagementSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyManagementSummaryQuery, JobVacancyManagementSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyManagementSummaryQuery, JobVacancyManagementSummaryQueryVariables>(JobVacancyManagementSummaryDocument, baseOptions);
        }
export type JobVacancyManagementSummaryQueryHookResult = ReturnType<typeof useJobVacancyManagementSummaryQuery>;
export type JobVacancyManagementSummaryLazyQueryHookResult = ReturnType<typeof useJobVacancyManagementSummaryLazyQuery>;
export type JobVacancyManagementSummaryQueryResult = ApolloReactCommon.QueryResult<JobVacancyManagementSummaryQuery, JobVacancyManagementSummaryQueryVariables>;
export const JobOfferManagementSummaryDocument = gql`
    query JobOfferManagementSummary {
  JobOfferManagementSummary
}
    `;

/**
 * __useJobOfferManagementSummaryQuery__
 *
 * To run a query within a React component, call `useJobOfferManagementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOfferManagementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOfferManagementSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobOfferManagementSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobOfferManagementSummaryQuery, JobOfferManagementSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<JobOfferManagementSummaryQuery, JobOfferManagementSummaryQueryVariables>(JobOfferManagementSummaryDocument, baseOptions);
      }
export function useJobOfferManagementSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobOfferManagementSummaryQuery, JobOfferManagementSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobOfferManagementSummaryQuery, JobOfferManagementSummaryQueryVariables>(JobOfferManagementSummaryDocument, baseOptions);
        }
export type JobOfferManagementSummaryQueryHookResult = ReturnType<typeof useJobOfferManagementSummaryQuery>;
export type JobOfferManagementSummaryLazyQueryHookResult = ReturnType<typeof useJobOfferManagementSummaryLazyQuery>;
export type JobOfferManagementSummaryQueryResult = ApolloReactCommon.QueryResult<JobOfferManagementSummaryQuery, JobOfferManagementSummaryQueryVariables>;
export const HiringProcessSummaryDocument = gql`
    query HiringProcessSummary {
  HiringProcessSummary
}
    `;

/**
 * __useHiringProcessSummaryQuery__
 *
 * To run a query within a React component, call `useHiringProcessSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringProcessSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringProcessSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useHiringProcessSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HiringProcessSummaryQuery, HiringProcessSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<HiringProcessSummaryQuery, HiringProcessSummaryQueryVariables>(HiringProcessSummaryDocument, baseOptions);
      }
export function useHiringProcessSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HiringProcessSummaryQuery, HiringProcessSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HiringProcessSummaryQuery, HiringProcessSummaryQueryVariables>(HiringProcessSummaryDocument, baseOptions);
        }
export type HiringProcessSummaryQueryHookResult = ReturnType<typeof useHiringProcessSummaryQuery>;
export type HiringProcessSummaryLazyQueryHookResult = ReturnType<typeof useHiringProcessSummaryLazyQuery>;
export type HiringProcessSummaryQueryResult = ApolloReactCommon.QueryResult<HiringProcessSummaryQuery, HiringProcessSummaryQueryVariables>;
export const SubmitRecruitmentRequisitionDocument = gql`
    mutation SubmitRecruitmentRequisition($input: Rec_RequisitionInput!) {
  SubmitRecruitmentRequisition(input: $input)
}
    `;
export type SubmitRecruitmentRequisitionMutationFn = ApolloReactCommon.MutationFunction<SubmitRecruitmentRequisitionMutation, SubmitRecruitmentRequisitionMutationVariables>;

/**
 * __useSubmitRecruitmentRequisitionMutation__
 *
 * To run a mutation, you first call `useSubmitRecruitmentRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRecruitmentRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRecruitmentRequisitionMutation, { data, loading, error }] = useSubmitRecruitmentRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRecruitmentRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRecruitmentRequisitionMutation, SubmitRecruitmentRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRecruitmentRequisitionMutation, SubmitRecruitmentRequisitionMutationVariables>(SubmitRecruitmentRequisitionDocument, baseOptions);
      }
export type SubmitRecruitmentRequisitionMutationHookResult = ReturnType<typeof useSubmitRecruitmentRequisitionMutation>;
export type SubmitRecruitmentRequisitionMutationResult = ApolloReactCommon.MutationResult<SubmitRecruitmentRequisitionMutation>;
export type SubmitRecruitmentRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRecruitmentRequisitionMutation, SubmitRecruitmentRequisitionMutationVariables>;
export const RecRequisitionListingTotalCountDocument = gql`
    query RecRequisitionListingTotalCount($EndDate: DateTime, $StartDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $ApprovalStatus: [String!], $keyword: String) {
  RecRequisitionListingTotalCount(EndDate: $EndDate, StartDate: $StartDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, ApprovalStatus: $ApprovalStatus, keyword: $keyword)
}
    `;

/**
 * __useRecRequisitionListingTotalCountQuery__
 *
 * To run a query within a React component, call `useRecRequisitionListingTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecRequisitionListingTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecRequisitionListingTotalCountQuery({
 *   variables: {
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useRecRequisitionListingTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecRequisitionListingTotalCountQuery, RecRequisitionListingTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<RecRequisitionListingTotalCountQuery, RecRequisitionListingTotalCountQueryVariables>(RecRequisitionListingTotalCountDocument, baseOptions);
      }
export function useRecRequisitionListingTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecRequisitionListingTotalCountQuery, RecRequisitionListingTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecRequisitionListingTotalCountQuery, RecRequisitionListingTotalCountQueryVariables>(RecRequisitionListingTotalCountDocument, baseOptions);
        }
export type RecRequisitionListingTotalCountQueryHookResult = ReturnType<typeof useRecRequisitionListingTotalCountQuery>;
export type RecRequisitionListingTotalCountLazyQueryHookResult = ReturnType<typeof useRecRequisitionListingTotalCountLazyQuery>;
export type RecRequisitionListingTotalCountQueryResult = ApolloReactCommon.QueryResult<RecRequisitionListingTotalCountQuery, RecRequisitionListingTotalCountQueryVariables>;
export const RecRequisitionListingDocument = gql`
    query RecRequisitionListing($EndDate: DateTime, $StartDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $ApprovalStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  RecRequisitionListing(EndDate: $EndDate, StartDate: $StartDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, ApprovalStatus: $ApprovalStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_requisition_id
    org_unit_id
    org_unit_name
    job_designation_id
    job_designation_name
    requestor_id
    company_id
    requisition_no
    employee_type
    recruitment_type
    requested_headcount
    is_budget
    request_date
    required_date
    justification_remark
    job_description
    min_year_experience
    min_salary
    max_salary
    min_education_id
    min_education {
      PersonnelParameterID
      Name
    }
    attachment_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    justification_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    job_specification
    rec_requisition_status
    requestor {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
    }
    approval_log
    approval_remark
    approval_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    submitter_id
    submitter_name
    submitter_no
    created_dt
    modified_dt
    WorkFlowCurrentApproversList
  }
}
    `;

/**
 * __useRecRequisitionListingQuery__
 *
 * To run a query within a React component, call `useRecRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecRequisitionListingQuery({
 *   variables: {
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useRecRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecRequisitionListingQuery, RecRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecRequisitionListingQuery, RecRequisitionListingQueryVariables>(RecRequisitionListingDocument, baseOptions);
      }
export function useRecRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecRequisitionListingQuery, RecRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecRequisitionListingQuery, RecRequisitionListingQueryVariables>(RecRequisitionListingDocument, baseOptions);
        }
export type RecRequisitionListingQueryHookResult = ReturnType<typeof useRecRequisitionListingQuery>;
export type RecRequisitionListingLazyQueryHookResult = ReturnType<typeof useRecRequisitionListingLazyQuery>;
export type RecRequisitionListingQueryResult = ApolloReactCommon.QueryResult<RecRequisitionListingQuery, RecRequisitionListingQueryVariables>;
export const GetRecRequisitionInfoDocument = gql`
    query GetRecRequisitionInfo($RecRequisitionID: String!) {
  GetRecRequisitionInfo(RecRequisitionID: $RecRequisitionID) {
    rec_requisition_id
    org_unit_id
    org_unit_name
    job_designation_id
    job_designation_name
    requestor_id
    company_id
    requisition_no
    employee_type
    recruitment_type
    requested_headcount
    is_budget
    request_date
    required_date
    justification_remark
    job_description
    min_year_experience
    min_salary
    max_salary
    min_education_id
    min_education {
      PersonnelParameterID
      Name
    }
    attachment_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    justification_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    job_specification
    rec_requisition_status
    requestor {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
    }
    approval_log
    approval_remark
    approval_remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __useGetRecRequisitionInfoQuery__
 *
 * To run a query within a React component, call `useGetRecRequisitionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecRequisitionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecRequisitionInfoQuery({
 *   variables: {
 *      RecRequisitionID: // value for 'RecRequisitionID'
 *   },
 * });
 */
export function useGetRecRequisitionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecRequisitionInfoQuery, GetRecRequisitionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecRequisitionInfoQuery, GetRecRequisitionInfoQueryVariables>(GetRecRequisitionInfoDocument, baseOptions);
      }
export function useGetRecRequisitionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecRequisitionInfoQuery, GetRecRequisitionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecRequisitionInfoQuery, GetRecRequisitionInfoQueryVariables>(GetRecRequisitionInfoDocument, baseOptions);
        }
export type GetRecRequisitionInfoQueryHookResult = ReturnType<typeof useGetRecRequisitionInfoQuery>;
export type GetRecRequisitionInfoLazyQueryHookResult = ReturnType<typeof useGetRecRequisitionInfoLazyQuery>;
export type GetRecRequisitionInfoQueryResult = ApolloReactCommon.QueryResult<GetRecRequisitionInfoQuery, GetRecRequisitionInfoQueryVariables>;
export const DeleteRecRequisitionDocument = gql`
    mutation DeleteRecRequisition($RecRequisitionID: String!) {
  DeleteRecRequisition(RecRequisitionID: $RecRequisitionID)
}
    `;
export type DeleteRecRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteRecRequisitionMutation, DeleteRecRequisitionMutationVariables>;

/**
 * __useDeleteRecRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteRecRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecRequisitionMutation, { data, loading, error }] = useDeleteRecRequisitionMutation({
 *   variables: {
 *      RecRequisitionID: // value for 'RecRequisitionID'
 *   },
 * });
 */
export function useDeleteRecRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecRequisitionMutation, DeleteRecRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRecRequisitionMutation, DeleteRecRequisitionMutationVariables>(DeleteRecRequisitionDocument, baseOptions);
      }
export type DeleteRecRequisitionMutationHookResult = ReturnType<typeof useDeleteRecRequisitionMutation>;
export type DeleteRecRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteRecRequisitionMutation>;
export type DeleteRecRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecRequisitionMutation, DeleteRecRequisitionMutationVariables>;
export const UpdateRecRequisitionApprovalStatusDocument = gql`
    mutation UpdateRecRequisitionApprovalStatus($input: Rec_RequisitionInput!) {
  UpdateRecRequisitionApprovalStatus(input: $input)
}
    `;
export type UpdateRecRequisitionApprovalStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateRecRequisitionApprovalStatusMutation, UpdateRecRequisitionApprovalStatusMutationVariables>;

/**
 * __useUpdateRecRequisitionApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRecRequisitionApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecRequisitionApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecRequisitionApprovalStatusMutation, { data, loading, error }] = useUpdateRecRequisitionApprovalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecRequisitionApprovalStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecRequisitionApprovalStatusMutation, UpdateRecRequisitionApprovalStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecRequisitionApprovalStatusMutation, UpdateRecRequisitionApprovalStatusMutationVariables>(UpdateRecRequisitionApprovalStatusDocument, baseOptions);
      }
export type UpdateRecRequisitionApprovalStatusMutationHookResult = ReturnType<typeof useUpdateRecRequisitionApprovalStatusMutation>;
export type UpdateRecRequisitionApprovalStatusMutationResult = ApolloReactCommon.MutationResult<UpdateRecRequisitionApprovalStatusMutation>;
export type UpdateRecRequisitionApprovalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecRequisitionApprovalStatusMutation, UpdateRecRequisitionApprovalStatusMutationVariables>;
export const SimpleCompanyListingDocument = gql`
    query SimpleCompanyListing($SubscriptionAccountID: String!, $CompanyIDs: [String!]) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID, CompanyIDs: $CompanyIDs) {
    CompanyID
    CompanyName
  }
}
    `;

/**
 * __useSimpleCompanyListingQuery__
 *
 * To run a query within a React component, call `useSimpleCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useSimpleCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
      }
export function useSimpleCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
        }
export type SimpleCompanyListingQueryHookResult = ReturnType<typeof useSimpleCompanyListingQuery>;
export type SimpleCompanyListingLazyQueryHookResult = ReturnType<typeof useSimpleCompanyListingLazyQuery>;
export type SimpleCompanyListingQueryResult = ApolloReactCommon.QueryResult<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>;
export const CompanyOrgUnitByCompanyIdArrayDocument = gql`
    query CompanyOrgUnitByCompanyIDArray($CompanyIDArray: [String!]) {
  CompanyOrgUnitList(CompanyIDArray: $CompanyIDArray)
}
    `;

/**
 * __useCompanyOrgUnitByCompanyIdArrayQuery__
 *
 * To run a query within a React component, call `useCompanyOrgUnitByCompanyIdArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitByCompanyIdArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOrgUnitByCompanyIdArrayQuery({
 *   variables: {
 *      CompanyIDArray: // value for 'CompanyIDArray'
 *   },
 * });
 */
export function useCompanyOrgUnitByCompanyIdArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
      }
export function useCompanyOrgUnitByCompanyIdArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
        }
export type CompanyOrgUnitByCompanyIdArrayQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayQuery>;
export type CompanyOrgUnitByCompanyIdArrayLazyQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayLazyQuery>;
export type CompanyOrgUnitByCompanyIdArrayQueryResult = ApolloReactCommon.QueryResult<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>;
export const JobDesignationListingDocument = gql`
    query JobDesignationListing($SubscriptionAccountID: String!) {
  JobDesignationListing(SubscriptionAccountID: $SubscriptionAccountID) {
    JobDesignationID
    SubscriptionAccountID
    Name
    IsAdmin
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Employees {
      Status
      DesignationID
      EmployeeID
    }
    JobDesignationGrades {
      JobGradeID
    }
  }
}
    `;

/**
 * __useJobDesignationListingQuery__
 *
 * To run a query within a React component, call `useJobDesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDesignationListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobDesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
      }
export function useJobDesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
        }
export type JobDesignationListingQueryHookResult = ReturnType<typeof useJobDesignationListingQuery>;
export type JobDesignationListingLazyQueryHookResult = ReturnType<typeof useJobDesignationListingLazyQuery>;
export type JobDesignationListingQueryResult = ApolloReactCommon.QueryResult<JobDesignationListingQuery, JobDesignationListingQueryVariables>;
export const RecRequisitionNotInUseListingDocument = gql`
    query RecRequisitionNotInUseListing($ApprovalStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  RecRequisitionNotInUseListing(ApprovalStatus: $ApprovalStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_requisition_id
    org_unit_id
    org_unit_name
    job_designation_id
    job_designation_name
    requestor_id
    requisition_no
    is_budget
    request_date
    required_date
    rec_requisition_status
    requestor {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
    }
  }
  RecRequisitionNotInUseListingCount(ApprovalStatus: $ApprovalStatus, keyword: $keyword)
}
    `;

/**
 * __useRecRequisitionNotInUseListingQuery__
 *
 * To run a query within a React component, call `useRecRequisitionNotInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecRequisitionNotInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecRequisitionNotInUseListingQuery({
 *   variables: {
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useRecRequisitionNotInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecRequisitionNotInUseListingQuery, RecRequisitionNotInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecRequisitionNotInUseListingQuery, RecRequisitionNotInUseListingQueryVariables>(RecRequisitionNotInUseListingDocument, baseOptions);
      }
export function useRecRequisitionNotInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecRequisitionNotInUseListingQuery, RecRequisitionNotInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecRequisitionNotInUseListingQuery, RecRequisitionNotInUseListingQueryVariables>(RecRequisitionNotInUseListingDocument, baseOptions);
        }
export type RecRequisitionNotInUseListingQueryHookResult = ReturnType<typeof useRecRequisitionNotInUseListingQuery>;
export type RecRequisitionNotInUseListingLazyQueryHookResult = ReturnType<typeof useRecRequisitionNotInUseListingLazyQuery>;
export type RecRequisitionNotInUseListingQueryResult = ApolloReactCommon.QueryResult<RecRequisitionNotInUseListingQuery, RecRequisitionNotInUseListingQueryVariables>;
export const JobVacancyListingDocument = gql`
    query JobVacancyListing($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  JobVacancyListing(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    location
    target_hiring_date
    job_vacancy_status_log
    total_amount
    requisition_no
    requested_headcount
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
    in_use
  }
}
    `;

/**
 * __useJobVacancyListingQuery__
 *
 * To run a query within a React component, call `useJobVacancyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobVacancyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyListingQuery, JobVacancyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyListingQuery, JobVacancyListingQueryVariables>(JobVacancyListingDocument, baseOptions);
      }
export function useJobVacancyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyListingQuery, JobVacancyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyListingQuery, JobVacancyListingQueryVariables>(JobVacancyListingDocument, baseOptions);
        }
export type JobVacancyListingQueryHookResult = ReturnType<typeof useJobVacancyListingQuery>;
export type JobVacancyListingLazyQueryHookResult = ReturnType<typeof useJobVacancyListingLazyQuery>;
export type JobVacancyListingQueryResult = ApolloReactCommon.QueryResult<JobVacancyListingQuery, JobVacancyListingQueryVariables>;
export const JobVacancyListingCountDocument = gql`
    query JobVacancyListingCount($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $keyword: String) {
  JobVacancyListingCount(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, keyword: $keyword)
}
    `;

/**
 * __useJobVacancyListingCountQuery__
 *
 * To run a query within a React component, call `useJobVacancyListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyListingCountQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobVacancyListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyListingCountQuery, JobVacancyListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyListingCountQuery, JobVacancyListingCountQueryVariables>(JobVacancyListingCountDocument, baseOptions);
      }
export function useJobVacancyListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyListingCountQuery, JobVacancyListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyListingCountQuery, JobVacancyListingCountQueryVariables>(JobVacancyListingCountDocument, baseOptions);
        }
export type JobVacancyListingCountQueryHookResult = ReturnType<typeof useJobVacancyListingCountQuery>;
export type JobVacancyListingCountLazyQueryHookResult = ReturnType<typeof useJobVacancyListingCountLazyQuery>;
export type JobVacancyListingCountQueryResult = ApolloReactCommon.QueryResult<JobVacancyListingCountQuery, JobVacancyListingCountQueryVariables>;
export const JobVacancyInfoDocument = gql`
    query JobVacancyInfo($JobVacancyID: String!) {
  JobVacancyInfo(JobVacancyID: $JobVacancyID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    location
    target_hiring_date
    rec_requisition
    rec_job_vacancy_source {
      rec_job_vacancy_source_id
      source_id
      rec_source {
        RecruitmentSourceID
        Name
      }
    }
    rec_job_vacancy_expense {
      rec_job_vacancy_expense_id
      rec_expense_id
      remark
      amount
      rec_expense {
        rec_expense_id
        name
      }
    }
  }
}
    `;

/**
 * __useJobVacancyInfoQuery__
 *
 * To run a query within a React component, call `useJobVacancyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyInfoQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *   },
 * });
 */
export function useJobVacancyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyInfoQuery, JobVacancyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyInfoQuery, JobVacancyInfoQueryVariables>(JobVacancyInfoDocument, baseOptions);
      }
export function useJobVacancyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyInfoQuery, JobVacancyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyInfoQuery, JobVacancyInfoQueryVariables>(JobVacancyInfoDocument, baseOptions);
        }
export type JobVacancyInfoQueryHookResult = ReturnType<typeof useJobVacancyInfoQuery>;
export type JobVacancyInfoLazyQueryHookResult = ReturnType<typeof useJobVacancyInfoLazyQuery>;
export type JobVacancyInfoQueryResult = ApolloReactCommon.QueryResult<JobVacancyInfoQuery, JobVacancyInfoQueryVariables>;
export const JobVacancyInsertDocument = gql`
    mutation JobVacancyInsert($jobVacancyInput: Rec_JobVacancyInput!, $recSourceInput: [Rec_JobVacancySourceInput!], $recExpenseInput: [Rec_JobVacancyExpenseInput!]) {
  JobVacancyInsert(jobVacancyInput: $jobVacancyInput, recSourceInput: $recSourceInput, recExpenseInput: $recExpenseInput)
}
    `;
export type JobVacancyInsertMutationFn = ApolloReactCommon.MutationFunction<JobVacancyInsertMutation, JobVacancyInsertMutationVariables>;

/**
 * __useJobVacancyInsertMutation__
 *
 * To run a mutation, you first call `useJobVacancyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobVacancyInsertMutation, { data, loading, error }] = useJobVacancyInsertMutation({
 *   variables: {
 *      jobVacancyInput: // value for 'jobVacancyInput'
 *      recSourceInput: // value for 'recSourceInput'
 *      recExpenseInput: // value for 'recExpenseInput'
 *   },
 * });
 */
export function useJobVacancyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobVacancyInsertMutation, JobVacancyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobVacancyInsertMutation, JobVacancyInsertMutationVariables>(JobVacancyInsertDocument, baseOptions);
      }
export type JobVacancyInsertMutationHookResult = ReturnType<typeof useJobVacancyInsertMutation>;
export type JobVacancyInsertMutationResult = ApolloReactCommon.MutationResult<JobVacancyInsertMutation>;
export type JobVacancyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobVacancyInsertMutation, JobVacancyInsertMutationVariables>;
export const JobVacancyStatusUpdateDocument = gql`
    mutation JobVacancyStatusUpdate($JobVacancyID: String!, $input: JobVacancyStatusLog!) {
  JobVacancyStatusUpdate(JobVacancyID: $JobVacancyID, input: $input)
}
    `;
export type JobVacancyStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<JobVacancyStatusUpdateMutation, JobVacancyStatusUpdateMutationVariables>;

/**
 * __useJobVacancyStatusUpdateMutation__
 *
 * To run a mutation, you first call `useJobVacancyStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobVacancyStatusUpdateMutation, { data, loading, error }] = useJobVacancyStatusUpdateMutation({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobVacancyStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobVacancyStatusUpdateMutation, JobVacancyStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobVacancyStatusUpdateMutation, JobVacancyStatusUpdateMutationVariables>(JobVacancyStatusUpdateDocument, baseOptions);
      }
export type JobVacancyStatusUpdateMutationHookResult = ReturnType<typeof useJobVacancyStatusUpdateMutation>;
export type JobVacancyStatusUpdateMutationResult = ApolloReactCommon.MutationResult<JobVacancyStatusUpdateMutation>;
export type JobVacancyStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobVacancyStatusUpdateMutation, JobVacancyStatusUpdateMutationVariables>;
export const JobVacancyDeleteDocument = gql`
    mutation JobVacancyDelete($JobVacancyID: String!) {
  JobVacancyDelete(JobVacancyID: $JobVacancyID)
}
    `;
export type JobVacancyDeleteMutationFn = ApolloReactCommon.MutationFunction<JobVacancyDeleteMutation, JobVacancyDeleteMutationVariables>;

/**
 * __useJobVacancyDeleteMutation__
 *
 * To run a mutation, you first call `useJobVacancyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobVacancyDeleteMutation, { data, loading, error }] = useJobVacancyDeleteMutation({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *   },
 * });
 */
export function useJobVacancyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobVacancyDeleteMutation, JobVacancyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobVacancyDeleteMutation, JobVacancyDeleteMutationVariables>(JobVacancyDeleteDocument, baseOptions);
      }
export type JobVacancyDeleteMutationHookResult = ReturnType<typeof useJobVacancyDeleteMutation>;
export type JobVacancyDeleteMutationResult = ApolloReactCommon.MutationResult<JobVacancyDeleteMutation>;
export type JobVacancyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobVacancyDeleteMutation, JobVacancyDeleteMutationVariables>;
export const PostedJobVacancyListingDocument = gql`
    query PostedJobVacancyListing($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  PostedJobVacancyListing(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __usePostedJobVacancyListingQuery__
 *
 * To run a query within a React component, call `usePostedJobVacancyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostedJobVacancyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostedJobVacancyListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function usePostedJobVacancyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostedJobVacancyListingQuery, PostedJobVacancyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PostedJobVacancyListingQuery, PostedJobVacancyListingQueryVariables>(PostedJobVacancyListingDocument, baseOptions);
      }
export function usePostedJobVacancyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostedJobVacancyListingQuery, PostedJobVacancyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostedJobVacancyListingQuery, PostedJobVacancyListingQueryVariables>(PostedJobVacancyListingDocument, baseOptions);
        }
export type PostedJobVacancyListingQueryHookResult = ReturnType<typeof usePostedJobVacancyListingQuery>;
export type PostedJobVacancyListingLazyQueryHookResult = ReturnType<typeof usePostedJobVacancyListingLazyQuery>;
export type PostedJobVacancyListingQueryResult = ApolloReactCommon.QueryResult<PostedJobVacancyListingQuery, PostedJobVacancyListingQueryVariables>;
export const PostedJobVacancyListingCountDocument = gql`
    query PostedJobVacancyListingCount($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobVacancyStatus: [String!], $keyword: String) {
  PostedJobVacancyListingCount(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobVacancyStatus: $JobVacancyStatus, keyword: $keyword)
}
    `;

/**
 * __usePostedJobVacancyListingCountQuery__
 *
 * To run a query within a React component, call `usePostedJobVacancyListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostedJobVacancyListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostedJobVacancyListingCountQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function usePostedJobVacancyListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostedJobVacancyListingCountQuery, PostedJobVacancyListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PostedJobVacancyListingCountQuery, PostedJobVacancyListingCountQueryVariables>(PostedJobVacancyListingCountDocument, baseOptions);
      }
export function usePostedJobVacancyListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostedJobVacancyListingCountQuery, PostedJobVacancyListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostedJobVacancyListingCountQuery, PostedJobVacancyListingCountQueryVariables>(PostedJobVacancyListingCountDocument, baseOptions);
        }
export type PostedJobVacancyListingCountQueryHookResult = ReturnType<typeof usePostedJobVacancyListingCountQuery>;
export type PostedJobVacancyListingCountLazyQueryHookResult = ReturnType<typeof usePostedJobVacancyListingCountLazyQuery>;
export type PostedJobVacancyListingCountQueryResult = ApolloReactCommon.QueryResult<PostedJobVacancyListingCountQuery, PostedJobVacancyListingCountQueryVariables>;
export const PostedJobVacancyInfoDocument = gql`
    query PostedJobVacancyInfo($JobVacancyID: String!) {
  PostedJobVacancyInfo(JobVacancyID: $JobVacancyID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __usePostedJobVacancyInfoQuery__
 *
 * To run a query within a React component, call `usePostedJobVacancyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostedJobVacancyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostedJobVacancyInfoQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *   },
 * });
 */
export function usePostedJobVacancyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostedJobVacancyInfoQuery, PostedJobVacancyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PostedJobVacancyInfoQuery, PostedJobVacancyInfoQueryVariables>(PostedJobVacancyInfoDocument, baseOptions);
      }
export function usePostedJobVacancyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostedJobVacancyInfoQuery, PostedJobVacancyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostedJobVacancyInfoQuery, PostedJobVacancyInfoQueryVariables>(PostedJobVacancyInfoDocument, baseOptions);
        }
export type PostedJobVacancyInfoQueryHookResult = ReturnType<typeof usePostedJobVacancyInfoQuery>;
export type PostedJobVacancyInfoLazyQueryHookResult = ReturnType<typeof usePostedJobVacancyInfoLazyQuery>;
export type PostedJobVacancyInfoQueryResult = ApolloReactCommon.QueryResult<PostedJobVacancyInfoQuery, PostedJobVacancyInfoQueryVariables>;
export const ApplicantListingDocument = gql`
    query ApplicantListing($JobVacancyID: String!, $keyword: String, $ApplicationStage: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $offset: Float, $limit: Float, $ApplicationStatus: RecruitmentApplicationStatus, $StartDate: DateTime, $EndDate: DateTime) {
  ApplicantListing(JobVacancyID: $JobVacancyID, keyword: $keyword, ApplicationStage: $ApplicationStage, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, offset: $offset, limit: $limit, ApplicationStatus: $ApplicationStatus, StartDate: $StartDate, EndDate: $EndDate) {
    rec_applicant_id
    rec_talent_pool_id
    availability_date
    rec_job_posting_id
    application_stage
    application_status
    application_status_log
    applicant_count_by_stage
    interview_status
    rec_talent_pool {
      preferred_location
    }
    preferred_location
    contact {
      FullName
    }
    FullName
    Email
    DocumentID
    DocumentFile
    personnel_job_history {
      StartDate
      EndDate
      JobPosition
      CompanyName
      LastDrawnSalary
    }
    CompanyName
    StartDate
    EndDate
    JobPosition
    LastDrawnSalary
    IsCurrentJob
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    rec_requisition
    requisition_no
    org_unit_id
    company_id
    org_unit_name
    job_designation_id
    Name
    job_designation_name
  }
}
    `;

/**
 * __useApplicantListingQuery__
 *
 * To run a query within a React component, call `useApplicantListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantListingQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      ApplicationStage: // value for 'ApplicationStage'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useApplicantListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicantListingQuery, ApplicantListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicantListingQuery, ApplicantListingQueryVariables>(ApplicantListingDocument, baseOptions);
      }
export function useApplicantListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicantListingQuery, ApplicantListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicantListingQuery, ApplicantListingQueryVariables>(ApplicantListingDocument, baseOptions);
        }
export type ApplicantListingQueryHookResult = ReturnType<typeof useApplicantListingQuery>;
export type ApplicantListingLazyQueryHookResult = ReturnType<typeof useApplicantListingLazyQuery>;
export type ApplicantListingQueryResult = ApolloReactCommon.QueryResult<ApplicantListingQuery, ApplicantListingQueryVariables>;
export const ApplicantListingCountDocument = gql`
    query ApplicantListingCount($JobVacancyID: String!, $keyword: String, $ApplicationStage: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $ApplicationStatus: RecruitmentApplicationStatus, $StartDate: DateTime, $EndDate: DateTime) {
  ApplicantListingCount(JobVacancyID: $JobVacancyID, keyword: $keyword, ApplicationStage: $ApplicationStage, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, ApplicationStatus: $ApplicationStatus, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useApplicantListingCountQuery__
 *
 * To run a query within a React component, call `useApplicantListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantListingCountQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      ApplicationStage: // value for 'ApplicationStage'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useApplicantListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicantListingCountQuery, ApplicantListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicantListingCountQuery, ApplicantListingCountQueryVariables>(ApplicantListingCountDocument, baseOptions);
      }
export function useApplicantListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicantListingCountQuery, ApplicantListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicantListingCountQuery, ApplicantListingCountQueryVariables>(ApplicantListingCountDocument, baseOptions);
        }
export type ApplicantListingCountQueryHookResult = ReturnType<typeof useApplicantListingCountQuery>;
export type ApplicantListingCountLazyQueryHookResult = ReturnType<typeof useApplicantListingCountLazyQuery>;
export type ApplicantListingCountQueryResult = ApolloReactCommon.QueryResult<ApplicantListingCountQuery, ApplicantListingCountQueryVariables>;
export const ApplicantStatusUpdateDocument = gql`
    mutation ApplicantStatusUpdate($ApplicantIDs: [String!]!, $applicantLog: ApplicantStatusLog, $talentStatusLog: TalentStatusLog, $ApplicationStatus: RecruitmentApplicationStatus) {
  ApplicantStatusUpdate(ApplicantIDs: $ApplicantIDs, applicantLog: $applicantLog, talentStatusLog: $talentStatusLog, ApplicationStatus: $ApplicationStatus)
}
    `;
export type ApplicantStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<ApplicantStatusUpdateMutation, ApplicantStatusUpdateMutationVariables>;

/**
 * __useApplicantStatusUpdateMutation__
 *
 * To run a mutation, you first call `useApplicantStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicantStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicantStatusUpdateMutation, { data, loading, error }] = useApplicantStatusUpdateMutation({
 *   variables: {
 *      ApplicantIDs: // value for 'ApplicantIDs'
 *      applicantLog: // value for 'applicantLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *      ApplicationStatus: // value for 'ApplicationStatus'
 *   },
 * });
 */
export function useApplicantStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplicantStatusUpdateMutation, ApplicantStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplicantStatusUpdateMutation, ApplicantStatusUpdateMutationVariables>(ApplicantStatusUpdateDocument, baseOptions);
      }
export type ApplicantStatusUpdateMutationHookResult = ReturnType<typeof useApplicantStatusUpdateMutation>;
export type ApplicantStatusUpdateMutationResult = ApolloReactCommon.MutationResult<ApplicantStatusUpdateMutation>;
export type ApplicantStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplicantStatusUpdateMutation, ApplicantStatusUpdateMutationVariables>;
export const JobPostingInsertDocument = gql`
    mutation JobPostingInsert($jobPostingInput: Rec_JobPostingInput!) {
  JobPostingInsert(jobPostingInput: $jobPostingInput)
}
    `;
export type JobPostingInsertMutationFn = ApolloReactCommon.MutationFunction<JobPostingInsertMutation, JobPostingInsertMutationVariables>;

/**
 * __useJobPostingInsertMutation__
 *
 * To run a mutation, you first call `useJobPostingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingInsertMutation, { data, loading, error }] = useJobPostingInsertMutation({
 *   variables: {
 *      jobPostingInput: // value for 'jobPostingInput'
 *   },
 * });
 */
export function useJobPostingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingInsertMutation, JobPostingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingInsertMutation, JobPostingInsertMutationVariables>(JobPostingInsertDocument, baseOptions);
      }
export type JobPostingInsertMutationHookResult = ReturnType<typeof useJobPostingInsertMutation>;
export type JobPostingInsertMutationResult = ApolloReactCommon.MutationResult<JobPostingInsertMutation>;
export type JobPostingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingInsertMutation, JobPostingInsertMutationVariables>;
export const JobVacancyNotInUseListingDocument = gql`
    query JobVacancyNotInUseListing($JobVacancyStatus: [String!], $offset: Float, $limit: Float, $keyword: String) {
  JobVacancyNotInUseListing(JobVacancyStatus: $JobVacancyStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_vacancy_id
    rec_requisition
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    location
    target_hiring_date
    requisition_no
    requested_headcount
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
  }
  JobVacancyNotInUseListingCount(JobVacancyStatus: $JobVacancyStatus)
}
    `;

/**
 * __useJobVacancyNotInUseListingQuery__
 *
 * To run a query within a React component, call `useJobVacancyNotInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyNotInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyNotInUseListingQuery({
 *   variables: {
 *      JobVacancyStatus: // value for 'JobVacancyStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobVacancyNotInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyNotInUseListingQuery, JobVacancyNotInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyNotInUseListingQuery, JobVacancyNotInUseListingQueryVariables>(JobVacancyNotInUseListingDocument, baseOptions);
      }
export function useJobVacancyNotInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyNotInUseListingQuery, JobVacancyNotInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyNotInUseListingQuery, JobVacancyNotInUseListingQueryVariables>(JobVacancyNotInUseListingDocument, baseOptions);
        }
export type JobVacancyNotInUseListingQueryHookResult = ReturnType<typeof useJobVacancyNotInUseListingQuery>;
export type JobVacancyNotInUseListingLazyQueryHookResult = ReturnType<typeof useJobVacancyNotInUseListingLazyQuery>;
export type JobVacancyNotInUseListingQueryResult = ApolloReactCommon.QueryResult<JobVacancyNotInUseListingQuery, JobVacancyNotInUseListingQueryVariables>;
export const JobPostingListingDocument = gql`
    query JobPostingListing($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobPostingStatus: String, $offset: Float, $limit: Float, $keyword: String) {
  JobPostingListing(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobPostingStatus: $JobPostingStatus, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_posting_id
    subscription_account_id
    rec_job_vacancy_id
    job_posting_status
    job_description
    job_specification
    job_closing_date
    remark
    created_dt
    job_vacancy_no
    org_unit_id
    company_id
    org_unit_name
    requisition_no
    job_designation_id
    job_designation_name
    location
    target_hiring_date
    rec_job_posting_portal
    in_use
    applicant_count
  }
}
    `;

/**
 * __useJobPostingListingQuery__
 *
 * To run a query within a React component, call `useJobPostingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobPostingStatus: // value for 'JobPostingStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobPostingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingListingQuery, JobPostingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingListingQuery, JobPostingListingQueryVariables>(JobPostingListingDocument, baseOptions);
      }
export function useJobPostingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingListingQuery, JobPostingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingListingQuery, JobPostingListingQueryVariables>(JobPostingListingDocument, baseOptions);
        }
export type JobPostingListingQueryHookResult = ReturnType<typeof useJobPostingListingQuery>;
export type JobPostingListingLazyQueryHookResult = ReturnType<typeof useJobPostingListingLazyQuery>;
export type JobPostingListingQueryResult = ApolloReactCommon.QueryResult<JobPostingListingQuery, JobPostingListingQueryVariables>;
export const JobPostingListingCountDocument = gql`
    query JobPostingListingCount($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $JobPostingStatus: String, $keyword: String) {
  JobPostingListingCount(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, JobPostingStatus: $JobPostingStatus, keyword: $keyword)
}
    `;

/**
 * __useJobPostingListingCountQuery__
 *
 * To run a query within a React component, call `useJobPostingListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingListingCountQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      JobPostingStatus: // value for 'JobPostingStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobPostingListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingListingCountQuery, JobPostingListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingListingCountQuery, JobPostingListingCountQueryVariables>(JobPostingListingCountDocument, baseOptions);
      }
export function useJobPostingListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingListingCountQuery, JobPostingListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingListingCountQuery, JobPostingListingCountQueryVariables>(JobPostingListingCountDocument, baseOptions);
        }
export type JobPostingListingCountQueryHookResult = ReturnType<typeof useJobPostingListingCountQuery>;
export type JobPostingListingCountLazyQueryHookResult = ReturnType<typeof useJobPostingListingCountLazyQuery>;
export type JobPostingListingCountQueryResult = ApolloReactCommon.QueryResult<JobPostingListingCountQuery, JobPostingListingCountQueryVariables>;
export const JobPostingInfoDocument = gql`
    query JobPostingInfo($jobPostingId: String!) {
  JobPostingInfo(jobPostingId: $jobPostingId) {
    rec_job_posting_id
    subscription_account_id
    rec_job_vacancy_id
    job_posting_status
    job_description
    job_specification
    job_closing_date
    remark
    created_dt
    job_vacancy_no
    org_unit_name
    requisition_no
    job_designation_name
    location
    target_hiring_date
    rec_job_posting_portal
  }
}
    `;

/**
 * __useJobPostingInfoQuery__
 *
 * To run a query within a React component, call `useJobPostingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingInfoQuery({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *   },
 * });
 */
export function useJobPostingInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPostingInfoQuery, JobPostingInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPostingInfoQuery, JobPostingInfoQueryVariables>(JobPostingInfoDocument, baseOptions);
      }
export function useJobPostingInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPostingInfoQuery, JobPostingInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPostingInfoQuery, JobPostingInfoQueryVariables>(JobPostingInfoDocument, baseOptions);
        }
export type JobPostingInfoQueryHookResult = ReturnType<typeof useJobPostingInfoQuery>;
export type JobPostingInfoLazyQueryHookResult = ReturnType<typeof useJobPostingInfoLazyQuery>;
export type JobPostingInfoQueryResult = ApolloReactCommon.QueryResult<JobPostingInfoQuery, JobPostingInfoQueryVariables>;
export const JobPostingStatusUpdateDocument = gql`
    mutation JobPostingStatusUpdate($jobPostingId: String!, $input: Rec_JobPostingUpdateStatus!) {
  JobPostingStatusUpdate(jobPostingId: $jobPostingId, input: $input)
}
    `;
export type JobPostingStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>;

/**
 * __useJobPostingStatusUpdateMutation__
 *
 * To run a mutation, you first call `useJobPostingStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingStatusUpdateMutation, { data, loading, error }] = useJobPostingStatusUpdateMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobPostingStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>(JobPostingStatusUpdateDocument, baseOptions);
      }
export type JobPostingStatusUpdateMutationHookResult = ReturnType<typeof useJobPostingStatusUpdateMutation>;
export type JobPostingStatusUpdateMutationResult = ApolloReactCommon.MutationResult<JobPostingStatusUpdateMutation>;
export type JobPostingStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingStatusUpdateMutation, JobPostingStatusUpdateMutationVariables>;
export const JobPostingUpdateDocument = gql`
    mutation JobPostingUpdate($jobPostingId: String!, $jobPostingInput: Rec_JobPostingUpdate!) {
  JobPostingUpdate(jobPostingId: $jobPostingId, jobPostingInput: $jobPostingInput)
}
    `;
export type JobPostingUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>;

/**
 * __useJobPostingUpdateMutation__
 *
 * To run a mutation, you first call `useJobPostingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingUpdateMutation, { data, loading, error }] = useJobPostingUpdateMutation({
 *   variables: {
 *      jobPostingId: // value for 'jobPostingId'
 *      jobPostingInput: // value for 'jobPostingInput'
 *   },
 * });
 */
export function useJobPostingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>(JobPostingUpdateDocument, baseOptions);
      }
export type JobPostingUpdateMutationHookResult = ReturnType<typeof useJobPostingUpdateMutation>;
export type JobPostingUpdateMutationResult = ApolloReactCommon.MutationResult<JobPostingUpdateMutation>;
export type JobPostingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingUpdateMutation, JobPostingUpdateMutationVariables>;
export const JobPostingDeleteDocument = gql`
    mutation JobPostingDelete($JobPostingID: String!) {
  JobPostingDelete(JobPostingID: $JobPostingID)
}
    `;
export type JobPostingDeleteMutationFn = ApolloReactCommon.MutationFunction<JobPostingDeleteMutation, JobPostingDeleteMutationVariables>;

/**
 * __useJobPostingDeleteMutation__
 *
 * To run a mutation, you first call `useJobPostingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPostingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPostingDeleteMutation, { data, loading, error }] = useJobPostingDeleteMutation({
 *   variables: {
 *      JobPostingID: // value for 'JobPostingID'
 *   },
 * });
 */
export function useJobPostingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPostingDeleteMutation, JobPostingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPostingDeleteMutation, JobPostingDeleteMutationVariables>(JobPostingDeleteDocument, baseOptions);
      }
export type JobPostingDeleteMutationHookResult = ReturnType<typeof useJobPostingDeleteMutation>;
export type JobPostingDeleteMutationResult = ApolloReactCommon.MutationResult<JobPostingDeleteMutation>;
export type JobPostingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPostingDeleteMutation, JobPostingDeleteMutationVariables>;
export const JobVacancyWithApplicantListingDocument = gql`
    query JobVacancyWithApplicantListing($rec_applicant_id: String, $tabStatus: String!, $application_stage: String!, $jobPositionFilter: [String!], $orgUnitFilter: [String!], $keyword: String) {
  JobVacancyWithApplicantListing(rec_applicant_id: $rec_applicant_id, tabStatus: $tabStatus, application_stage: $application_stage, jobPositionFilter: $jobPositionFilter, orgUnitFilter: $orgUnitFilter, keyword: $keyword) {
    job_vacancy_id
    job_vacancy_no
    job_posting_id
    job_designation_name
    company_id
    requestor_id
    org_unit_id
    org_unit_name
    rec_applicant_id
    applicant_name
    applicant_image
    application_stage
    interview_remark
    expected_salary
    availability_date
    final_selection_status
  }
}
    `;

/**
 * __useJobVacancyWithApplicantListingQuery__
 *
 * To run a query within a React component, call `useJobVacancyWithApplicantListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobVacancyWithApplicantListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobVacancyWithApplicantListingQuery({
 *   variables: {
 *      rec_applicant_id: // value for 'rec_applicant_id'
 *      tabStatus: // value for 'tabStatus'
 *      application_stage: // value for 'application_stage'
 *      jobPositionFilter: // value for 'jobPositionFilter'
 *      orgUnitFilter: // value for 'orgUnitFilter'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useJobVacancyWithApplicantListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobVacancyWithApplicantListingQuery, JobVacancyWithApplicantListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobVacancyWithApplicantListingQuery, JobVacancyWithApplicantListingQueryVariables>(JobVacancyWithApplicantListingDocument, baseOptions);
      }
export function useJobVacancyWithApplicantListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobVacancyWithApplicantListingQuery, JobVacancyWithApplicantListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobVacancyWithApplicantListingQuery, JobVacancyWithApplicantListingQueryVariables>(JobVacancyWithApplicantListingDocument, baseOptions);
        }
export type JobVacancyWithApplicantListingQueryHookResult = ReturnType<typeof useJobVacancyWithApplicantListingQuery>;
export type JobVacancyWithApplicantListingLazyQueryHookResult = ReturnType<typeof useJobVacancyWithApplicantListingLazyQuery>;
export type JobVacancyWithApplicantListingQueryResult = ApolloReactCommon.QueryResult<JobVacancyWithApplicantListingQuery, JobVacancyWithApplicantListingQueryVariables>;
export const InterviewerInUseDocument = gql`
    query InterviewerInUse {
  InterviewerInUse
}
    `;

/**
 * __useInterviewerInUseQuery__
 *
 * To run a query within a React component, call `useInterviewerInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerInUseQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewerInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InterviewerInUseQuery, InterviewerInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<InterviewerInUseQuery, InterviewerInUseQueryVariables>(InterviewerInUseDocument, baseOptions);
      }
export function useInterviewerInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InterviewerInUseQuery, InterviewerInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InterviewerInUseQuery, InterviewerInUseQueryVariables>(InterviewerInUseDocument, baseOptions);
        }
export type InterviewerInUseQueryHookResult = ReturnType<typeof useInterviewerInUseQuery>;
export type InterviewerInUseLazyQueryHookResult = ReturnType<typeof useInterviewerInUseLazyQuery>;
export type InterviewerInUseQueryResult = ApolloReactCommon.QueryResult<InterviewerInUseQuery, InterviewerInUseQueryVariables>;
export const InterviewScheduleListingDocument = gql`
    query InterviewScheduleListing($rec_applicant_id: String) {
  InterviewScheduleListing(rec_applicant_id: $rec_applicant_id) {
    rec_interview_schedule_id
    subscription_account_id
    rec_applicant_id
    start_time
    end_time
    interview_date
    venue
    is_complete
    remark
    remark_voice
    remark_voice_file {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    interviewers {
      rec_interviewer_id
      interviewer_id
      interviewer {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
      }
      rec_interview_schedule_id
      rating
      remark
      remark_voice
      attachment
      is_recommend
      is_attend
      attachment_file {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      remark_voice_file {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
    }
  }
}
    `;

/**
 * __useInterviewScheduleListingQuery__
 *
 * To run a query within a React component, call `useInterviewScheduleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewScheduleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewScheduleListingQuery({
 *   variables: {
 *      rec_applicant_id: // value for 'rec_applicant_id'
 *   },
 * });
 */
export function useInterviewScheduleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InterviewScheduleListingQuery, InterviewScheduleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<InterviewScheduleListingQuery, InterviewScheduleListingQueryVariables>(InterviewScheduleListingDocument, baseOptions);
      }
export function useInterviewScheduleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InterviewScheduleListingQuery, InterviewScheduleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InterviewScheduleListingQuery, InterviewScheduleListingQueryVariables>(InterviewScheduleListingDocument, baseOptions);
        }
export type InterviewScheduleListingQueryHookResult = ReturnType<typeof useInterviewScheduleListingQuery>;
export type InterviewScheduleListingLazyQueryHookResult = ReturnType<typeof useInterviewScheduleListingLazyQuery>;
export type InterviewScheduleListingQueryResult = ApolloReactCommon.QueryResult<InterviewScheduleListingQuery, InterviewScheduleListingQueryVariables>;
export const SubmitInterviewScheduleDocument = gql`
    mutation SubmitInterviewSchedule($input: Rec_InterviewScheduleInput!, $isRemovedVoice: Boolean) {
  SubmitInterviewSchedule(input: $input, isRemovedVoice: $isRemovedVoice)
}
    `;
export type SubmitInterviewScheduleMutationFn = ApolloReactCommon.MutationFunction<SubmitInterviewScheduleMutation, SubmitInterviewScheduleMutationVariables>;

/**
 * __useSubmitInterviewScheduleMutation__
 *
 * To run a mutation, you first call `useSubmitInterviewScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInterviewScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInterviewScheduleMutation, { data, loading, error }] = useSubmitInterviewScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isRemovedVoice: // value for 'isRemovedVoice'
 *   },
 * });
 */
export function useSubmitInterviewScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitInterviewScheduleMutation, SubmitInterviewScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitInterviewScheduleMutation, SubmitInterviewScheduleMutationVariables>(SubmitInterviewScheduleDocument, baseOptions);
      }
export type SubmitInterviewScheduleMutationHookResult = ReturnType<typeof useSubmitInterviewScheduleMutation>;
export type SubmitInterviewScheduleMutationResult = ApolloReactCommon.MutationResult<SubmitInterviewScheduleMutation>;
export type SubmitInterviewScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitInterviewScheduleMutation, SubmitInterviewScheduleMutationVariables>;
export const UpdateInterviewResultDocument = gql`
    mutation UpdateInterviewResult($input: Rec_InterviewerInput!, $isRemovedVoice: Boolean, $isRemovedAttachment: Boolean) {
  UpdateInterviewResult(input: $input, isRemovedVoice: $isRemovedVoice, isRemovedAttachment: $isRemovedAttachment)
}
    `;
export type UpdateInterviewResultMutationFn = ApolloReactCommon.MutationFunction<UpdateInterviewResultMutation, UpdateInterviewResultMutationVariables>;

/**
 * __useUpdateInterviewResultMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewResultMutation, { data, loading, error }] = useUpdateInterviewResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isRemovedVoice: // value for 'isRemovedVoice'
 *      isRemovedAttachment: // value for 'isRemovedAttachment'
 *   },
 * });
 */
export function useUpdateInterviewResultMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInterviewResultMutation, UpdateInterviewResultMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInterviewResultMutation, UpdateInterviewResultMutationVariables>(UpdateInterviewResultDocument, baseOptions);
      }
export type UpdateInterviewResultMutationHookResult = ReturnType<typeof useUpdateInterviewResultMutation>;
export type UpdateInterviewResultMutationResult = ApolloReactCommon.MutationResult<UpdateInterviewResultMutation>;
export type UpdateInterviewResultMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInterviewResultMutation, UpdateInterviewResultMutationVariables>;
export const CompleteInterviewDocument = gql`
    mutation CompleteInterview($input: Rec_ApplicantInput!) {
  CompleteInterview(input: $input)
}
    `;
export type CompleteInterviewMutationFn = ApolloReactCommon.MutationFunction<CompleteInterviewMutation, CompleteInterviewMutationVariables>;

/**
 * __useCompleteInterviewMutation__
 *
 * To run a mutation, you first call `useCompleteInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInterviewMutation, { data, loading, error }] = useCompleteInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteInterviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteInterviewMutation, CompleteInterviewMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteInterviewMutation, CompleteInterviewMutationVariables>(CompleteInterviewDocument, baseOptions);
      }
export type CompleteInterviewMutationHookResult = ReturnType<typeof useCompleteInterviewMutation>;
export type CompleteInterviewMutationResult = ApolloReactCommon.MutationResult<CompleteInterviewMutation>;
export type CompleteInterviewMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteInterviewMutation, CompleteInterviewMutationVariables>;
export const ReopenInterviewDocument = gql`
    mutation ReopenInterview($rec_applicant_id: String!) {
  ReopenInterview(rec_applicant_id: $rec_applicant_id)
}
    `;
export type ReopenInterviewMutationFn = ApolloReactCommon.MutationFunction<ReopenInterviewMutation, ReopenInterviewMutationVariables>;

/**
 * __useReopenInterviewMutation__
 *
 * To run a mutation, you first call `useReopenInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenInterviewMutation, { data, loading, error }] = useReopenInterviewMutation({
 *   variables: {
 *      rec_applicant_id: // value for 'rec_applicant_id'
 *   },
 * });
 */
export function useReopenInterviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReopenInterviewMutation, ReopenInterviewMutationVariables>) {
        return ApolloReactHooks.useMutation<ReopenInterviewMutation, ReopenInterviewMutationVariables>(ReopenInterviewDocument, baseOptions);
      }
export type ReopenInterviewMutationHookResult = ReturnType<typeof useReopenInterviewMutation>;
export type ReopenInterviewMutationResult = ApolloReactCommon.MutationResult<ReopenInterviewMutation>;
export type ReopenInterviewMutationOptions = ApolloReactCommon.BaseMutationOptions<ReopenInterviewMutation, ReopenInterviewMutationVariables>;
export const DeleteInterviewScheduleDocument = gql`
    mutation DeleteInterviewSchedule($rec_interview_schedule_id: String!) {
  DeleteInterviewSchedule(rec_interview_schedule_id: $rec_interview_schedule_id)
}
    `;
export type DeleteInterviewScheduleMutationFn = ApolloReactCommon.MutationFunction<DeleteInterviewScheduleMutation, DeleteInterviewScheduleMutationVariables>;

/**
 * __useDeleteInterviewScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteInterviewScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInterviewScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInterviewScheduleMutation, { data, loading, error }] = useDeleteInterviewScheduleMutation({
 *   variables: {
 *      rec_interview_schedule_id: // value for 'rec_interview_schedule_id'
 *   },
 * });
 */
export function useDeleteInterviewScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInterviewScheduleMutation, DeleteInterviewScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInterviewScheduleMutation, DeleteInterviewScheduleMutationVariables>(DeleteInterviewScheduleDocument, baseOptions);
      }
export type DeleteInterviewScheduleMutationHookResult = ReturnType<typeof useDeleteInterviewScheduleMutation>;
export type DeleteInterviewScheduleMutationResult = ApolloReactCommon.MutationResult<DeleteInterviewScheduleMutation>;
export type DeleteInterviewScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInterviewScheduleMutation, DeleteInterviewScheduleMutationVariables>;
export const ActiveJobVacancyListingDocument = gql`
    query ActiveJobVacancyListing($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $offset: Float, $limit: Float, $keyword: String) {
  ActiveJobVacancyListing(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    requested_headcount
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __useActiveJobVacancyListingQuery__
 *
 * To run a query within a React component, call `useActiveJobVacancyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveJobVacancyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveJobVacancyListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useActiveJobVacancyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveJobVacancyListingQuery, ActiveJobVacancyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveJobVacancyListingQuery, ActiveJobVacancyListingQueryVariables>(ActiveJobVacancyListingDocument, baseOptions);
      }
export function useActiveJobVacancyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveJobVacancyListingQuery, ActiveJobVacancyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveJobVacancyListingQuery, ActiveJobVacancyListingQueryVariables>(ActiveJobVacancyListingDocument, baseOptions);
        }
export type ActiveJobVacancyListingQueryHookResult = ReturnType<typeof useActiveJobVacancyListingQuery>;
export type ActiveJobVacancyListingLazyQueryHookResult = ReturnType<typeof useActiveJobVacancyListingLazyQuery>;
export type ActiveJobVacancyListingQueryResult = ApolloReactCommon.QueryResult<ActiveJobVacancyListingQuery, ActiveJobVacancyListingQueryVariables>;
export const ActiveJobVacancyListingCountDocument = gql`
    query ActiveJobVacancyListingCount($StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $keyword: String) {
  ActiveJobVacancyListingCount(StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, keyword: $keyword)
}
    `;

/**
 * __useActiveJobVacancyListingCountQuery__
 *
 * To run a query within a React component, call `useActiveJobVacancyListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveJobVacancyListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveJobVacancyListingCountQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useActiveJobVacancyListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveJobVacancyListingCountQuery, ActiveJobVacancyListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveJobVacancyListingCountQuery, ActiveJobVacancyListingCountQueryVariables>(ActiveJobVacancyListingCountDocument, baseOptions);
      }
export function useActiveJobVacancyListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveJobVacancyListingCountQuery, ActiveJobVacancyListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveJobVacancyListingCountQuery, ActiveJobVacancyListingCountQueryVariables>(ActiveJobVacancyListingCountDocument, baseOptions);
        }
export type ActiveJobVacancyListingCountQueryHookResult = ReturnType<typeof useActiveJobVacancyListingCountQuery>;
export type ActiveJobVacancyListingCountLazyQueryHookResult = ReturnType<typeof useActiveJobVacancyListingCountLazyQuery>;
export type ActiveJobVacancyListingCountQueryResult = ApolloReactCommon.QueryResult<ActiveJobVacancyListingCountQuery, ActiveJobVacancyListingCountQueryVariables>;
export const ActiveJobVacancyInfoDocument = gql`
    query ActiveJobVacancyInfo($JobVacancyID: String!) {
  ActiveJobVacancyInfo(JobVacancyID: $JobVacancyID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requested_headcount
    requisition_no
    job_designation_id
    job_designation_name
    org_unit_id
    company_id
    org_unit_name
    applicant_count_by_stage
  }
}
    `;

/**
 * __useActiveJobVacancyInfoQuery__
 *
 * To run a query within a React component, call `useActiveJobVacancyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveJobVacancyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveJobVacancyInfoQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *   },
 * });
 */
export function useActiveJobVacancyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveJobVacancyInfoQuery, ActiveJobVacancyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveJobVacancyInfoQuery, ActiveJobVacancyInfoQueryVariables>(ActiveJobVacancyInfoDocument, baseOptions);
      }
export function useActiveJobVacancyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveJobVacancyInfoQuery, ActiveJobVacancyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveJobVacancyInfoQuery, ActiveJobVacancyInfoQueryVariables>(ActiveJobVacancyInfoDocument, baseOptions);
        }
export type ActiveJobVacancyInfoQueryHookResult = ReturnType<typeof useActiveJobVacancyInfoQuery>;
export type ActiveJobVacancyInfoLazyQueryHookResult = ReturnType<typeof useActiveJobVacancyInfoLazyQuery>;
export type ActiveJobVacancyInfoQueryResult = ApolloReactCommon.QueryResult<ActiveJobVacancyInfoQuery, ActiveJobVacancyInfoQueryVariables>;
export const FinalApplicantSelectionListingDocument = gql`
    query FinalApplicantSelectionListing($JobVacancyID: String!, $keyword: String, $FinalSelectionStatus: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $offset: Float, $limit: Float, $StartDate: DateTime, $EndDate: DateTime) {
  FinalApplicantSelectionListing(JobVacancyID: $JobVacancyID, keyword: $keyword, FinalSelectionStatus: $FinalSelectionStatus, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, offset: $offset, limit: $limit, StartDate: $StartDate, EndDate: $EndDate) {
    rec_applicant_id
    rec_talent_pool_id
    availability_date
    rec_job_posting_id
    application_stage
    application_status
    application_status_log
    applicant_count_by_stage
    interview_status
    final_selection_status
    expected_salary
    contact {
      FullName
    }
    FullName
    Email
    DocumentID
    DocumentFile
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    rec_requisition
    requisition_no
  }
}
    `;

/**
 * __useFinalApplicantSelectionListingQuery__
 *
 * To run a query within a React component, call `useFinalApplicantSelectionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalApplicantSelectionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalApplicantSelectionListingQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      FinalSelectionStatus: // value for 'FinalSelectionStatus'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useFinalApplicantSelectionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinalApplicantSelectionListingQuery, FinalApplicantSelectionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FinalApplicantSelectionListingQuery, FinalApplicantSelectionListingQueryVariables>(FinalApplicantSelectionListingDocument, baseOptions);
      }
export function useFinalApplicantSelectionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinalApplicantSelectionListingQuery, FinalApplicantSelectionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinalApplicantSelectionListingQuery, FinalApplicantSelectionListingQueryVariables>(FinalApplicantSelectionListingDocument, baseOptions);
        }
export type FinalApplicantSelectionListingQueryHookResult = ReturnType<typeof useFinalApplicantSelectionListingQuery>;
export type FinalApplicantSelectionListingLazyQueryHookResult = ReturnType<typeof useFinalApplicantSelectionListingLazyQuery>;
export type FinalApplicantSelectionListingQueryResult = ApolloReactCommon.QueryResult<FinalApplicantSelectionListingQuery, FinalApplicantSelectionListingQueryVariables>;
export const FinalApplicantSelectionListingCountDocument = gql`
    query FinalApplicantSelectionListingCount($JobVacancyID: String!, $keyword: String, $FinalSelectionStatus: [String!], $MinLastDrawnSalary: Float, $MaxLastDrawnSalary: Float, $StartDate: DateTime, $EndDate: DateTime) {
  FinalApplicantSelectionListingCount(JobVacancyID: $JobVacancyID, keyword: $keyword, FinalSelectionStatus: $FinalSelectionStatus, MinLastDrawnSalary: $MinLastDrawnSalary, MaxLastDrawnSalary: $MaxLastDrawnSalary, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useFinalApplicantSelectionListingCountQuery__
 *
 * To run a query within a React component, call `useFinalApplicantSelectionListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalApplicantSelectionListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalApplicantSelectionListingCountQuery({
 *   variables: {
 *      JobVacancyID: // value for 'JobVacancyID'
 *      keyword: // value for 'keyword'
 *      FinalSelectionStatus: // value for 'FinalSelectionStatus'
 *      MinLastDrawnSalary: // value for 'MinLastDrawnSalary'
 *      MaxLastDrawnSalary: // value for 'MaxLastDrawnSalary'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useFinalApplicantSelectionListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinalApplicantSelectionListingCountQuery, FinalApplicantSelectionListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<FinalApplicantSelectionListingCountQuery, FinalApplicantSelectionListingCountQueryVariables>(FinalApplicantSelectionListingCountDocument, baseOptions);
      }
export function useFinalApplicantSelectionListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinalApplicantSelectionListingCountQuery, FinalApplicantSelectionListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinalApplicantSelectionListingCountQuery, FinalApplicantSelectionListingCountQueryVariables>(FinalApplicantSelectionListingCountDocument, baseOptions);
        }
export type FinalApplicantSelectionListingCountQueryHookResult = ReturnType<typeof useFinalApplicantSelectionListingCountQuery>;
export type FinalApplicantSelectionListingCountLazyQueryHookResult = ReturnType<typeof useFinalApplicantSelectionListingCountLazyQuery>;
export type FinalApplicantSelectionListingCountQueryResult = ApolloReactCommon.QueryResult<FinalApplicantSelectionListingCountQuery, FinalApplicantSelectionListingCountQueryVariables>;
export const FinalSelectionStatusUpdateDocument = gql`
    mutation FinalSelectionStatusUpdate($ApplicantIDs: [String!]!, $applicantLog: ApplicantStatusLog, $FinalSelectionStatus: RecruitmentFinalSelectionStatus) {
  FinalSelectionStatusUpdate(ApplicantIDs: $ApplicantIDs, applicantLog: $applicantLog, FinalSelectionStatus: $FinalSelectionStatus)
}
    `;
export type FinalSelectionStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<FinalSelectionStatusUpdateMutation, FinalSelectionStatusUpdateMutationVariables>;

/**
 * __useFinalSelectionStatusUpdateMutation__
 *
 * To run a mutation, you first call `useFinalSelectionStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSelectionStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSelectionStatusUpdateMutation, { data, loading, error }] = useFinalSelectionStatusUpdateMutation({
 *   variables: {
 *      ApplicantIDs: // value for 'ApplicantIDs'
 *      applicantLog: // value for 'applicantLog'
 *      FinalSelectionStatus: // value for 'FinalSelectionStatus'
 *   },
 * });
 */
export function useFinalSelectionStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalSelectionStatusUpdateMutation, FinalSelectionStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalSelectionStatusUpdateMutation, FinalSelectionStatusUpdateMutationVariables>(FinalSelectionStatusUpdateDocument, baseOptions);
      }
export type FinalSelectionStatusUpdateMutationHookResult = ReturnType<typeof useFinalSelectionStatusUpdateMutation>;
export type FinalSelectionStatusUpdateMutationResult = ApolloReactCommon.MutationResult<FinalSelectionStatusUpdateMutation>;
export type FinalSelectionStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalSelectionStatusUpdateMutation, FinalSelectionStatusUpdateMutationVariables>;
export const OfferProposalListingDocument = gql`
    query OfferProposalListing($OfferStatus: [RecruitmentOfferStatus!]!, $ResponseStatus: [RecruitmentOfferResponseStatus!], $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $offset: Float, $limit: Float, $keyword: String) {
  OfferProposalListing(OfferStatus: $OfferStatus, ResponseStatus: $ResponseStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_offer_id
    target_hiring_date
    duty_date
    offer_status
    response_status
    offer_count
    availability_date
    rec_applicant_id
    expected_salary
    rec_talent_pool_id
    FullName
    DocumentFile
    rec_job_vacancy_id
    job_vacancy_no
    rec_requisition_id
    requisition_no
    company_id
    org_unit_name
    job_designation_id
    job_designation_name
  }
}
    `;

/**
 * __useOfferProposalListingQuery__
 *
 * To run a query within a React component, call `useOfferProposalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferProposalListingQuery({
 *   variables: {
 *      OfferStatus: // value for 'OfferStatus'
 *      ResponseStatus: // value for 'ResponseStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useOfferProposalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferProposalListingQuery, OfferProposalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferProposalListingQuery, OfferProposalListingQueryVariables>(OfferProposalListingDocument, baseOptions);
      }
export function useOfferProposalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferProposalListingQuery, OfferProposalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferProposalListingQuery, OfferProposalListingQueryVariables>(OfferProposalListingDocument, baseOptions);
        }
export type OfferProposalListingQueryHookResult = ReturnType<typeof useOfferProposalListingQuery>;
export type OfferProposalListingLazyQueryHookResult = ReturnType<typeof useOfferProposalListingLazyQuery>;
export type OfferProposalListingQueryResult = ApolloReactCommon.QueryResult<OfferProposalListingQuery, OfferProposalListingQueryVariables>;
export const OfferProposalListingCountDocument = gql`
    query OfferProposalListingCount($OfferStatus: [RecruitmentOfferStatus!]!, $ResponseStatus: [RecruitmentOfferResponseStatus!], $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $keyword: String) {
  OfferProposalListingCount(OfferStatus: $OfferStatus, ResponseStatus: $ResponseStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, keyword: $keyword)
}
    `;

/**
 * __useOfferProposalListingCountQuery__
 *
 * To run a query within a React component, call `useOfferProposalListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferProposalListingCountQuery({
 *   variables: {
 *      OfferStatus: // value for 'OfferStatus'
 *      ResponseStatus: // value for 'ResponseStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useOfferProposalListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferProposalListingCountQuery, OfferProposalListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferProposalListingCountQuery, OfferProposalListingCountQueryVariables>(OfferProposalListingCountDocument, baseOptions);
      }
export function useOfferProposalListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferProposalListingCountQuery, OfferProposalListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferProposalListingCountQuery, OfferProposalListingCountQueryVariables>(OfferProposalListingCountDocument, baseOptions);
        }
export type OfferProposalListingCountQueryHookResult = ReturnType<typeof useOfferProposalListingCountQuery>;
export type OfferProposalListingCountLazyQueryHookResult = ReturnType<typeof useOfferProposalListingCountLazyQuery>;
export type OfferProposalListingCountQueryResult = ApolloReactCommon.QueryResult<OfferProposalListingCountQuery, OfferProposalListingCountQueryVariables>;
export const OfferProposalInfoDocument = gql`
    query OfferProposalInfo($ApplicantID: String!) {
  OfferProposalInfo(ApplicantID: $ApplicantID) {
    rec_offer_id
    rec_applicant_id
    salary
    probation_month
    duty_date
    offer_remark
    offer_remark_voice_file {
      DocumentID
      DocumentFile
      Description
    }
    offer_attachment_file {
      DocumentID
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useOfferProposalInfoQuery__
 *
 * To run a query within a React component, call `useOfferProposalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferProposalInfoQuery({
 *   variables: {
 *      ApplicantID: // value for 'ApplicantID'
 *   },
 * });
 */
export function useOfferProposalInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferProposalInfoQuery, OfferProposalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferProposalInfoQuery, OfferProposalInfoQueryVariables>(OfferProposalInfoDocument, baseOptions);
      }
export function useOfferProposalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferProposalInfoQuery, OfferProposalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferProposalInfoQuery, OfferProposalInfoQueryVariables>(OfferProposalInfoDocument, baseOptions);
        }
export type OfferProposalInfoQueryHookResult = ReturnType<typeof useOfferProposalInfoQuery>;
export type OfferProposalInfoLazyQueryHookResult = ReturnType<typeof useOfferProposalInfoLazyQuery>;
export type OfferProposalInfoQueryResult = ApolloReactCommon.QueryResult<OfferProposalInfoQuery, OfferProposalInfoQueryVariables>;
export const OfferProposalUpdateDocument = gql`
    mutation OfferProposalUpdate($input: Rec_OfferInput!, $isRemovedVoice: Boolean, $isRemovedAttachment: Boolean) {
  OfferProposalUpdate(input: $input, isRemovedVoice: $isRemovedVoice, isRemovedAttachment: $isRemovedAttachment)
}
    `;
export type OfferProposalUpdateMutationFn = ApolloReactCommon.MutationFunction<OfferProposalUpdateMutation, OfferProposalUpdateMutationVariables>;

/**
 * __useOfferProposalUpdateMutation__
 *
 * To run a mutation, you first call `useOfferProposalUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerProposalUpdateMutation, { data, loading, error }] = useOfferProposalUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isRemovedVoice: // value for 'isRemovedVoice'
 *      isRemovedAttachment: // value for 'isRemovedAttachment'
 *   },
 * });
 */
export function useOfferProposalUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferProposalUpdateMutation, OfferProposalUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferProposalUpdateMutation, OfferProposalUpdateMutationVariables>(OfferProposalUpdateDocument, baseOptions);
      }
export type OfferProposalUpdateMutationHookResult = ReturnType<typeof useOfferProposalUpdateMutation>;
export type OfferProposalUpdateMutationResult = ApolloReactCommon.MutationResult<OfferProposalUpdateMutation>;
export type OfferProposalUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferProposalUpdateMutation, OfferProposalUpdateMutationVariables>;
export const OfferProposalDetailListingDocument = gql`
    query OfferProposalDetailListing($ApplicantID: String!) {
  OfferProposalDetailListing(ApplicantID: $ApplicantID) {
    salary
    probation_month
    duty_date
    offer_remark
    offer_attachment_file {
      DocumentFile
    }
    submit_date
    offer_status
    response_status
    response_date
    response_remark
    rec_decline_reason {
      reason
    }
    response_attachment_file {
      DocumentFile
    }
    approval_remark
  }
}
    `;

/**
 * __useOfferProposalDetailListingQuery__
 *
 * To run a query within a React component, call `useOfferProposalDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferProposalDetailListingQuery({
 *   variables: {
 *      ApplicantID: // value for 'ApplicantID'
 *   },
 * });
 */
export function useOfferProposalDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferProposalDetailListingQuery, OfferProposalDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferProposalDetailListingQuery, OfferProposalDetailListingQueryVariables>(OfferProposalDetailListingDocument, baseOptions);
      }
export function useOfferProposalDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferProposalDetailListingQuery, OfferProposalDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferProposalDetailListingQuery, OfferProposalDetailListingQueryVariables>(OfferProposalDetailListingDocument, baseOptions);
        }
export type OfferProposalDetailListingQueryHookResult = ReturnType<typeof useOfferProposalDetailListingQuery>;
export type OfferProposalDetailListingLazyQueryHookResult = ReturnType<typeof useOfferProposalDetailListingLazyQuery>;
export type OfferProposalDetailListingQueryResult = ApolloReactCommon.QueryResult<OfferProposalDetailListingQuery, OfferProposalDetailListingQueryVariables>;
export const OfferProposalInsertDocument = gql`
    mutation OfferProposalInsert($input: Rec_OfferInput!) {
  OfferProposalInsert(input: $input)
}
    `;
export type OfferProposalInsertMutationFn = ApolloReactCommon.MutationFunction<OfferProposalInsertMutation, OfferProposalInsertMutationVariables>;

/**
 * __useOfferProposalInsertMutation__
 *
 * To run a mutation, you first call `useOfferProposalInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferProposalInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerProposalInsertMutation, { data, loading, error }] = useOfferProposalInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferProposalInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferProposalInsertMutation, OfferProposalInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferProposalInsertMutation, OfferProposalInsertMutationVariables>(OfferProposalInsertDocument, baseOptions);
      }
export type OfferProposalInsertMutationHookResult = ReturnType<typeof useOfferProposalInsertMutation>;
export type OfferProposalInsertMutationResult = ApolloReactCommon.MutationResult<OfferProposalInsertMutation>;
export type OfferProposalInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferProposalInsertMutation, OfferProposalInsertMutationVariables>;
export const OfferApprovalListingDocument = gql`
    query OfferApprovalListing($OfferStatus: [RecruitmentOfferStatus!]!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $offset: Float, $limit: Float, $keyword: String) {
  OfferApprovalListing(OfferStatus: $OfferStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_offer_id
    target_hiring_date
    duty_date
    offer_status
    offer_count
    availability_date
    rec_applicant_id
    expected_salary
    rec_talent_pool_id
    FullName
    DocumentFile
    rec_job_vacancy_id
    job_vacancy_no
    rec_requisition_id
    requisition_no
    company_id
    org_unit_name
    job_designation_id
    job_designation_name
  }
}
    `;

/**
 * __useOfferApprovalListingQuery__
 *
 * To run a query within a React component, call `useOfferApprovalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferApprovalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferApprovalListingQuery({
 *   variables: {
 *      OfferStatus: // value for 'OfferStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useOfferApprovalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferApprovalListingQuery, OfferApprovalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferApprovalListingQuery, OfferApprovalListingQueryVariables>(OfferApprovalListingDocument, baseOptions);
      }
export function useOfferApprovalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferApprovalListingQuery, OfferApprovalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferApprovalListingQuery, OfferApprovalListingQueryVariables>(OfferApprovalListingDocument, baseOptions);
        }
export type OfferApprovalListingQueryHookResult = ReturnType<typeof useOfferApprovalListingQuery>;
export type OfferApprovalListingLazyQueryHookResult = ReturnType<typeof useOfferApprovalListingLazyQuery>;
export type OfferApprovalListingQueryResult = ApolloReactCommon.QueryResult<OfferApprovalListingQuery, OfferApprovalListingQueryVariables>;
export const OfferApprovalListingCountDocument = gql`
    query OfferApprovalListingCount($OfferStatus: [RecruitmentOfferStatus!]!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $keyword: String) {
  OfferApprovalListingCount(OfferStatus: $OfferStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, keyword: $keyword)
}
    `;

/**
 * __useOfferApprovalListingCountQuery__
 *
 * To run a query within a React component, call `useOfferApprovalListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferApprovalListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferApprovalListingCountQuery({
 *   variables: {
 *      OfferStatus: // value for 'OfferStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useOfferApprovalListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferApprovalListingCountQuery, OfferApprovalListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferApprovalListingCountQuery, OfferApprovalListingCountQueryVariables>(OfferApprovalListingCountDocument, baseOptions);
      }
export function useOfferApprovalListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferApprovalListingCountQuery, OfferApprovalListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferApprovalListingCountQuery, OfferApprovalListingCountQueryVariables>(OfferApprovalListingCountDocument, baseOptions);
        }
export type OfferApprovalListingCountQueryHookResult = ReturnType<typeof useOfferApprovalListingCountQuery>;
export type OfferApprovalListingCountLazyQueryHookResult = ReturnType<typeof useOfferApprovalListingCountLazyQuery>;
export type OfferApprovalListingCountQueryResult = ApolloReactCommon.QueryResult<OfferApprovalListingCountQuery, OfferApprovalListingCountQueryVariables>;
export const OfferApprovalDetailListingDocument = gql`
    query OfferApprovalDetailListing($ApplicantID: String!) {
  OfferApprovalDetailListing(ApplicantID: $ApplicantID) {
    salary
    probation_month
    duty_date
    offer_remark
    offer_attachment_file {
      DocumentFile
    }
    submit_date
    offer_status
    approval_remark
  }
}
    `;

/**
 * __useOfferApprovalDetailListingQuery__
 *
 * To run a query within a React component, call `useOfferApprovalDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferApprovalDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferApprovalDetailListingQuery({
 *   variables: {
 *      ApplicantID: // value for 'ApplicantID'
 *   },
 * });
 */
export function useOfferApprovalDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferApprovalDetailListingQuery, OfferApprovalDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferApprovalDetailListingQuery, OfferApprovalDetailListingQueryVariables>(OfferApprovalDetailListingDocument, baseOptions);
      }
export function useOfferApprovalDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferApprovalDetailListingQuery, OfferApprovalDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferApprovalDetailListingQuery, OfferApprovalDetailListingQueryVariables>(OfferApprovalDetailListingDocument, baseOptions);
        }
export type OfferApprovalDetailListingQueryHookResult = ReturnType<typeof useOfferApprovalDetailListingQuery>;
export type OfferApprovalDetailListingLazyQueryHookResult = ReturnType<typeof useOfferApprovalDetailListingLazyQuery>;
export type OfferApprovalDetailListingQueryResult = ApolloReactCommon.QueryResult<OfferApprovalDetailListingQuery, OfferApprovalDetailListingQueryVariables>;
export const OfferApprovalUpdateDocument = gql`
    mutation OfferApprovalUpdate($input: Rec_OfferInput!) {
  OfferApprovalUpdate(input: $input)
}
    `;
export type OfferApprovalUpdateMutationFn = ApolloReactCommon.MutationFunction<OfferApprovalUpdateMutation, OfferApprovalUpdateMutationVariables>;

/**
 * __useOfferApprovalUpdateMutation__
 *
 * To run a mutation, you first call `useOfferApprovalUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferApprovalUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerApprovalUpdateMutation, { data, loading, error }] = useOfferApprovalUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferApprovalUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferApprovalUpdateMutation, OfferApprovalUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferApprovalUpdateMutation, OfferApprovalUpdateMutationVariables>(OfferApprovalUpdateDocument, baseOptions);
      }
export type OfferApprovalUpdateMutationHookResult = ReturnType<typeof useOfferApprovalUpdateMutation>;
export type OfferApprovalUpdateMutationResult = ApolloReactCommon.MutationResult<OfferApprovalUpdateMutation>;
export type OfferApprovalUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferApprovalUpdateMutation, OfferApprovalUpdateMutationVariables>;
export const ApprovedJobOfferListingDocument = gql`
    query ApprovedJobOfferListing($OfferProcessStatus: [RecruitmentOfferProcessStatus!]!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $offset: Float, $limit: Float, $keyword: String) {
  ApprovedJobOfferListing(OfferProcessStatus: $OfferProcessStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, offset: $offset, limit: $limit, keyword: $keyword) {
    rec_offer_id
    target_hiring_date
    duty_date
    offer_status
    response_status
    rec_offer_process_id
    offer_process_status
    send_log
    offer_count
    availability_date
    rec_applicant_id
    expected_salary
    rec_talent_pool_id
    FullName
    Email
    DocumentFile
    rec_job_vacancy_id
    job_vacancy_no
    rec_requisition_id
    requisition_no
    company_id
    org_unit_name
    job_designation_id
    job_designation_name
    requestor_name
    offer_checklist_snapshot
    rec_offer_content {
      rec_offer_content_id
      rec_offer_checklist_id
      eletter_document_file {
        DocumentFile
        BucketFileName
        Content
      }
      offer_content_status
    }
  }
}
    `;

/**
 * __useApprovedJobOfferListingQuery__
 *
 * To run a query within a React component, call `useApprovedJobOfferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedJobOfferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedJobOfferListingQuery({
 *   variables: {
 *      OfferProcessStatus: // value for 'OfferProcessStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useApprovedJobOfferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovedJobOfferListingQuery, ApprovedJobOfferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovedJobOfferListingQuery, ApprovedJobOfferListingQueryVariables>(ApprovedJobOfferListingDocument, baseOptions);
      }
export function useApprovedJobOfferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovedJobOfferListingQuery, ApprovedJobOfferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovedJobOfferListingQuery, ApprovedJobOfferListingQueryVariables>(ApprovedJobOfferListingDocument, baseOptions);
        }
export type ApprovedJobOfferListingQueryHookResult = ReturnType<typeof useApprovedJobOfferListingQuery>;
export type ApprovedJobOfferListingLazyQueryHookResult = ReturnType<typeof useApprovedJobOfferListingLazyQuery>;
export type ApprovedJobOfferListingQueryResult = ApolloReactCommon.QueryResult<ApprovedJobOfferListingQuery, ApprovedJobOfferListingQueryVariables>;
export const ApprovedJobOfferListingCountDocument = gql`
    query ApprovedJobOfferListingCount($OfferProcessStatus: [RecruitmentOfferProcessStatus!]!, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $keyword: String) {
  ApprovedJobOfferListingCount(OfferProcessStatus: $OfferProcessStatus, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, keyword: $keyword)
}
    `;

/**
 * __useApprovedJobOfferListingCountQuery__
 *
 * To run a query within a React component, call `useApprovedJobOfferListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedJobOfferListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedJobOfferListingCountQuery({
 *   variables: {
 *      OfferProcessStatus: // value for 'OfferProcessStatus'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useApprovedJobOfferListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovedJobOfferListingCountQuery, ApprovedJobOfferListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovedJobOfferListingCountQuery, ApprovedJobOfferListingCountQueryVariables>(ApprovedJobOfferListingCountDocument, baseOptions);
      }
export function useApprovedJobOfferListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovedJobOfferListingCountQuery, ApprovedJobOfferListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovedJobOfferListingCountQuery, ApprovedJobOfferListingCountQueryVariables>(ApprovedJobOfferListingCountDocument, baseOptions);
        }
export type ApprovedJobOfferListingCountQueryHookResult = ReturnType<typeof useApprovedJobOfferListingCountQuery>;
export type ApprovedJobOfferListingCountLazyQueryHookResult = ReturnType<typeof useApprovedJobOfferListingCountLazyQuery>;
export type ApprovedJobOfferListingCountQueryResult = ApolloReactCommon.QueryResult<ApprovedJobOfferListingCountQuery, ApprovedJobOfferListingCountQueryVariables>;
export const ApprovedJobOfferInfoDocument = gql`
    query ApprovedJobOfferInfo($OfferProcessID: String!) {
  ApprovedJobOfferInfo(OfferProcessID: $OfferProcessID)
}
    `;

/**
 * __useApprovedJobOfferInfoQuery__
 *
 * To run a query within a React component, call `useApprovedJobOfferInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedJobOfferInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedJobOfferInfoQuery({
 *   variables: {
 *      OfferProcessID: // value for 'OfferProcessID'
 *   },
 * });
 */
export function useApprovedJobOfferInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovedJobOfferInfoQuery, ApprovedJobOfferInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovedJobOfferInfoQuery, ApprovedJobOfferInfoQueryVariables>(ApprovedJobOfferInfoDocument, baseOptions);
      }
export function useApprovedJobOfferInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovedJobOfferInfoQuery, ApprovedJobOfferInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovedJobOfferInfoQuery, ApprovedJobOfferInfoQueryVariables>(ApprovedJobOfferInfoDocument, baseOptions);
        }
export type ApprovedJobOfferInfoQueryHookResult = ReturnType<typeof useApprovedJobOfferInfoQuery>;
export type ApprovedJobOfferInfoLazyQueryHookResult = ReturnType<typeof useApprovedJobOfferInfoLazyQuery>;
export type ApprovedJobOfferInfoQueryResult = ApolloReactCommon.QueryResult<ApprovedJobOfferInfoQuery, ApprovedJobOfferInfoQueryVariables>;
export const ProcessedOfferInfoDocument = gql`
    query ProcessedOfferInfo($ApplicantID: String!) {
  ProcessedOfferInfo(ApplicantID: $ApplicantID) {
    salary
    probation_month
    duty_date
    offer_remark
    offer_attachment_file {
      DocumentFile
    }
    submit_date
    offer_status
    response_status
    response_date
    response_remark
    rec_decline_reason {
      reason
    }
    response_attachment_file {
      DocumentFile
    }
    approval_remark
  }
}
    `;

/**
 * __useProcessedOfferInfoQuery__
 *
 * To run a query within a React component, call `useProcessedOfferInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessedOfferInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessedOfferInfoQuery({
 *   variables: {
 *      ApplicantID: // value for 'ApplicantID'
 *   },
 * });
 */
export function useProcessedOfferInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcessedOfferInfoQuery, ProcessedOfferInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcessedOfferInfoQuery, ProcessedOfferInfoQueryVariables>(ProcessedOfferInfoDocument, baseOptions);
      }
export function useProcessedOfferInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcessedOfferInfoQuery, ProcessedOfferInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcessedOfferInfoQuery, ProcessedOfferInfoQueryVariables>(ProcessedOfferInfoDocument, baseOptions);
        }
export type ProcessedOfferInfoQueryHookResult = ReturnType<typeof useProcessedOfferInfoQuery>;
export type ProcessedOfferInfoLazyQueryHookResult = ReturnType<typeof useProcessedOfferInfoLazyQuery>;
export type ProcessedOfferInfoQueryResult = ApolloReactCommon.QueryResult<ProcessedOfferInfoQuery, ProcessedOfferInfoQueryVariables>;
export const OfferChecklistListingDocument = gql`
    query OfferChecklistListing {
  OfferChecklistListing {
    rec_offer_checklist_id
    name
    sequence
    is_required
  }
}
    `;

/**
 * __useOfferChecklistListingQuery__
 *
 * To run a query within a React component, call `useOfferChecklistListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferChecklistListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferChecklistListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfferChecklistListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfferChecklistListingQuery, OfferChecklistListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OfferChecklistListingQuery, OfferChecklistListingQueryVariables>(OfferChecklistListingDocument, baseOptions);
      }
export function useOfferChecklistListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfferChecklistListingQuery, OfferChecklistListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfferChecklistListingQuery, OfferChecklistListingQueryVariables>(OfferChecklistListingDocument, baseOptions);
        }
export type OfferChecklistListingQueryHookResult = ReturnType<typeof useOfferChecklistListingQuery>;
export type OfferChecklistListingLazyQueryHookResult = ReturnType<typeof useOfferChecklistListingLazyQuery>;
export type OfferChecklistListingQueryResult = ApolloReactCommon.QueryResult<OfferChecklistListingQuery, OfferChecklistListingQueryVariables>;
export const ELetterTemplateInfoDocument = gql`
    query ELetterTemplateInfo($OfferChecklistID: String!) {
  ELetterTemplateInfo(OfferChecklistID: $OfferChecklistID) {
    name
    content
    is_default
  }
}
    `;

/**
 * __useELetterTemplateInfoQuery__
 *
 * To run a query within a React component, call `useELetterTemplateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useELetterTemplateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useELetterTemplateInfoQuery({
 *   variables: {
 *      OfferChecklistID: // value for 'OfferChecklistID'
 *   },
 * });
 */
export function useELetterTemplateInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ELetterTemplateInfoQuery, ELetterTemplateInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ELetterTemplateInfoQuery, ELetterTemplateInfoQueryVariables>(ELetterTemplateInfoDocument, baseOptions);
      }
export function useELetterTemplateInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ELetterTemplateInfoQuery, ELetterTemplateInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ELetterTemplateInfoQuery, ELetterTemplateInfoQueryVariables>(ELetterTemplateInfoDocument, baseOptions);
        }
export type ELetterTemplateInfoQueryHookResult = ReturnType<typeof useELetterTemplateInfoQuery>;
export type ELetterTemplateInfoLazyQueryHookResult = ReturnType<typeof useELetterTemplateInfoLazyQuery>;
export type ELetterTemplateInfoQueryResult = ApolloReactCommon.QueryResult<ELetterTemplateInfoQuery, ELetterTemplateInfoQueryVariables>;
export const OfferContentInsertDocument = gql`
    mutation OfferContentInsert($offerContentInput: Rec_OfferContentInput!, $offerChecklistSnapshot: [OfferChecklistSnapshot!]) {
  OfferContentInsert(offerContentInput: $offerContentInput, offerChecklistSnapshot: $offerChecklistSnapshot)
}
    `;
export type OfferContentInsertMutationFn = ApolloReactCommon.MutationFunction<OfferContentInsertMutation, OfferContentInsertMutationVariables>;

/**
 * __useOfferContentInsertMutation__
 *
 * To run a mutation, you first call `useOfferContentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferContentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerContentInsertMutation, { data, loading, error }] = useOfferContentInsertMutation({
 *   variables: {
 *      offerContentInput: // value for 'offerContentInput'
 *      offerChecklistSnapshot: // value for 'offerChecklistSnapshot'
 *   },
 * });
 */
export function useOfferContentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferContentInsertMutation, OfferContentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferContentInsertMutation, OfferContentInsertMutationVariables>(OfferContentInsertDocument, baseOptions);
      }
export type OfferContentInsertMutationHookResult = ReturnType<typeof useOfferContentInsertMutation>;
export type OfferContentInsertMutationResult = ApolloReactCommon.MutationResult<OfferContentInsertMutation>;
export type OfferContentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferContentInsertMutation, OfferContentInsertMutationVariables>;
export const FinalizeOfferDocument = gql`
    mutation FinalizeOffer($offerContentInput: [Rec_OfferContentInput!]!) {
  FinalizeOffer(offerContentInput: $offerContentInput)
}
    `;
export type FinalizeOfferMutationFn = ApolloReactCommon.MutationFunction<FinalizeOfferMutation, FinalizeOfferMutationVariables>;

/**
 * __useFinalizeOfferMutation__
 *
 * To run a mutation, you first call `useFinalizeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeOfferMutation, { data, loading, error }] = useFinalizeOfferMutation({
 *   variables: {
 *      offerContentInput: // value for 'offerContentInput'
 *   },
 * });
 */
export function useFinalizeOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalizeOfferMutation, FinalizeOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalizeOfferMutation, FinalizeOfferMutationVariables>(FinalizeOfferDocument, baseOptions);
      }
export type FinalizeOfferMutationHookResult = ReturnType<typeof useFinalizeOfferMutation>;
export type FinalizeOfferMutationResult = ApolloReactCommon.MutationResult<FinalizeOfferMutation>;
export type FinalizeOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalizeOfferMutation, FinalizeOfferMutationVariables>;
export const UpdateOfferResponseDocument = gql`
    mutation UpdateOfferResponse($input: Rec_OfferInput!, $applicantLog: ApplicantStatusLog, $talentStatusLog: TalentStatusLog) {
  UpdateOfferResponse(input: $input, applicantLog: $applicantLog, talentStatusLog: $talentStatusLog)
}
    `;
export type UpdateOfferResponseMutationFn = ApolloReactCommon.MutationFunction<UpdateOfferResponseMutation, UpdateOfferResponseMutationVariables>;

/**
 * __useUpdateOfferResponseMutation__
 *
 * To run a mutation, you first call `useUpdateOfferResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferResponseMutation, { data, loading, error }] = useUpdateOfferResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      applicantLog: // value for 'applicantLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useUpdateOfferResponseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOfferResponseMutation, UpdateOfferResponseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOfferResponseMutation, UpdateOfferResponseMutationVariables>(UpdateOfferResponseDocument, baseOptions);
      }
export type UpdateOfferResponseMutationHookResult = ReturnType<typeof useUpdateOfferResponseMutation>;
export type UpdateOfferResponseMutationResult = ApolloReactCommon.MutationResult<UpdateOfferResponseMutation>;
export type UpdateOfferResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOfferResponseMutation, UpdateOfferResponseMutationVariables>;
export const SendLetterDocument = gql`
    mutation SendLetter($sendLog: SendLog!, $OfferContent: [Rec_OfferContentInput!]!, $OfferChecklistSnapshot: [OfferChecklistSnapshot!]!, $OfferProcessID: String!, $FullName: String!, $Email: String!) {
  SendLetter(sendLog: $sendLog, OfferContent: $OfferContent, OfferChecklistSnapshot: $OfferChecklistSnapshot, OfferProcessID: $OfferProcessID, FullName: $FullName, Email: $Email)
}
    `;
export type SendLetterMutationFn = ApolloReactCommon.MutationFunction<SendLetterMutation, SendLetterMutationVariables>;

/**
 * __useSendLetterMutation__
 *
 * To run a mutation, you first call `useSendLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLetterMutation, { data, loading, error }] = useSendLetterMutation({
 *   variables: {
 *      sendLog: // value for 'sendLog'
 *      OfferContent: // value for 'OfferContent'
 *      OfferChecklistSnapshot: // value for 'OfferChecklistSnapshot'
 *      OfferProcessID: // value for 'OfferProcessID'
 *      FullName: // value for 'FullName'
 *      Email: // value for 'Email'
 *   },
 * });
 */
export function useSendLetterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendLetterMutation, SendLetterMutationVariables>) {
        return ApolloReactHooks.useMutation<SendLetterMutation, SendLetterMutationVariables>(SendLetterDocument, baseOptions);
      }
export type SendLetterMutationHookResult = ReturnType<typeof useSendLetterMutation>;
export type SendLetterMutationResult = ApolloReactCommon.MutationResult<SendLetterMutation>;
export type SendLetterMutationOptions = ApolloReactCommon.BaseMutationOptions<SendLetterMutation, SendLetterMutationVariables>;
export const ApplicantOfferPreHiringListingDocument = gql`
    query ApplicantOfferPreHiringListing($offset: Float, $limit: Float, $keyword: String, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!]) {
  ApplicantOfferPreHiringListing(offset: $offset, limit: $limit, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs) {
    duty_date
    acceptance_date
    rec_talent_pool_id
    FullName
    Email
    document_file
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    org_unit_name
    job_designation_name
    rec_hiring_id
    prehiring_status
    data_update_status
  }
  ApplicantOfferPreHiringListingCount(keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs)
}
    `;

/**
 * __useApplicantOfferPreHiringListingQuery__
 *
 * To run a query within a React component, call `useApplicantOfferPreHiringListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantOfferPreHiringListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantOfferPreHiringListingQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *   },
 * });
 */
export function useApplicantOfferPreHiringListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicantOfferPreHiringListingQuery, ApplicantOfferPreHiringListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicantOfferPreHiringListingQuery, ApplicantOfferPreHiringListingQueryVariables>(ApplicantOfferPreHiringListingDocument, baseOptions);
      }
export function useApplicantOfferPreHiringListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicantOfferPreHiringListingQuery, ApplicantOfferPreHiringListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicantOfferPreHiringListingQuery, ApplicantOfferPreHiringListingQueryVariables>(ApplicantOfferPreHiringListingDocument, baseOptions);
        }
export type ApplicantOfferPreHiringListingQueryHookResult = ReturnType<typeof useApplicantOfferPreHiringListingQuery>;
export type ApplicantOfferPreHiringListingLazyQueryHookResult = ReturnType<typeof useApplicantOfferPreHiringListingLazyQuery>;
export type ApplicantOfferPreHiringListingQueryResult = ApolloReactCommon.QueryResult<ApplicantOfferPreHiringListingQuery, ApplicantOfferPreHiringListingQueryVariables>;
export const InsertAcceptedPreHiringDocument = gql`
    mutation InsertAcceptedPreHiring($hiringInput: AcceptedPreHiringInput!) {
  InsertAcceptedPreHiring(hiringInput: $hiringInput)
}
    `;
export type InsertAcceptedPreHiringMutationFn = ApolloReactCommon.MutationFunction<InsertAcceptedPreHiringMutation, InsertAcceptedPreHiringMutationVariables>;

/**
 * __useInsertAcceptedPreHiringMutation__
 *
 * To run a mutation, you first call `useInsertAcceptedPreHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAcceptedPreHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAcceptedPreHiringMutation, { data, loading, error }] = useInsertAcceptedPreHiringMutation({
 *   variables: {
 *      hiringInput: // value for 'hiringInput'
 *   },
 * });
 */
export function useInsertAcceptedPreHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertAcceptedPreHiringMutation, InsertAcceptedPreHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertAcceptedPreHiringMutation, InsertAcceptedPreHiringMutationVariables>(InsertAcceptedPreHiringDocument, baseOptions);
      }
export type InsertAcceptedPreHiringMutationHookResult = ReturnType<typeof useInsertAcceptedPreHiringMutation>;
export type InsertAcceptedPreHiringMutationResult = ApolloReactCommon.MutationResult<InsertAcceptedPreHiringMutation>;
export type InsertAcceptedPreHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertAcceptedPreHiringMutation, InsertAcceptedPreHiringMutationVariables>;
export const SendHiringEmailDocument = gql`
    mutation SendHiringEmail($HiringIDs: [String!]!, $dataUpdateStatusLog: DataUpdateStatusLog!, $emailRemark: String) {
  SendHiringEmail(HiringIDs: $HiringIDs, dataUpdateStatusLog: $dataUpdateStatusLog, emailRemark: $emailRemark)
}
    `;
export type SendHiringEmailMutationFn = ApolloReactCommon.MutationFunction<SendHiringEmailMutation, SendHiringEmailMutationVariables>;

/**
 * __useSendHiringEmailMutation__
 *
 * To run a mutation, you first call `useSendHiringEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHiringEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHiringEmailMutation, { data, loading, error }] = useSendHiringEmailMutation({
 *   variables: {
 *      HiringIDs: // value for 'HiringIDs'
 *      dataUpdateStatusLog: // value for 'dataUpdateStatusLog'
 *      emailRemark: // value for 'emailRemark'
 *   },
 * });
 */
export function useSendHiringEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendHiringEmailMutation, SendHiringEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendHiringEmailMutation, SendHiringEmailMutationVariables>(SendHiringEmailDocument, baseOptions);
      }
export type SendHiringEmailMutationHookResult = ReturnType<typeof useSendHiringEmailMutation>;
export type SendHiringEmailMutationResult = ApolloReactCommon.MutationResult<SendHiringEmailMutation>;
export type SendHiringEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendHiringEmailMutation, SendHiringEmailMutationVariables>;
export const QuickHiringListingDocument = gql`
    query QuickHiringListing($offset: Float, $limit: Float, $keyword: String, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!]) {
  QuickHiringListing(offset: $offset, limit: $limit, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs) {
    rec_hiring_id
    org_unit_name
    acceptance_date
    duty_date
    prehiring_status
    FullName
    job_designation_name
    in_use
  }
  QuickHiringListingCount(keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs)
}
    `;

/**
 * __useQuickHiringListingQuery__
 *
 * To run a query within a React component, call `useQuickHiringListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickHiringListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickHiringListingQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *   },
 * });
 */
export function useQuickHiringListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuickHiringListingQuery, QuickHiringListingQueryVariables>) {
        return ApolloReactHooks.useQuery<QuickHiringListingQuery, QuickHiringListingQueryVariables>(QuickHiringListingDocument, baseOptions);
      }
export function useQuickHiringListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuickHiringListingQuery, QuickHiringListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuickHiringListingQuery, QuickHiringListingQueryVariables>(QuickHiringListingDocument, baseOptions);
        }
export type QuickHiringListingQueryHookResult = ReturnType<typeof useQuickHiringListingQuery>;
export type QuickHiringListingLazyQueryHookResult = ReturnType<typeof useQuickHiringListingLazyQuery>;
export type QuickHiringListingQueryResult = ApolloReactCommon.QueryResult<QuickHiringListingQuery, QuickHiringListingQueryVariables>;
export const QuickHiringDetailsDocument = gql`
    query QuickHiringDetails($hiringId: String!) {
  QuickHiringDetails(hiringId: $hiringId) {
    rec_hiring_id
    org_unit_id
    job_designation_name
    company_id
    subscription_account_id
    acceptance_date
    duty_date
    prehiring_status
    source_id
    source_name
    superior_id
    superior_full_name
    superior_no
    job_designation_id
    remark
    remark_voice_file
    contact_id
    rec_talent_pool_id
    full_name
    email
    mobile_no
    org_unit_name
  }
}
    `;

/**
 * __useQuickHiringDetailsQuery__
 *
 * To run a query within a React component, call `useQuickHiringDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickHiringDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickHiringDetailsQuery({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *   },
 * });
 */
export function useQuickHiringDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuickHiringDetailsQuery, QuickHiringDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<QuickHiringDetailsQuery, QuickHiringDetailsQueryVariables>(QuickHiringDetailsDocument, baseOptions);
      }
export function useQuickHiringDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuickHiringDetailsQuery, QuickHiringDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuickHiringDetailsQuery, QuickHiringDetailsQueryVariables>(QuickHiringDetailsDocument, baseOptions);
        }
export type QuickHiringDetailsQueryHookResult = ReturnType<typeof useQuickHiringDetailsQuery>;
export type QuickHiringDetailsLazyQueryHookResult = ReturnType<typeof useQuickHiringDetailsLazyQuery>;
export type QuickHiringDetailsQueryResult = ApolloReactCommon.QueryResult<QuickHiringDetailsQuery, QuickHiringDetailsQueryVariables>;
export const InsertQuickHiringDocument = gql`
    mutation InsertQuickHiring($quickHiringInput: QuickHiringInput!) {
  InsertQuickHiring(quickHiringInput: $quickHiringInput)
}
    `;
export type InsertQuickHiringMutationFn = ApolloReactCommon.MutationFunction<InsertQuickHiringMutation, InsertQuickHiringMutationVariables>;

/**
 * __useInsertQuickHiringMutation__
 *
 * To run a mutation, you first call `useInsertQuickHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQuickHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuickHiringMutation, { data, loading, error }] = useInsertQuickHiringMutation({
 *   variables: {
 *      quickHiringInput: // value for 'quickHiringInput'
 *   },
 * });
 */
export function useInsertQuickHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertQuickHiringMutation, InsertQuickHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertQuickHiringMutation, InsertQuickHiringMutationVariables>(InsertQuickHiringDocument, baseOptions);
      }
export type InsertQuickHiringMutationHookResult = ReturnType<typeof useInsertQuickHiringMutation>;
export type InsertQuickHiringMutationResult = ApolloReactCommon.MutationResult<InsertQuickHiringMutation>;
export type InsertQuickHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertQuickHiringMutation, InsertQuickHiringMutationVariables>;
export const UpdateQuickHiringDocument = gql`
    mutation UpdateQuickHiring($quickHiringId: String!, $quickHiringInput: QuickHiringInput!, $isRemovedVoice: Boolean) {
  UpdateQuickHiring(quickHiringId: $quickHiringId, quickHiringInput: $quickHiringInput, isRemovedVoice: $isRemovedVoice)
}
    `;
export type UpdateQuickHiringMutationFn = ApolloReactCommon.MutationFunction<UpdateQuickHiringMutation, UpdateQuickHiringMutationVariables>;

/**
 * __useUpdateQuickHiringMutation__
 *
 * To run a mutation, you first call `useUpdateQuickHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickHiringMutation, { data, loading, error }] = useUpdateQuickHiringMutation({
 *   variables: {
 *      quickHiringId: // value for 'quickHiringId'
 *      quickHiringInput: // value for 'quickHiringInput'
 *      isRemovedVoice: // value for 'isRemovedVoice'
 *   },
 * });
 */
export function useUpdateQuickHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuickHiringMutation, UpdateQuickHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuickHiringMutation, UpdateQuickHiringMutationVariables>(UpdateQuickHiringDocument, baseOptions);
      }
export type UpdateQuickHiringMutationHookResult = ReturnType<typeof useUpdateQuickHiringMutation>;
export type UpdateQuickHiringMutationResult = ApolloReactCommon.MutationResult<UpdateQuickHiringMutation>;
export type UpdateQuickHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuickHiringMutation, UpdateQuickHiringMutationVariables>;
export const DeleteQuickHiringDocument = gql`
    mutation DeleteQuickHiring($quickHiringId: String!, $talentStatusLog: TalentStatusLog!) {
  DeleteQuickHiring(quickHiringId: $quickHiringId, talentStatusLog: $talentStatusLog)
}
    `;
export type DeleteQuickHiringMutationFn = ApolloReactCommon.MutationFunction<DeleteQuickHiringMutation, DeleteQuickHiringMutationVariables>;

/**
 * __useDeleteQuickHiringMutation__
 *
 * To run a mutation, you first call `useDeleteQuickHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickHiringMutation, { data, loading, error }] = useDeleteQuickHiringMutation({
 *   variables: {
 *      quickHiringId: // value for 'quickHiringId'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useDeleteQuickHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuickHiringMutation, DeleteQuickHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQuickHiringMutation, DeleteQuickHiringMutationVariables>(DeleteQuickHiringDocument, baseOptions);
      }
export type DeleteQuickHiringMutationHookResult = ReturnType<typeof useDeleteQuickHiringMutation>;
export type DeleteQuickHiringMutationResult = ApolloReactCommon.MutationResult<DeleteQuickHiringMutation>;
export type DeleteQuickHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuickHiringMutation, DeleteQuickHiringMutationVariables>;
export const PreHiringDataUpdateListingDocument = gql`
    query PreHiringDataUpdateListing($dataUpdateStatus: DataUpdateStatus!, $offset: Float, $limit: Float, $keyword: String, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!]) {
  PreHiringDataUpdateListing(dataUpdateStatus: $dataUpdateStatus, offset: $offset, limit: $limit, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs) {
    data_update_status
    data_update_status_log
    document_file
    duty_date
    Email
    FullName
    job_designation_name
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    org_unit_name
    rec_hiring_id
    rec_talent_pool_id
    rec_applicant_id
  }
  PreHiringDataUpdateListingCount(dataUpdateStatus: $dataUpdateStatus, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs)
}
    `;

/**
 * __usePreHiringDataUpdateListingQuery__
 *
 * To run a query within a React component, call `usePreHiringDataUpdateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreHiringDataUpdateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreHiringDataUpdateListingQuery({
 *   variables: {
 *      dataUpdateStatus: // value for 'dataUpdateStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *   },
 * });
 */
export function usePreHiringDataUpdateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreHiringDataUpdateListingQuery, PreHiringDataUpdateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PreHiringDataUpdateListingQuery, PreHiringDataUpdateListingQueryVariables>(PreHiringDataUpdateListingDocument, baseOptions);
      }
export function usePreHiringDataUpdateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreHiringDataUpdateListingQuery, PreHiringDataUpdateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PreHiringDataUpdateListingQuery, PreHiringDataUpdateListingQueryVariables>(PreHiringDataUpdateListingDocument, baseOptions);
        }
export type PreHiringDataUpdateListingQueryHookResult = ReturnType<typeof usePreHiringDataUpdateListingQuery>;
export type PreHiringDataUpdateListingLazyQueryHookResult = ReturnType<typeof usePreHiringDataUpdateListingLazyQuery>;
export type PreHiringDataUpdateListingQueryResult = ApolloReactCommon.QueryResult<PreHiringDataUpdateListingQuery, PreHiringDataUpdateListingQueryVariables>;
export const CancelPreHiringDocument = gql`
    mutation CancelPreHiring($hiringId: String!, $cancelRemark: String) {
  CancelPreHiring(hiringId: $hiringId, cancelRemark: $cancelRemark)
}
    `;
export type CancelPreHiringMutationFn = ApolloReactCommon.MutationFunction<CancelPreHiringMutation, CancelPreHiringMutationVariables>;

/**
 * __useCancelPreHiringMutation__
 *
 * To run a mutation, you first call `useCancelPreHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPreHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPreHiringMutation, { data, loading, error }] = useCancelPreHiringMutation({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *      cancelRemark: // value for 'cancelRemark'
 *   },
 * });
 */
export function useCancelPreHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPreHiringMutation, CancelPreHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPreHiringMutation, CancelPreHiringMutationVariables>(CancelPreHiringDocument, baseOptions);
      }
export type CancelPreHiringMutationHookResult = ReturnType<typeof useCancelPreHiringMutation>;
export type CancelPreHiringMutationResult = ApolloReactCommon.MutationResult<CancelPreHiringMutation>;
export type CancelPreHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPreHiringMutation, CancelPreHiringMutationVariables>;
export const ApprovePreHiringDocument = gql`
    mutation ApprovePreHiring($hiringIds: [String!]!, $dataUpdateStatusLog: DataUpdateStatusLog!, $talentStatusLog: TalentStatusLog!) {
  ApprovePreHiring(hiringIds: $hiringIds, dataUpdateStatusLog: $dataUpdateStatusLog, talentStatusLog: $talentStatusLog)
}
    `;
export type ApprovePreHiringMutationFn = ApolloReactCommon.MutationFunction<ApprovePreHiringMutation, ApprovePreHiringMutationVariables>;

/**
 * __useApprovePreHiringMutation__
 *
 * To run a mutation, you first call `useApprovePreHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePreHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePreHiringMutation, { data, loading, error }] = useApprovePreHiringMutation({
 *   variables: {
 *      hiringIds: // value for 'hiringIds'
 *      dataUpdateStatusLog: // value for 'dataUpdateStatusLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useApprovePreHiringMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovePreHiringMutation, ApprovePreHiringMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovePreHiringMutation, ApprovePreHiringMutationVariables>(ApprovePreHiringDocument, baseOptions);
      }
export type ApprovePreHiringMutationHookResult = ReturnType<typeof useApprovePreHiringMutation>;
export type ApprovePreHiringMutationResult = ApolloReactCommon.MutationResult<ApprovePreHiringMutation>;
export type ApprovePreHiringMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovePreHiringMutation, ApprovePreHiringMutationVariables>;
export const DataUpdateStatusLogDocument = gql`
    mutation DataUpdateStatusLog($hiringId: String!, $input: DataUpdateStatusLog!) {
  DataUpdateStatusLog(hiringId: $hiringId, input: $input)
}
    `;
export type DataUpdateStatusLogMutationFn = ApolloReactCommon.MutationFunction<DataUpdateStatusLogMutation, DataUpdateStatusLogMutationVariables>;

/**
 * __useDataUpdateStatusLogMutation__
 *
 * To run a mutation, you first call `useDataUpdateStatusLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataUpdateStatusLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataUpdateStatusLogMutation, { data, loading, error }] = useDataUpdateStatusLogMutation({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDataUpdateStatusLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataUpdateStatusLogMutation, DataUpdateStatusLogMutationVariables>) {
        return ApolloReactHooks.useMutation<DataUpdateStatusLogMutation, DataUpdateStatusLogMutationVariables>(DataUpdateStatusLogDocument, baseOptions);
      }
export type DataUpdateStatusLogMutationHookResult = ReturnType<typeof useDataUpdateStatusLogMutation>;
export type DataUpdateStatusLogMutationResult = ApolloReactCommon.MutationResult<DataUpdateStatusLogMutation>;
export type DataUpdateStatusLogMutationOptions = ApolloReactCommon.BaseMutationOptions<DataUpdateStatusLogMutation, DataUpdateStatusLogMutationVariables>;
export const NewHireProcessListingDocument = gql`
    query NewHireProcessListing($offset: Float, $limit: Float, $keyword: String, $StartDate: DateTime, $EndDate: DateTime, $OrgUnitIDs: [String!], $JobPositionIDs: [String!], $OnboardStatus: [OnboardStatus!]!) {
  NewHireProcessListing(offset: $offset, limit: $limit, keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, OnboardStatus: $OnboardStatus) {
    data_update_status
    data_update_status_log
    onboard_status
    onboard_approval_log
    submitter_id
    submitter_name
    submitter_no
    document_file
    duty_date
    Email
    FullName
    job_designation_id
    job_designation_name
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    org_unit_name
    rec_hiring_id
    rec_talent_pool_id
    rec_applicant_id
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
  NewHireProcessListingCount(keyword: $keyword, StartDate: $StartDate, EndDate: $EndDate, OrgUnitIDs: $OrgUnitIDs, JobPositionIDs: $JobPositionIDs, OnboardStatus: $OnboardStatus)
}
    `;

/**
 * __useNewHireProcessListingQuery__
 *
 * To run a query within a React component, call `useNewHireProcessListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHireProcessListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHireProcessListingQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobPositionIDs: // value for 'JobPositionIDs'
 *      OnboardStatus: // value for 'OnboardStatus'
 *   },
 * });
 */
export function useNewHireProcessListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewHireProcessListingQuery, NewHireProcessListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NewHireProcessListingQuery, NewHireProcessListingQueryVariables>(NewHireProcessListingDocument, baseOptions);
      }
export function useNewHireProcessListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewHireProcessListingQuery, NewHireProcessListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewHireProcessListingQuery, NewHireProcessListingQueryVariables>(NewHireProcessListingDocument, baseOptions);
        }
export type NewHireProcessListingQueryHookResult = ReturnType<typeof useNewHireProcessListingQuery>;
export type NewHireProcessListingLazyQueryHookResult = ReturnType<typeof useNewHireProcessListingLazyQuery>;
export type NewHireProcessListingQueryResult = ApolloReactCommon.QueryResult<NewHireProcessListingQuery, NewHireProcessListingQueryVariables>;
export const DeclineNewHiringProcessDocument = gql`
    mutation DeclineNewHiringProcess($hiringId: String!, $talentStatusLog: TalentStatusLog!, $declineDate: DateTime!, $declineReason: String!, $declineRemark: String, $declineRemarkVoice: DocumentsInput) {
  DeclineNewHiringProcess(hiringId: $hiringId, talentStatusLog: $talentStatusLog, declineDate: $declineDate, declineReason: $declineReason, declineRemark: $declineRemark, declineRemarkVoice: $declineRemarkVoice)
}
    `;
export type DeclineNewHiringProcessMutationFn = ApolloReactCommon.MutationFunction<DeclineNewHiringProcessMutation, DeclineNewHiringProcessMutationVariables>;

/**
 * __useDeclineNewHiringProcessMutation__
 *
 * To run a mutation, you first call `useDeclineNewHiringProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineNewHiringProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineNewHiringProcessMutation, { data, loading, error }] = useDeclineNewHiringProcessMutation({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *      talentStatusLog: // value for 'talentStatusLog'
 *      declineDate: // value for 'declineDate'
 *      declineReason: // value for 'declineReason'
 *      declineRemark: // value for 'declineRemark'
 *      declineRemarkVoice: // value for 'declineRemarkVoice'
 *   },
 * });
 */
export function useDeclineNewHiringProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineNewHiringProcessMutation, DeclineNewHiringProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclineNewHiringProcessMutation, DeclineNewHiringProcessMutationVariables>(DeclineNewHiringProcessDocument, baseOptions);
      }
export type DeclineNewHiringProcessMutationHookResult = ReturnType<typeof useDeclineNewHiringProcessMutation>;
export type DeclineNewHiringProcessMutationResult = ApolloReactCommon.MutationResult<DeclineNewHiringProcessMutation>;
export type DeclineNewHiringProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineNewHiringProcessMutation, DeclineNewHiringProcessMutationVariables>;
export const NewHireProcessOnboardInfoDocument = gql`
    query NewHireProcessOnboardInfo($hiringId: String!) {
  NewHireProcessOnboardInfo(hiringId: $hiringId) {
    contact_id
    org_unit_id
    superior_id
    source_id
    duty_date
    probation_month
    full_name
    job_designation_id
    job_designation_name
    company_id
    superior_full_name
    superior_role
    recruitment_source_name
    org_unit_name
  }
}
    `;

/**
 * __useNewHireProcessOnboardInfoQuery__
 *
 * To run a query within a React component, call `useNewHireProcessOnboardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHireProcessOnboardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHireProcessOnboardInfoQuery({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *   },
 * });
 */
export function useNewHireProcessOnboardInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewHireProcessOnboardInfoQuery, NewHireProcessOnboardInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<NewHireProcessOnboardInfoQuery, NewHireProcessOnboardInfoQueryVariables>(NewHireProcessOnboardInfoDocument, baseOptions);
      }
export function useNewHireProcessOnboardInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewHireProcessOnboardInfoQuery, NewHireProcessOnboardInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewHireProcessOnboardInfoQuery, NewHireProcessOnboardInfoQueryVariables>(NewHireProcessOnboardInfoDocument, baseOptions);
        }
export type NewHireProcessOnboardInfoQueryHookResult = ReturnType<typeof useNewHireProcessOnboardInfoQuery>;
export type NewHireProcessOnboardInfoLazyQueryHookResult = ReturnType<typeof useNewHireProcessOnboardInfoLazyQuery>;
export type NewHireProcessOnboardInfoQueryResult = ApolloReactCommon.QueryResult<NewHireProcessOnboardInfoQuery, NewHireProcessOnboardInfoQueryVariables>;
export const OnboardNewHireProcessDocument = gql`
    mutation OnboardNewHireProcess($hiringId: String!, $input: OnboardNewHireInput!) {
  OnboardNewHireProcess(hiringId: $hiringId, input: $input)
}
    `;
export type OnboardNewHireProcessMutationFn = ApolloReactCommon.MutationFunction<OnboardNewHireProcessMutation, OnboardNewHireProcessMutationVariables>;

/**
 * __useOnboardNewHireProcessMutation__
 *
 * To run a mutation, you first call `useOnboardNewHireProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardNewHireProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardNewHireProcessMutation, { data, loading, error }] = useOnboardNewHireProcessMutation({
 *   variables: {
 *      hiringId: // value for 'hiringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardNewHireProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnboardNewHireProcessMutation, OnboardNewHireProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<OnboardNewHireProcessMutation, OnboardNewHireProcessMutationVariables>(OnboardNewHireProcessDocument, baseOptions);
      }
export type OnboardNewHireProcessMutationHookResult = ReturnType<typeof useOnboardNewHireProcessMutation>;
export type OnboardNewHireProcessMutationResult = ApolloReactCommon.MutationResult<OnboardNewHireProcessMutation>;
export type OnboardNewHireProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<OnboardNewHireProcessMutation, OnboardNewHireProcessMutationVariables>;
export const TalentPoolListingDocument = gql`
    query TalentPoolListing($jobPositions: [String!], $preferLocations: [String!], $extraFilters: [JSON!], $offset: Float, $limit: Float, $talentStatus: [RecruitmentTalentStatus!], $keyword: String) {
  TalentPoolListing(jobPositions: $jobPositions, preferLocations: $preferLocations, extraFilters: $extraFilters, offset: $offset, limit: $limit, talentStatus: $talentStatus, keyword: $keyword) {
    rec_talent_pool_id
    preferred_location
    status
    talent_status
    talent_status_log
    availability_date
    expected_salary
    is_hire
    is_blacklist
    application_count
    applicant_ids
    talent_image
    talent_name
    job_position
    company_name
    start_date
    end_date
    last_drawn_salary
    is_current_job
  }
  TalentPoolListingCount(jobPositions: $jobPositions, preferLocations: $preferLocations, extraFilters: $extraFilters, talentStatus: $talentStatus, keyword: $keyword)
}
    `;

/**
 * __useTalentPoolListingQuery__
 *
 * To run a query within a React component, call `useTalentPoolListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentPoolListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentPoolListingQuery({
 *   variables: {
 *      jobPositions: // value for 'jobPositions'
 *      preferLocations: // value for 'preferLocations'
 *      extraFilters: // value for 'extraFilters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      talentStatus: // value for 'talentStatus'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useTalentPoolListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentPoolListingQuery, TalentPoolListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentPoolListingQuery, TalentPoolListingQueryVariables>(TalentPoolListingDocument, baseOptions);
      }
export function useTalentPoolListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentPoolListingQuery, TalentPoolListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentPoolListingQuery, TalentPoolListingQueryVariables>(TalentPoolListingDocument, baseOptions);
        }
export type TalentPoolListingQueryHookResult = ReturnType<typeof useTalentPoolListingQuery>;
export type TalentPoolListingLazyQueryHookResult = ReturnType<typeof useTalentPoolListingLazyQuery>;
export type TalentPoolListingQueryResult = ApolloReactCommon.QueryResult<TalentPoolListingQuery, TalentPoolListingQueryVariables>;
export const TalentPoolDeleteDocument = gql`
    mutation TalentPoolDelete($TalentPoolID: String!) {
  TalentPoolDelete(TalentPoolID: $TalentPoolID)
}
    `;
export type TalentPoolDeleteMutationFn = ApolloReactCommon.MutationFunction<TalentPoolDeleteMutation, TalentPoolDeleteMutationVariables>;

/**
 * __useTalentPoolDeleteMutation__
 *
 * To run a mutation, you first call `useTalentPoolDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentPoolDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentPoolDeleteMutation, { data, loading, error }] = useTalentPoolDeleteMutation({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *   },
 * });
 */
export function useTalentPoolDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentPoolDeleteMutation, TalentPoolDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentPoolDeleteMutation, TalentPoolDeleteMutationVariables>(TalentPoolDeleteDocument, baseOptions);
      }
export type TalentPoolDeleteMutationHookResult = ReturnType<typeof useTalentPoolDeleteMutation>;
export type TalentPoolDeleteMutationResult = ApolloReactCommon.MutationResult<TalentPoolDeleteMutation>;
export type TalentPoolDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentPoolDeleteMutation, TalentPoolDeleteMutationVariables>;
export const TalentProfileSummaryDocument = gql`
    query TalentProfileSummary($TalentPoolID: String!, $type: String) {
  TalentProfileSummary(TalentPoolID: $TalentPoolID, type: $type)
}
    `;

/**
 * __useTalentProfileSummaryQuery__
 *
 * To run a query within a React component, call `useTalentProfileSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentProfileSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentProfileSummaryQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTalentProfileSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentProfileSummaryQuery, TalentProfileSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentProfileSummaryQuery, TalentProfileSummaryQueryVariables>(TalentProfileSummaryDocument, baseOptions);
      }
export function useTalentProfileSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentProfileSummaryQuery, TalentProfileSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentProfileSummaryQuery, TalentProfileSummaryQueryVariables>(TalentProfileSummaryDocument, baseOptions);
        }
export type TalentProfileSummaryQueryHookResult = ReturnType<typeof useTalentProfileSummaryQuery>;
export type TalentProfileSummaryLazyQueryHookResult = ReturnType<typeof useTalentProfileSummaryLazyQuery>;
export type TalentProfileSummaryQueryResult = ApolloReactCommon.QueryResult<TalentProfileSummaryQuery, TalentProfileSummaryQueryVariables>;
export const TalentProfileDetailDocument = gql`
    query TalentProfileDetail($typeID: String!, $type: String!, $formType: String) {
  TalentProfileDetail(typeID: $typeID, type: $type, formType: $formType)
}
    `;

/**
 * __useTalentProfileDetailQuery__
 *
 * To run a query within a React component, call `useTalentProfileDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentProfileDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentProfileDetailQuery({
 *   variables: {
 *      typeID: // value for 'typeID'
 *      type: // value for 'type'
 *      formType: // value for 'formType'
 *   },
 * });
 */
export function useTalentProfileDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentProfileDetailQuery, TalentProfileDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentProfileDetailQuery, TalentProfileDetailQueryVariables>(TalentProfileDetailDocument, baseOptions);
      }
export function useTalentProfileDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentProfileDetailQuery, TalentProfileDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentProfileDetailQuery, TalentProfileDetailQueryVariables>(TalentProfileDetailDocument, baseOptions);
        }
export type TalentProfileDetailQueryHookResult = ReturnType<typeof useTalentProfileDetailQuery>;
export type TalentProfileDetailLazyQueryHookResult = ReturnType<typeof useTalentProfileDetailLazyQuery>;
export type TalentProfileDetailQueryResult = ApolloReactCommon.QueryResult<TalentProfileDetailQuery, TalentProfileDetailQueryVariables>;
export const TalentHealthInfoDocument = gql`
    query TalentHealthInfo($PersonnelHealthInfoID: String!) {
  TalentHealthInfo(PersonnelHealthInfoID: $PersonnelHealthInfoID) {
    PersonnelHealthInfoID
    Height
    Weight
    BMI
    BloodType
    DietaryPreference
    IsSmoker
    VaccinationList
    VaccinationListSummary {
      vaccinationID
      vaccinationDate
      vaccinationAttachedment {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
    MedicalRecords {
      MedicalRecordID
      DocumentID
      Description
      Document {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __useTalentHealthInfoQuery__
 *
 * To run a query within a React component, call `useTalentHealthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentHealthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentHealthInfoQuery({
 *   variables: {
 *      PersonnelHealthInfoID: // value for 'PersonnelHealthInfoID'
 *   },
 * });
 */
export function useTalentHealthInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentHealthInfoQuery, TalentHealthInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentHealthInfoQuery, TalentHealthInfoQueryVariables>(TalentHealthInfoDocument, baseOptions);
      }
export function useTalentHealthInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentHealthInfoQuery, TalentHealthInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentHealthInfoQuery, TalentHealthInfoQueryVariables>(TalentHealthInfoDocument, baseOptions);
        }
export type TalentHealthInfoQueryHookResult = ReturnType<typeof useTalentHealthInfoQuery>;
export type TalentHealthInfoLazyQueryHookResult = ReturnType<typeof useTalentHealthInfoLazyQuery>;
export type TalentHealthInfoQueryResult = ApolloReactCommon.QueryResult<TalentHealthInfoQuery, TalentHealthInfoQueryVariables>;
export const TalentPersonalInsertDocument = gql`
    mutation TalentPersonalInsert($contactInput: ContactInput!, $documentInput: DocumentsInput, $talentInput: Rec_TalentPoolInput!, $employeeInput: EmployeeInput) {
  TalentPersonalInsert(contactInput: $contactInput, documentInput: $documentInput, talentInput: $talentInput, employeeInput: $employeeInput)
}
    `;
export type TalentPersonalInsertMutationFn = ApolloReactCommon.MutationFunction<TalentPersonalInsertMutation, TalentPersonalInsertMutationVariables>;

/**
 * __useTalentPersonalInsertMutation__
 *
 * To run a mutation, you first call `useTalentPersonalInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentPersonalInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentPersonalInsertMutation, { data, loading, error }] = useTalentPersonalInsertMutation({
 *   variables: {
 *      contactInput: // value for 'contactInput'
 *      documentInput: // value for 'documentInput'
 *      talentInput: // value for 'talentInput'
 *      employeeInput: // value for 'employeeInput'
 *   },
 * });
 */
export function useTalentPersonalInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentPersonalInsertMutation, TalentPersonalInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentPersonalInsertMutation, TalentPersonalInsertMutationVariables>(TalentPersonalInsertDocument, baseOptions);
      }
export type TalentPersonalInsertMutationHookResult = ReturnType<typeof useTalentPersonalInsertMutation>;
export type TalentPersonalInsertMutationResult = ApolloReactCommon.MutationResult<TalentPersonalInsertMutation>;
export type TalentPersonalInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentPersonalInsertMutation, TalentPersonalInsertMutationVariables>;
export const TalentEducationInsertDocument = gql`
    mutation TalentEducationInsert($input: PersonnelEducationInsertInput!, $TalentPoolID: String, $PersonnelEducationID: String) {
  TalentEducationInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelEducationID: $PersonnelEducationID)
}
    `;
export type TalentEducationInsertMutationFn = ApolloReactCommon.MutationFunction<TalentEducationInsertMutation, TalentEducationInsertMutationVariables>;

/**
 * __useTalentEducationInsertMutation__
 *
 * To run a mutation, you first call `useTalentEducationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentEducationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentEducationInsertMutation, { data, loading, error }] = useTalentEducationInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelEducationID: // value for 'PersonnelEducationID'
 *   },
 * });
 */
export function useTalentEducationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentEducationInsertMutation, TalentEducationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentEducationInsertMutation, TalentEducationInsertMutationVariables>(TalentEducationInsertDocument, baseOptions);
      }
export type TalentEducationInsertMutationHookResult = ReturnType<typeof useTalentEducationInsertMutation>;
export type TalentEducationInsertMutationResult = ApolloReactCommon.MutationResult<TalentEducationInsertMutation>;
export type TalentEducationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentEducationInsertMutation, TalentEducationInsertMutationVariables>;
export const TalentMembershipInsertDocument = gql`
    mutation TalentMembershipInsert($input: PersonnelMembershipInput!, $TalentPoolID: String, $PersonnelMembershipID: String) {
  TalentMembershipInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelMembershipID: $PersonnelMembershipID)
}
    `;
export type TalentMembershipInsertMutationFn = ApolloReactCommon.MutationFunction<TalentMembershipInsertMutation, TalentMembershipInsertMutationVariables>;

/**
 * __useTalentMembershipInsertMutation__
 *
 * To run a mutation, you first call `useTalentMembershipInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentMembershipInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentMembershipInsertMutation, { data, loading, error }] = useTalentMembershipInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelMembershipID: // value for 'PersonnelMembershipID'
 *   },
 * });
 */
export function useTalentMembershipInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentMembershipInsertMutation, TalentMembershipInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentMembershipInsertMutation, TalentMembershipInsertMutationVariables>(TalentMembershipInsertDocument, baseOptions);
      }
export type TalentMembershipInsertMutationHookResult = ReturnType<typeof useTalentMembershipInsertMutation>;
export type TalentMembershipInsertMutationResult = ApolloReactCommon.MutationResult<TalentMembershipInsertMutation>;
export type TalentMembershipInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentMembershipInsertMutation, TalentMembershipInsertMutationVariables>;
export const TalentEmpHistoryInsertDocument = gql`
    mutation TalentEmpHistoryInsert($input: PersonnelJobHistoryInput!, $TalentPoolID: String, $PersonnelJobHistoryID: String) {
  TalentEmpHistoryInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelJobHistoryID: $PersonnelJobHistoryID)
}
    `;
export type TalentEmpHistoryInsertMutationFn = ApolloReactCommon.MutationFunction<TalentEmpHistoryInsertMutation, TalentEmpHistoryInsertMutationVariables>;

/**
 * __useTalentEmpHistoryInsertMutation__
 *
 * To run a mutation, you first call `useTalentEmpHistoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentEmpHistoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentEmpHistoryInsertMutation, { data, loading, error }] = useTalentEmpHistoryInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelJobHistoryID: // value for 'PersonnelJobHistoryID'
 *   },
 * });
 */
export function useTalentEmpHistoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentEmpHistoryInsertMutation, TalentEmpHistoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentEmpHistoryInsertMutation, TalentEmpHistoryInsertMutationVariables>(TalentEmpHistoryInsertDocument, baseOptions);
      }
export type TalentEmpHistoryInsertMutationHookResult = ReturnType<typeof useTalentEmpHistoryInsertMutation>;
export type TalentEmpHistoryInsertMutationResult = ApolloReactCommon.MutationResult<TalentEmpHistoryInsertMutation>;
export type TalentEmpHistoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentEmpHistoryInsertMutation, TalentEmpHistoryInsertMutationVariables>;
export const TalentReferenceInsertDocument = gql`
    mutation TalentReferenceInsert($input: PersonnelReferenceInput!, $TalentPoolID: String, $PersonnelReferenceID: String) {
  TalentReferenceInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelReferenceID: $PersonnelReferenceID)
}
    `;
export type TalentReferenceInsertMutationFn = ApolloReactCommon.MutationFunction<TalentReferenceInsertMutation, TalentReferenceInsertMutationVariables>;

/**
 * __useTalentReferenceInsertMutation__
 *
 * To run a mutation, you first call `useTalentReferenceInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentReferenceInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentReferenceInsertMutation, { data, loading, error }] = useTalentReferenceInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelReferenceID: // value for 'PersonnelReferenceID'
 *   },
 * });
 */
export function useTalentReferenceInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentReferenceInsertMutation, TalentReferenceInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentReferenceInsertMutation, TalentReferenceInsertMutationVariables>(TalentReferenceInsertDocument, baseOptions);
      }
export type TalentReferenceInsertMutationHookResult = ReturnType<typeof useTalentReferenceInsertMutation>;
export type TalentReferenceInsertMutationResult = ApolloReactCommon.MutationResult<TalentReferenceInsertMutation>;
export type TalentReferenceInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentReferenceInsertMutation, TalentReferenceInsertMutationVariables>;
export const TalentHealthInfoInsertDocument = gql`
    mutation TalentHealthInfoInsert($healthInput: PersonnelHealthInfoInput!, $attachmentToRemove: [DocumentsInput!], $medicalRecordInput: [MedicalRecordInput!]!, $vaccinationRecordInput: [VaccinationRecordInput!], $TalentPoolID: String, $PersonnelHealthInfoID: String) {
  TalentHealthInfoInsert(healthInput: $healthInput, attachmentToRemove: $attachmentToRemove, medicalRecordInput: $medicalRecordInput, vaccinationRecordInput: $vaccinationRecordInput, TalentPoolID: $TalentPoolID, PersonnelHealthInfoID: $PersonnelHealthInfoID)
}
    `;
export type TalentHealthInfoInsertMutationFn = ApolloReactCommon.MutationFunction<TalentHealthInfoInsertMutation, TalentHealthInfoInsertMutationVariables>;

/**
 * __useTalentHealthInfoInsertMutation__
 *
 * To run a mutation, you first call `useTalentHealthInfoInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentHealthInfoInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentHealthInfoInsertMutation, { data, loading, error }] = useTalentHealthInfoInsertMutation({
 *   variables: {
 *      healthInput: // value for 'healthInput'
 *      attachmentToRemove: // value for 'attachmentToRemove'
 *      medicalRecordInput: // value for 'medicalRecordInput'
 *      vaccinationRecordInput: // value for 'vaccinationRecordInput'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelHealthInfoID: // value for 'PersonnelHealthInfoID'
 *   },
 * });
 */
export function useTalentHealthInfoInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentHealthInfoInsertMutation, TalentHealthInfoInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentHealthInfoInsertMutation, TalentHealthInfoInsertMutationVariables>(TalentHealthInfoInsertDocument, baseOptions);
      }
export type TalentHealthInfoInsertMutationHookResult = ReturnType<typeof useTalentHealthInfoInsertMutation>;
export type TalentHealthInfoInsertMutationResult = ApolloReactCommon.MutationResult<TalentHealthInfoInsertMutation>;
export type TalentHealthInfoInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentHealthInfoInsertMutation, TalentHealthInfoInsertMutationVariables>;
export const TalentStatutoryInsertDocument = gql`
    mutation TalentStatutoryInsert($input: Rec_TalentStatutoryInput!, $TalentPoolID: String, $TalentStatutoryID: String) {
  TalentStatutoryInsert(input: $input, TalentPoolID: $TalentPoolID, TalentStatutoryID: $TalentStatutoryID)
}
    `;
export type TalentStatutoryInsertMutationFn = ApolloReactCommon.MutationFunction<TalentStatutoryInsertMutation, TalentStatutoryInsertMutationVariables>;

/**
 * __useTalentStatutoryInsertMutation__
 *
 * To run a mutation, you first call `useTalentStatutoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentStatutoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentStatutoryInsertMutation, { data, loading, error }] = useTalentStatutoryInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      TalentStatutoryID: // value for 'TalentStatutoryID'
 *   },
 * });
 */
export function useTalentStatutoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentStatutoryInsertMutation, TalentStatutoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentStatutoryInsertMutation, TalentStatutoryInsertMutationVariables>(TalentStatutoryInsertDocument, baseOptions);
      }
export type TalentStatutoryInsertMutationHookResult = ReturnType<typeof useTalentStatutoryInsertMutation>;
export type TalentStatutoryInsertMutationResult = ApolloReactCommon.MutationResult<TalentStatutoryInsertMutation>;
export type TalentStatutoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentStatutoryInsertMutation, TalentStatutoryInsertMutationVariables>;
export const TalentCertificateInsertDocument = gql`
    mutation TalentCertificateInsert($input: PersonnelCertificateInput!, $TalentPoolID: String, $PersonnelCertificateID: String) {
  TalentCertificateInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelCertificateID: $PersonnelCertificateID)
}
    `;
export type TalentCertificateInsertMutationFn = ApolloReactCommon.MutationFunction<TalentCertificateInsertMutation, TalentCertificateInsertMutationVariables>;

/**
 * __useTalentCertificateInsertMutation__
 *
 * To run a mutation, you first call `useTalentCertificateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentCertificateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentCertificateInsertMutation, { data, loading, error }] = useTalentCertificateInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelCertificateID: // value for 'PersonnelCertificateID'
 *   },
 * });
 */
export function useTalentCertificateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentCertificateInsertMutation, TalentCertificateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentCertificateInsertMutation, TalentCertificateInsertMutationVariables>(TalentCertificateInsertDocument, baseOptions);
      }
export type TalentCertificateInsertMutationHookResult = ReturnType<typeof useTalentCertificateInsertMutation>;
export type TalentCertificateInsertMutationResult = ApolloReactCommon.MutationResult<TalentCertificateInsertMutation>;
export type TalentCertificateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentCertificateInsertMutation, TalentCertificateInsertMutationVariables>;
export const TalentDependentInsertDocument = gql`
    mutation TalentDependentInsert($dependentInput: EmployeeDependentsInput!, $contactInput: ContactInput!, $TalentPoolID: String, $EmployeeDependentsID: String) {
  TalentDependentInsert(dependentInput: $dependentInput, contactInput: $contactInput, TalentPoolID: $TalentPoolID, EmployeeDependentsID: $EmployeeDependentsID)
}
    `;
export type TalentDependentInsertMutationFn = ApolloReactCommon.MutationFunction<TalentDependentInsertMutation, TalentDependentInsertMutationVariables>;

/**
 * __useTalentDependentInsertMutation__
 *
 * To run a mutation, you first call `useTalentDependentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentDependentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentDependentInsertMutation, { data, loading, error }] = useTalentDependentInsertMutation({
 *   variables: {
 *      dependentInput: // value for 'dependentInput'
 *      contactInput: // value for 'contactInput'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      EmployeeDependentsID: // value for 'EmployeeDependentsID'
 *   },
 * });
 */
export function useTalentDependentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentDependentInsertMutation, TalentDependentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentDependentInsertMutation, TalentDependentInsertMutationVariables>(TalentDependentInsertDocument, baseOptions);
      }
export type TalentDependentInsertMutationHookResult = ReturnType<typeof useTalentDependentInsertMutation>;
export type TalentDependentInsertMutationResult = ApolloReactCommon.MutationResult<TalentDependentInsertMutation>;
export type TalentDependentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentDependentInsertMutation, TalentDependentInsertMutationVariables>;
export const TalentLanguageInsertDocument = gql`
    mutation TalentLanguageInsert($input: [PersonnelLanguageInput!]!, $TalentPoolID: String, $PersonnelLanguageID: String) {
  TalentLanguageInsert(input: $input, TalentPoolID: $TalentPoolID, PersonnelLanguageID: $PersonnelLanguageID)
}
    `;
export type TalentLanguageInsertMutationFn = ApolloReactCommon.MutationFunction<TalentLanguageInsertMutation, TalentLanguageInsertMutationVariables>;

/**
 * __useTalentLanguageInsertMutation__
 *
 * To run a mutation, you first call `useTalentLanguageInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentLanguageInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentLanguageInsertMutation, { data, loading, error }] = useTalentLanguageInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      PersonnelLanguageID: // value for 'PersonnelLanguageID'
 *   },
 * });
 */
export function useTalentLanguageInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentLanguageInsertMutation, TalentLanguageInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentLanguageInsertMutation, TalentLanguageInsertMutationVariables>(TalentLanguageInsertDocument, baseOptions);
      }
export type TalentLanguageInsertMutationHookResult = ReturnType<typeof useTalentLanguageInsertMutation>;
export type TalentLanguageInsertMutationResult = ApolloReactCommon.MutationResult<TalentLanguageInsertMutation>;
export type TalentLanguageInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentLanguageInsertMutation, TalentLanguageInsertMutationVariables>;
export const TalentDocInsertDocument = gql`
    mutation TalentDocInsert($input: DocumentsInput!, $TalentPoolID: String, $DocumentID: String) {
  TalentDocInsert(input: $input, TalentPoolID: $TalentPoolID, DocumentID: $DocumentID)
}
    `;
export type TalentDocInsertMutationFn = ApolloReactCommon.MutationFunction<TalentDocInsertMutation, TalentDocInsertMutationVariables>;

/**
 * __useTalentDocInsertMutation__
 *
 * To run a mutation, you first call `useTalentDocInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentDocInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentDocInsertMutation, { data, loading, error }] = useTalentDocInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TalentPoolID: // value for 'TalentPoolID'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useTalentDocInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentDocInsertMutation, TalentDocInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentDocInsertMutation, TalentDocInsertMutationVariables>(TalentDocInsertDocument, baseOptions);
      }
export type TalentDocInsertMutationHookResult = ReturnType<typeof useTalentDocInsertMutation>;
export type TalentDocInsertMutationResult = ApolloReactCommon.MutationResult<TalentDocInsertMutation>;
export type TalentDocInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentDocInsertMutation, TalentDocInsertMutationVariables>;
export const TalentInfoDeleteDocument = gql`
    mutation TalentInfoDelete($typeID: String!, $type: String!) {
  TalentInfoDelete(typeID: $typeID, type: $type)
}
    `;
export type TalentInfoDeleteMutationFn = ApolloReactCommon.MutationFunction<TalentInfoDeleteMutation, TalentInfoDeleteMutationVariables>;

/**
 * __useTalentInfoDeleteMutation__
 *
 * To run a mutation, you first call `useTalentInfoDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTalentInfoDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [talentInfoDeleteMutation, { data, loading, error }] = useTalentInfoDeleteMutation({
 *   variables: {
 *      typeID: // value for 'typeID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTalentInfoDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TalentInfoDeleteMutation, TalentInfoDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TalentInfoDeleteMutation, TalentInfoDeleteMutationVariables>(TalentInfoDeleteDocument, baseOptions);
      }
export type TalentInfoDeleteMutationHookResult = ReturnType<typeof useTalentInfoDeleteMutation>;
export type TalentInfoDeleteMutationResult = ApolloReactCommon.MutationResult<TalentInfoDeleteMutation>;
export type TalentInfoDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TalentInfoDeleteMutation, TalentInfoDeleteMutationVariables>;
export const UpdateTalentStatusDocument = gql`
    mutation UpdateTalentStatus($talentPoolID: String!, $talentStatus: RecruitmentTalentStatus!, $talentStatusLog: TalentStatusLog!) {
  UpdateTalentStatus(talentPoolID: $talentPoolID, talentStatus: $talentStatus, talentStatusLog: $talentStatusLog)
}
    `;
export type UpdateTalentStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTalentStatusMutation, UpdateTalentStatusMutationVariables>;

/**
 * __useUpdateTalentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTalentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentStatusMutation, { data, loading, error }] = useUpdateTalentStatusMutation({
 *   variables: {
 *      talentPoolID: // value for 'talentPoolID'
 *      talentStatus: // value for 'talentStatus'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useUpdateTalentStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTalentStatusMutation, UpdateTalentStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTalentStatusMutation, UpdateTalentStatusMutationVariables>(UpdateTalentStatusDocument, baseOptions);
      }
export type UpdateTalentStatusMutationHookResult = ReturnType<typeof useUpdateTalentStatusMutation>;
export type UpdateTalentStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTalentStatusMutation>;
export type UpdateTalentStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTalentStatusMutation, UpdateTalentStatusMutationVariables>;
export const TalentApplicationListingDocument = gql`
    query TalentApplicationListing($TalentPoolID: String!, $offset: Float, $limit: Float) {
  TalentApplicationListing(TalentPoolID: $TalentPoolID, offset: $offset, limit: $limit) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_name
    org_unit_name
    application_stage
    has_access
  }
}
    `;

/**
 * __useTalentApplicationListingQuery__
 *
 * To run a query within a React component, call `useTalentApplicationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationListingQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTalentApplicationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentApplicationListingQuery, TalentApplicationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentApplicationListingQuery, TalentApplicationListingQueryVariables>(TalentApplicationListingDocument, baseOptions);
      }
export function useTalentApplicationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentApplicationListingQuery, TalentApplicationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentApplicationListingQuery, TalentApplicationListingQueryVariables>(TalentApplicationListingDocument, baseOptions);
        }
export type TalentApplicationListingQueryHookResult = ReturnType<typeof useTalentApplicationListingQuery>;
export type TalentApplicationListingLazyQueryHookResult = ReturnType<typeof useTalentApplicationListingLazyQuery>;
export type TalentApplicationListingQueryResult = ApolloReactCommon.QueryResult<TalentApplicationListingQuery, TalentApplicationListingQueryVariables>;
export const TalentJobVacancyAppliedListingDocument = gql`
    query TalentJobVacancyAppliedListing($TalentPoolID: String!) {
  TalentJobVacancyAppliedListing(TalentPoolID: $TalentPoolID) {
    rec_job_vacancy_id
    rec_requisition_id
    job_vacancy_no
    job_vacancy_status
    target_hiring_date
    requisition_no
    job_designation_name
    org_unit_name
    is_applied
  }
}
    `;

/**
 * __useTalentJobVacancyAppliedListingQuery__
 *
 * To run a query within a React component, call `useTalentJobVacancyAppliedListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentJobVacancyAppliedListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentJobVacancyAppliedListingQuery({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *   },
 * });
 */
export function useTalentJobVacancyAppliedListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentJobVacancyAppliedListingQuery, TalentJobVacancyAppliedListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentJobVacancyAppliedListingQuery, TalentJobVacancyAppliedListingQueryVariables>(TalentJobVacancyAppliedListingDocument, baseOptions);
      }
export function useTalentJobVacancyAppliedListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentJobVacancyAppliedListingQuery, TalentJobVacancyAppliedListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentJobVacancyAppliedListingQuery, TalentJobVacancyAppliedListingQueryVariables>(TalentJobVacancyAppliedListingDocument, baseOptions);
        }
export type TalentJobVacancyAppliedListingQueryHookResult = ReturnType<typeof useTalentJobVacancyAppliedListingQuery>;
export type TalentJobVacancyAppliedListingLazyQueryHookResult = ReturnType<typeof useTalentJobVacancyAppliedListingLazyQuery>;
export type TalentJobVacancyAppliedListingQueryResult = ApolloReactCommon.QueryResult<TalentJobVacancyAppliedListingQuery, TalentJobVacancyAppliedListingQueryVariables>;
export const AddTalentToPrescreenDocument = gql`
    mutation AddTalentToPrescreen($TalentPoolID: String!, $JobVacancyIDs: [String!]!, $applicantLog: ApplicantStatusLog!, $talentStatusLog: TalentStatusLog!) {
  AddTalentToPrescreen(TalentPoolID: $TalentPoolID, JobVacancyIDs: $JobVacancyIDs, applicantLog: $applicantLog, talentStatusLog: $talentStatusLog)
}
    `;
export type AddTalentToPrescreenMutationFn = ApolloReactCommon.MutationFunction<AddTalentToPrescreenMutation, AddTalentToPrescreenMutationVariables>;

/**
 * __useAddTalentToPrescreenMutation__
 *
 * To run a mutation, you first call `useAddTalentToPrescreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTalentToPrescreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTalentToPrescreenMutation, { data, loading, error }] = useAddTalentToPrescreenMutation({
 *   variables: {
 *      TalentPoolID: // value for 'TalentPoolID'
 *      JobVacancyIDs: // value for 'JobVacancyIDs'
 *      applicantLog: // value for 'applicantLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useAddTalentToPrescreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTalentToPrescreenMutation, AddTalentToPrescreenMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTalentToPrescreenMutation, AddTalentToPrescreenMutationVariables>(AddTalentToPrescreenDocument, baseOptions);
      }
export type AddTalentToPrescreenMutationHookResult = ReturnType<typeof useAddTalentToPrescreenMutation>;
export type AddTalentToPrescreenMutationResult = ApolloReactCommon.MutationResult<AddTalentToPrescreenMutation>;
export type AddTalentToPrescreenMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTalentToPrescreenMutation, AddTalentToPrescreenMutationVariables>;
export const TalentSearchListingDocument = gql`
    query TalentSearchListing($employee_id: String) {
  TalentSearchListing(employee_id: $employee_id) {
    rec_talent_search_id
    subscription_account_id
    predefined_search_name
    job_position
    preferred_location
    extra_filter
    employee_id
  }
}
    `;

/**
 * __useTalentSearchListingQuery__
 *
 * To run a query within a React component, call `useTalentSearchListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentSearchListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentSearchListingQuery({
 *   variables: {
 *      employee_id: // value for 'employee_id'
 *   },
 * });
 */
export function useTalentSearchListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentSearchListingQuery, TalentSearchListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentSearchListingQuery, TalentSearchListingQueryVariables>(TalentSearchListingDocument, baseOptions);
      }
export function useTalentSearchListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentSearchListingQuery, TalentSearchListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentSearchListingQuery, TalentSearchListingQueryVariables>(TalentSearchListingDocument, baseOptions);
        }
export type TalentSearchListingQueryHookResult = ReturnType<typeof useTalentSearchListingQuery>;
export type TalentSearchListingLazyQueryHookResult = ReturnType<typeof useTalentSearchListingLazyQuery>;
export type TalentSearchListingQueryResult = ApolloReactCommon.QueryResult<TalentSearchListingQuery, TalentSearchListingQueryVariables>;
export const SaveTalentSearchDocument = gql`
    mutation SaveTalentSearch($input: Rec_TalentSearchInput!) {
  SaveTalentSearch(input: $input)
}
    `;
export type SaveTalentSearchMutationFn = ApolloReactCommon.MutationFunction<SaveTalentSearchMutation, SaveTalentSearchMutationVariables>;

/**
 * __useSaveTalentSearchMutation__
 *
 * To run a mutation, you first call `useSaveTalentSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTalentSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTalentSearchMutation, { data, loading, error }] = useSaveTalentSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTalentSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveTalentSearchMutation, SaveTalentSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveTalentSearchMutation, SaveTalentSearchMutationVariables>(SaveTalentSearchDocument, baseOptions);
      }
export type SaveTalentSearchMutationHookResult = ReturnType<typeof useSaveTalentSearchMutation>;
export type SaveTalentSearchMutationResult = ApolloReactCommon.MutationResult<SaveTalentSearchMutation>;
export type SaveTalentSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveTalentSearchMutation, SaveTalentSearchMutationVariables>;
export const DeleteTalentSearchDocument = gql`
    mutation DeleteTalentSearch($rec_talent_search_id: String!) {
  DeleteTalentSearch(rec_talent_search_id: $rec_talent_search_id)
}
    `;
export type DeleteTalentSearchMutationFn = ApolloReactCommon.MutationFunction<DeleteTalentSearchMutation, DeleteTalentSearchMutationVariables>;

/**
 * __useDeleteTalentSearchMutation__
 *
 * To run a mutation, you first call `useDeleteTalentSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentSearchMutation, { data, loading, error }] = useDeleteTalentSearchMutation({
 *   variables: {
 *      rec_talent_search_id: // value for 'rec_talent_search_id'
 *   },
 * });
 */
export function useDeleteTalentSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTalentSearchMutation, DeleteTalentSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTalentSearchMutation, DeleteTalentSearchMutationVariables>(DeleteTalentSearchDocument, baseOptions);
      }
export type DeleteTalentSearchMutationHookResult = ReturnType<typeof useDeleteTalentSearchMutation>;
export type DeleteTalentSearchMutationResult = ApolloReactCommon.MutationResult<DeleteTalentSearchMutation>;
export type DeleteTalentSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTalentSearchMutation, DeleteTalentSearchMutationVariables>;
export const InsertTalentInvitationDocument = gql`
    mutation InsertTalentInvitation($name: String!, $email: String!, $talentInvitationLog: TalentInvitationStatusLog!, $companyName: String!, $sourceID: String) {
  InsertTalentInvitation(name: $name, email: $email, talentInvitationLog: $talentInvitationLog, companyName: $companyName, sourceID: $sourceID)
}
    `;
export type InsertTalentInvitationMutationFn = ApolloReactCommon.MutationFunction<InsertTalentInvitationMutation, InsertTalentInvitationMutationVariables>;

/**
 * __useInsertTalentInvitationMutation__
 *
 * To run a mutation, you first call `useInsertTalentInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTalentInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTalentInvitationMutation, { data, loading, error }] = useInsertTalentInvitationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      talentInvitationLog: // value for 'talentInvitationLog'
 *      companyName: // value for 'companyName'
 *      sourceID: // value for 'sourceID'
 *   },
 * });
 */
export function useInsertTalentInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTalentInvitationMutation, InsertTalentInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTalentInvitationMutation, InsertTalentInvitationMutationVariables>(InsertTalentInvitationDocument, baseOptions);
      }
export type InsertTalentInvitationMutationHookResult = ReturnType<typeof useInsertTalentInvitationMutation>;
export type InsertTalentInvitationMutationResult = ApolloReactCommon.MutationResult<InsertTalentInvitationMutation>;
export type InsertTalentInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTalentInvitationMutation, InsertTalentInvitationMutationVariables>;
export const TalentInvitationListingDocument = gql`
    query TalentInvitationListing($StartDate: DateTime, $EndDate: DateTime, $inviteStatus: InviteStatus, $keyword: String, $offset: Float, $limit: Float) {
  TalentInvitationListing(StartDate: $StartDate, EndDate: $EndDate, inviteStatus: $inviteStatus, keyword: $keyword, offset: $offset, limit: $limit) {
    rec_talent_invitation_id
    rec_talent_pool_id
    full_name
    email
    document_file
    invite_date
    talent_invitation_log
  }
  TalentInvitationListingCount(StartDate: $StartDate, EndDate: $EndDate, inviteStatus: $inviteStatus, keyword: $keyword)
}
    `;

/**
 * __useTalentInvitationListingQuery__
 *
 * To run a query within a React component, call `useTalentInvitationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentInvitationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentInvitationListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      inviteStatus: // value for 'inviteStatus'
 *      keyword: // value for 'keyword'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTalentInvitationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TalentInvitationListingQuery, TalentInvitationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TalentInvitationListingQuery, TalentInvitationListingQueryVariables>(TalentInvitationListingDocument, baseOptions);
      }
export function useTalentInvitationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TalentInvitationListingQuery, TalentInvitationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TalentInvitationListingQuery, TalentInvitationListingQueryVariables>(TalentInvitationListingDocument, baseOptions);
        }
export type TalentInvitationListingQueryHookResult = ReturnType<typeof useTalentInvitationListingQuery>;
export type TalentInvitationListingLazyQueryHookResult = ReturnType<typeof useTalentInvitationListingLazyQuery>;
export type TalentInvitationListingQueryResult = ApolloReactCommon.QueryResult<TalentInvitationListingQuery, TalentInvitationListingQueryVariables>;
export const SendTalentInvitationEmailDocument = gql`
    mutation SendTalentInvitationEmail($talentInvitationIDs: [String!]!, $companyName: String!, $talentInvitationLog: TalentInvitationStatusLog!, $emailRemark: String) {
  SendTalentInvitationEmail(talentInvitationIDs: $talentInvitationIDs, companyName: $companyName, talentInvitationLog: $talentInvitationLog, emailRemark: $emailRemark)
}
    `;
export type SendTalentInvitationEmailMutationFn = ApolloReactCommon.MutationFunction<SendTalentInvitationEmailMutation, SendTalentInvitationEmailMutationVariables>;

/**
 * __useSendTalentInvitationEmailMutation__
 *
 * To run a mutation, you first call `useSendTalentInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTalentInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTalentInvitationEmailMutation, { data, loading, error }] = useSendTalentInvitationEmailMutation({
 *   variables: {
 *      talentInvitationIDs: // value for 'talentInvitationIDs'
 *      companyName: // value for 'companyName'
 *      talentInvitationLog: // value for 'talentInvitationLog'
 *      emailRemark: // value for 'emailRemark'
 *   },
 * });
 */
export function useSendTalentInvitationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTalentInvitationEmailMutation, SendTalentInvitationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTalentInvitationEmailMutation, SendTalentInvitationEmailMutationVariables>(SendTalentInvitationEmailDocument, baseOptions);
      }
export type SendTalentInvitationEmailMutationHookResult = ReturnType<typeof useSendTalentInvitationEmailMutation>;
export type SendTalentInvitationEmailMutationResult = ApolloReactCommon.MutationResult<SendTalentInvitationEmailMutation>;
export type SendTalentInvitationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTalentInvitationEmailMutation, SendTalentInvitationEmailMutationVariables>;
export const CancelTalentInvitationDocument = gql`
    mutation CancelTalentInvitation($talentInvitationID: String!, $cancelRemark: String) {
  CancelTalentInvitation(talentInvitationID: $talentInvitationID, cancelRemark: $cancelRemark)
}
    `;
export type CancelTalentInvitationMutationFn = ApolloReactCommon.MutationFunction<CancelTalentInvitationMutation, CancelTalentInvitationMutationVariables>;

/**
 * __useCancelTalentInvitationMutation__
 *
 * To run a mutation, you first call `useCancelTalentInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTalentInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTalentInvitationMutation, { data, loading, error }] = useCancelTalentInvitationMutation({
 *   variables: {
 *      talentInvitationID: // value for 'talentInvitationID'
 *      cancelRemark: // value for 'cancelRemark'
 *   },
 * });
 */
export function useCancelTalentInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelTalentInvitationMutation, CancelTalentInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelTalentInvitationMutation, CancelTalentInvitationMutationVariables>(CancelTalentInvitationDocument, baseOptions);
      }
export type CancelTalentInvitationMutationHookResult = ReturnType<typeof useCancelTalentInvitationMutation>;
export type CancelTalentInvitationMutationResult = ApolloReactCommon.MutationResult<CancelTalentInvitationMutation>;
export type CancelTalentInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelTalentInvitationMutation, CancelTalentInvitationMutationVariables>;
export const ApproveTalentInvitationDocument = gql`
    mutation ApproveTalentInvitation($talentInvitationIDs: [String!]!, $talentInvitationLog: TalentInvitationStatusLog!, $talentStatusLog: TalentStatusLog!) {
  ApproveTalentInvitation(talentInvitationIDs: $talentInvitationIDs, talentInvitationLog: $talentInvitationLog, talentStatusLog: $talentStatusLog)
}
    `;
export type ApproveTalentInvitationMutationFn = ApolloReactCommon.MutationFunction<ApproveTalentInvitationMutation, ApproveTalentInvitationMutationVariables>;

/**
 * __useApproveTalentInvitationMutation__
 *
 * To run a mutation, you first call `useApproveTalentInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTalentInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTalentInvitationMutation, { data, loading, error }] = useApproveTalentInvitationMutation({
 *   variables: {
 *      talentInvitationIDs: // value for 'talentInvitationIDs'
 *      talentInvitationLog: // value for 'talentInvitationLog'
 *      talentStatusLog: // value for 'talentStatusLog'
 *   },
 * });
 */
export function useApproveTalentInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveTalentInvitationMutation, ApproveTalentInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveTalentInvitationMutation, ApproveTalentInvitationMutationVariables>(ApproveTalentInvitationDocument, baseOptions);
      }
export type ApproveTalentInvitationMutationHookResult = ReturnType<typeof useApproveTalentInvitationMutation>;
export type ApproveTalentInvitationMutationResult = ApolloReactCommon.MutationResult<ApproveTalentInvitationMutation>;
export type ApproveTalentInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveTalentInvitationMutation, ApproveTalentInvitationMutationVariables>;
export const ApprovalPolicyMatrixSummaryDocument = gql`
    query ApprovalPolicyMatrixSummary {
  ApprovalPolicyMatrixSummary
}
    `;

/**
 * __useApprovalPolicyMatrixSummaryQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyMatrixSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyMatrixSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyMatrixSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useApprovalPolicyMatrixSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyMatrixSummaryQuery, ApprovalPolicyMatrixSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyMatrixSummaryQuery, ApprovalPolicyMatrixSummaryQueryVariables>(ApprovalPolicyMatrixSummaryDocument, baseOptions);
      }
export function useApprovalPolicyMatrixSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyMatrixSummaryQuery, ApprovalPolicyMatrixSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyMatrixSummaryQuery, ApprovalPolicyMatrixSummaryQueryVariables>(ApprovalPolicyMatrixSummaryDocument, baseOptions);
        }
export type ApprovalPolicyMatrixSummaryQueryHookResult = ReturnType<typeof useApprovalPolicyMatrixSummaryQuery>;
export type ApprovalPolicyMatrixSummaryLazyQueryHookResult = ReturnType<typeof useApprovalPolicyMatrixSummaryLazyQuery>;
export type ApprovalPolicyMatrixSummaryQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyMatrixSummaryQuery, ApprovalPolicyMatrixSummaryQueryVariables>;
export const RecruitmentApprovalPolicyByTypeDocument = gql`
    query RecruitmentApprovalPolicyByType($ApprovalPolicyType: String!) {
  RecruitmentApprovalPolicyByType(ApprovalPolicyType: $ApprovalPolicyType) {
    id
    approval_policy_id
    ApprovalPolicy {
      ID
      Title
    }
    condition_list
    description
  }
}
    `;

/**
 * __useRecruitmentApprovalPolicyByTypeQuery__
 *
 * To run a query within a React component, call `useRecruitmentApprovalPolicyByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentApprovalPolicyByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentApprovalPolicyByTypeQuery({
 *   variables: {
 *      ApprovalPolicyType: // value for 'ApprovalPolicyType'
 *   },
 * });
 */
export function useRecruitmentApprovalPolicyByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentApprovalPolicyByTypeQuery, RecruitmentApprovalPolicyByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentApprovalPolicyByTypeQuery, RecruitmentApprovalPolicyByTypeQueryVariables>(RecruitmentApprovalPolicyByTypeDocument, baseOptions);
      }
export function useRecruitmentApprovalPolicyByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentApprovalPolicyByTypeQuery, RecruitmentApprovalPolicyByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentApprovalPolicyByTypeQuery, RecruitmentApprovalPolicyByTypeQueryVariables>(RecruitmentApprovalPolicyByTypeDocument, baseOptions);
        }
export type RecruitmentApprovalPolicyByTypeQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyByTypeQuery>;
export type RecruitmentApprovalPolicyByTypeLazyQueryHookResult = ReturnType<typeof useRecruitmentApprovalPolicyByTypeLazyQuery>;
export type RecruitmentApprovalPolicyByTypeQueryResult = ApolloReactCommon.QueryResult<RecruitmentApprovalPolicyByTypeQuery, RecruitmentApprovalPolicyByTypeQueryVariables>;
export const ApprovalPolicyMatrixInsertDocument = gql`
    mutation ApprovalPolicyMatrixInsert($input: ApprovalPolicyMatrixInput!) {
  ApprovalPolicyMatrixInsert(input: $input)
}
    `;
export type ApprovalPolicyMatrixInsertMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyMatrixInsertMutation, ApprovalPolicyMatrixInsertMutationVariables>;

/**
 * __useApprovalPolicyMatrixInsertMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyMatrixInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyMatrixInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyMatrixInsertMutation, { data, loading, error }] = useApprovalPolicyMatrixInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovalPolicyMatrixInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyMatrixInsertMutation, ApprovalPolicyMatrixInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyMatrixInsertMutation, ApprovalPolicyMatrixInsertMutationVariables>(ApprovalPolicyMatrixInsertDocument, baseOptions);
      }
export type ApprovalPolicyMatrixInsertMutationHookResult = ReturnType<typeof useApprovalPolicyMatrixInsertMutation>;
export type ApprovalPolicyMatrixInsertMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyMatrixInsertMutation>;
export type ApprovalPolicyMatrixInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyMatrixInsertMutation, ApprovalPolicyMatrixInsertMutationVariables>;
export const ApprovalPolicyMatrixDeleteDocument = gql`
    mutation ApprovalPolicyMatrixDelete($ApprovalPolicyMatrixID: String!) {
  ApprovalPolicyMatrixDelete(ApprovalPolicyMatrixID: $ApprovalPolicyMatrixID)
}
    `;
export type ApprovalPolicyMatrixDeleteMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyMatrixDeleteMutation, ApprovalPolicyMatrixDeleteMutationVariables>;

/**
 * __useApprovalPolicyMatrixDeleteMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyMatrixDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyMatrixDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyMatrixDeleteMutation, { data, loading, error }] = useApprovalPolicyMatrixDeleteMutation({
 *   variables: {
 *      ApprovalPolicyMatrixID: // value for 'ApprovalPolicyMatrixID'
 *   },
 * });
 */
export function useApprovalPolicyMatrixDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyMatrixDeleteMutation, ApprovalPolicyMatrixDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyMatrixDeleteMutation, ApprovalPolicyMatrixDeleteMutationVariables>(ApprovalPolicyMatrixDeleteDocument, baseOptions);
      }
export type ApprovalPolicyMatrixDeleteMutationHookResult = ReturnType<typeof useApprovalPolicyMatrixDeleteMutation>;
export type ApprovalPolicyMatrixDeleteMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyMatrixDeleteMutation>;
export type ApprovalPolicyMatrixDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyMatrixDeleteMutation, ApprovalPolicyMatrixDeleteMutationVariables>;
export const WorkFlowDetailDocument = gql`
    query WorkFlowDetail($type: String!, $typeID: String!) {
  WorkFlowDetail(type: $type, typeID: $typeID) {
    submitter_id
    submitter_name
    submitter_no
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useWorkFlowDetailQuery__
 *
 * To run a query within a React component, call `useWorkFlowDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkFlowDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkFlowDetailQuery({
 *   variables: {
 *      type: // value for 'type'
 *      typeID: // value for 'typeID'
 *   },
 * });
 */
export function useWorkFlowDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkFlowDetailQuery, WorkFlowDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkFlowDetailQuery, WorkFlowDetailQueryVariables>(WorkFlowDetailDocument, baseOptions);
      }
export function useWorkFlowDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkFlowDetailQuery, WorkFlowDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkFlowDetailQuery, WorkFlowDetailQueryVariables>(WorkFlowDetailDocument, baseOptions);
        }
export type WorkFlowDetailQueryHookResult = ReturnType<typeof useWorkFlowDetailQuery>;
export type WorkFlowDetailLazyQueryHookResult = ReturnType<typeof useWorkFlowDetailLazyQuery>;
export type WorkFlowDetailQueryResult = ApolloReactCommon.QueryResult<WorkFlowDetailQuery, WorkFlowDetailQueryVariables>;
export const namedOperations = {
  Query: {
    loggedInUserProfile: 'loggedInUserProfile',
    IsSuperUserEmployee: 'IsSuperUserEmployee',
    loginSSO: 'loginSSO',
    loggedInEmployeeProfile: 'loggedInEmployeeProfile',
    UserProfilePic: 'UserProfilePic',
    CheckOnPremServer: 'CheckOnPremServer',
    RecruitmentExpensesListing: 'RecruitmentExpensesListing',
    RecruitmentApprovalPolicySummary: 'RecruitmentApprovalPolicySummary',
    RecruitmentApprovalPolicyListing: 'RecruitmentApprovalPolicyListing',
    RecruitmentApprovalPolicyEmployeeListing: 'RecruitmentApprovalPolicyEmployeeListing',
    Rec_EApplicationFormSetupListing: 'Rec_EApplicationFormSetupListing',
    ELetterTemplateListing: 'ELetterTemplateListing',
    PreOfferChecklistListing: 'PreOfferChecklistListing',
    TalentPoolUserFieldsListing: 'TalentPoolUserFieldsListing',
    DeclineReasonListing: 'DeclineReasonListing',
    Rec_PrehiringFormSetupListing: 'Rec_PrehiringFormSetupListing',
    JobPortalSetupListing: 'JobPortalSetupListing',
    JobPortalSetupDetail: 'JobPortalSetupDetail',
    JobPortalQrCode: 'JobPortalQrCode',
    AdminRecruitmentSummary: 'AdminRecruitmentSummary',
    JobPositionByOrgUnitList: 'JobPositionByOrgUnitList',
    PersonnelParameterListingByType: 'PersonnelParameterListingByType',
    getRolePermissionByUser: 'getRolePermissionByUser',
    GetEmployeeAppSecurityPermissionsByContactID: 'GetEmployeeAppSecurityPermissionsByContactID',
    MyEmployeeInfo: 'MyEmployeeInfo',
    EmployeeListForSpecificSection: 'EmployeeListForSpecificSection',
    GetEmpCoOrgUnitList: 'GetEmpCoOrgUnitList',
    JobPortalInfo: 'JobPortalInfo',
    JobPostingListByJobPortal: 'JobPostingListByJobPortal',
    JobPostingListByJobPortalCount: 'JobPostingListByJobPortalCount',
    JobPostingInfoByJobPortal: 'JobPostingInfoByJobPortal',
    TokenValidation: 'TokenValidation',
    EApplicationListing: 'EApplicationListing',
    EApplicationSummary: 'EApplicationSummary',
    DataUpdateEApplicationSummary: 'DataUpdateEApplicationSummary',
    RecruitmentParameterListing: 'RecruitmentParameterListing',
    TalentUserDefineFieldsListing: 'TalentUserDefineFieldsListing',
    DataUpdateTalentPersonal: 'DataUpdateTalentPersonal',
    DataUpdateHealthInfo: 'DataUpdateHealthInfo',
    MyRecruitmentMenuSummary: 'MyRecruitmentMenuSummary',
    MyRecRequisitionListingTotalCount: 'MyRecRequisitionListingTotalCount',
    MyRecRequisitionListing: 'MyRecRequisitionListing',
    MyRecInterviewListing: 'MyRecInterviewListing',
    MyRecInterviewListingCount: 'MyRecInterviewListingCount',
    MyApprovedJobVacancyListing: 'MyApprovedJobVacancyListing',
    MyApprovedJobVacancyListingCount: 'MyApprovedJobVacancyListingCount',
    MyApprovedJobVacancyInfo: 'MyApprovedJobVacancyInfo',
    MyApprovedJobVacancyApplicantListing: 'MyApprovedJobVacancyApplicantListing',
    MyApprovedJobVacancyApplicantListingCount: 'MyApprovedJobVacancyApplicantListingCount',
    MyOfferListing: 'MyOfferListing',
    MyOfferListingCount: 'MyOfferListingCount',
    MyOfferDetail: 'MyOfferDetail',
    MyTalentSearchApplicationListing: 'MyTalentSearchApplicationListing',
    GetAllCoOrgUnitListBySubscription: 'GetAllCoOrgUnitListBySubscription',
    AdminRecruitmentMenuSummary: 'AdminRecruitmentMenuSummary',
    SettingSummary: 'SettingSummary',
    TalentPoolSummary: 'TalentPoolSummary',
    JobVacancyManagementSummary: 'JobVacancyManagementSummary',
    JobOfferManagementSummary: 'JobOfferManagementSummary',
    HiringProcessSummary: 'HiringProcessSummary',
    RecRequisitionListingTotalCount: 'RecRequisitionListingTotalCount',
    RecRequisitionListing: 'RecRequisitionListing',
    GetRecRequisitionInfo: 'GetRecRequisitionInfo',
    SimpleCompanyListing: 'SimpleCompanyListing',
    CompanyOrgUnitByCompanyIDArray: 'CompanyOrgUnitByCompanyIDArray',
    JobDesignationListing: 'JobDesignationListing',
    RecRequisitionNotInUseListing: 'RecRequisitionNotInUseListing',
    JobVacancyListing: 'JobVacancyListing',
    JobVacancyListingCount: 'JobVacancyListingCount',
    JobVacancyInfo: 'JobVacancyInfo',
    PostedJobVacancyListing: 'PostedJobVacancyListing',
    PostedJobVacancyListingCount: 'PostedJobVacancyListingCount',
    PostedJobVacancyInfo: 'PostedJobVacancyInfo',
    ApplicantListing: 'ApplicantListing',
    ApplicantListingCount: 'ApplicantListingCount',
    JobVacancyNotInUseListing: 'JobVacancyNotInUseListing',
    JobPostingListing: 'JobPostingListing',
    JobPostingListingCount: 'JobPostingListingCount',
    JobPostingInfo: 'JobPostingInfo',
    JobVacancyWithApplicantListing: 'JobVacancyWithApplicantListing',
    InterviewerInUse: 'InterviewerInUse',
    InterviewScheduleListing: 'InterviewScheduleListing',
    ActiveJobVacancyListing: 'ActiveJobVacancyListing',
    ActiveJobVacancyListingCount: 'ActiveJobVacancyListingCount',
    ActiveJobVacancyInfo: 'ActiveJobVacancyInfo',
    FinalApplicantSelectionListing: 'FinalApplicantSelectionListing',
    FinalApplicantSelectionListingCount: 'FinalApplicantSelectionListingCount',
    OfferProposalListing: 'OfferProposalListing',
    OfferProposalListingCount: 'OfferProposalListingCount',
    OfferProposalInfo: 'OfferProposalInfo',
    OfferProposalDetailListing: 'OfferProposalDetailListing',
    OfferApprovalListing: 'OfferApprovalListing',
    OfferApprovalListingCount: 'OfferApprovalListingCount',
    OfferApprovalDetailListing: 'OfferApprovalDetailListing',
    ApprovedJobOfferListing: 'ApprovedJobOfferListing',
    ApprovedJobOfferListingCount: 'ApprovedJobOfferListingCount',
    ApprovedJobOfferInfo: 'ApprovedJobOfferInfo',
    ProcessedOfferInfo: 'ProcessedOfferInfo',
    OfferChecklistListing: 'OfferChecklistListing',
    ELetterTemplateInfo: 'ELetterTemplateInfo',
    ApplicantOfferPreHiringListing: 'ApplicantOfferPreHiringListing',
    QuickHiringListing: 'QuickHiringListing',
    QuickHiringDetails: 'QuickHiringDetails',
    PreHiringDataUpdateListing: 'PreHiringDataUpdateListing',
    NewHireProcessListing: 'NewHireProcessListing',
    NewHireProcessOnboardInfo: 'NewHireProcessOnboardInfo',
    TalentPoolListing: 'TalentPoolListing',
    TalentProfileSummary: 'TalentProfileSummary',
    TalentProfileDetail: 'TalentProfileDetail',
    TalentHealthInfo: 'TalentHealthInfo',
    TalentApplicationListing: 'TalentApplicationListing',
    TalentJobVacancyAppliedListing: 'TalentJobVacancyAppliedListing',
    TalentSearchListing: 'TalentSearchListing',
    TalentInvitationListing: 'TalentInvitationListing',
    ApprovalPolicyMatrixSummary: 'ApprovalPolicyMatrixSummary',
    RecruitmentApprovalPolicyByType: 'RecruitmentApprovalPolicyByType',
    WorkFlowDetail: 'WorkFlowDetail'
  },
  Mutation: {
    EmployeeProfileInsertUpdate: 'EmployeeProfileInsertUpdate',
    login: 'login',
    employeeAppLogin: 'employeeAppLogin',
    changePassword: 'changePassword',
    changeEmployeePassword: 'changeEmployeePassword',
    forgotPassword: 'forgotPassword',
    forgotEmployeePassword: 'forgotEmployeePassword',
    resetPassword: 'resetPassword',
    resetEmployeePassword: 'resetEmployeePassword',
    logout: 'logout',
    employeeLogout: 'employeeLogout',
    activateUser: 'activateUser',
    RecruitmentExpensesInsert: 'RecruitmentExpensesInsert',
    RecruitmentExpensesDelete: 'RecruitmentExpensesDelete',
    Rec_EApplicationFormSetupInsert: 'Rec_EApplicationFormSetupInsert',
    ELetterTemplateInsert: 'ELetterTemplateInsert',
    ELetterTemplateUpdate: 'ELetterTemplateUpdate',
    ELetterTemplateUpdateDefault: 'ELetterTemplateUpdateDefault',
    ELetterTemplateDelete: 'ELetterTemplateDelete',
    PreOfferChecklistInsert: 'PreOfferChecklistInsert',
    PreOfferChecklistUpdate: 'PreOfferChecklistUpdate',
    PreOfferChecklistSequenceUpdate: 'PreOfferChecklistSequenceUpdate',
    PreOfferChecklistDelete: 'PreOfferChecklistDelete',
    TalentPoolAdditionalUserInsert: 'TalentPoolAdditionalUserInsert',
    DeclineReasonInsert: 'DeclineReasonInsert',
    Rec_PrehiringFormSetupInsert: 'Rec_PrehiringFormSetupInsert',
    JobPortalSetupInsert: 'JobPortalSetupInsert',
    JobPortalSetupUpdate: 'JobPortalSetupUpdate',
    JobPortalSetupDelete: 'JobPortalSetupDelete',
    updateEmployeeProfilePic: 'updateEmployeeProfilePic',
    JobPortalTalentInsert: 'JobPortalTalentInsert',
    EApplicationInsert: 'EApplicationInsert',
    DataUpdateEApplicationInsert: 'DataUpdateEApplicationInsert',
    SubmitMyRecruitmentRequisition: 'SubmitMyRecruitmentRequisition',
    UpdateMyRecRequisitionApprovalStatus: 'UpdateMyRecRequisitionApprovalStatus',
    MyApprovedJobVacancyApplicantStatusUpdate: 'MyApprovedJobVacancyApplicantStatusUpdate',
    SubmitRecruitmentRequisition: 'SubmitRecruitmentRequisition',
    DeleteRecRequisition: 'DeleteRecRequisition',
    UpdateRecRequisitionApprovalStatus: 'UpdateRecRequisitionApprovalStatus',
    JobVacancyInsert: 'JobVacancyInsert',
    JobVacancyStatusUpdate: 'JobVacancyStatusUpdate',
    JobVacancyDelete: 'JobVacancyDelete',
    ApplicantStatusUpdate: 'ApplicantStatusUpdate',
    JobPostingInsert: 'JobPostingInsert',
    JobPostingStatusUpdate: 'JobPostingStatusUpdate',
    JobPostingUpdate: 'JobPostingUpdate',
    JobPostingDelete: 'JobPostingDelete',
    SubmitInterviewSchedule: 'SubmitInterviewSchedule',
    UpdateInterviewResult: 'UpdateInterviewResult',
    CompleteInterview: 'CompleteInterview',
    ReopenInterview: 'ReopenInterview',
    DeleteInterviewSchedule: 'DeleteInterviewSchedule',
    FinalSelectionStatusUpdate: 'FinalSelectionStatusUpdate',
    OfferProposalUpdate: 'OfferProposalUpdate',
    OfferProposalInsert: 'OfferProposalInsert',
    OfferApprovalUpdate: 'OfferApprovalUpdate',
    OfferContentInsert: 'OfferContentInsert',
    FinalizeOffer: 'FinalizeOffer',
    UpdateOfferResponse: 'UpdateOfferResponse',
    SendLetter: 'SendLetter',
    InsertAcceptedPreHiring: 'InsertAcceptedPreHiring',
    SendHiringEmail: 'SendHiringEmail',
    InsertQuickHiring: 'InsertQuickHiring',
    UpdateQuickHiring: 'UpdateQuickHiring',
    DeleteQuickHiring: 'DeleteQuickHiring',
    CancelPreHiring: 'CancelPreHiring',
    ApprovePreHiring: 'ApprovePreHiring',
    DataUpdateStatusLog: 'DataUpdateStatusLog',
    DeclineNewHiringProcess: 'DeclineNewHiringProcess',
    OnboardNewHireProcess: 'OnboardNewHireProcess',
    TalentPoolDelete: 'TalentPoolDelete',
    TalentPersonalInsert: 'TalentPersonalInsert',
    TalentEducationInsert: 'TalentEducationInsert',
    TalentMembershipInsert: 'TalentMembershipInsert',
    TalentEmpHistoryInsert: 'TalentEmpHistoryInsert',
    TalentReferenceInsert: 'TalentReferenceInsert',
    TalentHealthInfoInsert: 'TalentHealthInfoInsert',
    TalentStatutoryInsert: 'TalentStatutoryInsert',
    TalentCertificateInsert: 'TalentCertificateInsert',
    TalentDependentInsert: 'TalentDependentInsert',
    TalentLanguageInsert: 'TalentLanguageInsert',
    TalentDocInsert: 'TalentDocInsert',
    TalentInfoDelete: 'TalentInfoDelete',
    UpdateTalentStatus: 'UpdateTalentStatus',
    AddTalentToPrescreen: 'AddTalentToPrescreen',
    SaveTalentSearch: 'SaveTalentSearch',
    DeleteTalentSearch: 'DeleteTalentSearch',
    InsertTalentInvitation: 'InsertTalentInvitation',
    SendTalentInvitationEmail: 'SendTalentInvitationEmail',
    CancelTalentInvitation: 'CancelTalentInvitation',
    ApproveTalentInvitation: 'ApproveTalentInvitation',
    ApprovalPolicyMatrixInsert: 'ApprovalPolicyMatrixInsert',
    ApprovalPolicyMatrixDelete: 'ApprovalPolicyMatrixDelete'
  }
}