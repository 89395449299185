import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { FullHeader } from 'components/ExternalComponent/FullHeader'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

export const EApplicationSubmitted = (props: any) => {
  // Define
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const JobPortal = state?.JobPortal

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    } else {
      const storedTalentPoolID = sessionStorage.getItem('TalentPoolID')

      if (storedTalentPoolID) {
        sessionStorage.removeItem('TalentPoolID')
      }
    }
  }, [state])

  // Function
  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.goBack()
      }
    }, 2000)
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={JobPortal?.theme_color} />
      ) : (
        <>
          <FullHeader
            themeColor={JobPortal?.theme_color}
            desktopBanner={JobPortal?.desktop_banner}
            mobileBanner={JobPortal?.mobile_banner}
          />

          <ContentWrapper multiDynamicInfoTabSearch>
            <div style={{ textAlign: 'center' }}>
              {type === 'DataUpdate'
                ? 'Thank you for your personal info update!'
                : 'Thank you for your application!'}
            </div>
          </ContentWrapper>
        </>
      )}

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
