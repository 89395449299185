import { gql } from 'apollo-boost'

export const GET_ACCOUNT_DETAIL = gql`
  query getAccountDetail($accountID: String!) {
    getAccountDetail(accountID: $accountID) {
      name
      regNo
      contactNo
      email
      address {
        address
        country
        city
        state
        postCode
        AddressType
      }
    }
  }
`
export const GET_DOCUMENT = gql`
query getDocument($DocumentID: String!) {
  getDocument(DocumentID: $DocumentID) {
    DocumentID
    DocumentFile
    Description
  }
}
`
export const GET_CURRENCY = gql`
  query getCurrency($ID: String, $orderByAsc: String, $orderByDesc: String) {
    getCurrency(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
      ID
      name
      code
      symbol
    }
  }
`

export const COUNTRY = gql`
  query getCountry($ID: String, $orderByAsc: String, $orderByDesc: String) {
    getCountry(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
      name
      ID
    }
  }
`
export const STATE_BY_COUNTRY = gql`
  query getStatebyCountry($countryID: String!) {
    getStatebyCountry(countryID: $countryID) {
      name
      ID
    }
  }
`
export const CITY_BY_STATE = gql`
  query getCitybyState($stateID: String!) {
    getCitybyState(stateID: $stateID) {
      ID
      name
    }
  }
`
export const POSTCODE_BY_CITY = gql`
  query getPostcodebyCity($cityID: String!) {
    getPostcodebyCity(cityID: $cityID) {
      ID
      postcode
    }
  }
`

export const GET_PACKAGE_BY_ACCOUNT = gql`
  query getPackageByAccount($softwareCode: String!, $accountID: String!) {
    getPackageByAccount(softwareCode: $softwareCode, accountID: $accountID)
  }
`
