import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { AddressAutoComponent } from 'components/AddressAutocomplete/AddressComponent'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { useAddressAutoComplete } from 'containers/helper/hooks/useAddressAutoComplete'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import NumberFormat from 'react-number-format'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import uuid from 'uuid'
import {
  PersonnelParameterType,
  Status,
  useRecruitmentParameterListingQuery,
} from 'generated/graphql'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { lightenColor } from 'containers/helper/ColorConverter'
import useInputStyles from '../../CustomInputStyles'

interface EmpHistoryForm {
  IsCurrentJob: boolean
  StartDate: Date
  EndDate?: Date
  CompanyName: string
  CompanyAddress: string
  Country: string
  State: string
  City: string
  Postcode: string
  SpecializationID: string
  IndustryID: string
  JobPosition: string
  JobDescription: string
  LastDrawnSalary: number
}

export const EmpHistoryForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const SubscriptionInfo = state?.SubscriptionInfo
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelJobHistoryID = state?.EmpHistoryID
  const EmpHistoryInfo = state?.ListingData?.find(
    x => x?.section_name === 'Employment History'
  )?.DataList
  const CurrentInfo = EmpHistoryInfo?.find(
    x => x?.PersonnelJobHistoryID === PersonnelJobHistoryID
  )
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<EmpHistoryForm>({
    defaultValues: {
      IsCurrentJob: CurrentInfo?.IsCurrentJob || false,
      StartDate: CurrentInfo?.StartDate || null,
      EndDate: CurrentInfo?.EndDate || null,
      CompanyName: CurrentInfo?.CompanyName || '',
      CompanyAddress: CurrentInfo?.CompanyAddress || '',
      Country: CurrentInfo?.Country || '',
      State: CurrentInfo?.State || '',
      City: CurrentInfo?.City || '',
      Postcode: CurrentInfo?.Postcode || '',
      SpecializationID: CurrentInfo?.SpecializationID || '',
      IndustryID: CurrentInfo?.IndustryID || '',
      JobPosition: CurrentInfo?.JobPosition || '',
      JobDescription: CurrentInfo?.JobDescription || '',
      LastDrawnSalary:
        CurrentInfo?.LastDrawnSalary !== undefined
          ? CurrentInfo?.LastDrawnSalary
          : null,
    },
  })

  const { isDirty } = formState

  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    autoCompleteRefV2,
    stateV2,
    initializeEditValue,
    dispatchV2,
  } = useAddressAutoComplete()

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [countryV2, setCountryV2] = useState('')
  const [isDisable, setIsDisable] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  // Query
  const {
    data: { RecruitmentParameterListing } = { RecruitmentParameterListing: [] },
    loading: RecruitmentParameterListingLoading,
  } = useRecruitmentParameterListingQuery({
    variables: {
      SubscriptionAccountID: SubscriptionInfo?.SubscriptionAccountID,
      type: [
        PersonnelParameterType.Specialization,
        PersonnelParameterType.Industry,
      ],
    },
    fetchPolicy: 'no-cache',
  })

  const SpecializationList = RecruitmentParameterListing?.filter(
    el => el?.PersonnelParameterType === PersonnelParameterType.Specialization
  )

  const IndustryList = RecruitmentParameterListing?.filter(
    el => el?.PersonnelParameterType === PersonnelParameterType.Industry
  )

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (CurrentInfo && mode === 'Edit') {
      initializeEditValue(CurrentInfo?.CompanyAddress, 'companyAddress')
    }
  }, [CurrentInfo, mode])

  // Function
  const onSubmit = (data: EmpHistoryForm, addNew: boolean) => {
    setIsDisable(true)
    const isWorking = EmpHistoryInfo?.find(x => x?.IsCurrentJob)

    if (
      isWorking?.PersonnelJobHistoryID !== PersonnelJobHistoryID &&
      data?.IsCurrentJob
    ) {
      snackBar('You cannot have multiple active employments', false)

      return
    }

    let input = {
      PersonnelJobHistoryID: CurrentInfo?.PersonnelJobHistoryID || uuid(),
      IsCurrentJob: data?.IsCurrentJob,
      StartDate: data?.StartDate,
      EndDate: data?.EndDate,
      CompanyName: data?.CompanyName,
      CompanyAddress: {
        City: stateV2?.city?.name,
        Line1: stateV2?.address,
        State: stateV2?.state?.name,
        Country: stateV2?.country?.name,
        PostalCode: stateV2?.postcode?.postcode,
      },
      SpecializationID: data?.SpecializationID,
      Specialization: SpecializationList?.find(
        x => x?.PersonnelParameterID === data?.SpecializationID
      )?.Name,
      IndustryID: data?.IndustryID,
      Industry: IndustryList?.find(
        x => x?.PersonnelParameterID === data?.IndustryID
      )?.Name,
      JobPosition: data?.JobPosition,
      JobDescription: data?.JobDescription,
      LastDrawnSalary: data?.LastDrawnSalary
        ? Number(data?.LastDrawnSalary)
        : null,
      Status: Status.Active,
    }

    if (mode === 'New') {
      EmpHistoryInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = EmpHistoryInfo?.findIndex(
        x => x?.PersonnelJobHistoryID === PersonnelJobHistoryID
      )

      if (indexToEdit !== -1) {
        EmpHistoryInfo[indexToEdit] = input
      }
    }

    EmpHistoryInfo?.sort((a, b) => {
      if (a.EndDate === undefined && b.EndDate !== undefined) return -1
      if (a.EndDate !== undefined && b.EndDate === undefined) return 1
      return new Date(b.EndDate).getTime() - new Date(a.EndDate).getTime()
    })

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Employment History') {
          return {
            ...x,
            DataList: EmpHistoryInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      setIsSubmitted(false)
      initializeEditValue(
        {
          Line1: '',
          Country: '',
          State: '',
          City: '',
          PostalCode: '',
        },
        'companyAddress'
      )
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      {!state || RecruitmentParameterListingLoading ? (
        <Loading backdropColor={JobPortal?.theme_color || '#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'Employment History'}
            themeColor={JobPortal?.theme_color || '#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  control={control}
                  name={`IsCurrentJob`}
                  render={({ name, value, onChange }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                          color="primary"
                          className={`${classes.checkBox}`}
                        />
                      }
                      label="I currently work here."
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="StartDate"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <KeyboardDatePicker
                        required
                        helperText={errors.StartDate?.message}
                        error={!!errors.StartDate}
                        label="Start Date"
                        format="dd MMM yyyy"
                        onChange={date => [onChange(date)]}
                        value={value}
                        className={`${classes.datePicker} left`}
                        name={name}
                        autoComplete="off"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        DialogProps={{
                          className: classes.datePicker,
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: requiredField },
                    }}
                  />
                </MuiPickersUtilsProvider>

                {!watch('IsCurrentJob') && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="EndDate"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <KeyboardDatePicker
                          required
                          helperText={
                            errors.EndDate?.message ||
                            (value &&
                              new Date(value) < new Date(watch('StartDate')) &&
                              'End Date cannot be less than Start Date')
                          }
                          error={
                            !!errors.EndDate ||
                            (value &&
                              new Date(value) < new Date(watch('StartDate')))
                          }
                          label="End Date"
                          format="dd MMM yyyy"
                          onChange={date => [onChange(date)]}
                          value={value}
                          className={`${classes.datePicker} right`}
                          name={name}
                          autoComplete="off"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          minDate={watch('StartDate')}
                          DialogProps={{
                            className: classes.datePicker,
                          }}
                        />
                      )}
                      rules={{
                        required: {
                          value: !watch('IsCurrentJob'),
                          message: requiredField,
                        },
                        validate: value =>
                          new Date(value) > new Date(watch('StartDate')) ||
                          'End Date cannot be less than Start Date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'CompanyName'}
                  label="Company Name"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Company Name"
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.CompanyName?.message}
                      error={!!errors.CompanyName}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <AddressAutoComponent
                  id="companyAddress"
                  getCountry={getCountry}
                  getStatebyCountry={getStatebyCountry}
                  getCitybyState={getCitybyState}
                  getPostcodebyCity={getPostcodebyCity}
                  handleCountryOnChange={handleCountryOnChange}
                  handleStateOnChange={handleStateOnChange}
                  handleCityOnChange={handleCityOnChange}
                  handlePostCodeOnChange={handlePostCodeOnChange}
                  handleGoogleOnChange={handleGoogleOnChange}
                  autoCompleteRef={autoCompleteRefV2}
                  state={stateV2}
                  country={countryV2}
                  setCountry={setCountryV2}
                  formType={mode}
                  isSubmitted={isSubmitted}
                  isMandatory={true}
                  customClass={`${classes.textField}`}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="SpecializationID"
                  label="Specialization"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Specialization"
                      value={value}
                      name={name}
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.SpecializationID?.message}
                      error={!!errors.SpecializationID}
                    >
                      {SpecializationList.map((el, index) => (
                        <MenuItem key={index} value={el?.PersonnelParameterID}>
                          {el?.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="IndustryID"
                  label="Industry"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Industry"
                      value={value}
                      name={name}
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.IndustryID?.message}
                      error={!!errors.IndustryID}
                    >
                      {IndustryList.map((el, index) => (
                        <MenuItem key={index} value={el?.PersonnelParameterID}>
                          {el?.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'JobPosition'}
                  label="Job Position"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Job Position"
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.JobPosition?.message}
                      error={!!errors.JobPosition}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'JobDescription'}
                  label="Job Description"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Job Description"
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="LastDrawnSalary"
                  label="Last Drawn Salary"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <NumberFormat
                      allowNegative={false}
                      customInput={TextField}
                      label={`Last Drawn Salary`}
                      name={name}
                      className={`${classes.textField}`}
                      fixedDecimalScale
                      decimalScale={2}
                      value={value || ''}
                      prefix="RM "
                      onValueChange={e => {
                        onChange(e.value)
                      }}
                    />
                  )}
                />
              </Grid>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            themeColor={JobPortal?.theme_color}
            options={
              mode === 'Edit'
                ? [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        setIsSubmitted(true)
                        handleSubmit(data => onSubmit(data, true))()
                      },
                      name: 'Save & New',
                      color: 'primary',
                      disabled: isDisable,
                    },
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save & Exit',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
            }
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
            sections={{
              header: {
                children: (
                  <ListItem
                    className="remove-padding"
                    style={{
                      backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: JobPortal?.theme_color || '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
