import React, { lazy } from 'react'

const AvailableVacancyListing = lazy(() =>
  import('./JobPortal/AvailableVacancyListing').then(module => ({
    default: module.AvailableVacancyListing,
  }))
)

const AvailableVacancyDetail = lazy(() =>
  import('./JobPortal/AvailableVacancyDetail').then(module => ({
    default: module.AvailableVacancyDetail,
  }))
)

const JobPortalForm = lazy(() =>
  import('./JobPortal/JobPortalForm').then(module => ({
    default: module.JobPortalForm,
  }))
)

const EApplication = lazy(() =>
  import('./EApplication/EApplication').then(module => ({
    default: module.EApplication,
  }))
)

const EApplicationSubmitted = lazy(() =>
  import('./EApplication/EApplicationSubmitted').then(module => ({
    default: module.EApplicationSubmitted,
  }))
)

const PersonalInfoForm = lazy(() =>
  import('./EApplication/EApplicationForm/PersonalInfoForm').then(module => ({
    default: module.PersonalInfoForm,
  }))
)

const DataUpdatePersonalInfoForm = lazy(() =>
  import('./EApplication/EApplicationForm/DataUpdatePersonalInfoForm').then(
    module => ({
      default: module.DataUpdatePersonalInfoForm,
    })
  )
)

const EducationForm = lazy(() =>
  import('./EApplication/EApplicationForm/EducationForm').then(module => ({
    default: module.EducationForm,
  }))
)

const MembershipForm = lazy(() =>
  import('./EApplication/EApplicationForm/MembershipForm').then(module => ({
    default: module.MembershipForm,
  }))
)

const EmpHistoryForm = lazy(() =>
  import('./EApplication/EApplicationForm/EmpHistoryForm').then(module => ({
    default: module.EmpHistoryForm,
  }))
)

const LanguageForm = lazy(() =>
  import('./EApplication/EApplicationForm/LanguageForm').then(module => ({
    default: module.LanguageForm,
  }))
)

const ReferenceForm = lazy(() =>
  import('./EApplication/EApplicationForm/ReferenceForm').then(module => ({
    default: module.ReferenceForm,
  }))
)

const SupportingDocForm = lazy(() =>
  import('./EApplication/EApplicationForm/SupportingDocForm').then(module => ({
    default: module.SupportingDocForm,
  }))
)

const DataUpdateEApplication = lazy(() =>
  import('./EApplication/DataUpdateEApplication').then(module => ({
    default: module.DataUpdateEApplication,
  }))
)

const CertificateForm = lazy(() =>
  import('./EApplication/EApplicationForm/CertificateForm').then(module => ({
    default: module.CertificateForm,
  }))
)

const DependentForm = lazy(() =>
  import('./EApplication/EApplicationForm/DependentForm').then(module => ({
    default: module.DependentForm,
  }))
)

const HealthInfoForm = lazy(() =>
  import('./EApplication/EApplicationForm/HealthInfoForm').then(module => ({
    default: module.HealthInfoForm,
  }))
)

const externalPortalRoutes = [
  // Job Portal
  {
    props: { exact: true, path: '/JobPortal/AvailableVacancyListing' },
    component: <AvailableVacancyListing />,
  },
  {
    props: { exact: true, path: '/JobPortal/AvailableVacancyDetail' },
    component: <AvailableVacancyDetail />,
  },
  {
    props: { exact: true, path: '/JobPortal/JobPortalForm' },
    component: <JobPortalForm />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplication' },
    component: <EApplication type="JobPortal" />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplicationSubmitted' },
    component: <EApplicationSubmitted type="JobPortal" />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplication/PersonalInfoForm' },
    component: <PersonalInfoForm type="JobPortal" />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplication/EducationForm' },
    component: <EducationForm type="JobPortal" />,
  },
  {
    props: {
      exact: true,
      path: '/JobPortal/EApplication/ProfessionalMembershipForm',
    },
    component: <MembershipForm type="JobPortal" />,
  },
  {
    props: {
      exact: true,
      path: '/JobPortal/EApplication/EmploymentHistoryForm',
    },
    component: <EmpHistoryForm type="JobPortal" />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplication/LanguagesForm' },
    component: <LanguageForm type="JobPortal" />,
  },
  {
    props: { exact: true, path: '/JobPortal/EApplication/ReferencesForm' },
    component: <ReferenceForm type="JobPortal" />,
  },
  {
    props: {
      exact: true,
      path: '/JobPortal/EApplication/SupportingDocumentsForm',
    },
    component: <SupportingDocForm type="JobPortal" />,
  },

  // Talent Invitation
  {
    props: { exact: true, path: '/TalentInvitation/EApplication' },
    component: <EApplication type="TalentInvitation" />,
  },
  {
    props: { exact: true, path: '/TalentInvitation/EApplicationSubmitted' },
    component: <EApplicationSubmitted type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/PersonalInfoForm',
    },
    component: <PersonalInfoForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/EducationForm',
    },
    component: <EducationForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/ProfessionalMembershipForm',
    },
    component: <MembershipForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/EmploymentHistoryForm',
    },
    component: <EmpHistoryForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/LanguagesForm',
    },
    component: <LanguageForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/ReferencesForm',
    },
    component: <ReferenceForm type="TalentInvitation" />,
  },
  {
    props: {
      exact: true,
      path: '/TalentInvitation/EApplication/SupportingDocumentsForm',
    },
    component: <SupportingDocForm type="TalentInvitation" />,
  },

  // Data Update
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication',
    },
    component: <DataUpdateEApplication type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/CertificateForm',
    },
    component: <CertificateForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/HealthInfoForm',
    },
    component: <HealthInfoForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/PersonalInfoForm',
    },
    component: <DataUpdatePersonalInfoForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/EducationForm',
    },
    component: <EducationForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/DependentForm',
    },
    component: <DependentForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/EmploymentHistoryForm',
    },
    component: <EmpHistoryForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/ReferencesForm',
    },
    component: <ReferenceForm type="DataUpdate" />,
  },
  {
    props: {
      exact: true,
      path: '/DataUpdate/EApplication/SupportingDocumentsForm',
    },
    component: <SupportingDocForm type="DataUpdate" />,
  },
]

export default externalPortalRoutes
