import {
  COUNTRY,
  STATE_BY_COUNTRY,
  CITY_BY_STATE,
  POSTCODE_BY_CITY,
} from 'containers/AccountModule/AccountQuery'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { AccountClient } from 'client'
// import {
//   useGetCitybyStateLazyQuery,
//   useGetCountryQuery,
//   useGetPostcodebyCityLazyQuery,
//   useGetStatebyCountryLazyQuery,
// } from 'generated/graphql';

export const useCustomCountryQuery = () => {
  const { data: { getCountry } = { getCountry: [] } } = useQuery(COUNTRY, {
    client: AccountClient,
    variables: {
      orderByAsc: 'name',
    },
  })
  const [
    handleGetStateByCountry,
    { data: { getStatebyCountry } = { getStatebyCountry: [] } },
  ] = useLazyQuery(STATE_BY_COUNTRY, {
    client: AccountClient,
  })
  const [
    handleGetCityByState,
    { data: { getCitybyState } = { getCitybyState: [] } },
  ] = useLazyQuery(CITY_BY_STATE, {
    client: AccountClient,
  })
  const [
    handleGetPostcodeByCity,
    { data: { getPostcodebyCity } = { getPostcodebyCity: [] } },
  ] = useLazyQuery(POSTCODE_BY_CITY, {
    client: AccountClient,
  })

  return {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleGetStateByCountry, // variables : CountryID
    handleGetCityByState, // variables : StateID
    handleGetPostcodeByCity, // variables : StateID
  }
}
