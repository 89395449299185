import { AddressAutocomplete } from '@ifca-root/react-component/src/components/Input/AddressAutocomplete'
import { TextField } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import React from 'react'

// 'AIzaSyBbPkG0hX9GDe3aAB5LxaQkPnuQ7KGDViI'

export const AddressAutoComponent = (props: any) => {
  const {
    label,
    id,
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    autoCompleteRef,
    state,
    formType,
    country,
    setCountry,
    isMandatory,
    isSubmitted,
    customClass,
  } = props

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.name,
  })

  return (
    <>
      <AddressAutocomplete
        sections={{
          address: {
            props: {
              id: id,
              inputRef: autoCompleteRef,
              label: label
                ? label
                : id === 'presentAddress'
                ? isMandatory
                  ? 'Present Address *'
                  : 'Present Address'
                : id === 'permanentAddress'
                ? isMandatory
                  ? 'Permanent Address *'
                  : 'Permanent Address'
                : id === 'companyAddress'
                ? isMandatory
                  ? 'Company Address *'
                  : 'Company Address'
                : null,
              // value: query,
              value: state.address,
              multiline: true,
              onChange: e => handleGoogleOnChange(e.target.value, id),
              style: { width: '100%' },
              error:
                isMandatory && !state?.address && isSubmitted ? true : false,
              helperText:
                isMandatory && !state?.address && isSubmitted
                  ? 'Address is required'
                  : '',
              className: customClass,
            },
          },
          country: {
            props: {
              value: state?.country,
              options: getCountry || [],
              style: {
                width: '49%',
                display: 'inline-block',
              },

              filterOptions: filterOptions,
              onChange: (e, newValue) => {
                handleCountryOnChange(newValue, id)
              },
              inputValue: country,
              onInputChange: (e, value) => {
                setCountry(value)
              },
              getOptionLabel: (option: any) => option.name || '',
              renderInput: params => {
                if (formType === 'edit') {
                  // anyone changing this astronomically weird shit, this is the key
                  // to it all when editing because apparently setting default value
                  // doesnt fucking work
                  params.inputProps.value = country
                }

                return (
                  <TextField
                    {...params}
                    className={customClass}
                    value={state?.country?.name}
                    label={isMandatory ? 'Country *' : 'Country'}
                    error={
                      isMandatory &&
                      state &&
                      !state?.country?.name &&
                      isSubmitted
                        ? true
                        : false
                    }
                    helperText={
                      isMandatory && !state?.country?.name && isSubmitted
                        ? 'Country is required'
                        : ''
                    }
                  />
                )
              },
            },
          },
          state: {
            props: {
              value: state?.state,
              options: getStatebyCountry || [],
              style: {
                width: '49%',
                paddingLeft: '2%',
                display: 'inline-block',
              },
              getOptionLabel: option => option.name || '',
              onChange: (e, newValue) => {
                if (newValue) {
                  handleStateOnChange(newValue || { name: e.target.value }, id)
                }
              },
              onInputChange: (e, newValue) => {
                handleStateOnChange({ name: newValue }, id)
              },
              renderInput: params => {
                if (formType === 'edit') {
                  params.inputProps.value = state?.state?.name
                }

                return (
                  <TextField
                    {...params}
                    className={customClass}
                    value={state?.state}
                    label={isMandatory ? 'State *' : 'State'}
                    style={{ width: '100%', paddingLeft: '4px' }}
                    error={
                      isMandatory && !state?.state?.name && isSubmitted
                        ? true
                        : false
                    }
                    helperText={
                      isMandatory && !state?.state?.name && isSubmitted
                        ? 'State is required'
                        : ''
                    }
                  />
                )
              },
            },
          },
          city: {
            props: {
              value: state?.city,
              options: getCitybyState || [],
              style: {
                width: '49%',
                display: 'inline-block',
              },
              getOptionLabel: option => option.name || '',
              onChange: (e, newValue) => {
                if (newValue) {
                  handleCityOnChange(newValue || { name: e.target.value }, id)
                }
              },
              onInputChange: (e, newValue) => {
                handleCityOnChange({ name: newValue }, id)
              },
              renderInput: params => {
                if (formType === 'edit') {
                  params.inputProps.value = state?.city?.name
                }

                return (
                  <TextField
                    {...params}
                    className={customClass}
                    value={state?.city}
                    label={isMandatory ? 'City *' : 'City'}
                    style={{ width: '100%' }}
                    error={
                      isMandatory && !state?.city?.name && isSubmitted
                        ? true
                        : false
                    }
                    helperText={
                      isMandatory && !state?.city?.name && isSubmitted
                        ? 'City is required'
                        : ''
                    }
                  />
                )
              },
            },
          },
          postCode: {
            props: {
              value: state?.postcode,
              options: getPostcodebyCity || [],
              style: {
                width: '49%',
                paddingLeft: '2%',
                display: 'inline-block',
              },
              onChange: (e: any, newValue) => {
                if (!!newValue) {
                  handlePostCodeOnChange(
                    newValue || { postcode: e.target.value },
                    id
                  )
                }
              },

              onInputChange: (e, newValue) => {
                handlePostCodeOnChange({ postcode: newValue }, id)
              },
              getOptionLabel: option => option.postcode || '',
              renderInput: params => {
                if (formType === 'edit') {
                  params.inputProps.value = state?.postcode?.postcode
                }

                return (
                  <TextField
                    {...params}
                    className={customClass}
                    value={state?.postcode}
                    style={{ width: '100%', paddingLeft: '4px' }}
                    label={isMandatory ? 'Postcode *' : 'Postcode'}
                    error={
                      isMandatory && !state?.postcode?.postcode && isSubmitted
                        ? true
                        : false
                    }
                    helperText={
                      isMandatory && !state?.postcode?.postcode && isSubmitted
                        ? 'Postcode is required'
                        : ''
                    }
                  />
                )
              },
            },
          },
        }}
      />
    </>
  )
}
