import { IconButton, AppBar, Toolbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

export interface BasicHeaderProps {
  onClick?: any
  mainBtn: string
  title?: string
  primary?: string
  secondary?: string
  themeColor?: string
}

export const BasicHeader = (props: BasicHeaderProps) => {
  const { onClick, title, primary, secondary, themeColor } = props

  return (
    <>
      <AppBar
        position={'fixed'}
        style={{
          backgroundColor: 'white',
          boxShadow: 'none',
        }}
      >
        <Toolbar variant="dense">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              style={{ padding: 0, paddingRight: '8px' }}
              onClick={onClick}
            >
              <Close fontSize="small" />
            </IconButton>
            <div className="smTitle" style={{ color: 'black' }}>
              {title}
            </div>
          </div>
        </Toolbar>

        <div className="basic-header">
          <div className="xsTitle" style={{ color: themeColor }}>
            {primary}
          </div>
          <div className="desc" style={{ color: 'black' }}>
            {secondary}
          </div>
        </div>
      </AppBar>
    </>
  )
}
