import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppContext from '../Store/AppContext'
import RouteSuspense from './RouteSuspense'
import { PrivateRoute } from './PrivateRoute'
import { Login } from '@hr-root/hr-employee-react/src/containers/AccountModule/Login'
import { ForgotPassword } from 'containers/AccountModule/ForgotPassword'
import { Logout } from '@hr-root/hr-employee-react/src/containers/AccountModule/Logout'
import { ResetPassword } from '../../AccountModule/ResetPassword'
import { ActivatedUser } from 'containers/AccountModule/ActivatedUser'
import { ConfirmEmail } from 'containers/AccountModule/ConfirmEmail'
import { AvailableVacancyListing } from 'containers/HomeModule/ExternalPortal/JobPortal/AvailableVacancyListing'
import { JobPortalForm } from 'containers/HomeModule/ExternalPortal/JobPortal/JobPortalForm'
import { AvailableVacancyDetail } from 'containers/HomeModule/ExternalPortal/JobPortal/AvailableVacancyDetail'
import { PersonalInfoForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/PersonalInfoForm'
import { EducationForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/EducationForm'
import { EApplication } from 'containers/HomeModule/ExternalPortal/EApplication/EApplication'
import { MembershipForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/MembershipForm'
import { EmpHistoryForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/EmpHistoryForm'
import { LanguageForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/LanguageForm'
import { ReferenceForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/ReferenceForm'
import { SupportingDocForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/SupportingDocForm'
import { EApplicationSubmitted } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationSubmitted'
import { DataUpdateEApplication } from 'containers/HomeModule/ExternalPortal/EApplication/DataUpdateEApplication'
import { CertificateForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/CertificateForm'
import { DependentForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/DependentForm'
import { HealthInfoForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/HealthInfoForm'
import { StatutoryInfoForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/StatutoryInfoForm'
import { DataUpdatePersonalInfoForm } from 'containers/HomeModule/ExternalPortal/EApplication/EApplicationForm/DataUpdatePersonalInfoForm'

const Routes = () => {
  const { globalState } = useContext(AppContext as any)
  return (
    <Switch>
      <Route path="/activate/:token">
        <ActivatedUser />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/resetPassword/:token">
        <ResetPassword />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/resetPassword/:token">
        <ResetPassword />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/confirm/:token">
        <ConfirmEmail />
      </Route>

      {/* Recruitment Job Portal */}
      <Route path="/JobPortal/AvailableVacancyListing">
        <AvailableVacancyListing />
      </Route>
      <Route path="/JobPortal/AvailableVacancyDetail">
        <AvailableVacancyDetail />
      </Route>
      <Route path="/JobPortal/JobPortalForm">
        <JobPortalForm />
      </Route>
      <Route path="/JobPortal/EApplicationSubmitted">
        <EApplicationSubmitted type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/PersonalInfoForm">
        <PersonalInfoForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/EducationForm">
        <EducationForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/ProfessionalMembershipForm">
        <MembershipForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/EmploymentHistoryForm">
        <EmpHistoryForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/LanguagesForm">
        <LanguageForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/ReferencesForm">
        <ReferenceForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication/SupportingDocumentsForm">
        <SupportingDocForm type="JobPortal" />
      </Route>
      <Route path="/JobPortal/EApplication">
        <EApplication type="JobPortal" />
      </Route>

      {/* Recruitment Talent Invitation */}
      <Route path="/TalentInvitation/EApplicationSubmitted">
        <EApplicationSubmitted type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/PersonalInfoForm">
        <PersonalInfoForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/EducationForm">
        <EducationForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/ProfessionalMembershipForm">
        <MembershipForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/EmploymentHistoryForm">
        <EmpHistoryForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/LanguagesForm">
        <LanguageForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/ReferencesForm">
        <ReferenceForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication/SupportingDocumentsForm">
        <SupportingDocForm type="TalentInvitation" />
      </Route>
      <Route path="/TalentInvitation/EApplication">
        <EApplication type="TalentInvitation" />
      </Route>

      {/* Recruitment Data Update */}
      <Route path="/DataUpdate/EApplicationSubmitted">
        <EApplicationSubmitted type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/PersonalInfoForm">
        <DataUpdatePersonalInfoForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/EducationForm">
        <EducationForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/CertificateForm">
        <CertificateForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/DependentForm">
        <DependentForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/HealthInfoForm">
        <HealthInfoForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/StatutoryInfoForm">
        <StatutoryInfoForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/EmploymentHistoryForm">
        <EmpHistoryForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/ReferencesForm">
        <ReferenceForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication/SupportingDocumentsForm">
        <SupportingDocForm type="DataUpdate" />
      </Route>
      <Route path="/DataUpdate/EApplication">
        <DataUpdateEApplication type="DataUpdate" />
      </Route>

      {globalState.routes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el.props}>
            <RouteSuspense>{el.component}</RouteSuspense>
          </PrivateRoute>
        )
      })}
    </Switch>
  )
}

export default Routes
