import { Education, Nationality, Relationship, Status } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import dateFormat from 'dateformat'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Grid,
  TextField,
  ListItem,
  ListItemText,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import uuid from 'uuid'

interface DependentForm {
  Name: string
  Relationship: string
  IsEmergencyContact: boolean
  Nationality: string
  NricNo: string
  PassportNo: string
  Dob: Date
  Dob2: Date
  MobileNo: string
  Occupation: string
  Education: string
  ChildEligibleRate: string
  Disabled: boolean
  PCBNo: string
}

export const DependentForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const EmployeeDependentsID = state?.DependentID
  const DependentInfo = state?.ListingData?.find(
    x => x?.section_name === 'Dependent'
  )?.DataList
  const CurrentInfo = DependentInfo?.find(
    x => x?.EmployeeDependentsID === EmployeeDependentsID
  )

  const ChildEligibleRateList: any = []
  ChildEligibleRateList.push('0%')
  ChildEligibleRateList.push('50%')
  ChildEligibleRateList.push('100%')

  let Relationships: any[] = Object.values(Relationship)

  let Educations: any[] = Object.values(Education).map(i => {
    return i
      .replace(/_/g, ' ')
      .replace(/CertificateMatriculation/g, 'Certificate/Matriculation')
      .replace(/DiplomaDegree/g, 'Diploma/Degree')
  })

  let Nationalities: any[] = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })

  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
    register,
    reset,
  } = useForm<DependentForm>({
    defaultValues: {
      Name: CurrentInfo?.Contact?.FullName || '',
      Relationship: CurrentInfo?.Relationship || '',
      IsEmergencyContact: CurrentInfo?.IsEmergencyContact || false,
      Nationality: CurrentInfo?.Contact?.Nationality || Nationality.Malaysian,
      NricNo: CurrentInfo?.Contact?.NRIC || '',
      PassportNo: CurrentInfo?.Contact?.PassportNo || '',
      Dob: CurrentInfo?.Contact?.BirthDate
        ? dateFormat(CurrentInfo?.Contact?.BirthDate, 'dd mmm yyyy')
        : '',
      Dob2: CurrentInfo?.Contact?.BirthDate || null,
      MobileNo: CurrentInfo?.Contact?.MobileNo || '',
      Occupation: CurrentInfo?.Occupation || '',
      Education: CurrentInfo?.Education || '',
      ChildEligibleRate: CurrentInfo?.ChildEligibleRate || '',
      Disabled: CurrentInfo?.IsOKU || false,
      PCBNo: CurrentInfo?.PCBNo || '',
    },
  })

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [Age, setAge] = useState('-')

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (
      watch('NricNo') === '' &&
      watch('Nationality') === Nationality.Malaysian
    ) {
      clearErrors('NricNo')
      setValue('Dob', '')
      setValue('PassportNo', '')
    }

    const NricElem = document.getElementById('NricNo')

    if (NricElem) {
      NricElem.onkeydown = (e: KeyboardEvent) => {
        const allowedKeys = [
          'Backspace',
          'Delete',
          'ArrowLeft',
          'ArrowRight',
          'Tab',
          'Control',
          'Meta',
          'Shift',
          'Alt',
          'Enter',
          'Escape',
        ]

        const isShortcut = e.ctrlKey || e.metaKey || e.altKey || e.shiftKey

        if (isShortcut || allowedKeys.includes(e.key) || /^\d$/.test(e.key)) {
          return // Allow the input
        }

        e.preventDefault()
      }

      NricElem.oninput = (e: Event) => {
        const target = e.target as HTMLInputElement
        const cursorPos = target.selectionStart ?? 0

        // Allow only numbers in the NRIC input
        let numericValue = ''
        for (const char of target.value) {
          if (!isNaN(Number(char))) {
            numericValue += char
          }
        }

        // Format the value with hyphens
        let formattedNric = numericValue
        if (numericValue.length > 6) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(6)}`
        }
        if (numericValue.length > 8) {
          formattedNric = `${numericValue.slice(0, 6)}-${numericValue.slice(
            6,
            8
          )}-${numericValue.slice(8)}`
        }

        setValue('NricNo', formattedNric)

        // Calculate new cursor position
        let newCursorPos = cursorPos
        if (cursorPos > 6) newCursorPos += 1 // Account for the first hyphen
        if (cursorPos > 8) newCursorPos += 1 // Account for the second hyphen

        requestAnimationFrame(() => {
          target.setSelectionRange(newCursorPos, newCursorPos)
        })
      }
    }

    const nric = watch('NricNo')?.replace(/-/g, '')
    const nationality = watch('Nationality')

    if (nric?.length === 12 && nationality === Nationality.Malaysian) {
      const nricSplit = `${nric.slice(0, 6)}-${nric.slice(6, 8)}-${nric.slice(
        8
      )}`.split('-')

      if (
        nricSplit[0]?.length === 6 &&
        nricSplit[1]?.length === 2 &&
        nricSplit[2]?.length === 4
      ) {
        const year = nricSplit[0].substring(0, 2)
        const month = nricSplit[0].substring(2, 4)
        const day = nricSplit[0].substring(4)
        const currentYear = new Date()
          .getFullYear()
          .toString()
          .substring(2)

        // Determine full year
        const fullYear = year > currentYear ? `19${year}` : `20${year}`

        // Validate month and day
        if (Number(month) >= 1 && Number(month) <= 12) {
          const date = new Date(`${fullYear}-${month}-${day}`)
          if (date.getDate() === Number(day) && !isNaN(date.getTime())) {
            // Check if date is valid
            setValue('Dob', dateFormat(date, 'dd mmm yyyy'))
            setAge(calculateAge(date))
            clearErrors('NricNo')
          } else {
            setError('NricNo', {
              type: 'validate',
              message: 'Invalid NRIC No.',
            })
          }
        } else {
          setError('NricNo', {
            type: 'validate',
            message: 'Invalid NRIC No.',
          })
        }
      }
    } else if (nationality !== Nationality.Malaysian) {
      setValue('NricNo', '')
      if (watch('Dob2')) {
        setAge(calculateAge(new Date(watch('Dob2'))))
      } else {
        setAge('-')
      }
    }
  }, [watch('NricNo'), watch('Nationality'), watch('Dob2')])

  // Function
  const onSubmit = (data: DependentForm, addNew: Boolean) => {
    setIsDisable(true)

    if (DependentInfo?.length > 0) {
      const exist = DependentInfo?.find(x => {
        const isSameRelationship =
          x?.Relationship ===
          Object.values(Relationship).find(j => j === data.Relationship)
        const isSameName = x?.Contact?.FullName === data?.Name

        if (mode === 'Edit') {
          return (
            isSameRelationship &&
            isSameName &&
            x?.EmployeeDependentsID !== CurrentInfo?.EmployeeDependentsID
          )
        }
        return isSameRelationship && isSameName
      })

      if (exist) {
        setIsDisable(false)
        snackBar('Already have duplicated name for this relationship!', false)

        return
      }
    }

    let input = {
      EmployeeDependentsID: CurrentInfo?.EmployeeDependentsID || uuid(),
      Relationship: Object.values(Relationship).find(
        j => j === data.Relationship
      ),
      Education:
        data.Relationship === 'Daughter' || data.Relationship === 'Son'
          ? data.Education === 'Under 18 years old'
            ? Education.Under_18YearsOld
            : Object.values(Education).find(j =>
                j === data.Education.replace('/', '')
                  ? data.Education.replace('/', '')
                  : ''
              )
          : null,
      IsWorking: false,
      IsOKU: data.Disabled,
      ChildEligibleRate:
        data.ChildEligibleRate && data.ChildEligibleRate !== ''
          ? Number(data.ChildEligibleRate.replace('%', ''))
          : null,
      Status: Status.Active,
      IsEmergencyContact: data.IsEmergencyContact,
      Occupation: data.Occupation,
      PCBNo: data.PCBNo,
      Contact: {
        ContactID: CurrentInfo?.Contact?.ContactID || null,
        FullName: data.Name,
        MobileNo: data.MobileNo,
        NRIC: data.NricNo,
        PassportNo: data.PassportNo,
        Nationality: Object.values(Nationality).find(
          j => j === data.Nationality.replace(/ /g, '_')
        ),
        BirthDate: data?.Dob || data?.Dob2,
        Status: Status.Active,
      },
    }

    if (mode === 'New') {
      DependentInfo?.push(input)
    } else if (mode === 'Edit') {
      const indexToEdit = DependentInfo?.findIndex(
        x => x?.EmployeeDependentsID === EmployeeDependentsID
      )

      if (indexToEdit !== -1) {
        DependentInfo[indexToEdit] = input
      }
    }

    DependentInfo?.sort((a, b) => b?.IsEmergencyContact - a?.IsEmergencyContact)

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Dependent') {
          return {
            ...x,
            DataList: DependentInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      snackBar('Saved Successfully!', false)
    } else {
      snackBar('Saved Successfully!', true)
    }
  }

  const calculateAge = (date: Date) => {
    const ageDiffMs = Date.now() - date?.getTime()
    const ageDate = new Date(ageDiffMs)
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)

    if (date) {
      if (date.getFullYear() === new Date().getFullYear()) {
        return '0'
      } else {
        return calculatedAge?.toString()
      }
    } else {
      return null
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={'#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'Dependent'}
            themeColor={'#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  name={'Name'}
                  label="Name"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Name"
                      required
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.Name?.message}
                      error={!!errors.Name}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="Relationship"
                  label="Relationship"
                  required
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Relationship"
                      value={value}
                      name={name}
                      required
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.Relationship?.message}
                      error={!!errors.Relationship}
                    >
                      {Relationships.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  control={control}
                  name={`IsEmergencyContact`}
                  render={({ name, value, onChange }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Emergency Contact"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="Nationality"
                  label="Nationality"
                  required
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Nationality"
                      value={value}
                      name={name}
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.Nationality?.message}
                      error={!!errors.Nationality}
                    >
                      {Nationalities.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'NricNo'}
                  label="NRIC No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="NRIC No."
                      id="NricNo"
                      className={`left`}
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.NricNo?.message}
                      error={!!errors.NricNo}
                      disabled={watch('Nationality') !== 'Malaysian'}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^\d{6}-\d{2}-\d{4}$/i,
                      message: 'Invalid NRIC No.',
                    },
                  }}
                />

                <Controller
                  name={'PassportNo'}
                  label="Passport No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Passport No."
                      className={`right`}
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.PassportNo?.message}
                      error={!!errors.PassportNo}
                      disabled={watch('Nationality') === 'Malaysian'}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                {watch('Nationality') === 'Malaysian' ? (
                  <Controller
                    name="Dob"
                    label="Birth Date"
                    required
                    fullWidth
                    control={control}
                    render={({ onChange, value, name }) => (
                      <TextField
                        label="Birth Date"
                        value={value}
                        name={name}
                        className={`left`}
                        autoComplete="off"
                        // helperText={validateDate && 'NRIC is incorrect'}
                        // error={validateDate}
                      />
                    )}
                  />
                ) : (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="Dob2"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <KeyboardDatePicker
                          helperText={errors.Dob2?.message}
                          error={!!errors.Dob2}
                          label="Birth Date"
                          format="dd MMM yyyy"
                          onChange={date => [onChange(date)]}
                          value={value}
                          className={`left`}
                          name={name}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                )}
                <Controller
                  name={'Age'}
                  label="Age"
                  control={control}
                  render={({ name, value }) => (
                    <TextField
                      name={name}
                      label="Age"
                      fullWidth
                      value={Age ?? '-'}
                      disabled
                      className={`right`}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        style: {
                          color: 'black',
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'MobileNo'}
                  label="Mobile No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Mobile No."
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.MobileNo?.message}
                      error={!!errors.MobileNo}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^[+-]?[0-9]*\.?[0-9]+$/,
                      message: 'Invalid Mobile No.',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'Occupation'}
                  label="Occupation"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Occupation"
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.Occupation?.message}
                      error={!!errors.Occupation}
                    />
                  )}
                />
              </Grid>

              {watch('Relationship') === 'Husband' ||
              watch('Relationship') === 'Wife' ? (
                <Grid item xs={12} className="form-content">
                  <Controller
                    name={'PCBNo'}
                    label="Spouse Income Tax No."
                    control={control}
                    ref={register}
                    render={({ onChange, value, name }) => (
                      <TextField
                        name={name}
                        label="Spouse Income Tax No."
                        fullWidth
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        helperText={errors.PCBNo?.message}
                        error={!!errors.PCBNo}
                      />
                    )}
                  />
                </Grid>
              ) : watch('Relationship') === 'Daughter' ||
                watch('Relationship') === 'Son' ? (
                <>
                  <Grid item xs={12} className="form-content">
                    <Controller
                      name={'Education'}
                      label="Child Education"
                      control={control}
                      ref={register}
                      render={({ onChange, value, name }) => (
                        <TextField
                          select
                          name={name}
                          label="Child Education"
                          fullWidth
                          required
                          autoComplete="off"
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                            e.target.value === 'No'
                              ? setValue('ChildEligibleRate', '0%')
                              : setValue('ChildEligibleRate', '')
                          }}
                          helperText={errors.Education?.message}
                          error={!!errors.Education}
                        >
                          {Educations.map((i, index) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      rules={{
                        required: {
                          value:
                            getValues().Relationship === 'Daughter' ||
                            getValues().Relationship === 'Son'
                              ? true
                              : false,
                          message: requiredField,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} className="form-content">
                    <Controller
                      name={'ChildEligibleRate'}
                      label="Child Eligible Rate"
                      control={control}
                      ref={register}
                      render={({ onChange, value, name }) => (
                        <TextField
                          select
                          name={name}
                          label="Child Eligible Rate"
                          fullWidth
                          required
                          disabled={
                            getValues().Education === 'No' ? true : false
                          }
                          autoComplete="off"
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          helperText={errors.ChildEligibleRate?.message}
                          error={!!errors.ChildEligibleRate}
                        >
                          {ChildEligibleRateList.map((i, index) => (
                            <MenuItem key={index} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      rules={{
                        required: {
                          value: getValues().Education !== 'No' ? true : false,
                          message: requiredField,
                        },
                      }}
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} className="form-content">
                <Controller
                  control={control}
                  name={`Disabled`}
                  render={({ name, value, onChange }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Disability / OKU"
                    />
                  )}
                />
              </Grid>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            options={
              mode === 'Edit'
                ? [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, true))()
                      },
                      name: 'Save & New',
                      color: 'primary',
                      disabled: isDisable,
                    },
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save & Exit',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
            }
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <SnackBarMsg
            open={openSnackBar}
            message={snackBarMessage}
            externalDisplay
          />
        </>
      )}
    </>
  )
}
