import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export interface FullHeaderProps {
  desktopBanner?: any
  mobileBanner?: any
  themeColor?: any
}

export const FullHeader = (props: FullHeaderProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { desktopBanner, mobileBanner, themeColor } = props

  return (
    <AppBar
      className="full-header"
      position={'fixed'}
      style={{
        backgroundImage: `url(${isDesktop ? desktopBanner : mobileBanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: themeColor,
        height: '76px',
        width: '100%',
      }}
    ></AppBar>
  )
}
