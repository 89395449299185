import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Grid,
  TextField,
  MenuItem,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { Bank, ResidentStatus, TaxMaritialStatus } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'

interface StatutoryInfoForm {
  BankName: string
  AccountNo: string
  EpfNo: string
  TaxNo: string
  TaxMaritalStatus: string
  TaxResidentStatus: string
  CountryCode: string
}

export const StatutoryInfoForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const StatutoryInfo = state?.ListingData?.find(
    x => x?.section_name === 'Statutory Info'
  )?.DataList

  let Banks: any[] = Object.values(Bank).map(i => {
    return i.replace(/_/g, ' ')
  })

  let TaxMaritalStatus: any[] = Object.values(TaxMaritialStatus).map(i => {
    return i.replace(/_/g, ' ')
  })

  let TaxResidentStatus = Object.values(ResidentStatus).map(i => {
    return i.replace(/_/g, ' ')
  })

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
    register,
    reset,
  } = useForm<StatutoryInfoForm>({
    defaultValues: {
      BankName: StatutoryInfo?.bank || '',
      AccountNo: StatutoryInfo?.account_no || '',
      EpfNo: StatutoryInfo?.EPF_no || '',
      TaxNo: StatutoryInfo?.tax_no || '',
      TaxMaritalStatus: StatutoryInfo?.tax_marital_status || '',
      TaxResidentStatus: StatutoryInfo?.tax_resident_status || '',
      CountryCode: StatutoryInfo?.country_code || '',
    },
  })

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false, null)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  // Function
  const onSubmit = (data: StatutoryInfoForm) => {
    setIsDisable(true)

    let input = {
      bank: data?.BankName,
      account_no: data?.AccountNo,
      EPF_no: data?.EpfNo,
      tax_no: data?.TaxNo,
      tax_marital_status: Object.values(TaxMaritialStatus).find(
        i => i === data?.TaxMaritalStatus?.replace(/ /g, '_')
      ),
      tax_resident_status: Object.values(ResidentStatus).find(
        i => i === data?.TaxResidentStatus?.replace(/ /g, '_')
      ),
      country_code: data?.CountryCode,
    }

    snackBar('Saved Successfully!', true, input)
  }

  const ShowActualBankName = BankName => {
    switch (BankName?.replace(/\s/g, '_')) {
      case Bank.AlRajhiBankingInvestmentCorporationMalaysiaBerhad:
        return 'Al Rajhi Banking & Investment Corporation Malaysia Berhad'
      case Bank.BankPertanianMalaysiaBerhadAgroBank:
        return 'Bank Pertanian Malaysia Berhad (Agro Bank)'
      case Bank.OcbcAlAminBankBerhad:
        return 'OCBC Al-Amin Bank Berhad'
      case Bank.PtBankMuamalatIndonesiaTbk:
        return 'PT Bank Muamalat Indonesia, Tbk'
      default:
        return BankName
    }
  }

  const snackBar = (message: string, redirect: boolean, updatedData: any) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
          ListingData: state?.ListingData?.map(x => {
            if (x?.section_name === 'Statutory Info') {
              return {
                ...x,
                DataList: updatedData,
              }
            }

            return x
          }),
        })
      }
    }, 2000)
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={'#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'Statutory Info'}
            themeColor={'#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  name="BankName"
                  label="Bank Name"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Bank Name"
                      value={value}
                      name={name}
                      required
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.BankName?.message}
                      error={!!errors.BankName}
                    >
                      {Banks.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {ShowActualBankName(el)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'AccountNo'}
                  label="Bank Account No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Bank Account No."
                      required
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.AccountNo?.message}
                      error={!!errors.AccountNo}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                    pattern: {
                      value: /^[+-]?[0-9]*\.?[0-9]+$/,
                      //value: /^[+-]?[1-9][0-9]*|0$/i,
                      message: 'Invalid Bank Account No',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'EpfNo'}
                  label="EPF No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="EPF No."
                      fullWidth
                      autoComplete="off"
                      value={value}
                      className="left"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.EpfNo?.message}
                      error={!!errors.EpfNo}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^[+-]?[0-9]*\.?[0-9]+$/,
                      message: 'Invalid Account No',
                    },
                  }}
                />

                <Controller
                  name={'TaxNo'}
                  label="Tax No."
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Tax No."
                      fullWidth
                      autoComplete="off"
                      value={value}
                      className="right"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.TaxNo?.message}
                      error={!!errors.TaxNo}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^[+-]?[0-9]*\.?[0-9]+$/,
                      message: 'Invalid Account No',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="TaxMaritalStatus"
                  label="Tax Marital Status"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Tax Marital Status"
                      value={value}
                      name={name}
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.TaxMaritalStatus?.message}
                      error={!!errors.TaxMaritalStatus}
                    >
                      {TaxMaritalStatus.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="TaxResidentStatus"
                  label="Tax Resident Status"
                  required
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextField
                      select
                      label="Tax Resident Status"
                      value={value}
                      name={name}
                      fullWidth
                      onChange={e => onChange(e.target.value)}
                      helperText={errors.TaxResidentStatus?.message}
                      error={!!errors.TaxResidentStatus}
                    >
                      {TaxResidentStatus.map((el, index) => (
                        <MenuItem key={index} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name={'CountryCode'}
                  label="Country Code"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Country Code"
                      fullWidth
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.CountryCode?.message}
                      error={!!errors.CountryCode}
                    />
                  )}
                />
              </Grid>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            options={[
              {
                onClick: () => {
                  handleSubmit(onSubmit)()
                },
                name: 'Save',
                color: 'primary',
                disabled: isDisable,
              },
            ]}
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            sections={{
              header: {
                children: (
                  <ListItem className="remove-padding">
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />

          <SnackBarMsg
            open={openSnackBar}
            message={snackBarMessage}
            externalDisplay
          />
        </>
      )}
    </>
  )
}
