import React, { FunctionComponent, Props } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface AddressProps {
  sections?: {
    address?: {
      component?: FunctionComponent
      props?: TextFieldProps
    }
    country?: {
      component?: FunctionComponent
      props?: any
    }
    state?: {
      component?: FunctionComponent
      props?: any
    }
    city?: {
      component?: FunctionComponent
      props?: any
    }
    postCode?: {
      component?: FunctionComponent
      props?: any
    }
  }
}
export const AddressAutocomplete = (props: AddressProps) => {
  return (
    <>
      {' '}
      {props?.sections?.address.component?.({}) || (
        <TextField {...props.sections.address.props} fullWidth />
      )}
      {props?.sections?.country?.component?.({}) || (
        <Autocomplete {...props.sections.country.props} freeSolo fullWidth />
      )}
      {props?.sections?.state?.component?.({}) || (
        <Autocomplete {...props.sections.state.props} fullWidth />
      )}
      {props?.sections?.city?.component?.({}) || (
        <Autocomplete {...props.sections.city.props} />
      )}
      {props?.sections?.postCode?.component?.({}) || (
        <Autocomplete {...props.sections.postCode.props} />
      )}
    </>
  )
}
