import { makeStyles } from '@material-ui/core/styles'
import { lightenColor } from 'containers/helper/ColorConverter'

const useInputStyles = themeColor =>
  makeStyles({
    textField: {
      '& .MuiInput-underline:after': {
        borderBottomColor: themeColor, // Underline color on focus
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: themeColor, // Label color on focus
      },
    },
    checkBox: {
      '&.MuiIconButton-colorPrimary:hover': {
        backgroundColor: lightenColor(themeColor, 95), // custom hover color
      },
      '&.Mui-checked': {
        color: themeColor, // custom checked color
      },
    },
    datePicker: {
      '& .MuiInput-underline:after': {
        borderBottomColor: themeColor, // Custom color for the underline after focus
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: themeColor, // Label color on focus
      },
      '& .MuiPickersDay-dayDisabled.MuiPickersDay-current': {
        color: '#00000061', // Text color for current day
      },
      '& .MuiPickersDay-current': {
        color: themeColor, // Text color for current day
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: themeColor, // Custom selected day color
        color: '#ffffff', // Text color for selected day
      },
      '& .MuiPickersDay-root': {
        color: '#000000', // Text color for all days
      },
      '& .MuiPickersYear-yearSelected': {
        color: themeColor, // Text color selected year
      },
      '& .MuiPickersYear-root:focus': {
        color: themeColor, // Text color when click on year
      },
      '& .MuiPickersToolbarText-toolbarBtnSelected': {
        color: '#ffffff', // Custom color for the toolbar text
      },
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: themeColor, // Custom background color for toolbar
      },
      '& .MuiButton-root': {
        color: themeColor, // Custom color for footer button text
      },
      '& .MuiDialogActions-root .MuiButton-root:hover': {
        backgroundColor: lightenColor(themeColor, 95), // Custom color for footer button text when hover
      },
    },
  })

export default useInputStyles
