import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Grid, ListItem, ListItemText, TextField } from '@material-ui/core'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { DocumentType, Status } from 'generated/graphql'
import uuid from 'uuid'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { lightenColor } from 'containers/helper/ColorConverter'
import useInputStyles from '../../CustomInputStyles'

interface SupportingDocForm {
  DocumentName: string
  Attachment: string
}

export const SupportingDocForm = (props: any) => {
  // Define section
  let history = useHistory()
  const { state }: any = useLocation()
  const { type } = props
  const requiredField = 'This field is required'
  const mode = state?.mode
  const PersonnelDocumentID = state?.SupportingDocID
  const DocumentInfo = state?.ListingData?.find(
    x => x?.section_name === 'Supporting Documents'
  )?.DataList
  const CurrentInfo = DocumentInfo?.find(
    x => x?.DocumentID === PersonnelDocumentID
  )
  const DocumentID = CurrentInfo?.DocumentID
  const JobPortal = state?.JobPortal
  const classes = useInputStyles(JobPortal?.theme_color)()

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState,
    register,
    reset,
  } = useForm<SupportingDocForm>({
    defaultValues: {
      DocumentName: CurrentInfo?.DocumentName || '',
      Attachment: CurrentInfo?.Attachment || '',
    },
  })

  const { isDirty } = formState

  // useState
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openExitDialog, setopenExitDialog] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })
  const [isDisable, setIsDisable] = useState(false)

  // Query

  // useEffect
  useEffect(() => {
    if (!state) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [state])

  useEffect(() => {
    if (CurrentInfo) {
      if (CurrentInfo?.Attachment) {
        setSelectedFile({
          files: [CurrentInfo?.Attachment],
        })

        let reader = new FileReader()

        reader.onloadend = () => {
          setImagePreview(reader.result)
        }
        reader.readAsDataURL(CurrentInfo?.Attachment)
      } else if (CurrentInfo?.DocumentFile) {
        setImagePreview(CurrentInfo?.DocumentFile)

        setValue('Attachment', CurrentInfo?.DocumentFile)
      }

      setSelectedFileName(CurrentInfo?.Description)
    }
  }, [CurrentInfo])

  // Function
  const onSubmit = (data: SupportingDocForm, addNew: boolean) => {
    setIsDisable(true)
    let input = null
    let newDeleteList = state?.ListingData?.find(
      x => x?.section_name === 'Supporting Documents'
    )?.DeleteList

    if (selectedFile.files.length > 0) {
      input = {
        DocumentType: DocumentType.PersonalDoc,
        Attachment: selectedFile.files[0] || null,
        Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
        Status: Status.Active,
        FileSize: selectedFile.files[0].size,
        DocumentID:
          CurrentInfo?.Attachment &&
          !CurrentInfo?.DocumentFile &&
          mode === 'Edit'
            ? DocumentID
            : uuid(),
        DocumentName: data?.DocumentName,
      }

      if (mode === 'New') {
        DocumentInfo?.push(input)
      } else if (mode === 'Edit') {
        const indexToEdit = DocumentInfo?.findIndex(
          x => x?.DocumentID === PersonnelDocumentID
        )

        if (CurrentInfo?.DocumentFile && indexToEdit !== -1) {
          newDeleteList?.push({
            DocumentID,
            Status: Status.Inactive,
          })

          DocumentInfo?.splice(indexToEdit, 1)

          DocumentInfo?.push(input)
        } else if (indexToEdit !== -1) {
          DocumentInfo[indexToEdit] = input
        }
      }
    } else {
      input = {
        ...CurrentInfo,
        DocumentName: data?.DocumentName,
      }

      if (mode === 'Edit') {
        const indexToEdit = DocumentInfo?.findIndex(
          x => x?.DocumentID === PersonnelDocumentID
        )

        if (indexToEdit !== -1) {
          DocumentInfo[indexToEdit] = input
        }
      }
    }

    if (addNew) {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Supporting Documents') {
          return {
            ...x,
            DataList: DocumentInfo,
          }
        }

        return x
      })

      setIsDisable(false)
      reset()
      setImagePreview(null)
      setSelectedFileName('')
      setSelectedFile({ files: [] })
      snackBar('Saved Successfully!', false)
    } else {
      state?.ListingData?.map(x => {
        if (x?.section_name === 'Supporting Documents') {
          return {
            ...x,
            DataList: DocumentInfo,
            DeleteList: newDeleteList,
          }
        }

        return x
      })

      snackBar('Saved Successfully!', true)
    }
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/${type}/EApplication`, {
          ...state,
        })
      }
    }, 2000)
  }

  const onChangeFile = event => {
    const file = event.target.files[0]

    if (!file) return

    if (file?.name === CurrentInfo?.Description) {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      let nextState = { ...selectedFile }
      nextState.files.splice(0, nextState.files.length)
      nextState.files.push(file)
      setSelectedFileName(file?.name)
      setSelectedFile(nextState)
      let reader = new FileReader()

      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type })
        const url = URL.createObjectURL(blob)
        setImagePreview(url)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <>
      {!state ? (
        <Loading backdropColor={JobPortal?.theme_color || '#FF9800'} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              isDirty
                ? setopenExitDialog(true)
                : history.push(`/${type}/EApplication`, {
                    ...state,
                  })
            }
            title="E-Application"
            primary={'Supporting Documents'}
            themeColor={JobPortal?.theme_color || '#FF9800'}
          />

          <ContentWrapper externalBasicHeader footer>
            <CardContents>
              <Grid item xs={12} className="form-content">
                <Controller
                  name={'DocumentName'}
                  label="Document Description"
                  control={control}
                  ref={register}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      label="Document Description"
                      required
                      fullWidth
                      className={`${classes.textField}`}
                      autoComplete="off"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      helperText={errors.DocumentName?.message}
                      error={!!errors.DocumentName}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="form-content">
                <Controller
                  name="Attachment"
                  label="Attachment"
                  control={control}
                  ref={register}
                  render={({ name, onChange }) => (
                    <InputUploadAllType
                      value={selectedFileName}
                      label="Attachment"
                      handleUpload={e => {
                        onChangeFile(e)

                        const file = e.target.files[0]
                        if (file) {
                          onChange(file)
                        }
                      }}
                      className={`${classes.textField} p-b-20`}
                      fullWidth
                      required
                      autoComplete="off"
                      name={name}
                      helperText={errors?.Attachment?.message}
                      error={!!errors?.Attachment}
                    />
                  )}
                  rules={{
                    required: { value: true, message: requiredField },
                  }}
                />
                {imagePreview !== null && (
                  <div className="content-wrap full">
                    <UploadPreview
                      src={imagePreview}
                      mediaType={selectedFile?.files[0]?.type}
                      onClick={() => {
                        setSelectedFile({ files: [] })
                        setSelectedFileName('')
                        setImagePreview(null)
                      }}
                      remove
                    />
                  </div>
                )}
              </Grid>
            </CardContents>
          </ContentWrapper>

          <Footer
            externalDisplay
            themeColor={JobPortal?.theme_color}
            options={
              mode === 'Edit'
                ? [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, true))()
                      },
                      name: 'Save & New',
                      color: 'primary',
                      disabled: isDisable,
                    },
                    {
                      onClick: () => {
                        handleSubmit(data => onSubmit(data, false))()
                      },
                      name: 'Save & Exit',
                      color: 'primary',
                      disabled: isDisable,
                    },
                  ]
            }
          />

          <CommonDialog
            fullWidth={true}
            open={openExitDialog}
            onClose={() => setopenExitDialog(false)}
            headerBgColor={lightenColor(JobPortal?.theme_color, 90)}
            sections={{
              header: {
                children: (
                  <ListItem
                    className="remove-padding"
                    style={{
                      backgroundColor: lightenColor(JobPortal?.theme_color, 90),
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="smTitle flex-space"
                            style={{
                              color: JobPortal?.theme_color || '#FF9800',
                            }}
                          >
                            Exit Confirmation
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                ),
              },
              body: () => (
                <>
                  <div>
                    Are you sure you want to exit? Your changes will not be
                    saved.
                  </div>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => setopenExitDialog(false),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () =>
                        history.push(`/${type}/EApplication`, {
                          ...state,
                        }),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
