export const lightenColor = (hex: string, percent: number): string => {
  // Convert hex to RGB
  let r: number = parseInt(hex?.slice(1, 3), 16)
  let g: number = parseInt(hex?.slice(3, 5), 16)
  let b: number = parseInt(hex?.slice(5, 7), 16)

  // Calculate the lightened color
  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)))
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)))
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)))

  // Convert back to hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}
