import { IAction } from '../index'
import { Reducer, useEffect, useReducer, useRef, useState } from 'react'
import { useCustomCountryQuery } from './useCustomCountryQuery'

// Load Google APi key and callback function
let autoComplete: any
let autoCompleteV2: any

// ANY VARIABLE NAME WITH V2 IS FOR PRESENT ADDRESS OTHERWISE IS FOR PERMANENT ADDRESS

const loadScript = (url: any, callback: any) => {
  let script: any = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

interface AddressAutoCompleteProps {
  address: string
  country: any
  state: any
  city: any
  postcode: any
}
export const useAddressAutoComplete = () => {
  const [query, setQuery] = useState<String>('')
  const [queryV2, setQueryV2] = useState<String>('')

  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleGetStateByCountry, // variables : CountryID
    handleGetCityByState, // variables : StateID
    handleGetPostcodeByCity, // variables : StateID
  } = useCustomCountryQuery()
  //autocomplete Ref for input field
  const autoCompleteRef = useRef(null)
  const autoCompleteRefV2 = useRef(null)

  const initialState: AddressAutoCompleteProps = {
    address: '',
    country: {},
    state: {},
    city: {},
    postcode: {},
  }
  const reducer: Reducer<AddressAutoCompleteProps, IAction> = (
    state,
    action
  ) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [stateV2, dispatchV2] = useReducer(reducer, initialState)
  const APIKEY =
    process.env.REACT_APP_GOOGLE_API_KEY ||
    'AIzaSyBbPkG0hX9GDe3aAB5LxaQkPnuQ7KGDViI'
  const handlePlaceSelect = async (updateQuery: any, id: any) => {
    // Address object {} getPlace  //https://developers.google.com/maps/documentation/javascript/reference/places-widget
    const addressObject =
      id === 'permanentAddress'
        ? autoComplete.getPlace()
        : autoCompleteV2.getPlace()
    const addressName = addressObject.name
    let query: any

    if (addressObject) {
      if (addressObject?.formatted_address.includes(addressName)) {
        query = addressObject?.formatted_address
      } else {
        query = addressName + ', ' + addressObject.formatted_address
      }
    }
    updateQuery(query)

    const addressComponent: Array<any> = addressObject['address_components']
    let PostCode = {
        postcode: '',
      },
      City = {
        name: '',
      },
      State = {
        name: '',
      },
      Country = {
        name: '',
      }

    // Address component for State , City , Post Code value
    addressComponent.forEach(x => {
      if (x.types.includes('locality')) {
        City.name = x.long_name
      } else if (x.types.includes('administrative_area_level_1')) {
        State.name = x.long_name
      } else if (x.types.includes('country')) {
        Country.name = x.long_name
      } else if (x.types.includes('postal_code')) {
        PostCode.postcode = x.long_name
      }
    })
    // join 4 object short_name
    let addressResult: Array<any> = [{ name: addressName }]

    addressComponent.forEach(x =>
      x.types.filter(y => {
        if (
          (y === 'premise' ||
            y === 'street_number' ||
            y === 'subpremise' ||
            y === 'route' ||
            y === 'sublocality_level_1') &&
          !x.long_name.match(addressName) &&
          y !== 'administrative_area_level_1' &&
          y !== 'country' &&
          y !== 'postal_code'
        )
          return addressResult.push({ name: x.long_name })
        else return null
      })
    )

    if (id === 'permanentAddress') {
      dispatch({
        type: 'address',
        payload: addressResult.map(x => x.name).join(', '),
      })
      dispatch({
        type: 'country',
        payload: Country,
      })
      dispatch({
        type: 'state',
        payload: State,
      })
      dispatch({
        type: 'city',
        payload: City,
      })
      dispatch({
        type: 'postcode',
        payload: PostCode,
      })
    } else {
      dispatchV2({
        type: 'address',
        payload: addressResult.map(x => x.name).join(', '),
      })
      dispatchV2({
        type: 'country',
        payload: Country,
      })
      dispatchV2({
        type: 'state',
        payload: State,
      })
      dispatchV2({
        type: 'city',
        payload: City,
      })
      dispatchV2({
        type: 'postcode',
        payload: PostCode,
      })
    }
  }

  const handleScriptLoad = (
    updateQuery: any,
    autoCompleteRef: any,
    autoCompleteRefV2: any
  ) => {
    autoComplete = new (window as any).google.maps.places.Autocomplete(
      document.getElementById('permanentAddress'),
      {}
    )
    autoComplete.setFields(['address_components', 'formatted_address', 'name'])
    autoComplete.addListener('place_changed', () => {
      handlePlaceSelect(updateQuery, 'permanentAddress')
    })

    autoCompleteV2 = new (window as any).google.maps.places.Autocomplete(
      document.getElementById('presentAddress'),
      {}
    )

    autoCompleteV2 = new (window as any).google.maps.places.Autocomplete(
      document.getElementById('companyAddress'),
      {}
    )
    autoCompleteV2.setFields([
      'address_components',
      'formatted_address',
      'name',
    ])
    autoCompleteV2.addListener('place_changed', () => {
      handlePlaceSelect(updateQuery, 'presentAddress')
    })

    autoCompleteV2.addListener('place_changed', () => {
      handlePlaceSelect(updateQuery, 'companyAddress')
    })
  }
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, autoCompleteRefV2)
      // loadScript(
      //   `https://maps.googleapis.com/maps/api/js?key=${DEFAULT_GOOGLE_API_KEY}&libraries=places`,
      //   () => handleScriptLoad(setQuery, autoCompleteRef),
    )
  }, [])
  // useEffect(() => {
  //   if (state.country?.name === 'Malaysia') {
  //     handleGetStateByCountry({
  //       variables: {
  //         countryID: getCountry
  //           .filter(v => v.name === 'Malaysia')
  //           .map(x => x.ID)[0] as any,
  //       },
  //     });
  //   }
  // }, [state.country?.name]);

  const handleGoogleOnChange = (e, id) => {
    if (id === 'permanentAddress') {
      setQuery(e)
      dispatch({
        type: 'address',
        payload: e,
      })
    } else {
      setQueryV2(e)
      dispatchV2({
        type: 'address',
        payload: e,
      })
    }
  }

  const handleCountryOnChange = (newValue: any, id: any) => {
    if (id === 'permanentAddress') {
      dispatch({
        type: 'country',
        // payload: newValue?.name,
        payload: { name: newValue?.name },
      })
      if (newValue?.ID) {
        handleGetStateByCountry({
          variables: {
            countryID: newValue?.ID,
          },
        })
      }
    } else {
      dispatchV2({
        type: 'country',
        // payload: newValue?.name,
        payload: { name: newValue?.name },
      })
      if (newValue?.ID) {
        handleGetStateByCountry({
          variables: {
            countryID: newValue?.ID,
          },
        })
      }
    }
  }

  const handleStateOnChange = (newValue: any, id) => {
    if (id === 'permanentAddress') {
      dispatch({
        type: 'state',
        payload: newValue,
      })
      if (newValue?.ID) {
        handleGetCityByState({
          variables: {
            stateID: newValue?.ID,
          },
        })
      }
    } else {
      dispatchV2({
        type: 'state',
        payload: newValue,
      })
      if (newValue?.ID) {
        handleGetCityByState({
          variables: {
            stateID: newValue?.ID,
          },
        })
      }
    }
  }

  const handleCityOnChange = (newValue: any, id) => {
    if (id === 'permanentAddress') {
      dispatch({
        type: 'city',
        payload: newValue,
      })
      if (newValue?.ID) {
        handleGetPostcodeByCity({
          variables: {
            cityID: newValue?.ID,
          },
        })
      }
    } else {
      dispatchV2({
        type: 'city',
        payload: newValue,
      })
      if (newValue?.ID) {
        handleGetPostcodeByCity({
          variables: {
            cityID: newValue?.ID,
          },
        })
      }
    }
  }
  const handlePostCodeOnChange = (newValue: any, id) => {
    if (id === 'permanentAddress') {
      dispatch({
        type: 'postcode',
        payload: newValue,
      })
    } else {
      dispatchV2({
        type: 'postcode',
        payload: newValue,
      })
    }
  }

  const initializeEditValue = (props: any, id) => {
    if (id === 'permanentAddress') {
      dispatch({
        type: 'address',
        payload: props?.Line1,
      })
      dispatch({
        type: 'country',
        payload: { name: props?.Country },
        // payload: props.country,
      })
      dispatch({
        type: 'state',
        payload: { name: props?.State },
      })
      dispatch({
        type: 'city',
        payload: { name: props?.City },
      })
      dispatch({
        type: 'postcode',
        payload: { postcode: props?.PostalCode },
      })
    } else {
      dispatchV2({
        type: 'address',
        payload: props?.Line1,
      })
      dispatchV2({
        type: 'country',
        payload: { name: props?.Country },
        // payload: props.country,
      })
      dispatchV2({
        type: 'state',
        payload: { name: props?.State },
      })
      dispatchV2({
        type: 'city',
        payload: { name: props?.City },
      })
      dispatchV2({
        type: 'postcode',
        payload: { postcode: props?.PostalCode },
      })
    }
  }

  return {
    state,
    stateV2,
    autoCompleteRef,
    autoCompleteRefV2,
    query,
    queryV2,
    setQuery,
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    dispatch,
    dispatchV2,
    initializeEditValue,
    loadScript,
    handleScriptLoad,
  }
}
