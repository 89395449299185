import React, { lazy } from 'react'

// Setting
const SettingMenu = lazy(() =>
  import('../RecruitmentModule/RecruitmentSetting/SettingMenu').then(
    module => ({
      default: module.SettingMenu,
    })
  )
)

const ApprovalPolicyMatrixMenu = lazy(() =>
  import(
    '../RecruitmentModule/RecruitmentSetting/ApprovalPolicyMatrix/ApprovalPolicyMatrixMenu'
  ).then(module => ({
    default: module.ApprovalPolicyMatrixMenu,
  }))
)

const ApprovalPolicyMatrixListing = lazy(() =>
  import(
    '../RecruitmentModule/RecruitmentSetting/ApprovalPolicyMatrix/ApprovalPolicyMatrixListing'
  ).then(module => ({
    default: module.ApprovalPolicyMatrixListing,
  }))
)

// Talent Pool
const TalentPoolMenuListing = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentPoolMenuListing').then(
    module => ({
      default: module.TalentPoolMenuListing,
    })
  )
)

const TalentPoolListing = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolListing'
  ).then(module => ({
    default: module.TalentPoolListing,
  }))
)

const TalentPoolForm = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolForm'
  ).then(module => ({
    default: module.TalentPoolForm,
  }))
)

const TalentPoolView = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolView'
  ).then(module => ({
    default: module.TalentPoolView,
  }))
)

const TalentPoolDetails = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolDetails'
  ).then(module => ({
    default: module.TalentPoolDetails,
  }))
)

const TalentPoolApplication = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolApplication'
  ).then(module => ({
    default: module.TalentPoolApplication,
  }))
)

const TalentPoolApplicantByStage = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolApplicantByStage'
  ).then(module => ({
    default: module.TalentPoolApplicantByStage,
  }))
)

// Talent Invitation
const TalentInvitationListing = lazy(() =>
  import('./TalentPool/TalentInvitation/TalentInvitationListing').then(
    module => ({
      default: module.TalentInvitationListing,
    })
  )
)

const TalentInvitationView = lazy(() =>
  import('./TalentPool/TalentInvitation/TalentInvitationView').then(module => ({
    default: module.TalentInvitationView,
  }))
)

const TalentInvitationForm = lazy(() =>
  import('./TalentPool/TalentInvitation/TalentInvitationForm').then(module => ({
    default: module.TalentInvitationForm,
  }))
)

const TalentInvitationDetails = lazy(() =>
  import('./TalentPool/TalentInvitation/TalentInvitationDetails').then(
    module => ({
      default: module.TalentInvitationDetails,
    })
  )
)

//Talent Search
const TalentPoolSearch = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentSearch/TalentPoolSearch').then(
    module => ({
      default: module.TalentPoolSearch,
    })
  )
)

const TalentSearchApplication = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentSearch/TalentSearchApplication'
  ).then(module => ({
    default: module.TalentSearchApplication,
  }))
)

const TalentSearchApplicantByStage = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentSearch/TalentSearchApplicantByStage'
  ).then(module => ({
    default: module.TalentSearchApplicantByStage,
  }))
)

const TalentSearchView = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentSearch/TalentSearchView').then(
    module => ({
      default: module.TalentSearchView,
    })
  )
)

const TalentSearchDetails = lazy(() =>
  import(
    '../RecruitmentModule/TalentPool/TalentSearch/TalentSearchDetails'
  ).then(module => ({
    default: module.TalentSearchDetails,
  }))
)

// Recruitment Requisition
const RecruitmentRequisitionListing = lazy(() =>
  import(
    '../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionListing'
  ).then(module => ({
    default: module.RecruitmentRequisitionListing,
  }))
)

const RecruitmentRequisitionForm = lazy(() =>
  import(
    '../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionForm'
  ).then(module => ({
    default: module.RecruitmentRequisitionForm,
  }))
)

const RecruitmentRequisitionView = lazy(() =>
  import(
    '../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionView'
  ).then(module => ({
    default: module.RecruitmentRequisitionView,
  }))
)

// Job Vacancy Management
const JobVacancyManagement = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobVacancyManagement').then(
    module => ({
      default: module.JobVacancyManagement,
    })
  )
)
//Job Vacancy
const JobVacancyListing = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyListing'
  ).then(module => ({
    default: module.JobVacancyListing,
  }))
)

const JobVacancyForm = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyForm'
  ).then(module => ({
    default: module.JobVacancyForm,
  }))
)

const JobVacancyView = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyView'
  ).then(module => ({
    default: module.JobVacancyView,
  }))
)

const JobVacancyApplicantList = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyApplicantList'
  ).then(module => ({
    default: module.JobVacancyApplicantList,
  }))
)

const JobVacancyApplicantView = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyApplicantView'
  ).then(module => ({
    default: module.JobVacancyApplicantView,
  }))
)

const JobVacancyApplicantDetails = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyApplicantDetails'
  ).then(module => ({
    default: module.JobVacancyApplicantDetails,
  }))
)

//Job Posting
const JobPostingListing = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingListing'
  ).then(module => ({
    default: module.JobPostingListing,
  }))
)

const JobPostingForm = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingForm'
  ).then(module => ({
    default: module.JobPostingForm,
  }))
)

const JobPostingView = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingView'
  ).then(module => ({
    default: module.JobPostingView,
  }))
)

const JobPostingApplication = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingApplication'
  ).then(module => ({
    default: module.JobPostingApplication,
  }))
)

const JobPostingApplicationView = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingApplicationView'
  ).then(module => ({
    default: module.JobPostingApplicationView,
  }))
)

const JobPostingApplicationDetails = lazy(() =>
  import(
    '../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingApplicationDetails'
  ).then(module => ({
    default: module.JobPostingApplicationDetails,
  }))
)

// Applicant Management
const ApplicantManagementListing = lazy(() =>
  import(
    './JobVacancyManagement/ApplicantManagement/ApplicantManagementListing'
  ).then(module => ({
    default: module.ApplicantManagementListing,
  }))
)

const ApplicationListing = lazy(() =>
  import('./JobVacancyManagement/ApplicantManagement/ApplicationListing').then(
    module => ({
      default: module.ApplicationListing,
    })
  )
)

const ApplicantByStageListing = lazy(() =>
  import(
    './JobVacancyManagement/ApplicantManagement/ApplicantByStageListing'
  ).then(module => ({
    default: module.ApplicantByStageListing,
  }))
)

const ApplicantView = lazy(() =>
  import('./JobVacancyManagement/ApplicantManagement/ApplicantView').then(
    module => ({
      default: module.ApplicantView,
    })
  )
)

const ApplicantDetails = lazy(() =>
  import('./JobVacancyManagement/ApplicantManagement/ApplicantDetails').then(
    module => ({
      default: module.ApplicantDetails,
    })
  )
)

// Interview Scheduling
const InterviewSchedulingListing = lazy(() =>
  import(
    './JobVacancyManagement/InterviewScheduling/InterviewSchedulingListing'
  ).then(module => ({
    default: module.InterviewSchedulingListing,
  }))
)

const ScheduledInterviewListing = lazy(() =>
  import(
    './JobVacancyManagement/InterviewScheduling/ScheduledInterviewListing'
  ).then(module => ({
    default: module.ScheduledInterviewListing,
  }))
)

const InterviewDetail = lazy(() =>
  import('./JobVacancyManagement/InterviewScheduling/InterviewDetail').then(
    module => ({
      default: module.InterviewDetail,
    })
  )
)

const InterviewDetailView = lazy(() =>
  import('./JobVacancyManagement/InterviewScheduling/InterviewDetailView').then(
    module => ({
      default: module.InterviewDetailView,
    })
  )
)
//Job Offer Management
const JobOfferManagement = lazy(() =>
  import('./JobOfferManagement/JobOfferManagement').then(module => ({
    default: module.JobOfferManagement,
  }))
)
//Final Applicant Selection
const FinalApplicantSelectionListing = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantSelectionListing'
  ).then(module => ({
    default: module.FinalApplicantSelectionListing,
  }))
)
const FinalApplicantSelectionView = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantSelectionView'
  ).then(module => ({
    default: module.FinalApplicantSelectionView,
  }))
)
const FinalApplicantInterviewDetails = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantInterviewDetails'
  ).then(module => ({
    default: module.FinalApplicantInterviewDetails,
  }))
)
const FinalApplicantInterviewDetailView = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantInterviewDetailView'
  ).then(module => ({
    default: module.FinalApplicantInterviewDetailView,
  }))
)
const FinalApplicantView = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantView'
  ).then(module => ({
    default: module.FinalApplicantView,
  }))
)
const FinalApplicantDetails = lazy(() =>
  import(
    './JobOfferManagement/FinalApplicantSelection/FinalApplicantDetails'
  ).then(module => ({
    default: module.FinalApplicantDetails,
  }))
)
//Offer Proposal
const OfferProposalListing = lazy(() =>
  import('./JobOfferManagement/OfferProposal/OfferProposalListing').then(
    module => ({
      default: module.OfferProposalListing,
    })
  )
)
const OfferProposalForm = lazy(() =>
  import('./JobOfferManagement/OfferProposal/OfferProposalForm').then(
    module => ({
      default: module.OfferProposalForm,
    })
  )
)
const OfferProposalView = lazy(() =>
  import('./JobOfferManagement/OfferProposal/OfferProposalView').then(
    module => ({
      default: module.OfferProposalView,
    })
  )
)
//Offer Approval
const OfferApprovalListing = lazy(() =>
  import('./JobOfferManagement/OfferApproval/OfferApprovalListing').then(
    module => ({
      default: module.OfferApprovalListing,
    })
  )
)
const OfferApprovalView = lazy(() =>
  import('./JobOfferManagement/OfferApproval/OfferApprovalView').then(
    module => ({
      default: module.OfferApprovalView,
    })
  )
)
//Approved Job Offer Processing
const ApprovedJobOfferProcessingListing = lazy(() =>
  import(
    './JobOfferManagement/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingListing'
  ).then(module => ({
    default: module.ApprovedJobOfferProcessingListing,
  }))
)
const ApprovedJobOfferProcessingView = lazy(() =>
  import(
    './JobOfferManagement/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingView'
  ).then(module => ({
    default: module.ApprovedJobOfferProcessingView,
  }))
)
const ApprovedJobOfferProcessingDetails = lazy(() =>
  import(
    './JobOfferManagement/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingDetails'
  ).then(module => ({
    default: module.ApprovedJobOfferProcessingDetails,
  }))
)
const OfferChecklistPreview = lazy(() =>
  import(
    './JobOfferManagement/ApprovedJobOfferProcessing/OfferChecklistPreview'
  ).then(module => ({
    default: module.OfferChecklistPreview,
  }))
)
//Hiring Process

const HiringProcess = lazy(() =>
  import('./HiringProcess/HiringProcess').then(module => ({
    default: module.HiringProcess,
  }))
)

const AcceptedOfferPreHiringListing = lazy(() =>
  import(
    './HiringProcess/AcceptedOfferPreHiring/AcceptedOfferPreHiringListing'
  ).then(module => ({
    default: module.AcceptedOfferPreHiringListing,
  }))
)

//Quick Hiring

const QuickHiringListing = lazy(() =>
  import('./HiringProcess/QuickHiring/QuickHiringListing').then(module => ({
    default: module.QuickHiringListing,
  }))
)

const QuickHiringForm = lazy(() =>
  import('./HiringProcess/QuickHiring/QuickHiringForm').then(module => ({
    default: module.QuickHiringForm,
  }))
)

//Pre Hiring Data Update

const PreHiringDataUpdateListing = lazy(() =>
  import('./HiringProcess/PreHiringDataUpdate/PreHiringDataUpdateListing').then(
    module => ({
      default: module.PreHiringDataUpdateListing,
    })
  )
)

const PreHiringDataUpdateForm = lazy(() =>
  import('./HiringProcess/PreHiringDataUpdate/PreHiringDataUpdateForm').then(
    module => ({
      default: module.PreHiringDataUpdateForm,
    })
  )
)

const PreHiringDataUpdateView = lazy(() =>
  import('./HiringProcess/PreHiringDataUpdate/PreHiringDataUpdateView').then(
    module => ({
      default: module.PreHiringDataUpdateView,
    })
  )
)

const PreHiringDataUpdateDetails = lazy(() =>
  import('./HiringProcess/PreHiringDataUpdate/PreHiringDataUpdateDetails').then(
    module => ({
      default: module.PreHiringDataUpdateDetails,
    })
  )
)

//New Hire Process

const NewHireProcessListing = lazy(() =>
  import('./HiringProcess/NewHireProcess/NewHireProcessListing').then(
    module => ({
      default: module.NewHireProcessListing,
    })
  )
)

const NewHireProcessOnboard = lazy(() =>
  import('./HiringProcess/NewHireProcess/NewHireProcessOnboard').then(
    module => ({
      default: module.NewHireProcessOnboard,
    })
  )
)

const NewHireProcessApplicantView = lazy(() =>
  import('./HiringProcess/NewHireProcess/NewHireProcessApplicantView').then(
    module => ({
      default: module.NewHireProcessApplicantView,
    })
  )
)

const NewHireProcessApplicantDetails = lazy(() =>
  import('./HiringProcess/NewHireProcess/NewHireProcessApplicantDetails').then(
    module => ({
      default: module.NewHireProcessApplicantDetails,
    })
  )
)

const recruitmentRoutes = [
  // Setting
  {
    props: { exact: true, path: '/RecruitmentManagement/Setting' },
    component: <SettingMenu />,
  },

  {
    props: { exact: true, path: '/Setting/ApprovalPolicyMatrix' },
    component: <ApprovalPolicyMatrixMenu />,
  },

  {
    props: {
      exact: true,
      path: '/Setting/ApprovalPolicyMatrix/RecruitmentRequisitionPolicy',
    },
    component: (
      <ApprovalPolicyMatrixListing type="RecruitmentRequisitionPolicy" />
    ),
  },

  {
    props: { exact: true, path: '/Setting/ApprovalPolicyMatrix/OfferPolicy' },
    component: <ApprovalPolicyMatrixListing type="OfferPolicy" />,
  },

  {
    props: { exact: true, path: '/Setting/ApprovalPolicyMatrix/OnboardPolicy' },
    component: <ApprovalPolicyMatrixListing type="OnboardPolicy" />,
  },
  // Talent Pool
  {
    props: { exact: true, path: '/RecruitmentManagement/TalentPool' },
    component: <TalentPoolMenuListing />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolListing' },
    component: <TalentPoolListing />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolForm/New' },
    component: <TalentPoolForm mode="add" />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolForm/Edit' },
    component: <TalentPoolForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolView' },
    component: <TalentPoolView />,
  },

  {
    props: {
      exact: true,
      path: '/TalentPool/TalentPoolView/TalentPoolDetails',
    },
    component: <TalentPoolDetails />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolApplication' },
    component: <TalentPoolApplication />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolApplicantByStage' },
    component: <TalentPoolApplicantByStage />,
  },

  // Talent Invitation
  {
    props: { exact: true, path: '/TalentInvitation/TalentInvitationListing' },
    component: <TalentInvitationListing />,
  },

  {
    props: { exact: true, path: '/TalentInvitation/TalentInvitationView' },
    component: <TalentInvitationView />,
  },

  {
    props: { exact: true, path: '/TalentInvitation/TalentInvitationForm/New' },
    component: <TalentInvitationForm mode="add" />,
  },

  {
    props: { exact: true, path: '/TalentInvitation/TalentInvitationForm/Edit' },
    component: <TalentInvitationForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/TalentInvitation/TalentInvitationView/TalentInvitationDetails',
    },
    component: <TalentInvitationDetails />,
  },

  //Talent Search
  {
    props: { exact: true, path: '/TalentSearch/TalentPoolSearch' },
    component: <TalentPoolSearch />,
  },

  {
    props: { exact: true, path: '/TalentSearch/TalentSearchApplication' },
    component: <TalentSearchApplication />,
  },

  {
    props: { exact: true, path: '/TalentSearch/TalentSearchApplicantByStage' },
    component: <TalentSearchApplicantByStage />,
  },

  {
    props: { exact: true, path: '/TalentSearch/TalentSearchView' },
    component: <TalentSearchView />,
  },

  {
    props: { exact: true, path: '/TalentSearch/TalentSearchDetails' },
    component: <TalentSearchDetails />,
  },

  // Recruitment Requisition
  {
    props: {
      exact: true,
      path: '/RecruitmentRequisition/RecruitmentRequisitionListing',
    },
    component: <RecruitmentRequisitionListing />,
  },

  {
    props: {
      exact: true,
      path: '/RecruitmentRequisition/RecruitmentRequisitionForm/New',
    },
    component: <RecruitmentRequisitionForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/RecruitmentRequisition/RecruitmentRequisitionForm/Edit',
    },
    component: <RecruitmentRequisitionForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/RecruitmentRequisition/RecruitmentRequisitionView',
    },
    component: <RecruitmentRequisitionView />,
  },

  // Job Vacancy Management
  {
    props: { exact: true, path: '/RecruitmentManagement/JobVacancyManagement' },
    component: <JobVacancyManagement />,
  },
  // Job Vacancy
  {
    props: { exact: true, path: '/JobVacancy/JobVacancyListing' },
    component: <JobVacancyListing />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyForm/New' },
    component: <JobVacancyForm mode="add" />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyForm/Edit' },
    component: <JobVacancyForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyView' },
    component: <JobVacancyView />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyApplicantList' },
    component: <JobVacancyApplicantList />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyApplicantView' },
    component: <JobVacancyApplicantView />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyApplicantDetails' },
    component: <JobVacancyApplicantDetails />,
  },

  // Job Posting
  {
    props: { exact: true, path: '/JobPosting/JobPostingListing' },
    component: <JobPostingListing />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingForm/New' },
    component: <JobPostingForm mode="add" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingForm/Edit' },
    component: <JobPostingForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingView' },
    component: <JobPostingView />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingApplication' },
    component: <JobPostingApplication />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingApplicationView' },
    component: <JobPostingApplicationView />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingApplicationDetails' },
    component: <JobPostingApplicationDetails />,
  },

  // Applicant Management
  {
    props: {
      exact: true,
      path: '/ApplicantManagement/ApplicantManagementListing',
    },
    component: <ApplicantManagementListing />,
  },

  {
    props: { exact: true, path: '/ApplicantManagement/ApplicationListing' },
    component: <ApplicationListing />,
  },

  {
    props: {
      exact: true,
      path: '/ApplicantManagement/ApplicantByStageListing',
    },
    component: <ApplicantByStageListing />,
  },

  {
    props: {
      exact: true,
      path: '/ApplicantManagement/ApplicantView',
    },
    component: <ApplicantView />,
  },

  {
    props: {
      exact: true,
      path: '/ApplicantManagement/ApplicantDetails',
    },
    component: <ApplicantDetails />,
  },

  // Interview Scheduling
  {
    props: {
      exact: true,
      path: '/InterviewScheduling/InterviewSchedulingListing',
    },
    component: <InterviewSchedulingListing />,
  },

  {
    props: {
      exact: true,
      path: '/InterviewScheduling/ScheduledInterviewListing',
    },
    component: <ScheduledInterviewListing />,
  },

  {
    props: { exact: true, path: '/InterviewScheduling/InterviewDetail' },
    component: <InterviewDetail />,
  },

  {
    props: { exact: true, path: '/InterviewScheduling/InterviewDetailView' },
    component: <InterviewDetailView />,
  },
  //Job Offer Management
  {
    props: { exact: true, path: '/RecruitmentManagement/JobOfferManagement' },
    component: <JobOfferManagement />,
  },

  //Final Applicant Selection Listing
  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantSelectionListing',
    },
    component: <FinalApplicantSelectionListing />,
  },

  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantSelectionView',
    },
    component: <FinalApplicantSelectionView />,
  },

  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantInterviewDetails',
    },
    component: <FinalApplicantInterviewDetails />,
  },

  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantInterviewDetailView',
    },
    component: <FinalApplicantInterviewDetailView />,
  },

  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantView',
    },
    component: <FinalApplicantView />,
  },

  {
    props: {
      exact: true,
      path: '/FinalApplicantSelection/FinalApplicantDetails',
    },
    component: <FinalApplicantDetails />,
  },
  //Offer Proposal
  {
    props: { exact: true, path: '/OfferProposal/OfferProposalListing' },
    component: <OfferProposalListing />,
  },

  {
    props: { exact: true, path: '/OfferProposal/OfferProposalForm' },
    component: <OfferProposalForm />,
  },

  {
    props: { exact: true, path: '/OfferProposal/OfferProposalView' },
    component: <OfferProposalView />,
  },
  //Offer Approval
  {
    props: { exact: true, path: '/OfferApproval/OfferApprovalListing' },
    component: <OfferApprovalListing />,
  },
  {
    props: { exact: true, path: '/OfferApproval/OfferApprovalView' },
    component: <OfferApprovalView />,
  },
  //Approve Job Offer Processing
  {
    props: {
      exact: true,
      path: '/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingListing',
    },
    component: <ApprovedJobOfferProcessingListing />,
  },
  {
    props: {
      exact: true,
      path: '/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingView',
    },
    component: <ApprovedJobOfferProcessingView />,
  },
  {
    props: {
      exact: true,
      path: '/ApprovedJobOfferProcessing/ApprovedJobOfferProcessingDetails',
    },
    component: <ApprovedJobOfferProcessingDetails />,
  },
  {
    props: {
      exact: true,
      path: '/ApprovedJobOfferProcessing/OfferChecklistPreview',
    },
    component: <OfferChecklistPreview />,
  },

  //Hiring Process

  {
    props: { exact: true, path: '/RecruitmentManagement/HiringProcess' },
    component: <HiringProcess />,
  },

  {
    props: {
      exact: true,
      path: '/AcceptedOfferPreHiring/AcceptedOfferPreHiringListing',
    },
    component: <AcceptedOfferPreHiringListing />,
  },

  //Quick Hiring

  {
    props: { exact: true, path: '/QuickHiring/QuickHiringListing' },
    component: <QuickHiringListing />,
  },

  {
    props: { exact: true, path: '/QuickHiring/QuickHiringForm/New' },
    component: <QuickHiringForm mode="add" />,
  },

  {
    props: { exact: true, path: '/QuickHiring/QuickHiringForm/Edit' },
    component: <QuickHiringForm mode="edit" />,
  },

  //Pre Hiring Data Update

  {
    props: {
      exact: true,
      path: '/PreHiringDataUpdate/PreHiringDataUpdateListing',
    },
    component: <PreHiringDataUpdateListing />,
  },

  {
    props: {
      exact: true,
      path: '/PreHiringDataUpdate/PreHiringDataUpdateForm/New',
    },
    component: <PreHiringDataUpdateForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/PreHiringDataUpdate/PreHiringDataUpdateForm/Edit',
    },
    component: <PreHiringDataUpdateForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/PreHiringDataUpdate/PreHiringDataUpdateView',
    },
    component: <PreHiringDataUpdateView />,
  },

  {
    props: {
      exact: true,
      path: '/PreHiringDataUpdate/PreHiringDataUpdateDetails',
    },
    component: <PreHiringDataUpdateDetails />,
  },

  //New Hire Process

  {
    props: { exact: true, path: '/NewHireProcess/NewHireProcessListing' },
    component: <NewHireProcessListing />,
  },

  {
    props: { exact: true, path: '/NewHireProcess/NewHireProcessOnboard' },
    component: <NewHireProcessOnboard />,
  },

  {
    props: { exact: true, path: '/NewHireProcess/NewHireProcessApplicantView' },
    component: <NewHireProcessApplicantView />,
  },

  {
    props: {
      exact: true,
      path: '/NewHireProcess/NewHireProcessApplicantDetails',
    },
    component: <NewHireProcessApplicantDetails />,
  },
]

export default recruitmentRoutes
