import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Grid, TextField } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { BasicHeader } from 'components/ExternalComponent/BasicHeader'
import { lightenColor } from 'containers/helper/ColorConverter'
import {
  useJobPortalInfoQuery,
  useJobPortalTalentInsertMutation,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import useInputStyles from '../CustomInputStyles'

interface JobPortalForm {
  Name: string
  Email: string
}

export const JobPortalForm = (props: any) => {
  // Define
  let history = useHistory()
  const { state }: any = useLocation()
  const portalToken = state?.portalToken

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    formState,
    watch,
  } = useForm<JobPortalForm>({
    defaultValues: {
      Name: '',
      Email: '',
    },
  })

  // useState
  const [showExistDialog, setShowExistDialog] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [JobPortal, setJobPortal] = useState(null)

  // Query
  const {
    data: JobPortalInfo = { JobPortalInfo: null },
    loading: JobPortalInfoLoading,
  } = useJobPortalInfoQuery({
    fetchPolicy: 'no-cache',
    variables: {
      PortalToken: state?.portalToken,
    },
    onCompleted: data => {
      if (data?.JobPortalInfo) {
        setJobPortal(data?.JobPortalInfo)
      }
    },
  })

  const [JobPortalTalentInsert] = useJobPortalTalentInsertMutation({
    onError: error => {
      snackBar(error?.message?.replace('GraphQL error:', ''), false)
    },
    onCompleted: data => {
      const result = data?.JobPortalTalentInsert

      if (result?.status === 'Accepted') {
        setShowExistDialog('Accepted')
      } else if (result?.status === 'Progress') {
        setShowExistDialog('Progress')
      } else if (result?.status === 'Exist') {
        setShowExistDialog('Exist')
      } else if (result?.status === 'New') {
        history.push(`/JobPortal/EApplication`, {
          ...state,
          TalentInfo: {
            name: result?.name,
            email: result?.email,
          },
          SubscriptionInfo: {
            SubscriptionAccountID: result?.SubscriptionAccountID,
            userID: result?.userID,
          },
        })
      }
    },
  })

  // useEffect
  useEffect(() => {
    if (!portalToken) {
      snackBar(`You don't have permission to access this page.`, false)

      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [portalToken])

  // Function
  const onSubmit = (data: JobPortalForm) => {
    JobPortalTalentInsert({
      variables: {
        input: {
          FullName: data?.Name,
          Email: data?.Email,
        },
        portalToken,
        JobPostingID: state?.JobPostingID,
      },
    })
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.push(`/JobPortal/AvailableVacancyDetail`, {
          ...state,
        })
      }
    }, 2000)
  }

  const classes = useInputStyles(JobPortal?.theme_color)()

  return (
    <>
      {JobPortalInfoLoading ? (
        <Loading backdropColor={JobPortal?.theme_color} />
      ) : (
        <>
          <BasicHeader
            mainBtn="close"
            onClick={() =>
              history.push(`/JobPortal/AvailableVacancyDetail`, {
                ...state,
              })
            }
            title="E-Application"
          />

          <ContentWrapper multiDynamicInfoSearch footer>
            <div
              className="mdDesc"
              style={{
                textAlign: 'center',
                whiteSpace: 'initial',
                marginLeft: '12px',
                marginRight: '12px',
              }}
            >
              Please enter your name and email address to proceed.
            </div>

            <Grid
              item
              xs={12}
              style={{ paddingRight: '8px', paddingLeft: '8px' }}
            >
              <Controller
                name={'Name'}
                label="Name"
                control={control}
                ref={register}
                render={({ onChange, value, name }) => (
                  <TextField
                    name={name}
                    label="Name"
                    required
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    helperText={errors?.Name?.message}
                    error={!!errors?.Name}
                  />
                )}
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingRight: '8px', paddingLeft: '8px' }}
            >
              <Controller
                name={'Email'}
                label="Email Address"
                control={control}
                ref={register}
                render={({ onChange, value, name }) => (
                  <TextField
                    name={name}
                    label="Email Address"
                    required
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    helperText={errors?.Email?.message}
                    error={!!errors?.Email}
                  />
                )}
                rules={{
                  required: { value: true, message: 'This field is required' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
              />
            </Grid>
          </ContentWrapper>

          <Footer
            externalDisplay
            themeColor={JobPortal?.theme_color}
            footerText={
              <>
                <div
                  className="desc"
                  style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  <div
                    className="xsTitle"
                    style={{ paddingRight: '8px', paddingLeft: '8px' }}
                  >
                    Existing Applicants
                  </div>
                  <div
                    className="desc"
                    style={{
                      color: 'grey',
                      whiteSpace: 'initial',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                    }}
                  >
                    If you have previously applied for a position with us,
                    please use the same email address as your previous
                    application.
                  </div>
                </div>
              </>
            }
            options={[
              {
                onClick: () => handleSubmit(onSubmit)(),
                name: 'Proceed',
                color: 'primary',
                style: {
                  backgroundColor: JobPortal?.theme_color,
                },
              },
            ]}
          />

          <CommonDialog
            fullWidth={true}
            open={!!showExistDialog}
            onClose={() => setShowExistDialog('')}
            headerBgColor={lightenColor(JobPortal?.theme_color, 80)}
            sections={{
              body: () => (
                <>
                  {showExistDialog === 'Accepted' && (
                    <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                      Congratulations! You have accepted our offer. Please stay
                      tuned for further instructions regarding the onboarding
                      process.
                    </div>
                  )}

                  {showExistDialog === 'Progress' && (
                    <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                      Your application is currently in progress. If you need to
                      update your profile, please contact HR Department to
                      request the update.
                    </div>
                  )}

                  {showExistDialog === 'Exist' && (
                    <div className="mdDesc" style={{ whiteSpace: 'initial' }}>
                      We have your records on file. To proceed with the
                      application with the data previously submitted, we've send
                      you an email with the instructions to proceed.
                    </div>
                  )}
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Close',
                    props: {
                      onClick: () => setShowExistDialog(''),
                      variant: 'contained',
                      color: 'primary',
                      style: {
                        backgroundColor: JobPortal?.theme_color,
                      },
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        externalDisplay
      />
    </>
  )
}
