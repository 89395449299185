import React, { lazy } from 'react'

// ------------ Common Setting > Recruitment Management ----------- //
const RecruitmentManagement = lazy(() =>
  import('./SystemAdmin/RecruitmentManagement').then(module => ({
    default: module.RecruitmentManagement,
  }))
)
const RecruitmentApprovalPoliciesMenu = lazy(() =>
  import(
    './SystemAdmin/RecruitmentApprovalPolicies/RecruitmentApprovalPoliciesMenu'
  ).then(module => ({
    default: module.RecruitmentApprovalPoliciesMenu,
  }))
)
const RecruitmentApprovalPoliciesListing = lazy(() =>
  import(
    './SystemAdmin/RecruitmentApprovalPolicies/RecruitmentApprovalPoliciesListing'
  ).then(module => ({
    default: module.RecruitmentApprovalPoliciesListing,
  }))
)
const RecruitmentApprovalPoliciesForm = lazy(() =>
  import(
    './SystemAdmin/RecruitmentApprovalPolicies/RecruitmentApprovalPoliciesForm'
  ).then(module => ({
    default: module.RecruitmentApprovalPoliciesForm,
  }))
)
const RecruitmentExpensesListing = lazy(() =>
  import('./SystemAdmin/RecruitmentExpenses/RecruitmentExpensesListing').then(
    module => ({
      default: module.RecruitmentExpensesListing,
    })
  )
)
const EApplicationFormSetupListing = lazy(() =>
  import(
    './SystemAdmin/EApplicationFormSetup/EApplicationFormSetupListing'
  ).then(module => ({
    default: module.EApplicationFormSetupListing,
  }))
)
const ELetterTemplateListing = lazy(() =>
  import('./SystemAdmin/ELetterTemplate/ELetterTemplateListing').then(
    module => ({
      default: module.ELetterTemplateListing,
    })
  )
)
const ELetterTemplateForm = lazy(() =>
  import('./SystemAdmin/ELetterTemplate/ELetterTemplateForm').then(module => ({
    default: module.ELetterTemplateForm,
  }))
)
const ELetterTemplatePreview = lazy(() =>
  import('./SystemAdmin/ELetterTemplate/ELetterTemplatePreview').then(
    module => ({
      default: module.ELetterTemplatePreview,
    })
  )
)
const PreofferChecklistListing = lazy(() =>
  import('./SystemAdmin/PreofferChecklist/PreofferChecklistListing').then(
    module => ({
      default: module.PreofferChecklistListing,
    })
  )
)
const AdditionalUserFieldsListing = lazy(() =>
  import(
    './SystemAdmin/TalentPoolAdditionalUserFields/AdditionalUserFieldsListing'
  ).then(module => ({
    default: module.AdditionalUserFieldsListing,
  }))
)
const DeclineReasonListing = lazy(() =>
  import('./SystemAdmin/DeclineReason/DeclineReasonListing').then(module => ({
    default: module.DeclineReasonListing,
  }))
)
const PrehiringFormSetupListing = lazy(() =>
  import('./SystemAdmin/PrehiringFormSetup/PrehiringFormSetupListing').then(
    module => ({
      default: module.PrehiringFormSetupListing,
    })
  )
)
const JobPortalSetupListing = lazy(() =>
  import('./SystemAdmin/JobPortalSetup/JobPortalSetupListing').then(module => ({
    default: module.JobPortalSetupListing,
  }))
)
const JobPortalSetupForm = lazy(() =>
  import('./SystemAdmin/JobPortalSetup/JobPortalSetupForm').then(module => ({
    default: module.JobPortalSetupForm,
  }))
)
const JobPortalSetupView = lazy(() =>
  import('./SystemAdmin/JobPortalSetup/JobPortalSetupView').then(module => ({
    default: module.JobPortalSetupView,
  }))
)
// ------------ Recruitment Management ----------- //
const RecruitmentManagementListing = lazy(() =>
  import('./RecruitmentModule/RecruitmentManagementListing').then(module => ({
    default: module.RecruitmentManagementListing,
  }))
)

// ------------ My Recruitment ----------- //
const MyRecruitmentMenu = lazy(() =>
  import('./MyRecruitment/MyRecruitmentMenu').then(module => ({
    default: module.MyRecruitmentMenu,
  }))
)

const MyRecTalentSearchListing = lazy(() =>
  import('./MyRecruitment/TalentSearch/MyRecTalentSearchListing').then(
    module => ({
      default: module.MyRecTalentSearchListing,
    })
  )
)

const MyRecTalentSearchApplication = lazy(() =>
  import('./MyRecruitment/TalentSearch/MyRecTalentSearchApplication').then(
    module => ({
      default: module.MyRecTalentSearchApplication,
    })
  )
)

const MyRecTalentSearchApplicantByStage = lazy(() =>
  import('./MyRecruitment/TalentSearch/MyRecTalentSearchApplicantByStage').then(
    module => ({
      default: module.MyRecTalentSearchApplicantByStage,
    })
  )
)

const MyRecTalentSearchView = lazy(() =>
  import('./MyRecruitment/TalentSearch/MyRecTalentSearchView').then(module => ({
    default: module.MyRecTalentSearchView,
  }))
)

const MyRecTalentSearchDetails = lazy(() =>
  import('./MyRecruitment/TalentSearch/MyRecTalentSearchDetails').then(
    module => ({
      default: module.MyRecTalentSearchDetails,
    })
  )
)

const MyRecRequisitionListing = lazy(() =>
  import('./MyRecruitment/RecruitmentRequisition/MyRecRequisitionListing').then(
    module => ({
      default: module.MyRecRequisitionListing,
    })
  )
)

const MyRecRequisitionForm = lazy(() =>
  import('./MyRecruitment/RecruitmentRequisition/MyRecRequisitionForm').then(
    module => ({
      default: module.MyRecRequisitionForm,
    })
  )
)

const MyRecRequisitionDetails = lazy(() =>
  import('./MyRecruitment/RecruitmentRequisition/MyRecRequisitionDetails').then(
    module => ({
      default: module.MyRecRequisitionDetails,
    })
  )
)

const MyApprovedJobVacancyListing = lazy(() =>
  import('./MyRecruitment/ApprovedJobVacancy/MyApprovedJobVacancyListing').then(
    module => ({
      default: module.MyApprovedJobVacancyListing,
    })
  )
)

const MyApplicantByStageListing = lazy(() =>
  import('./MyRecruitment/ApprovedJobVacancy/MyApplicantByStageListing').then(
    module => ({
      default: module.MyApplicantByStageListing,
    })
  )
)

const MyApplicationListing = lazy(() =>
  import('./MyRecruitment/ApprovedJobVacancy/MyApplicationListing').then(
    module => ({
      default: module.MyApplicationListing,
    })
  )
)

const MyApplicantView = lazy(() =>
  import('./MyRecruitment/ApprovedJobVacancy/MyApplicantView').then(module => ({
    default: module.MyApplicantView,
  }))
)

const MyApplicantDetails = lazy(() =>
  import('./MyRecruitment/ApprovedJobVacancy/MyApplicantDetails').then(
    module => ({
      default: module.MyApplicantDetails,
    })
  )
)

const MyRecInterviewListing = lazy(() =>
  import('./MyRecruitment/Interview/MyRecInterviewListing').then(module => ({
    default: module.MyRecInterviewListing,
  }))
)

const MyRecInterviewDetail = lazy(() =>
  import('./MyRecruitment/Interview/MyRecInterviewDetail').then(module => ({
    default: module.MyRecInterviewDetail,
  }))
)

const MyRecInterviewForm = lazy(() =>
  import('./MyRecruitment/Interview/MyRecInterviewForm').then(module => ({
    default: module.MyRecInterviewForm,
  }))
)

const MyOfferListing = lazy(() =>
  import('./MyRecruitment/OfferListing/MyOfferListing').then(module => ({
    default: module.MyOfferListing,
  }))
)

const MyOfferView = lazy(() =>
  import('./MyRecruitment/OfferListing/MyOfferView').then(module => ({
    default: module.MyOfferView,
  }))
)

const HomeRoutes = [
  // ------------ Common Setting > Recruitment Management ----------- //
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RecruitmentManagement',
    },
    component: <RecruitmentManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/RecruitmentApprovalPolicies/RecruitmentApprovalPoliciesMenu',
    },
    component: <RecruitmentApprovalPoliciesMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/RecruitmentApprovalPolicies/RecruitmentRequisitionPolicy',
    },
    component: (
      <RecruitmentApprovalPoliciesListing type="RecruitmentRequisitionPolicy" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OfferPolicy',
    },
    component: <RecruitmentApprovalPoliciesListing type="OfferPolicy" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OnboardPolicy',
    },
    component: <RecruitmentApprovalPoliciesListing type="OnboardPolicy" />,
  },
  {
    props: {
      exect: true,
      path:
        '/SystemAdmin/RecruitmentApprovalPolicies/RecruitmentRequisitionPolicy/add',
    },
    component: (
      <RecruitmentApprovalPoliciesForm
        type="RecruitmentRequisitionPolicy"
        mode="add"
      />
    ),
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OfferPolicy/add',
    },
    component: (
      <RecruitmentApprovalPoliciesForm type="OfferPolicy" mode="add" />
    ),
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OnboardPolicy/add',
    },
    component: (
      <RecruitmentApprovalPoliciesForm type="OnboardPolicy" mode="add" />
    ),
  },
  {
    props: {
      exect: true,
      path:
        '/SystemAdmin/RecruitmentApprovalPolicies/RecruitmentRequisitionPolicy/edit',
    },
    component: (
      <RecruitmentApprovalPoliciesForm
        type="RecruitmentRequisitionPolicy"
        mode="edit"
      />
    ),
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OfferPolicy/edit',
    },
    component: (
      <RecruitmentApprovalPoliciesForm type="OfferPolicy" mode="edit" />
    ),
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/RecruitmentApprovalPolicies/OnboardPolicy/edit',
    },
    component: (
      <RecruitmentApprovalPoliciesForm type="OnboardPolicy" mode="edit" />
    ),
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/RecruitmentExpenses/RecruitmentExpensesListing',
    },
    component: <RecruitmentExpensesListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/EApplicationFormSetup/EApplicationFormSetupListing',
    },
    component: <EApplicationFormSetupListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/ELetterTemplate/ELetterTemplateListing',
    },
    component: <ELetterTemplateListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/ELetterTemplate/ELetterTemplateForm/add',
    },
    component: <ELetterTemplateForm mode="add" />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/ELetterTemplate/ELetterTemplateForm/edit',
    },
    component: <ELetterTemplateForm mode="edit" />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/ELetterTemplate/ELetterTemplatePreview',
    },
    component: <ELetterTemplatePreview />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/PreofferChecklist/PreofferChecklistListing',
    },
    component: <PreofferChecklistListing />,
  },
  {
    props: {
      exect: true,
      path:
        '/SystemAdmin/TalentPoolAdditionalUserFields/AdditionalUserFieldsListing',
    },
    component: <AdditionalUserFieldsListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/DeclineReason/DeclineReasonListing',
    },
    component: <DeclineReasonListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/PrehiringFormSetup/PrehiringFormSetupListing',
    },
    component: <PrehiringFormSetupListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/JobPortalSetup/JobPortalSetupListing',
    },
    component: <JobPortalSetupListing />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/JobPortalSetup/JobPortalSetupForm/add',
    },
    component: <JobPortalSetupForm mode="add" />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/JobPortalSetup/JobPortalSetupForm/edit',
    },
    component: <JobPortalSetupForm mode="edit" />,
  },
  {
    props: {
      exect: true,
      path: '/SystemAdmin/JobPortalSetup/JobPortalSetupView',
    },
    component: <JobPortalSetupView />,
  },
  // ------------- Recruitment Management -------------- //
  {
    props: { exact: true, path: '/RecruitmentManagementListing' },
    component: <RecruitmentManagementListing />,
  },
  // ------------- My Recruitment -------------- //
  {
    props: {
      exact: true,
      path: '/MyRecruitment/Menu',
    },
    component: <MyRecruitmentMenu />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/TalentSearch/Listing',
    },
    component: <MyRecTalentSearchListing />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/TalentSearch/MyTalentSearchApplication',
    },
    component: <MyRecTalentSearchApplication />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/TalentSearch/MyTalentSearchApplicantByStage',
    },
    component: <MyRecTalentSearchApplicantByStage />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/TalentSearch/MyTalentSearchView',
    },
    component: <MyRecTalentSearchView />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/TalentSearch/MyTalentSearchDetails',
    },
    component: <MyRecTalentSearchDetails />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/RecruitmentRequisition/Listing',
    },
    component: <MyRecRequisitionListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/RecruitmentRequisition/Listing/add',
    },
    component: <MyRecRequisitionForm mode="add" />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/RecruitmentRequisition/Listing/edit',
    },
    component: <MyRecRequisitionForm mode="edit" />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/RecruitmentRequisition/Listing/view',
    },
    component: <MyRecRequisitionDetails />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/ApprovedJobVacancy/MyApprovedJobVacancyListing',
    },
    component: <MyApprovedJobVacancyListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/ApprovedJobVacancy/MyApplicantByStageListing',
    },
    component: <MyApplicantByStageListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/ApprovedJobVacancy/MyApplicationListing',
    },
    component: <MyApplicationListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/ApprovedJobVacancy/MyApplicantView',
    },
    component: <MyApplicantView />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/ApprovedJobVacancy/MyApplicantDetails',
    },
    component: <MyApplicantDetails />,
  },
  {
    props: {
      exact: true,
      path: '/MyRecruitment/InterviewListing',
    },
    component: <MyRecInterviewListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/InterviewListingView',
    },
    component: <MyRecInterviewDetail />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/InterviewListingForm',
    },
    component: <MyRecInterviewForm />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/OfferListing/MyOfferListing',
    },
    component: <MyOfferListing />,
  },
  {
    props: {
      exect: true,
      path: '/MyRecruitment/OfferListing/MyOfferView',
    },
    component: <MyOfferView />,
  },
]

export default HomeRoutes
